import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getItinerary, getPorts } from "@/itinerary_dashboard/selectors"
import { setPort } from "@/itinerary_dashboard/Slice"
import Select from "@/components/input/Select"

const PortDropdown: FC = () => {
  const dispatch = useDispatch()

  const { selectedPortId } = useSelector(getItinerary)
  const ports = useSelector(getPorts)

  return (
    <Select
      label="Port"
      floating
      value={selectedPortId}
      onChange={({ target }) => dispatch(setPort(target.value))}
    >
      <option value="">All available ports</option>
      {ports.map((port) => (
        <option key={port.id} value={port.id}>
          {port.name}
        </option>
      ))}
    </Select>
  )
}

export default PortDropdown
