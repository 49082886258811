import React, { FC } from "react"
import { Button, Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { TabTypes } from "@/itinerary_dashboard/Slice"
import {
  getItinerary,
  getSelectedItinerary,
  getSelectedPort,
  getSelectedSeason,
  getSelectedShip,
} from "@/itinerary_dashboard/selectors"
import routes from "@/routes"

import ItineraryTable from "./tables/ItineraryTable"
import PortTable from "./tables/PortTable"
import ShipTable from "./tables/ShipTable"

const Title: FC = () => {
  const { selectedTab } = useSelector(getItinerary)

  const selectedSeason = useSelector(getSelectedSeason)
  const selectedItinerary = useSelector(getSelectedItinerary)
  const selectedPort = useSelector(getSelectedPort)
  const selectedShip = useSelector(getSelectedShip)

  if (!selectedSeason) {
    return <h2 className="h5 mb-0">Showing all available seasons</h2>
  }

  const seasonName = selectedSeason.name

  let title

  switch (selectedTab) {
    case TabTypes.ITINERARY:
      if (!selectedItinerary) {
        title = "Showing all available itineraries"
      } else {
        title = `${selectedItinerary.name} - ${seasonName}`
      }
      break
    case TabTypes.PORT:
      if (!selectedPort) {
        title = "Showing all available ports"
      } else {
        title = `${selectedPort.name} - ${seasonName}`
      }
      break
    case TabTypes.SHIP:
      if (!selectedShip) {
        title = "Showing all available ships"
      } else {
        title = `${selectedShip.name} - ${seasonName}`
      }
      break
    default:
      throw `Unknown tab type: ${selectedTab}`
  }

  return <h2 className="h5 mb-0">{title}</h2>
}

const Actions: FC = () => {
  const { selectedTab } = useSelector(getItinerary)
  const port = useSelector(getSelectedPort)
  const season = useSelector(getSelectedSeason)

  switch (selectedTab) {
    case TabTypes.PORT:
      return (
        <div>
          <Button
            size="sm"
            variant="outline-primary"
            href={routes.onboarding_tour_library_index_path({
              port_ids: _.compact([_.get(port, "id", null)]),
              season_ids: _.compact([_.get(season, "id", null)]),
              _options: true,
            })}
          >
            View Tours
          </Button>
        </div>
      )
    default:
      return null
  }
}

const Tables: FC = () => {
  const { selectedTab } = useSelector(getItinerary)

  switch (selectedTab) {
    case TabTypes.ITINERARY:
      return <ItineraryTable />
    case TabTypes.PORT:
      return <PortTable />
    case TabTypes.SHIP:
      return <ShipTable />
    default:
      throw `Unknown tab type: ${selectedTab}`
  }
}

const VisitCard: FC = () => (
  <Card>
    <Card.Header>
      <div className="d-flex justify-content-between">
        <Title />
        <Actions />
      </div>
    </Card.Header>
    <Tables />
  </Card>
)

export default VisitCard
