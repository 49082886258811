import React, { FC } from "react"
import { Table, Card } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getShips } from "@/itinerary_dashboard/selectors"

const ShipRow = ({ ship }) => (
  <tr>
    <td>{ship.name}</td>
    <td>{ship.cruiseCompany.name}</td>
    <td>{ship.className}</td>
    <td>{ship.capacity}</td>
    <td>{ship.launchYear}</td>
    <td>{ship.sailings}</td>
  </tr>
)

const ShipsCard: FC = () => {
  const ships = useSelector(getShips)

  return (
    <Card>
      <Card.Header>
        <h2 className="h5 mb-0">Ships</h2>
      </Card.Header>
      <Table responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Ship</th>
            <th scope="col">Cruise Line</th>
            <th scope="col">Class</th>
            <th scope="col">Capacity</th>
            <th scope="col">Launch Year</th>
            <th scope="col">Lifetime Sailings</th>
          </tr>
        </thead>
        <tbody>
          {ships.map((ship) => (
            <ShipRow key={ship.id} ship={ship} />
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

export default ShipsCard
