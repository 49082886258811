import React from "react"
import { useSelector } from "react-redux"
import { Table } from "react-bootstrap"

import {
  getCruiseBrands,
  getProspectiveAwardSets,
  getPriceAnalysis,
  getSelectedCruiseBrand,
} from "@/onboarding/features/price_analysis/selectors"

import CruiseBrandRow from "./cruise_brand_table/CruiseBrandRow"
import AwardSetRadioButton from "./cruise_brand_table/AwardSetRadioButton"
import GroupingSelector from "../header/GroupingSelector"

const CruiseBrandTable = (): JSX.Element => {
  const { selectedAwardSetId } = useSelector(getPriceAnalysis)
  const selectedCruiseBrand = useSelector(getSelectedCruiseBrand)
  const cruiseBrands = useSelector(getCruiseBrands)

  const awardSets = useSelector(getProspectiveAwardSets)

  return (
    <div>
      <h2 className="h5">Total Cost Per Cruise Brand</h2>
      <GroupingSelector />
      <Table responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "20%" }}>
              Cruise Brand
            </th>
            <th />
            {awardSets.map(({ id }) => (
              <th key={id} scope="col">
                <div className="d-flex justify-content-end">
                  <AwardSetRadioButton awardSetId={id} />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedCruiseBrand ? (
            <CruiseBrandRow
              key={selectedCruiseBrand.id}
              cruiseBrand={selectedCruiseBrand}
              awardSets={awardSets}
              selectedAwardSetId={selectedAwardSetId}
            />
          ) : (
            cruiseBrands.map((cruiseBrands) => (
              <CruiseBrandRow
                key={cruiseBrands.id}
                cruiseBrand={cruiseBrands}
                awardSets={awardSets}
                selectedAwardSetId={selectedAwardSetId}
              />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default CruiseBrandTable
