import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectPort } from "@/onboarding/features/price_analysis/Slice"
import {
  getPriceAnalysis,
  getSelectedBiddingRegion,
} from "@/onboarding/features/price_analysis/selectors"

const PortDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedPortId } = useSelector(getPriceAnalysis)
  const selectedBiddingRegion = useSelector(getSelectedBiddingRegion)

  const onNewPort = (selectedPortId) => {
    dispatch(selectPort({ portId: selectedPortId }))
  }

  return (
    <Form.Group>
      <Form.Label>Port</Form.Label>
      <Form.Control as="select" value={selectedPortId} onChange={(e) => onNewPort(e.target.value)}>
        <option value="">All</option>
        {selectedBiddingRegion.ports.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default PortDropdown
