import React from "react"
import { useSelector } from "react-redux"
import { Card, Table } from "react-bootstrap"
import _ from "lodash"

import { getChargedPassenger, getBookedPassengers } from "@/guests_booked_tour_account/selectors"
import { getTotalCost } from "@/guests_booked_tour_account/helpers/Charges"

const TourGuestsCard = (): JSX.Element => {
  const passengers = useSelector(getBookedPassengers)
  const payer = useSelector(getChargedPassenger)
  const total = getTotalCost(passengers)

  // TODO(guest-app): If this top-level mt-4 is repeated everywhere, then maybe we can
  // adapt the outer view layout to instead do that.
  //
  // TODO(guest-app): Check the documentation for Card and possibly add inner Card.Body
  // and Card.Title elements: https://react-bootstrap.github.io/components/cards/.
  return (
    <Card className="mt-4">
      <h4 className="mb-1 mt-3 ms-3">Booked Guests</h4>
      <Table size="sm" className="align-items-center table-flush table-pad-sm">
        <thead>
          <tr>
            <th scope="col" className="text-center" />
            <th scope="col" className="text-center">
              Name
            </th>
            <th scope="col" className="text-center">
              Cost
            </th>
          </tr>
        </thead>

        <tbody>
          {_.map(passengers, (value) => (
            <tr key={value.details.name}>
              <td scope="col" className="text-center">
                <img className="avatar rounded-circle" src={value.profilePicture} alt="" />
              </td>
              <td scope="col" className="text-center">
                <button className="btn btn-light" id="dropdownMenuButton" data-bs-toggle="dropdown">
                  {value.details.name}
                  {value.details.name == payer.name ? <i className="fa fa-credit-card ms-2" /> : ""}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li className="dropdown-item">{_.startCase(value.details.pax_type)}</li>
                  {value.details.email ? (
                    <li className="dropdown-item">{value.details.email}</li>
                  ) : (
                    ""
                  )}
                  {value.details.phone_number ? (
                    <li className="dropdown-item">{value.details.phone_number}</li>
                  ) : (
                    ""
                  )}
                </ul>
              </td>
              <td scope="col" className="text-center">
                {value.price.formatted}
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <th scope="row" className="text-center">
              <span className="ms-n3"> Total </span>
            </th>
            <td scope="col" className="text-center" />
            <td scope="col" className="text-center">
              <span className="ms-1"> {total} </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Card>
  )
}

export default TourGuestsCard
