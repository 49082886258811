import React from "react"
import { Button, Dropdown } from "react-bootstrap"
import { useSelector } from "react-redux"

import { daily_settlement_path } from "@/routes"
import { getFilteredTours, getFinalizedSettlementTours } from "@/dashboard/selectors"

const SettlementLink = ({
  date,
  variant = "button",
}: {
  date: string
  variant: string
}): JSX.Element => {
  const finalizedTours = useSelector(getFinalizedSettlementTours)
  const tours = useSelector(getFilteredTours)

  if (variant === "button") {
    return (
      <Button className="float-end" href={daily_settlement_path({ date })}>
        {"Settlement "}
        {tours.length > 0 && (
          <span className="badge bg-secondary text-dark">{`${finalizedTours.length} / ${tours.length}`}</span>
        )}
      </Button>
    )
  }

  if (variant === "dropdown") {
    return (
      <Dropdown.Item href={daily_settlement_path({ date })}>
        {"Settlement "}
        {tours.length > 0 && (
          <div className="badge bg-secondary text-dark">{`${finalizedTours.length} / ${tours.length}`}</div>
        )}
      </Dropdown.Item>
    )
  }

  throw new Error(`Variant ${variant} not supported`)
}

export default SettlementLink
