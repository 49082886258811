import React, { useState } from "react"

import { useSelector, useDispatch } from "react-redux"

import { Row, Col, Form, Button, Container } from "react-bootstrap"
import { DateTimePicker } from "@/components/DatePickers"

import ContentVisibility from "@/components/ContentVisibility"

import FormIdContext from "@/manual_booking/contexts/FormIdContext"

import ToggledTextAreaFieldControl from "@/components/ToggledTextAreaFieldControl"

import { isHidden, isEditable, formatFor } from "@/helpers/formSpec"

import GuestSearch from "@/manual_booking/components/GuestSearch"

import * as AsyncField from "@/helpers/AsyncField"
import GuestColumns from "@/manual_booking/components/GuestColumns"
import {
  setPickupDateTime,
  setDropoffDateTime,
  setSpecialRequirements,
  togglePickupLocationSameAsDropoff,
  selectVehicleClass,
  selectTransmissionType,
  selectPassengerCount,
  selectAircon,
  selectUnlimitedMileage,
  removeBookingForm,
  setPickupLocation,
  setDropoffLocation,
  setDestinationLocation,
  selectDriver,
} from "./Slice"

import { getBookingFormById, getDriverSearchField, getSelectedDriver } from "./selectors"

import { fetchDrivers } from "./thunks"

import VehicleSelection from "./components/VehicleSelection"

const DriverSearch = ({ formId }) => {
  const dispatch = useDispatch()

  const fields = useSelector((state) => state.transportRentalBookings.fields)

  const searchField = useSelector((state) =>
    getDriverSearchField(getBookingFormById(state.transportRentalBookings, formId))
  )

  const [isActive, setActive] = useState(false)

  const toggleActive = () => {
    setActive(!isActive)
  }

  if (!isEditable(fields, "driverSearch")) {
    return <> </>
  }
  if (isActive) {
    return (
      <>
        <Col sm={6}>
          <GuestSearch
            results={AsyncField.isLoading(searchField) ? [] : searchField.value}
            isLoading={AsyncField.isLoading(searchField)}
            onSearch={(q) => {
              dispatch(fetchDrivers({ q, formId }))
            }}
            onSelect={(selections, typeAhead) => {
              dispatch(selectDriver({ driver: selections[0], formId }))
              typeAhead.clear()
              toggleActive()
            }}
          />
        </Col>
        <Col className="col-auto">
          <Button variant="secondary" onClick={toggleActive}>
            {" "}
            Cancel{" "}
          </Button>
        </Col>
      </>
    )
  }
  return (
    <Col className="col-auto">
      <Button size="sm" variant="secondary" onClick={toggleActive}>
        {" "}
        Select Another Driver{" "}
      </Button>
    </Col>
  )
}

const Driver = ({ formId }) => {
  const dispatch = useDispatch()

  const driver = useSelector((state) =>
    getSelectedDriver(getBookingFormById(state.transportRentalBookings, formId))
  )

  return (
    <>
      <Row className="mb-3">
        <Col className="col-auto">
          <b>Driver</b>
        </Col>
        <GuestColumns
          guest={driver}
          guestId={(driver && driver.id) || "no-guest"}
          noGuestMessage="No driver selected"
          auto
        />
        <DriverSearch formId={formId} />
      </Row>
    </>
  )
}

const TransportRentalBookingForm = ({ formId }) => {
  const dispatch = useDispatch()

  const formData = useSelector((state) => getBookingFormById(state.transportRentalBookings, formId))

  const fields = useSelector((state) => state.transportRentalBookings.fields)

  return (
    <FormIdContext.Provider value={formId}>
      <Container fluid className="p-0">
        <ContentVisibility hidden={isHidden(fields, "remove")}>
          <i
            className="bi bi-x-circle float-end fs-4 text-danger"
            onClick={(e) => {
              dispatch(removeBookingForm({ formId }))
            }}
          />
        </ContentVisibility>
        <strong className="mb-3 d-block text-muted">Private Vehicle Rental</strong>
        <Form>
          <Row>
            <Col xs="12" md={6}>
              <Row>
                <Col xs="12" lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Pick up location</Form.Label>
                    <Form.Control
                      value={formData.pickupLocation}
                      onChange={(e) => {
                        dispatch(
                          setPickupLocation({ pickupLocation: e.target.value.toString(), formId })
                        )
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Check
                      type="checkbox"
                      label="Pickup same as dropoff?"
                      checked={formData.pickupLocationSameAsDropoffLocation}
                      onChange={(e) => dispatch(togglePickupLocationSameAsDropoff({ formId }))}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" lg={6}>
                  <DateTimePicker
                    wrapperProps={{ className: "mb-2" }}
                    inputLabel="Pick up date & time"
                    dateFormat={formatFor(fields, "pickupDateTime")}
                    selected={new Date(formData.pickupDateTime)}
                    onChange={(date) => {
                      dispatch(
                        setPickupDateTime({
                          pickupDateTime: date.toISOString(),
                          formId,
                        })
                      )
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="12" md={6}>
              <Row>
                <ContentVisibility hidden={formData.pickupLocationSameAsDropoffLocation}>
                  <Col xs="12" lg={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>Drop off location</Form.Label>
                      <Form.Control
                        value={formData.dropoffLocation}
                        onChange={(e) => {
                          dispatch(
                            setDropoffLocation({
                              dropoffLocation: e.target.value.toString(),
                              formId,
                            })
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </ContentVisibility>
                <Col xs="12" lg={6}>
                  <DateTimePicker
                    wrapperProps={{ className: "mb-2" }}
                    inputLabel="Dropoff date & time"
                    dateFormat={formatFor(fields, "dropoffDateTime")}
                    selected={new Date(formData.dropoffDateTime)}
                    onChange={(date) => {
                      dispatch(
                        setDropoffDateTime({
                          dropoffDateTime: date.toISOString(),
                          formId,
                        })
                      )
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row>
                <Col xs="12" lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Destination</Form.Label>
                    <Form.Control
                      value={formData.destinationLocation}
                      onChange={(e) => {
                        dispatch(
                          setDestinationLocation({
                            destinationLocation: e.target.value.toString(),
                            formId,
                          })
                        )
                      }}
                    />
                    <div className="form-text">
                      Where do you plan on driving to with the vehicle?
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row>
                <Col xs="12" lg={6}>
                  <Driver formId={formId} />
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row>
                <Col lg="4" md="6">
                  <Form.Group className="mb-2">
                    <Form.Label>Vehicle Class</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        dispatch(
                          selectVehicleClass({ formId, vehicleClass: e.target.value.toString() })
                        )
                      }}
                      value={formData.vehicleClassId}
                    >
                      {_.map(formData._vehicleClassOptions.value, (o, idx) => (
                        <option key={idx} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="4" md="6">
                  <Form.Group className="mb-2">
                    <Form.Label>Passengers</Form.Label>
                    <Form.Control
                      type="number"
                      value={formData.passengerCount}
                      onChange={(e) =>
                        dispatch(
                          selectPassengerCount({
                            formId,
                            passengerCount: e.target.value.toString(),
                          })
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="6">
                  <Form.Group className="mb-2">
                    <Form.Label>Transmission</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        dispatch(
                          selectTransmissionType({
                            formId,
                            transmission: e.target.value.toString(),
                          })
                        )
                      }}
                      value={formData.transmission}
                    >
                      {_.map(formData._transmissionOptions.value, (o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="4" md="6">
                  <Form.Group className="mb-2">
                    <Form.Label>Aircon</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        dispatch(
                          selectAircon({
                            formId,
                            aircon: e.target.value.toString(),
                          })
                        )
                      }}
                      value={formData.aircon}
                    >
                      {_.map(formData._airconOptions.value, (o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="4" md="6">
                  <Form.Group className="mb-2">
                    <Form.Label>Unlimited Mileage</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        dispatch(
                          selectUnlimitedMileage({
                            formId,
                            unlimitedMileage: e.target.value.toString(),
                          })
                        )
                      }}
                      value={formData.unlimitedMileage}
                    >
                      {_.map(formData._unlimitedMileageOptions.value, (o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs="12" className="mb-2">
                  <ToggledTextAreaFieldControl
                    value={formData.specialRequirements}
                    onChange={(e) =>
                      dispatch(setSpecialRequirements({ value: e.target.value, formId }))
                    }
                    hidden={isHidden(fields, "specialRequirements")}
                    toggleLabel="Add Special Requirements"
                    toggleClassName="btn-sm btn-outline-primary"
                    label="Special Requirements"
                  >
                    <div className="form-text">
                      Enter any special requirements such as the specification for a special needs
                      mobility device, or other vehicle requirements
                    </div>
                  </ToggledTextAreaFieldControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="mb-2">
                <VehicleSelection formData={formData} />
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </FormIdContext.Provider>
  )
}

export default TransportRentalBookingForm
