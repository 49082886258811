import React from "react"
import { useField, useFormikContext } from "formik"
import _ from "lodash"

import Pricing from "@/pricing"

const RateInput = ({ defaultRateCategories, defaultTiers }): JSX.Element => {
  const name = "ratesheet"
  const formCtx = useFormikContext()
  const [field] = useField(name)

  const value = _.get(formCtx, `values.${name}`)

  // The components being rendered within the component library always belong to the user's company, so they may edit
  // the structure freely.
  return (
    <Pricing.Editor
      {...field}
      name={name}
      value={value}
      readOnly={false}
      canEditPricingStructure
      defaultRateCategories={defaultRateCategories}
      defaultTiers={defaultTiers}
    />
  )
}

export default RateInput
