import React from "react"
import { useSelector } from "react-redux"
import { getPayer, getRoom } from "../selectors"

const Header = (): JSX.Element => {
  const { name } = useSelector(getPayer)
  const roomName = useSelector(getRoom).name

  return (
    <div>
      <h5>
        Room Account for
        {name}
      </h5>
      <h5>
        Room
        {roomName}
      </h5>
    </div>
  )
}

export default Header
