import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getSettlement } from "@/settlement/selectors"

import SignedByLabel from "./header/SignedByLabel"
import SettlementInfo from "./header/SettlementInfo"
import ButtonRow from "./header/ButtonRow"

const Header = (): JSX.Element => {
  const { signedShxAgent, signedTourAgent } = useSelector(getSettlement)

  return (
    <Card.Header>
      <Row>
        <Col md={8}>
          <SettlementInfo />
        </Col>
        <Col md={4}>
          <div className="d-flex flex-column gap-1">
            <ButtonRow />
            <SignedByLabel agent={signedShxAgent} />
            <SignedByLabel agent={signedTourAgent} />
          </div>
        </Col>
      </Row>
    </Card.Header>
  )
}

export default Header
