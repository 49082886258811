import React, { FC } from "react"

import SectionHolder from "./shared/SectionHolder"
import Details from "./tour_stops/Details"
import Edit from "./tour_stops/Edit"
import Timeline from "./tour_stops/Timeline"

const TourStops: FC = () => (
  <SectionHolder title="Tour Itinerary" editComponent={Edit}>
    <Details />
    <Timeline />
  </SectionHolder>
)

export default TourStops
