import React, { useContext } from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import { useModal } from "@/helpers/useModal"
import AuthDescriptorContext from "@/auth_descriptor/Context"
import Roles from "@/roles"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import SendMessageModal, {
  RoleRecipient,
} from "@/dashboard/components/tour_list_card/modals/SendMessageModal"

import BulkTicketLink from "./actions/BulkTicketLink"
import SalesToggle from "./actions/SalesToggle"
import ManifestLink from "./actions/ManifestLink"
import DispatchLink from "./actions/DispatchLink"
import SettlementLink from "./actions/SettlementLink"
import CancelPortLink from "./actions/CancelPortLink"

const DashboardTools = ({ date, dailySale }) => {
  const authDescriptor = useContext(AuthDescriptorContext)

  // TODO Decouple the top row of the SalesToggle component from its bottom row so that
  // we can control the row of action buttons independently.
  const toggleMessageHQ = useModal((props) => (
    <SendMessageModal
      title="Message HQ"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsHqAgent)}
      messageSubject=""
      messageBody=""
      {...props}
    />
  ))

  const toggleMessageEventCoordinator = useModal((props) => (
    <SendMessageModal
      title="Message Event Coordinator"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsEventCoordinator)}
      messageSubject=""
      messageBody=""
      {...props}
    />
  ))

  const toggleMessageBridge = useModal((props) => (
    <SendMessageModal
      title="Message Bridge"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsBridgeAgent)}
      messageSubject=""
      messageBody=""
      {...props}
    />
  ))

  const toolsMenu = (
    <DropdownButton key="dashboard-tools" title="Tools" boundary="viewport">
      <ManifestLink key="daily-manifest" date={date} variant="dropdown" />
      <DispatchLink key="dispatch-sheet" date={date} variant="dropdown" />
      <SettlementLink key="settlement" date={date} variant="dropdown" />
      <CancelPortLink key="cancel-port" variant="dropdown" />
    </DropdownButton>
  )

  const messageMenu = (
    <CruiseLineOnly key="messaging">
      <DropdownButton title="Messaging" boundary="viewport">
        <Dropdown.Item onClick={toggleMessageBridge}>Message Bridge</Dropdown.Item>
        <Dropdown.Item onClick={toggleMessageHQ}>Message HQ</Dropdown.Item>
        <Dropdown.Item onClick={toggleMessageEventCoordinator}>
          Message Event Coordinator
        </Dropdown.Item>
      </DropdownButton>
    </CruiseLineOnly>
  )

  const headerActions = [<BulkTicketLink key="bulk-tickets" date={date} />, toolsMenu, messageMenu]

  return (
    <CruiseLineOnly>
      <SalesToggle initialValue={dailySale} date={dailySale.date} headerActions={headerActions} />
    </CruiseLineOnly>
  )
}

export default DashboardTools
