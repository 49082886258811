import React from "react"

import { getView } from "@/onboarding/features/product/components/product_components/views/EditWrapper"

const ComponentDisplay = ({ component }): JSX.Element => {
  const View = getView(component)

  return <View component={component} editing />
}

export default ComponentDisplay
