import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import FormikBootstrap from "@/components/formik-bootstrap"
import _ from "lodash"
import MultiSelect from "@/components/input/MultiSelect"
import { getMinimumCoverageRequirement } from "@/insurance/features/helpers"
import {
  uploadInsuranceCertificate,
  updateInsuranceCertificate,
  updateView,
  updateEditStateUploads,
} from "../Slice"
import { getProfile } from "../selectors"

const EditUpload = ({ form, fileSrc }): JSX.Element => {
  const { certificateId } = form
  const { _view, editStateUploads } = useSelector(getProfile)
  const [editUploads, setEditUploads] = useState(editStateUploads)

  const updateEditState = () => {
    const newState = {
      ...editUploads,
      [certificateId]: !editUploads[certificateId],
    }
    setEditUploads(newState)
    dispatch(updateEditStateUploads({ editStateUploads: newState }))
  }

  const updateCategories = (categories) => {
    const newView = _.cloneDeep(_view)

    if (_.isNil(certificateId)) {
      newView.value.profile.newUpload.categoriesForm.value = categories
      dispatch(updateView({ view: newView }))
    } else {
      const currentUpload = newView.value.profile.insuranceUploads.find(
        (upload) => upload.certificateId === certificateId
      )
      currentUpload.categoriesForm.value = categories
      dispatch(updateView({ view: newView }))
    }
  }

  const { categoriesForm } = form

  const [categoriesUpdated, setCategoriesUpdated] = useState(false)
  const [initialCategories, setInitialCategories] = useState(_.cloneDeep(form.categoriesForm.value))
  const [selectedCategories, setSelectedCategories] = useState(_.cloneDeep(categoriesForm.value))

  const [minimumCoverageRequirement, setMinimumCoverageRequirement] = useState(
    getMinimumCoverageRequirement(categoriesForm.value, _view.value.profile.insuranceCategories)
  )

  const onCancel = () => {
    updateEditState()
    setCategoriesUpdated(false)
    setSelectedCategories(_.cloneDeep(initialCategories))
    updateCategories(initialCategories)
  }

  const onChange = (e) => {
    setSelectedCategories(e.target.value)
    setMinimumCoverageRequirement(
      getMinimumCoverageRequirement(e.target.value, _view.value.profile.insuranceCategories)
    )
    setCategoriesUpdated(true)
  }

  const dispatch = useDispatch()

  return (
    <>
      <div className="col-lg-3 ms-3 mb-4">
        <MultiSelect
          name="categories"
          value={selectedCategories}
          label="Select certificate categories"
          onChange={onChange}
          placeholder="General Liability"
          options={categoriesForm.options}
          maxSelectedLabels={1}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={form}
        onSubmit={(values, { setSubmitting }) => {
          updateEditState()

          setInitialCategories(selectedCategories)
          const categories =
            selectedCategories.length > 0 ? selectedCategories : ["General Liability"]
          const status = "pending"
          const valuesToSend = {
            ...values,
            categories,
            status,
          }

          _.isNil(values.certificateId)
            ? dispatch(uploadInsuranceCertificate({ values: valuesToSend }))
            : dispatch(updateInsuranceCertificate({ values: valuesToSend }))

          setSubmitting(false)
        }}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <FormikBootstrap.TextInput name="insurer" label="Insurer" required />
                  <div className="mt-2" />
                  <FormikBootstrap.TextInput name="brokerName" label="Broker Name" required />
                  <div className="mt-2" />
                  <FormikBootstrap.TextInput name="policyNumber" label="Policy Number" required />
                  <div className="mt-2" />
                  <FormikBootstrap.NumberInput
                    name="minimumCoverage"
                    label={`Minimum Coverage (${_view.value.profile.currency})`}
                    minimum={minimumCoverageRequirement}
                    required
                  />
                  <div className="mt-2" />
                  <FormikBootstrap.NumberInput
                    name="deductible"
                    label={`Deductible (${_view.value.profile.currency})`}
                    required
                  />
                  <div className="mt-2" />
                  <FormikBootstrap.TextInput
                    name="subcontractor"
                    label="Subcontractor (Optional)"
                  />
                </div>
                <div className="col">
                  <FormikBootstrap.Select name="currencyCode" label="Currency Code" required>
                    <option value="">Select Currency</option>
                    {form.currencyForm.map((currency) => (
                      <option key={currency.value} value={currency.value}>
                        {currency.label}
                      </option>
                    ))}
                  </FormikBootstrap.Select>
                  <div className="mt-2" />
                  <FormikBootstrap.DatePicker name="effectiveDate" label="Effective Date" />
                  <div className="mt-2" />
                  <FormikBootstrap.DatePicker name="expirationDate" label="Expiration Date" />
                  <div className="mt-2" />
                  <FormikBootstrap.TextInput name="jurisdiction" label="Jurisdiction" required />
                  <div className="mt-2" />
                  <FormikBootstrap.FileInput
                    name="fileSrc"
                    label={
                      _.isNull(certificateId) ? "Upload certificate" : "Upload new certificate"
                    }
                    // required field if not yet source id but still empty field object, i.e. no file uploaded yet
                    required={!(typeof fileSrc === "string" || fileSrc instanceof String)}
                  />
                  <small className="text-muted">
                    *Your name must match the name on the certificate
                  </small>
                  <div className="mt-3" />

                  <small className="d-inline">
                    <strong>
                      By submitting this form, you confirm that the information provided is true,
                      accurate and that you have the authority to share it.
                    </strong>
                  </small>
                  <div key="check" className="d-inline-block ms-1">
                    <span className="fa fa-check-circle text-success" />
                  </div>

                  {!_.isEmpty(fileSrc) ? (
                    <div className="d-flex justify-content-end mt-5">
                      <Button onClick={onCancel} className="btn-secondary">
                        <i className="fas fa-times me-2" />
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn-primary ms-2"
                        disabled={(!dirty && !categoriesUpdated) || isSubmitting}
                      >
                        <i className="fas fa-save me-2" />
                        Save
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        type="submit"
                        className="btn-primary"
                        disabled={(!dirty && !categoriesUpdated) || isSubmitting}
                      >
                        <i className="fas fa-save me-2" />
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditUpload
