import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getPriceView } from "@/onboarding/features/price_analysis/selectors"
import { selectView, ViewTypes } from "@/onboarding/features/price_analysis/Slice"

const PriceViewDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const selectedView = useSelector(getPriceView)

  return (
    <Form.Group>
      <Form.Label>Cost View</Form.Label>
      <Form.Control
        as="select"
        value={selectedView}
        onChange={({ target }) => dispatch(selectView({ selectedView: target.value }))}
      >
        <option value={ViewTypes.BREAKDOWN_VARIANCE}>Pax Type Variances</option>
        <option value={ViewTypes.BREAKDOWN}>Pax Types</option>
        <option value={ViewTypes.AGGREGATE_VARIANCE}>Total Cost Variances</option>
        <option value={ViewTypes.AGGREGATE}>Total Costs</option>
      </Form.Control>
    </Form.Group>
  )
}

export default PriceViewDropdown
