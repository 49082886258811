import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { Formik } from "formik"

import FormikBootstrap from "@/components/formik-bootstrap"
import { addContractPeriod } from "@/onboarding/features/product/Slice"

const DESCRIPTION =
  "This will create a new contract period with the selected start date and run until the end of the season. All rate adjustments made in that period will need to be approved by both parties."

const AddContractPeriodModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="add-contract-period-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="add-contract-period-modal-title">Adjust Rates</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <Formik
          initialValues={{ startDate: "2023-12-12" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            dispatch(addContractPeriod(values))
            setSubmitting(false)
            onHide()
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <FormikBootstrap.DatePicker name="startDate" />
              <div>{DESCRIPTION}</div>
              <div className="d-flex gap-3">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button variant="danger" onClick={onHide} disabled={isSubmitting}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddContractPeriodModal
