import React from "react"
import { Card } from "react-bootstrap"
import { useSelector, connect } from "react-redux"

import Voyage from "@/dashboard/models/Voyage"
import { DailySale } from "@/dashboard/models/DailySale"
import {
  getFilteredTours,
  getFilteredPrivateCarBookings,
  getPrivateVillas,
} from "@/dashboard/selectors"

import { IfFeatureOn } from "@/helpers/Flipflop"
import Tour from "@/dashboard/models/Tour"
import ErrorBoundary from "@/components/ErrorBoundary"

import PrivateCarBookingsCard from "./PrivateCarBookingsCard"
import PrivateVillasCard from "./PrivateVillasCard"
import TourListCard from "./TourListCard"
import LatestToursCard from "./LatestToursCard"

const DashboardCard = ({
  date,
  firstMeeting,
  voyage,
  dailySale,
  latestTours,
}: {
  date: string
  firstMeeting: string
  voyage: Voyage
  dailySale: DailySale
  latestTours: Array<Tour>
}): JSX.Element => {
  const tours = useSelector(getFilteredTours)
  const privateCarBookings = useSelector(getFilteredPrivateCarBookings)
  return (
    <ErrorBoundary>
      <LatestToursCard tours={latestTours} />
      <Card border="light" className="shadow-sm">
        <TourListCard
          date={date}
          firstMeeting={firstMeeting}
          voyage={voyage}
          dailySale={dailySale}
          tours={tours}
        />
        <IfFeatureOn feature="privateVillas">
          <ConnectedPrivateVillasCard />
        </IfFeatureOn>
        <IfFeatureOn feature="privateCars">
          <PrivateCarBookingsCard bookings={privateCarBookings} />
        </IfFeatureOn>
      </Card>
    </ErrorBoundary>
  )
}

const ConnectedPrivateVillasCard = connect((state) => ({ privateVillas: getPrivateVillas(state) }))(
  PrivateVillasCard
)

export default DashboardCard
