import React from "react"
import { Button, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { pushConfirmations } from "@/settlement/Slice"
import { getSettlement } from "@/settlement/selectors"

const CommitButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const { submitting, pendingConfirmedIds } = useSelector(getSettlement)

  if (submitting) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  return (
    <Button
      variant="success"
      onClick={() => dispatch(pushConfirmations({ confirmedIds: pendingConfirmedIds }))}
    >
      Save Changes
    </Button>
  )
}

export default CommitButton
