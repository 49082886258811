import Pricing from "@/pricing"

import { ReferencedValueTypes, ReservedFields } from "./Product"

export enum ComponentType {
  SIMPLE = "simple",
  EXTRA_FEE = "extra_fee",
  TRANSPORT = "transport",
  VENUE = "venue",
  REFRESHMENT = "refreshment",
  RESTAURANT = "restaurant",
  GUIDE = "guide",
  HEADSET = "headset",
  OVERHEAD = "overhead",
  STOP = "tour_stop",
  IMAGE = "image",
  VEHICLE = "vehicle",
  ENTERTAINMENT = "entertainment",
  ATTACHMENT = "attachment",
}

interface ComponentConfig {
  kind: ComponentType
  defaultRateCategories: Array<string>
}

interface BaseComponent {
  kind: ComponentType
  name: string
  description: string
}

const newSingleReferencedValue = (type: ReferencedValueTypes) => ({
  type,
  multiple: false,
  value: null,
})

const newSimpleComponent = (config: ComponentConfig) => ({
  kind: ComponentType.SIMPLE,
  name: "",
  description: "",
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newTransportComponent = (config: ComponentConfig) => ({
  kind: ComponentType.TRANSPORT,
  name: "",
  description: "",
  transportType: newSingleReferencedValue(ReferencedValueTypes.VEHICLE_TYPE),
  availableVehicleCount: 1,
  maximumCapacityPerVehicle: 10,
  restroomsAvailable: false,
  transportCompanyName: "",
  wheelchairAccessible: false,
  motorizedWheelchairAccessible: false,
  wheelchairLiftAvailable: false,
  airConditioned: true,
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newVenueComponent = (config: ComponentConfig) => ({
  kind: ComponentType.VENUE,
  name: "",
  description: "",
  steps: 0,
  distance_from_parking: "",
  wheelchairAccessible: false,
  restroomsAvailable: true,
  venueType: newSingleReferencedValue(ReferencedValueTypes.VENUE_TYPE),
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newRefreshmentComponent = (config: ComponentConfig) => ({
  kind: ComponentType.REFRESHMENT,
  name: "",
  description: "",
  refreshmentType: newSingleReferencedValue(ReferencedValueTypes.REFRESHMENT_TYPE),
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newRestaurantComponent = (config: ComponentConfig) => ({
  kind: ComponentType.RESTAURANT,
  name: "",
  description: "",
  capacity: 100,
  airConditioned: false,
  waiterService: true,
  buffetService: false,
  specialDietaryMeals: false,
  vegetarianMeals: false,
  wheelchairAccessible: true,
  rampsAvailable: false,
  liftsAvailable: false,
  restroomsAvailable: true,
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newEntertainmentComponent = (config: ComponentConfig) => ({
  kind: ComponentType.ENTERTAINMENT,
  name: "",
  description: "",
  entertainmentType: newSingleReferencedValue(ReferencedValueTypes.ENTERTAINMENT_TYPE),
  website: "",
  duration: 0,
  capacity: 0,
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newGuideComponent = (config: ComponentConfig) => ({
  kind: ComponentType.GUIDE,
  name: "",
  description: "",
  guideType: newSingleReferencedValue(ReferencedValueTypes.GUIDE_TYPE),
  licensedGuide: false,
  stepOnGuide: false,
  pierToPier: false,
  notes: "",
  languages: "",
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newHeadsetComponent = (config: ComponentConfig) => ({
  kind: ComponentType.HEADSET,
  name: "",
  description: "",
  headsetProvided: false,
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

const newImage = (_config: ComponentConfig) => ({
  kind: ComponentType.IMAGE,
  // This will be converted to a string of the FileBoxId on the server
  imageSrc: null,
  name: "",
  description: "",
  promotional: "false",
})

const newAttachment = (_config: ComponentConfig) => ({
  kind: ComponentType.ATTACHMENT,
  // This will be converted to a string of the FileBoxId on the server
  fileSrc: null,
  name: "",
  description: "",
})

export const newVehicle = (_config: ComponentConfig) => ({
  kind: ComponentType.VEHICLE,
  name: "",
  quantity: 0,
  maxCapacity: 0,
  allowedCapacity: 0,
})

const newStopComponent = (_config: ComponentConfig) => ({
  kind: ComponentType.STOP,
  name: "",
  time: "00:00",
  duration: 0,
  description: "",
  stopType: newSingleReferencedValue(ReferencedValueTypes.STOP_TYPE),
  transferType: newSingleReferencedValue(ReferencedValueTypes.VEHICLE_TYPE),
  steps: 0,
  terrain: "",
  bathroomsAvailable: false,
  components: [],
})

const newExtraFeeComponent = (config: ComponentConfig) => ({
  kind: ComponentType.EXTRA_FEE,
  name: "",
  category: "",
  payableByGuest: false,
  ratesheet: Pricing.newRatesheet({
    kind: Pricing.types.FlatRatesheet,
    defaultRateCategories: config.defaultRateCategories,
  }),
})

export const newComponent = (config: ComponentConfig) => {
  switch (config.kind) {
    case ComponentType.SIMPLE:
    case ComponentType.OVERHEAD:
      return newSimpleComponent(config)
    case ComponentType.TRANSPORT:
      return newTransportComponent(config)
    case ComponentType.VENUE:
      return newVenueComponent(config)
    case ComponentType.REFRESHMENT:
      return newRefreshmentComponent(config)
    case ComponentType.RESTAURANT:
      return newRestaurantComponent(config)
    case ComponentType.ENTERTAINMENT:
      return newEntertainmentComponent(config)
    case ComponentType.GUIDE:
      return newGuideComponent(config)
    case ComponentType.HEADSET:
      return newHeadsetComponent(config)
    case ComponentType.STOP:
      return newStopComponent(config)
    case ComponentType.IMAGE:
      return newImage(config)
    case ComponentType.ATTACHMENT:
      return newAttachment(config)
    case ComponentType.VEHICLE:
      return newVehicle(config)
    case ComponentType.EXTRA_FEE:
      return newExtraFeeComponent(config)
    default:
      throw new Error(`Undefined for kind: ${config.kind}`)
  }
}

export const getComponentFieldName = (componentType: ComponentType): ReservedFields => {
  switch (componentType) {
    case ComponentType.SIMPLE:
      return ReservedFields.VENUE_COMPONENTS
    case ComponentType.TRANSPORT:
      return ReservedFields.LOGISTICAL_COMPONENTS
    case ComponentType.VENUE:
      return ReservedFields.VENUE_COMPONENTS
    case ComponentType.REFRESHMENT:
      return ReservedFields.REFRESHMENT_COMPONENTS
    case ComponentType.RESTAURANT:
      return ReservedFields.RESTAURANT_COMPONENTS
    case ComponentType.ENTERTAINMENT:
      return ReservedFields.ENTERTAINMENT_COMPONENTS
    case ComponentType.GUIDE:
      return ReservedFields.GUIDE_COMPONENTS
    case ComponentType.HEADSET:
      return ReservedFields.HEADSET_COMPONENTS
    default:
      throw new Error(`Unknown component type: ${componentType}`)
  }
}

export const getComponentType = (fieldName: ReservedFields): ComponentType => {
  switch (fieldName) {
    case ReservedFields.LOGISTICAL_COMPONENTS:
      return ComponentType.TRANSPORT
    case ReservedFields.VENUE_COMPONENTS:
      return ComponentType.VENUE
    case ReservedFields.REFRESHMENT_COMPONENTS:
      return ComponentType.REFRESHMENT
    case ReservedFields.RESTAURANT_COMPONENTS:
      return ComponentType.RESTAURANT
    case ReservedFields.ENTERTAINMENT_COMPONENTS:
      return ComponentType.ENTERTAINMENT
    case ReservedFields.GUIDE_COMPONENTS:
      return ComponentType.GUIDE
    case ReservedFields.HEADSET_COMPONENTS:
      return ComponentType.HEADSET
    default:
      throw new Error(`Unknown field: ${fieldName}`)
  }
}

export default BaseComponent
