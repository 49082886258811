import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

export const buildReduxUseField = (formSelector, filterAction) => (name) => {
  const dispatch = useDispatch()

  const form = useSelector(formSelector)

  const onChange = (e) => {
    const updatedForm = JSON.parse(JSON.stringify(form))

    updatedForm[name].value = e.target.value

    dispatch(filterAction(updatedForm))
  }

  return _.merge({}, form[name], { name, onChange })
}
