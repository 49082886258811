import React from "react"
import { Spinner } from "react-bootstrap"

import { LoadState } from "@/helpers/useDelayedState"

const Loading = () => <Spinner className="d-block" animation="border" />

const Failure = ({ onRetry, errorMessage = "There was a problem reaching the server" }) => {
  if (onRetry) {
    return (
      <div className="d-block">
        <i>{errorMessage}</i>
        <i className="fs-3 bi bi-arrow-counterclockwise align-middle" onClick={onRetry} />
      </div>
    )
  }

  return <i className="d-block">{errorMessage}</i>
}

const DelayedComponent = ({
  onRetry,
  state,
  forwarededRef,
  loadingComponent = Loading,
  failureComponent = Failure,
  errorMessage = "There was a problem reaching the server",
  children = null,
}): JSX.Element => {
  const LoadingComponent = loadingComponent
  const FailureComponent = failureComponent

  switch (state) {
    // TODO can we abstract the idea of an unloaded / loadable component?
    case LoadState.Loading: {
      return <LoadingComponent />
    }
    case LoadState.Stale: {
      return <LoadingComponent />
    }
    case LoadState.Failed: {
      return <FailureComponent onRetry={onRetry} errorMessage={errorMessage} />
    }
    case LoadState.Ready: {
      return children
    }
    default: {
      throw new Error(`State ${state} is not supported`)
    }
  }
}

export default DelayedComponent
