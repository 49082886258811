import { useEffect, useState } from "react"
import { useFormikContext } from "formik"

/**
 * Transparently performs form validation inbetween any form changes,
 * and invokes the setValidity hook function with a boolean indicating
 * the validity of the form as a whole.
 */
const FormikFormValidityTracker = ({ setValidity }) => {
  const { values, validateForm } = useFormikContext()

  const [isValidating, setIsValidating] = useState(false)

  useEffect(() => {
    if (!isValidating) {
      setIsValidating(true)

      validateForm()
        .then((errors) => {
          const isValid = _.isEmpty(errors)

          setValidity(isValid)
        })
        .finally(() => {
          setIsValidating(false)
        })
    }
  }, [values])

  return null
}

export default FormikFormValidityTracker
