import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import { tour_booking_tickets_path } from "@/routes"
import Booking from "@/dashboard/models/Booking"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"

const BookingDocuments = ({ booking }: { booking: Booking }) => (
  <CruiseLineOnly>
    <DropdownButton size="sm" title="Documents" boundary="viewport">
      <Dropdown.Item>Invoice</Dropdown.Item>
      <Dropdown.Item href={tour_booking_tickets_path({ tour_booking_id: booking.bookingId })}>
        Tickets
      </Dropdown.Item>
    </DropdownButton>
  </CruiseLineOnly>
)

export default BookingDocuments
