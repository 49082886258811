import { isLoading } from "@/helpers/AsyncField"
import { isBlank } from "@/utils"

export const getInventoryAllocation = ({ inventoryAllocation }) => inventoryAllocation

export const getView = (state) => getInventoryAllocation(state)._view.value

export const getForm = (state) => getView(state).form

export const getInventory = (state) => getView(state).inventory

export const getIssueCount = (state) => getView(state).inventory.issueCount

export const getAllocationTableData = (state) => getView(state).inventory.allocationTableData

export const isViewLoading = (state) => isLoading(getInventoryAllocation(state)._view)

export const getConstraintsEditStates = (state) =>
  getInventoryAllocation(state).constraintsEditStates

export const getCallDaysPreEdit = (state) => getView(state).inventory.callDaysPreEdit.callDays

export const getDayExpansionStates = (state) => getInventoryAllocation(state).dayExpansionStates

export const getIsMakingRequestStates = (state) =>
  getInventoryAllocation(state).isMakingRequestStates

export const getBulkExpansionStates = (state) => getInventoryAllocation(state).bulkExpansionStates

export const getRowEditStates = (state) => getInventoryAllocation(state).rowEditStates

export const getUnlockedDates = (state) =>
  getView(state).inventory.allocationTableData.unlockedDates

export const getDatesWithCruiseRequests = (state) =>
  getView(state).inventory.allocationTableData.datesWithCruiseRequests

export const getDatesWithOperatorRequests = (state) =>
  getView(state).inventory.allocationTableData.datesWithOperatorRequests

export const getAllDates = (state) => getView(state).inventory.allocationTableData.allDates

export const getTour = (state) => getView(state).inventory.tour

export const getShip = (state) => getView(state).inventory.ship

export const getDepartureRequestStates = (state) =>
  getInventoryAllocation(state).departuresRequestStates

export const getCruiseDeparturesEditStates = (state) =>
  getInventoryAllocation(state).cruiseDeparturesEditState

export const getCallDayConstraints = (state) => getView(state).inventory.callDayConstraints

export const getNewCallDayConstraints = (state) => getView(state).inventory.newCallDayConstraints

export const canSubmit = (state) => {
  const editStates = getConstraintsEditStates(state)
  const isInEditState = Object.values(editStates).some((value) => value === true)
  const hasNewConstraints = !isBlank(getNewCallDayConstraints(state))
  return isInEditState || hasNewConstraints
}

export const getShowBulkEditForm = (state) => getInventoryAllocation(state).showBulkEditForm

export const getBulkDepartures = (state) => getInventoryAllocation(state).bulkDepartures

export const getbulkDepartureEdit = (state) => getInventoryAllocation(state).bulkDepartureEdit

export const getSelectedProperties = (state) => getInventoryAllocation(state).selectedProperties

export const isDisplaySales = (state) => getInventoryAllocation(state).displaySales
