import React from "react"

import { withApp } from "@/components/App"
import Slice from "@/operator_check_in/Slice"

import OperatorCheckInCard from "./OperatorCheckInCard"
import CheckinsScanner from "./CheckinsScanner"

const App = (props): JSX.Element => (
  <>
    <OperatorCheckInCard {...props} />
    <CheckinsScanner />
  </>
)

export default withApp(App, { slices: [Slice] })
