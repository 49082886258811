import React, { useState } from "react"

import BootstrapSearchInput from "./BootstrapSearchInput"

const SimpleSearchInput = ({
  id,
  results,
  isLoading,
  onSearch,
  onSelect,
  placeholder,
  resultComponent,
  minLength = 1,
  labelKey = "label",
}) => {
  const [typeAheadRef, setTypeAheadRef] = useState(null)

  // TODO Remove the mb-1 here and fix the spacing issues higher up in the stack
  return (
    <BootstrapSearchInput
      className="mb-1"
      placeholder={placeholder}
      onTypeAheadRef={(ref) => {
        setTypeAheadRef(ref)
      }}
      onSearch={onSearch}
      onChange={(e) => {
        onSelect(e, typeAheadRef)
      }}
      isLoading={isLoading}
      options={results}
      labelKey={labelKey}
      id={id}
      filterBy={() => true}
      minLength={minLength}
      renderMenuItemChildren={(result, props) => resultComponent({ result, ...props })}
    />
  )
}

export default SimpleSearchInput
