import React, { useEffect, useState } from "react"
import { Col, Button, Form, Table } from "react-bootstrap"
import { useDispatch } from "react-redux"

import TrashButton from "@/components/buttons/TrashButton"

import { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import RegexSearchInput from "@/components/RegexSearchInput"
import useItemContainer from "@/helpers/useItemContainer"
import { getForm, getPorts } from "../selectors"

const CurrentPortsTable = ({ onRemove, ports, readOnly }): JSX.Element => (
  <div>
    <h3 className="h6">Current Ports</h3>
    <Table className="mb-3">
      <thead>
        <tr>
          <th> Port </th>
          <th> Country </th>
          <th> Common Region </th>
          <th> CL Region </th>
          {!readOnly && <th> </th>}
        </tr>
      </thead>
      <tbody>
        {ports.length == 0 && (
          <tr>
            <td colSpan="4">
              <i> Not operating in any ports </i>
            </td>
          </tr>
        )}
        {_.map(ports, (port) => (
          <tr key={port.id}>
            <td>{port.name}</td>
            <td>{port.countryName}</td>
            <td>{port.regionName}</td>
            <td>{port.biddingRegionsName}</td>
            {!readOnly && (
              <td>
                <TrashButton onClick={() => onRemove(port)} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
)

const PortsTable = ({ ports, selectedPorts, onTogglePort, onAddPorts }) => {
  // We render ports that were selected at the top event if they are
  // not in the ports list
  const addPortsClass = selectedPorts.length > 0 ? "" : "invisible"

  const [query, setQuery] = useState("")

  const [filteredPorts, setFilteredPorts] = useState(ports)

  const portFilterOn = (p) => [p.name, p.countryName]

  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between">
        <h3 className="h6">Add Additional Ports</h3>
        <Button
          onClick={() => onAddPorts(selectedPorts)}
          variant="outline-primary"
          className={`${addPortsClass} float-end`}
        >
          Add Selected Ports
        </Button>
      </div>
      <div className="col-md-5">
        <RegexSearchInput
          label="Search by port & country"
          items={ports}
          id="port-filter"
          onFiltered={setFilteredPorts}
          filterOn={portFilterOn}
        />
      </div>
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th> Port </th>
            <th> Country </th>
            <th> Region </th>
            <th> CL Region </th>
          </tr>
        </thead>
        <tbody>
          {ports.length == 0 && (
            <tr>
              <td colSpan="4">
                <i> No Ports Found </i>
              </td>
            </tr>
          )}
          {_.map(filteredPorts, (port) => (
            <tr key={port.id}>
              <td>
                <Form.Check
                  id={port.id}
                  type="checkbox"
                  value={port.id}
                  defaultChecked={_.includes(_.map(selectedPorts, "id"), port.id)}
                  onChange={() => onTogglePort(port)}
                />
              </td>
              <td> {port.name} </td>
              <td> {port.countryName} </td>
              <td> {port.regionName} </td>
              <td>{port.biddingRegionsName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const PortDetailsForm = (props): JSX.Element => {
  const dispatch = useDispatch()

  const { updatePortDetailsForm, updateOperatorDetails } = useSliceActions()

  const { submitting, operator, portDetailsForm } = useSliceSelector(getForm)

  const formGroupOpts = {
    md: "12",
    lg: "5",
    as: Col,
    className: "mb-2",
  }

  const ports = useSliceSelector(getPorts)

  const [currentPorts, currentPortFns] = useItemContainer(
    operator.operatingPorts,
    true,
    (updatedItems) => {
      dispatch(updateOperatorDetails({ values: { operatingPorts: updatedItems } }))
    }
  )

  const [selectedPorts, selectedPortsFns] = useItemContainer(
    portDetailsForm.selectedPorts,
    true,
    (updatedItems) => {
      dispatch(updatePortDetailsForm({ selectedPorts: updatedItems }))
    }
  )

  useEffect(() => {
    props.setValidity(currentPorts.length > 0)
  }, [currentPorts])

  const addPortsToCurrent = (ports) => {
    currentPortFns.addAll(ports)
    selectedPortsFns.removeAll(ports)
  }

  const portsForSelection = _.reject(ports, (p) => currentPortFns.includes(p))

  return (
    <div>
      <h2 className="h5">{props.title}</h2>

      <p>Ports where your business currently has capabilities</p>

      <i>
        Note that this information will be updated globally (for all cruise lines) if edited here
      </i>
      <hr />
      <CurrentPortsTable
        ports={currentPorts}
        onRemove={currentPortFns.remove}
        readOnly={props.context.readOnly}
      />
      {!props.context.readOnly && (
        <PortsTable
          ports={portsForSelection}
          selectedPorts={selectedPorts}
          onTogglePort={selectedPortsFns.toggle}
          onAddPorts={addPortsToCurrent}
        />
      )}
      <div className="d-flex gap-2">{props.formNavigation}</div>
    </div>
  )
}

export default PortDetailsForm
