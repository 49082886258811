import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateGroupByBrand } from "@/onboarding/features/price_analysis/Slice"
import { isGroupByBrand } from "@/onboarding/features/price_analysis/selectors"

const GroupingSelector = (): JSX.Element => {
  const dispatch = useDispatch()
  const groupByBrand = useSelector(isGroupByBrand)

  const onClick = () => dispatch(updateGroupByBrand({ groupByBrand: !groupByBrand }))

  return (
    <Form.Check
      className="mt-1 form-check form-switch"
      type="checkbox"
      checked={groupByBrand}
      label="Group by Cruise Brand"
      onChange={onClick}
    />
  )
}

export default GroupingSelector
