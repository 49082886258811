import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { UserCircle } from "@/components/Icons"

import { assignOperator, unassignOperator } from "@/onboarding/features/price_analysis/Slice"
import {
  getProductAwardedOperator,
  getSelectedProspectiveAwardSet,
} from "@/onboarding/features/price_analysis/selectors"

const OperatorSelector = ({ product, operator }): JSX.Element => {
  const dispatch = useDispatch()
  const awardedOperatorId = useSelector(getProductAwardedOperator(product.id))
  const awardSet = useSelector(getSelectedProspectiveAwardSet)
  const selected = awardedOperatorId === operator.id

  const onClick = selected
    ? () =>
        dispatch(
          unassignOperator({
            awardSetId: awardSet.id,
            productId: product.id,
            operatorId: operator.id,
          })
        )
    : () =>
        dispatch(
          assignOperator({
            awardSetId: awardSet.id,
            productId: product.id,
            operatorId: operator.id,
          })
        )

  return (
    <div className="d-flex align-items-center">
      <Form.Check type="checkbox" checked={selected} label={operator.name} onChange={onClick} />
      {product.incumbentOperator && product.incumbentOperator.id === operator.id && (
        <UserCircle
          className="ms-2 mt-n2 cursor-pointer"
          data-toggle="tooltip"
          data-placement="top"
          title="Incumbent Operator"
        />
      )}
    </div>
  )
}

export default OperatorSelector
