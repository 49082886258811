import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getSectionFieldNames } from "@/onboarding/features/product/selectors"
import FieldTable from "@/onboarding/features/product/components/shared/FieldTable"

const Details: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.tourLocation)

  return <FieldTable fieldNames={fieldNames} />
}

export default Details
