import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getProduct } from "@/onboarding/features/product/selectors"
import Select from "@/components/input/Select"

const Dropdown: FC = () => {
  const { biddingRegion } = useSelector(getProduct)

  return (
    <Select label="Region">
      <option value={biddingRegion.id}>{biddingRegion.name}</option>
    </Select>
  )
}

export default Dropdown
