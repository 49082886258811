import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import Tour, { ArrangementsStates } from "@/dashboard/models/Tour"
import { PostDropdownItem } from "@/components/post_links"
import {
  edit_tour_path,
  accept_arrangements_tour_path,
  tour_tickets_path,
  tour_change_letters_path,
  settlement_path,
  cleared_to_leave_tour_path,
  departed_tour_path,
  operator_check_in_path,
  tour_guest_complaints_path,
} from "@/routes"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { productTypeText, isPrivateVilla } from "@/product_api"
import Hidden from "@/components/Hidden"

import NestedDropdown from "./NestedDropdown"

const TourToolsDropdown = ({
  tour,
  onDuplicateTour,
  onCancelAllBookings,
  onCancelTour,
  onEditDepartureTime,
  onMessageHQ,
  onMessageTourOperator,
  onMessageBridge,
  onMessageShip,
  onMessageEventCoordinator,
  onEditStickers,
}: {
  tour: Tour
  onDuplicateTour: () => void
  onCancelAllBookings: () => void
  onCancelTour: () => void
  onEditDepartureTime: () => void
  onMessageHQ: () => void
  onMessageTourOperator: () => void
  onMessageBridge: () => void
  onMessageShip: () => void
  onMessageEventCoordinator: () => void
  onEditStickers: () => void
}): JSX.Element => (
  <DropdownButton size="sm" title="Tools">
    <NestedDropdown title="Operations">
      <CruiseLineOnly>
        <Dropdown.Item href={tour_tickets_path({ tour_id: tour.tourId })}>
          Ticket Set-up
        </Dropdown.Item>
        <Dropdown.Item>Manifest</Dropdown.Item>
        <Dropdown.Item onClick={onEditStickers}>Edit Stickers</Dropdown.Item>
      </CruiseLineOnly>
      <Dropdown.Item href={operator_check_in_path({ id: tour.tourId })}>Check-In</Dropdown.Item>
      <CruiseLineOnly>
        <Hidden if={isPrivateVilla(tour)}>
          <PostDropdownItem
            url={cleared_to_leave_tour_path({ id: tour.tourId })}
            linkText={`${productTypeText(tour)} Cleared to Leave`}
          />
          <PostDropdownItem
            url={departed_tour_path({ id: tour.tourId })}
            linkText={`${productTypeText(tour)} Departed`}
          />
        </Hidden>
        <Dropdown.Item onClick={onCancelTour}>{`Cancel ${productTypeText(tour)}`}</Dropdown.Item>
        <Dropdown.Item onClick={onCancelAllBookings}>Cancel All Bookings</Dropdown.Item>
      </CruiseLineOnly>
      <Dropdown.Item href={settlement_path({ id: tour.tourId })}>Settlement</Dropdown.Item>
    </NestedDropdown>
    <NestedDropdown title="Messaging">
      <CruiseLineOnly>
        <Dropdown.Item onClick={onMessageHQ}>Message HQ</Dropdown.Item>
        <Hidden if={tour.selfOperated}>
          <Dropdown.Item onClick={onMessageTourOperator}>
            Message {productTypeText(tour)} Operator
          </Dropdown.Item>
        </Hidden>
        <Dropdown.Item onClick={onMessageBridge}>Message Bridge</Dropdown.Item>
        {
          // <Dropdown.Item>Message Guest</Dropdown.Item>
        }
      </CruiseLineOnly>
      <OperatorOnly>
        <Dropdown.Item onClick={onMessageShip}>Message Ship</Dropdown.Item>
      </OperatorOnly>
    </NestedDropdown>
    <CruiseLineOnly>
      <NestedDropdown title="CRM">
        <Dropdown.Item
          href={tour_change_letters_path({ id: tour.tourId, cancel_letter: true, _options: true })}
        >
          Cancel Letter
        </Dropdown.Item>
        <Dropdown.Item href={tour_change_letters_path({ id: tour.tourId })}>
          Change Letter
        </Dropdown.Item>
        <Dropdown.Item href={tour_guest_complaints_path({ tour_id: tour.tourId, _options: true })}>
          Guest Complaints
        </Dropdown.Item>
      </NestedDropdown>
    </CruiseLineOnly>
    <NestedDropdown title={`${productTypeText(tour)} Arrangements`}>
      <Hidden if={isPrivateVilla(tour)}>
        <Dropdown.Item onClick={onDuplicateTour}>
          {`Add ${productTypeText(tour)} Departure`}
        </Dropdown.Item>
      </Hidden>
      <CruiseLineOnly>
        <Hidden if={tour.selfOperated}>
          <Dropdown.Item onClick={onMessageTourOperator}>
            Request to Confirm Arrangements
          </Dropdown.Item>
        </Hidden>
      </CruiseLineOnly>
      <PostDropdownItem
        hidden={tour.arrangementsState === ArrangementsStates.CONFIRMED}
        url={accept_arrangements_tour_path({ id: tour.tourId })}
        linkText={`Confirm ${productTypeText(tour)} Arrangements`}
      />
      <Dropdown.Item href={edit_tour_path({ id: tour.tourId })}>
        Edit {productTypeText(tour)} Arrangements
      </Dropdown.Item>
      <CruiseLineOnly>
        <Hidden if={isPrivateVilla(tour)}>
          {
            // <Dropdown.Item>Request to New (Extra) Departure Time</Dropdown.Item>
          }
          <Dropdown.Item onClick={onEditDepartureTime}>Move Bookings</Dropdown.Item>
        </Hidden>
      </CruiseLineOnly>
    </NestedDropdown>
  </DropdownButton>
)

export default TourToolsDropdown
