import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { v4 as uuid } from "uuid"

import { manual_booking_path, dashboard_path } from "@/routes"
import Api from "@/api"
import OnScreenNotifications from "@/osn"
import * as AsyncField from "@/helpers/AsyncField"
import { selectRoomResult as tourBookingsSelectRoomResult } from "@/manual_booking/features/tour_bookings/Slice"
import { getFormSubmissionPayload, ValidationError } from "@/manual_booking/selectors"
import { fetchTimeline as fetchVoyageTimeline } from "@/manual_booking/VoyageTimelineActions"
import { addCouponToBookings } from "@/manual_booking/reservationSystems"

import { getChargedRoomAccount } from "./selectors"

const fetchChargedRoomAccountRooms = AsyncField.createLoadThunk(
  "manualBooking/fetchChargedRoomAccountRooms",
  () => "_chargedRoomAccountSearch",
  async ({ q }, thunkAPI) => {
    const { bookings } = getFormSubmissionPayload(thunkAPI.getState())
    const tourIds = _(bookings).map("tourId").compact().value()

    const response = await Api.manualBooking.fetchRooms(q, { tourIds })

    return { value: response.data.data.rooms }
  }
)

const fetchApplicableCoupons = AsyncField.createLoadThunk(
  "manualBooking/fetchApplicableCoupons",
  () => "_applicableCouponsSearch",
  async ({ q }, thunkAPI) => {
    const { payer } = getChargedRoomAccount(thunkAPI.getState())

    const response = await Api.manualBooking.fetchCoupons(q, payer.id)

    return { value: response.data.data.coupons }
  }
)

const selectApplicableCoupon = createAsyncThunk(
  "manualBooking/selectApplicableCoupon",
  async ({ selectedCoupon }, thunkAPI) => {
    addCouponToBookings(thunkAPI.dispatch, thunkAPI.getState(), selectedCoupon.code)

    return { selectedCoupon }
  }
)

const submitForm =
  (actionPayload, isGuest = false, id = "") =>
  async (dispatch, getState) => {
    let payload = null

    const windowLocation = isGuest ? manual_booking_path({ id }) : dashboard_path()

    try {
      payload = getFormSubmissionPayload(getState())
    } catch (err) {
      if (err instanceof ValidationError) {
        dispatch(OnScreenNotifications.warning(err.message))
        return
      }

      throw err
    }

    const action = getState().manualBooking.submitAction
    const actionExtras = actionPayload || { actionExtras: {} }

    return Api.manualBooking
      .submitForm(action.url, action.method, _.merge({}, payload, actionExtras))
      .then(() => {
        window.location = windowLocation
      }, OnScreenNotifications.newAxiosErrorHandler(dispatch))
  }

export const Slice = createSlice({
  name: "manualBooking",
  initialState: {
    initialized: false,
    submitAction: {},
    fields: {},
    chargedRoomAccount: null,
    selectedCoupon: null,
    _applicableCouponsSearch: AsyncField.createField({ defaultValue: [] }),
    voyageTimeline: {
      fields: {},
    },
    _chargedRoomAccountSearch: AsyncField.createField({ defaultValue: [] }),
  },
  reducers: {
    initializeForm: (state, action) => {
      const payload = action.payload || {}

      state.fields = payload.fields || {}

      if (!payload.submitAction) {
        throw new Error("submitAction is a required argument")
      }

      state.submitAction = payload.submitAction
      state.chargedRoomAccount = payload.chargedRoomAccount || null
      state.selectedCoupon = payload.selectedCoupon || null

      state.initialized = true

      return state
    },
    selectChargedRoomAccount: (state, { payload }) =>
      _.merge(state, { chargedRoomAccount: payload.chargedRoomAccount }),
    clearChargedRoomAccount: (state) => _.merge(state, { chargedRoomAccount: null }),
    loadVoyageDate: (state, { payload }) => {
      state.voyageTimeline.fields[uuid()] = AsyncField.createField({
        defaultValue: payload.date,
      })
      return state
    },
  },
  extraReducers: {
    // NOTE Reducer based on foreign action
    [tourBookingsSelectRoomResult.fulfilled]: (state, action) => {
      // set default room account to charge
      if (!state.chargedRoomAccount) {
        state.chargedRoomAccount = action.payload.chargedRoomAccount
      }
      return state
    },
    [selectApplicableCoupon.fulfilled]: (state, { payload }) =>
      _.merge(state, { selectedCoupon: payload.selectedCoupon }),
    ...fetchChargedRoomAccountRooms.reducers,
    ...fetchApplicableCoupons.reducers,
    ...fetchVoyageTimeline.reducers,
  },
})

export const { initializeForm, selectChargedRoomAccount, clearChargedRoomAccount, loadVoyageDate } =
  Slice.actions

export { submitForm, fetchChargedRoomAccountRooms, fetchApplicableCoupons, selectApplicableCoupon }

export default Slice
