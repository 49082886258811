/* eslint-disable func-names */
(function (window) {
  document.addEventListener("DOMContentLoaded", () => {
    const resetState = () => {
      const sidebar = document.querySelector("#sidebarMenu")

      if (!sidebar) {
        return
      }

      if (window.width < 992) {
        sidebar.classList.remove("contractable")
        sidebar.classList.remove("contracted")

        sidebar.removeEventListener("mouseover")
        sidebar.removeEventListener("mouseout")
      } else {
        sidebar.classList.add("contractable")
        sidebar.classList.add("contracted")

        sidebar.addEventListener("mouseover", () => {
          sidebar.classList.remove("contracted")
        })

        sidebar.addEventListener("mouseout", () => {
          sidebar.classList.add("contracted")
        })
      }
    }

    resetState()

    document.addEventListener("resize", () => {
      resetState()
    })
  })
}(window))
