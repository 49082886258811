import React, { FC, useContext } from "react"

import AuthDescriptorContext from "./Context"

const GuestOnly: FC = ({ children }) => {
  const { isGuestUi } = useContext(AuthDescriptorContext)

  if (!isGuestUi) {
    return null
  }

  return <>{children}</>
}

export default GuestOnly
