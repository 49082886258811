import React from "react"
import { AsyncPaginate as AsyncSelect } from "react-select-async-paginate"

import _ from "lodash"

const scopeRange = ({ number, range, window }) => {
  const isDefined = (v) => v !== null && v !== undefined

  const rangeStart = isDefined(number) ? _.max([range[0], number]) : range[0]

  const windowMax = rangeStart + window

  return [
    rangeStart,
    _.max([rangeStart, isDefined(range[1]) ? _.min([range[1], windowMax]) : windowMax]),
  ]
}

const numberSelectorOptions = ({ range, search, exclude = [], window = 100 }) => {
  const result = []
  const sortedExclude = _.sortBy(exclude)

  const scopedRange = scopeRange({ number: search | 0, range, window })

  for (let i = scopedRange[0]; i < scopedRange[1]; i++) {
    if (_.sortedIndexOf(sortedExclude, i) >= 0) {
      continue
    }
    result.push({ label: i, value: i })
  }

  return result
}

const NumberDropdown = ({
  name,
  value,
  onChange,
  range = [0, null],
  excludeValues = [],
  disabled,
  ...props
}) => {
  const sortedExclude = _.sortBy(excludeValues)

  const loadOptions = (search, previousOptions) => {
    const lastOfPrevious = _.last(previousOptions)

    const result = {
      options: numberSelectorOptions({
        range: [
          _.max([lastOfPrevious ? lastOfPrevious.value + 1 : range[0], search | 0]),
          range[1],
        ],
        search,
        // We don't exclude them here, instead we use React Select's `isOptionDisabled` below
        exclude: [],
      }),
      hasMore: true,
    }

    return result
  }

  return (
    <AsyncSelect
      onChange={(o) => {
        onChange({ target: { name, value: o.value } })
      }}
      value={{ label: value, value }}
      loadOptions={loadOptions}
      cacheOptions={false}
      isOptionDisabled={(o) => _.sortedIndexOf(sortedExclude, o.value) >= 0}
      isDisabled={disabled}
      {...props}
    />
  )
}

export default NumberDropdown
