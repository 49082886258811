import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { guestConfirmed, guestUnconfirmed } from "@/settlement/Slice"

const ConfirmedCheckBox = ({ guestId }): JSX.Element => {
  const dispatch = useDispatch()

  const { editing, tickedIds, pendingIds } = useSelector(({ settlement }) => ({
    editing: settlement.editing,
    tickedIds: settlement.confirmedIds,
    pendingIds: settlement.pendingConfirmedIds,
  }))

  let checked
  let onChange

  if (editing) {
    checked = _.includes(pendingIds, guestId)
  } else {
    checked = _.includes(tickedIds, guestId)
  }

  if (!editing) {
    onChange = null
  } else if (checked) {
    onChange = () => dispatch(guestUnconfirmed({ guestId }))
  } else {
    onChange = () => dispatch(guestConfirmed({ guestId }))
  }

  return <Form.Check type="checkbox" readOnly={!editing} checked={checked} onChange={onChange} />
}

export default ConfirmedCheckBox
