import React from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import { BlueLock } from "@/components/Icons"

import { isBlank } from "@/utils"

const WrappedLabel = ({
  label,
  floating = false,
  children,
  showLockedIcon = false,
  showRequired = false,
}) => {
  if (isBlank(label)) return <>{children}</>

  if (floating) {
    return <FloatingLabel label={label}>{children}</FloatingLabel>
  }

  return (
    <>
      <div className="d-flex align-items-center">
        {showLockedIcon && (
          <BlueLock
            className="me-2 mt-n2 cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="This value is fixed and cannot be edited"
          />
        )}
        <Form.Label>
          {label}
          {showRequired && <span className="ms-1">*</span>}
        </Form.Label>
      </div>
      {children}
    </>
  )
}

const FloatableGroup = ({
  label = undefined,
  floating = false,
  showLockedIcon = false,
  showRequired = false,
  children,
  ...props
}): JSX.Element => (
  <Form.Group {...props}>
    <WrappedLabel
      label={label}
      floating={floating}
      showLockedIcon={showLockedIcon}
      showRequired={showRequired}
    >
      {children}
    </WrappedLabel>
  </Form.Group>
)

export default FloatableGroup
