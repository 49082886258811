import React from "react"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import _ from "lodash"
import { BlueLock } from "@/components/Icons"

import { isPresent } from "@/utils"

const Switch = ({
  name,
  label,
  checked,
  helpText = "",
  offLabel = "",
  showLockedIcon = false,
  showRequired = false,
  ...props
}) => {
  const [, , helpers] = useField(name)

  let overriddenChecked

  if (isPresent(checked)) {
    overriddenChecked = checked
  } else if (_.isBoolean(props.value)) {
    overriddenChecked = props.value
  } else {
    overriddenChecked = props.value === "true"
  }

  return (
    <Form.Group>
      <div className="d-flex align-items-center">
        {isPresent(offLabel) && <label className="me-2">{offLabel}</label>}

        <div className="form-check form-switch" style={{ paddingLeft: 0 }}>
          <input
            className="form-check-input"
            type="checkbox"
            style={{ marginLeft: 0, float: "none" }}
            checked={overriddenChecked}
            {...props}
            onChange={(e) => helpers.setValue(e.target.checked)}
          />
        </div>

        {showLockedIcon && (
          <BlueLock
            className="ms-2 mb-2 cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="This value is fixed and cannot be edited"
          />
        )}
        <label className="ms-2">
          {label}
          {showRequired && <span className="ms-1">*</span>}
        </label>
      </div>
    </Form.Group>
  )
}

export default Switch
