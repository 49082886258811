import React from "react"
import { Button, Modal, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import AwardSetDropdown from "@/onboarding/features/price_analysis/components/header/AwardSetDropdown"
import {
  getSelectedAwardOperatorSummary,
  getSelectedProspectiveAwardSet,
} from "@/onboarding/features/price_analysis/selectors"
import { confirmProspectiveAwardSet } from "@/onboarding/features/price_analysis/Slice"

const AwardBidsModal = ({ show, onHide }: { show: boolean; onHide: () => void }): JSX.Element => {
  const dispatch = useDispatch()
  const operators = useSelector(getSelectedAwardOperatorSummary)
  const awardSet = useSelector(getSelectedProspectiveAwardSet)

  const onConfirm = () => {
    dispatch(confirmProspectiveAwardSet({ awardSetId: awardSet.id }))
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="award-bids-modal-title" centered>
      <Modal.Header>
        <Modal.Title id="award-bids-modal-title">Award Bids</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <AwardSetDropdown />

        <Table>
          <thead>
            <tr>
              <th scope="col">Operator</th>
              <th scope="col">Products</th>
            </tr>
          </thead>
          <tbody>
            {operators.map(({ name, products }) => (
              <tr key={name}>
                <td>{name}</td>
                <td className="d-inline-flex flex-column">
                  {products.map((product) => (
                    <span key={product}>{product}</span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="success" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AwardBidsModal
