import React, { useEffect } from "react"
import {
  Field as FormikField,
  FieldArray as FormikFieldArray,
  Formik,
  useFormikContext,
} from "formik"
import _ from "lodash"

import TextInput from "./TextInput"
import TextAreaInput from "./TextAreaInput"
import NumberInput from "./NumberInput"
import FileInput from "./input/File"
import NumberDropdown from "./NumberDropdown"
import Checkbox from "./Checkbox"
import Switch from "./Switch"
import Select from "./input/Select"
import MultiSelect from "./input/MultiSelect"
import SingleSelect from "./input/SingleSelect"
import { TimePicker, parseTime, parseDate, formatTime, formatDate, DatePicker } from "./DatePickers"

// https://stackoverflow.com/a/25837411/2490686
const escapeRegex = (value) => value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&")

const selectOptions = (options) => options

const newFormControl = (fn) =>
  React.forwardRef((props, ref) => (
    <FormikField ref={ref} name={props.name}>
      {(formik) => fn(props, formik)}
    </FormikField>
  ))

const Effect = ({ fields = [], effect, children }) => {
  const formik = useFormikContext()

  if (effect && children) {
    throw new Error("Either pass effect or use Effect's children")
  }

  if (!(effect || children)) {
    throw new Error("Pass either effect of children")
  }

  const action = children || effect

  let sanitizedFields = null

  // Single string argument case
  if (_.isString(fields)) {
    sanitizedFields = [fields]
  } else {
    sanitizedFields = fields
  }

  const fieldValues = _.map(sanitizedFields, (f) => _.get(formik.values, f))

  const effectArgs = () => {
    if (!_.isEmpty(fieldValues)) {
      return [fieldValues]
    }

    return []
  }

  useEffect(
    () => {
      action(formik)
    },
    ...effectArgs()
  )

  return null
}

// TODO add this to field types other than TextInput and TextAreaInput
const fieldOptions = (formik) => {
  const isInvalid = !!(formik.meta.touched && formik.meta.error)
  const isValid = !!(
    formik.meta.touched &&
    !formik.meta.error &&
    (_.isString(formik.field.value) ? formik.field.value.trim() !== "" : true)
  )

  const fieldNameRegex = new RegExp(`^${escapeRegex(formik.field.name)}\\s*`)

  const formattedErrorMessage = () =>
    isInvalid ? formik.meta.error.replace(fieldNameRegex, "") : null

  return {
    isValid,
    isInvalid,
    validationMessage: formattedErrorMessage(),
  }
}

export default {
  // TODO Extend other inputs
  TextInput: newFormControl((props, formik) => (
    <TextInput {...props} {...formik.field} {...fieldOptions(formik)} />
  )),
  TextInputViewOnly: newFormControl((props, formik) => <TextInput {...props} {...formik.field} />),
  TextAreaInput: newFormControl((props, formik) => (
    <TextAreaInput {...props} {...formik.field} {...fieldOptions(formik)} />
  )),
  NumberInput: newFormControl((props, { field }) => <NumberInput {...props} {...field} />),
  // TODO See if there's an alternative way to avoid any values being passed here (can't show existing files)
  FileInput: newFormControl((props, { field }) => (
    <FileInput {...props} {...field} value={undefined} />
  )),
  /**
   * ReactDatePicker requires that a Date object be passed into +selected+, but for the TimePicker we want to store
   * just the time string in the field, i.e. not the full date.
   */
  TimePicker: newFormControl((props, { field, form }) => (
    <TimePicker
      {...props}
      selected={parseTime(field.value)}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(v) => form.setFieldValue(field.name, formatTime(v))}
    />
  )),
  DatePicker: newFormControl((props, { field, form }) => (
    <DatePicker
      {...props}
      selected={parseDate(field.value)}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(v) => form.setFieldValue(field.name, formatDate(v))}
    />
  )),
  NumberDropdown: newFormControl((props, { field }) => <NumberDropdown {...props} {...field} />),
  Select: newFormControl((props, formik) => (
    <Select {...props} {...formik.field} {...fieldOptions(formik)}>
      {selectOptions(props.children)}
    </Select>
  )),
  MultiSelect: newFormControl((props, { field }) => <MultiSelect {...props} {...field} />),
  SingleSelect: newFormControl((props, { field }) => <SingleSelect {...props} {...field} />),
  Checkbox: newFormControl((props, { field }) => <Checkbox {...props} {...field} />),
  Switch: newFormControl((props, { field }) => <Switch {...props} {...field} />),
  Effect,
  withForm: (onSubmit) => (InputComponent) => (props) => (
    <Formik initialValues={{ [props.name]: props.value }} onSubmit={onSubmit}>
      {() => (
        <FormikField name={props.name}>
          {({ field, form }) => (
            <InputComponent
              {...props}
              {...field}
              onBlur={() => {
                if (props.readOnly) {
                  return
                }

                form.handleSubmit()
              }}
            />
          )}
        </FormikField>
      )}
    </Formik>
  ),
  withArrayForm: (onSubmit) => (FormComponent) => (props) => (
    <Formik initialValues={{ [props.name]: props.value }} onSubmit={onSubmit}>
      {() => (
        <FormikFieldArray
          name={props.name}
          render={(arrayHelpers) => <FormComponent {...props} {...arrayHelpers} />}
        />
      )}
    </Formik>
  ),
}
