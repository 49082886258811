import React from "react"

import { Card } from "react-bootstrap"

import Profile from "./Profile"

const ProfileCard = (props) => (
  <Card>
    <Card.Header className="d-inline-flex flex-column gap-4">
      <h1 className="h4 mb-0">{props.view.heading}</h1>
    </Card.Header>
    <Card.Body>
      <Profile {...props} />
    </Card.Body>
  </Card>
)

export default ProfileCard
