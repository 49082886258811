import React, { FC } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import {
  getFinancialDetailFields,
  getTargetPaxFieldNames,
  getTargetRevenueFieldNames,
} from "@/onboarding/features/product/selectors"
import FormikBootstrap from "@/components/formik-bootstrap"
import FormikFieldSubmitter from "@/onboarding/features/product/components/shared/FormikFieldSubmitter"

const TargetPaxInput: FC = () => {
  const fieldNames = useSelector(getTargetPaxFieldNames)

  if (_.isEmpty(fieldNames)) {
    return null
  }

  return (
    <div className="d-flex flex-column gap-4">
      {_.chunk(fieldNames, 2).map((chunkedFieldNames) => (
        <div key={_.join(chunkedFieldNames)} className="d-flex gap-4">
          {chunkedFieldNames.map((fieldName) => (
            <FormikBootstrap.NumberInput
              key={fieldName}
              name={fieldName}
              label={_.startCase(fieldName)}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const TargetRevenueInput: FC = () => {
  const fieldNames = useSelector(getTargetRevenueFieldNames)

  if (_.isEmpty(fieldNames)) {
    return null
  }

  return (
    <div className="d-flex gap-4">
      {fieldNames.map((fieldName) => (
        <FormikBootstrap.NumberInput
          key={fieldName}
          name={fieldName}
          label={_.startCase(fieldName)}
          displayScale={100.0}
        />
      ))}
    </div>
  )
}

const Edit: FC = () => {
  const fields = useSelector(getFinancialDetailFields)

  return (
    <FormikFieldSubmitter initialValues={fields}>
      <TargetPaxInput />
      <TargetRevenueInput />
    </FormikFieldSubmitter>
  )
}

export default Edit
