export enum TenderStatuses {
  DRAFT = "draft",
  READY_FOR_BIDS = "ready_for_bids",
  BIDS_REQUESTED = "bids_requested",
  BIDDING_CLOSED = "bidding_closed",
  AWARDED_TO_OPERATOR = "awarded_to_operator",
  BIDDING_EXPIRED = "bidding_expired",
}

export enum SettlementMethods {
  PER_GUEST = "per_guest",
  PER_VEHICLE = "per_vehicle",
  PER_VEHICLE_AVERAGE = "per_vehicle_average",
}

export enum ReservedFields {
  RACK_RATESHEET = "rack_ratesheet",
  NET_RATESHEET = "net_ratesheet",
  CALCULATED_NET_RATESHEET = "calculated_net_ratesheet",
  OVERRIDDEN_NET_RATESHEET = "overridden_net_ratesheet",
  SELECTED_NET_RATESHEET_FIELD = "selected_net_ratesheet_field",
  OPERATOR_FEE_RATESHEET = "operator_fee_ratesheet",
  HEADSET_FEE_RATESHEET = "headset_fee_ratesheet",
  TARGET_ADULTS = "target_adults",
  TARGET_CHILDREN = "target_children",
  TARGET_SALES = "target_sales",
  TARGET_MARGIN = "target_margin",
  TARGET_MINIMUM_DEPARTURE_PAX = "target_minimum_departure_pax",
  TARGET_MAXIMUM_DEPARTURE_PAX = "target_maximum_departure_pax",
  TARGET_MAXIMUM_CALL_PAX = "target_maximum_call_pax",
  TARGET_DEPARTURES = "target_departures",
  TARGET_MORNING_DEPARTURES = "target_morning_departures",
  TARGET_AFTERNOON_DEPARTURES = "target_afternoon_departures",
  TOUR_STOPS = "tour_stops",
  TOUR_IMAGES = "tour_images",
  ATTACHMENTS = "attachments",
  LOGISTICAL_COMPONENTS = "logistical_components",
  VENUE_COMPONENTS = "venue_components",
  REFRESHMENT_COMPONENTS = "refreshment_components",
  RESTAURANT_COMPONENTS = "restaurant_components",
  ENTERTAINMENT_COMPONENTS = "entertainment_components",
  GUIDE_COMPONENTS = "guide_components",
  HEADSET_COMPONENTS = "headset_components",
  OVERHEAD_CATEGORIES = "overhead_components",
  SETTLEMENT_METHOD = "settlement_method",
  EXTRA_FEES = "extra_fees",
  VEHICLES = "vehicles",
  TOUR_TYPE = "tour_type",
  ACTIVITY_TYPE = "activity_type",
  ACTIVITY_LEVEL = "activity_level",
  ADA_COMPLIANT = "ada_compliant",
  TOUR_DESCRIPTION = "tour_description",
  SHORT_TOUR_DESCRIPTION = "short_tour_description",
  TOUR_NOTES = "tour_notes",
  TOP_THREE_REASONS = "top_three_reasons",
  UNAVAILABLE_DAYS = "unavailable_days",
  OTHER_RELEVANT_UNAVAILABLE_INFORMATION = "other_relevant_information_on_days_and_times_the_tour_is_not_available",
  AGE_RESTRICTIONS = "age_restrictions",
  HEIGHT_RESTRICTIONS = "height_restrictions",
  WEIGHT_RESTRICTIONS = "weight_restrictions",
  MEDICAL_CONDITION_RESTRICTIONS = "medical_condition_restrictions",
  WHEELCHAIR_SUITABLE = "wheelchair_suitable",
  BLIND_SUITABLE = "blind_suitable",
  DEAF_SUITABLE = "deaf_suitable",
  WALKING_DIFFICULTY_SUITABLE = "walking_difficulty_suitable",
  INSTRUCTORS_PROVIDED = "instructors_provided",
  CHANGING_FACILITIES_PROVIDED = "changing_facilities_provided",
  STORAGE_LOCKERS_PROVIDED = "storage_lockers_provided",
  TOWELS_PROVIDED = "towels_provided",
  WAIVER_FORMS_REQUIRED = "waiver_forms_required",
  INCLUDED_EQUIPMENT = "included_equipment",
  GUEST_GUIDANCE = "guest_guidance",
  OVERVIEW = "overview",
  HIGHLIGHTS = "highlights",
  WHAT_TO_BRING = "what_to_bring",
  WHAT_TO_WEAR = "what_to_wear",
  IMPORTANT_NOTES = "important_notes",
}

export enum TourLocation {
  START_LOCATION = "start_location",
  TOUR_DURATION = "tour_duration",
}

export enum ReferencedValueTypes {
  ACTIVITY_LEVEL = "activityLevel",
  ACTIVITY_TYPE = "activityType",
  TOUR_TYPE = "tourType",
  VEHICLE_TYPE = "vehicleType",
  STOP_TYPE = "stopType",
  GUIDE_TYPE = "guideType",
  VENUE_TYPE = "venueType",
  REFRESHMENT_TYPE = "refreshmentType",
  ENTERTAINMENT_TYPE = "entertainmentType",
}
