import React from "react"

import SingleSelect from "@/components/input/SingleSelect"
import { buildReduxUseField } from "@/form_toolkit/hooks"

const Select = ({ name, label = undefined, useField }): JSX.Element => {
  const field = useField(name)

  return (
    <SingleSelect
      placeholder={field.allLabel}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      options={field.options}
      label={label || field.label}
    />
  )
}

export const buildReduxSingleSelect =
  (formSelector, filterAction) =>
  ({ name, label = undefined }) => (
    <Select name={name} label={label} useField={buildReduxUseField(formSelector, filterAction)} />
  )

export default Select
