import React, { useState, useEffect, useContext } from "react"
import { Card, Form, Row, Col, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useModal } from "@/helpers/useModal"
import SendMessageModal, {
  RoleRecipient,
} from "@/dashboard/components/tour_list_card/modals/SendMessageModal"
import Roles from "@/roles"
import _ from "lodash"
import AuthDescriptorContext from "@/auth_descriptor/Context"
import MessageButton from "@/components/buttons/MessageButton"
import InsuranceStatusCheckbox from "./InsuranceStatusCheckbox"
import { updateInsuranceCertificate } from "../Slice"

const sendMessage = (subject, recipient, title) => {
  const authDescriptor = useContext(AuthDescriptorContext)

  return useModal((props) => (
    <SendMessageModal
      title={title}
      companyId={authDescriptor.companyId}
      recipient={recipient}
      messageSubject={subject}
      messageBody=""
      {...props}
    />
  ))
}

const InsuranceUploadReview = ({ form, operatorName }) => {
  const dispatch = useDispatch()
  const statusOptions = [
    { name: "approved", label: "approved" },
    { name: "approved_with_exception", label: "approved with exception" },
    { name: "rejected", label: "rejected" },
  ]

  const [initialStatus, setInitialStatus] = useState("")

  // TODO Make read only if not write permissions

  useEffect(() => {
    setInitialStatus(form.status)
  }, [])

  const onSubmit = () => {
    dispatch(updateInsuranceCertificate({ values: form }))
  }

  return (
    <>
      <Card className="mt-2 mb-4">
        <h5 className="ms-4 mt-2">Insurance Review</h5>

        <span className="ms-4 mb-3">
          Please select the current status of their insurance upload and submit your final review
        </span>

        <Row className="ms-3">
          {_.map(statusOptions, (option) => (
            <Form.Group key={option.name}>
              <InsuranceStatusCheckbox
                statusName={option.name}
                statusLabel={option.label}
                currentStatus={form.status}
                certificateId={form.certificateId}
              />
            </Form.Group>
          ))}
        </Row>

        <Col className="col-md-3 col-lg-4 G ms-4">
          <Button
            className="btn-primary mb-3 mt-3"
            onClick={onSubmit}
            disabled={initialStatus == form.status}
          >
            submit
          </Button>
          <div className="position-absolute end-0 me-3 mt-n5">
            <MessageButton
              onClick={sendMessage(
                "Insurance review feedback",
                new RoleRecipient(Roles.TourOnboardingInsurance),
                `Message to ${operatorName}`
              )}
            />
          </div>
        </Col>
      </Card>
    </>
  )
}

export default InsuranceUploadReview
