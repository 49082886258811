import React, { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { withApp } from "@/components/App"
import Slice, { initialize } from "@/dashboard/Slice"
import { getDashboard } from "@/dashboard/selectors"

import DashboardCard from "./DashboardCard"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getDashboard)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <Spinner animation="border" />
  }

  return <DashboardCard {...props} />
}

export default withApp(Initializer, { slices: [Slice] })
