import React, { useRef, useState } from "react"
import { Button, Card, Form, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import SignaturePad from "react-signature-pad-wrapper"
import _ from "lodash"

import { submitSheet } from "@/settlement/Slice"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { getSettlement } from "@/settlement/selectors"
import SettlementTotalSummary from "./SettlementTotalSummary"

const SignSettlementModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()
  const { cruiseLine, tour } = useSelector(getSettlement)
  const signaturePad: any = useRef({})
  const [comment, setComment] = useState("")
  const [override, setOverride] = useState(false)

  const clearCanvas = () => signaturePad.current.clear()
  const onSubmit = () =>
    dispatch(
      submitSheet({
        signature: signaturePad.current.toDataURL(),
        comment,
        override,
      })
    )

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="sign-settlement-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="sign-settlement-modal-title">Sign and Approve Settlement</Modal.Title>
        <SettlementTotalSummary />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            type="text"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </Form.Group>
        <CruiseLineOnly>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              checked={override}
              label={`${cruiseLine} and ${_.join(tour.operatorNames, ", ")} have signed on paper?`}
              onChange={(e) => setOverride(e.target.checked)}
            />
          </Form.Group>
        </CruiseLineOnly>
        <Form.Group>
          <Form.Label>Signature</Form.Label>
          <Card>
            <SignaturePad ref={signaturePad} height={250} />
          </Card>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="warning" onClick={clearCanvas}>
          Clear Signature
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SignSettlementModal
