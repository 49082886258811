import React, { forwardRef, useState, useEffect } from "react"
import { default as ReactDatePicker } from "react-datepicker"
import { Form } from "react-bootstrap"
import _ from "lodash"
import {
  format as dateFnsFormat,
  addDays,
  startOfMonth,
  getDay,
  endOfMonth,
  parse as dateFnsParse,
  parseISO,
} from "date-fns"

import { iterDaysInclusive, fixDateString } from "@/utils"
import LoadingSpinner from "@/components/LoadingSpinner"

// https://date-fns.org/v2.21.2/docs/format
export const FORMATS = {
  datetime: {
    default: "yyyy-MM-dd HH:mm",
    humaneShort: "EEE, d MMM yyyy HH:mm",
  },
  date: {
    default: "yyyy-MM-dd",
    humaneDate: "dd MMM yyyy",
    humaneShort: "EEE, d MMM yyyy",
    humaneLong: "EEEE, d MMM yyyy",
  },
  time: {
    default: "HH:mm",
  },
}

const formatDate = (date, format = "date.default") => dateFnsFormat(date, _.get(FORMATS, format))
const formatTime = (date, format = "time.default") => formatDate(date, format)
const formatDateTime = (date, format = "datetime.default") => formatDate(date, format)

// hydrates a time-only string to a Date object
const parseTime = (timeString, format = "time.default") =>
  dateFnsParse(timeString, _.get(FORMATS, format), new Date())

const parseDate = (dateString, format = "date.default") =>
  dateFnsParse(dateString, _.get(FORMATS, format), new Date())

const parseDateTime = (dateTimeString) => parseISO(dateTimeString)

const DateTimeWrapper = ({ formGroup = false, children, ...props }): JSX.Element => {
  if (formGroup === false) {
    return <>{children}</>
  }

  return <Form.Group {...props}>{children}</Form.Group>
}

const DateTimeCustomInput = forwardRef(
  (
    {
      labelText,
      inputName,
      value,
      wrapperProps,
      formGroup = true,
      noFormGroup = undefined,
      readOnly = true,
      ...props
    },
    ref
  ) => {
    if (noFormGroup) {
      formGroup = false
    }

    const inputProps = _.merge({}, props, {
      value,
      name: inputName,
      onChange: () => {},
      readOnly,
      ref,
    })

    return (
      <DateTimeWrapper formGroup={formGroup} {...wrapperProps}>
        {labelText && <Form.Label>{labelText}</Form.Label>}
        <Form.Control {...inputProps} />
      </DateTimeWrapper>
    )
  }
)

/**
 * TODO deprecate and remove as legacy: inputLabel, inputName
 *
 * @param editable
 *  is the input field editable by keyboard? i.e. not through the control.
 */
const DatePicker = ({
  inputLabel,
  inputName,
  selected,
  onChange,
  editable = false,
  name = undefined,
  label = undefined,
  ...props
}): JSX.Element => (
  <ReactDatePicker
    wrapperClassName="w-100"
    selected={selected}
    onChange={onChange}
    dateFormat={props.dateFormat || FORMATS.date.default}
    customInput={
      <DateTimeCustomInput
        labelText={inputLabel || label}
        inputName={inputName || name}
        readOnly={!editable}
      />
    }
    {...props}
  />
)

// TODO The performance of the minute time interval really slows down the loading of the timepicker
const DateTimePicker = (props) => (
  <DatePicker showTimeSelect dateFormat={FORMATS.datetime.default} timeIntervals={1} {...props} />
)

const TimePicker = (props) => (
  <DatePicker
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={1}
    dateFormat={FORMATS.time.default}
    {...props}
  />
)

const DateBadge = ({ selected, onChange, className, dateFormat }) => {
  const date = new Date(fixDateString(selected))

  const DateInput = forwardRef(({ value, ...props }, ref) => (
    <span ref={ref} {...props}>
      {value}
    </span>
  ))

  return (
    <ReactDatePicker
      id="dashboard-date-selector"
      className={`badge ${className || ""}`}
      dateFormat={dateFormat || FORMATS.date.default}
      selected={date}
      onChange={onChange}
      customInput={<DateInput />}
    />
  )
}

// const SpinnerWrapper = (DatePicker) => React.forwardRef()

const createCalendarSpinnerContainer = ({ isLoading }) => {
  const CalendarOrSpinner = ({ children }) => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center" style={{ height: "200px", width: "240px" }}>
          <LoadingSpinner className="align-self-center" />
        </div>
      )
    }

    return <div style={{ position: "relative" }}>{children}</div>
  }

  return ({ className, children }) => (
    <div className={className}>
      <CalendarOrSpinner>{children}</CalendarOrSpinner>
    </div>
  )
}

const AsyncWrapper = (DatePicker) => {
  const WrappedDatePicker = CallbackWrapper(DatePicker)

  return React.forwardRef(
    (
      {
        isLoading = false,
        isDateLoaded = () => true,
        loadDates = () => {},
        getHighlightedDates = () => [],
        ...props
      },
      ref
    ) => {
      const [visibleStartDate, setVisibleStartDate] = useState(null)

      const [visibleEndDate, setVisibleEndDate] = useState(null)

      const isVisibleRangeDefined = visibleStartDate && visibleEndDate

      let highlightedDates = []

      const unloadedDates = []

      if (isVisibleRangeDefined && !isLoading) {
        iterDaysInclusive(visibleStartDate, visibleEndDate, (date) => {
          if (!isDateLoaded(date)) unloadedDates.push(date)
        })
      }

      const hasUnloadedDates = unloadedDates.length > 0

      if (isVisibleRangeDefined && !isLoading && !hasUnloadedDates) {
        highlightedDates = getHighlightedDates({ visibleStartDate, visibleEndDate })
      }

      useEffect(() => {
        if (!isVisibleRangeDefined || isLoading || !hasUnloadedDates) return
        loadDates({ dates: unloadedDates, visibleStartDate, visibleEndDate })
      }, [visibleStartDate, visibleEndDate, isLoading, unloadedDates])

      const Container = createCalendarSpinnerContainer({ isLoading: hasUnloadedDates || isLoading })

      return (
        <WrappedDatePicker
          ref={ref}
          calendarContainer={Container}
          onCalendarChange={({ visibleStartDate, visibleEndDate }) => {
            setVisibleStartDate(visibleStartDate)
            setVisibleEndDate(visibleEndDate)
          }}
          highlightDates={highlightedDates}
          {...props}
        />
      )
    }
  )
}

const CallbackWrapper = (DatePicker) =>
  React.forwardRef(
    ({ selected, onCalendarChange, calendarStartDay = 0, calendarEndDay = 6, ...props }, ref) => {
      const visibleStartDate = (date) => {
        const startDate = startOfMonth(date)
        const difference = calendarStartDay - getDay(startDate)
        return addDays(startDate, difference)
      }

      const visibleEndDate = (date) => {
        const endDate = endOfMonth(date)
        const difference = calendarEndDay - getDay(endDate)
        return addDays(endDate, difference)
      }

      const handleMonthChanged = (viewedMonth) => {
        onCalendarChange &&
          onCalendarChange({
            highlightedDate: viewedMonth,
            visibleStartDate: visibleStartDate(viewedMonth),
            visibleEndDate: visibleEndDate(viewedMonth),
          })
      }

      const handleCalendarOpened = () => {
        handleMonthChanged(selected)
      }

      return (
        <DatePicker
          ref={ref}
          onMonthChange={handleMonthChanged}
          onCalendarOpen={handleCalendarOpened}
          selected={selected}
          calendarStartDay={calendarStartDay}
          calendarEndDay={calendarEndDay}
          {...props}
        />
      )
    }
  )

export const AsyncDatePicker = AsyncWrapper(DatePicker)

export {
  DatePicker,
  DateTimePicker,
  TimePicker,
  DateBadge,
  formatDate,
  formatDateTime,
  parseTime,
  parseDate,
  formatTime,
  parseDateTime,
}
