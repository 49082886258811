import React from "react"

import { Table } from "react-bootstrap"

import { getPagesWithValidity } from "@/components/Wizard"

import StatusIcon from "@/components/Wizard/views/StatusIcon"

const PageStatusTable = () => {
  const applicablePages = getPagesWithValidity()

  return (
    <Table responsive>
      <tbody>
        {_.map(applicablePages, (p) => (
          <tr key={p.uuid}>
            <td className="fit">
              <StatusIcon isValid={p.isValid} className="me-2" /> {p.title}
            </td>
            <td className="fit">{!p.isValid && <span> Form is incomplete or has errors </span>}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default PageStatusTable
