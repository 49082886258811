import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getSelectedBiddingRegion,
  getSelectedProspectiveAwardSet,
  isGroupByBrand,
} from "@/onboarding/features/price_analysis/selectors"

import BiddingRegionDropdown from "./header/BiddingRegionDropdown"
import AwardSetDropdown from "./header/AwardSetDropdown"
import CompareButton from "./header/CompareButton"
import SummaryTable from "./dashboard/SummaryTable"
import CruiseBrandTable from "./dashboard/CruiseBrandTable"
import CruiseBrandDropdown from "./header/CruiseBrandDropdown"
import OperatorDropdown from "./header/OperatorDropdown"
import PortDropdown from "./header/PortDropdown"
import BackToProductButton from "./header/BackToProductButton"
import PortTable from "./dashboard/PortTable"

const Body = (): JSX.Element => {
  const selectedAwardSet = useSelector(getSelectedProspectiveAwardSet)
  const groupByBrand = useSelector(isGroupByBrand)

  if (!selectedAwardSet) {
    return <p className="mb-0">Select a region and prospective award set.</p>
  }

  return (
    <>
      <SummaryTable />
      {groupByBrand ? <CruiseBrandTable /> : <PortTable />}
    </>
  )
}

const Dashboard = (): JSX.Element => {
  const biddingRegion = useSelector(getSelectedBiddingRegion)

  return (
    <Card>
      <Card.Header className="d-inline-flex flex-column gap-4">
        <Row>
          <Col md={4}>
            <h1 className="h4">Cost Analysis Dashboard</h1>
            {biddingRegion && (
              <span className="text-muted">{`Bidding Region: ${biddingRegion.name}`}</span>
            )}
          </Col>

          <Col className="d-inline-flex justify-content-end gap-2">
            <div>
              <CompareButton />
            </div>
            <div>
              <BackToProductButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <CruiseBrandDropdown />
          </Col>
          <Col md={2}>
            <BiddingRegionDropdown />
          </Col>
          <Col md={2}>
            <PortDropdown />
          </Col>
          <Col md={2}>
            <OperatorDropdown />
          </Col>
          <Col md={2}>
            <AwardSetDropdown />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="d-inline-flex flex-column gap-4">
        <Body />
      </Card.Body>
    </Card>
  )
}

export default Dashboard
