import _ from "lodash"

import { yupSchema } from "./schema"
import { getFields } from "./utils"

import Field from "./components/Field"
import Fields from "./components/Fields"
import { buildReduxMultiSelect } from "./components/MultiSelect"
import { buildReduxSingleSelect } from "./components/SingleSelect"

const fieldValues = ({ namePrefix = undefined, ...props }) =>
  _.reduce(
    getFields(props),
    (result, f, name) => {
      _.set(result, _.compact([namePrefix, name]), f.value)
      return result
    },
    {}
  )

export default {
  buildReduxMultiSelect,
  buildReduxSingleSelect,
  yupSchema,
  Field,
  Fields,
  fieldValues,
}
