import React from "react"
import _ from "lodash"
import { Form, Spinner } from "react-bootstrap"

import { isReady, isLoading } from "@/helpers/AsyncField"

const TourProductSelectOptions = ({ options }): JSX.Element => (
  <>
    {_.map(options, ({ name, id }) => (
      <option value={id.toString()} key={id.toString()}>
        {name}
      </option>
    ))}
  </>
)

const TourProductSelect = ({ value, options, onChange, field, emptyText }) => {
  let content = null

  if (options.length === 0 && isReady(field)) {
    content = <i className="d-block">{emptyText}</i>
  } else if (isLoading(field)) {
    content = <Spinner className="d-block" animation="border" />
  } else {
    content = (
      <Form.Control as="select" onChange={onChange} value={value}>
        <TourProductSelectOptions options={options} />
      </Form.Control>
    )
  }

  return (
    <Form.Group>
      <Form.Label className="mt-3">Tour Name</Form.Label>
      {content}
    </Form.Group>
  )
}

export default TourProductSelect
