import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import _ from "lodash"
import { v4 as uuid } from "uuid"

import { FieldTypes } from "@/document_editor/models/Field"

import Pricing from "@/pricing"

const FieldDisplay = ({ field }): JSX.Element => {
  let content

  switch (field.type) {
    case FieldTypes.TOUR_COMPONENT_LIST:
      // TODO Add some additional content. Maybe the operator name?
      content = field.value.map((component) => (
        <Row key={uuid()}>
          <Col>{component.name}</Col>
        </Row>
      ))
      break
    case FieldTypes.TEXT_LIST:
      content = field.value.map((value) => (
        <Row key={uuid()}>
          <Col>{value}</Col>
        </Row>
      ))
      break
    case FieldTypes.IMAGE:
      content = <Image src={field.valueUrl} rounded />
      break
    case (FieldTypes.RATESHEET_TIERED, FieldTypes.RATESHEET_FLAT, FieldTypes.RATESHEET_PICKER):
      content = <Pricing.View value={field.value} />
      break
    default:
      if (_.isObjectLike(field.value)) {
        content = <>{JSON.stringify(field.value)}</>
      } else {
        content = <>{field.value}</>
      }
  }

  return (
    <Row>
      <Col md={3}>{_.startCase(field.name)}</Col>
      <Col>{content}</Col>
    </Row>
  )
}

const FlatDocument = ({ document }): JSX.Element => (
  <div className="d-flex flex-column gap-3">
    {_.sortBy(document.sections, "order").map((section) => (
      <div key={section.id}>
        <h3 className="h5">{_.startCase(section.name)}</h3>
        <div className="d-flex flex-column gap-1">
          {_.sortBy(section.fields, "order").map((field) => (
            <FieldDisplay key={field.id} field={field} />
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default FlatDocument
