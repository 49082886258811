import React from "react"
import { useSelector } from "react-redux"
import { Table } from "react-bootstrap"

import {
  getPriceAnalysis,
  getSelectedBiddingRegion,
  getSelectedBiddingRegionOperators,
} from "@/onboarding/features/price_analysis/selectors"
import GroupingSelector from "../header/GroupingSelector"

import PortRow from "./port_table/PortRow"

const PortTable = (): JSX.Element => {
  const { selectedPortId } = useSelector(getPriceAnalysis)

  const selectedPort = useSelector(getSelectedBiddingRegion).ports.find(
    (port) => port.id === selectedPortId
  )
  const { ports } = useSelector(getSelectedBiddingRegion)
  const operators = useSelector(getSelectedBiddingRegionOperators)

  return (
    <div>
      <h2 className="h5">Total Operator Costs Per Port</h2>
      <GroupingSelector />
      <Table responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "20%" }}>
              Port
            </th>
            <td />
            {operators.map((operator) => (
              <th key={operator.id} scope="col" className="text-right">
                {operator.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedPort ? (
            <PortRow key={selectedPort.id} port={selectedPort} operators={operators} />
          ) : (
            ports.map((port) => <PortRow key={port.id} port={port} operators={operators} />)
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default PortTable
