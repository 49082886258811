import React, { FC } from "react"

import SectionHolder from "./shared/SectionHolder"
import Content from "./tour_details/Content"
import Edit from "./tour_details/Edit"

const TourDetails: FC = () => (
  <SectionHolder title="Tour Details" editComponent={Edit} editMethod="inline">
    <Content />
  </SectionHolder>
)

export default TourDetails
