import React from "react"
import { Spinner } from "react-bootstrap"

const LoadingSpinner = (props): JSX.Element => (
  <Spinner animation="border" role="status" {...props}>
    <span className="sr-only">Loading...</span>
  </Spinner>
)

export default LoadingSpinner
