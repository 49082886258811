import { createSlice } from "@reduxjs/toolkit"

import { createPayloadReducer } from "@/helpers/redux"

// Right now this document is simply a copy of the initial react component props.
// All of the actual document state is currently controlled by Formik, including
// updated content coming back from the server.
//
// TODO Decide whether we would like to ditch Formik entirely and switch back to redux.
// Formik pros:
//   - provides easy to use helpers to update field states
// Formik cons:
//   - does not track the update events and makes it more difficult to determine
//     what to update server side
const Slice = createSlice({
  name: "documentEditor",
  initialState: {
    initialized: false,
    document: {},
    editorMode: null,
    availableOperators: [],
  },
  reducers: {
    initialize: createPayloadReducer({ persistentState: { initialized: true } }),
    updateDocument: (state, { payload }) => ({
      ...state,
      document: payload.document,
    }),
  },
})

export const { initialize, updateDocument } = Slice.actions

export default Slice
