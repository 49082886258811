import axios from "axios"
import _ from "lodash"

import routes from "@/routes"
import { deepCamelizeKeys, deepSnakeizeKeys } from "@/utils"

import { objectToJsonFormData } from "./helpers/objectToForm"

const responseTransformers = {
  camelCase: (transformers) =>
    (transformers || axios.defaults.transformResponse).concat(deepCamelizeKeys),
}

const getCamelCase = (url, options = {}) => axios.get(url, withCamelCaseResponse(options))

const withCamelCaseResponse = (options = {}) =>
  _.merge({}, options, {
    transformResponse: responseTransformers.camelCase(_.get(options, "transformResponse")),
  })

const Api = {
  chat: {
    // Recipient and subject are optional for existing conversations
    send: ({
      conversationId,
      subject,
      body,
      recipient,
    }: {
      conversationId: string
      body: string
      subject?: string
      recipient?: any
    }) =>
      axios.post(
        routes.conversation_messages_path({
          id: conversationId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({
          subject,
          body,
          recipient,
        }),
        withCamelCaseResponse()
      ),
    start: ({ recipient, subject, body }: { subject: string; body: string; recipient: any }) =>
      axios.post(
        routes.start_conversations_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({
          subject,
          body,
          recipient,
        }),
        withCamelCaseResponse()
      ),
    reply: (conversationId: string, body: string) =>
      axios.post(
        routes.reply_conversation_path({
          id: conversationId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({
          conversationsViewReplyForm: {
            conversationId,
            body,
          },
        }),
        withCamelCaseResponse()
      ),
    markAsRead: (conversationId: string) =>
      axios.post(
        routes.mark_as_read_conversation_path({
          id: conversationId,
          format: "json",
          _options: true,
        }),
        withCamelCaseResponse()
      ),
  },
  dashboardView: {
    alternativeTours({ tourId }) {
      return axios.get(
        routes.alternative_tours_dashboard_path({ tour_id: tourId }),
        withCamelCaseResponse()
      )
    },
    confirmableBookings({ cancelledBookingId }) {
      return axios.get(
        routes.confirmable_bookings_dashboard_path({ cancelled_booking_id: cancelledBookingId }),
        withCamelCaseResponse()
      )
    },
    specialRequirementBookings({ tourId }) {
      return axios.get(
        routes.special_requirement_bookings_dashboard_path({ tour_id: tourId }),
        withCamelCaseResponse()
      )
    },
  },
  dailySales: {
    fetch({ date }) {
      return axios.get(routes.daily_sale_path({ id: date }), withCamelCaseResponse())
    },
    close({ date }) {
      return axios.post(routes.close_daily_sale_path({ id: date }))
    },
    open({ date }) {
      return axios.post(routes.open_daily_sale_path({ id: date }))
    },
  },
  manualBooking: {
    transportRental: {
      matchingVehicles(formData) {
        return axios.get(
          "/manual_booking/transport_rental/matching_vehicles.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },
      fetchDrivers(q, startTime, endTime) {
        return axios.get(
          "/manual_booking/transport_rental/drivers.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys({ q, startTime, endTime }),
          })
        )
      },
      price(formData) {
        return axios.get(
          "/manual_booking/transport_rental/price.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },

      fetchVehicleClassOptions(formData) {
        return axios.get(
          "/manual_booking/transport_rental/vehicle_class_options.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },

      fetchTransmissionOptions(formData) {
        return axios.get(
          "/manual_booking/transport_rental/transmission_options.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },
      fetchAirconOptions(formData) {
        return axios.get(
          "/manual_booking/transport_rental/aircon_options.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },
      fetchUnlimitedMileageOptions(formData) {
        return axios.get(
          "/manual_booking/transport_rental/unlimited_mileage_options.json",
          withCamelCaseResponse({
            params: deepSnakeizeKeys(formData),
          })
        )
      },
    },
    fetchTourProductOptions(formData) {
      return axios.get(
        "/manual_booking/tour/tour_products.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys(formData),
        })
      )
    },
    fetchTourOptions(formData) {
      return axios.get(
        "/manual_booking/tour/tours.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys(formData),
        })
      )
    },
    fetchRooms(q: string, options = {}) {
      return axios.get(
        "/manual_booking/tour/rooms.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys(_.merge({ q }, options)),
        })
      )
    },
    fetchRoomGuests(roomId, date) {
      return axios.get(
        "/manual_booking/tour/guests.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys({ roomId, date }),
        })
      )
    },
    fetchCoupons(q, guestId) {
      return axios.get(
        "/manual_booking/tour/coupons.json",
        withCamelCaseResponse({
          params: { q, guestId },
        })
      )
    },
    fetchGuests(guestIds, date = null) {
      return axios.get(
        "/manual_booking/tour/guests.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys({ guestIds, date }),
        })
      )
    },
    calculateBookingPrice(bookingHash) {
      return axios.get(
        "/manual_booking/tour/price.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys(bookingHash),
        })
      )
    },
    fetchNextBookableDate(date) {
      return axios.get(
        "/manual_booking/tour/next_bookable_date.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys({ date }),
        })
      )
    },
    fetchVoyageTimeline(date) {
      return axios.get(
        "/manual_booking/voyage_timeline.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys({ date }),
        })
      )
    },
    fetchPort(date) {
      return axios.get(
        "/manual_booking/port.json",
        withCamelCaseResponse({
          params: deepSnakeizeKeys({ date }),
        })
      )
    },
    submitForm(submitUrl, method, formHash) {
      return axios[method](submitUrl, deepSnakeizeKeys(formHash), withCamelCaseResponse())
    },
  },
  operatorRegistration: {
    create(data) {
      return axios.post(
        routes.onboarding_operator_registrations_path({ format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
  },
  guestComplaints: {
    fetchComplaintOptions(q = "") {
      return axios.get(
        routes.complaint_categories_guest_complaints_path(),
        withCamelCaseResponse({
          params: { q },
        })
      )
    },
    // generalize
    fetchBookingsByRoom({ q = "", tourId }) {
      return axios.get(
        routes.bookings_guest_complaints_path(),
        withCamelCaseResponse({
          params: { q, tour_id: tourId },
        })
      )
    },
    fetchBookingGuests({ bookingId }) {
      return axios.get(
        routes.booking_guests_guest_complaints_path(),
        withCamelCaseResponse({
          params: { booking_id: bookingId },
        })
      )
    },
  },
  settlement: {
    fetchPriceBreakdown(tourId) {
      return axios.get(
        routes.price_breakdown_settlement_path({ id: tourId, format: "json", _options: true }),
        withCamelCaseResponse()
      )
    },
    pushConfirmations(tourId, data) {
      return axios.post(
        routes.confirm_guests_settlement_path({ id: tourId, format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    submitSheet(tourId, data) {
      return axios.post(
        routes.finalize_settlement_path({ id: tourId, format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    disputeSheet(tourId, data) {
      return axios.post(
        routes.dispute_settlement_path({ id: tourId, format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
  },
  dailySettlement: {
    markAsInvoiced(data) {
      return axios.post(
        routes.mark_as_paid_daily_settlement_path({ format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    submitReceivedTickets(data) {
      return axios.post(
        routes.submit_received_tickets_daily_settlement_path({ format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
  },
  operatorCheckIn: {
    submitEvents(tourId, data) {
      return axios.post(
        routes.submit_events_operator_check_in_path({ id: tourId, format: "json", _options: true }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    submitTicketScan(tourId, scan) {
      return axios.post(
        routes.submit_ticket_scan_operator_check_in_path({
          id: tourId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(scan),
        withCamelCaseResponse()
      )
    },
  },
  operatorProfile: {
    updateSettings(operatorProfileId, data) {
      return axios.patch(
        routes.onboarding_operator_profile_update_settings_path({
          operator_profile_id: operatorProfileId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
  },
  operatorApplication: {
    updateOperatorDetails(operatorApplicationId, data) {
      return axios.patch(
        routes.onboarding_operator_application_path({
          id: operatorApplicationId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    updateOperatorQuestionnaire(operatorApplicationId, data) {
      return axios.post(
        routes.operator_questionnaire_onboarding_operator_application_path({
          id: operatorApplicationId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
    submitAgreement(operatorApplicationId, data) {
      return axios.post(
        routes.accept_onboarding_operator_application_path({
          id: operatorApplicationId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(data),
        withCamelCaseResponse()
      )
    },
  },
  collaboration: {
    submitSection(sectionId, data) {
      return axios.put(
        routes.section_path({
          id: sectionId,
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys(data), "data"),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      )
    },
  },
  onboardingProduct: {
    fetchProduct: ({ id, ...extra }) =>
      axios.get(
        routes.onboarding_product_path({
          ...deepSnakeizeKeys({
            id,
            context: extra,
          }),
          format: "json",
          _options: true,
        }),
        withCamelCaseResponse()
      ),
    filter: (id, form) =>
      axios.post(
        routes.filter_onboarding_product_path({
          id,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      ),
    updateProductFields: (productId: string, fields, context, form) =>
      axios.post(
        routes.update_fields_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys({ fields, context, form })),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      ),
    fieldImportDiff: (productId: string, fields, currentProductContext, previousProductContext) =>
      axios.post(
        routes.field_import_diff_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({
          fieldImportDiff: { fields, currentProductContext, previousProductContext },
        }),
        withCamelCaseResponse()
      ),
    openProductForBidding: (productId) =>
      axios.post(
        routes.open_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({}),
        withCamelCaseResponse()
      ),
    closeProductForBidding: (productId) =>
      axios.post(
        routes.close_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({}),
        withCamelCaseResponse()
      ),
    resetProduct: (productId) =>
      axios.post(
        routes.reset_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({}),
        withCamelCaseResponse()
      ),
    submitBid: (productId, bidId) =>
      axios.post(
        routes.bid_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ bidId }),
        withCamelCaseResponse()
      ),
    acceptBid: (productId, bidId) =>
      axios.post(
        routes.accept_bid_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ bidId }),
        withCamelCaseResponse()
      ),
    rejectBid: (productId, bidId) =>
      axios.post(
        routes.reject_bid_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ bidId }),
        withCamelCaseResponse()
      ),
    pendBid: (productId, bidId) =>
      axios.post(
        routes.pend_bid_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ bidId }),
        withCamelCaseResponse()
      ),
    removeBid: (productId, bidId) =>
      axios.post(
        routes.remove_bid_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ bidId }),
        withCamelCaseResponse()
      ),
    activateProduct: (productId) =>
      axios.post(
        routes.activate_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({}),
        withCamelCaseResponse()
      ),
    addContractPeriod: (productId, startDate) =>
      axios.post(
        routes.add_contract_period_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ startDate }),
        withCamelCaseResponse()
      ),
    submitComment: (productId, context, message, sectionName) =>
      axios.post(
        routes.comment_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ comment: { context, message, sectionName } }),
        withCamelCaseResponse()
      ),
    searchCruiseLines: (query: string) =>
      axios.get(
        routes.search_cruise_lines_onboarding_products_path(
          deepSnakeizeKeys({
            query,
            format: "json",
            _options: true,
          })
        ),
        withCamelCaseResponse({
          params: { query },
        })
      ),
    searchComponents: (query: string) =>
      axios.get(
        routes.search_components_onboarding_products_path(
          deepSnakeizeKeys({
            query,
            format: "json",
            _options: true,
          })
        ),
        withCamelCaseResponse({
          params: { query },
        })
      ),
    searchImportableProducts: (context, query: string) =>
      axios.get(
        routes.search_importable_products_onboarding_product_path(
          deepSnakeizeKeys({
            id: context.id,
            format: "json",
            _options: true,
          })
        ),
        withCamelCaseResponse({ params: { query, context } })
      ),
    proposeProduct: (productId, recipientId) =>
      axios.post(
        routes.propose_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ recipientId }),
        withCamelCaseResponse()
      ),
    submitFieldSettings: (productId, settings) =>
      axios.post(
        routes.update_field_settings_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ settings }),
        withCamelCaseResponse()
      ),
    submitMainDetails: (productId, values) =>
      axios.post(
        routes.update_main_details_onboarding_product_path({
          id: productId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ values }),
        withCamelCaseResponse()
      ),
  },
  priceAnalysis: {
    assignOperator(awardSetId, productId, operatorId) {
      return axios.post(
        routes.assign_operator_onboarding_prospective_award_set_path({
          id: awardSetId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ productId, operatorId }),
        withCamelCaseResponse()
      )
    },
    unassignOperator(awardSetId, productId, operatorId) {
      return axios.post(
        routes.unassign_operator_onboarding_prospective_award_set_path({
          id: awardSetId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ productId, operatorId }),
        withCamelCaseResponse()
      )
    },
    confirmProspectiveAwardSet(awardSetId) {
      return axios.post(
        routes.award_bids_onboarding_prospective_award_set_path({
          id: awardSetId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({}),
        withCamelCaseResponse()
      )
    },
  },
  inventoryAnalysis: {
    filter(form) {
      return axios.post(
        routes.filter_onboarding_inventory_analysis_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      )
    },
  },
  seasonDashboard: {
    filter: (form) =>
      axios.post(
        routes.filter_onboarding_seasons_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      ),
  },
  operatorProfileDashboard: {
    filter: (form) =>
      axios.post(
        routes.filter_onboarding_operator_profiles_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      ),
  },
  insurances: {
    filter(form) {
      return axios.post(
        routes.filter_insurance_dashboard_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      )
    },
    submitContractedIds(pendingContractedIds, profileId) {
      return axios.post(
        routes.operator_profile_insurance_update_selected_items_path({
          operator_profile_id: profileId,
          insurance_id: profileId,
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(pendingContractedIds),
        withCamelCaseResponse()
      )
    },
    uploadInsuranceCertificate(insuranceUpload, profileId) {
      return axios.post(
        routes.operator_profile_insurance_upload_certificate_path({
          operator_profile_id: profileId,
          insurance_id: profileId,
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys({ insuranceUpload })),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      )
    },
    updateInsuranceCertificate(insuranceUpload, profileId) {
      return axios.post(
        routes.operator_profile_insurance_update_certificate_path({
          operator_profile_id: profileId,
          insurance_id: profileId,
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys({ insuranceUpload })),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      )
    },
  },
  componentLibrary: {
    create(component) {
      return axios.post(
        routes.components_path({
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys({ component })),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      )
    },
    update(id, component) {
      return axios.post(
        routes.component_path({
          id,
          format: "json",
          _options: true,
        }),
        objectToJsonFormData(deepSnakeizeKeys({ component })),
        withCamelCaseResponse({
          headers: {
            "content-type": "multipart/form-data",
          },
        })
      )
    },
  },
  inventoryAllocation: {
    filter(form) {
      return axios.post(
        routes.filter_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys({ form }),
        withCamelCaseResponse()
      )
    },
    addDepartures(inventory) {
      return axios.post(
        routes.add_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    approveDepartures(inventory) {
      return axios.post(
        routes.approve_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    replaceDepartures(inventory) {
      return axios.post(
        routes.replace_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    appendDepartures(inventory) {
      return axios.post(
        routes.append_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    updateDepartures(inventory) {
      return axios.post(
        routes.update_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    removeDepartures(departures) {
      return axios.post(
        routes.remove_departures_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(departures),
        withCamelCaseResponse()
      )
    },
    updateAllotmentConstraints(inventory) {
      return axios.post(
        routes.update_allotment_constraints_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    lockDays(inventory) {
      return axios.post(
        routes.lock_days_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    unlockDays(inventory) {
      return axios.post(
        routes.unlock_days_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    closeDays(inventory) {
      return axios.post(
        routes.close_days_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
    openDays(inventory) {
      return axios.post(
        routes.open_days_onboarding_inventory_allocation_path({
          format: "json",
          _options: true,
        }),
        deepSnakeizeKeys(inventory),
        withCamelCaseResponse()
      )
    },
  },
}

export default Api
