enum DailySaleState {
  Open = "open",
  Closed = "closed",
}

interface DailySale {
  state: DailySaleState
  date: string
  updatedAt: string
  message: string
}

export { DailySale, DailySaleState }
