import React, { FC } from "react"
import { Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getFeatures } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

import Header from "./Header"
import TourDetails from "./TourDetails"
import WebContent from "./WebContent"
import TourStops from "./TourStops"
import Headsets from "./Headsets"
import AdditionalDetails from "./AdditionalDetails"
import ExtraFees from "./ExtraFees"
import Media from "./Media"
import Costs from "./Costs"
import Settlement from "./Settlement"
import Forecasts from "./Forecasts"
import ComponentSection from "./product_components/Section"

const TourForm: FC = () => {
  const features = useSelector(getFeatures)

  const components = _.compact([
    <Header />,
    <TourDetails />,
    <WebContent />,
    <TourStops />,
    <ComponentSection fieldName={ReservedFields.VENUE_COMPONENTS} />,
    <ComponentSection fieldName={ReservedFields.LOGISTICAL_COMPONENTS} />,
    <ComponentSection fieldName={ReservedFields.REFRESHMENT_COMPONENTS} />,
    <ComponentSection fieldName={ReservedFields.RESTAURANT_COMPONENTS} />,
    <ComponentSection fieldName={ReservedFields.ENTERTAINMENT_COMPONENTS} />,
    <ComponentSection fieldName={ReservedFields.GUIDE_COMPONENTS} />,
    <Headsets />,
    <AdditionalDetails />,
    <ExtraFees />,
    <Media />,
    features.general.product.hasAttachments && (
      <ComponentSection fieldName={ReservedFields.ATTACHMENTS} />
    ),
    features.general.product.readPricing && <Costs />,
    features.general.product.readPricing && <Settlement />,
    features.general.product.forecasts.showSection && <Forecasts />,
  ])

  return (
    <div className="d-flex flex-column gap-3 horizontal-scroll">
      {components.map((component, index) => (
        <Card key={index} border="light" className="shadow-sm">
          <Card.Body>{component}</Card.Body>
        </Card>
      ))}
    </div>
  )
}

export default TourForm
