import React, { useContext, useState, useEffect } from "react"

import Actions from "./actions"

import ChatBox from "./ChatBox"

import ChatContext, { Provider } from "./ChatContext"

const UnreadChatDot = () => <div className="unread-chat-dot" />

const ChatButton = ({ className = "" }): JSX.Element => {
  const { conversation, updateConversation } = useContext(ChatContext)

  const defaultClassName = "fs-5"

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isVisible && conversation.isUnread && conversation.id) {
      Actions.markAsRead({ conversationId: conversation.id }).then(() =>
        updateConversation({ isUnread: false, isRead: true })
      )
    }
  }, [isVisible])

  return (
    <div>
      <a
        className={className || defaultClassName}
        role="button"
        onClick={() => setIsVisible(!isVisible)}
      >
        <span style={{ position: "relative", width: "40px" }}>
          <i key="comment" className="fas fa-comment" />
          {conversation.isUnread && <UnreadChatDot />}
        </span>
      </a>
      <ChatBox isVisible={isVisible} onClose={() => setIsVisible(false)} />
    </div>
  )
}

const Wrapper = ({ chat, className = "" }): JSX.Element => (
  <Provider conversation={chat}>
    <ChatButton className={className} />
  </Provider>
)

export default Wrapper
