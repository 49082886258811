import React from "react"
import { useSelector } from "react-redux"

import { getProductRequestedTotal } from "@/onboarding/features/price_analysis/selectors"

import PriceDisplay from "./PriceDisplay"

const RequestedTotalValue = ({ productId }): JSX.Element => {
  const { expectedTotal, priceBreakdown } = useSelector(getProductRequestedTotal(productId))

  return (
    <PriceDisplay expectedTotal={expectedTotal} priceBreakdown={priceBreakdown} originalValue />
  )
}

export default RequestedTotalValue
