import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Slice, { initialize, setTableLoading } from "@/insurance/features/profile/Slice"
import { getProfile } from "@/insurance/features/profile/selectors"
import { withApp } from "@/components/App"
import LoadingSpinner from "@/components/LoadingSpinner"
import { isReady } from "@/helpers/AsyncField"
import Profile from "../features/profile/components/Profile"

const ProfileApp = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized, _view } = useSelector(getProfile)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
    if (isReady(_view)) {
      dispatch(setTableLoading({ isTableLoading: false }))
    }
  }, [initialized, _view])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <div className="d-flex flex-column gap-3">
      <Profile props={_view.value} />
    </div>
  )
}

export default withApp(ProfileApp, { slices: [Slice] })
