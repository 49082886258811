import React, { useState } from "react"
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap"
import _ from "lodash"

const AddRateCategory = ({ rateCategories, onClick }): JSX.Element => {
  const [newRateCtg, setNewRateCtg] = useState("")
  const [rateTypeError, setRateTypeError] = useState("")

  const noRatesDefined = _.isEmpty(rateCategories)

  const validateRateCategory = (rateCtg) => {
    const sanitizedCtg = _.lowerCase(_.trim(rateCtg || ""))

    if (sanitizedCtg === "") {
      setRateTypeError("Rate category cannot be blank")
      return false
    }

    if (_.includes(rateCategories, sanitizedCtg)) {
      setRateTypeError("Rate category has already been defined")
      return false
    }

    if (rateCtg.match(/(?<!^)[A-Z]/)) {
      setRateTypeError("Capital letters are only allowed at the beginning of the word")
      return false
    }

    if (sanitizedCtg.match(/\s/)) {
      setRateTypeError("Rate category cannot contain spaces")
      return false
    }

    setRateTypeError("")
    return true
  }

  const addRateCategory = (ctg) => {
    if (!validateRateCategory(ctg)) return

    onClick(ctg)
    setNewRateCtg("")
  }

  return (
    <Form.Group as={Row}>
      {noRatesDefined && (
        <Col xs={12} className="mb-2">
          <small>
            <i>Enter a singular name for the category of the rate below (ex. adult or child)</i>
          </small>
        </Col>
      )}
      <Col md={4}>
        <InputGroup hasValidation>
          <Form.Control
            value={newRateCtg}
            onChange={(e) => {
              setNewRateCtg(e.target.value)
              setRateTypeError("")
            }}
            isInvalid={rateTypeError !== "" ? true : undefined}
          />
          <Form.Control.Feedback type="invalid">{rateTypeError}</Form.Control.Feedback>
        </InputGroup>
      </Col>
      <Col md={8}>
        <Button size="sm" onClick={() => addRateCategory(newRateCtg)}>
          <i className="fas fa-plus me-2" />
          Category
        </Button>
      </Col>
    </Form.Group>
  )
}

export default AddRateCategory
