import { withApp } from "@/components/App"

import ProfileCard from "@/onboarding/features/operator_profiles/ProfileCard"

import createOperatorApplicationSlice from "@/onboarding/features/operator_application/Slice"

export default withApp(ProfileCard, {
  slices: [
    createOperatorApplicationSlice({ name: "currentOperatorApplication" }),
    createOperatorApplicationSlice({ name: "pendingOperatorApplication" }),
  ],
})
