import React, { useState } from "react"
import { Button, Col, Nav, Row, Tab } from "react-bootstrap"
import _ from "lodash"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"

import ContentSet from "./ContentSet"

const App = ({
  authDescriptor,
  contentSets,
  availableOperators = [],
  initialContentSetId = "",
}): JSX.Element => {
  const contentSetIds = _.map(contentSets, "id")
  const iniitalActiveKey = _.includes(contentSetIds, initialContentSetId)
    ? initialContentSetId
    : _.head(contentSetIds)

  const [activeKey, setActiveKey] = useState(iniitalActiveKey)
  const [presenting, setPresenting] = useState(true)

  const contentSet = _.find(contentSets, (c) => c.id === activeKey)

  let presentButton

  if (presenting) {
    presentButton = (
      <Button onClick={() => setPresenting(false)}>
        <span className="fas fa-edit me-2" />
        Edit
      </Button>
    )
  } else {
    presentButton = (
      <Button onClick={() => setPresenting(true)}>
        <span className="fas fa-desktop me-2" />
        Present
      </Button>
    )
  }

  return (
    <Tab.Container defaultActiveKey={activeKey} id="content-set-editor">
      <CruiseLineOnly>
        <Row className="mb-4">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <Nav variant="tabs" defaultActiveKey={activeKey}>
              {contentSets.map((set) => (
                <Nav.Item key={set.id}>
                  <Nav.Link eventKey={set.id} onClick={() => setActiveKey(set.id)}>
                    {set.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {presentButton}
          </div>
        </Row>
      </CruiseLineOnly>
      <Row>
        <Col>
          <ContentSet
            authDescriptor={authDescriptor}
            contentSet={contentSet}
            availableOperators={availableOperators}
            presenting={presenting}
          />
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default App
