import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import _ from "lodash"
import { useField } from "formik"

import Pricing from "@/pricing"

const selectOptions = () => {
  const result = []

  return _.concat(
    result,
    _.chain(Pricing.CONFIG)
      .filter("selectable")
      .map((v, k) => [v.label, v.kind])
      .value()
  )
}

const ratesheetLabel = (ratesheet) => {
  // Is ratesheet field part of the internal representation??
  const kind = _.get(ratesheet, "ratesheet.kind")

  const o = _.find(selectOptions(), (o) => o[1] === kind)

  if (o) {
    return o[0]
  }

  return o
}

const RateTypeDropdown = ({ value, onChange, readOnly }) => {
  const optionControls = _.map(selectOptions(), ([label, value]) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))

  return (
    <Form.Group as={Row}>
      <Col xs="auto">
        <Form.Label>Rate type</Form.Label>
      </Col>
      <Col>
        <Form.Control as="select" onChange={onChange} value={value} disabled={readOnly}>
          {optionControls}
        </Form.Control>
        {!readOnly && <Form.Text>Switching rate types will clear all previous inputs</Form.Text>}
      </Col>
    </Form.Group>
  )
}

const RatesheetPicker = (props): JSX.Element => {
  const { name, value, readOnly, canEditPricingStructure, defaultRateCategories, defaultTiers } =
    props

  const [, , ratesheetHelpers] = useField(`${name}.ratesheet`)

  const onChangeRateType = (rateType) => {
    ratesheetHelpers.setValue(
      Pricing.newRatesheet({ kind: rateType, defaultRateCategories, defaultTiers })
    )
  }

  return (
    <Row>
      <Col>
        {canEditPricingStructure && (
          <RateTypeDropdown
            value={value.ratesheet.kind}
            onChange={(e) => onChangeRateType(e.target.value)}
            readOnly={readOnly}
          />
        )}
        <Pricing.Editor
          name={`${name}.ratesheet`}
          value={value.ratesheet}
          readOnly={readOnly}
          onChange={props.onChange}
          canEditPricingStructure={canEditPricingStructure}
        />
      </Col>
    </Row>
  )
}

RatesheetPicker.View = (props): JSX.Element => (
  <div>
    <b>Rate Type</b> {ratesheetLabel(props.value) || <i>None selected</i>}
    <Pricing.View value={props.value.ratesheet} />
  </div>
)

export default RatesheetPicker
