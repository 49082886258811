import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { useSelector } from "react-redux"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import LoadingSpinner from "@/components/LoadingSpinner"
import FormToolkit from "@/form_toolkit"
import { filter } from "../Slice"
import { getForm, isViewLoading, getInventory } from "../selectors"

const Select = FormToolkit.buildReduxSingleSelect(getForm, filter)

const HeaderTitle = () => {
  const inventory = useSelector(getInventory)

  return (
    <h1 className="h4 mb-0">
      Inventory Allocation
      {inventory && inventory.company && inventory.company.name && ` for ${inventory.company.name}`}
    </h1>
  )
}

const Header = (): JSX.Element => {
  const isLoading = useSelector(isViewLoading)
  return (
    <Card.Header className="d-inline-flex flex-column gap-4">
      <div className="d-flex align-items-center gap-2">
        <HeaderTitle />
        {isLoading && <LoadingSpinner className="align-self-center" />}
      </div>

      <Row>
        <CruiseLineOnly>
          <Col md={2}>
            <Select name="operator" />
          </Col>
        </CruiseLineOnly>
        <OperatorOnly>
          <Col md={2}>
            <Select name="cruiseLine" />
          </Col>
        </OperatorOnly>

        <Col md={2}>
          <Select name="port" />
        </Col>
        <Col md={2}>
          <Select name="tour" />
        </Col>
        <Col md={2}>
          <Select name="ship" />
        </Col>
      </Row>
    </Card.Header>
  )
}

export default Header
