// type =>
const DATA_TABLE = {
  Tour: {
    productTypeText: "Tour",
  },
  PrivateVilla: {
    productTypeText: "Villa",
  },
}

const productType = (product) => product.kind

const bookingType = (booking) => booking.kind

const isTour = (product) => productType(product) === "Tour"

const isPrivateVilla = (product) => productType(product) === "PrivateVilla"

const productTypeText = (product) => DATA_TABLE[product.kind].productTypeText

const isSelfOperated = (product) => product.selfOperated

const isPrivateVillaBooking = (booking) => bookingType(booking) === "PrivateVilla"

export { productTypeText, isPrivateVilla, isSelfOperated, isPrivateVillaBooking }
