import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getProductField } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({ name, label }): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>{label}</b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content: FC = () => (
  <div className="d-flex flex-column gap-3 px-4">
    <Row>
      <Col>
        <DisplayValue
          name={ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX}
          label="Minimum Per Departure"
        />
      </Col>
      <Col>
        <DisplayValue
          name={ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX}
          label="Maximum Per Departure"
        />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.TARGET_MAXIMUM_CALL_PAX} label="Maximum Per Call" />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.TARGET_DEPARTURES} label="Departures" />
      </Col>
    </Row>
    <Row>
      <Col>
        <DisplayValue name={ReservedFields.TOUR_TYPE} label="Tour Types" />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.ACTIVITY_TYPE} label="Activity Type" />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.ACTIVITY_LEVEL} label="Activity Level" />
      </Col>
      <Col className="d-flex align-items-center">
        <DisplayValue name={ReservedFields.ADA_COMPLIANT} label="ADA Compliant" />
      </Col>
    </Row>
    <DisplayValue name={ReservedFields.TOUR_DESCRIPTION} label="Tour Description" />
    <DisplayValue
      name={ReservedFields.SHORT_TOUR_DESCRIPTION}
      label="Tour Description (Less Than 1000 Characters)"
    />
    <DisplayValue name={ReservedFields.TOUR_NOTES} label="Tour Notes" />
    <DisplayValue
      name={ReservedFields.TOP_THREE_REASONS}
      label="Top Three Reasons A Guest Should Buy This Tour"
    />
    <Row>
      <Col>
        <DisplayValue
          name={ReservedFields.UNAVAILABLE_DAYS}
          label="Days On Which The Tour Will Be Unavailable"
        />
      </Col>
      <Col>
        <DisplayValue
          name={ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION}
          label="Other Relevant Information On Days And Times The Tour Is Not Available"
        />
      </Col>
    </Row>
  </div>
)

export default Content
