import _ from "lodash"
import * as AsyncField from "@/helpers/AsyncField"

import {
  initialize,
  selectTourProduct,
  selectTour,
  calculateBookingPrice,
  selectTourStartDate,
  // Fetching
  fetchTourProductOptions,
  fetchTourOptions,
  fetchPassengers,
  fetchPort,
  fetchNextBookableDate,
} from "./Slice"

import { getBookingFormById } from "./selectors"

import { getUnloadedPassengerOptionIds } from "./bookingSelectors"

const hasOptions = (field) => field.value.length > 0
// TODO must find a way to deal with bad connections
const canLoadTourProductOptions = (form) => !_.isNil(form.tourStartDate)

const canLoadPort = (form) => !_.isNil(form.tourStartDate)

const canLoadTourOptions = (form) => !_.isNil(form.tourProductId) && canLoadTourProductOptions(form)

const tourBookingAutoSelect = (dispatch, getState, formId) => {
  const form = getBookingFormById(getState().tourBookings, formId)

  if (
    !form.tourProductId &&
    hasOptions(form._tourProductOptions) &&
    AsyncField.isReady(form._tourProductOptions)
  ) {
    dispatch(
      selectTourProduct({
        formId,
        tourProductId: form._tourProductOptions.value[0].id,
      })
    )
  }

  if (!form.tourId && hasOptions(form._tourOptions) && AsyncField.isReady(form._tourOptions)) {
    dispatch(selectTour({ formId, tourId: form._tourOptions.value[0].id }))
  }
}

const autorun = async (props, dispatch, getState) => {
  const { tourBookings } = getState()

  if (!tourBookings.initialized) {
    dispatch(initialize(props))
  }

  _.each(tourBookings.bookingForms, (form) => {
    const { formId } = form

    /**
     * When a new booking form is created from a previous one we copy that form. However in a recent requirement
     * we had to ensure that when a tour form is copied the booking date is advanced to that of the next port.
     *
     * However, after calculating that date we had to ensure that the same invalidations would run as
     * when the tourDate was set by a redux action.
     *
     * Since we have no dispatch handle in the newBookingForm function we resort to the tried and true pattern
     * which we use for handling asynchronous updates in the unidirectional flow of redux:
     *  render -> dispatch action -> update store -> monitor -> (dispatch ->) -> render
     *
     * Essentially we set a state variable to be stale and then dispatch an API update in the monitor phase.
     */
    // XXX Interesting challenge with the latched on side-effects of the booking form. Hmmm
    // It seems that we should create a slice for each individual field and let each field invalidate
    // when one of its depedent fields are invalidated
    if (form._prefilledTourStartDate) {
      dispatch(
        selectTourStartDate({
          formId,
          tourStartDate: form._prefilledTourStartDate,
          clearPrefilled: true,
        })
      )
    }

    tourBookingAutoSelect(dispatch, getState, formId)

    if (AsyncField.isStale(form._tourProductOptions) && canLoadTourProductOptions(form)) {
      dispatch(fetchTourProductOptions({ formId }))
    }

    if (AsyncField.isStale(form._tourOptions) && canLoadTourOptions(form)) {
      dispatch(fetchTourOptions({ formId }))
    }

    if (AsyncField.isStale(form._price)) {
      dispatch(calculateBookingPrice({ formId }))
    }

    if (AsyncField.isStale(form._port) && canLoadPort(form)) {
      dispatch(fetchPort({ formId, date: form.tourStartDate }))
    }

    if (AsyncField.isStale(form._nextTourStartDate) && !_.isNil(form.tourStartDate)) {
      dispatch(fetchNextBookableDate({ formId, date: form.tourStartDate }))
    }

    const unloadedPassengerIds = getUnloadedPassengerOptionIds(form)

    if (!_.isEmpty(unloadedPassengerIds)) {
      dispatch(fetchPassengers({ formId, passengerIds: unloadedPassengerIds }))
    }
  })
}

export default autorun
