import React from "react"
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"

import { useModal } from "@/helpers/useModal"
import { getSettlement } from "@/daily_settlement/Slice"

import SubmitReceivedTicketsModal from "./SubmitReceivedTicketsModal"

const ReceivedTicketInput = ({ settlementSheetId }) => {
  const { settledPax, submittingTicketCounts } = useSelector(getSettlement(settlementSheetId))
  const toggleModal = useModal((props) => (
    <SubmitReceivedTicketsModal settlementSheetId={settlementSheetId} {...props} />
  ))

  let paxDisplay

  if (settledPax) {
    paxDisplay = `${settledPax.adult} / ${settledPax.child}`
  } else {
    paxDisplay = ""
  }

  if (settledPax !== null || submittingTicketCounts) {
    return <Form.Control className="text-right" value={paxDisplay} readOnly />
  }

  return <Form.Control className="text-right" value={paxDisplay} readOnly onClick={toggleModal} />
}

export default ReceivedTicketInput
