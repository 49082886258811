import React from "react"
import { Row, Col, Image, Spinner } from "react-bootstrap"

const GuestColumns = ({
  guest,
  noGuestMessage = undefined,
  hasPaymentMethod = undefined,
  onClick = undefined,
}) => {
  if (!guest && noGuestMessage) {
    return (
      <Col onClick={onClick} className="col-auto">
        <i>{noGuestMessage}</i>
      </Col>
    )
  }

  const renderPaymentMethodFlag = () => {
    if (hasPaymentMethod === undefined) return null

    if (hasPaymentMethod) {
      return (
        <span className="d-block" key="has-payment-method">
          <span className="fas fa-check-circle text-success me-1" />
          <span>Payment Method Loaded</span>
        </span>
      )
    }

    return (
      <span className="d-block" key="no-payment-method">
        <span className="fas fa-times-circle text-danger me-1" />
        <span>No Payment Method Loaded</span>
      </span>
    )
  }

  if (guest) {
    return (
      <Col onClick={onClick} className="col-auto">
        <div className="d-flex gap-2">
          <div>
            <div className="avatar">
              <Image src={guest.profilePicturePath} roundedCircle />
            </div>
          </div>
          <div>
            <h4 className="h6 mb-0">{guest.name}</h4>
            <small>
              <b>{"Room: "}</b>
              {guest.roomNumber}
            </small>
            {renderPaymentMethodFlag()}
          </div>
        </div>
      </Col>
    )
  }

  return (
    <Col onClick={onClick} className="col-auto">
      <Row>
        <Col className="col-auto">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Col>
  )
}

export default GuestColumns
