import React, { useState, useEffect } from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getToursPerPort, getVoyageName, getPassengerID } from "@/guests_tours/selectors"

const ToursCard = (): JSX.Element => {
  const toursPerPort = useSelector(getToursPerPort)
  const voyage = useSelector(getVoyageName)
  const passengerID = useSelector(getPassengerID)

  const [selectedPortName, setSelectedPortName] = useState("All")

  useEffect(() => {
    getSelectedPort(selectedPortName)
  }, [selectedPortName])

  useEffect(() => {
    setSelectedPort(toursPerPort)
  }, [toursPerPort])

  const [selectedPort, setSelectedPort] = useState(toursPerPort)

  const getSelectedPort = (portName) => {
    const result =
      portName == "All" ? toursPerPort : [toursPerPort.find((obj) => obj.name === portName)]
    setSelectedPort(result)
    return result
  }

  if (toursPerPort.length == 0) {
    return (
      <h5 className="mt-3">
        No bookable excursions available for the
        {voyage}.
      </h5>
    )
  }

  // TODO(guest-app): Use @/routes helper in the paths below
  return (
    <div className="pb-6">
      <div className="text-right">
        <select
          className="btn btn-primary"
          id="ports"
          defaultValue="Filter by port"
          onChange={(e) => setSelectedPortName(e.target.value)}
        >
          <option value="All">All Ports</option>
          {_.map(toursPerPort, (port) => (
            <option key={port.name} value={port.name}>
              {port.name}
            </option>
          ))}
        </select>
      </div>

      {_.map(selectedPort, (port) => (
        <Card key={port.name} className="mt-3 pt-2">
          <div className="table-responsive-sm">
            <h4 className="ps-4 ms-n3 d-inline">{port.name}</h4>
            <span className="ps-3 d-inline">
              {port.arrivalDate}
              {port.departureDate}
            </span>
            <Table size="sm" className="align-items-center table-sm">
              <thead>
                <tr>
                  <th scope="col" className="text-start">
                    {port.tours.length == 0 ? (
                      <span className="ms-n3">No bookings at this port</span>
                    ) : (
                      <span className="ms-n3">Tour</span>
                    )}
                  </th>
                  <th scope="col" className="text-end" />
                </tr>
              </thead>
              <tbody>
                {_.map(port.tours, (tour) => (
                  <tr key={tour.name}>
                    <td scope="col" className="text-start">
                      <a className="ms-n3" href={`/guests_tour_description?id=${passengerID}`}>
                        <u>{tour.name}</u>
                      </a>
                    </td>
                    <th scope="col" className="text-end  pe-2">
                      {tour.tourHasPassed ? (
                        <a className="text-secondary" href={`/guests_feedback?id=${passengerID}`}>
                          <button className="btn btn-outline-secondary btn-sm ms-n4">
                            Feedback
                          </button>
                        </a>
                      ) : (
                        <span />
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      ))}
    </div>
  )
}

export default ToursCard
