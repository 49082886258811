import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getField, hasField } from "@/onboarding/features/product/selectors"
import * as FlatRatesheet from "@/pricing/models/FlatRatesheet"
import * as TieredRatesheet from "@/pricing/models/TieredRatesheet"
import * as BulkRatesheet from "@/pricing/models/BulkRatesheet"
import FormikFieldSubmitter from "@/onboarding/features/product/components/shared/FormikFieldSubmitter"
import RateInputs from "@/onboarding/features/product/components/shared/RateInputs"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import RateDisplay from "@/onboarding/features/product/components/shared/RateDisplay"

const Edit = ({ fieldName }): JSX.Element => {
  const field = useSelector(getField(fieldName))

  return (
    <FormikFieldSubmitter initialValues={{ [fieldName]: field.value }}>
      <RateInputs name={fieldName} />
    </FormikFieldSubmitter>
  )
}

const TieredRateTable = ({ table, rateLabel, canEdit, toggleModal }) => (
  <Table>
    <thead>
      <tr>
        {table.headings.map((heading, index) => (
          <th scope="col" key={index}>
            <div
              className={
                index === 0
                  ? "d-flex justify-content-between gap-2"
                  : "d-flex justify-content-end gap-2"
              }
            >
              {index === 0 ? (
                <span>{heading.label}</span>
              ) : (
                <span>{`${_.capitalize(heading.label)} ${rateLabel}`}</span>
              )}
              {index === table.headings.length - 1 && canEdit && <EditIcon onEdit={toggleModal} />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {_.isEmpty(table.body) && (
        <tr>
          <td>No tiers defined</td>
        </tr>
      )}
      {table.body.map((tier, index) => (
        <tr key={index}>
          <td key="tierFrom">{tier.tierFromColumn.value}</td>
          {tier.rateColumns.map(({ category, value }) => (
            <td key={category} className="text-right">
              <RateDisplay amount={value} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
)

// TODO We should ideally be using the DynamicRatesheet.View here instead, but for
// now I need to be able to tweak things a bit.
const RatesTable = ({ fieldName, ratesheet, rateLabel = "", editable }): JSX.Element => {
  const canEdit = editable && useSelector(hasField(fieldName))
  const toggleModal =
    canEdit &&
    useModal((props) => (
      <EditModal {...props} title={rateLabel} Body={Edit} bodyProps={{ fieldName }} />
    ))

  if (_.isEmpty(ratesheet)) {
    return (
      <Table>
        <thead>
          <tr>
            <th scope="col">
              <div className="d-flex justify-content-between gap-2">
                <span>{rateLabel}</span>
                {canEdit && <EditIcon onEdit={toggleModal} />}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No rates defined</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  // TODO Try to pull some of this back into the pricing module
  switch (ratesheet.kind) {
    case FlatRatesheet.KIND:
      const categories = FlatRatesheet.rateCategories(ratesheet)

      return (
        <Table>
          <thead>
            <tr>
              {categories.map((category, index) => (
                <th scope="col" key={category}>
                  <div className="d-flex justify-content-end gap-2">
                    <span>{`${_.capitalize(category)} ${rateLabel}`}</span>
                    {index === categories.length - 1 && canEdit && (
                      <EditIcon onEdit={toggleModal} />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {categories.map((category) => (
                <td key={category} className="text-right">
                  <RateDisplay amount={ratesheet.rates[category]} />
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      )
    case TieredRatesheet.KIND:
      return (
        <TieredRateTable
          table={TieredRatesheet.asTable(ratesheet)}
          rateLabel={rateLabel}
          canEdit={canEdit}
          toggleModal={toggleModal}
        />
      )
    case BulkRatesheet.KIND:
      return (
        <TieredRateTable
          table={BulkRatesheet.asTable(ratesheet)}
          rateLabel={rateLabel}
          canEdit={canEdit}
          toggleModal={toggleModal}
        />
      )
    default:
      return null
  }
}

const RatesDisplay = ({
  fieldName,
  rateLabel,
  editable = false,
}: {
  fieldName: string
  rateLabel: string
  editable?: boolean
}) => {
  const { value: ratesheet } = useSelector(getField(fieldName))

  return (
    <RatesTable
      fieldName={fieldName}
      ratesheet={ratesheet.ratesheet}
      rateLabel={rateLabel}
      editable={editable}
    />
  )
}

export default RatesDisplay
