import React from "react"

// Note: Keep the look of this button consistent with the BackButtonComponent defined in the app.
const BackLink = ({ path = "#top", onClick = undefined }): JSX.Element => (
  <a href={path} onClick={onClick} className="ps-2">
    <span className="fas fa-chevron-left me-2" />
    Back
  </a>
)

export default BackLink
