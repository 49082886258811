export const getTourBooking = ({ guestBookedTourAccount }) => guestBookedTourAccount

export const getTourName = ({ guestBookedTourAccount }) => guestBookedTourAccount.name

export const getStartTime = ({ guestBookedTourAccount }) => guestBookedTourAccount.startTime

export const getEndTime = ({ guestBookedTourAccount }) => guestBookedTourAccount.endTime

export const getBookedPassengers = ({ guestBookedTourAccount }) =>
  guestBookedTourAccount.bookedPassengers

export const getChargedPassenger = ({ guestBookedTourAccount }) =>
  guestBookedTourAccount.chargedPassenger

export const getLoggedInPassenger = ({ guestBookedTourAccount }) =>
  guestBookedTourAccount.loggedInPassenger

export const getTourDetails = ({ guestBookedTourAccount }) => guestBookedTourAccount.tourDetails

export const isInitialized = ({ guestBookedTourAccount }) => guestBookedTourAccount.initialized
