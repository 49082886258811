import _ from "lodash"

import { requireKeys, omitUnderscoreKeys } from "@/utils"

/**
 * Returns a list of booking forms with all private fields omitted.
 */
const getSubmitableBookingForms = (state) => _.map(state.bookingForms, getSubmitableBookingForm)

const getBookingFormById = (state, formId) => state.bookingForms[formId]

const getSubmitableBookingForm = (booking) => omitUnderscoreKeys(booking)

const getDriverSearchField = (booking) => booking._driverSearch

const getSelectedDriver = (booking) => booking._selectedDriver

const getBookingFormByAction = (state, action) => {
  requireKeys(action.payload, "formId")
  return getBookingFormById(state, action.payload.formId)
}

export {
  getBookingFormById,
  getSubmitableBookingForms,
  // Based on booking data
  getSubmitableBookingForm,
  getBookingFormByAction,
  getDriverSearchField,
  getSelectedDriver,
}
