import React from "react"

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  // TODO Log Error to AirBrake
  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return <>{this.props.children}</>
  }
}

export const withErrorBoundary =
  (Component) =>
  (props): JSX.Element => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  )

export default ErrorBoundary
