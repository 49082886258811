import React from "react"

import { Button } from "react-bootstrap"

import { isPresent } from "@/utils"

const TrashButton = ({ label = "", onClick, outlined = true, ...props }) => {
  const buttonVariant = outlined ? "outline-danger" : "danger"

  const iconClassName = isPresent(label) ? "fas fa-trash me-2" : "fas fa-trash"

  return (
    <Button variant={buttonVariant} size="sm" onClick={onClick} {...props}>
      <span className={iconClassName} />
      {label}
    </Button>
  )
}

export default TrashButton
