import _ from "lodash"

interface Field {
  value: any
}

export const appendValueElement = (field: Field, value: unknown): Field => ({
  ...field,
  value: _.concat(field.value, value),
})

export const removeValueElement = (field: Field, index: string): Field => ({
  ...field,
  value: _.filter(field.value, (_value, i) => i !== index),
})

// Note that these items are converted from an array of hashes to a hash containing string indices for each element,
// i.e. { "0" => "a", "1" => "b" }. This is to avoid an issue on the backend where parsing html form data resulted
// in array elements being merged into previous ones, as discussed in this comment's example:
// https://stackoverflow.com/questions/18337609/rspec-request-test-merges-hashes-in-array-in-post-json-params/70363366#70363366
//
// For now the server's derserialize_form_data function has an option to deal with this exception and transform the
// hash back into an array.
export const updateValueElement = (field: Field, index: string, value: unknown): Field => ({
  ...field,
  value: _.reduce(
    [...field.value.slice(0, index), value, ...field.value.slice(index + 1)],
    (previousItems, item, itemIndex) => _.assign(previousItems, { [itemIndex]: item }),
    {}
  ),
})
