import React from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { formatMoney } from "@/helpers/money"
import { getSettlement } from "@/settlement/selectors"

import TicketAcceptedCheckBox from "./body/TicketAcceptedCheckBox"
import ConfirmedCheckBox from "./body/ConfirmedCheckBox"

const Body = (): JSX.Element => {
  const { guests } = useSelector(getSettlement)

  if (guests.length === 0) {
    return (
      <Card.Body>
        <p>No bookings for this tour</p>
      </Card.Body>
    )
  }

  return (
    <Table responsive size="sm" className="align-items-center table-flush">
      <thead className="thead-light">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Room</th>
          <th scope="col">Booking</th>
          <th scope="col" className="text-right">
            Net
          </th>
          <th scope="col" className="text-center">
            Checked In
          </th>
          <th scope="col" className="text-center">
            Confirmed
          </th>
        </tr>
      </thead>
      <tbody>
        {guests.map((guest) => (
          <tr key={guest.guestId}>
            <td>{guest.name}</td>
            <td>{_.startCase(guest.paxType)}</td>
            <td>{guest.roomName}</td>
            <td>{guest.bookingReference}</td>
            <td className="text-right">{formatMoney(guest.netPrice, guest.currency)}</td>
            <td className="text-center">
              <TicketAcceptedCheckBox guestId={guest.guestId} />
            </td>
            <td className="text-center">
              <ConfirmedCheckBox guestId={guest.guestId} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default Body
