import { manual_booking_path } from "@/routes"

const redirect = (url) => {
  window.location = url
}

/** Wraps actionFn in an eventHandler
 construction:
  const contructor = withEventHander(doThis)
  const eventHandler = contructor(1)
*/
const withEventHander =
  (actionFn) =>
  (...actionArgs) =>
  (_event) => {
    actionFn(...actionArgs)
  }

const actions = {
  redirectToManualBookingForm(tourId) {
    redirect(manual_booking_path({ tour_id: tourId }))
  },
}

const actionOnEvent = {
  redirectToManualBookingForm: withEventHander(actions.redirectToManualBookingForm),
}

export { actions, actionOnEvent }
