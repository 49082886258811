import React from "react"

import SimpleSearchInput from "@/components/SimpleSearchInput"

import { Guest } from "./RoomSearchResult"

const GuestResult = ({ result, ...props }): JSX.Element => (
  <Guest guest={result} includeRoom {...props} />
)

const GuestSearch = ({ results, isLoading, onSearch, onSelect, placeholder }) => (
  <SimpleSearchInput
    id="guestSearch"
    results={results}
    isLoading={isLoading}
    onSearch={onSearch}
    onSelect={onSelect}
    placeholder="Search"
    labelKey="name"
    resultComponent={GuestResult}
  />
)

export default GuestSearch
