import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { getBookings, getVoyage, getID } from "@/guests/selectors"
import BookingsModal from "./BookingsModal"

const VoyageItinerary = (): JSX.Element => {
  const voyage = useSelector(getVoyage)
  const bookings = useSelector(getBookings)
  const guestID = useSelector(getID)

  // TODO(guest-app): Pass bookings as an empty array from above in the selector and avoid the string
  // comparison.
  const portBookings = (leg) =>
    bookings == "" ? "" : bookings.find((b) => b.port.name === leg.port)
  const target = (leg) => `#${leg.port}`
  const click = (leg) => (leg.opacity == 1 ? "" : "disabled")

  return (
    <div>
      <h4 className="mb-1">Voyage Itinerary</h4>
      <Table size="sm" className="align-items-center table-flush table-pad-sm overflow-auto">
        <thead className="ms-n2">
          <tr className="ms-n2 ">
            <th scope="col" className="text-start ps-0 pe-3">
              Date
            </th>
            <th scope="col" className="text-start">
              Time
            </th>
            <th scope="col" className="text-center">
              Port
            </th>
            <th scope="col" className="text-start pe-0">
              Activity
            </th>
          </tr>
        </thead>
        <tbody>
          {voyage.itinerary.map((leg) => (
            <tr key={leg.date}>
              <td scope="col" className="text-start ps-0 pe-3" style={{ opacity: leg.opacity }}>
                {leg.date}
              </td>
              <td scope="col" className="text-start" style={{ opacity: leg.opacity }}>
                {leg.time}
              </td>
              <td scope="col" className="text-start">
                <button
                  style={{ opacity: leg.opacity }}
                  className={`btn btn-light dropdown-toggle ${click(leg)}`}
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {leg.port}
                </button>
                <BookingsModal {...portBookings(leg)} name={leg.port} />
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button
                      className="btn btn-light"
                      data-bs-toggle="modal"
                      data-bs-target={target(leg)}
                    >
                      View current bookings
                    </button>
                  </li>
                  <li>
                    <a className="dropdown-item" href={`manual_booking?id=${guestID}`}>
                      Make bookings
                    </a>
                  </li>
                </ul>
              </td>
              <td scope="col" className="text-start pe-0 py-3" style={{ opacity: leg.opacity }}>
                {leg.activity}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default VoyageItinerary
