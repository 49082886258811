import React, { FC } from "react"
import { useModal } from "@/helpers/useModal"
import { Button } from "react-bootstrap"
import OverviewModal from "./OverviewModal"

const OverviewModalLink: FC = () => {
  const toggleModal = useModal((props) => <OverviewModal {...props} />)

  return (
    <span className="cursor-pointer" onClick={toggleModal} title="View insurance metrics">
      <Button variant="primary">Insurance Metrics</Button>
    </span>
  )
}

export default OverviewModalLink
