import _ from "lodash"

const truthyInt = (it) => (_.isNil(it) ? 0 : 1)

// Returns a { Key<FieldName>: Value<Field> } object
export const getFields = ({ form = undefined, fields = undefined, sections = undefined }) => {
  const definedCount = truthyInt(form) + truthyInt(fields) + truthyInt(sections)

  if (definedCount > 1) {
    throw new Error("Only one of `form', `fields' or `sections' must be specified.")
  }

  if (form) return form.fields

  if (fields) return fields

  // Representation using our Questionnaire / questionnaire.rb model
  if (sections) {
    // Use the `id` of the question as the fields name in the generated hash / object
    return _.merge(
      {},
      ..._(sections)
        .flatMap("questions")
        .map((q) => ({ [q.id]: q.field }))
        .value()
    )
  }

  throw new Error("Either `form', `fields' or `sections' must be specified.")
}
