import React from "react"
import { useSelector } from "react-redux"
import { Table } from "react-bootstrap"

import {
  getCruiseBrands,
  getSelectedCruiseBrand,
  getSelectedBiddingRegionOperators,
} from "@/onboarding/features/price_analysis/selectors"

import GroupingSelector from "@/onboarding/features/price_analysis/components/header/GroupingSelector"
import CruiseBrandRow from "./cruise_brand_table/CruiseBrandRow"

const CruiseBrandTable = (): JSX.Element => {
  const selectedCruiseBrand = useSelector(getSelectedCruiseBrand)
  const cruiseBrands = useSelector(getCruiseBrands)
  const operators = useSelector(getSelectedBiddingRegionOperators)

  return (
    <div>
      <h2 className="h5">Total Operator Costs Per Cruise Brand</h2>
      <GroupingSelector />
      <Table responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "20%" }}>
              Cruise Brand
            </th>
            <td />
            {operators.map((operator) => (
              <th key={operator.id} scope="col" className="text-right">
                {operator.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedCruiseBrand ? (
            <CruiseBrandRow
              key={selectedCruiseBrand.id}
              cruiseBrand={selectedCruiseBrand}
              operators={operators}
            />
          ) : (
            cruiseBrands.map((brand) => (
              <CruiseBrandRow key={brand.id} cruiseBrand={brand} operators={operators} />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default CruiseBrandTable
