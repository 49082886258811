import React, { FC } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getItinerary } from "@/itinerary_dashboard/selectors"
import { setTab, TabTypes } from "@/itinerary_dashboard/Slice"

import HeaderCard from "./header/HeaderCard"

const TabController: FC = () => {
  const dispatch = useDispatch()
  const { selectedTab } = useSelector(getItinerary)

  return (
    <Tabs
      id="tab-pane"
      activeKey={selectedTab}
      onSelect={(tab) => dispatch(setTab(tab))}
      className="mx-4"
    >
      <Tab eventKey={TabTypes.PORT} title="Ports">
        <HeaderCard />
      </Tab>
      <Tab eventKey={TabTypes.SHIP} title="Ships">
        <HeaderCard />
      </Tab>
      <Tab eventKey={TabTypes.ITINERARY} title="Itineraries">
        <HeaderCard />
      </Tab>
    </Tabs>
  )
}

export default TabController
