import React, { useState } from "react"
import { Button } from "react-bootstrap"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import Summary from "./InsuranceSummary"
import InsuranceUploads from "./InsuranceUploads"

const Profile = ({ props }) => {
  const [activeTab, setActiveTab] = useState("summary")

  return (
    <>
      <CruiseLineOnly>
        <h5>{`Insurance Self Certification for ${props.profile.operatorName}`}</h5>
      </CruiseLineOnly>
      <OperatorOnly>
        <h5>{`Insurance Self Certification for ${props.profile.cruiseLineName}`}</h5>
      </OperatorOnly>
      <div className="navbar navbar-expand-lg navbar-light bg-light nav-tabs">
        <div className="nav-item">
          {activeTab === "summary" ? (
            <Button className="nav-link active"> Summary </Button>
          ) : (
            <Button className="nav-link" onClick={() => setActiveTab("summary")}>
              Summary
            </Button>
          )}
        </div>
        <div key="uploads" className="nav-item">
          {activeTab === "uploads" ? (
            <Button className="nav-link active"> Insurance uploads </Button>
          ) : (
            <Button className="nav-link" onClick={() => setActiveTab("uploads")}>
              Insurance uploads
            </Button>
          )}
        </div>
      </div>
      {activeTab === "summary" ? (
        <Summary props={props} />
      ) : (
        <div>
          <InsuranceUploads />
        </div>
      )}
    </>
  )
}

export default Profile
