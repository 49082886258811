import React from "react"
import { useSelector } from "react-redux"

import { useModal } from "@/helpers/useModal"
import PriceBreakdownModal from "@/settlement/components/card/header/PriceBreakdownModal"
import { getSettlement } from "@/daily_settlement/Slice"

const PriceBreakdownLink = ({ settlementSheetId }) => {
  const { priceBreakdown } = useSelector(getSettlement(settlementSheetId))
  const toggleModal = useModal((props) => (
    <PriceBreakdownModal priceBreakdown={priceBreakdown} {...props} />
  ))

  return (
    <span className="cursor-pointer" onClick={toggleModal} title="View settlement assignments">
      <i className="fas fa-info-circle" />
    </span>
  )
}

export default PriceBreakdownLink
