import React, { useState } from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import _ from "lodash"

import useDelayedState from "@/helpers/useDelayedState"
import DelayedComponent from "@/components/DelayedComponent"
import AuthenticityToken from "@/components/AuthenticityToken"
import { cancel_tour_booking_path } from "@/routes"
import Booking, { ReservationStates } from "@/dashboard/models/Booking"
import TourCodeBadge from "@/dashboard/components/tour_list_card/TourCodeBadge"
import Api from "@/api"

const BookingRows = ({ bookings, toggleBooking, selectedBookingId }) => {
  const hasConfirmed = selectedBookingId

  const renderConfirmButton = (booking) => {
    const isConfirmed = selectedBookingId === booking.bookingId

    if (isConfirmed) {
      return (
        <Button variant="danger" size="sm" onClick={() => toggleBooking(booking.bookingId)}>
          Remove
        </Button>
      )
    }

    if (hasConfirmed) {
      return <></>
    }

    return (
      <Button variant="success" size="sm" onClick={() => toggleBooking(booking.bookingId)}>
        Confirm
      </Button>
    )
  }

  return _.map(bookings, (b) => (
    <tr key={b.bookingId}>
      <td> {b.reference} </td>
      <td> {b.tour.timeslot} </td>
      <td>
        {b.tour.name}
        <br />
        <TourCodeBadge tour={b.tour} />
      </td>
      <td className="text-right">{b.confirmedRetailPrice}</td>
      <td className="text-right">{renderConfirmButton(b)}</td>
    </tr>
  ))
}

const BookingsTable = ({ booking, confirmedBookingId, toggleConfirmedBooking }) => {
  const bookingsControl = useDelayedState({
    initialState: [],
    loadFn: (control, context) => {
      Api.dashboardView
        .confirmableBookings({ cancelledBookingId: booking.bookingId })
        .then((response) => {
          control.setSuccess(response.data.data.confirmableBookings)
        })
        .catch((error) => {
          control.setFailed()
        })
    },
  })

  const renderBookings = (bookings) => {
    if (!bookings || bookings.length === 0) {
      return <i> No confirmable bookings</i>
    }

    return (
      <div>
        <p> The bookings below are confirmable for these guest after cancelling this booking </p>
        <Table className="table-flush">
          <thead className="thead-light">
            <tr>
              <th> Reference </th>
              <th> Departure </th>
              <th> Tour </th>
              <th className="text-right"> Retail </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <BookingRows
              bookings={bookings}
              selectedBookingId={confirmedBookingId}
              toggleBooking={toggleConfirmedBooking}
            />
          </tbody>
        </Table>
      </div>
    )
  }

  if (booking.reservationState !== ReservationStates.CONFIRMED) {
    return <></>
  }

  return (
    <div>
      <h5 className="mt-3"> Waitlisted Bookings </h5>
      <DelayedComponent onReload={bookingsControl.reload} state={bookingsControl.state}>
        {renderBookings(bookingsControl.value)}
      </DelayedComponent>
    </div>
  )
}

const CancelBookingModal = ({
  show,
  tourBooking,
  onHide,
}: {
  show: boolean
  tourBooking: Booking
  onHide: () => void
}): JSX.Element => {
  const [confirmedBookingId, setConfirmedBookingId] = useState(null)

  const toggleConfirmedBooking = (bookingId) => {
    if (confirmedBookingId === bookingId) {
      setConfirmedBookingId(null)
    } else {
      setConfirmedBookingId(bookingId)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="cancel-booking-modal-title"
      centered
    >
      <Form action={cancel_tour_booking_path({ id: tourBooking.bookingId })} method="post">
        <AuthenticityToken />
        <Modal.Header>
          <Modal.Title id="cancel-booking-modal-title">
            Cancel Booking {tourBooking.reference}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmedBookingId && (
            <input hidden name="confirmed_booking_id" defaultValue={confirmedBookingId} />
          )}
          <Form.Group className="mb-2">
            <Form.Control
              as="textarea"
              placeholder="Why is the guest cancelling this booking?"
              rows={3}
              name="cancellation_reason"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              name="credit_room_account"
              value="1"
              label="Credit room account?"
              defaultChecked
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              name="notify_guests"
              value="1"
              label="Send cancellation message to guests?"
              defaultChecked
            />
          </Form.Group>
          <BookingsTable
            booking={tourBooking}
            confirmedBookingId={confirmedBookingId}
            toggleConfirmedBooking={toggleConfirmedBooking}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type="submit">Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CancelBookingModal
