import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getProductOperatorTotal,
  portExpanded,
  getPortExpandedProductAwards,
  getPriceAnalysis,
  getFilteredPortOperatorTotal,
} from "@/onboarding/features/price_analysis/selectors"
import { expandPort, minimizePort, selectProduct } from "@/onboarding/features/price_analysis/Slice"
import { formatMoney } from "@/helpers/money"

const PortOperatorTotal = ({ filteredPortProducts, operatorId }): JSX.Element => {
  const { amount, currency } = useSelector(
    getFilteredPortOperatorTotal(filteredPortProducts)(operatorId)
  )

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(amount, currency)}</>
}

const ProductOperatorTotal = ({ productId, operatorId }): JSX.Element => {
  const { expectedTotal, currency } = useSelector(getProductOperatorTotal(productId)(operatorId))

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(expectedTotal, currency)}</>
}

const PortRow = ({ port, operators }): JSX.Element => {
  const dispatch = useDispatch()
  const selected = useSelector(portExpanded(port.id))
  const productAwards = useSelector(getPortExpandedProductAwards(port.id))
  const { selectedCruiseBrandId, selectedOperatorId } = useSelector(getPriceAnalysis)

  const filteredPortProducts = port.products.filter((product) => {
    const matchesCruiseBrand =
      !selectedCruiseBrandId ||
      product.cruiseBrands.some((brand) => brand.id === selectedCruiseBrandId)

    const matchesOperator =
      !selectedOperatorId ||
      product.biddingOperators.some((operator) => operator.id === selectedOperatorId)

    return matchesCruiseBrand && matchesOperator
  })

  const operatorColumnClass = (productId, operatorId) =>
    productAwards[productId] === operatorId ? "text-right bg-secondary" : "text-right"

  const onClick = selected
    ? () => dispatch(minimizePort())
    : () => dispatch(expandPort({ portId: port.id }))

  let row

  if (filteredPortProducts.length > 0) {
    row = (
      <tr className="cursor-pointer" onClick={onClick}>
        <th scope="row">
          {`${port.name} `}
          <span className="badge bg-primary">{filteredPortProducts.length}</span>
        </th>
        <td />
        {operators.map((operator) => (
          <td key={operator.id} className="text-right">
            <PortOperatorTotal
              filteredPortProducts={filteredPortProducts}
              operatorId={operator.id}
            />
          </td>
        ))}
      </tr>
    )
  } else {
    row = (
      <tr>
        <th scope="row">{port.name}</th>
        <td />
        {operators.map((operator) => (
          <td key={operator.id} className="text-right">
            <PortOperatorTotal
              filteredPortProducts={filteredPortProducts}
              operatorId={operator.id}
            />
          </td>
        ))}
      </tr>
    )
  }

  if (selected) {
    return (
      <>
        {row}
        {filteredPortProducts.map((product) => (
          <tr key={product.id}>
            <th
              scope="row"
              className="text-underline cursor-pointer"
              onClick={() => dispatch(selectProduct({ productId: product.id }))}
            >
              <div className="ms-3">{product.code}</div>
            </th>
            <td>{product.name}</td>
            {operators.map((operator) => (
              <td key={operator.id} className={operatorColumnClass(product.id, operator.id)}>
                <ProductOperatorTotal productId={product.id} operatorId={operator.id} />
              </td>
            ))}
          </tr>
        ))}
      </>
    )
  }

  return row
}

export default PortRow
