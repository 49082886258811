import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getPriceBreakdown } from "@/settlement/selectors"
import { useModal } from "@/helpers/useModal"

import PriceBreakdownModal from "./PriceBreakdownModal"

const PriceBreakdownLink: FC = () => {
  const priceBreakdown = useSelector(getPriceBreakdown)
  const toggleModal = useModal((props) => (
    <PriceBreakdownModal priceBreakdown={priceBreakdown} {...props} />
  ))

  return (
    <span className="cursor-pointer" onClick={toggleModal} title="View settlement assignments">
      <i className="fas fa-info-circle" />
    </span>
  )
}

export default PriceBreakdownLink
