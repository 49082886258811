import _ from "lodash"

import { omitUnderscoreKeys } from "@/utils"

import { LoadState } from "@/helpers/useDelayedState"

const getBookingDescription = (booking) => {
  const tourProduct = getTourProduct(booking)
  const tour = getTour(booking)

  if (!tourProduct || !tour) return undefined

  return `${tourProduct.name} (${tour.name})`
}

const getTourProduct = (booking) => {
  if (_.isNil(booking.tourProductId)) {
    return null
  }
  return _.find(booking._tourProductOptions.value, (o) => o.id == booking.tourProductId)
}

const getTour = (booking) => {
  if (_.isNil(booking.tourId)) {
    return undefined
  }

  return _.find(booking._tourOptions.value, (t) => t.id == booking.tourId)
}

const getSubmitableBookingForm = (booking) => omitUnderscoreKeys(booking)

const getUnloadedPassengerOptions = (booking) =>
  _.filter(booking._passengerOptions, { state: LoadState.Stale })

const getUnloadedPassengerOptionIds = (booking) => _.map(getUnloadedPassengerOptions(booking), "id")

export {
  getBookingDescription,
  getTour,
  getTourProduct,
  getSubmitableBookingForm,
  getUnloadedPassengerOptions,
  getUnloadedPassengerOptionIds,
}
