import React from "react"

import { Button } from "react-bootstrap"

const MessageButton = ({ onClick, ...props }) => {
  const iconClassName = "fas fa-comment"

  return (
    <Button size="sm" onClick={onClick} {...props}>
      <span className={iconClassName} />
    </Button>
  )
}

export default MessageButton
