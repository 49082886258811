import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import { useModal } from "@/helpers/useModal"
import {
  canEdit,
  getComments,
  getFeatures,
  getFormsExpanded,
} from "@/onboarding/features/product/selectors"

import { BlueLock } from "@/components/Icons"
import CommentIcon from "./CommentIcon"
import CommentModal from "./CommentModal"
import EditIcon from "./EditIcon"
import EditModal from "./EditModal"
import Importer from "./Importer"

const SectionHolder = ({
  title,
  editComponent = null,
  editMethod = "modal",
  showLockedIcon = false,
  importableFields = [],
  children,
}): JSX.Element => {
  const editable = useSelector(canEdit)
  const {
    general: {
      product: { comment: showComment },
    },
  } = useSelector(getFeatures)
  const formsExpanded = useSelector(getFormsExpanded)
  const comments = useSelector(getComments(_.snakeCase(title)))

  const editingInitialState = editMethod === "modal" ? false : formsExpanded
  const showEditIcon = !_.isNull(editComponent) && editable
  const showImportIcon = !_.isEmpty(importableFields) && editable

  const [editing, setEditing] = useState(editingInitialState)
  const [collapsed, setCollapsed] = useState(false)
  const [importing, setImporting] = useState(false)

  useEffect(() => {
    if (editMethod !== "modal" && editing !== formsExpanded) {
      setEditing(formsExpanded)
    }
  }, [formsExpanded])

  const toggleCommentModal = useModal((props) => <CommentModal {...props} title={title} />)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const onEdit =
    editMethod === "modal"
      ? useModal((props) => <EditModal {...props} title={title} Body={editComponent} />)
      : () => setEditing(true)

  const EditComponent = editComponent

  let body

  if (importing) {
    body = <Importer setImporting={setImporting} fieldNames={importableFields} />
  } else if (editing) {
    body = <EditComponent setEditing={setEditing} />
  } else if (!collapsed) {
    body = children
  } else {
    body = null
  }

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {showLockedIcon && (
            <BlueLock
              className="me-2 cursor-pointer"
              data-toggle="tooltip"
              data-placement="top"
              title="This component is fixed"
            />
          )}
          <h2 className="h5 mb-0 cursor-pointer d-flex gap-2" onClick={toggleCollapsed}>
            <span>{title}</span>
            <span className={collapsed ? "" : "d-none"}>
              <i key="down-icon" className="fas fa-chevron-down" />
            </span>
            <span className={collapsed ? "d-none" : ""}>
              <i key="up-icon" className="fas fa-chevron-up" />
            </span>
          </h2>
        </div>

        <div className="d-flex gap-2">
          {showEditIcon && !editing && <EditIcon onEdit={onEdit} />}
          {editing && (
            <span className="cursor-pointer" onClick={() => setEditing(false)}>
              <i className="fas fa-times text-danger" />
            </span>
          )}
          {showImportIcon && !importing && (
            <span className="cursor-pointer" onClick={() => setImporting(true)}>
              <i className="fas fa-file-import" />
            </span>
          )}
          {importing && (
            <span className="cursor-pointer" onClick={() => setImporting(false)}>
              <i className="fas fa-times text-danger" />
            </span>
          )}
          {showComment && (
            <CommentIcon showUnread={comments.length > 0} onComment={toggleCommentModal} />
          )}
        </div>
      </div>

      {!collapsed && <div>{body}</div>}
    </div>
  )
}

export default SectionHolder
