import React from "react"
import { Row, Col, Form, Container, Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import ContentVisibility from "@/components/ContentVisibility"
import ToggledTextAreaFieldControl from "@/components/ToggledTextAreaFieldControl"
import { TourAvailabilityDropdown } from "@/components/TourAvailability"
import RoomSearch from "@/manual_booking/components/RoomSearch"
import FormIdContext from "@/manual_booking/contexts/FormIdContext"
import { isStale, isLoading, isReady } from "@/helpers/AsyncField"
import { isHidden, formatFor, constantFor } from "@/helpers/formSpec"
import {
  productTypeText,
  isTourBooking,
  isPrivateVillaBooking,
} from "@/manual_booking/productSelectors"
import VoyageAwareDatePicker from "@/manual_booking/components/VoyageAwareDatePicker"
import ShoretimeStyles from "@/stylesheets/shoretime"

import { useAuthDescriptor } from "@/auth_descriptor/hooks"
import { fixDateString } from "@/utils"

import PassengerList from "./PassengerList"
import TourProductSelect from "./TourProductSelect"
import { getBookingFormById } from "./selectors"
import {
  // Commands
  removeBookingForm,
  selectRoomResult,
  setSpecialRequirements,
  selectTourStartDate,
  selectTour,
  selectTourProduct,
  waitlistBooking,
  // Queries
  findRooms,
} from "./Slice"
import AppliedCoupons from "./AppliedCoupons"

const TourBookingForm = ({ formId }): JSX.Element => {
  const { isGuestUi } = useAuthDescriptor()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const dispatch = useDispatch()
  const formData = useSelector((state) => getBookingFormById(state.tourBookings, formId))
  const fields = useSelector((state) => state.tourBookings.fields)

  const tourProductOptions = formData._tourProductOptions.value
  const tourOptions = formData._tourOptions.value

  const onChangeTourProduct = (event) => {
    const tourProductId = event.target.value.toString()
    dispatch(selectTourProduct({ formId, tourProductId }))
  }

  const onChangeTour = (tourId) => {
    dispatch(selectTour({ formId, tourId }))
  }

  let tourAvailabilityControl = (
    <Form.Group>
      <Form.Label className="mt-3">Tour Departure Time</Form.Label>
      <TourAvailabilityDropdown
        tourOptions={tourOptions}
        selected={formData.tourId}
        onChange={onChangeTour}
        loadState={formData._tourOptions.state}
        spread
      />
    </Form.Group>
  )

  if (isReady(formData._tourProductOptions) && formData._tourProductOptions.value.length === 0) {
    tourAvailabilityControl = null
  }

  const renderPortName = () => {
    if (isReady(formData._port)) {
      const port = formData._port.value

      if (port.inPort) {
        return (
          <span>
            {"(at port "}
            <b style={{ color: ShoretimeStyles.secondaryColour }}>{formData._port.value.name}</b>)
          </span>
        )
      }

      return (
        <span>
          (<i>{formData._port.value.name}</i>)
        </span>
      )
    }

    return (
      <Spinner animation="border" variant="light" size="sm" className="badge-spinner-sm ms-1" />
    )
  }

  return (
    <FormIdContext.Provider value={formId}>
      <Container fluid className="p-0">
        <ContentVisibility hidden={isHidden(fields, "remove")}>
          <i
            className="bi bi-x-circle float-end fs-4 text-danger cursor-pointer"
            onClick={() => dispatch(removeBookingForm({ formId }))}
          />
        </ContentVisibility>
        <strong className="text-muted">
          {productTypeText(formData)}
          {" Booking "}
          {renderPortName()}
        </strong>
        <Form className="d-flex flex-column gap-2">
          <Row>
            <Col xs={12} md={4}>
              <ContentVisibility hidden={isHidden(fields, "tourProduct")}>
                <TourProductSelect
                  options={tourProductOptions}
                  value={formData.tourProductId}
                  onChange={onChangeTourProduct}
                  field={formData._tourProductOptions}
                  emptyText={`No ${productTypeText(formData, true)} available on selected day`}
                />
              </ContentVisibility>
            </Col>

            {isGuestUi && tourProductOptions.length != 0 ? (
              <a
                className="my-2 link-secondary"
                href={`/guests_tour_description?id=${urlParams.get("id")}`}
              >
                <u className="small">About Tour</u>
              </a>
            ) : (
              <div />
            )}

            <Col xs={12} md={4} className="mt-3">
              <ContentVisibility hidden={isHidden(fields, "tourStartDate")}>
                <Form.Group>
                  <VoyageAwareDatePicker
                    inputLabel="Tour Date"
                    dateFormat={formatFor(fields, "tourStartDate")}
                    selected={new Date(fixDateString(formData.tourStartDate))}
                    popperPlacement="top"
                    showOnlyCurrentVoyage
                    onChange={(date) => {
                      dispatch(
                        selectTourStartDate({
                          tourStartDate: date.toISOString(),
                          formId,
                        })
                      )
                    }}
                  />
                </Form.Group>
              </ContentVisibility>
            </Col>
            <Col xs={12} md={4}>
              <ContentVisibility hidden={isHidden(fields, "tour")}>
                {isTourBooking(formData) && tourAvailabilityControl}
              </ContentVisibility>
            </Col>
          </Row>
          <Row>
            <Col>
              {isPrivateVillaBooking(formData) &&
                isReady(formData._tourOptions) &&
                formData._tourOptions.value[0] && (
                  <i className="d-block mt-2">
                    {`Can accomodate ${formData._tourOptions.value[0].maximumPax} guests`}
                  </i>
                )}
              <ContentVisibility hidden={isHidden(fields, "passengers")}>
                <Form.Group className="mt-2">
                  <Form.Label>Guests</Form.Label>
                  <RoomSearch
                    results={formData._roomSearch.value}
                    isLoading={isLoading(formData._roomSearch) || isStale(formData._roomSearch)}
                    onSearch={(q) => {
                      dispatch(findRooms({ q, formId }))
                    }}
                    onSelect={(selections, typeAhead) => {
                      dispatch(selectRoomResult({ room: selections[0], formId }))
                      typeAhead.clear()
                    }}
                  />
                  <PassengerList />
                </Form.Group>
              </ContentVisibility>
            </Col>
          </Row>
          <Row>
            <Col className="mt-1">
              <ToggledTextAreaFieldControl
                value={formData.specialRequirements}
                onChange={(e) =>
                  dispatch(setSpecialRequirements({ value: e.target.value, formId }))
                }
                hidden={isHidden(fields, "specialRequirements")}
                toggleLabel="Add Special Requirements"
                toggleClassName="btn-sm btn-outline-primary"
                label="Special Requirements"
                helpText={constantFor(fields, "specialRequirementsPlaceholder")}
              />
            </Col>
          </Row>
          {!isGuestUi ? (
            <Row>
              <Col>
                <ContentVisibility hidden={isHidden(fields, "waitlisted")}>
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Waitlist booking"
                      checked={formData.waitlist}
                      onChange={(e) =>
                        dispatch(waitlistBooking({ formId, waitlist: e.target.checked }))
                      }
                    />
                  </Form.Group>
                </ContentVisibility>
              </Col>
            </Row>
          ) : (
            <div />
          )}

          <Row>
            <Col>
              <AppliedCoupons formId={formId} />
            </Col>
          </Row>
        </Form>
      </Container>
    </FormIdContext.Provider>
  )
}

export default TourBookingForm
