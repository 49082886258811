import React from "react"

import Passenger from "@/dashboard/models/Passenger"

const PassengerInfo = ({ passenger }: { passenger: Passenger }) => (
  <div>
    <span className="d-block">{passenger.name}</span>
    <span className="d-block">{passenger.phoneNumber}</span>
    <span className="d-block">{passenger.email}</span>
  </div>
)

export default PassengerInfo
