import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getItinerary, getShips } from "@/itinerary_dashboard/selectors"
import { setShip } from "@/itinerary_dashboard/Slice"
import Select from "@/components/input/Select"

const ShipDropdown: FC = () => {
  const dispatch = useDispatch()

  const { selectedShipId } = useSelector(getItinerary)
  const ships = useSelector(getShips)

  return (
    <Select
      label="Ship"
      floating
      value={selectedShipId}
      onChange={({ target }) => dispatch(setShip(target.value))}
    >
      <option value="">All available ships</option>
      {ships.map((ship) => (
        <option key={ship.id} value={ship.id}>
          {ship.name}
        </option>
      ))}
    </Select>
  )
}

export default ShipDropdown
