import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectOperator } from "@/onboarding/features/price_analysis/Slice"
import { getPriceAnalysis, getSeason } from "@/onboarding/features/price_analysis/selectors"

const OperatorDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedOperatorId } = useSelector(getPriceAnalysis)
  const season = useSelector(getSeason)

  const onNewOperator = (selectedOperatorId) => {
    dispatch(selectOperator({ operatorId: selectedOperatorId }))
  }

  return (
    <Form.Group>
      <Form.Label>Operator</Form.Label>
      <Form.Control
        as="select"
        value={selectedOperatorId}
        onChange={(e) => onNewOperator(e.target.value)}
      >
        <option value="">All</option>
        {season.operators.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default OperatorDropdown
