import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Slice, { initialize, setTableLoading } from "@/insurance/features/dashboard/Slice"
import { getInsurance } from "@/insurance/features/dashboard/selectors"
import { withApp } from "@/components/App"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import LoadingSpinner from "@/components/LoadingSpinner"
import { isReady } from "@/helpers/AsyncField"
import Dashboard from "../features/dashboard/components/Dashboard"

const App = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized, _view } = useSelector(getInsurance)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
    if (isReady(_view)) {
      dispatch(setTableLoading({ isTableLoading: false }))
    }
  }, [initialized, _view])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <div className="d-flex flex-column gap-3">
      <CruiseLineOnly>
        <Dashboard profiles={_view.value.operators} />
      </CruiseLineOnly>

      <OperatorOnly>
        <Dashboard profiles={_view.value.cruiseLines} />
      </OperatorOnly>
    </div>
  )
}

export default withApp(App, { slices: [Slice] })
