import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Table, Button } from "react-bootstrap"
import TextInput from "@/components/input/Text"
import NumberInput from "@/components/input/Number"
import { useModal } from "@/helpers/useModal"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { getView, getShowBulkEditForm, getBulkDepartures } from "../selectors"
import {
  addDraftBulkDeparture,
  removeDraftBulkDeparture,
  onBulkDepartureInputChange,
  onCancelBulkEdit,
  setShowBulkEditForm,
  addBulkDepartures,
} from "../Slice"
import ReasonModal from "./ReasonModal"

const ReplaceDepartures = (): JSX.Element => {
  const view = useSelector(getView)
  const showBulkEditForm = useSelector(getShowBulkEditForm)
  const bulkDepartures = useSelector(getBulkDepartures)
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [reasonPrompt, setreasonPrompt] = useState("")

  const onBulkDepartureSubmitCallback = async (reason) => {
    const dates = view.inventory.allocationTableData.callDays.map((callDay) => callDay.date)
    const bulkPayload = { departures: bulkDepartures, dates, reason }
    dispatch(setShowBulkEditForm({ showFormState: !showBulkEditForm }))
    dispatch(onCancelBulkEdit())
    await dispatch(addBulkDepartures(bulkPayload))
  }

  const toggleSubmitBulkDeparturesModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Submit"
      onSubmitCallback={onBulkDepartureSubmitCallback}
      header={header}
      {...props}
    />
  ))

  const toggleCruiselineSubmitBulkDeparturesModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Request"
      onSubmitCallback={onBulkDepartureSubmitCallback}
      header={header}
      {...props}
    />
  ))

  const onSubmit = async () => {
    setHeader("You are allocating departures in bulk")
    setreasonPrompt("Please provide a reason for this submission")
    toggleSubmitBulkDeparturesModal()
  }

  const onCruiselineSubmit = async () => {
    setHeader("You are requesting departures in bulk")
    setreasonPrompt("Please provide a reason for these requests")
    toggleCruiselineSubmitBulkDeparturesModal()
  }

  return (
    showBulkEditForm && (
      <>
        <div className="mt-3">
          <CruiseLineOnly>
            <h5 className="d-inline">Request departures in bulk</h5>
            <i
              title="On submit these departure requests will be added to all the resulting days in the
              allocation table below. Existing requests will be replaced."
              className="fas fa-info-circle d-inline ms-2 cursor-pointer"
            />
          </CruiseLineOnly>
          <OperatorOnly>
            <h5 className="d-inline">Add and replace departures in bulk</h5>
            <i
              title="On submit these departures will be added to all the resulting days in the
              allocation table below. Existing departures will be replaced."
              className="fas fa-info-circle d-inline ms-2 cursor-pointer"
            />
          </OperatorOnly>
        </div>
        <div style={{ width: "50%" }}>
          <Table className="mt-2">
            <thead className="thead-light">
              <tr>
                <th> Departure </th>
                <th> Duration </th>
                <th> Min </th>
                <th> Max </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {bulkDepartures.map((departure, index) => (
                    <React.Fragment key={index}>
                      <div className="d-flex align-items-center">
                        {bulkDepartures.length !== 1 && (
                          <div
                            className="cursor-pointer mt-n1"
                            onClick={() => dispatch(removeDraftBulkDeparture({ index }))}
                          >
                            <i className="fas fa-window-close fa-lg me-3 ms-n4" />
                          </div>
                        )}
                        <TextInput
                          style={{ maxWidth: "120px" }}
                          className="mb-2"
                          name="description"
                          label=""
                          placeholder={departure.departureTime === "" ? "00:00" : null}
                          value={departure.departureTime}
                          onChange={(e) =>
                            dispatch(
                              onBulkDepartureInputChange({
                                index,
                                property: "departureTime",
                                input: e.target.value,
                              })
                            )
                          }
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  <>
                    {bulkDepartures.map((departure, index) => (
                      <NumberInput
                        key={index}
                        style={{ maxWidth: "120px" }}
                        suffix="min"
                        className="mb-2"
                        name="description"
                        label=""
                        placeholder={0}
                        value={departure.durationInMinutes}
                        onChange={(e) =>
                          dispatch(
                            onBulkDepartureInputChange({
                              index,
                              property: "durationInMinutes",
                              input: e.target.value,
                            })
                          )
                        }
                      />
                    ))}
                  </>
                </td>
                <td>
                  {bulkDepartures.map((departure, index) => (
                    <NumberInput
                      key={index}
                      style={{ maxWidth: "120px" }}
                      className="mb-2"
                      name="description"
                      label=""
                      placeholder={0}
                      value={departure.minimumCapacity}
                      onChange={(e) =>
                        dispatch(
                          onBulkDepartureInputChange({
                            index,
                            property: "minimumCapacity",
                            input: e.target.value,
                          })
                        )
                      }
                    />
                  ))}
                </td>
                <td>
                  <>
                    {bulkDepartures.map((departure, index) => (
                      <NumberInput
                        key={index}
                        style={{ maxWidth: "120px" }}
                        className="mb-2"
                        name="description"
                        label=""
                        placeholder={0}
                        value={departure.maximumCapacity}
                        onChange={(e) =>
                          dispatch(
                            onBulkDepartureInputChange({
                              index,
                              property: "maximumCapacity",
                              input: e.target.value,
                            })
                          )
                        }
                      />
                    ))}
                    <Button
                      className="btn-sm btn-outline-light mt-1 ms-6"
                      onClick={() => dispatch(addDraftBulkDeparture())}
                    >
                      <i className="fas fa-plus cursor-pointer" />
                    </Button>
                  </>
                </td>
                <td>
                  <CruiseLineOnly>
                    <Button variant="primary" onClick={() => onCruiselineSubmit()}>
                      Submit
                    </Button>
                  </CruiseLineOnly>
                  <OperatorOnly>
                    <Button variant="primary" onClick={() => onSubmit()}>
                      Submit
                    </Button>
                  </OperatorOnly>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    )
  )
}

export default ReplaceDepartures
