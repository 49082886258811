import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import _ from "lodash"

import Voyage from "@/dashboard/models/Voyage"

const VoyageInfo = ({ voyage }: { voyage: Voyage }): JSX.Element => {
  const info = _.reject([voyage.itenerary, voyage.tripDescription], (e) => e.length == 0)

  if (info.length === 0) return null

  const shipName = (
    <OverlayTrigger
      key="shipName"
      placement="right"
      overlay={
        <Tooltip id="tooltip-shipName">
          {`${voyage.startTime} until ${voyage.endTime} (${voyage.days} total). ${voyage.daysRemaining}.`}
        </Tooltip>
      }
    >
      <span>{voyage.shipName}</span>
    </OverlayTrigger>
  )

  return (
    <h3 className="h5">
      {shipName}
      {`: ${_.join(info, ", ")}`}
    </h3>
  )
}

export default VoyageInfo
