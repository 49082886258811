import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectCruiseBrand } from "@/onboarding/features/price_analysis/Slice"
import { getPriceAnalysis, getSeason } from "@/onboarding/features/price_analysis/selectors"

const CruiseBrandDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedCruiseBrandId } = useSelector(getPriceAnalysis)
  const season = useSelector(getSeason)

  const onNewCruiseBrand = (selectedCruiseBrandId) => {
    dispatch(selectCruiseBrand({ cruiseBrandId: selectedCruiseBrandId }))
  }

  return (
    <Form.Group>
      <Form.Label>Cruise Brand</Form.Label>
      <Form.Control
        as="select"
        value={selectedCruiseBrandId}
        onChange={(e) => onNewCruiseBrand(e.target.value)}
      >
        <option value="">All</option>
        {season.cruiseBrands.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default CruiseBrandDropdown
