import { createSlice } from "@reduxjs/toolkit"

const Slice = createSlice({
  name: "guestRoomAccount",
  initialState: {
    initialized: false,
    guestId: "",
    payer: "",
    room: "",
    charges: "",
    passengers: "",
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
  },
})

export const { initialize } = Slice.actions

export default Slice
