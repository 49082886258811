import _ from "lodash"

import { isLoading } from "@/helpers/AsyncField"

import {
  getBookings as getAllReservationSystemBookings,
  Booking,
  getBookingsForSubmission,
} from "./reservationSystems"

export class ValidationError extends Error {
  constructor(message) {
    super(message)
    this.name = "ValidationError"
  }
}

/**
 * Returns all booking forms ordered by the time that they were added.
 */
const getOrderedBookingForms = (state): Array<Booking> =>
  _.sortBy(getAllReservationSystemBookings(state), "createdAtMillis")

const getChargedRoomAccountSearch = (state) => ({
  isLoading: isLoading(state.manualBooking._chargedRoomAccountSearch),
  results: state.manualBooking._chargedRoomAccountSearch.value,
})

const getApplicableCouponSearch = (state) => ({
  isLoading: isLoading(state.manualBooking._applicableCouponsSearch),
  results: state.manualBooking._applicableCouponsSearch.value,
})

const getFormSubmissionPayload = (state) => {
  if (_.isNil(state.manualBooking.chargedRoomAccount)) {
    throw new ValidationError("Room account must be selected to create a booking")
  }

  return {
    chargedRoomOccupancyId: state.manualBooking.chargedRoomAccount.payer.roomOccupancyId,
    bookings: getBookingsForSubmission(state),
  }
}

export {
  getOrderedBookingForms,
  getChargedRoomAccountSearch,
  getFormSubmissionPayload,
  getApplicableCouponSearch,
}
