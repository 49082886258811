import React from "react"
import { useSelector } from "react-redux"
import { getOperatorCheckIn } from "@/operator_check_in/selectors"

const OperatorCheckInInfo = (): JSX.Element => {
  const { tour } = useSelector(getOperatorCheckIn)

  return (
    <div>
      <h1 className="h4">{`Check-in for ${tour.name} (${tour.operatorName})`}</h1>
      <h2 className="h5">{`${tour.port}. ${tour.date} ${tour.timeslot}`}</h2>
    </div>
  )
}

export default OperatorCheckInInfo
