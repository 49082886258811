import React from "react"
import { Modal } from "react-bootstrap"

import ErrorBoundary from "@/components/ErrorBoundary"

const EditModal = ({
  show,
  onHide,
  title,
  Body,
  bodyProps = {},
}: {
  show: boolean
  onHide: () => void
  title: string
  Body: React.ComponentClass
  bodyProps: object
}): JSX.Element => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-modal-title" centered>
    <Modal.Header>
      <Modal.Title className="w-100" id="edit-modal-title">
        <div className="d-flex justify-content-between">
          <span>{`Edit ${title}`}</span>
          <span className="cursor-pointer" onClick={onHide}>
            <i className="fas fa-times" />
          </span>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ErrorBoundary>
        <Body {...bodyProps} />
      </ErrorBoundary>
    </Modal.Body>
  </Modal>
)

export default EditModal
