import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useDispatch } from "react-redux"

import _ from "lodash"

import LoadingSpinner from "@/components/LoadingSpinner"
import Wizard from "@/components/Wizard"
import SliceProvider, { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import createOperatorApplicationSlice from "./Slice"
import { getForm } from "./selectors"

import OperatorForm from "./pages/OperatorForm"
import AgreementForm from "./pages/AgreementForm"
import PortDetailsForm from "./pages/PortDetailsForm"

import OperatorQuestionsForm from "./pages/OperatorQuestionsForm"

import AuditView from "./pages/AuditView"

const PAGES = [
  {
    title: "Company Details",
    component: OperatorForm,
    href: "#company-details",
  },
  {
    title: "Port Details",
    component: PortDetailsForm,
    href: "#port-details",
  },
  {
    title: "Questionnaire",
    component: OperatorQuestionsForm,
    href: "#operator-questionnaire",
  },
  {
    title: "Requirements & Agreement",
    component: AgreementForm,
    href: "#confirm-agreement",
  },
]
const AppWithInitialization = (props) => {
  const dispatch = useDispatch()

  const { initialize } = useSliceActions()

  const { initialized, page, submitting } = useSliceSelector(getForm)

  let pages = PAGES

  // Only include audits when we are in review mode
  if (props.form.mode == "review") {
    pages = _.concat(pages, [
      {
        title: "Audit Trail",
        component: AuditView,
        href: "#audit-view",
      },
    ])
  }

  useEffect(() => {
    if (!initialized) dispatch(initialize(props.form))
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return <Wizard pages={pages} context={{ readOnly: props.form.mode == "review" }} />
}

const App = (props) => {
  const sliceName = props.sliceName || "operatorApplication"

  const slice = createOperatorApplicationSlice({ name: sliceName })

  return (
    <SliceProvider slice={slice}>
      <AppWithInitialization {...props} />
    </SliceProvider>
  )
}

export const AppCard = (props) => {
  let heading = ""

  if (props.form.mode == "edit") {
    heading = `Complete Operator Agreement between ${props.form.cruiseCompanyName} and ${props.form.operatorName}`
  } else {
    heading = `Operator Agreement between ${props.form.cruiseCompanyName} and ${props.form.operatorName}`
  }

  return (
    <Card>
      <Card.Header className="d-inline-flex flex-column gap-4">
        <h1 className="h4 mb-0">{heading}</h1>
      </Card.Header>
      <Card.Body>
        <App {...props} />
      </Card.Body>
    </Card>
  )
}

export default App
