import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import {
  getFieldUpdateContext,
  getFieldValues,
  getProductId,
} from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import Api from "@/api"
import AsyncSearch from "@/helpers/AsyncSearch"
import { deepSnakeizeKeys, isBlank } from "@/utils"
import Pricing from "@/pricing"
import { newAxiosErrorHandler } from "@/osn"

const SearchSection = ({ selectedValue, onSearchSelect }): JSX.Element => {
  const context = useSelector(getFieldUpdateContext)
  const normalizedSelectedValues = _([selectedValue]).compact().value()

  return (
    <Row>
      <Col md={4}>
        <Form.Group>
          <Form.Label>
            Preview Tour Values
            <i
              title="Search tours for a set of importable values and display a preview of the changes. Saving will replace the listed field values of the current tour with the imported tour values."
              className="fas fa-info-circle d-inline ms-2 cursor-pointer"
            />
          </Form.Label>
          <AsyncSearch
            selectedValues={normalizedSelectedValues}
            setSelectedValues={onSearchSelect}
            getOptions={(query) => Api.onboardingProduct.searchImportableProducts(context, query)}
            maxSelectable={1}
          />
          <Form.Text muted>Tour ideas and tours sharing this departure code.</Form.Text>
        </Form.Group>
      </Col>
    </Row>
  )
}

const buildComparisonTableData = (oldValues, newValues) => {
  const fieldNames = _.keys(newValues)

  return fieldNames.map((fieldName) => ({
    fieldName,
    oldValue: oldValues[fieldName],
    newValue: newValues[fieldName],
  }))
}

const FieldDisplay = ({ value }): JSX.Element => {
  if (!isBlank(value.kind) && _.startsWith(value.kind, "Pricing")) {
    return <Pricing.View value={value} />
  }

  if (!isBlank(value.ratesheet)) {
    return <Pricing.View value={value.ratesheet} />
  }

  if (_.isArray(value)) {
    if (_.isEmpty(value)) {
      return <i>No components</i>
    }

    return (
      <div className="d-flex flex-column gap-1">
        {value.map((item, index) => (
          <Row key={index}>
            <Col md={6}>
              <Table>
                <thead>
                  <tr>
                    <th>Component Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.name || "None specified"}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <FieldDisplay value={item} />
            </Col>
          </Row>
        ))}
      </div>
    )
  }

  return null
}

const FieldPreview = ({ setImporting, currentValues, previewValues }): JSX.Element => {
  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  if (_.isEmpty(previewValues)) {
    return (
      <div className="d-flex flex-column gap-3">
        <Row>
          <Col>
            <p className="mb-0">No difference in importable values. Please select another tour.</p>
          </Col>
        </Row>
        <div>
          <Button size="sm" variant="outline-danger" onClick={() => setImporting(false)}>
            <i className="fas fa-times me-2" />
            Cancel
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Formik
      initialValues={previewValues}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(updateFields({ values }))
      }}
    >
      {({ handleSubmit, values }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <Row>
            <Table>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Current Tour Values</th>
                  <th>Importable Tour Values</th>
                </tr>
              </thead>
              <tbody>
                {_.map(buildComparisonTableData(currentValues, values), (data) => (
                  <tr key={data.fieldName}>
                    <td>{_.startCase(data.fieldName)}</td>
                    <td>
                      <FieldDisplay value={data.oldValue} />
                    </td>
                    <td>
                      <FieldDisplay value={data.newValue} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <div className="d-flex gap-2">
            <Button size="sm" type="submit" disabled={isSubmitting}>
              <i className="fas fa-save me-2" />
              Import and Replace
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setImporting(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

const Importer = ({ setImporting, fieldNames }): JSX.Element => {
  const dispatch = useDispatch()
  const productId = useSelector(getProductId)
  const context = useSelector(getFieldUpdateContext)
  const currentValues = useSelector(getFieldValues(...fieldNames))
  const [previewProductContext, setPreviewProductContext] = useState(null)
  const [previewValues, setPreviewValues] = useState(null)

  const productSelected = !isBlank(previewProductContext)
  const previewLoaded = productSelected && !_.isNull(previewValues)

  const onSearchSelect = (results) => setPreviewProductContext(_.head(results))

  useEffect(() => {
    if (productSelected) {
      Api.onboardingProduct
        .fieldImportDiff(productId, fieldNames, context, previewProductContext.value)
        .then(({ data }) => setPreviewValues(deepSnakeizeKeys(data.data.diffValues)))
        .catch(newAxiosErrorHandler(dispatch))
    }
  }, [previewProductContext])

  return (
    <div className="d-flex flex-column gap-3">
      <SearchSection selectedValue={previewProductContext} onSearchSelect={onSearchSelect} />
      {previewLoaded ? (
        <FieldPreview
          setImporting={setImporting}
          currentValues={currentValues}
          previewValues={previewValues}
        />
      ) : (
        <div>
          <Button size="sm" variant="outline-danger" onClick={() => setImporting(false)}>
            <i className="fas fa-times me-2" />
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}

export default Importer
