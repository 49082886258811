import React from "react"
import { Button, Form } from "react-bootstrap"
import { Field } from "formik"
import { BlueLock } from "@/components/Icons"

const AddButton = ({ label, onClick }): JSX.Element => (
  <div>
    <Button size="sm" variant="outline-primary" onClick={onClick}>
      <i className="fas fa-plus me-2" />
      {label}
    </Button>
  </div>
)

const RemoveButton = ({ onClick }): JSX.Element => (
  <div>
    <Button size="sm" variant="outline-danger" onClick={onClick}>
      <i className="fas fa-times" />
    </Button>
  </div>
)

// This handles the default case of it being an empty string and allowing us to still perform array actions
const castValue = (value) => (value === "" ? [] : value)

const InputList = ({
  push,
  remove,
  form = undefined,
  name,
  label = "",
  fieldValuePath = "",
  readOnly = false,
  showControls = true,
  newValue = () => "",
  addLabel = "Add Value",
  showLockedIcon = false,
  showRequired = false,
}): JSX.Element => {
  const onRemove = (index) => () => {
    remove(index)

    if (form) {
      form.handleSubmit()
    }
  }

  const onAdd = () => {
    push(newValue())

    if (form) {
      form.handleSubmit()
    }
  }

  const valuePath = (index) =>
    fieldValuePath === "" ? `${name}.${index}` : `${name}.${index}.${fieldValuePath}`

  const showEmptyMessage = !showControls && readOnly

  return (
    <Field name={name}>
      {({ field }) => (
        <Form.Group>
          <div className="d-flex align-items-center">
            {showLockedIcon && (
              <BlueLock
                className="me-2 mt-n2 cursor-pointer"
                data-toggle="tooltip"
                data-placement="top"
                title="This value is fixed and cannot be edited"
              />
            )}
            {label !== "" && <Form.Label>{label}</Form.Label>}
            {showRequired && <span className="ms-1 mb-2">*</span>}
          </div>
          <div className="d-flex flex-column gap-2">
            {showEmptyMessage && castValue(field.value).length === 0 && (
              <span className="text-muted">No values specified</span>
            )}
            {castValue(field.value).map((_value, index) => (
              <div
                key={`${name}/${index}`}
                className="d-flex justify-content-between align-items-center gap-2"
              >
                <Field name={valuePath(index)}>
                  {(arrayFieldItem) => (
                    <Form.Control
                      {...arrayFieldItem.field}
                      readOnly={readOnly}
                      onBlur={() => {
                        if (readOnly) {
                          return
                        }

                        if (form) {
                          form.handleSubmit()
                        }
                      }}
                    />
                  )}
                </Field>
                {showControls && <RemoveButton onClick={onRemove(index)} />}
              </div>
            ))}
            {showControls && <AddButton label={addLabel} onClick={onAdd} />}
          </div>
        </Form.Group>
      )}
    </Field>
  )
}

export default InputList
