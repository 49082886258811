import React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"

import routes from "@/routes"
import getCSRFToken from "@/helpers/csrfToken"

const NewSectionForm = ({ documentId }) => (
  <Form action={routes.sections_path()} method="post">
    <Row>
      <Col md={6}>
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
        <input type="hidden" name="section[document_id]" value={documentId} />

        <Form.Group className="mb-4" controlId="section[name]">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="section[name]" placeholder="Enter section name..." />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Col>
    </Row>
  </Form>
)

export default NewSectionForm
