import React from "react"

import { Row, Col } from "react-bootstrap"

import BlockSpan from "./BlockSpan"

import VehicleAttribute from "./VehicleAttribute"

const VehicleResult = ({ result, actionButton }) => (
  <Row className="mb-2">
    <Col className="col-auto d-flex align-items-center">
      <img className="img" style={{ width: 100 }} src={result.imageUrl} />
    </Col>
    <Col className="col-auto">
      <BlockSpan>
        <b>{result.vehicleClassName}</b>
      </BlockSpan>
      <BlockSpan>{result.exampleVehicle} or similar</BlockSpan>

      <BlockSpan>
        <VehicleAttribute icon="bi bi-person-fill" text={result.passengers} />
        <VehicleAttribute icon="bi bi-gear-fill" text={result.transmission} />
      </BlockSpan>
      <BlockSpan>{result.aircon && <VehicleAttribute icon="bi bi-snow" text="Aircon" />}</BlockSpan>
      <BlockSpan>
        {result.unlimitedMileage && (
          <VehicleAttribute icon="bi bi-speedometer2" text="Unlimited Mileage" />
        )}
      </BlockSpan>
    </Col>
    <Col className="d-flex justify-content-end">
      <div className="align-self-center">
        <BlockSpan className="h4">{result.totalPrice.formatted}</BlockSpan>
        <BlockSpan>{`${result.perDayPrice.formatted} per day`}</BlockSpan>
        {actionButton}
      </div>
    </Col>
  </Row>
)

export default VehicleResult
