/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
;(function (window) {
  function openLinksInBackground() {
    const items = document.querySelectorAll("a.open_in_background")
    Array.prototype.forEach.call(items, (link) => {
      link.click()
    })
  }
  // https://stackoverflow.com/a/807997/2490686
  if (window.attachEvent) {
    window.attachEvent("onload", openLinksInBackground)
  } else if (window.onload) {
    const curronload = window.onload
    const newonload = function (evt) {
      curronload(evt)
      openLinksInBackground()
    }
    window.onload = newonload
  } else {
    window.onload = openLinksInBackground
  }
})(window)
