import React from "react"
import { Button, Form, Modal } from "react-bootstrap"

import AuthenticityToken from "@/components/AuthenticityToken"
import { cancel_port_dashboard_path } from "@/routes"

const CancelPortModal = ({ show, portId, portName, voyageId, date, onHide }): JSX.Element => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="cancel-tour-modal-title" centered>
    <Form
      action={cancel_port_dashboard_path({
        port_id: portId,
        voyage_id: voyageId,
        date,
        _options: true,
      })}
      method="post"
    >
      <AuthenticityToken />

      <Modal.Header>
        <Modal.Title id="cancel-tour-modal-title">
          Cancel All tours for port
          {portName}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Control
            as="textarea"
            placeholder="Why do you wish to cancel this port?"
            rows={3}
            name="reason"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="credit_room_account"
            value="1"
            label="Credit room account?"
            defaultChecked
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="notify_guests"
            value="1"
            label="Send cancellation message to guests?"
            defaultChecked
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button type="submit">Submit</Button>
      </Modal.Footer>
    </Form>
  </Modal>
)

export default CancelPortModal
