import React from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"

const displayRate = (rate) => (parseInt(rate, 10) / 100.0).toFixed(2)

const FlatRatesheetView = ({ value }) => (
  <Table>
    <thead className="mb-2">
      <tr>
        <th>Category</th>
        <th className="text-right">Rate</th>
      </tr>
    </thead>
    <tbody>
      {_.map(value.rates, (rate, ctg) => (
        <tr key={ctg}>
          <td>{_.capitalize(ctg)}</td>
          <td className="text-right">{displayRate(rate)}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export default FlatRatesheetView
