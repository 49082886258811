/* eslint-disable no-console */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@/sentry.ts.erb"
// Setup polyfills according to the webpacker 5-x-stable documentation
import "core-js/stable"
import "core-js/features/object/entries"
import "regenerator-runtime/runtime"
import "../components"

import Rails from "@rails/ujs"
import ReactRailsUJS from "react_ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// TODO Determine whether or not to move the following assets into their own pack
import "bootstrap-icons/font/bootstrap-icons.css"

import "@fortawesome/fontawesome-free/js/fontawesome"
import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/regular"
import "@fortawesome/fontawesome-free/js/brands"

import "react-bootstrap-typeahead/css/Typeahead.css"

// Primereact
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

import setupAxios from "@/helpers/setupAxios"
import { initialize as initializeFlipflop } from "@/helpers/Flipflop"

require.context("@/images", true)

Rails.start()
ActiveStorage.start()

/* global gon */
if (gon.global && gon.global.featureFlags) {
  initializeFlipflop(gon.global.featureFlags)
} else {
  console.warn("gon.global or gon.global.featureFlags is undefined")
}

ReactRailsUJS.useContext(require.context("@/", true, /components\//))

setupAxios()
