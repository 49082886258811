import _ from "lodash"

import { createSlice } from "@reduxjs/toolkit"
import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"
import { LoadState } from "@/helpers/AsyncField"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"

import { getForm } from "./selectors"

export const filter = AsyncField.createLoadThunk(
  "operatorProfileDashboard/filter",
  (_action) => "_view",
  async (form, thunkAPI) => {
    if (_.isEmpty(form)) {
      form = _.cloneDeep(getForm(thunkAPI.getState()))
    }

    const response = await Api.operatorProfileDashboard
      .filter(form)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

const Slice = createSlice({
  name: "operatorProfileDashboard",
  initialState: {
    initialized: false,
    _view: {},
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      initialized: true,
      _view: AsyncField.createField({
        value: payload.view,
        defaultValue: payload.view,
        state: LoadState.Ready,
      }),
    }),
  },
  extraReducers: {
    ...filter.reducers,
  },
})

export const { initialize } = Slice.actions

export default Slice
