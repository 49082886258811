import React, { FC } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import { TabTypes } from "@/itinerary_dashboard/Slice"
import { getItinerary } from "@/itinerary_dashboard/selectors"

import SeasonDropdown from "./SeasonDropdown"
import ItineraryDropdown from "./ItineraryDropdown"
import PortDropdown from "./PortDropdown"
import ShipDropdown from "./ShipDropdown"

const HeaderCard: FC = () => {
  const { selectedTab } = useSelector(getItinerary)

  return (
    <Card className="mt-n4">
      <Card.Header className="d-inline-flex flex-column gap-2">
        <div className="d-flex justify-content-between gap-4">
          <div className="d-flex flex-column gap-2">
            <h1 className="h4 mb-0">Itinerary Dashboard</h1>
          </div>
        </div>
        <Row>
          <Col md={3}>
            <SeasonDropdown />
          </Col>
          {selectedTab === TabTypes.ITINERARY && (
            <Col md={3}>
              <ItineraryDropdown />
            </Col>
          )}
          {selectedTab === TabTypes.PORT && (
            <Col md={3}>
              <PortDropdown />
            </Col>
          )}
          {selectedTab === TabTypes.SHIP && (
            <Col md={3}>
              <ShipDropdown />
            </Col>
          )}
        </Row>
      </Card.Header>
    </Card>
  )
}

export default HeaderCard
