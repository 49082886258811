import React from "react"
import _ from "lodash"

import { ComponentType, newComponent } from "@/onboarding/features/product/models/Component"
import Api from "@/api"

import Form from "./Form"

const New = ({
  ports,
  defaultRateCategories,
  defaultTiers,
  referenceValueOptions,
}): JSX.Element => {
  const formValues = _.extend(newComponent({ kind: ComponentType.SIMPLE, defaultRateCategories }), {
    enabled: true,
    portId: _(ports).map("id").head(),
    referenceValueOptions,
  })

  const api = (params) => Api.componentLibrary.create(params)

  return (
    <Form
      formValues={formValues}
      api={api}
      ports={ports}
      defaultRateCategories={defaultRateCategories}
      defaultTiers={defaultTiers}
    />
  )
}

export default New
