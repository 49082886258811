import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectCarRentalOperator as selectOperator } from "@/dashboard/Slice"
import {
  getAvailableCarRentalOperators,
  getSelectedCarRentalOperators,
} from "@/dashboard/selectors"

const FilterCarRentalOperator = () => {
  const dispatch = useDispatch()
  const availableOperators = useSelector(getAvailableCarRentalOperators)
  const selectedOperator = useSelector(getSelectedCarRentalOperators)

  const onChange = (event) => dispatch(selectOperator({ operator: event.target.value }))

  return (
    <div className="d-inline-flex">
      <Form.Control as="select" onChange={onChange} value={selectedOperator}>
        {availableOperators.map((operator) => (
          <option key={operator}>{operator}</option>
        ))}
      </Form.Control>
    </div>
  )
}

export default FilterCarRentalOperator
