import { createSlice } from "@reduxjs/toolkit"

import { createPayloadReducer } from "@/helpers/redux"

const Slice = createSlice({
  name: "productImport",
  initialState: {
    initialized: false,
    id: null,
    seasonId: null,
    finished: false,
    items: [],
  },
  reducers: {
    initialize: createPayloadReducer({ persistentState: { initialized: true } }),
  },
})

export const { initialize } = Slice.actions

export default Slice
