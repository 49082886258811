import React from "react"
import { Button } from "react-bootstrap"
import { FieldArray } from "formik"
import _ from "lodash"

import TextInput from "@/components/input/Text"

const ObjectList = ({ name, value, readOnly, onChange, onBlur, newObject }): JSX.Element => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <div className="d-flex flex-column gap-3">
        {value.map((object, index) => (
          <div
            key={`${name}.${index}`}
            className="d-flex justify-content-between gap-2 align-items-end"
          >
            {_.entries(object).map(([key, objectValue]) => (
              <TextInput
                key={key}
                label={_.startCase(key)}
                name={`${name}.${index}.${key}`}
                readOnly={readOnly}
                value={objectValue}
                onChange={onChange}
                onBlur={onBlur}
              />
            ))}
            {!readOnly && (
              <div>
                <Button size="sm" onClick={() => arrayHelpers.remove(index)} variant="danger">
                  Remove
                </Button>
              </div>
            )}
          </div>
        ))}
        {!readOnly && (
          <div>
            <Button size="sm" onClick={() => arrayHelpers.push(newObject())}>
              <i className="fas fa-plus me-2" />
              Add
            </Button>
          </div>
        )}
      </div>
    )}
  />
)

export default ObjectList
