import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column gap-2">
    <Row>
      <Col md={8}>
        <h5>Edit Details</h5>
        <Row>
          <Col md={5}>
            <FormikBootstrap.TextInput name="name" label="Name" />
          </Col>
          <Col md={5}>
            <ReferencedValueSelect
              name="venueType"
              type={ReferencedValueTypes.VENUE_TYPE}
              label="Venue Type"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={5}>
            <FormikBootstrap.NumberInput name="steps" label="Steps" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="distanceFromParking" label="Distance From Parking" />
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <FormikBootstrap.TextInput name="description" label="Description" />
          </Col>
        </Row>
        <Row>
          <Col md={5} className="mt-3">
            <FormikBootstrap.Checkbox name="wheelchairAccessible" label="Wheelchair Accessible?" />
          </Col>
          <Col md={5} className="mt-3">
            <FormikBootstrap.Checkbox name="restroomsAvailable" label="Restrooms Available?" />
          </Col>
        </Row>
      </Col>
      <Col md={4} className="ms-n6">
        <h5 className="mt-0">Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <Row className="d-flex">
      <Col md={hideRates ? 9 : 6}>
        <h5>Details</h5>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td aria-label="Name">
                <TextDisplay value={component.name} />
              </td>
              <td aria-label="Venue Type">
                <TextDisplay value={component.venueType} />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Steps</th>
              <th>Distance From Parking</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td aria-label="Steps">
                <TextDisplay value={component.steps} />
              </td>
              <td aria-label="Distance From Parking">
                <TextDisplay value={component.distanceFromParking} />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th colSpan={2}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td aria-label="Description" colSpan={2}>
                <TextDisplay value={component.description} />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Wheelchair Accessible?</th>
              <th>Restrooms Available?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td aria-label="Wheelchair Accessible?">
                <TextDisplay value={component.wheelchairAccessible} />
              </td>
              <td aria-label="Restrooms Available?">
                <TextDisplay value={component.restroomsAvailable} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>

      {!hideRates && (
        <Col md={3} className="ms-5">
          <h5>Rates</h5>
          <Pricing.View value={component.ratesheet} />
        </Col>
      )}
    </Row>
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
