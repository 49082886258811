import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { FieldArray } from "formik"

import { getState } from "@/document_editor/selectors"

// TODO This component probably no longer works correctly!
const newComponent = () => ({
  name: "",
  operatorId: "",
  adultRate: 0,
  childRate: 0,
})

const TourComponentList = ({ name, value, readOnly, onChange, onBlur }): JSX.Element => {
  const { availableOperators } = useSelector(getState)

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className="d-flex flex-column gap-3">
          {value.map((component, index) => (
            <Row key={`${name}.${index}`} className="align-items-end">
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name={`${name}.${index}.name`}
                    readOnly={readOnly}
                    value={component.name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Operator</Form.Label>
                  <Form.Control
                    as="select"
                    name={`${name}.${index}.operatorId`}
                    readOnly={readOnly}
                    value={component.operatorId}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    <option value="">Select an operator</option>
                    {availableOperators.map((operator) => (
                      <option key={operator.id} value={operator.id}>
                        {operator.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Adult Rate</Form.Label>
                  <Form.Control
                    name={`${name}.${index}.adultRate`}
                    readOnly={readOnly}
                    value={component.adultRate}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Child Rate</Form.Label>
                  <Form.Control
                    name={`${name}.${index}.childRate`}
                    readOnly={readOnly}
                    value={component.childRate}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              {!readOnly && (
                <Col>
                  <Button size="sm" onClick={() => arrayHelpers.remove(index)} variant="danger">
                    Remove
                  </Button>
                </Col>
              )}
            </Row>
          ))}
          {!readOnly && (
            <Row>
              <Col>
                <Button size="sm" onClick={() => arrayHelpers.push(newComponent())}>
                  <i className="fas fa-plus me-2" />
                  Add Component
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    />
  )
}

export default TourComponentList
