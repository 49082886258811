import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <>
    <h5>Edit Details</h5>
    <Row>
      <Col md={3}>
        <FormikBootstrap.TextInput name="name" label="Name" />
      </Col>
      <Col md={3}>
        <ReferencedValueSelect
          name="entertainmentType"
          type={ReferencedValueTypes.ENTERTAINMENT_TYPE}
          label="Type"
        />
      </Col>
      <Col md={3}>
        <FormikBootstrap.NumberInput name="duration" label="Duration (Minutes)" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.TextInput name="website" label="Website" />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormikBootstrap.NumberInput name="capacity" label="Capacity" />
      </Col>
      <Col md={9}>
        <FormikBootstrap.TextInput name="description" label="Description" />
      </Col>
    </Row>
    <Col md={3}>
      <h5 className="mt-4">Edit Rates</h5>
      <RateInputs name="ratesheet" />
    </Col>
  </>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <h5>Details</h5>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Duration (Minutes)</th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Entertainment Type">
            <TextDisplay value={component.entertainmentType} />
          </td>
          <td aria-label="Duration">
            <TextDisplay value={component.duration} />
          </td>
          <td aria-label="Website">
            <TextDisplay value={component.website} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Capacity</th>
          <th colSpan={3}>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Capacity">
            <TextDisplay value={component.capacity} />
          </td>
          <td aria-label="Description" colSpan={3}>
            <TextDisplay value={component.description} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <Col md={3}>
        <h5 className="mt-2">Rates</h5>
        <Pricing.View value={component.ratesheet} />
      </Col>
    )}
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
