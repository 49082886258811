import React from "react"
import { ListGroup, Row, Col, Image } from "react-bootstrap"

import Room from "@/search_results/models/Room"

const RoomItem = ({ room }: { room: Room }): JSX.Element => (
  <ListGroup.Item>
    <div className="d-flex justify-content-between">
      <h2 className="h5">{room.roomNumber}</h2>
    </div>
    <ListGroup variant="flush">
      {room.guests.map((guest) => (
        <ListGroup.Item key={guest.email}>
          <Row className="align-items-center">
            <Col className="col-auto">
              <div className="avatar">
                <Image src={guest.profilePicturePath} roundedCircle />
              </div>
            </Col>
            <Col className="ms--2">
              <h4 className="h6 mb-0">{guest.name}</h4>
              <small>{guest.email}</small>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </ListGroup.Item>
)

const RoomListGroup = ({ rooms }: { rooms: Array<Room> }): JSX.Element => (
  <ListGroup>
    {rooms.map((room) => (
      <RoomItem key={room.roomNumber} room={room} />
    ))}
  </ListGroup>
)

export default RoomListGroup
