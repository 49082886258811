import React, { useEffect, useState } from "react"

import PageBar from "./views/PageBar"

import WizardContext, { initializePages, updatePageValidity } from "./WizardContext"

const Wizard = ({ pages: _pages, activePageIndex: _activePageIndex, context }) => {
  const [isInitialized, setInitialized] = useState(false)

  const [pages, setPages] = useState([])

  const [activePage, setPage] = useState(null)

  const setPageValidity = (page, isValid) =>
    setPages((oldPages) => updatePageValidity(oldPages, page, isValid))

  useEffect(() => {
    if (!isInitialized) {
      const initPages = initializePages(_pages)

      setPages(initPages)
      setPage(initPages[_activePageIndex || 0])

      setInitialized(true)
    }
  }, [isInitialized])

  const providerValue = React.useMemo(
    () => ({
      pages,
      setPages,
      activePage,
      setPage,
      context,
      setPageValidity,
    }),
    [pages, activePage, context]
  )

  return (
    <WizardContext.Provider value={providerValue}>
      {isInitialized && <PageBar />}
    </WizardContext.Provider>
  )
}

export * from "./WizardContext"
export default Wizard
