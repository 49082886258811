import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import FormToolkit from "@/form_toolkit"
import {
  getActiveBid,
  getFeatures,
  getForm,
  getProduct,
  isProductReady,
} from "@/onboarding/features/product/selectors"
import { filter } from "@/onboarding/features/product/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"

import SeasonDropdown from "./header/SeasonDropdown"
import TourStatus from "./header/TourStatus"
import BidStatus from "./header/BidStatus"
import ToolsDropdown from "./header/ToolsDropdown"
import ToggleFormsButton from "./header/ToggleFormsButton"
import DetailsConfirmationStatus from "./header/DetailsConfirmationStatus"

const Select = FormToolkit.buildReduxSingleSelect(getForm, filter)

const DetailRow = ({ label, children }) => (
  <div>
    <span style={{ fontWeight: "bold" }}>{label}:</span>
    <span className="d-inline ms-2" style={{ display: "block" }}>
      {children}
    </span>
  </div>
)

const Header: FC = () => {
  const product = useSelector(getProduct)
  const productReady = useSelector(isProductReady)
  const features = useSelector(getFeatures)
  const activeBid = useSelector(getActiveBid)

  const MainDetailsDisplay = () => (
    <div className="mb-3">
      {product.incumbentCompany && (
        <DetailRow label="Incumbent Operator">{product.incumbentCompany.name}</DetailRow>
      )}
      <DetailRow label="Owner">{product.managingCompany.name}</DetailRow>
      <DetailRow label="Tour Status">
        <TourStatus />
      </DetailRow>
      {activeBid && (
        <DetailRow label="Bid Status">
          <BidStatus />
        </DetailRow>
      )}
      <DetailRow label="Port">{`${product.port.name} (${product.port.countryName})`}</DetailRow>
      {product.biddingRegion && (
        <DetailRow label="Bidding Region">{product.biddingRegion.name}</DetailRow>
      )}
      {product.cruiseBrands && (
        <DetailRow label="Cruise Brands">
          {product.cruiseBrands.map((brand) => brand.initials).join(", ")}
        </DetailRow>
      )}
      {product.detailsConfirmation && (
        <DetailRow label="Tour Details">
          <DetailsConfirmationStatus />
        </DetailRow>
      )}
    </div>
  )

  return (
    <div className="d-flex flex-column gap-2">
      <Row>
        <Col md={8} className="d-flex flex-column">
          <h1 className="h4 mb-0 d-flex gap-2">
            {`${product.name} - ${product.code}`}
            {!productReady && <LoadingSpinner />}
          </h1>
        </Col>
        <Col className="d-inline-flex justify-content-end gap-2">
          <ToggleFormsButton />
          <div>
            <ToolsDropdown />
          </div>
        </Col>
      </Row>
      <MainDetailsDisplay />
      <Row>
        {features.general.product.showSeasonsFilter && (
          <Col md={2}>
            <SeasonDropdown />
          </Col>
        )}
        {features.general.product.showRatePeriods && (
          <Col md={2}>
            <Select name="contractPeriodId" label="Rate Periods" />
          </Col>
        )}
        <Col md={2}>{features.general.product.toggleBids && <Select name="bidId" />}</Col>
      </Row>
    </div>
  )
}

export default Header
