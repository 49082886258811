import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"

import { initialize } from "./Slice"
import { getImport } from "./selectors"
import ImportTable from "./ImportTable"

const ImportStatus = ({ productImport }): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getImport)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(productImport))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return <ImportTable />
}

export default ImportStatus
