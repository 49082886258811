import React from "react"
import {
  OverlayTrigger,
  Popover,
  ListGroup,
  ListGroupItem,
  PopoverHeader,
  PopoverBody,
} from "react-bootstrap"

import { tour_booking_path } from "@/routes"
import Booking, { ReservationStates } from "@/dashboard/models/Booking"

import PassengerInfo from "@/dashboard/components/tour_list_card/PassengerInfo"
import BookingDocuments from "@/dashboard/components/tour_list_card/BookingDocuments"
import ConfirmedBookingTools from "@/dashboard/components/tour_list_card/ConfirmedBookingTools"
import BookingBadge from "@/dashboard/components/tour_list_card/BookingBadge"
import WaitlistedBookingTools from "@/dashboard/components/tour_list_card/WaitlistedBookingTools"
import CancelledBookingTools from "@/dashboard/components/tour_list_card/CancelledBookingTools"

const PrivateVillaBookingRow = ({ booking }: { booking: Booking }): JSX.Element => {
  let toolsAction
  let badge = null

  if (booking.reservationState === ReservationStates.CONFIRMED) {
    toolsAction = <ConfirmedBookingTools booking={booking} />
  } else if (booking.reservationState === ReservationStates.WAITLISTED) {
    toolsAction = <WaitlistedBookingTools booking={booking} />
    badge = <BookingBadge variant="warning" text="waitlist" />
  } else if (booking.reservationState === ReservationStates.CANCELLED) {
    toolsAction = <CancelledBookingTools booking={booking} />
    badge = <BookingBadge variant="danger" text="cancelled" />
  }

  const passengerHover = (
    <Popover id={`${booking.reference}-other-passenger-popover`}>
      <PopoverHeader>Other Guests</PopoverHeader>
      <PopoverBody>
        <ListGroup>
          {booking.otherPassengers.map((passenger) => (
            <ListGroupItem key={passenger.email}>
              <PassengerInfo passenger={passenger} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </PopoverBody>
    </Popover>
  )

  const requirementsHover = (
    <Popover id={`${booking.reference}-requirements-popover`}>
      <PopoverHeader>Special Requirements</PopoverHeader>
      <PopoverBody>
        <div dangerouslySetInnerHTML={{ __html: booking.specialRequirementsFormatted }} />
      </PopoverBody>
    </Popover>
  )

  return (
    <tr>
      <td>
        <div className="d-inline-flex flex-column">
          <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="bottom"
            overlay={requirementsHover}
          >
            <div>
              <a className="hover-underline" href={tour_booking_path({ id: booking.bookingId })}>
                {booking.reference}
              </a>
              {badge}
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        <OverlayTrigger trigger="click" placement="bottom" overlay={passengerHover}>
          <div>
            <span className="d-block">{booking.leadPassenger.name}</span>
            <span className="d-block">{booking.leadPassenger.roomNumber}</span>
          </div>
        </OverlayTrigger>
      </td>
      <td className="text-right">{booking.guests}</td>
      <td className="text-right">{booking.retailPrice}</td>
      <td>
        <div className="d-flex gap-2 justify-content-end">
          <BookingDocuments booking={booking} />
          {toolsAction}
        </div>
      </td>
    </tr>
  )
}

export default PrivateVillaBookingRow
