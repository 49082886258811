import _ from "lodash"

import * as AsyncField from "@/helpers/AsyncField"

export const getSettlement = ({ settlement }) => settlement

export const getGuestIds = ({ settlement }) => _.map(settlement.guests, "guestId")

export const isPriceStale = (state) => AsyncField.isStale(getSettlement(state)._priceBreakdown)

export const isPriceReady = (state) => AsyncField.isReady(getSettlement(state)._priceBreakdown)

export const getPriceBreakdown = (state) => getSettlement(state)._priceBreakdown.value

export const isInitialized = ({ settlement }) => settlement.initialized

export const isEditing = ({ settlement }) => settlement.editing

export const requiresShxSignature = ({ settlement }) => settlement.signedShxAgent === null

export const requiresOperatorSignature = ({ settlement }) => settlement.signedTourAgent === null
