import React from "react"

const StatusIcon = ({ status, label }) => {
  let icon
  switch (status) {
    case "incomplete":
      icon = "fas fa-exclamation-triangle text-danger"
      break
    case "expired":
      icon = "fa fa-clock text-danger"
      break
    case "archived":
      icon = "fa fa-clock text-muted"
      break
    case "pending":
      icon = "fas fa-hourglass-half text-warning"
      break
    case "rejected":
      icon = "fas fa-times text-danger"
      break
    case "approved":
      icon = "fas fa-check-circle text-success"
      break
    case "approvedWithException":
      icon = "fas fa-check-double text-success"
      break
    case "approved_with_exception":
      icon = "fas fa-check-double text-success"
      break
    default:
      icon = ""
  }

  return (
    <div key={icon} className="d-inline-block me-1">
      <span className={icon} title={label} />
    </div>
  )
}

export default StatusIcon
