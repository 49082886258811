import React from "react"
import { Button, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { submitEvents } from "@/operator_check_in/Slice"
import { getOperatorCheckIn } from "@/operator_check_in/selectors"

const SubmitButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const { submitting, pendingEvents } = useSelector(getOperatorCheckIn)

  if (submitting) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Submitting...</span>
      </Spinner>
    )
  }

  const onClick = () => dispatch(submitEvents({ events: pendingEvents }))

  return (
    <div>
      <Button disabled={pendingEvents.length === 0} onClick={onClick}>
        Submit
      </Button>
    </div>
  )
}

export default SubmitButton
