import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { FieldArray } from "formik"

const TextList = ({ name, value, readOnly, onChange, onBlur }): JSX.Element => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <div className="d-flex flex-column gap-3">
        {value.map((val, index) => (
          <Row key={`${name}.${index}`} className="align-items-end">
            <Col md={9}>
              <Form.Group>
                <Form.Control
                  name={`${name}.${index}`}
                  readOnly={readOnly}
                  value={val}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            {!readOnly && (
              <Col>
                <Button size="sm" onClick={() => arrayHelpers.remove(index)} variant="danger">
                  Remove
                </Button>
              </Col>
            )}
          </Row>
        ))}
        {!readOnly && (
          <Row>
            <Col>
              <Button size="sm" onClick={() => arrayHelpers.push("")}>
                <i className="fas fa-plus me-2" />
                Add Value
              </Button>
            </Col>
          </Row>
        )}
      </div>
    )}
  />
)

export default TextList
