import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { fetchPriceBreakdown, initializeSheet } from "@/settlement/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"
import { isInitialized, isPriceStale } from "@/settlement/selectors"

import Header from "./card/Header"
import Body from "./card/Body"

const SettlementCard = ({ settlementSheet }): JSX.Element => {
  const dispatch = useDispatch()

  const initialized = useSelector(isInitialized)
  const priceStale = useSelector(isPriceStale)

  useEffect(() => {
    if (!initialized) {
      dispatch(initializeSheet(settlementSheet))
    }

    if (initialized && priceStale) {
      dispatch(fetchPriceBreakdown({}))
    }
  }, [initialized, priceStale])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return (
    <Card border="light" className="shadow-sm">
      <Header />
      <Body />
    </Card>
  )
}

export default SettlementCard
