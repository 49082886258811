import React from "react"
import { Table } from "react-bootstrap"
import InventoryAllocationTableRows from "./InventoryAllocationTableRows"

const InventoryAllocationTable = () => (
  <Table className="ms-4">
    <thead className="thead-light">
      <tr>
        <th> Date </th>
        <th> Ship </th>
        <th> Time in port </th>
        <th> Requirements </th>
        <th> Departure </th>
        <th> Duration </th>
        <th> Min </th>
        <th> Max </th>
        <th> Issues </th>
        <th> Status </th>
        <th> Action </th>
        <th> Chat </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <InventoryAllocationTableRows />
    </tbody>
  </Table>
)

export default InventoryAllocationTable
