import React from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { navigateToComparisonPage } from "@/onboarding/features/price_analysis/Slice"
import { getSelectedProspectiveAwardSet } from "@/onboarding/features/price_analysis/selectors"

const CompareButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const awardSet = useSelector(getSelectedProspectiveAwardSet)

  return (
    <Button size="sm" disabled={!awardSet} onClick={() => dispatch(navigateToComparisonPage())}>
      <span className="fas fa-chart-bar me-2" />
      Compare and Award Bids
    </Button>
  )
}

export default CompareButton
