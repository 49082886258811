import React, { Fragment } from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"
import { submitFieldSettings } from "@/onboarding/features/product/Slice"
import { getProduct } from "@/onboarding/features/product/selectors"

const FieldRow = ({ field, index }) => (
  <tr>
    <td>{_.startCase(field.name)}</td>
    <td className="text-center">
      <FormikBootstrap.Checkbox
        name={`settings.fields.${index}.writeable`}
        type="switch"
        checked={field.writeable}
      />
    </td>
    <td className="text-center">
      <FormikBootstrap.Checkbox
        name={`settings.fields.${index}.required`}
        type="switch"
        checked={field.required}
      />
    </td>
  </tr>
)

const FieldSettingRows = ({ fields }) => {
  const fieldsWithIndices = fields.map((field, index) => ({ field, index }))
  const fieldsBySection = _.groupBy(fieldsWithIndices, ({ field }) => field.section.id)

  return _.map(fieldsBySection, (groupedFields, sectionId) => (
    <Fragment key={sectionId}>
      <tr className="table-active">
        <th colSpan={3} scope="row">
          {_.startCase(_.head(groupedFields).field.section.name)}
        </th>
      </tr>
      {groupedFields.map(({ field, index }) => (
        <FieldRow key={field.id} field={field} index={index} />
      ))}
    </Fragment>
  ))
}

const FieldSettingsModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()

  const { fieldSettings } = useSelector(getProduct)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="modify-field-settings-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="modify-field-settings-modal-title">Modify Field Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <Formik
          initialValues={{ settings: fieldSettings }}
          onSubmit={({ settings }, { setSubmitting }) => {
            setSubmitting(true)
            dispatch(submitFieldSettings(settings))
            setSubmitting(false)
          }}
        >
          {({ handleSubmit, values, isSubmitting }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Table>
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col" className="text-center">
                      Editable?
                    </th>
                    <th scope="col" className="text-center">
                      Required?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <FieldSettingRows fields={values.settings.fields} />
                </tbody>
              </Table>
              <div className="d-flex gap-3">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button variant="danger" onClick={onHide} disabled={isSubmitting}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default FieldSettingsModal
