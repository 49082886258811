import React from "react"
import { Form } from "react-bootstrap"
import { useField } from "formik"

import FloatableGroup from "./FloatableGroup"

const File = ({ label = undefined, floating = false, ...props }): JSX.Element => {
  const [field, meta, helpers] = useField(props)

  return (
    <FloatableGroup label={label} floating={floating}>
      <Form.Control
        type="file"
        {...props}
        onChange={(event) =>
          helpers.setValue({ ...props.value, value: event.currentTarget.files[0] })
        }
      />
    </FloatableGroup>
  )
}

export default File
