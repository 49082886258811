import _ from "lodash"

import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"

import { getStaleTimelineFieldsHash } from "./VoyageTimelineSelectors"

const fetchTimeline = AsyncField.createLoadThunk(
  "manualBooking/fetchVoyageTimeline",
  (action) => `voyageTimeline.fields.${action.payload.fieldId}`,
  async ({ date }) => {
    const response = await Api.manualBooking.fetchVoyageTimeline(date)

    return {
      value: response.data.data.voyageTimeline,
    }
  }
)

const fetchStaleTimelines = (state, dispatch) => {
  _.each(getStaleTimelineFieldsHash(state), (field, key) => {
    dispatch(fetchTimeline({ fieldId: key, date: AsyncField.getValue(field) }))
  })
}

export { fetchTimeline, fetchStaleTimelines }
