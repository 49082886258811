import React, { ChangeEventHandler } from "react"
import { Row, Col, Form, Spinner } from "react-bootstrap"
import _ from "lodash"

import { LoadState } from "@/helpers/useDelayedState"

export interface TourOption {
  id: string
  label: string
  closedForSales: boolean
}

export enum AvailabilitySelectFormat {
  RADIO = "radio",
  DROPDOWN = "dropdown",
}

const TourAvailability = ({
  tourOptions,
  inputName,
  onChange,
  onReload,
  selected,
  loadState,
  spread = false,
  format = AvailabilitySelectFormat.RADIO,
  ...props
}: {
  tourOptions: Array<TourOption>
  inputName?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onReload?: () => void
  loadState: LoadState
  selected: string
  format?: string
  spread: boolean
}): JSX.Element => {
  const changeHandler = (e) => {
    onChange && onChange(e.target.value)
  }

  let renderOptions

  switch (format) {
    case AvailabilitySelectFormat.RADIO: {
      renderOptions = () => {
        const children = tourOptions.map(({ label, id, closedForSales }) => {
          const renderLabel = () =>
            closedForSales ? <span className="text-danger">{label}</span> : <span>{label}</span>

          return (
            <Col xs={spread ? undefined : 12} className={spread ? "col-auto" : undefined} key={id}>
              <Form.Check
                type="radio"
                name={inputName}
                label={renderLabel()}
                value={id}
                checked={id == selected}
                onChange={changeHandler}
                key={id}
                {...props}
              />
            </Col>
          )
        })

        return <Row>{children}</Row>
      }
      break
    }
    case AvailabilitySelectFormat.DROPDOWN: {
      renderOptions = () => (
        <Form.Control as="select" name={inputName} onChange={changeHandler} value={selected}>
          {tourOptions.map(({ label, id }) => (
            <option value={id} key={id} {...props}>
              {label}
            </option>
          ))}
        </Form.Control>
      )
      break
    }
    default: {
      throw new Error(`The following tour availability format is not supported: ${format}`)
    }
  }

  switch (loadState) {
    // TODO can we abstract the idea of an unloaded / loadable component?
    case LoadState.Loading: {
      return <Spinner className="d-block" animation="border" />
    }
    case LoadState.Stale: {
      return <Spinner className="d-block" animation="border" />
    }
    case LoadState.Failed: {
      if (onReload) {
        return (
          <div className="d-block">
            <i>There was a problem reaching the server</i>
            <i className="fs-3 bi bi-arrow-counterclockwise align-middle" onClick={onReload} />
          </div>
        )
      }

      return <i className="d-block">There was a problem reaching the server</i>
    }
    default: {
      if (_.isEmpty(tourOptions)) {
        return <i className="d-block">No departure times for this tour</i>
      }

      return renderOptions()
    }
  }
}

export const TourAvailabilityDropdown = (props) => (
  <TourAvailability {...props} format={AvailabilitySelectFormat.DROPDOWN} />
)

export default TourAvailability
