import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

import { withApp } from "@/components/App"
import LoadingSpinner from "@/components/LoadingSpinner"
import { getFeedback, getPassengerID, getToursPerPort, getTours } from "@/guests_feedback/selectors"
import Slice, { initialize } from "@/guests_feedback/Slice"
import Navbar from "@/guests_footer/Navbar"

import StarRating from "./StarRating"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getFeedback)
  const passengerID = useSelector(getPassengerID)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return <Navbar page="Feedback" guestId={passengerID} />
}

// TODO Move to own component files and probably implement state within redux
const Selectors = ({ setSelectedTourName }): JSX.Element => {
  const [selectedPortName, setSelectedPortName] = useState("All")

  useEffect(() => {
    setSelectedPortName(selectedPortName)
  }, [selectedPortName])

  const toursPerPort = useSelector(getToursPerPort)
  const allTours = useSelector(getTours)

  let tours = {}

  if (toursPerPort.length > 0) {
    if (selectedPortName != "All") {
      const found = toursPerPort.find((element) => element.name == selectedPortName)
      tours = found.tours
    } else {
      tours = allTours
    }
  }

  return (
    <div className="mt-2">
      <select
        style={{ appearance: "none" }}
        className="btn btn-primary px-3"
        id="ports"
        defaultValue="All"
        onChange={(e) => setSelectedPortName(e.target.value)}
      >
        <option value="All">All Ports</option>
        {_.map(toursPerPort, (port) => (
          <option key={port.name} value={port.name}>
            {port.name}
          </option>
        ))}
      </select>

      <select
        style={{ appearance: "none" }}
        className="btn btn-primary ms-4 px-3"
        id="ports"
        onChange={(e) => setSelectedTourName(e.target.value)}
      >
        <option value="All" style={{ display: "none" }}>
          Select Tour
        </option>

        {selectedPortName == "All"
          ? _.map(allTours, (tour) => (
              <option key={tour.name} value={tour.name}>
                {tour.name}
              </option>
            ))
          : _.map(tours, (tour) => (
              <option key={tour.name} value={tour.name}>
                {tour.name}
              </option>
            ))}
      </select>
      {_.isEmpty(allTours) ? <p className="mt-3">You have no experiences to review</p> : <p />}
    </div>
  )
}

const Feedback = (): JSX.Element => {
  const [selectedTourName, setSelectedTourName] = useState("")
  const [review, setReview] = useState("")

  useEffect(() => {
    setSelectedTourName(selectedTourName)
  }, [selectedTourName])

  return (
    <>
      <h1 className="mb-n1">Tour Feedback</h1>
      <div className="pb-6">
        <Selectors setSelectedTourName={setSelectedTourName} />
        <h2 className="mt-4">{selectedTourName}</h2>
        <div className="mb-3">
          <textarea
            placeholder="My experience"
            autoFocus
            className="form-control"
            id="experienceTextArea"
            rows={6}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
        <div className="text-center">
          <label className="form-label mt-3 mb-n2">Rating out of 5</label>
          <StarRating />
          <Button disabled={selectedTourName == ""}>Submit</Button>
        </div>
      </div>
    </>
  )
}

const App = (props): JSX.Element => (
  <>
    <Feedback />
    <Initializer {...props} />
  </>
)

export default withApp(App, { slices: [Slice] })
