import { withApp } from "@/components/App"

import { AppCard } from "@/onboarding/features/operator_application/App"

import createOperatorApplicationSlice from "@/onboarding/features/operator_application/Slice"

const operatorApplicationSlice = createOperatorApplicationSlice({ name: "operatorApplication" })

export default withApp(AppCard, {
  slices: [operatorApplicationSlice],
})
