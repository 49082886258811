import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"

import { disputeSheet } from "@/settlement/Slice"

import SettlementTotalSummary from "./SettlementTotalSummary"

const DisputeSettlementModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()
  const [comment, setComment] = useState("")

  const onSubmit = () => dispatch(disputeSheet({ comment }))

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="dispute-settlement-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="dispute-settlement-modal-title">Confirm Dispute of Settlement</Modal.Title>
        <SettlementTotalSummary />
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Comment</Form.Label>
          <Form.Control
            type="text"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DisputeSettlementModal
