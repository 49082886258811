import React from "react"
import { Table } from "react-bootstrap"

import * as DocUtils from "@/document_editor/document_selectors"

const BrochureView = ({ context, document }): JSX.Element => (
  <div>
    <div className="text-center">
      <span className="display-5">
        {" "}
        {context.location} - IN PORT
        {context.portArrivalDay} -{context.portArrivalTime} -{context.portDepartureTime}{" "}
      </span>
    </div>
    <Table responsive>
      <thead>
        <tr>
          <th> Code </th>
          <th> Tour Description </th>
          <th> Details </th>
          <th> Price </th>
          <th> Departs </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fit"> {context.tourCode} </td>
          <td>
            <span className="text-uppercase">
              {" "}
              {context.name} -{context.duration}{" "}
            </span>
            <br />
            {DocUtils.fieldValue(document, "description")}
          </td>
          <td> {} </td>
          <td>
            {context.currencySymbol} {DocUtils.fieldValue(document, "adult_rate", "N/A")} / Adult
            <br />
            {context.currencySymbol} {DocUtils.fieldValue(document, "child_rate", "N/A")} / Child
          </td>
          <td> {DocUtils.fieldValue(document, "start_time", "N/A")} </td>
        </tr>
      </tbody>
    </Table>
  </div>
)

export default BrochureView
