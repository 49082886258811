import React, { FC } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { toggleFormsExpanded } from "@/onboarding/features/product/Slice"
import { canEditProduct, getFormsExpanded } from "@/onboarding/features/product/selectors"

const ToggleFormsButton: FC = () => {
  const dispatch = useDispatch()
  const isEditable = useSelector(canEditProduct)
  const formsExpanded = useSelector(getFormsExpanded)

  if (!isEditable) {
    return null
  }

  const text = formsExpanded ? "Hide All Forms" : "Show All Forms"

  return (
    <Button variant="outline-primary" onClick={() => dispatch(toggleFormsExpanded())}>
      {text}
    </Button>
  )
}

export default ToggleFormsButton
