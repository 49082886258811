/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Button, Form, Row, Col } from "react-bootstrap"
import { useField } from "formik"
import { useSelector } from "react-redux"
import _ from "lodash"

import Pricing from "@/pricing"

import { DateTimePicker, formatDateTime } from "@/components/DatePickers"
import { getMode } from "@/document_editor/selectors"
import EditorModes from "@/document_editor/models/EditorModes"
import { FieldTypes } from "@/document_editor/models/Field"
import TextInput from "@/components/input/Text"
import SelectInput from "@/components/input/Select"
import { newComponent, ComponentType } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

import ProposalButton from "./ProposalButton"
import DeleteFieldButton from "./DeleteFieldButton"
import TourComponentList from "./controls/TourComponentList"
import TextList from "./controls/TextList"
import ObjectList from "./controls/ObjectList"

const toTime = (hour, minute) =>
  `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`

const FieldGroup = ({ index, field: { id, name, type, text, valueOptions } }): JSX.Element => {
  const editorMode = useSelector(getMode)

  const [field, meta, helpers] = useField(`fields.${index}`)
  const { value, proposal } = field.value

  const hasProposal = !_.isNil(proposal.id)

  const sharedAttributes = {
    ...field,
    value,
    name: `fields.${index}.value`,
    readOnly: hasProposal || editorMode === EditorModes.READ,
  }

  let control

  // TODO Potentially support valueOptions in other types of fields as well
  switch (type) {
    case FieldTypes.TEXT_FIELD:
      if (_.isEmpty(valueOptions)) {
        control = <TextInput {...sharedAttributes} />
      } else {
        // TODO Need to pass the label of the option value
        control = (
          <SelectInput {...sharedAttributes}>
            {valueOptions.map((option) => (
              <option key={option} value={option}>
                {_.startCase(option)}
              </option>
            ))}
          </SelectInput>
        )
      }

      break
    case FieldTypes.DATETIME:
      control = (
        <DateTimePicker
          {...sharedAttributes}
          noFormGroup
          onChange={(date) => helpers.setValue({ ...field.value, value: formatDateTime(date) })}
        />
      )
      break
    case FieldTypes.NUMBER:
      control = <TextInput {...sharedAttributes} />
      break
    case FieldTypes.TEXT_AREA:
      control = <Form.Control as="textarea" rows={5} {...sharedAttributes} />
      break
    case FieldTypes.TOUR_COMPONENT_LIST:
      if (name === ReservedFields.VEHICLES) {
        control = (
          <ObjectList
            newObject={() => newComponent({ kind: ComponentType.VEHICLE })}
            {...sharedAttributes}
          />
        )
      } else {
        control = <TourComponentList {...sharedAttributes} />
      }

      break
    case FieldTypes.TEXT_LIST:
      control = <TextList {...sharedAttributes} />
      break
    case FieldTypes.IMAGE:
      control = (
        <Form.Control
          type="file"
          name={sharedAttributes.name}
          readOnly={sharedAttributes.readOnly}
          onBlur={sharedAttributes.onBlur}
          onChange={(event) =>
            helpers.setValue({ ...field.value, value: event.currentTarget.files[0] })
          }
        />
      )
      break
    case FieldTypes.TIME:
      control = (
        <Form.Control as="select" {...sharedAttributes}>
          {_.range(0, 23).map((hour) =>
            _.range(0, 55, 5).map((minute) => (
              <option key={toTime(hour, minute)}>{toTime(hour, minute)}</option>
            ))
          )}
        </Form.Control>
      )
      break
    case (FieldTypes.RATESHEET_TIERED, FieldTypes.RATESHEET_FLAT, FieldTypes.RATESHEET_PICKER):
      control = <Pricing.Editor {...sharedAttributes} />
      break
    default:
      control = null
  }

  const actions = []
  const info = []

  if (hasProposal && editorMode === EditorModes.COMMENT) {
    if (proposal.canAccept) {
      actions.push(<ProposalButton key="accept" fieldName={`fields.${index}`} action="accept" />)
    }

    if (proposal.canReject) {
      actions.push(<ProposalButton key="reject" fieldName={`fields.${index}`} action="reject" />)
    }

    info.push(
      <Form.Text key="proposed_by" className="text-muted">
        {`Proposed by ${proposal.proposedBy}`}
      </Form.Text>
    )
  }

  if (editorMode === EditorModes.WRITE) {
    actions.push(<DeleteFieldButton key="remove" fieldName={`fields.${index}`} />)
  }

  if (meta.touched && meta.initialValue && meta.initialValue.value !== field.value.value) {
    actions.push(
      <Button
        key="reset"
        variant="warning"
        size="sm"
        onClick={() => helpers.setValue(meta.initialValue)}
      >
        <i className="fas fa-undo me-2" />
        Reset
      </Button>
    )
  }

  // actions.push(
  //   <NotReading key="comment">
  //     <Button key="comment" variant="outline-primary" size="sm">
  //       <i className="fas fa-comment me-2" />
  //       Comment
  //     </Button>
  //   </NotReading>
  // )

  return (
    <Form.Group controlId={id}>
      <Row>
        <Form.Label>{_.startCase(name)}</Form.Label>
      </Row>
      <Row>
        <Col md={9}>
          {control}
          {text !== "" && <Form.Text className="text-muted">{text}</Form.Text>}
          {info}
        </Col>
        <Col className="d-flex align-items-center gap-2">{actions}</Col>
      </Row>
    </Form.Group>
  )
}

export default FieldGroup
