import React from "react"
import { useSelector } from "react-redux"
import { getTourName, getStartTime, getEndTime } from "@/guests_booked_tour_account/selectors"
import { displayDate } from "@/guests_booked_tour_account/helpers/Dates"

const Header = (): JSX.Element => {
  const name = useSelector(getTourName)
  const start = useSelector(getStartTime)
  const end = useSelector(getEndTime)

  return (
    <div>
      <h2>{name}</h2>
      {displayDate(start, end)}
    </div>
  )
}

export default Header
