import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"

import { getDashboard } from "./selectors"
import { initialize } from "./Slice"
import Header from "./Header"
import ProfileTable from "./ProfileTable"

const Dashboard = ({ view }): JSX.Element => {
  const dispatch = useDispatch()

  const { initialized } = useSelector(getDashboard)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ view }))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <Card>
      <Header />
      <ProfileTable />
    </Card>
  )
}

export default Dashboard
