import React from "react"
import { isBlank } from "@/utils"
import { MutedcheckMark } from "@/components/Icons"
import {
  calculateTotalMaximumCapacity,
  RenderDeparturesColumn,
  RenderIssuesColumn,
} from "./Helpers"

const TableRow = ({ call }) => (
  <>
    <tr>
      <td />
      <td style={{ color: "#888888" }}>
        {`${call.ship.name}`} <br />
        {`day ${call.portDay.cruiseDay} of ${call.portDay.cruiseLength} `}
      </td>
      <td style={{ color: "#888888" }}>
        {call.ship.arrivalTime && call.ship.departureTime
          ? `${call.ship.arrivalTime} - ${call.ship.departureTime}`
          : call.ship.arrivalTime
            ? `${call.ship.arrivalTime} - in port`
            : call.ship.departureTime
              ? ` in port - ${call.ship.departureTime}`
              : "in port"}
      </td>
      <td style={{ color: "#888888" }}>
        <strong>{call.tours[0].code}</strong> <br />
        {isBlank(call.tours[0].constraints.allotmentConstraint) && (
          <div>
            No requirement
            <br /> loaded
          </div>
        )}
        {!isBlank(call.tours[0].constraints.allotmentConstraint) && (
          <>
            Min per departure:{" "}
            {`${call.tours[0].constraints.allotmentConstraint.capacityPerDepartureMin}`}
            <br />
            <>
              <span style={{ marginRight: "40px" }}>Max per day:</span>
              <span>{call.tours[0].constraints.allotmentConstraint.capacityPerDayMax}</span>
            </>
          </>
        )}
      </td>
      <td style={{ color: "#888888" }}>
        {!isBlank(call.tours[0].departures) && (
          <RenderDeparturesColumn
            departures={call.tours[0].departures}
            property="departureTime"
            closed={call.tours[0].closedDay}
            additionalData
          />
        )}
      </td>
      <td style={{ color: "#888888" }}>
        {!isBlank(call.tours[0].departures) && (
          <RenderDeparturesColumn
            departures={call.tours[0].departures}
            property="durationInMinutes"
            closed={call.tours[0].closedDay}
            additionalData
          />
        )}
      </td>
      <td style={{ color: "#888888" }}>
        {!isBlank(call.tours[0].departures) && (
          <RenderDeparturesColumn
            departures={call.tours[0].departures}
            property="minimumCapacity"
            closed={call.tours[0].closedDay}
            additionalData
          />
        )}
      </td>
      <td style={{ color: "#888888" }}>
        {!isBlank(call.tours[0].departures) && (
          <RenderDeparturesColumn
            departures={call.tours[0].departures}
            property="maximumCapacity"
            closed={call.tours[0].closedDay}
            additionalData
          />
        )}
        {!call.tours[0].closedDay && !isBlank(call.tours[0].departures) && (
          <strong>{`${calculateTotalMaximumCapacity(call.tours[0].departures)} (Total)`}</strong>
        )}
      </td>
      <td style={{ color: "#888888" }}>
        {!isBlank(call.tours[0].issues) && <RenderIssuesColumn issues={call.tours[0].issues} />}
      </td>
      <td style={{ color: "#888888" }}>
        {call.tours[0].state == "Approved" && <MutedcheckMark />} {call.tours[0].state}
      </td>
      <td />
      <td />
      <td />
    </tr>
    {call.tours.slice(1).map((tour) => (
      <tr key={tour.code}>
        <td />
        <td />
        <td />
        <td style={{ color: "#888888" }}>
          <strong>{tour.code}</strong> <br />
          {isBlank(tour.constraints.allotmentConstraint) && (
            <div>
              No requirement
              <br /> loaded
            </div>
          )}
          {!isBlank(tour.constraints.allotmentConstraint) && (
            <>
              Min per departure: {`${tour.constraints.allotmentConstraint.capacityPerDepartureMin}`}
              <br />
              <>
                <span style={{ marginRight: "40px" }}>Max per day:</span>
                <span>{tour.constraints.allotmentConstraint.capacityPerDayMax}</span>
              </>
            </>
          )}
        </td>
        <td style={{ color: "#888888" }}>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="departureTime"
              closed={tour.closedDay}
              additionalData
            />
          )}
        </td>
        <td style={{ color: "#888888" }}>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="durationInMinutes"
              closed={tour.closedDay}
              additionalData
            />
          )}
        </td>
        <td style={{ color: "#888888" }}>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="minimumCapacity"
              closed={tour.closedDay}
              additionalData
            />
          )}
        </td>
        <td style={{ color: "#888888" }}>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="maximumCapacity"
              closed={tour.closedDay}
              additionalData
            />
          )}
          {!tour.closedDay && !isBlank(tour.departures) && (
            <strong>{`${calculateTotalMaximumCapacity(tour.departures)} (Total)`}</strong>
          )}
        </td>
        <td style={{ color: "#888888" }}>
          {!isBlank(tour.issues) && <RenderIssuesColumn issues={tour.issues} />}
        </td>
        <td style={{ color: "#888888" }}>
          {tour.state == "Approved" && <MutedcheckMark />} {tour.state}
        </td>
        <td />
        <td />
        <td />
      </tr>
    ))}
  </>
)

const AdditionalShips = ({ additionalInventoryInPort }) => {
  if (isBlank(additionalInventoryInPort)) {
    return null
  }

  return additionalInventoryInPort.map((inventory) => (
    <React.Fragment key={inventory.ship.name}>
      <TableRow call={inventory} />
    </React.Fragment>
  ))
}

export default AdditionalShips
