import React, { useEffect, useRef } from "react"
import { Button, Modal } from "react-bootstrap"
import SignaturePad from "react-signature-pad-wrapper"

const ShowSignatureModal = ({
  show,
  onHide,
  agent,
}: {
  show: boolean
  onHide: () => void
  agent: any
}): JSX.Element => {
  const signaturePad: any = useRef({})

  // Resize to handle high DPI screens right after the first render
  useEffect(() => {
    signaturePad.current.fromDataURL(agent.signature)
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="show-signature-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="show-signature-modal-title">{`${agent.name} (${agent.company})`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="h4">Comment</h1>
        <p>{agent.comment}</p>
        <SignaturePad ref={signaturePad} height={250} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShowSignatureModal
