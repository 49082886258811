import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { Button } from "react-bootstrap"
import { isBlank } from "@/utils"
import { useModal } from "@/helpers/useModal"
import {
  getCruiseDeparturesEditStates,
  getCallDaysPreEdit,
  getView,
  getDepartureRequestStates,
  getIsMakingRequestStates,
} from "../selectors"
import {
  updateCruiseDeparturesEditStates,
  updateDepartures,
  addDraftCruiseDeparture,
  onCancelEdit,
  removeDepartures,
  updateDeparturesRequestStates,
  approveDepartures,
  updateIsMakingRequestStates,
} from "../Slice"
import {
  RenderCruiseDeparturesColumn,
  calculateTotalMaximumCapacity,
  hasDepartureMismatch,
} from "./Helpers"
import DeparturesEditColumns from "./DeparturesEditColumns"
import ConfirmationModal from "./ConfirmationModal"

const CruiseLineDepartures = ({ index }) => {
  const view = useSelector(getView)
  const call = view.inventory.allocationTableData.callDays[index]
  const { departures } = call.inventory.currentAllocatingTour.constraints
  const { date } = call

  const dispatch = useDispatch()

  const rowEditStates = useSelector(getCruiseDeparturesEditStates)
  const callDaysPreEdit = useSelector(getCallDaysPreEdit)
  const departureRequestStates = useSelector(getDepartureRequestStates)
  const isMakingRequestStates = useSelector(getIsMakingRequestStates)

  const [makingRequest, setMakingRequest] = useState(isMakingRequestStates || {})
  const [confirmationPrompt, setConfirmationPrompt] = useState("")
  const [confirmationHeader, setConfirmationHeader] = useState("")
  const [edit, setEdit] = useState(rowEditStates || {})
  const [departureRequests, setDepartureRequests] = useState(departureRequestStates || {})

  useEffect(() => {
    setEdit(rowEditStates)
    setDepartureRequests(departureRequestStates)
    if (isMakingRequestStates) {
      setMakingRequest(isMakingRequestStates)
    }
  }, [rowEditStates, departureRequestStates, isMakingRequestStates])

  const onApproveCruiselineDepartures = async () => {
    await dispatch(approveDepartures([call.date]))
  }

  const onSubmit = async (date) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    const newRequestState = { ...departureRequests, [date]: !departureRequests[date] }
    setDepartureRequests(newState)

    const dateIndex = callDaysPreEdit.findIndex((callDay) => callDay.date === date)
    const previousDepartures =
      callDaysPreEdit[dateIndex].inventory.currentAllocatingTour.constraints.departures

    const departuresToAdd = departures.filter(
      (departure) => !previousDepartures.some((prevDeparture) => prevDeparture.id === departure.id)
    )

    const departuresToRemove = previousDepartures.filter(
      (prevDeparture) => !departures.some((departure) => departure.id === prevDeparture.id)
    )

    const commonDepartures = departures.filter((departure) =>
      previousDepartures.some((prevDeparture) => prevDeparture.id === departure.id)
    )

    const departuresToUpdate = commonDepartures.filter((commonDeparture) => {
      const prevDeparture = previousDepartures.find((prev) => prev.id === commonDeparture.id)
      if (!prevDeparture) return false
      return Object.keys(commonDeparture).some((key) => {
        if (key !== "id" && key !== "issues") {
          return prevDeparture[key] !== commonDeparture[key]
        }
      })
    })

    if (!isBlank(departuresToAdd)) {
      await dispatch(updateDepartures({ departures: departuresToAdd }))
    }

    if (!isBlank(departuresToUpdate)) {
      const departuresToUpdateFormatted = departuresToUpdate.map((departure) => {
        const { id, ...rest } = departure
        return { departureId: id, ...rest }
      })
      await dispatch(updateDepartures({ departures: departuresToUpdateFormatted }))
    }

    if (!isBlank(departuresToRemove)) {
      const departuresToRemoveFormatted = departuresToRemove.map((departure) => ({
        departureId: departure.id,
        remove: true,
      }))
      await dispatch(updateDepartures({ departures: departuresToRemoveFormatted }))
    }

    // set making request false so that row will collapse
    const newMakingRequestState = { ...makingRequest, [date]: false }
    setMakingRequest(newMakingRequestState)
    dispatch(updateIsMakingRequestStates({ isMakingRequestStates: newMakingRequestState }))
    dispatch(updateCruiseDeparturesEditStates({ editState: newState }))
    dispatch(updateDeparturesRequestStates({ requestState: newRequestState }))
  }

  const onEdit = (date, cancel = false) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    dispatch(updateCruiseDeparturesEditStates({ editState: newState }))
    if (!cancel && isBlank(departures)) {
      dispatch(addDraftCruiseDeparture({ date }))
    }
    if (cancel) {
      const newRequestState = { ...departureRequests, [date]: !departureRequests[date] }
      setDepartureRequests(newRequestState)
      dispatch(updateDeparturesRequestStates({ requestState: newRequestState }))
      dispatch(onCancelEdit({ date }))

      // set making request false so that row will collapse
      const newMakingRequestState = { ...makingRequest, [date]: false }
      setMakingRequest(newMakingRequestState)
      dispatch(updateIsMakingRequestStates({ isMakingRequestStates: newMakingRequestState }))
    }
  }

  const onRemoveCallback = () => {
    dispatch(removeDepartures(departures))
  }

  const toggleConfirmationModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onRemoveCallback}
      buttonText="Delete"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const onRemove = () => {
    setConfirmationPrompt(
      `Are you sure you want to delete all departures requested for ${call.date}`
    )
    setConfirmationHeader("Confirm deletion")
    toggleConfirmationModal()
  }
  return (
    <>
      <OperatorOnly>
        {!isBlank(departures) && hasDepartureMismatch(call) && (
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "departureTime", true)
                : !isBlank(departures) && (
                    <RenderCruiseDeparturesColumn
                      departures={departures}
                      property="departureTime"
                    />
                  )}
            </td>
            <td>
              {edit[date] ? (
                <>{DeparturesEditColumns(date, departures, "durationInMinutes", true)}</>
              ) : (
                !isBlank(departures) && (
                  <RenderCruiseDeparturesColumn
                    departures={departures}
                    property="durationInMinutes"
                  />
                )
              )}
            </td>
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "minimumCapacity", true)
                : !isBlank(departures) && (
                    <RenderCruiseDeparturesColumn
                      departures={departures}
                      property="minimumCapacity"
                    />
                  )}
            </td>
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "maximumCapacity", true)
                : !isBlank(departures) && (
                    <>
                      <RenderCruiseDeparturesColumn
                        departures={departures}
                        property="maximumCapacity"
                      />
                      <strong style={{ color: "#000000" }}>{`${calculateTotalMaximumCapacity(
                        departures
                      )} (Total)`}</strong>
                    </>
                  )}
            </td>
            <td />
            <td>Cruise Line Request</td>
            <td>
              {edit[date] ? (
                <>
                  <Button variant="secondary mb-1" onClick={() => onEdit(date, true)}>
                    Cancel
                  </Button>
                  <br />
                  <Button variant="secondary" onClick={() => onSubmit(date)}>
                    Submit
                  </Button>
                </>
              ) : (
                <Button className="primary" onClick={() => onApproveCruiselineDepartures()}>
                  Accept
                </Button>
              )}
            </td>
            <td />
            <td />
          </tr>
        )}
      </OperatorOnly>
      <CruiseLineOnly>
        {!isBlank(departures) && (
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "departureTime", true)
                : !isBlank(departures) && (
                    <RenderCruiseDeparturesColumn
                      departures={departures}
                      property="departureTime"
                    />
                  )}
            </td>
            <td>
              {edit[date] ? (
                <>{DeparturesEditColumns(date, departures, "durationInMinutes", true)}</>
              ) : (
                !isBlank(departures) && (
                  <RenderCruiseDeparturesColumn
                    departures={departures}
                    property="durationInMinutes"
                  />
                )
              )}
            </td>
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "minimumCapacity", true)
                : !isBlank(departures) && (
                    <RenderCruiseDeparturesColumn
                      departures={departures}
                      property="minimumCapacity"
                    />
                  )}
            </td>
            <td>
              {edit[date]
                ? DeparturesEditColumns(date, departures, "maximumCapacity", true)
                : !isBlank(departures) && (
                    <>
                      <RenderCruiseDeparturesColumn
                        departures={departures}
                        property="maximumCapacity"
                      />
                      <strong style={{ color: "#000000" }}>{`${calculateTotalMaximumCapacity(
                        departures
                      )} (Total)`}</strong>
                    </>
                  )}
            </td>
            <td />
            <td>{!hasDepartureMismatch(call) && !edit[date] ? "Your Approval" : "Your Request"}</td>
            <td>
              {edit[date] ? (
                <>
                  <Button variant="secondary mb-1" onClick={() => onEdit(date, true)}>
                    Cancel
                  </Button>
                  <br />
                  <Button variant="secondary" onClick={() => onSubmit(date)}>
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <Button className="primary mb-1" onClick={() => onEdit(date)}>
                    Edit
                  </Button>
                  <br />
                  <Button className="primary" onClick={() => onRemove()}>
                    Remove
                  </Button>
                </>
              )}
            </td>
            <td />
            <td />
          </tr>
        )}
      </CruiseLineOnly>
    </>
  )
}

export default CruiseLineDepartures
