import React from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { toggleEditing } from "@/settlement/Slice"
import { isEditing } from "@/settlement/selectors"

const ToggleEditButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const editing = useSelector(isEditing)

  const label = editing ? "Cancel" : "Edit"
  const variant = editing ? "danger" : "secondary"

  return (
    <Button variant={variant} onClick={() => dispatch(toggleEditing({}))}>
      {label}
    </Button>
  )
}

export default ToggleEditButton
