import React from "react"
import { Row, Col, Image } from "react-bootstrap"

const Guest = ({ guest, isCardHolder, includeRoom = false }) => (
  <Row className="align-items-center" key={guest.id}>
    <Col className="col-auto">
      <div className="avatar">
        <Image src={guest.profilePicturePath} roundedCircle />
      </div>
    </Col>
    <Col>
      <h4 className="h6 mb-0">
        {guest.name}
        {includeRoom ? `(${guest.roomNumber})` : ""}
      </h4>
      {isCardHolder && (
        <small>
          <i>Card Holder</i>
        </small>
      )}
    </Col>
  </Row>
)

export default Guest
