import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getProduct } from "@/onboarding/features/product/selectors"
import { TenderStatuses } from "@/onboarding/features/product/models/Product"

const Status: FC = () => {
  const { tenderStatus, awardedCompany } = useSelector(getProduct)

  switch (tenderStatus) {
    case TenderStatuses.DRAFT:
      return <span className="text-warning">Draft</span>
    case TenderStatuses.READY_FOR_BIDS:
      return <span className="text-info">Ready for Bids</span>
    case TenderStatuses.BIDS_REQUESTED:
      return <span className="text-info">Bids Requested</span>
    case TenderStatuses.BIDDING_EXPIRED:
      return <span className="text-danger">Bidding Expired</span>
    case TenderStatuses.BIDDING_CLOSED:
      return <span className="text-warning">Bidding Closed</span>
    case TenderStatuses.AWARDED_TO_OPERATOR:
      return <span className="text-success">{`Awarded to ${awardedCompany.name}`}</span>
    default:
      break
  }

  return null
}

export default Status
