import React from "react"
import { Button, Card, Form, Modal } from "react-bootstrap"

import useSignaturePad from "@/helpers/useSignaturePad"

const SignAgreementModal = ({
  show,
  onHide,
  onSubmit,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const [SignaturePad, signaturePadHelpers] = useSignaturePad()

  const onClickSubmit = () => onSubmit({ signature: signaturePadHelpers.getData() })

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="sign-agreement-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="sign-agreement-modal-title">Sign and Accept Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By signing below you agree to bid according to the cruise line terms and conditions
          described above.
        </p>
        <Form.Group>
          <Form.Label>Signature</Form.Label>
          <Card>
            <SignaturePad />
          </Card>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="warning" onClick={signaturePadHelpers.clear}>
          Clear Signature
        </Button>
        <Button variant="primary" onClick={onClickSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SignAgreementModal
