import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getItinerary, getSeasons } from "@/itinerary_dashboard/selectors"
import { setSeason } from "@/itinerary_dashboard/Slice"
import Select from "@/components/input/Select"

const SeasonDropdown: FC = () => {
  const dispatch = useDispatch()

  const { selectedSeasonId } = useSelector(getItinerary)
  const seasons = useSelector(getSeasons)

  return (
    <Select
      label="Season"
      floating
      value={selectedSeasonId}
      onChange={({ target }) => dispatch(setSeason(target.value))}
    >
      <option value="">All available seasons</option>
      {seasons.map((season) => (
        <option key={season.id} value={season.id}>
          {season.name}
        </option>
      ))}
    </Select>
  )
}

export default SeasonDropdown
