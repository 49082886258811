import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { initialize, getDailySettlement } from "@/daily_settlement/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"

import CardHeader from "./card/Header"
import CardBody from "./card/Body"

const DailySettlementCard = ({ dailySettlement }) => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getDailySettlement)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(dailySettlement))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return (
    <Card border="light" className="shadow-sm">
      <CardHeader />
      <CardBody />
    </Card>
  )
}

export default DailySettlementCard
