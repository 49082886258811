import React from "react"
import _ from "lodash"

import MultiSelect from "@/components/input/MultiSelect"
import { buildReduxUseField } from "@/form_toolkit/hooks"

const toTooltip = ({ values, maxDisplay }) => {
  if (values.length <= maxDisplay) {
    return null
  }

  return _.join(
    _.map(_.map(values, "label"), (item) => `• ${item}`),
    "\n"
  )
}

const Select = ({ name, useField, count = null, ...props }): JSX.Element => {
  const field = useField(name)

  const tooltipValues = _.map(field.value, (v) => _.find(field.options, { value: v }))

  return (
    <MultiSelect
      maxSelectedLabels={1}
      tooltip={toTooltip({ values: tooltipValues, maxDisplay: 1 })}
      placeholder={field.allLabel}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      options={field.options}
      label={count ? `${field.label} (${count})` : field.label}
      {...props}
    />
  )
}

export const buildReduxMultiSelect =
  (formSelector, filterAction) =>
  ({ name, count = null, ...props }) => (
    <Select
      name={name}
      count={count}
      useField={buildReduxUseField(formSelector, filterAction)}
      {...props}
    />
  )

export default Select
