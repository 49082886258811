import React from "react"
import { Button, Col, Row, Table } from "react-bootstrap"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"
import * as BulkRatesheet from "@/pricing/models/BulkRatesheet"
import { displayPrecision } from "@/onboarding/features/product/helpers"

import AddRateCategory from "./AddRateCategory"

const BulkRateEntryRow = ({ name, readOnly }): JSX.Element => (
  <Row>
    <Col md={4}>
      <FormikBootstrap.NumberInput
        name={`${name}.bulkRate`}
        label="Bulk Rate"
        readOnly={readOnly}
        displayScale={100.0}
      />
    </Col>
  </Row>
)

const TieredRatesheetTable = ({
  ratesheet,
  onRemoveTier,
  onRemoveCategory,
  name,
  readOnly,
  canEditPricingStructure,
}) => {
  const table = BulkRatesheet.asTable(ratesheet)
  const rateHeadings = _.drop(table.headings)

  return (
    <Table className="mb-2 table-compact" size="sm">
      <thead>
        <tr>
          <th key="tier-start" className="compact">
            Tier start
          </th>
          {_.map(rateHeadings, (h, idx) => (
            <th key={idx} className="compact">
              <span>{h.label}</span>
            </th>
          ))}
          {canEditPricingStructure && (
            <th key="delete-action" className="fit compact">
              {" "}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {_.isEmpty(table.body) && (
          <tr>
            <td>No tiers loaded</td>
          </tr>
        )}
        {_.map(table.body, (row) => (
          <tr key={row.tierIndex}>
            <td key="tierFrom" className="compact" style={{ width: "100px" }}>
              <FormikBootstrap.NumberDropdown
                name={`${name}.tiers.${row.tierIndex}.tierFrom`}
                exclude={table.tierFroms}
                window={15}
                range={[1, null]}
                disabled={row.tierFromColumn.value === 1 || !canEditPricingStructure}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              />
            </td>
            {_.map(row.rateColumns, (v, idx) => (
              <td key={idx} className="compact">
                {displayPrecision(v.value)}
              </td>
            ))}
            {canEditPricingStructure && (
              <td key="removeTier" className="fit compact">
                <Button
                  key="remove"
                  variant="danger"
                  size="sm"
                  onClick={() => onRemoveTier(row.tierIndex)}
                  disabled={!readOnly && row.tierFromColumn.value === 1}
                >
                  <i className="fas fa-trash" />
                </Button>
              </td>
            )}
          </tr>
        ))}
        {canEditPricingStructure && !_.isEmpty(table.orderedRateCategories) && (
          <tr>
            <td className="compact">{/* TIER_FROM */}</td>
            {_.map(table.orderedRateCategories, (ctg) => (
              <td key={`remove-${ctg}`} className="compact">
                <Button
                  variant="danger"
                  size="sm"
                  disabled={!readOnly}
                  onClick={() => onRemoveCategory(ctg)}
                >
                  <i className="fas fa-trash" />
                </Button>
              </td>
            ))}
            <td className="compact">{/* TIER_ACTION_REMOVE */}</td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const BulkRatesheetEditor = ({
  name,
  value,
  onChange,
  readOnly,
  canEditPricingStructure,
}): JSX.Element => {
  const newChangeEvent = (val) => ({ target: { name, value: val } })

  const triggerChange = (val) => onChange(newChangeEvent(val))

  const appendTier = (tier) => {
    triggerChange(BulkRatesheet.addTier(value, tier))
  }

  const addTier = () => {
    if (value.tiers.length === 0) {
      appendTier(BulkRatesheet.newTier())
    } else {
      appendTier(
        BulkRatesheet.newTier({
          tierFrom: BulkRatesheet.nextTierFrom(value),
        })
      )
    }
  }

  const removeTier = (index) => {
    triggerChange(BulkRatesheet.removeTierAt(value, index))
  }

  const removeCategory = (category) => {
    triggerChange(BulkRatesheet.removeCategory(value, category))
  }

  const addCategory = (category) => {
    let newSheet = BulkRatesheet.addCategory(value, category)

    if (newSheet.tiers.length === 0) {
      newSheet = BulkRatesheet.addTier(newSheet, BulkRatesheet.newTier())
    }

    triggerChange(newSheet)
  }

  return (
    <Row>
      <Col md={12} className="d-flex flex-column gap-2">
        <BulkRateEntryRow name={name} readOnly={readOnly} />
        <TieredRatesheetTable
          name={name}
          readOnly={readOnly}
          ratesheet={value}
          canEditPricingStructure={canEditPricingStructure}
          onRemoveTier={removeTier}
          onRemoveCategory={removeCategory}
        />
        {!readOnly && canEditPricingStructure && (
          <div>
            <Button size="sm" onClick={() => addTier()}>
              <i className="fas fa-plus me-2" />
              Tier
            </Button>
          </div>
        )}
        {!readOnly && canEditPricingStructure && (
          <AddRateCategory
            rateCategories={BulkRatesheet.rateCategories(value)}
            onClick={addCategory}
          />
        )}
      </Col>
    </Row>
  )
}

export default BulkRatesheetEditor
