import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { updateView } from "../Slice"
import { getProfile } from "../selectors"

const InsuranceStatusCheckBox = ({
  statusName,
  statusLabel,
  currentStatus,
  certificateId,
}): JSX.Element => {
  const insuranceObject = {
    approved: currentStatus == "approved",
    approved_with_exception: currentStatus == "approved_with_exception",
    rejected: currentStatus == "rejected",
  }

  const { _view, editingReview } = useSelector(getProfile)
  const dispatch = useDispatch()

  let onChange

  const checked = insuranceObject[statusName]

  if (!editingReview) {
    onChange = null
  } else {
    const newView = _.cloneDeep(_view)
    newView.value.profile.insuranceUploads.find(
      (upload) => upload.certificateId === certificateId
    ).status = statusName
    newView.value.profile.insuranceUploads.find(
      (upload) => upload.certificateId === certificateId
    ).statusLabel = statusLabel
    onChange = () => dispatch(updateView({ view: newView }))
  }

  return (
    <Form.Check
      type="checkbox"
      checked={checked}
      name="name"
      value={statusName}
      label={_.startCase(statusLabel)}
      onChange={() => onChange()}
    />
  )
}

export default InsuranceStatusCheckBox
