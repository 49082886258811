import React from "react"

import { GreenCheckMark } from "@/components/Icons"
import Tour, { SettlementStates } from "@/dashboard/models/Tour"

const ReconState = ({ tour }: { tour: Tour }): JSX.Element => {
  if (tour.settlementState === SettlementStates.FINALIZED) {
    return <GreenCheckMark />
  }

  if (tour.settlementState === SettlementStates.RECON) {
    if (tour.reconDifference > 0) {
      return <span className="text-success">{`+ ${tour.reconDifference}`}</span>
    }

    return <span className="text-danger">{tour.reconDifference}</span>
  }

  return null
}

export default ReconState
