import React from "react"
import _ from "lodash"

// TODO(guest-app): Merge with or move these functions to a more generic Money helper
// available everywherre.
export const getCurrency = (charges) => {
  let currency = ""
  _.map(charges, (value) => {
    currency = value.amountInMoney.currency.html_entity
  })
  return currency
}

export const formatAmount = (currency, fractional) =>
  `${currency}${(fractional / 100.0).toFixed(2)}`

export const getTotalCost = (charges) => {
  let total = 0.0
  _.map(charges, (value) => {
    total += parseFloat(value.amountInMoney.fractional)
  })
  return total
}

const getChargeName = (description) => {
  const name = description.substring(description.indexOf("for") + 4, description.lastIndexOf("on"))
  return name
}

export const formatDescription = (charge, guestID) =>
  charge.hasOwnProperty("descriptionUrl") ? (
    <a className="link-dark" href={`${charge.descriptionUrl}&guest=${guestID}`}>
      <u>{getChargeName(charge.description)}</u>
    </a>
  ) : (
    getChargeName(charge.description)
  )
