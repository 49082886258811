import React from "react"

import AuditTrail from "@/components/AuditTrail"

import { useSliceSelector } from "@/helpers/SliceProvider"
import { getForm } from "../selectors"

const AuditView = (props) => {
  const { audits } = useSliceSelector(getForm)

  return (
    <div>
      <h2 className="h5">{props.title}</h2>
      <AuditTrail audits={audits} />
      <div className="d-flex gap-2">{props.formNavigation}</div>
    </div>
  )
}

export default AuditView
