import { useEffect } from "react"
import { useFormikContext } from "formik"

/**
 * Checks whether any values in the form has changed or not.
 *
 * currentValues are optional, but when passed in will be used for comparison instead of
 * the form's initialValues.
 *
 * This allows you to compare against the values that were last submitted instead of the values that the form was initialized with.
 *
 */
const FormikFormChangeTracker = ({ setHasChanged, currentValues = null }) => {
  const { values, initialValues } = useFormikContext()

  useEffect(() => {
    let compareTo = initialValues

    if (currentValues !== null) compareTo = currentValues

    setHasChanged(!_.isEqual(values, compareTo))
  }, [values, initialValues, currentValues])

  return null
}

export default FormikFormChangeTracker
