import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getActiveBid } from "@/onboarding/features/product/selectors"
import { BidStatuses } from "@/onboarding/features/product/models/Bid"

const Status: FC = () => {
  const { status } = useSelector(getActiveBid)

  switch (status) {
    case BidStatuses.DRAFT:
      return <span className="text-warning">Draft</span>
    case BidStatuses.REQUESTED:
      return <span className="text-info">Requested</span>
    case BidStatuses.SUBMITTED:
      return <span className="text-info">Submitted</span>
    case BidStatuses.REJECTED:
      return <span className="text-danger">Rejected</span>
    case BidStatuses.AWARDED:
      return <span className="text-success">Accepted</span>
    default:
      break
  }

  return null
}

export default Status
