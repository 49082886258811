import { createSlice } from "@reduxjs/toolkit"

const Slice = createSlice({
  name: "guestBookedTourAccount",
  initialState: {
    initialized: false,
    name: "",
    startTime: "",
    endTime: "",
    bookedPassengers: "",
    chargedPassenger: "",
    loggedInPassenger: "",
    tourDetails: "",
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
  },
})

export const { initialize } = Slice.actions

export default Slice
