import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { Formik } from "formik"

import Api from "@/api"
import AsyncSearch from "@/helpers/AsyncSearch"
import { proposeProduct } from "@/onboarding/features/product/Slice"

const ProposeTourModal = ({ show, onHide }: { show: boolean; onHide: () => void }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="propose-tour-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="propose-tour-modal-title">Propose Tour</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <Formik
          initialValues={{ cruiseLines: [] }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)
            dispatch(proposeProduct({ recipientId: _.head(_.map(values.cruiseLines, "value")) }))
            setSubmitting(false)
            onHide()
          }}
        >
          {({ handleSubmit, setValues, values, isSubmitting }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Cruise Lines</Form.Label>
                <AsyncSearch
                  selectedValues={values.cruiseLines}
                  setSelectedValues={(cruiseLines) => setValues({ cruiseLines })}
                  getOptions={Api.onboardingProduct.searchCruiseLines}
                  maxSelectable={1}
                />
              </Form.Group>
              <div className="d-flex gap-3">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button variant="danger" onClick={onHide} disabled={isSubmitting}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ProposeTourModal
