import React from "react"

import DocumentEditorApp from "@/document_editor/components/App"
import EditorModes from "@/document_editor/models/EditorModes"
import { ContentSetTypes } from "@/content_sets/models/ContentSet"
import TourForm from "@/onboarding/features/product/components/TourForm"

import TicketPreview from "./presenters/TicketPreview"
import KioskView from "./presenters/KioskView"
import BrochureView from "./presenters/BrochureView"

// TODO Fix this dependency here on the PrimaryTourView and move some of this code back into
// the onboarding domain
const presenterComponent = ({ type, document, context }) => {
  switch (type) {
    case ContentSetTypes.TICKET:
      return <TicketPreview url={context.previewUrl} />
    case ContentSetTypes.KIOSK:
      return <KioskView context={context} document={document} />
    case ContentSetTypes.BROCHURE:
      return <BrochureView context={context} document={document} />
    default: {
      // XXX HACK, basically ignores the passed in document and uses its own...
      return <TourForm />
    }
  }
}

const ContentSet = ({ presenting, authDescriptor, contentSet, availableOperators }) => (
  <>
    {presenting ? (
      presenterComponent(contentSet)
    ) : (
      <DocumentEditorApp
        authDescriptor={authDescriptor}
        editorMode={EditorModes.WRITE}
        document={contentSet.document}
        availableOperators={availableOperators}
      />
    )}
  </>
)

export default ContentSet
