import _ from "lodash"

export const findField = (document, fieldName) =>
  _.find(
    _.flatMap(document.sections, (s) => s.fields),
    (f) => f.name == fieldName
  )

export const fieldValue = (document, fieldName, defaultValue = "") => {
  const field = findField(document, fieldName)

  if (!field) return defaultValue
  if (field.value === "") return defaultValue

  return field.value
}
