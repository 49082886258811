import React from "react"

import { Spinner } from "react-bootstrap"
import * as AsyncField from "@/helpers/AsyncField"

import VehicleResultList from "./VehicleResultList"

const VehicleSearchResults = ({ field, onReserve }) => {
  if (AsyncField.hasErrorMessage(field)) {
    return (
      <div>
        <i> {AsyncField.getMessage(field)} </i>{" "}
      </div>
    )
  }

  if (AsyncField.isLoading(field) || AsyncField.isStale(field)) {
    return (
      <div>
        <Spinner className="d-block" animation="border" />
      </div>
    )
  }

  if (field.value.length == 0) {
    return (
      <div>
        <i> No results found. Please try adjusting search criteria </i>
      </div>
    )
  }

  return <VehicleResultList results={field.value} onAction={onReserve} actionText="Reserve" />
}

export default VehicleSearchResults
