import React from "react"

import { Button } from "react-bootstrap"

import CancelBookingModal from "@/dashboard/components/tour_list_card/modals/CancelBookingModal"

import { useModal, ModalHolder } from "@/helpers/useModal"

const ToggledCancelTourBookingModal = ({
  booking,
  toggleLabel,
}: {
  booking: Booking
}): JSX.Element => {
  const toggleModal = useModal((props) => <CancelBookingModal tourBooking={booking} {...props} />)

  return (
    <Button variant="danger" onClick={toggleModal}>
      {toggleLabel}
    </Button>
  )
}

const App = (props) => (
  <ModalHolder>
    <ToggledCancelTourBookingModal {...props} />
  </ModalHolder>
)

export default App
