import React from "react"
import { Button, Table } from "react-bootstrap"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"
import * as TieredRatesheet from "@/pricing/models/TieredRatesheet"

import AddRateCategory from "./AddRateCategory"

const TieredRatesheetTable = ({
  ratesheet,
  onShiftCategoryLeft,
  onShiftCategoryRight,
  onRemoveTier,
  onRemoveCategory,
  name,
  readOnly,
  canEditPricingStructure,
}) => {
  const table = TieredRatesheet.asTable(ratesheet)

  const rateHeadings = _.drop(table.headings)

  const isFirstCol = (idx) => idx === 0
  const isLastCol = (idx) => idx === rateHeadings.length - 1
  const hasManyRates = rateHeadings.length > 1

  const hasCategoryShifts =
    hasManyRates && canEditPricingStructure && !ratesheet.independentCategories

  // TODO get rid of displayScale below! (makes cents into currency)
  return (
    <Table className="mb-2 table-compact" size="sm">
      <thead>
        <tr>
          <th key="tier-start" className="compact">
            Tier start
          </th>
          {_.map(rateHeadings, (h, idx) => (
            <th key={idx} className="compact">
              {hasCategoryShifts && !isFirstCol(idx) && (
                <Button size="sm" onClick={() => onShiftCategoryLeft(h.value)} className="me-1 p-1">
                  <i className="fas fa-angle-left fs-6" style={{ verticalAlign: "sub" }} />
                </Button>
              )}
              <span>{h.label}</span>
              {hasCategoryShifts && !isLastCol(idx) && (
                <Button
                  size="sm"
                  onClick={() => onShiftCategoryRight(h.value)}
                  className="ms-1 p-1"
                >
                  <i className="fas fa-angle-right fs-6" style={{ verticalAlign: "sub" }} />
                </Button>
              )}
            </th>
          ))}
          {canEditPricingStructure && (
            <th key="delete-action" className="fit compact">
              {" "}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {_.isEmpty(table.body) && (
          <tr>
            <td>No tiers loaded</td>
          </tr>
        )}
        {_.map(table.body, (row) => (
          <tr key={row.tierIndex}>
            <td key="tierFrom" className="compact" style={{ width: "100px" }}>
              <FormikBootstrap.NumberDropdown
                name={`${name}.tiers.${row.tierIndex}.tierFrom`}
                exclude={table.tierFroms}
                window={15}
                range={[1, null]}
                disabled={row.tierFromColumn.value === 1 || !canEditPricingStructure}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              />
            </td>
            {_.map(row.rateColumns, (v, idx) => (
              <td key={idx} className="compact">
                <FormikBootstrap.NumberInput
                  name={`${name}.tiers.${row.tierIndex}.rates.${v.category}`}
                  readOnly={readOnly}
                  displayScale={100.0}
                />
              </td>
            ))}
            {canEditPricingStructure && (
              <td key="removeTier" className="fit compact">
                <Button
                  key="remove"
                  variant="danger"
                  size="sm"
                  onClick={() => onRemoveTier(row.tierIndex)}
                  disabled={row.tierFromColumn.value === 1}
                >
                  <i className="fas fa-trash" />
                </Button>
              </td>
            )}
          </tr>
        ))}
        {canEditPricingStructure && !_.isEmpty(table.orderedRateCategories) && (
          <tr>
            <td className="compact">{/* TIER_FROM */}</td>
            {_.map(table.orderedRateCategories, (ctg) => (
              <td key={`remove-${ctg}`} className="compact">
                <Button variant="danger" size="sm" onClick={() => onRemoveCategory(ctg)}>
                  <i className="fas fa-trash" />
                </Button>
              </td>
            ))}
            <td className="compact">{/* TIER_ACTION_REMOVE */}</td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const TieredRatesheetEditor = ({
  name,
  value,
  onChange,
  readOnly,
  canEditPricingStructure,
}): JSX.Element => {
  const rateCategories = TieredRatesheet.rateCategories(value)

  const newChangeEvent = (value) => ({ target: { name, value } })

  const triggerChange = (value) => onChange(newChangeEvent(value))

  const appendTier = (tier) => {
    triggerChange(TieredRatesheet.addTier(value, tier))
  }

  const addTier = () => {
    if (value.tiers.length === 0) {
      appendTier(TieredRatesheet.newTier({ rateKeys: rateCategories }))
    } else {
      appendTier(
        TieredRatesheet.newTier({
          tierFrom: TieredRatesheet.nextTierFrom(value),
          rateKeys: rateCategories,
        })
      )
    }
  }

  const removeTier = (idx) => {
    triggerChange(TieredRatesheet.removeTierAt(value, idx))
  }

  const removeCategory = (category) => {
    triggerChange(TieredRatesheet.removeCategory(value, category))
  }

  const shiftCategoryLeft = (category) => {
    triggerChange(TieredRatesheet.moveCategoryLeft(value, category))
  }

  const shiftCategoryRight = (category) => {
    triggerChange(TieredRatesheet.moveCategoryRight(value, category))
  }

  const addCategory = (category) => {
    let newSheet = TieredRatesheet.addCategory(value, category)

    if (newSheet.tiers.length === 0) {
      newSheet = TieredRatesheet.addTier(
        newSheet,
        TieredRatesheet.newTier({ rateKeys: TieredRatesheet.rateCategories(newSheet) })
      )
    }

    triggerChange(newSheet)
  }

  return (
    <div className="d-flex flex-column gap-2">
      <FormikBootstrap.Checkbox
        name={`${name}.perTier`}
        label="Price per tier?"
        readOnly={readOnly}
        disabled={!canEditPricingStructure}
        helpText={
          <span>
            When enabled, the price will be the total price when passengers are packed into the
            price bands from top to bottom, left to right.
            <br />
            When disabled, find the first tier able to hold all passengers and use those rates.
          </span>
        }
      />
      <FormikBootstrap.Checkbox
        name={`${name}.independentCategories`}
        label="Independent Rate Categories?"
        readOnly={readOnly}
        disabled={!canEditPricingStructure}
        helpText={
          <span>
            When enabled, each rate category functions as an independent tier system.
            <br />
            When disabled, the tier selection occurs on the conjoined passenger counts.
          </span>
        }
      />
      <TieredRatesheetTable
        name={name}
        readOnly={readOnly}
        ratesheet={value}
        canEditPricingStructure={canEditPricingStructure}
        onRemoveTier={removeTier}
        onRemoveCategory={removeCategory}
        onShiftCategoryLeft={shiftCategoryLeft}
        onShiftCategoryRight={shiftCategoryRight}
      />
      {!readOnly && canEditPricingStructure && (
        <div>
          <Button size="sm" onClick={() => addTier()}>
            <i className="fas fa-plus me-2" />
            Tier
          </Button>
        </div>
      )}
      {!readOnly && canEditPricingStructure && (
        <AddRateCategory
          rateCategories={TieredRatesheet.rateCategories(value)}
          onClick={(ctg) => addCategory(ctg)}
        />
      )}
    </div>
  )
}

export default TieredRatesheetEditor
