import React from "react"

import ScreenSize from "@/helpers/ScreenSize"

// TODO(guest-app): Use the URL helpers from @/routes in the links below
const Navbar = (props): JSX.Element => {
  const isMobile = ScreenSize()

  const icon_properties = [
    {
      icon: "fas fa-dollar-sign",
      header: "Account",
      link: `/guests_room_accounts?id=${props.guestId}`,
    },
    {
      icon: "fas fa-shopping-cart",
      header: "Booking",
      link: `/manual_booking?id=${props.guestId}`,
    },
    { icon: "fas fa-ship", header: "Home", link: `/guests?id=${props.guestId}` },
    { icon: "fas fa-dollar-sign", header: "Excursions", link: `/guests_tours?id=${props.guestId}` },
    { icon: "fas fa-ticket-alt", header: "Feedback", link: `/guests_feedback?id=${props.guestId}` },
  ]

  if (isMobile) {
    return (
      <footer className="footer mt-auto fixed-bottom" style={{ background: "#f2f4f6" }}>
        <hr className="mb-n1 mt-0" />
        <ul className="list-unstyled d-flex justify-content-between px-2 mb-n4 py-1">
          {icon_properties.map((x) => {
            const color = props.page === x.header ? "Primary" : "grey"

            return (
              <li key={x.header} className="nav-item row text-center">
                <a className="nav-link active" aria-current="page" href={x.link}>
                  <i style={{ color: `${color}` }} className={x.icon} />
                  <p style={{ fontSize: "12px" }} className="fw-lighter">
                    {x.header}
                  </p>
                </a>
              </li>
            )
          })}
        </ul>
      </footer>
    )
  }

  return (
    <div
      className="position-absolute top-0 start-0"
      style={{ background: "#1f2937", height: "100%", width: "8%" }}
    >
      <ul className=" nav flex-column  justify-content-between mt-4">
        {icon_properties.map((x) => {
          const color = props.page === x.header ? "white" : "grey"

          return (
            <li key={x.header} className="nav-item row text-center mt-5">
              <a className="nav-link active" aria-current="page" href={x.link}>
                <i style={{ color: `${color}` }} className={`${x.icon} fa-lg`} />
                <p style={{ fontSize: "16px", color }} className="fw-lighter fw-bold">
                  {x.header}
                </p>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Navbar
