import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getItineraries, getItinerary } from "@/itinerary_dashboard/selectors"
import { setItinerary } from "@/itinerary_dashboard/Slice"
import Select from "@/components/input/Select"

const ItineraryDropdown: FC = () => {
  const dispatch = useDispatch()

  const { selectedItineraryId } = useSelector(getItinerary)
  const itneraries = useSelector(getItineraries)

  return (
    <Select
      label="Itinerary"
      floating
      value={selectedItineraryId}
      onChange={({ target }) => dispatch(setItinerary(target.value))}
    >
      <option value="">All available intineraries</option>
      {itneraries.map((itnerary) => (
        <option key={itnerary.id} value={itnerary.id}>
          {itnerary.name}
        </option>
      ))}
    </Select>
  )
}

export default ItineraryDropdown
