import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getProductField } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({ name, label }): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>{label}</b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content: FC = () => (
  <div className="d-flex flex-column gap-3 px-4">
    <DisplayValue name={ReservedFields.OVERVIEW} label="Overview" />
    <Row>
      <Col>
        <DisplayValue name={ReservedFields.HIGHLIGHTS} label="Highlights" />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.IMPORTANT_NOTES} label="Important Notes" />
      </Col>
    </Row>
    <Row>
      <Col>
        <DisplayValue name={ReservedFields.WHAT_TO_BRING} label="What to Bring" />
      </Col>
      <Col>
        <DisplayValue name={ReservedFields.WHAT_TO_WEAR} label="What to Wear" />
      </Col>
    </Row>
  </div>
)

export default Content
