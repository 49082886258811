import _ from "lodash"

import * as yup from "yup"

import { getFields } from "./utils"

const parseYupSchema = (parts: string[][]) => {
  if (_.isEmpty(parts)) return null

  return _.reduce(parts, (memo, p) => memo[p[0]](...p.slice(1)), yup)
}

// Given a field definition, extracts a yup schema
export const yupSchema = (props) =>
  yup.object(
    _.reduce(
      getFields(props),
      (memo, field, name) => {
        if (_.has(field, "schemaVariants.yup")) {
          const fieldSchema = parseYupSchema(field.schemaVariants.yup)

          if (fieldSchema) {
            return _.merge(memo, { [name]: fieldSchema })
          }
          return memo
        }
        return memo
      },
      {}
    )
  )
