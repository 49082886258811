import React from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { getCouponByCode } from "@/manual_booking/features/booking_form/selectors"

import { getBookingFormById } from "./selectors"
import { removeCoupon } from "./Slice"

const CouponDisplay = ({ formId, code }): JSX.Element => {
  const dispatch = useDispatch()
  const selectedCoupon = useSelector(getCouponByCode(code))

  if (selectedCoupon) {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="d-flex flex-column gap-1">
          <h4 className="h6 mb-0">{selectedCoupon.coupon.name}</h4>
          <small>
            <i>{code}</i>
            {` • ${selectedCoupon.coupon.benefit} off`}
          </small>
        </div>
        <i
          className="bi bi-x-circle text-danger cursor-pointer"
          onClick={() => dispatch(removeCoupon({ formId, code }))}
        />
      </div>
    )
  }

  return null
}

const AppliedCoupons = ({ formId }): JSX.Element => {
  const { couponCodes } = useSelector((state) => getBookingFormById(state.tourBookings, formId))

  if (_.isEmpty(couponCodes)) {
    return <i>No coupons applied</i>
  }

  return (
    <div className="d-flex flex-column gap-1">
      {couponCodes.map((code) => (
        <CouponDisplay key={code} formId={formId} code={code} />
      ))}
    </div>
  )
}

export default AppliedCoupons
