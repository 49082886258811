import React from "react"

import { useSelector, useDispatch } from "react-redux"

import { Button } from "react-bootstrap"
import { copyBookingForm } from "@/manual_booking/features/transport_rental_bookings/Slice"
import ScreenSize from "@/helpers/ScreenSize"

const ActionBarButtons = (props) => {
  const isMobile = ScreenSize()

  const text = isMobile ? "Car" : "Private Car"

  const dispatch = useDispatch()

  const fields = useSelector((state) => state.transportRentalBookings.fields)
  return (
    <Button variant="secondary" onClick={() => dispatch(copyBookingForm({ sourceFormId: null }))}>
      <i className="bi bi-plus-lg"> </i> {text}
    </Button>
  )
}

export default ActionBarButtons
