import React from "react"
import { Button, Col, Row, Table } from "react-bootstrap"
import _ from "lodash"

import Field, { FieldTypes } from "@/document_editor/models/Field"

const DetailContent = ({ field }: { field: Field }): JSX.Element => {
  switch (field.type) {
    case FieldTypes.TOUR_COMPONENT_LIST:
      return (
        <ul>
          {field.value.map((component) => (
            <li key={component.name}>{component.name}</li>
          ))}
        </ul>
      )
    case FieldTypes.TEXT_LIST:
      return (
        <ul>
          {field.value.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      )
    default:
      return <div className="mx-3">{field.value}</div>
  }
}

const DetailsBlock = ({ field }: { field: Field }): JSX.Element => (
  <Row>
    <Col>
      <h3 className="h5 text-uppercase">{_.startCase(field.name)}</h3>
      <DetailContent field={field} />
    </Col>
  </Row>
)

const KioskView = ({ context, document }): JSX.Element => {
  const detailSection = _.find(document.sections, (s) => _.lowerCase(s.name) === "details")
  const sidebarSection = _.find(document.sections, (s) => _.lowerCase(s.name) === "sidebar")
  const headerSection = _.find(document.sections, (s) => _.lowerCase(s.name) === "header")

  let adultRate = "N/A"

  if (headerSection) {
    // TODO replace with indicative rate
    adultRate = _.find(headerSection.fields, (f) => f.name === "adult_rate").value
  }

  // TODO(data) Fetch real currency
  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <h1 className="h3 mb-0 text-uppercase">{context.name}</h1>
      </Row>
      <Row className="py-4" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <h2 className="h4 text-uppercase">Book This Shore Excursion</h2>
            <span>
              {"Starting from "}
              <span className="fs-3">{`$${adultRate}`}</span>
              {" / Adult"}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <Button variant="secondary" className="text-uppercase">
              Book Now
            </Button>
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={12} md={7} className="d-inline-flex flex-column gap-2">
          {detailSection &&
            detailSection.fields.map((field) => <DetailsBlock key={field.id} field={field} />)}
        </Col>
        <Col xs={12} md={5}>
          <Table>
            <thead>
              {["location", "tourCode", "duration"].map((key) => (
                <tr key={key}>
                  <th className="text-uppercase">{_.startCase(key)}</th>
                  <td>{context[key]}</td>
                </tr>
              ))}
              {sidebarSection &&
                sidebarSection.fields.map((field) => (
                  <tr key={field.id}>
                    <th className="text-uppercase">{_.startCase(field.name)}</th>
                    <td>{field.value}</td>
                  </tr>
                ))}
            </thead>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default KioskView
