import React, { useState } from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { settlement_path } from "@/routes"
import { getFilteredSettlementSheets, getFilteredTotals } from "@/daily_settlement/Slice"
import { StatusIcon } from "@/components/Icons"

import ToggleSettlement from "./body/ToggleSettlement"
import ToggleAllSettlements from "./header/ToggleAllSettlements"
import ReceivedTicketDifference from "./body/ReceivedTicketDifference"
import ReceivedTicketInput from "./body/ReceivedTicketInput"
import PriceBreakdownLink from "./body/PriceBreakdownLink"

const PriceHoverColumn = ({ totalPrice, settlementSheetId }) => {
  const [isShown, setIsShown] = useState(false)

  return (
    <td onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <div className="d-flex justify-content-end gap-2">
        <div className={isShown ? "" : "invisible"}>
          <PriceBreakdownLink settlementSheetId={settlementSheetId} />
        </div>
        <span>{totalPrice}</span>
      </div>
    </td>
  )
}

const Body = (): JSX.Element => {
  const filteredSettlementSheets = useSelector(getFilteredSettlementSheets)

  if (filteredSettlementSheets.length === 0) {
    return (
      <Card.Body>
        <p>No tours for this operator</p>
      </Card.Body>
    )
  }

  const {
    adultTotal,
    childTotal,
    underTotal,
    physicalTicketTotal,
    ReceivedTicketDifferenceTotal,
    priceTotal,
    finalizedTotal,
    paidTotal,
  } = useSelector(getFilteredTotals)

  return (
    <Table size="sm" className="align-items-center table-flush table-pad-sm">
      <thead className="thead-light">
        <tr>
          <th scope="col" className="text-center">
            <ToggleAllSettlements />
          </th>
          <th scope="col" className="text-start">
            Tour Settlement Sheet
          </th>
          <th scope="col" className="text-start">
            Operator
          </th>
          <th scope="col" className="text-center">
            Min / Max
          </th>
          <th scope="col" className="text-center">
            A / C
          </th>
          <th scope="col" className="text-center">
            Settled
          </th>
          <th scope="col" className="text-right">
            Recon
          </th>
          <th scope="col" className="text-right">
            Under
          </th>
          <th scope="col" className="text-right">
            Amount
          </th>
          <th scope="col" className="text-center">
            Invoiced
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredSettlementSheets.map((settlement) => (
          <tr key={settlement.settlementSheetId}>
            <td className="text-center">
              <ToggleSettlement settlementId={settlement.settlementSheetId} />
            </td>
            <td>
              <span className="d-block">
                <a href={settlement_path({ id: settlement.tourId })}>{settlement.tourName}</a>
              </span>
              <span className="d-block">{`${settlement.tourCode} - ${settlement.departure}`}</span>
            </td>
            <td>{_.join(settlement.operatorNames, ", ")}</td>
            <td className="text-center">{`${settlement.minimumPax} / ${settlement.maximumPax}`}</td>
            <td className="text-center">{`${settlement.adults} / ${settlement.children}`}</td>
            <td className="text-right" style={{ width: 100 }}>
              <ReceivedTicketInput settlementSheetId={settlement.settlementSheetId} />
            </td>
            <td className="text-right">
              <ReceivedTicketDifference settlementSheetId={settlement.settlementSheetId} />
            </td>
            <td className="text-right">{settlement.minimumUnder}</td>
            <PriceHoverColumn
              totalPrice={settlement.totalPrice}
              settlementSheetId={settlement.settlementSheetId}
            />
            <td className="text-center">
              <StatusIcon value={settlement.paid} />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colSpan={4}>
            Totals
          </th>
          <th className="text-center">{`${adultTotal} / ${childTotal}`}</th>
          <th className="text-right">{physicalTicketTotal}</th>
          <th className="text-right">{ReceivedTicketDifferenceTotal}</th>
          <th className="text-right">{underTotal}</th>
          <th className="text-right">{priceTotal}</th>
          <th className="text-center">{paidTotal}</th>
        </tr>
      </tfoot>
    </Table>
  )
}

export default Body
