import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getProductOperatorTotal,
  cruiseBrandExpanded,
  getCruiseBrandExpandedProductAwards,
  getPriceAnalysis,
  getOperatorTotalForProducts,
  getCruiseBrandProducts,
} from "@/onboarding/features/price_analysis/selectors"
import {
  expandCruiseBrand,
  minimizeCruiseBrand,
  selectProduct,
} from "@/onboarding/features/price_analysis/Slice"
import { formatMoney } from "@/helpers/money"

const CruiseBrandOperatorTotal = ({ filteredBrandProducts, operatorId }): JSX.Element => {
  const { amount, currency } = useSelector(
    getOperatorTotalForProducts(filteredBrandProducts)(operatorId)
  )

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(amount, currency)}</>
}

const ProductOperatorTotal = ({ productId, operatorId }): JSX.Element => {
  const { expectedTotal, currency } = useSelector(getProductOperatorTotal(productId)(operatorId))

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(expectedTotal, currency)}</>
}

const CruiseBrandRow = ({ cruiseBrand, operators }): JSX.Element => {
  const dispatch = useDispatch()
  const selected = useSelector(cruiseBrandExpanded(cruiseBrand.id))
  const cruiseBrandProducts = useSelector(getCruiseBrandProducts(cruiseBrand.id))
  const productAwards = useSelector(getCruiseBrandExpandedProductAwards(cruiseBrand.id))
  const { selectedOperatorId, selectedPortId } = useSelector(getPriceAnalysis)

  const filteredBrandProducts = cruiseBrandProducts.filter((product) => {
    const matchesPort = !selectedPortId || product.port.id === selectedPortId

    const matchesOperator =
      !selectedOperatorId ||
      product.biddingOperators.some((operator) => operator.id === selectedOperatorId)

    return matchesPort && matchesOperator
  })

  const operatorColumnClass = (productId, operatorId) =>
    productAwards[productId] === operatorId ? "text-right bg-secondary" : "text-right"

  const onClick = selected
    ? () => dispatch(minimizeCruiseBrand())
    : () => dispatch(expandCruiseBrand({ cruiseBrandId: cruiseBrand.id }))

  let row

  if (filteredBrandProducts.length > 0) {
    row = (
      <tr className="cursor-pointer" onClick={onClick}>
        <th scope="row">
          {`${cruiseBrand.name} `}
          <span className="badge bg-primary">{filteredBrandProducts.length}</span>
        </th>
        <td />
        {operators.map((operator) => (
          <td key={operator.id} className="text-right">
            <CruiseBrandOperatorTotal
              filteredBrandProducts={filteredBrandProducts}
              operatorId={operator.id}
            />
          </td>
        ))}
      </tr>
    )
  } else {
    row = (
      <tr>
        <th scope="row">{cruiseBrand.name}</th>
        <td />
        {operators.map((operator) => (
          <td key={operator.id} className="text-right">
            <CruiseBrandOperatorTotal
              filteredBrandProducts={filteredBrandProducts}
              operatorId={operator.id}
            />
          </td>
        ))}
      </tr>
    )
  }

  if (selected) {
    return (
      <>
        {row}
        {filteredBrandProducts.map((product) => (
          <tr key={product.id}>
            <th
              scope="row"
              className="text-underline cursor-pointer"
              onClick={() => dispatch(selectProduct({ productId: product.id }))}
            >
              <div className="ms-3">{product.code}</div>
            </th>
            <td>{product.name}</td>
            {operators.map((operator) => (
              <td key={operator.id} className={operatorColumnClass(product.id, operator.id)}>
                <ProductOperatorTotal productId={product.id} operatorId={operator.id} />
              </td>
            ))}
          </tr>
        ))}
      </>
    )
  }

  return row
}

export default CruiseBrandRow
