import React from "react"

const TourPaxConstraints = ({ tour }) => {
  if (tour.confirmedGuests < tour.minimumPax) {
    return (
      <>
        {`${tour.minimumPax} / ${tour.maximumPax} `}
        <span className="fas fa-exclamation text-danger" />
      </>
    )
  }

  return <>{`${tour.minimumPax} / ${tour.maximumPax}`}</>
}

export default TourPaxConstraints
