import React from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { navigateToDashboard } from "@/onboarding/features/price_analysis/Slice"
import { getPriceAnalysis } from "../../selectors"

const DashboardButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedBiddingRegionId } = useSelector(getPriceAnalysis)

  return (
    <Button
      size="sm"
      variant="secondary"
      onClick={() => dispatch(navigateToDashboard({ biddingRegionId: selectedBiddingRegionId }))}
    >
      Region Cost Analysis
    </Button>
  )
}

export default DashboardButton
