import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { createPayloadReducer } from "@/helpers/redux"
import Api from "@/api"
import OnScreenNotifications, { newAxiosErrorHandler } from "@/osn"
import routes from "@/routes"

import { formatDate, FORMATS as DATE_FORMATS } from "@/components/DatePickers"

const createOperatorApplicationSlice = ({ name }) => {
  const updateOperatorDetails = createAsyncThunk(
    `${name}/updateOperatorDetails`,
    async ({ values }, thunkAPI) => {
      const { operatorApplicationId } = thunkAPI.getState()[name]

      return Api.operatorApplication
        .updateOperatorDetails(operatorApplicationId, values)
        .catch(newAxiosErrorHandler(thunkAPI.dispatch))
        .then((response) => {
          thunkAPI.dispatch(
            OnScreenNotifications.success(response.data.message, { duration: 1500 })
          )
          return response
        })
        .then((response) => ({ operator: response.data.data.operator }))
    }
  )

  const updateOperatorQuestionnaire = createAsyncThunk(
    `${name}/updateOperatorQuestionnaire`,
    async ({ values }, thunkAPI) => {
      const { operatorApplicationId } = thunkAPI.getState()[name]

      return Api.operatorApplication
        .updateOperatorQuestionnaire(operatorApplicationId, values)
        .catch(newAxiosErrorHandler(thunkAPI.dispatch))
        .then((response) => {
          thunkAPI.dispatch(
            OnScreenNotifications.success(response.data.message, { duration: 1500 })
          )
          return response
        })
        .then((response) => ({ operatorQuestionnaire: response.data.data.operatorQuestionnaire }))
    }
  )

  const submitAgreement =
    ({ signature }) =>
    async (dispatch, getState) => {
      const { operatorApplicationId, agreement } = getState()[name]

      return Api.operatorApplication
        .submitAgreement(operatorApplicationId, {
          signature,
          document_agreements: agreement.documents,
          agreement_text: agreement.agreementTextUnformatted,
        })
        .then((_response) => {
          window.location = routes.onboarding_operator_profiles_path()
        }, newAxiosErrorHandler(dispatch))
    }

  const slice = createSlice({
    name,
    initialState: {
      initialized: false,
      submitting: false,
      operatorApplicationId: null,
      operator: {},
      agreement: {},
      portDetailsForm: {
        selectedPorts: [],
      },
    },
    reducers: {
      initialize: createPayloadReducer({ persistentState: { initialized: true } }),
      updatePortDetailsForm: createPayloadReducer({ key: "portDetailsForm" }),
      agreeToDocument: (state, { payload }) => {
        const docs = _.cloneDeep(state.agreement.documents)

        _.each(docs, (d) => {
          if (d.id == payload.documentId) {
            if (d.agreed) {
              d.agreed = false
              d.agreedOnDate = ""
              d.agreedByUser = ""
            } else {
              d.agreed = true
              d.agreedOnDate = formatDate(new Date(), DATE_FORMATS.humaneShort)
              d.agreedByUser = state.currentUserName
            }
          }
          d
        })

        return {
          ...state,
          agreement: {
            ...state.agreement,
            documents: docs,
          },
        }
      },
    },
    extraReducers: {
      [updateOperatorDetails.pending]: (state) => ({
        ...state,
        submitting: true,
      }),
      [updateOperatorDetails.rejected]: (state) => ({
        ...state,
        submitting: false,
      }),
      [updateOperatorDetails.fulfilled]: (state, { payload }) => ({
        ...state,
        submitting: false,
        operator: payload.operator,
      }),
      [updateOperatorQuestionnaire.pending]: (state) => ({
        ...state,
        submitting: false,
      }),
      [updateOperatorQuestionnaire.rejected]: (state) => ({
        ...state,
        submitting: false,
      }),
      [updateOperatorQuestionnaire.fulfilled]: (state, { payload }) => ({
        ...state,
        submitting: false,
        operatorQuestionnaire: payload.operatorQuestionnaire,
      }),
    },
  })

  // I'm lazy and in our case I don't see any need to have a special import for thunks...
  slice.actions = _.merge({}, slice.actions, {
    updateOperatorDetails,
    updateOperatorQuestionnaire,
    submitAgreement,
  })

  return slice
}

export default createOperatorApplicationSlice
