import React from "react"
import { DropdownButton } from "react-bootstrap"

import {
  confirm_tour_booking_path,
  waitlist_tour_booking_path,
  send_guest_cancellation_letter_tour_booking_path,
} from "@/routes"

import { PostDropdownItem } from "@/components/post_links"
import Booking from "@/dashboard/models/Booking"

const CancelledBookingTools = ({ booking }: { booking: Booking }): JSX.Element => (
  // TODO Why can't we use the format:"json" key below????
  <DropdownButton size="sm" title="Tools" boundary="viewport">
    <PostDropdownItem
      url={confirm_tour_booking_path({ id: booking.bookingId })}
      linkText="Confirm"
    />
    <PostDropdownItem
      url={confirm_tour_booking_path({ id: booking.bookingId })}
      params={{ print_tickets: true }}
      linkText="Confirm & Print Tickets"
    />
    <PostDropdownItem
      url={waitlist_tour_booking_path({ id: booking.bookingId })}
      linkText="Waitlist"
    />
    <PostDropdownItem
      url={send_guest_cancellation_letter_tour_booking_path({ id: booking.bookingId })}
      linkText="Send Cancellation Message To Guests"
    />
  </DropdownButton>
)

export default CancelledBookingTools
