export const getState = ({ manualBooking }) => manualBooking

export const getFields = (state) => getState(state).fields

export const getChargedRoomAccount = (state) => getState(state).chargedRoomAccount

export const getSelectedCoupon = (state) => getState(state).selectedCoupon

// TODO Consider an alternative where this additional context is no longer required
export const getSubmitableGlobalData = (state) => ({
  chargedRoomAccount: getChargedRoomAccount(state),
})

// TODO Store multiple coupons and search here
export const getCouponByCode = (code) => (state) => {
  const selectedCoupon = getSelectedCoupon(state)

  if (selectedCoupon && selectedCoupon.code === code) {
    return selectedCoupon
  }

  return null
}
