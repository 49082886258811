import React from "react"
import TransportCategories from "./TransportCategories"
import InsuranceRequirements from "./InsuranceRequirements"

const InsuranceSummary = ({ props }) => (
  <>
    <InsuranceRequirements props={props} />
    <TransportCategories props={props} />
  </>
)

export default InsuranceSummary
