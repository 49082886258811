import React from "react"
import { Table } from "react-bootstrap"

const BulkRatesheetView = ({ value }) => (
  <Table>
    <thead className="mb-2">
      <tr>
        <th>Bulk Rate</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{value.bulkRate}</td>
      </tr>
    </tbody>
  </Table>
)

export default BulkRatesheetView
