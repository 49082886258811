import React, { FC } from "react"
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import routes from "@/routes"
import ButtonLink from "@/components/ButtonLink"

import { getProfiles, getView } from "./selectors"

const seasonNameTooltip = ({ seasons, includeTourCount = false }) => (
  <Tooltip className="m-0">
    {_.map(seasons, (s) => `${s.name}${!includeTourCount ? "" : ` (${s.contractedTourCount})`}`)
      .join(", ")
      .valueOf()}
  </Tooltip>
)

const profileName = (profile) => {
  if (profile.id) {
    return (
      <a className="text-underline" href={routes.onboarding_operator_profile_path(profile.id)}>
        {profile.heading}
      </a>
    )
  }
  return profile.heading
}

const profileSeasons = (profile) =>
  _.isEmpty(profile.seasons) ? null : (
    <OverlayTrigger placement="bottom" overlay={seasonNameTooltip({ seasons: profile.seasons })}>
      <span className="d-block hover-underline">{profile.seasons.length}</span>
    </OverlayTrigger>
  )

const contractedTours = (profile) =>
  _.isEmpty(profile.seasons) ? null : (
    <OverlayTrigger
      placement="bottom"
      overlay={seasonNameTooltip({ seasons: profile.seasons, includeTourCount: true })}
    >
      <span className="d-block hover-underline">
        {_(profile.seasons).map("contractedTourCount").sum()}
      </span>
    </OverlayTrigger>
  )

const applicationStatus = (application) =>
  _.isNil(application) ? <i>&mdash;</i> : <span>{application.status}</span>

const profileActions = (profile) => {
  const actions = []

  if (profile.applicationContinuable) {
    actions.push(
      <ButtonLink
        href={routes.onboarding_operator_application_path(profile.application.id)}
        label="Continue Application"
        button={{ variant: "outline-primary" }}
        key="continue"
      />
    )
  }

  if (profile.reinvitable) {
    actions.push(
      <ButtonLink
        href={routes.invite_operator_onboarding_operator_profiles_path({
          operator_id: profile.operator.id,
        })}
        label="Request Reapplication"
        button={{ variant: "outline-primary" }}
        method="post"
        key="invite"
      />
    )
  }

  if (profile.reviewable) {
    actions.push(
      <ButtonLink
        href={routes.onboarding_operator_profile_path(profile.id)}
        label="Review"
        button={{ variant: "outline-primary" }}
        key="review"
      />
    )
  }

  if (profile.viewable) {
    actions.push(
      <ButtonLink
        href={routes.onboarding_operator_profile_path(profile.id)}
        label="View"
        button={{ variant: "outline-primary" }}
        key="view"
      />
    )
  }

  if (profile.invitable) {
    actions.push(
      <ButtonLink
        href={routes.invite_operator_onboarding_operator_profiles_path({
          operator_id: profile.operator.id,
        })}
        label="Request Application"
        button={{ variant: "outline-primary" }}
        method="post"
        key="invite"
      />
    )
  }

  if (profile.applyable) {
    actions.push(
      <ButtonLink
        href={routes.apply_to_cruise_company_onboarding_operator_profiles_path({
          cruise_company_id: profile.cruiseCompany.id,
        })}
        label="Apply"
        button={{ variant: "outline-primary" }}
        method="post"
        key="apply"
      />
    )
  }

  return actions
}

const ProfileTable: FC = () => {
  const profiles = useSelector(getProfiles)
  const view = useSelector(getView)

  const numberOfColumns = 3 + (view.showVendorNumbers ? 1 : 0) + (view.showSeasons ? 1 : 0)

  return (
    <Table responsive size="sm" className="align-items-center table-pad-sm">
      <thead className="thead-light">
        <tr>
          <th scope="col">Name</th>
          {view.showVendorNumbers && <th scope="col">Vendor Number</th>}
          {view.showSeasons && <th scope="col">Seasons</th>}
          {view.showSeasons && <th scope="col">Contracted Tours</th>}
          <th scope="col">Active Application</th>
          <th scope="col">Approved At</th>
          <th scope="col">New Application </th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {_.isEmpty(profiles) && (
          <tr>
            <td colSpan={numberOfColumns}>No companies match the search criteria above.</td>
          </tr>
        )}
        {profiles.map((profile) => (
          <tr key={`${profile.id}-${profile.heading}`}>
            <td>{profileName(profile)}</td>
            {view.showVendorNumbers && <td>{profile.vendorNumber}</td>}
            {view.showSeasons && <td>{profileSeasons(profile)}</td>}
            {view.showSeasons && <td>{contractedTours(profile)}</td>}
            <td>{applicationStatus(profile.currentApplication)}</td>
            <td>
              {profile.currentApplication &&
                profile.currentApplication.isApproved &&
                profile.currentApplication.approvedAt}
            </td>
            <td>{applicationStatus(profile.application)}</td>
            <td className="d-flex justify-content-end gap-2">{profileActions(profile)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ProfileTable
