import React from "react"
import { Button } from "react-bootstrap"
import { useField } from "formik"
import _ from "lodash"

const ProposalButton = ({ fieldName, action }) => {
  const [field, meta, helpers] = useField(fieldName)
  const { proposal } = field.value

  let variant

  if (action === "accept") {
    if (proposal.accepted) {
      variant = "success"
    } else {
      variant = "outline-success"
    }
  } else if (proposal.rejected) {
    variant = "danger"
  } else {
    variant = "outline-danger"
  }

  const onClick = () => {
    const newProposal = {
      ...proposal,
    }

    if (action === "accept") {
      newProposal.accepted = !proposal.accepted
      newProposal.rejected = false
    } else {
      newProposal.accepted = false
      newProposal.rejected = !proposal.rejected
    }

    helpers.setValue({
      ...field.value,
      proposal: newProposal,
    })
  }

  return (
    <Button variant={variant} size="sm" onClick={onClick}>
      <i className={`fas fa-${action === "accept" ? "check" : "times"} me-2`} />
      {_.startCase(action)}
    </Button>
  )
}

export default ProposalButton
