import React, { useEffect, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import FloatableGroup from "./FloatableGroup"
import Feedback from "./Feedback"
import { warnInputPropsForwarding, omitDeprecatedInputProps } from "./deprecations"

const Text = ({
  label = undefined,
  floating = false,
  debounce = false,
  formGroupOptions = {},
  validationMessage = "",
  type = undefined,
  className = undefined,
  showLockedIcon = false,
  showRequired = false,
  suffix = "",
  ...props
}) => {
  warnInputPropsForwarding(props)
  const inputProps = omitDeprecatedInputProps(props)

  const placeholderStyle = `
    .placeholder-muted::placeholder {
      color: lightgrey;
      opacity: 2;
    }
  `

  if (!debounce) {
    return (
      <FloatableGroup
        label={label}
        floating={floating}
        {...formGroupOptions}
        className={className}
        showLockedIcon={showLockedIcon}
        showRequired={showRequired}
      >
        <style>{placeholderStyle}</style>
        <InputGroup>
          <Form.Control type={type} {...inputProps} className="placeholder-muted" />
          {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
          <Feedback.Invalid message={validationMessage} />
        </InputGroup>
      </FloatableGroup>
    )
  }

  const [initialized, setInitialized] = useState(false)
  const [delayedValue, setDelayedValue] = useState(inputProps.value || "")

  useEffect(() => {
    // Avoid firing the onChange when this component first loads
    if (!initialized) {
      setInitialized(true)
      return
    }

    const timeOutId = setTimeout(
      () => inputProps.onChange({ target: { value: delayedValue } }),
      500
    )

    return () => clearTimeout(timeOutId)
  }, [delayedValue])

  return (
    <FloatableGroup
      label={label}
      floating={floating}
      {...formGroupOptions}
      className={className}
      showLockedIcon={showLockedIcon}
      showRequired={showRequired}
    >
      <style>{placeholderStyle}</style>
      <InputGroup>
        <Form.Control
          {...inputProps}
          value={delayedValue}
          onChange={(e) => setDelayedValue(e.target.value)}
          className="placeholder-muted"
        />
        {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
      </InputGroup>
      <Feedback.Invalid message={validationMessage} />
    </FloatableGroup>
  )
}

export default Text
