import Booking from "./Booking"

export enum ArrangementsStates {
  CONFIRMED = "confirmed",
  PENDING = "pending",
}

export enum OperationalStates {
  ACTIVE = "active",
  CANCELLED = "cancelled",
}

export enum SettlementStates {
  PENDING = "pending",
  FINALIZED = "finalized",
  RECON = "recon",
}

export interface RateItem {
  adult: string
  child: string
}

export interface Rates {
  net: RateItem
  rack: RateItem
}

export default interface Tour {
  tourId: string
  arrangementsState: ArrangementsStates
  operationalState: OperationalStates
  settlementState: SettlementStates
  departureStatus?: string
  code: string
  name: string
  portName: string
  portId: string
  voyageId: string
  timeslot: string
  closedForSales: boolean
  startTime: string
  endTime: string
  minimumPax: number
  maximumPax: number
  guests: number
  confirmedGuests: number
  waitlistedGuests: number
  adults: number
  confirmedAdults: number
  children: number
  confirmedChildren: number
  availableCount: number
  cancellationCutoffTime: string
  components: string
  operators: Array<string>
  manager: string
  netPrice: string
  retailPrice: string
  rates: Rates
  currency: string
  shipName: string
  bookings: Array<Booking>
  confirmedBookings: Array<Booking>
  waitlistedBookings: Array<Booking>
  cancelledBookings: Array<Booking>
  printedTicketCount: number
  ticketCount: number
  stickers: any
  unresolvedComplaints: number
  selfOperated: boolean
  reconDifference: number
}
