import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import { getDefaultRateCategories, getProductField } from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { ComponentType, newComponent } from "@/onboarding/features/product/models/Component"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"

const Edit = ({ setEditing }): JSX.Element => {
  const { value } = useSelector(getProductField(ReservedFields.EXTRA_FEES))
  const defaultRateCategories = useSelector(getDefaultRateCategories)
  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Formik
      initialValues={value}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(updateFields({ values: { [ReservedFields.EXTRA_FEES]: values } }))
      }}
    >
      {({ handleSubmit, dirty, values, setValues }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          {values.map((fee, index) => (
            <Row key={index}>
              <Col>
                <FormikBootstrap.TextInput name={`${index}.name`} label="Name" />
              </Col>
              <Col>
                <FormikBootstrap.TextInput name={`${index}.category`} label="Category" />
              </Col>
              <Col>
                <FormikBootstrap.NumberInput name={`${index}.ratesheet.rates.adult`} label="Rate" />
              </Col>
              <Col className="d-flex align-items-end gap-2">
                <FormikBootstrap.Switch
                  name={`${index}.payableByGuest`}
                  label="Payable by guests?"
                />
                <div>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => setValues(_.without(values, values[index]))}
                    disabled={isSubmitting}
                  >
                    <i className="fas fa-times me-2" />
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          ))}
          <div className="d-flex gap-2">
            <Button
              size="sm"
              onClick={() =>
                setValues(
                  values.concat(
                    newComponent({ defaultRateCategories, kind: ComponentType.EXTRA_FEE })
                  )
                )
              }
              disabled={isSubmitting}
            >
              <i className="fas fa-plus me-2" />
              Add Fee
            </Button>
            <Button size="sm" type="submit" disabled={!dirty || isSubmitting}>
              <i className="fas fa-save me-2" />
              Save
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
