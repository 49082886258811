import _ from "lodash"

/**
 * Used to warn in the console about props that are being forwarded down without discretion.
 *
 * This forwarding, though convenient results in warnings from React regarding undefined +DOM+ attributes, and in the long-run
 * may actually make code less changeable as we have no clue what the API of the method is
 * (because the props object is unbounded and some keys may be depended upon by elements further down the rendering tree)
 *
 */
export const warnInputPropsForwarding = (props) => {
  if (props.length > 0) {
    // eslint-disable-next-line no-console
    console.warn(`Unspecified prop(s) being passed to input component: ${_.keys(props)}`)
  }
}

// Omit some known props instead of only permitting specific props (because we don't know what the specific props are!)
export const omitDeprecatedInputProps = (props) => _.omit(props, "schemaVariants")
