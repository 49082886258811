import React, { useEffect, useState } from "react"

import { Form } from "react-bootstrap"

/**
 * items
 *  Array containing all the items to filter
 *
 * id
 *  HTML Component ID
 *
 * onFiltered
 *  Function that takes an array of the filtered items
 *
 * filterOn
 *  Function that given an item returns either (a) an array of string, or (b) a single string used to match against the search pattern
 */
const RegexSearchInput = ({ items, id, onFiltered, filterOn, label = "Search" }) => {
  const [query, setQuery] = useState("")

  const matches = (regex, item) => {
    if (_.isString(item)) return matches(regex, [item])
    return _.some(item, (i) => i.search(regex) >= 0)
  }

  useEffect(() => {
    if (query === "") {
      onFiltered(items)
    } else {
      const queryRegex = new RegExp(_.escapeRegExp(query), "i")
      onFiltered(_.filter(items, (i) => matches(queryRegex, filterOn(i))))
    }
  }, [query, items])

  return (
    <div>
      <Form.Label>{label}</Form.Label>
      <Form.Control onChange={(event) => setQuery(event.target.value || "")} />
    </div>
  )
}

export default RegexSearchInput
