import React from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getCharges, getID } from "@/guests_room_accounts/selectors"
import {
  getCurrency,
  formatAmount,
  getTotalCost,
  formatDescription,
} from "@/guests_room_accounts/helpers/Charges"

const RoomChargesCard = (): JSX.Element => {
  const charges = useSelector(getCharges)
  const guestID = useSelector(getID)

  const total = _.isEmpty(charges)
    ? "0.00"
    : formatAmount(getCurrency(charges), getTotalCost(charges))

  return (
    <Card className="mt-4">
      <h4 className="mt-2 ms-2">Charges</h4>
      <div className="table-responsive-sm">
        <Table size="sm" className="align-items-center table-sm">
          <thead>
            <tr>
              <th scope="col" className="text-start">
                <span className="ms-n3"> Description </span>
              </th>
              <th scope="col" className="text-center">
                <span className="ms-1"> Amount </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(charges, (value) => (
              <tr key={value.descriptionUrl}>
                <td scope="col" className="text-start">
                  <span className="ms-n3"> {formatDescription(value, guestID)} </span>
                </td>
                <td scope="col" className="text-center">
                  <span className="ms-1">
                    {" "}
                    {formatAmount(getCurrency(charges), value.amountInMoney.fractional)}{" "}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <th scope="row">
                <span className="ms-n3"> Total </span>
              </th>
              <td scope="col" className="text-center">
                <span className="ms-1"> {total} </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Card>
  )
}

export default RoomChargesCard
