import { createContext } from "react"

export default createContext({
  isShxUi: false,
  isProcurementUi: false,
  isCruiseLineUi: false,
  isTourUi: false,
  isGuestUi: false,
  companyId: null,
})
