import React from "react"
import { Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getField, getProductField } from "@/onboarding/features/product/selectors"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import FormikFieldSubmitter from "@/onboarding/features/product/components/shared/FormikFieldSubmitter"
import RateInputs from "@/onboarding/features/product/components/shared/RateInputs"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { updateFields } from "@/onboarding/features/product/Slice"

import * as RateTable from "./RateTable"

const Edit = ({ fieldName }: { fieldName: string }): JSX.Element => {
  const field = useSelector(getField(fieldName))

  return (
    <FormikFieldSubmitter initialValues={{ [fieldName]: field.value }}>
      <RateInputs name={fieldName} />
    </FormikFieldSubmitter>
  )
}

const RatesheetRow = ({
  fieldName,
  rowLabel,
  showRateSelectionButton = false,
  editable = true,
}: {
  fieldName: string
  rowLabel: string
  showRateSelectionButton?: boolean
  editable?: boolean
}): JSX.Element => {
  const dispatch = useDispatch()
  const { value: ratesheet } = useSelector(getProductField(fieldName))
  const { value: selectedRatesheetField } = useSelector(
    getProductField(ReservedFields.SELECTED_NET_RATESHEET_FIELD)
  )
  const fieldSettings = useSelector(getField(fieldName))
  const label = fieldSettings.required ? `${rowLabel} *` : rowLabel

  const toggleModal =
    fieldSettings.writeable &&
    useModal((props) => (
      <EditModal {...props} title={rowLabel} Body={Edit} bodyProps={{ fieldName }} />
    ))

  return (
    <Table>
      <thead>
        <RateTable.HeaderRow
          editIcon={
            editable && fieldSettings.writeable ? <EditIcon onEdit={toggleModal} /> : undefined
          }
        >
          <th colSpan={4} scope="row" aria-label="Header" />
        </RateTable.HeaderRow>
      </thead>
      <tbody>
        <RateTable.Row ratesheet={ratesheet}>
          <th colSpan={4} scope="row">
            {showRateSelectionButton ? (
              // TODO Switch over to a FormikBootstrap thing here
              <>
                <input
                  name={ReservedFields.SELECTED_NET_RATESHEET_FIELD}
                  type="radio"
                  checked={selectedRatesheetField === fieldName}
                  className="me-2"
                  value={fieldName}
                  onChange={() =>
                    dispatch(
                      updateFields({
                        values: { [ReservedFields.SELECTED_NET_RATESHEET_FIELD]: fieldName },
                      })
                    )
                  }
                />
                {label}
              </>
            ) : (
              <>{label}</>
            )}
          </th>
        </RateTable.Row>
      </tbody>
    </Table>
  )
}

RatesheetRow.defaultProps = {
  editable: false,
  showRateSelectionButton: false,
}

export default RatesheetRow
