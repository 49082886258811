import _ from "lodash"

import { requireKeys } from "@/utils"
import * as AsyncField from "@/helpers/AsyncField"
import { createBookingsSlice } from "@/manual_booking/createBookingsSlice"

import {
  fetchTransmissionOptions,
  fetchVehicleClassOptions,
  findMatchingVehicles,
  fetchAirconOptions,
  fetchUnlimitedMileageOptions,
  fetchDrivers,
} from "./thunks"
import { getBookingFormByAction } from "./selectors"

const newBookingForm = (overrides = {}) => {
  const bookingForm = {
    pickupLocationSameAsDropoffLocation: true,

    pickupDateTime: new Date().toISOString(),

    dropoffDateTime: new Date().toISOString(),

    pickupLocation: "",

    dropoffLocation: "",

    destinationLocation: "",

    couponCodes: [],

    transmission: "", // Any

    vehicleClass: "",

    passengerCount: "1",

    aircon: "",

    unlimitedMileage: "",

    specialRequirements: "",

    driverId: undefined,

    _selectedDriver: undefined,

    vehiclePoolId: undefined,

    rentalCompanyId: undefined,

    _selectedVehicle: undefined,

    _description: "",

    _price: AsyncField.createField({ defaultValue: null }),

    _airconOptions: AsyncField.createField({ defaultValue: [{ label: "Any", value: "" }] }),

    _unlimitedMileageOptions: AsyncField.createField({
      defaultValue: [{ label: "Any", value: "" }],
    }),

    _transmissionOptions: AsyncField.createField({ defaultValue: [{ label: "Any", value: "" }] }),

    _vehicleClassOptions: AsyncField.createField({ defaultValue: [{ label: "Any", value: "" }] }),

    _matchingVehicles: AsyncField.createField({ defaultValue: [] }),

    _driverSearch: AsyncField.createField({ defaultValue: [] }),

    kind: "TransportRentalBooking",

    createdAtMillis: Date.now(),
  }

  const resultForm = _.merge(bookingForm, overrides)

  updateDescription(resultForm)

  return resultForm
}

const invalidateSearchResults = (form) => {
  form._selectedVehicle = undefined
  form.vehiclePoolId = undefined
  form.rentalCompanyId = undefined
  AsyncField.invalidateField(form._matchingVehicles)
  AsyncField.invalidateField(form._price)
  updateDescription(form)
}

const invalidateOptions = (form) => {
  AsyncField.invalidateField(form._vehicleClassOptions)
  AsyncField.invalidateField(form._transmissionOptions)
  AsyncField.invalidateField(form._airconOptions)
  AsyncField.invalidateField(form._unlimitedMileageOptions)

  invalidateSearchResults(form)
}

const updateDescription = (form) => {
  let desc = "Private Vehicle Rental"
  if (form._selectedVehicle) {
    desc = `${form._selectedVehicle.exampleVehicle} or similar Private Vehicle Rental`
  }

  form._description = desc
}

const Slice = createBookingsSlice({
  name: "transportRentalBookings",
  newBookingForm,
  initialState: {
    passengers: {},
  },
  reducers: {
    setPickupDateTime: (state, action) => {
      requireKeys(action.payload, "pickupDateTime")

      const form = getBookingFormByAction(state, action)

      form.pickupDateTime = action.payload.pickupDateTime

      invalidateOptions(form)

      return state
    },
    setDropoffDateTime: (state, action) => {
      requireKeys(action.payload, "dropoffDateTime")

      const form = getBookingFormByAction(state, action)

      form.dropoffDateTime = action.payload.dropoffDateTime

      invalidateOptions(form)

      return state
    },
    setDestinationLocation: (state, action) => {
      requireKeys(action.payload, "destinationLocation")

      const form = getBookingFormByAction(state, action)

      form.destinationLocation = action.payload.destinationLocation

      return state
    },
    setPickupLocation: (state, action) => {
      requireKeys(action.payload, "pickupLocation")

      const form = getBookingFormByAction(state, action)

      form.pickupLocation = action.payload.pickupLocation

      if (form.pickupLocationSameAsDropoffLocation) {
        form.dropoffLocation = form.pickupLocation
      }

      return state
    },
    setDropoffLocation: (state, action) => {
      requireKeys(action.payload, "dropoffLocation")

      const form = getBookingFormByAction(state, action)

      form.dropoffLocation = action.payload.dropoffLocation

      return state
    },
    selectVehicleClass: (state, action) => {
      requireKeys(action.payload, "vehicleClass")

      const form = getBookingFormByAction(state, action)

      form.vehicleClass = action.payload.vehicleClass

      invalidateSearchResults(form)

      return state
    },
    selectTransmissionType: (state, action) => {
      requireKeys(action.payload, "transmission")

      const form = getBookingFormByAction(state, action)

      form.transmission = action.payload.transmission

      invalidateSearchResults(form)

      return state
    },
    selectPassengerCount: (state, action) => {
      requireKeys(action.payload, "passengerCount")

      const form = getBookingFormByAction(state, action)

      form.passengerCount = action.payload.passengerCount

      invalidateSearchResults(form)

      return state
    },
    selectAircon: (state, action) => {
      requireKeys(action.payload, "aircon")

      const form = getBookingFormByAction(state, action)

      form.aircon = action.payload.aircon

      invalidateSearchResults(form)

      return state
    },
    selectUnlimitedMileage: (state, action) => {
      requireKeys(action.payload, "unlimitedMileage")

      const form = getBookingFormByAction(state, action)

      form.unlimitedMileage = action.payload.unlimitedMileage

      invalidateSearchResults(form)

      return state
    },
    setSpecialRequirements: (state, action) => {
      requireKeys(action.payload, "value")

      const form = getBookingFormByAction(state, action)

      form.specialRequirements = action.payload.value

      return state
    },
    selectVehicle: (state, action) => {
      requireKeys(action.payload, "vehicle")

      const form = getBookingFormByAction(state, action)

      form._selectedVehicle = action.payload.vehicle
      form.vehiclePoolId = form._selectedVehicle.id
      form.rentalCompanyId = form._selectedVehicle.rentalCompanyId

      // Copy over the price
      AsyncField.setFieldReady(form._price, {
        value: form._selectedVehicle.totalPrice,
        message: "",
      })

      updateDescription(form)

      return state
    },
    clearSelectedVehicle: (state, action) => {
      const form = getBookingFormByAction(state, action)

      invalidateSearchResults(form)

      return state
    },
    selectDriver: (state, action) => {
      requireKeys(action.payload, "driver")

      const form = getBookingFormByAction(state, action)

      form._selectedDriver = action.payload.driver
      form.driverId = form._selectedDriver.id

      return state
    },
    clearDriver: (state, action) => {
      const form = getBookingFormByAction(state, action)

      form._selectedDriver = undefined
      form.driverId = undefined

      return state
    },
    togglePickupLocationSameAsDropoff: (state, action) => {
      const form = getBookingFormByAction(state, action)

      form.pickupLocationSameAsDropoffLocation = !form.pickupLocationSameAsDropoffLocation

      if (form.pickupLocationSameAsDropoffLocation) {
        form.dropoffLocation = form.pickupLocation
      }

      invalidateSearchResults(form)

      return state
    },
    addCoupon: (state, action) => {
      requireKeys(action.payload, "code")

      const form = getBookingFormByAction(state, action)

      const { code } = action.payload

      form.couponCodes = _.union(form.couponCodes, [code])

      AsyncField.invalidateField(form._price)

      return state
    },
  },
  extraReducers: {
    ...fetchVehicleClassOptions.reducers,
    ...fetchTransmissionOptions.reducers,
    ...findMatchingVehicles.reducers,
    ...fetchAirconOptions.reducers,
    ...fetchUnlimitedMileageOptions.reducers,
    ...fetchDrivers.reducers,
  },
})

export const {
  initialize,
  setPickupDateTime,
  setDropoffDateTime,
  selectVehicleClass,
  selectTransmissionType,
  selectPassengerCount,
  setSpecialRequirements,
  togglePickupLocationSameAsDropoff,
  selectVehicle,
  selectAircon,
  selectUnlimitedMileage,
  clearSelectedVehicle,
  copyBookingForm,
  removeBookingForm,
  setPickupLocation,
  setDropoffLocation,
  setDestinationLocation,
  clearDriver,
  selectDriver,
  addCoupon,
} = Slice.actions

export default Slice
