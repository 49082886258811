import React from "react"
import { useSelector } from "react-redux"

import { formatMoney } from "@/helpers/money"
import { displayPrecision } from "@/onboarding/features/product/helpers"
import { getProductCurrency } from "@/onboarding/features/product/selectors"

const RateDisplay = ({ amount }: { amount: number }): JSX.Element => {
  const currency = useSelector(getProductCurrency)

  return <>{formatMoney(displayPrecision(amount), currency)}</>
}

export default RateDisplay
