import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column gap-2">
    <Row>
      <Col md={8}>
        <h5>Edit Details</h5>
        <Row>
          <Col md={5}>
            <FormikBootstrap.TextInput name="name" label="Name" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="description" label="Description" />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <ReferencedValueSelect
              name="transportType"
              type={ReferencedValueTypes.VEHICLE_TYPE}
              label="Transport Type"
            />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="transportCompanyName" label="Transport Company" />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <FormikBootstrap.NumberInput name="availableVehicleCount" label="Available Vehicles" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.NumberInput
              name="maximumCapacityPerVehicle"
              label="Max Capacity per Vehicle"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={5}>
            <FormikBootstrap.Checkbox name="restroomsAvailable" label="Restrooms Available?" />
            <FormikBootstrap.Checkbox name="wheelchairAccessible" label="Wheelchair Accessible?" />
            <FormikBootstrap.Checkbox
              name="motorizedWheelchairAccessible"
              label="Motorized Wheelchair Accessible?"
              className="text-wrap"
            />
          </Col>
          <Col md={5}>
            <FormikBootstrap.Checkbox
              name="wheelchairLiftAvailable"
              label="Wheelchair Lift Available?"
            />
            <FormikBootstrap.Checkbox name="airConditioned" label="Air Conditioned?" />
          </Col>
        </Row>
      </Col>
      <Col md={4} className="ms-n6">
        <h5 className="mt-4">Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <h5>Details</h5>
    <Table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th colSpan={2}>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Transport Type">
            <TextDisplay value={component.transportType} />
          </td>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description" colSpan={2}>
            <TextDisplay value={component.description} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Transport Company</th>
          <th>Air Conditioned?</th>
          <th>Available Vehicles</th>
          <th>Average Max Capacity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Transport Company Name">
            <TextDisplay value={component.transportCompanyName} />
          </td>
          <td aria-label="Air Conditioned?">
            <TextDisplay value={component.airConditioned} />
          </td>
          <td aria-label="Available Vehicle Count">
            <TextDisplay value={component.availableVehicleCount} />
          </td>
          <td aria-label="Max Capacity per Vehicle">
            <TextDisplay value={component.maximumCapacityPerVehicle} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className="text-wrap">
          <th>Restrooms Available?</th>
          <th>Wheelchair Accessible?</th>
          <th>Motorized Wheelchair Accessible?</th>
          <th>Wheelchair Lift Available?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Restrooms Available?">
            <TextDisplay value={component.restroomsAvailable} />
          </td>
          <td aria-label="Wheelchair Accessible?">
            <TextDisplay value={component.wheelchairAccessible} />
          </td>
          <td aria-label="Motorized Wheelchair Accessible?">
            <TextDisplay value={component.motorizedWheelchairAccessible} />
          </td>
          <td aria-label="Wheelchair Lift Available?">
            <TextDisplay value={component.wheelchairLiftAvailable} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <Col md={3}>
        <h5 className="mt-2">Rates</h5>
        <Pricing.View value={component.ratesheet} />
      </Col>
    )}
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
