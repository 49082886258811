import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "react-bootstrap"
import NumberInput from "@/components/input/Number"
import { isBlank } from "@/utils"
import { onConstraintInputChange, onRemoveNewConstraint, onRemoveDateRange } from "../Slice"
import { DateSelector } from "./Helpers"
import { getNewCallDayConstraints } from "../selectors"

const NewConstraintsTableRow = () => {
  const dispatch = useDispatch()
  const newConstraints = useSelector(getNewCallDayConstraints)

  const onCancel = (constraintId) => {
    dispatch(onRemoveNewConstraint({ id: constraintId }))
  }

  if (isBlank(newConstraints)) {
    return <></>
  }

  return newConstraints.map((constraint) => (
    <tr key={constraint.allotmentConstraintId}>
      <td>
        {constraint.dateSelectors.map((dateRange, index) => (
          <React.Fragment key={index}>
            {constraint.dateSelectors.length != 1 && (
              <div
                className="d-inline cursor-pointer"
                onClick={() =>
                  dispatch(
                    onRemoveDateRange({ id: constraint.allotmentConstraintId, index, isNew: true })
                  )
                }
              >
                <i className="fas fa-window-close fa-lg d-inline ms-n4 me-2" />
              </div>
            )}
            <DateSelector
              constraint={constraint}
              index={index}
              dateRange={dateRange}
              dateType="start"
              isNewConstraint
            />
            <br />
          </React.Fragment>
        ))}
      </td>
      <td>
        {constraint.dateSelectors.map((dateRange, index) => (
          <React.Fragment key={`${dateRange.endDate}${index}`}>
            <DateSelector
              constraint={constraint}
              index={index}
              dateRange={dateRange}
              dateType="end"
              isNewConstraint
            />
            <br />
          </React.Fragment>
        ))}
      </td>
      <td>
        <NumberInput
          label=""
          name="capacityPerDepartureMin"
          placeholder={0}
          value={constraint.capacityPerDepartureMin}
          onChange={(e) =>
            dispatch(
              onConstraintInputChange({
                id: constraint.allotmentConstraintId,
                key: "capacityPerDepartureMin",
                input: e.target.value,
                isNew: true,
              })
            )
          }
        />
      </td>
      <td>
        <NumberInput
          label=""
          name="capacityPerDayMax"
          placeholder={0}
          value={constraint.capacityPerDayMax}
          onChange={(e) =>
            dispatch(
              onConstraintInputChange({
                id: constraint.allotmentConstraintId,
                key: "capacityPerDayMax",
                input: e.target.value,
                isNew: true,
              })
            )
          }
        />
      </td>
      <td>
        <NumberInput
          label=""
          name="departureStartTimeDeltaMinutes"
          placeholder={0}
          value={constraint.departureStartTimeDeltaMinutes}
          onChange={(e) =>
            dispatch(
              onConstraintInputChange({
                id: constraint.allotmentConstraintId,
                key: "departureStartTimeDeltaMinutes",
                input: e.target.value,
                isNew: true,
              })
            )
          }
        />
      </td>
      <td>
        <NumberInput
          label=""
          name="departureEndTimeDeltaMinutes"
          placeholder={0}
          value={constraint.departureEndTimeDeltaMinutes}
          onChange={(e) =>
            dispatch(
              onConstraintInputChange({
                id: constraint.allotmentConstraintId,
                key: "departureEndTimeDeltaMinutes",
                input: e.target.value,
                isNew: true,
              })
            )
          }
        />
      </td>
      <td>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => onCancel(constraint.allotmentConstraintId)}
            className="btn-primary me-2 "
          >
            Cancel
          </Button>
          {/* <Button
            onClick={() =>
              dispatch(onAddDateRange({ id: constraint.allotmentConstraintId, isNew: true }))
            }
            className="btn-primary"
          >
            Add Date Range
          </Button> */}
        </div>
      </td>
    </tr>
  ))
}

export default NewConstraintsTableRow
