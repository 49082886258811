import React from "react"
import { Modal, Table } from "react-bootstrap"
import _ from "lodash"

import { SettlementMethods } from "@/onboarding/features/product/models/Product"

const settlementMethodDescription = (settlementMethod: SettlementMethods) => {
  switch (settlementMethod) {
    case SettlementMethods.PER_GUEST:
      return "Fixed price per guest settlement"
    case SettlementMethods.PER_VEHICLE:
      return "Tiered settlement based on per-vehicle occupancy"
    case SettlementMethods.PER_VEHICLE_AVERAGE:
      return "Tiered settlement based on average vehicle occupancy"
    default:
      return "Unknown settlement type"
  }
}

const PriceBreakdownModal = ({
  show,
  onHide,
  priceBreakdown,
}: {
  show: boolean
  onHide: () => void
  priceBreakdown: object
}): JSX.Element => {
  const { assignments, netSettledPax, settlementMethod, price, vehicleBased } = priceBreakdown

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-modal-title" centered>
      <Modal.Header>
        <Modal.Title className="w-100" id="edit-modal-title">
          <div className="d-flex justify-content-between">
            <span>Settlement Assignments</span>
            <span className="cursor-pointer" onClick={onHide}>
              <i className="fas fa-times" />
            </span>
          </div>
          <p className="mb-0">{settlementMethodDescription(settlementMethod)}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th scope="col">{vehicleBased ? "Vehicle" : ""}</th>
              {vehicleBased && (
                <th scope="col" className="text-right">
                  Capacity
                </th>
              )}
              <th scope="col" className="text-right">
                Adults
              </th>
              <th scope="col" className="text-right">
                Children
              </th>
              <th scope="col" className="text-right">
                Under
              </th>
              <th scope="col" className="text-right">
                Guests
              </th>
              <th scope="col" className="text-right">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((assignment, index) => (
              <tr key={index}>
                <td>{assignment.name}</td>
                {vehicleBased && <td className="text-right">{assignment.capacity}</td>}
                <td className="text-right">{assignment.pax.adult}</td>
                <td className="text-right">{assignment.pax.child}</td>
                <td className="text-right">{assignment.pax.under}</td>
                <td className="text-right">
                  {assignment.pax.adult + assignment.pax.child + assignment.pax.under}
                </td>
                <td className="text-right">{assignment.price.formatted}</td>
              </tr>
            ))}
          </tbody>
          {assignments.length > 1 && (
            <tfoot>
              <tr>
                <td>Total</td>
                {vehicleBased && (
                  <td className="text-right">{_.sum(_.map(assignments, "capacity"))}</td>
                )}
                <td className="text-right">{netSettledPax.adult}</td>
                <td className="text-right">{netSettledPax.child}</td>
                <td className="text-right">{netSettledPax.under}</td>
                <td className="text-right">
                  {netSettledPax.adult + netSettledPax.child + netSettledPax.under}
                </td>
                <td className="text-right">{price.formatted}</td>
              </tr>
            </tfoot>
          )}
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default PriceBreakdownModal
