import React, { useContext } from "react"
import { Button } from "react-bootstrap"

import Tour from "@/dashboard/models/Tour"
import { actionOnEvent } from "@/dashboard/actions"
import { useModal } from "@/helpers/useModal"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import AuthDescriptorContext from "@/auth_descriptor/Context"
import Roles from "@/roles"

import TourToolsDropdown from "./TourToolsDropdown"
import DuplicateTourModal from "./modals/DuplicateTourModal"
import BulkCancelBookingsModal from "./modals/BulkCancelBookingsModal"
import CancelTourModal from "./modals/CancelTourModal"
import EditDepartureTimeModal from "./modals/EditDepartureTimeModal"
import StickerModal from "./modals/StickerModal"
import SendMessageModal, {
  TourOperatorRecipient,
  TourManagerRecipient,
  RoleRecipient,
} from "./modals/SendMessageModal"

const TourTools = ({ tour }: { tour: Tour }): JSX.Element => {
  const authDescriptor = useContext(AuthDescriptorContext)

  const toggleDuplicateTourModal = useModal((props) => (
    <DuplicateTourModal tour={tour} {...props} />
  ))

  const toggleBulkCancelBookingsModal = useModal((props) => (
    <BulkCancelBookingsModal tour={tour} {...props} />
  ))

  const toggleCancelTourModal = useModal((props) => <CancelTourModal tour={tour} {...props} />)

  const toggleEditDepartureTimeModal = useModal((props) => (
    <EditDepartureTimeModal tour={tour} {...props} />
  ))

  const tourMessageSubject = `Regarding ${tour.name} @ ${tour.timeslot} (${tour.code})`

  const toggleMessageHQ = useModal((props) => (
    <SendMessageModal
      title="Message HQ"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsHqAgent)}
      messageSubject={tourMessageSubject}
      messageBody=""
      {...props}
    />
  ))

  const toggleMessageBridge = useModal((props) => (
    <SendMessageModal
      title="Message Bridge"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsBridgeAgent)}
      messageSubject={tourMessageSubject}
      messageBody=""
      {...props}
    />
  ))

  // TODO Select multiple operators
  const toggleMessageTourOperator = useModal((props) => (
    <SendMessageModal
      title={`Message ${tour.operators.join(", ")}`}
      companyId={authDescriptor.companyId}
      recipient={new TourOperatorRecipient(tour)}
      messageSubject={tourMessageSubject}
      messageBody=""
      {...props}
    />
  ))

  const toggleMessageShip = useModal((props) => (
    <SendMessageModal
      title={`Message ${tour.shipName} (${tour.manager})`}
      companyId={authDescriptor.companyId}
      recipient={new TourManagerRecipient(tour)}
      messageSubject={tourMessageSubject}
      messageBody=""
      {...props}
    />
  ))

  const toggleMessageEventCoordinator = useModal((props) => (
    <SendMessageModal
      title="Message Event Coordinator"
      companyId={authDescriptor.companyId}
      recipient={new RoleRecipient(Roles.ShoreExcursionsEventCoordinator)}
      messageSubject={tourMessageSubject}
      messageBody=""
      {...props}
    />
  ))

  const toggleStickerModal = useModal((props) => <StickerModal tour={tour} {...props} />)

  return (
    <div className="d-flex gap-2 justify-content-end">
      <CruiseLineOnly>
        <Button
          variant="primary"
          size="sm"
          onClick={actionOnEvent.redirectToManualBookingForm(tour.tourId)}
          className="ms-n4"
        >
          Book
        </Button>
      </CruiseLineOnly>
      <TourToolsDropdown
        tour={tour}
        onDuplicateTour={toggleDuplicateTourModal}
        onCancelAllBookings={toggleBulkCancelBookingsModal}
        onCancelTour={toggleCancelTourModal}
        onEditDepartureTime={toggleEditDepartureTimeModal}
        onMessageHQ={toggleMessageHQ}
        onMessageTourOperator={toggleMessageTourOperator}
        onMessageBridge={toggleMessageBridge}
        onMessageShip={toggleMessageShip}
        onMessageEventCoordinator={toggleMessageEventCoordinator}
        onEditStickers={toggleStickerModal}
      />
    </div>
  )
}

export default TourTools
