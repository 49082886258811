import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getField } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"

const FieldRow = ({ fieldName }): JSX.Element => {
  const { value } = useSelector(getField(fieldName))

  const displayValue = _.isArray(value) ? (
    <div className="d-flex flex-column gap-1">
      {_.map(value, (val, index) => (
        <div key={index}>
          <TextDisplay value={val} />
        </div>
      ))}
    </div>
  ) : (
    <TextDisplay value={value} />
  )

  return (
    <tr>
      <td className="align-top col-1">
        <b>{_.startCase(fieldName)}</b>
      </td>
      <td className="align-top text-wrap">{displayValue}</td>
    </tr>
  )
}

const FieldTable = ({ fieldNames, noFieldsMessage = "No content loaded" }): JSX.Element => {
  if (fieldNames.length === 0) {
    return <p className="mb-0">{noFieldsMessage}</p>
  }

  return (
    <Table borderless size="sm">
      <tbody>
        {fieldNames.map((fieldName) => (
          <FieldRow key={fieldName} fieldName={fieldName} />
        ))}
      </tbody>
    </Table>
  )
}

export default FieldTable
