import Passenger from "./Passenger"

export enum ReservationStates {
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
  REJECTED = "rejected",
  WAITLISTED = "waitlisted",
  PENDING_CONFIRMATION = "pending_confirmation",
  PENDING_CANCELLATION = "pending_cancellation",
}

export default interface PrivateCarBooking {
  bookingId: string
  rentalOperatorName: string
  reservationState: ReservationStates
  reservationStateDescription: string
  reservationPending: boolean
  chargedPassenger: Passenger
  driver: Passenger
  passenger_count: number
  netPrice: string
  retailPrice: string
  currency: string
  pickupTime: string
  dropoffTime: string
  token: string
}
