import React, { useState } from "react"
import { Tab, Row, Col, ListGroup } from "react-bootstrap"
import _ from "lodash"

import Passenger from "@/search_results/models/Passenger"
import Room from "@/search_results/models/Room"
import TourBooking from "@/search_results/models/TourBooking"

import PassengerListGroup from "./results/PassengerListGroup"
import TourBookingListGroup from "./results/TourBookingListGroup"
import RoomListGroup from "./results/RoomListGroup"

const ResultNavItem = ({
  name,
  eventKey,
  active,
  count,
}: {
  name: string
  eventKey: string
  active: boolean
  count: number
}): JSX.Element => {
  if (count === 0) {
    return <ListGroup.Item disabled>{name}</ListGroup.Item>
  }

  let badge

  if (active) {
    badge = <span className="badge bg-secondary">{count}</span>
  } else {
    badge = <span className="badge bg-dark">{count}</span>
  }

  return (
    <ListGroup.Item
      action
      href={eventKey}
      className="d-flex justify-content-between align-items-center"
    >
      {name} {badge}
    </ListGroup.Item>
  )
}

interface SearchResults {
  passengers: Array<Passenger>
  tourBookings: Array<TourBooking>
  rooms: Array<Room>
}

const SearchResultsLayout = ({ results }: { results: SearchResults }): JSX.Element => {
  const categories = [
    {
      name: "Passengers",
      eventKey: "#passengers",
      count: results.passengers.length,
      content: <PassengerListGroup passengers={results.passengers} />,
    },
    {
      name: "Rooms",
      eventKey: "#rooms",
      count: results.rooms.length,
      content: <RoomListGroup rooms={results.rooms} />,
    },
    {
      name: "Tour Bookings",
      eventKey: "#tour-bookings",
      count: results.tourBookings.length,
      content: <TourBookingListGroup tourBookings={results.tourBookings} />,
    },
    {
      name: "Functions",
      eventKey: "#functions",
      count: 0,
      content: <></>,
    },
  ]

  const defaultCategory = _.find(categories, (c) => c.count > 0)

  if (!defaultCategory) {
    return <></>
  }

  const [activeKey, setActiveKey] = useState(defaultCategory.eventKey)

  return (
    <Tab.Container
      id="left-tabs-search-results"
      defaultActiveKey={activeKey}
      onSelect={(eventKey) => setActiveKey(eventKey)}
    >
      <Row>
        <Col sm={3}>
          <ListGroup>
            {categories.map(({ name, eventKey, count }) => (
              <ResultNavItem
                key={eventKey}
                name={name}
                eventKey={eventKey}
                active={activeKey === eventKey}
                count={count}
              />
            ))}
          </ListGroup>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {categories.map(({ eventKey, content }) => (
              <Tab.Pane key={eventKey} eventKey={eventKey}>
                {content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default SearchResultsLayout
