import React from "react"
import _ from "lodash"

import { getSubmitableBookingForms } from "./selectors"
import TransportRentalBookingForm from "./TransportRentalBookingForm"
import ActionBarButtons from "./ActionBarButtons"
import { addCoupon } from "./Slice"

export default {
  name: "transportRentalBookings",
  getBookings: (state) =>
    _.map(state.transportRentalBookings.bookingForms, (b) => ({
      formId: b.formId,
      description: b._description,
      price: b._price,
      createdAtMillis: b.createdAtMillis,
      kind: b.kind,
      hasGuests: b.driverId !== undefined,
      couponCodes: b.couponCodes,
    })),
  getBookingsForSubmission: (state) => getSubmitableBookingForms(state.transportRentalBookings),
  canRenderBooking: (booking) => booking && booking.kind == "TransportRentalBooking",
  renderBooking: (booking) => <TransportRentalBookingForm formId={booking.formId} />,
  getActionBarButtons: () => <ActionBarButtons />,
  addCoupon,
}
