import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { withApp } from "@/components/App"
import LoadingSpinner from "@/components/LoadingSpinner"
import { getTourDescription, getPassengerID } from "@/guests_tour_description/selectors"
import Slice, { initialize } from "@/guests_tour_description/Slice"
import Navbar from "@/guests_footer/Navbar"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()

  const { initialized } = useSelector(getTourDescription)
  const passengerID = useSelector(getPassengerID)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <>
      <h2 className="mb-n1">Tour Description</h2>
      <Navbar page="Booking" guestId={passengerID} />
    </>
  )
}

export default withApp(Initializer, { slices: [Slice] })
