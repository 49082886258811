import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "react-bootstrap"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { isBlank } from "@/utils"
import { useModal } from "@/helpers/useModal"
import NumberInput from "@/components/input/Number"
import {
  onConstraintInputChange,
  updateConstraintsEditStates,
  onCancelConstraint,
  onRemoveDateRange,
  updateAllotmentConstraints,
} from "../Slice"
import { DateSelector } from "./Helpers"
import { getConstraintsEditStates, getView } from "../selectors"
import ConfirmationModal from "./ConfirmationModal"

const ConstraintsTableRow = () => {
  const view = useSelector(getView)
  const constraintsEditStates = useSelector(getConstraintsEditStates)

  const [edit, setEdit] = useState(constraintsEditStates)
  const [confirmationPrompt, setConfirmationPrompt] = useState("")
  const [confirmationHeader, setConfirmationHeader] = useState("")
  const [constraintId, setConstraintId] = useState("")

  useEffect(() => {
    setEdit(constraintsEditStates)
  }, [constraintsEditStates, view])

  const onClick = (constraintId, action = "edit") => {
    const newState = { ...edit, [constraintId]: !edit[constraintId] }
    setEdit(newState)
    dispatch(updateConstraintsEditStates({ editState: newState }))
    if (action === "cancel") {
      dispatch(onCancelConstraint())
    }
  }

  const onDeleteCallback = () => {
    dispatch(updateAllotmentConstraints([{ allotmentConstraintId: constraintId, remove: true }]))
  }

  const toggleConfirmationModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onDeleteCallback}
      buttonText="Delete"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      constraintId={constraintId}
      {...props}
    />
  ))

  const onDelete = (constraintId) => {
    setConfirmationPrompt("Are you sure you want to delete this constraint?")
    setConfirmationHeader("Confirm deletion")
    setConstraintId(constraintId)
    toggleConfirmationModal()
  }

  const dispatch = useDispatch()

  if (isBlank(view.inventory.callDayConstraints)) {
    return <></>
  }

  return view.inventory.callDayConstraints.map((constraint) => (
    <tr key={constraint.allotmentConstraintId}>
      <td>
        {edit[constraint.allotmentConstraintId]
          ? constraint.dateSelectors.map((dateRange, index) => (
              <React.Fragment key={index}>
                {constraint.dateSelectors.length != 1 && (
                  <div
                    className="d-inline cursor-pointer"
                    onClick={() =>
                      dispatch(
                        onRemoveDateRange({
                          id: constraint.allotmentConstraintId,
                          index,
                          isNew: false,
                        })
                      )
                    }
                  >
                    <i className="fas fa-window-close fa-lg d-inline ms-n4 me-2" />
                  </div>
                )}
                <DateSelector
                  constraint={constraint}
                  index={index}
                  dateRange={dateRange}
                  dateType="start"
                />
                <br />
              </React.Fragment>
            ))
          : constraint.dateSelectors.map((dateRange, index) => (
              <React.Fragment key={index}>
                {constraint.isEditable ? (
                  dateRange.startDate
                ) : (
                  <span className="text-muted">Default values</span>
                )}
                <br />
              </React.Fragment>
            ))}
      </td>
      <td>
        {edit[constraint.allotmentConstraintId]
          ? constraint.dateSelectors.map((dateRange, index) => (
              <React.Fragment key={`${dateRange.endDate}${index}`}>
                <DateSelector
                  constraint={constraint}
                  index={index}
                  dateRange={dateRange}
                  dateType="end"
                />
                <br />
              </React.Fragment>
            ))
          : constraint.dateSelectors.map((dateRange, index) => (
              <React.Fragment key={index}>
                {constraint.isEditable ? (
                  dateRange.endDate
                ) : (
                  <span className="text-muted">Default values</span>
                )}
                <br />
              </React.Fragment>
            ))}
      </td>
      <td>
        {edit[constraint.allotmentConstraintId] ? (
          <NumberInput
            label=""
            name="capacityPerDepartureMin"
            placeholder={0}
            value={constraint.capacityPerDepartureMin}
            onChange={(e) =>
              dispatch(
                onConstraintInputChange({
                  id: constraint.allotmentConstraintId,
                  key: "capacityPerDepartureMin",
                  input: e.target.value,
                  isNew: false,
                })
              )
            }
          />
        ) : (
          constraint.capacityPerDepartureMin
        )}
      </td>
      <td>
        {edit[constraint.allotmentConstraintId] ? (
          <NumberInput
            label=""
            name="capacityPerDayMax"
            placeholder={0}
            value={constraint.capacityPerDayMax}
            onChange={(e) =>
              dispatch(
                onConstraintInputChange({
                  id: constraint.allotmentConstraintId,
                  key: "capacityPerDayMax",
                  input: e.target.value,
                  isNew: false,
                })
              )
            }
          />
        ) : (
          constraint.capacityPerDayMax
        )}
      </td>
      <td>
        {edit[constraint.allotmentConstraintId] ? (
          <NumberInput
            label=""
            name="departureStartTimeDeltaMinutes"
            placeholder={0}
            value={constraint.departureStartTimeDeltaMinutes}
            onChange={(e) =>
              dispatch(
                onConstraintInputChange({
                  id: constraint.allotmentConstraintId,
                  key: "departureStartTimeDeltaMinutes",
                  input: e.target.value,
                  isNew: false,
                })
              )
            }
            suffix="minutes"
          />
        ) : (
          constraint.departureStartTimeDeltaMinutes
        )}
      </td>
      <td>
        {edit[constraint.allotmentConstraintId] ? (
          <NumberInput
            label=""
            name="departureEndTimeDeltaMinutes"
            placeholder={0}
            value={constraint.departureEndTimeDeltaMinutes}
            onChange={(e) =>
              dispatch(
                onConstraintInputChange({
                  id: constraint.allotmentConstraintId,
                  key: "departureEndTimeDeltaMinutes",
                  input: e.target.value,
                  isNew: false,
                })
              )
            }
            suffix="minutes"
          />
        ) : (
          constraint.departureEndTimeDeltaMinutes
        )}
      </td>
      <CruiseLineOnly>
        <td>
          <div>
            {!edit[constraint.allotmentConstraintId] && constraint.isEditable && (
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => onClick(constraint.allotmentConstraintId)}
                  className="btn-primary me-2 "
                >
                  Edit
                </Button>
                <Button
                  onClick={() => onDelete(constraint.allotmentConstraintId)}
                  className="btn-primary"
                >
                  Delete
                </Button>
              </div>
            )}
            {edit[constraint.allotmentConstraintId] && (
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => onClick(constraint.allotmentConstraintId, "cancel")}
                  className="btn-primary me-2"
                >
                  Cancel
                </Button>
                {/* <Button
                  onClick={() =>
                    dispatch(
                      onAddDateRange({
                        id: constraint.allotmentConstraintId,
                        isNew: false,
                      })
                    )
                  }
                  className="btn-primary"
                >
                  Add Date Range
                </Button> */}
              </div>
            )}
          </div>
        </td>
      </CruiseLineOnly>
    </tr>
  ))
}

export default ConstraintsTableRow
