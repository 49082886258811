import React from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import InsuranceMetrics from "./InsuranceMetrics"
import { getInsurance } from "../selectors"

const OverviewModal = ({ show, onHide }: { show: boolean; onHide: () => void }): JSX.Element => {
  const { _view } = useSelector(getInsurance)
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-modal-title" centered>
      <Modal.Header>
        <Modal.Title className="w-100" id="edit-modal-title">
          <div className="d-flex justify-content-between">
            <span>Insurance Metrics Filtered By Selection</span>
            <span className="cursor-pointer" onClick={onHide}>
              <i className="fas fa-times" />
            </span>
          </div>
          <span className="h6">
            {`Total number of tour operators: ${_view.value.insuranceOverview.operatorTotal}`}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InsuranceMetrics />
      </Modal.Body>
    </Modal>
  )
}

export default OverviewModal
