import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import {
  getProspectiveAwardSets,
  getPriceAnalysis,
} from "@/onboarding/features/price_analysis/selectors"
import { selectAwardSet } from "@/onboarding/features/price_analysis/Slice"

const AwardSetDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const options = useSelector(getProspectiveAwardSets)
  const { selectedAwardSetId } = useSelector(getPriceAnalysis)

  const awardSetDisplayName = (awardSet) =>
    awardSet.finalized ? `${awardSet.name} (Awarded)` : awardSet.name

  return (
    <Form.Group>
      <Form.Label>Bid Scenarios</Form.Label>
      <Form.Control
        as="select"
        value={selectedAwardSetId}
        onChange={(e) => dispatch(selectAwardSet({ selectedAwardSetId: e.target.value }))}
      >
        <option value="">Select a bid scenario</option>
        {options.map((awardSet) => (
          <option key={awardSet.id} value={awardSet.id}>
            {awardSetDisplayName(awardSet)}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default AwardSetDropdown
