export const getFeedback = ({ guestFeedback }) => guestFeedback

export const getPassengerID = ({ guestFeedback }) => guestFeedback.passenger

export const getRating = ({ guestFeedback }) => guestFeedback.rating

export const getToursPerPort = ({ guestFeedback }) => guestFeedback.toursPerPort

export const getTours = ({ guestFeedback }) => guestFeedback.tours

export const isInitialized = ({ guestFeedback }) => guestFeedback.initialized
