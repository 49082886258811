/* THIS FILE WAS GENERATED *DO NOT EDIT* */
enum Roles {
  ShoreExcursionsBridgeAgent = "ShoreExcursions::Roles::BridgeAgent",
  ShoreExcursionsEventCoordinator = "ShoreExcursions::Roles::EventCoordinator",
  ShoreExcursionsHqAgent = "ShoreExcursions::Roles::HqAgent",
  ShoreExcursionsPurserAgent = "ShoreExcursions::Roles::PurserAgent",
  ShoreExcursionsShxAgent = "ShoreExcursions::Roles::ShxAgent",
  ShoreExcursionsShxManager = "ShoreExcursions::Roles::ShxManager",
  ShoreExcursionsTourAgent = "ShoreExcursions::Roles::TourAgent",
  ShoreExcursionsTourManager = "ShoreExcursions::Roles::TourManager",
  ShoretimeSuperAdminCompanySwitcher = "Shoretime::Roles::SuperAdmin::CompanySwitcher",
  TourOnboardingAllocation = "TourOnboarding::Roles::Allocation",
  TourOnboardingApplication = "TourOnboarding::Roles::Application",
  TourOnboardingBidding = "TourOnboarding::Roles::Bidding",
  TourOnboardingCallCenter = "TourOnboarding::Roles::CallCenter",
  TourOnboardingContracting = "TourOnboarding::Roles::Contracting",
  TourOnboardingContractingApproval = "TourOnboarding::Roles::ContractingApproval",
  TourOnboardingDestinationServices = "TourOnboarding::Roles::DestinationServices",
  TourOnboardingInsurance = "TourOnboarding::Roles::Insurance",
  TourOnboardingShipboard = "TourOnboarding::Roles::Shipboard",
  TourOnboardingSystemAdministrator = "TourOnboarding::Roles::SystemAdministrator",
  TourOnboardingUserAdmin = "TourOnboarding::Roles::UserAdmin",
  TourOnboardingUserManagement = "TourOnboarding::Roles::UserManagement",
}

export default Roles;

