import React from "react"

const CommentIcon = ({ showUnread, onComment }): JSX.Element => (
  <span className="cursor-pointer" onClick={onComment}>
    <i className="fas fa-comment" />
    {showUnread && <i className="notification-bell unread" />}
  </span>
)

export default CommentIcon
