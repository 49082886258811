import React from "react"

import PriceDisplay from "./PriceDisplay"

const ComponentRequestedValue = ({ component }): JSX.Element => {
  const { indicativeExpectedTotal, indicativeBreakdown } = component

  return (
    <PriceDisplay
      expectedTotal={indicativeExpectedTotal}
      priceBreakdown={indicativeBreakdown}
      originalValue
    />
  )
}

export default ComponentRequestedValue
