import React, { FC } from "react"
import { Provider } from "react-redux"
import { configureStore, Slice } from "@reduxjs/toolkit"
import _ from "lodash"

import AuthDescriptorProvider from "@/auth_descriptor/Provider"
import { FlipflopProvider } from "@/helpers/Flipflop"
import { ModalHolder } from "@/helpers/useModal"
import OSN, { ConnectedOnScreenNotifications } from "@/osn"

import { withErrorBoundary } from "./ErrorBoundary"

const withReduxProvider =
  (store) =>
  (Component) =>
  (props): JSX.Element => (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )

const BaseApp: FC = ({ children }) => (
  <FlipflopProvider>
    <ConnectedOnScreenNotifications>
      <ModalHolder>{children}</ModalHolder>
    </ConnectedOnScreenNotifications>
  </FlipflopProvider>
)

const withBaseApp =
  (Component, extraProps = {}) =>
  ({ authDescriptor, ...props }): JSX.Element => (
    <AuthDescriptorProvider value={authDescriptor}>
      <BaseApp>
        <Component {...extraProps} {...props} />
      </BaseApp>
    </AuthDescriptorProvider>
  )

const prepareReducers = (slices: Array<Slice>) =>
  _.reduce(slices, (reducers, slice) => _.merge(reducers, { [slice.name]: slice.reducer }), {})

/*
 * +config keys+
 *    slices
 *      all slices applicable to this
 *    store
 *    extraProps
 *
 */
export const withApp = (component, config = {}) => {
  let store = createStore({ slices: config.slices || [] })

  if (config.store) {
    store = config.store
  }

  let app = withBaseApp(component)

  app = withReduxProvider(store)(app)

  return withErrorBoundary(app)
}

export const createStore = (config) =>
  configureStore({ reducer: prepareReducers(_.concat(config.slices || [], OSN.slice)) })
