import React from "react"
import { useSelector } from "react-redux"
import { getName, getCruise, getVoyage } from "@/guests/selectors"
import { Card, Row, Col } from "react-bootstrap"
import { formatDate } from "../helpers/Format"

const MyDate = formatDate(new Date())

const Header = (): JSX.Element => {
  const name = useSelector(getName)
  const cruise = useSelector(getCruise)
  const voyage = useSelector(getVoyage)

  return (
    <div>
      <h5 className="text-end">{`Welcome ${name}`}</h5>
      <h6> {MyDate} </h6>
      <Card>
        <Row className="ms-2 mt-2">
          <Col>
            <h6>{`${voyage.shipName}, ${cruise.name}`}</h6>
            <h6>{voyage.tripDescription}</h6>
          </Col>
        </Row>
      </Card>

      <p className="mt-4 mb-3">
        <i className="fas fa-bell me-2" />
        Assemble at the Muster Station on Deck 1 at 12:00
      </p>
    </div>
  )
}

export default Header
