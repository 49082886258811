import React from "react"
import { Card, Table } from "react-bootstrap"
import _ from "lodash"
import { getStatus } from "@/insurance/features/helpers"
import { useSelector } from "react-redux"
import StatusIcon from "../../StatusIcon"
import { getProfile } from "../selectors"

const InsuranceRequirements = ({ props }) => {
  const { statusLabels } = useSelector(getProfile)
  return (
    <>
      <h5>Required Insurance</h5>
      <Card>
        <Table className="table">
          <thead className="thead-light">
            <tr>
              {_.map(props.profile.insuranceCategories, (insuranceCategory) => (
                <th key={insuranceCategory.categoryName} scope="col">
                  {insuranceCategory.categoryName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {_.map(props.profile.insuranceCategories, (insuranceCategory) => (
                <td key={insuranceCategory.categoryName}>
                  <StatusIcon
                    status={getStatus(insuranceCategory.insuranceStatus)}
                    label={_.startCase(statusLabels[getStatus(insuranceCategory.insuranceStatus)])}
                  />
                  <span className="ms-2">
                    {(function () {
                      const requiredStatus = Object.keys(insuranceCategory.insuranceStatus).find(
                        (key) => insuranceCategory.insuranceStatus[key] === true
                      )
                      return requiredStatus === "unrequired"
                        ? "Not Required"
                        : _.startCase(statusLabels[requiredStatus])
                    })()}
                  </span>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  )
}

export default InsuranceRequirements
