import React from "react"

import ReactList from "react-list"
import VehicleResult from "./VehicleResult"

const VehicleResultList = ({ results, onAction, actionText }) => {
  const renderItem = (index, key) => {
    const r = results[index]
    const actionButton = (
      <button className="btn btn-outline-secondary btn-sm" onClick={(e) => onAction(r)}>
        {actionText}
      </button>
    )

    return <VehicleResult key={key} result={r} actionButton={actionButton} />
  }

  return (
    <div
      className="shadow-sm"
      style={{
        overflowY: "scroll",
        maxHeight: 400,
        paddingRight: 15,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#c8cacd",
      }}
    >
      <ReactList itemRenderer={renderItem} length={results.length} />
    </div>
  )

  // return _.map(results, (r) => <VehicleResult key={r.id} result={r} />)
}

export default VehicleResultList
