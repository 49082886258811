import { useSelector } from "react-redux"

import { getMode } from "@/document_editor/selectors"
import EditorModes from "@/document_editor/models/EditorModes"

const ReadOnly = (props) => {
  const mode = useSelector(getMode)

  if (mode !== EditorModes.READ) {
    return null
  }

  return props.children
}

export default ReadOnly
