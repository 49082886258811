import { isBlank } from "@/utils"
import { calculateTotalMaximumCapacity } from "./Helpers"

const areArraysEqual = (operatorDepartures, cruiseLineDepartures) => {
  if (operatorDepartures.length !== cruiseLineDepartures.length) {
    return false
  }

  const isEqual = (val1, val2) => String(val1) === String(val2)

  for (const operatorDeparture of operatorDepartures) {
    let foundMatch = false

    for (const cruiseLineDeparture of cruiseLineDepartures) {
      if (
        isEqual(operatorDeparture.departureTime, cruiseLineDeparture.departureTime) &&
        isEqual(operatorDeparture.durationInMinutes, cruiseLineDeparture.durationInMinutes) &&
        isEqual(operatorDeparture.minimumCapacity, cruiseLineDeparture.minimumCapacity) &&
        isEqual(operatorDeparture.maximumCapacity, cruiseLineDeparture.maximumCapacity)
      ) {
        foundMatch = true
        break
      }
    }

    if (!foundMatch) {
      return false
    }
  }

  return true
}

const hasDayIssue = (departures, constraints, issue) => {
  switch (issue) {
    case "maximum":
      return (
        calculateTotalMaximumCapacity(departures) <
        constraints.allotmentConstraint.capacityPerDayMax
      )
    case "departures":
      if (isBlank(constraints.departures)) {
        return false
      }
      return !areArraysEqual(departures, constraints.departures)

    default:
      return false
  }
}

const hasDepartureIssue = (departure, constraints, ship, issue) => {
  const callDate = new Date(departure.departureDate)

  switch (issue) {
    case "startTime":
      if (!ship.arrivalTime) {
        return false
      }

      const [shipArrivalHours, shipArrivalMinutes] = ship.arrivalTime.split(":").map(Number)
      const [tourDepartureHours, tourDepartureMinutes] = departure.departureTime
        .split(":")
        .map(Number)

      const shipArrivalDate = new Date(
        callDate.getFullYear(),
        callDate.getMonth(),
        callDate.getDate(),
        shipArrivalHours,
        shipArrivalMinutes
      )
      const tourDepartureDate = new Date(
        callDate.getFullYear(),
        callDate.getMonth(),
        callDate.getDate(),
        tourDepartureHours,
        tourDepartureMinutes
      )

      const timeDifferenceOnDeparture =
        (tourDepartureDate.getTime() - shipArrivalDate.getTime()) / (1000 * 60)
      return (
        timeDifferenceOnDeparture < constraints.allotmentConstraint.departureStartTimeDeltaMinutes
      )

    case "endTime":
      // TODO handle overnight tours, since then we have to check if end time the next day is in time for the ships departure the next day...
      if (!ship.departureTime) {
        return false
      }
      const [shipDepartureHours, shipDepartureMinutes] = ship.departureTime.split(":").map(Number)
      const [tourEndTimeHours, tourEndTimeMinutes] = departure.departureTime.split(":").map(Number)

      const shipDepartureDate = new Date(
        callDate.getFullYear(),
        callDate.getMonth(),
        callDate.getDate(),
        shipDepartureHours,
        shipDepartureMinutes
      )
      const tourArrivalDate = new Date(
        callDate.getFullYear(),
        callDate.getMonth(),
        callDate.getDate(),
        tourEndTimeHours,
        tourEndTimeMinutes
      )

      const timeDifferenceOnArrival =
        (shipDepartureDate.getTime() - tourArrivalDate.getTime()) / (1000 * 60) -
        departure.durationInMinutes
      return timeDifferenceOnArrival < constraints.allotmentConstraint.departureEndTimeDeltaMinutes

    case "minimum":
      return departure.minimumCapacity > constraints.allotmentConstraint.capacityPerDepartureMin

    default:
      return false
  }
}

export const IssueCalculator = (call) => {
  const { departures } = call.inventory.currentAllocatingTour
  const { constraints } = call.inventory.currentAllocatingTour
  const { ship } = call.inventory

  const departureIssueDescriptions = [
    { description: "Tour departs too close to the ship's arrival time", issue: "startTime" },
    { description: "Tour ends too close to the ship's departure time", issue: "endTime" },
    { description: "Departure minimum is too high", issue: "minimum" },
  ]

  const dayIssueDescriptions = [
    { description: "Total maximum over departures for the day is too low", issue: "maximum" },
    {
      description: "Submitted departures are not the same as the departures requested",
      issue: "departures",
    },
  ]
  const issues = []

  departureIssueDescriptions.forEach(({ description, issue }) => {
    const count = departures.reduce((total, departure) => {
      const hasIssue = hasDepartureIssue(departure, constraints, ship, issue)
      return total + (hasIssue ? 1 : 0)
    }, 0)

    if (count > 0) {
      issues.push({ description, count })
    }
  })

  dayIssueDescriptions.forEach(({ description, issue }) => {
    const hasIssue = hasDayIssue(departures, constraints, issue)
    if (hasIssue) {
      issues.push({ description, count: 1 })
    }
  })

  return issues
}
