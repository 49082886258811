import React from "react"
import { useSelector } from "react-redux"

import { getProductOperatorTotal } from "@/onboarding/features/price_analysis/selectors"

import PriceDisplay from "./PriceDisplay"

const BidTotalValue = ({ productId, operatorId }): JSX.Element => {
  const { expectedTotal, expectedTotalVariance, priceBreakdown, priceBreakdownVariance } =
    useSelector(getProductOperatorTotal(productId)(operatorId))

  return (
    <PriceDisplay
      expectedTotal={expectedTotal}
      expectedTotalVariance={expectedTotalVariance}
      priceBreakdown={priceBreakdown}
      priceBreakdownVariance={priceBreakdownVariance}
    />
  )
}

export default BidTotalValue
