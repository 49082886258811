import React, { useState, useEffect } from "react"
import { Row, Col, Button, Spinner } from "react-bootstrap"

import Api from "@/api"
import { DailySaleState } from "@/dashboard/models/DailySale"

const SalesToggle = ({ date, initialValue, headerActions }): JSX.Element => {
  const [stateWanted, setStateWanted] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)

  const [isSalesOpen, setIsSalesOpen] = useState(true)
  const [salesMessage, setSalesMessage] = useState("")

  const setStateFromDailySale = (sale) => {
    setIsSalesOpen(sale.state === DailySaleState.Open)
    setSalesMessage(sale.message)
  }

  const fetchCurrentState = async () => {
    const response = await Api.dailySales.fetch({ date })
    setStateFromDailySale(response.data.data.dailySale)
  }

  useEffect(() => {
    if (!isLoaded) {
      ;(async () => {
        try {
          if (initialValue) {
            setStateFromDailySale(initialValue)
          } else {
            await fetchCurrentState()
          }
        } finally {
          // TODO error handling
          setIsLoaded(true)
        }
      })()
    }

    if (stateWanted == null) return

    const fetchData = async () => {
      switch (stateWanted) {
        case DailySaleState.Open: {
          await Api.dailySales.open({ date })
          await fetchCurrentState()
          break
        }
        case DailySaleState.Closed: {
          await Api.dailySales.close({ date })
          await fetchCurrentState()
          break
        }
      }
    }

    fetchData().finally(() => {
      setStateWanted(null)
    })
  }, [stateWanted, isLoaded])

  const closeSales = () => {
    setStateWanted(DailySaleState.Closed)
  }

  const openSales = () => {
    setStateWanted(DailySaleState.Open)
  }

  if (!isLoaded) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  if (isSalesOpen) {
    return (
      <div>
        <Row>
          <Col className="d-flex justify-content-end gap-1">
            <Button variant="secondary" onClick={closeSales}>
              {" Close Sales "}
            </Button>
            {headerActions}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="invisible">{salesMessage}</span>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col className="d-flex justify-content-end gap-1">
          <Button onClick={openSales}> Open Sales </Button>
          {headerActions}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <span>{salesMessage}</span>
        </Col>
      </Row>
    </div>
  )
}

export default SalesToggle
