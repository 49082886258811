import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { getSelectedProperties } from "../selectors"
import { updateSelectedProperties } from "../Slice"

const DepartuePropertyCheckBox = ({ propertyName }): JSX.Element => {
  const selectedProperties = useSelector(getSelectedProperties)
  const dispatch = useDispatch()

  const checked = selectedProperties[propertyName]

  const onChange = () => {
    const updatedselectedProperties = _.cloneDeep(selectedProperties)
    updatedselectedProperties[propertyName] = !updatedselectedProperties[propertyName]

    // Handle mutual exclusivity between startTimeShiftMinutes and departureTime
    if (propertyName === "startTimeShiftMinutes" && updatedselectedProperties[propertyName]) {
      updatedselectedProperties.departureTime = false
    } else if (propertyName === "departureTime" && updatedselectedProperties[propertyName]) {
      updatedselectedProperties.startTimeShiftMinutes = false
    }

    dispatch(updateSelectedProperties({ selectedProperties: updatedselectedProperties }))
  }

  return (
    <Form.Check
      type="checkbox"
      checked={checked}
      name="name"
      placeholder={propertyName}
      onChange={() => onChange()}
    />
  )
}

export default DepartuePropertyCheckBox
