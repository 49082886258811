import React from "react"

import SimpleSearchInput from "@/components/SimpleSearchInput"

import { RoomResultItem } from "./RoomSearchResult"

const RoomResult = ({ result, ...props }) => (
  <>
    <RoomResultItem {...props} room={result} />
  </>
)

const RoomSearch = ({ results, isLoading, onSearch, onSelect, ...props }) => (
  <SimpleSearchInput
    id="guestSearch"
    results={isLoading ? [] : results}
    isLoading={isLoading}
    onSearch={onSearch}
    onSelect={onSelect}
    placeholder="Search by room"
    resultComponent={RoomResult}
    {...props}
  />
)

export default RoomSearch
