import React from "react"

import { export_dashboard_path } from "@/routes"

const ExcelExportLink = ({ date }: { date: string }): JSX.Element => (
  <a
    className="float-end hover-underline"
    href={export_dashboard_path({ date, format: "csv", _options: true })}
  >
    Export to Excel
  </a>
)

export default ExcelExportLink
