import _ from "lodash"

import { requireKeys } from "@/utils"

import { isTourBooking } from "@/manual_booking/productSelectors"
import * as BookingSelectors from "./bookingSelectors"

const getBookingForms = (state) => state.bookingForms

const getBookingFormById = (state, formId) => state.bookingForms[formId]

const getBookingFormByAction = (state, action) => {
  requireKeys(action.payload, "formId")
  return getBookingFormById(state, action.payload.formId)
}

/**
 * Returns a list of booking forms with all private fields omitted.
 */
const getSubmitableBookingForms = (state) =>
  _.map(state.bookingForms, BookingSelectors.getSubmitableBookingForm)

/**
 * Returns tour booking forms ordered by the time that they were added.
 */
const getOrderedBookingForms = (state) => _.sortBy(getBookingForms(state), "_createdAtMillis")

interface PassengerListPassenger {
  id: string
  checked: boolean
  hasProfile: boolean
  // These are defined hwne hasProfile is true
  name: string
  paxType: string
  roomId: string
  roomNumber: string
  profilePicturePath: string
}

/**
 * Returns a list of selected guests with their profiles merged in if available.
 */
const getPassengerList = (state, formId): Array<PassengerListPassenger> => {
  const form = state.bookingForms[formId]

  const checkedPassengerIdsSet = new Set(form.passengerIds)

  return _.map(form._passengerOptions, (o) => {
    // TODO(mbf) use smarter selector
    const profile = state.passengers[o.id]

    const optionData = {
      checked: checkedPassengerIdsSet.has(o.id) || false,
      hasProfile: true,
    }

    if (!profile) {
      optionData.hasProfile = false
    }

    return _.merge({}, profile || {}, optionData)
  })
}

// XXX Hack
const getLastBooking = (state) => _.last(_.filter(getOrderedBookingForms(state), isTourBooking))

export {
  getBookingForms,
  getOrderedBookingForms,
  getLastBooking,
  getPassengerList,
  getBookingFormById,
  getBookingFormByAction,
  getSubmitableBookingForms,
}
