import React, { useState, useEffect } from "react"

import { subscribeToConversation } from "@/channels/conversation_channel"

import { uuid } from "@/utils"

import _ from "lodash"

const Context = React.createContext({
  canvasId: "",
  conversation: { subject: "", messages: [], isUnread: false, isRead: true },
  resetSubscription: () => {},
  updateConversation: ({}) => {},
})

const Provider = ({ conversation, children }) => {
  const [canvasId, setCanvasId] = useState(`canvas-${uuid()}`)

  const [serverConversation, setServerConversation] = useState(
    _.merge({}, conversation, { messages: [] })
  )

  const updateConversation = (update) =>
    setServerConversation((currentConversation) => _.merge({}, currentConversation, update))

  // When this nonce is reset, it triggers a reconnection of the subscription
  const [subscriptionNonce, setSubscriptionNonce] = useState(uuid())
  const resetSubscription = () => {
    setSubscriptionNonce(uuid())
  }

  useEffect(() => {
    const channel = subscribeToConversation(
      conversation.id,
      (conversation) => {
        setServerConversation(conversation)
      },
      () => resetSubscription()
    )

    return () => {
      // console.log(`Unsubscribe conversation ${conversation.id}`, channel)
      channel.unsubscribe()
    }
  }, [subscriptionNonce])

  return (
    <Context.Provider
      value={{
        conversation: serverConversation,
        resetSubscription,
        canvasId,
        updateConversation,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context

export { Provider }
