import React from "react"
import { Button, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { getDailySettlement, markAsInvoiced } from "@/daily_settlement/Slice"

const MarkAsInvoicedButton = (): JSX.Element => {
  const dispatch = useDispatch()

  const { submitting, checkedIds, permissions } = useSelector(getDailySettlement)

  if (!permissions.makePayments) {
    return null
  }

  if (submitting) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Submitting...</span>
      </Spinner>
    )
  }

  return (
    <Button
      disabled={checkedIds.length === 0}
      onClick={() => dispatch(markAsInvoiced({ settlementSheetIds: checkedIds }))}
    >
      Mark As Invoiced
    </Button>
  )
}

export default MarkAsInvoicedButton
