import React from "react"
import _ from "lodash"

import { isBlank } from "@/utils"

const TextDisplay = ({ value }) => {
  let displayValue = value

  if (_.isBoolean(value)) {
    return <>{value ? "Yes" : "No"}</>
  }

  if (value === "true") {
    return <>Yes</>
  }

  if (value === "false") {
    return <>No</>
  }

  if (_.isArray(value)) {
    displayValue = _.join(value, ", ")
  }

  if (_.isNumber(value)) {
    return <span className="text-wrap">{displayValue}</span>
  }

  if (_.has(value, "type") && _.has(value, "value")) {
    if (value.multiple) {
      displayValue = _.join(
        value.value.map((v) => v.label),
        ", "
      )
    } else {
      displayValue = value.value?.label || null
    }
  }

  if (isBlank(displayValue)) {
    displayValue = <span className="text-wrap">Not specified</span>
  } else {
    displayValue = <span className="text-wrap">{_.toString(displayValue)}</span>
  }

  return <>{displayValue}</>
}

export default TextDisplay
