import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import {
  checkSettlement,
  getDailySettlement,
  uncheckSettlement,
  getPayableSettlementSheetIds,
} from "@/daily_settlement/Slice"

const ToggleSettlement = ({ settlementId }): JSX.Element => {
  const dispatch = useDispatch()
  const { checkedIds } = useSelector(getDailySettlement)
  const payableIds = useSelector(getPayableSettlementSheetIds)

  const enabled = _.includes(payableIds, settlementId)
  const checked = _.includes(checkedIds, settlementId)

  let onChange

  if (checked) {
    onChange = () => dispatch(uncheckSettlement({ settlementId }))
  } else {
    onChange = () => dispatch(checkSettlement({ settlementId }))
  }

  return <Form.Check type="checkbox" disabled={!enabled} checked={checked} onChange={onChange} />
}

export default ToggleSettlement
