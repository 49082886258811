import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import {
  getPriceAnalysis,
  getProspectiveAwardSet,
} from "@/onboarding/features/price_analysis/selectors"
import { selectAwardSet } from "@/onboarding/features/price_analysis/Slice"

const AwardSetRadioButton = ({ awardSetId }): JSX.Element => {
  const dispatch = useDispatch()
  const awardSet = useSelector(getProspectiveAwardSet(awardSetId))
  const { selectedAwardSetId } = useSelector(getPriceAnalysis)

  return (
    <Form.Check
      type="radio"
      checked={awardSetId === selectedAwardSetId}
      label={awardSet.name}
      onChange={() => dispatch(selectAwardSet({ selectedAwardSetId: awardSetId }))}
    />
  )
}

export default AwardSetRadioButton
