import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getFeatures, getPerformanceSummary } from "@/onboarding/features/product/selectors"
import { formatPercentage } from "@/helpers/number"
import Hidden from "@/components/Hidden"

const SummaryDataRow = ({ label, summary }: { label: string; summary: any }): JSX.Element => {
  const features = useSelector(getFeatures)
  const targetMet = summary.currentMargin.fractional >= summary.marginTarget.fractional

  return (
    <tr>
      <td>{label}</td>
      <td className="text-right">{summary.expectedAdults}</td>
      <td className="text-right">{summary.expectedChildren}</td>
      <td className="text-right">{summary.expectedGuests}</td>
      <Hidden unless={features.general.product.forecasts.showFinancialTargets}>
        <td className="text-right">{summary.salesTarget.formatted}</td>
        <td className="text-right">
          {`${summary.marginTarget.formatted} (${formatPercentage(
            summary.marginTargetPercentage
          )})`}
        </td>
        <td className={targetMet ? "text-success text-right" : "text-danger text-right"}>
          {`${summary.currentMargin.formatted} (${formatPercentage(
            summary.currentMarginPercentage
          )})`}
        </td>
      </Hidden>
    </tr>
  )
}

const TargetSummary: FC = () => {
  const features = useSelector(getFeatures)
  const summary = useSelector(getPerformanceSummary)

  return (
    <Table>
      <thead>
        <tr>
          <th scope="col" aria-label="Timeframe" />
          <th scope="col" className="text-right">
            Expected Adults
          </th>
          <th scope="col" className="text-right">
            Expected Children
          </th>
          <th scope="col" className="text-right">
            Expected Total Guests
          </th>
          <Hidden unless={features.general.product.forecasts.showFinancialTargets}>
            <th scope="col" className="text-right">
              Sales Target
            </th>
            <th scope="col" className="text-right">
              Margin Target
            </th>
            <th scope="col" className="text-right">
              Current Margin
            </th>
          </Hidden>
        </tr>
      </thead>
      <tbody>
        <SummaryDataRow label="Per Call" summary={summary.daily} />
        <SummaryDataRow label="Per Season" summary={summary.season} />
      </tbody>
    </Table>
  )
}

export default TargetSummary
