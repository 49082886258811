import React, { FC } from "react"

import SectionHolder from "./shared/SectionHolder"
import Content from "./web_content/Content"
import Edit from "./web_content/Edit"

const WebContent: FC = () => (
  <SectionHolder title="Web Content" editComponent={Edit} editMethod="inline">
    <Content />
  </SectionHolder>
)

export default WebContent
