import React from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import { canEdit, getField } from "@/onboarding/features/product/selectors"
import { FieldTypes } from "@/document_editor/models/Field"
import { withUpdateFieldArray, withUpdateFields } from "@/onboarding/features/product/helpers"
import TextInput from "@/components/TextInput"
import TextAreaInput from "@/components/TextAreaInput"
import NumberInput from "@/components/NumberInput"
import SwitchInput from "@/components/Switch"
import { newComponent } from "@/onboarding/features/product/models/Component"

import InputList from "./InputList"
import RateInputs from "./RateInputs"

const FieldInput = ({ name }: { name: string }) => {
  const dispatch = useDispatch()
  const { type, value, label, errors, validationMessage, writeable, required } = useSelector(
    getField(name)
  )
  const editable = useSelector(canEdit) && writeable
  const isInvalid = !_.isEmpty(errors)

  const SubmittingTextField = withUpdateFields(dispatch)(TextInput)
  const SubmittingTextArea = withUpdateFields(dispatch)(TextAreaInput)
  const SubmittingNumber = withUpdateFields(dispatch)(NumberInput)
  const SubmittingSwitch = withUpdateFields(dispatch)(SwitchInput)
  const SubmittingTextList = withUpdateFieldArray(dispatch)(InputList)
  const SubmittingComponentList = withUpdateFieldArray(dispatch)(InputList)
  const SubmittingRateInputField = withUpdateFields(dispatch)(RateInputs)

  let displayLabel

  switch (name) {
    case "logistical_components":
      displayLabel = "Components"
      break
    case "overhead_components":
      return (
        <SubmittingTextArea
          name={name}
          label="Notes"
          rows={5}
          value="Suggestions or things to mention. Please state any element of cost which in addition to profit margin is included within your overhead - however small."
          readOnly
          required={required}
          showLockedIcon={!editable}
          showRequired={required}
        />
      )
    default:
      displayLabel = label || _.startCase(name)
  }

  switch (type) {
    case FieldTypes.TEXT_FIELD:
      return (
        <SubmittingTextField
          name={name}
          label={displayLabel}
          value={value}
          validationMessage={validationMessage}
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          showLockedIcon={!editable}
          showRequired={required}
        />
      )
    case FieldTypes.TEXT_AREA:
      return (
        <SubmittingTextArea
          name={name}
          label={displayLabel}
          value={value}
          validationMessage={validationMessage}
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          rows={10}
          showLockedIcon={!editable}
          showRequired={required}
        />
      )
    case FieldTypes.NUMBER:
      return (
        <SubmittingNumber
          name={name}
          label={displayLabel}
          value={value}
          validationMessage={validationMessage}
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          showLockedIcon={!editable}
          showRequired={required}
        />
      )
    case FieldTypes.TEXT_LIST:
      return (
        <SubmittingTextList
          name={name}
          label={displayLabel}
          value={value}
          validationMessage={validationMessage}
          newValue={() => ""}
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          showControls={editable}
          showLockedIcon={!editable}
          showRequired={required}
        />
      )
    case FieldTypes.TOUR_COMPONENT_LIST:
      return (
        <SubmittingComponentList
          name={name}
          label={displayLabel}
          value={value}
          validationMessage={validationMessage}
          newValue={newComponent}
          fieldValuePath="name"
          addLabel="Add Component"
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          showControls={editable}
          showRequired={required}
        />
      )
    case FieldTypes.RATESHEET_PICKER:
      return (
        <SubmittingRateInputField
          name={name}
          value={value}
          validationMessage={validationMessage}
          readOnly={!editable}
          isInvalid={isInvalid}
          required={required}
          showRequired={required}
        />
      )
    case FieldTypes.BOOLEAN:
      return (
        <SubmittingSwitch
          name={name}
          label={displayLabel}
          value={value}
          readOnly={!editable}
          showRequired={required}
        />
      )
    default:
      return null
  }
}

export default FieldInput
