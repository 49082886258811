import React from "react"
import { Card, Row, Col } from "react-bootstrap"

import ErrorBoundary from "@/components/ErrorBoundary"

import PrivateVillasTable from "./private_villas_card/PrivateVillasTable"

const PrivateVillasCard = ({ privateVillas }) => {
  const renderTable = () => {
    if (privateVillas.length === 0) {
      return (
        <Card.Body>
          <p>No private villas for the specified date</p>
        </Card.Body>
      )
    }

    return <PrivateVillasTable tours={privateVillas} />
  }

  return (
    <ErrorBoundary>
      <Card.Header>
        <Row>
          <Col>
            <br />
            <h2 className="h5"> Private Villa Bookings </h2>
          </Col>
        </Row>
      </Card.Header>
      {renderTable()}
    </ErrorBoundary>
  )
}

export default PrivateVillasCard
