import React, { FC } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import routes from "@/routes"

import ButtonLink from "@/components/ButtonLink"
import LoadingSpinner from "@/components/LoadingSpinner"
import FormToolkit from "@/form_toolkit"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OSN from "@/osn"

import { getForm, getView, isViewLoading } from "./selectors"
import { filter } from "./Slice"

const MultiSelect = FormToolkit.buildReduxMultiSelect(getForm, filter)

const CopyInviteLinkButton: FC = () => {
  const dispatch = useDispatch()
  const { inviteLink } = useSelector(getView)

  const copyLink = () => {
    navigator.clipboard.writeText(inviteLink)

    dispatch(OSN.success("Copied operator invitation link to clipboard", { duration: 2500 }))
  }

  return (
    <Button variant="primary" onClick={copyLink}>
      <span className="fa fa-clipboard me-2" />
      Copy Invite Link
    </Button>
  )
}

const ExportActiveApplicationSignatories: FC = () => (
  <ButtonLink
    href={routes.active_operator_application_signatories_onboarding_exports_path({
      format: "csv",
      _options: true,
    })}
  >
    <span className="fa fa-file-export me-2" />
    Export Active Signatories
  </ButtonLink>
)

const Header: FC = () => {
  const view = useSelector(getView)
  const isLoading = useSelector(isViewLoading)

  return (
    <Card.Header className="d-inline-flex flex-column gap-4">
      <div className="d-flex justify-content-between gap-2">
        <div className="d-flex align-items-center gap-2">
          <h1 className="h4 mb-0">{view.title}</h1>
          {isLoading && <LoadingSpinner className="align-self-center" />}
        </div>
        <div className="d-flex justify-content-end gap-2">
          <CruiseLineOnly>
            <CopyInviteLinkButton />
            <ExportActiveApplicationSignatories />
          </CruiseLineOnly>
        </div>
      </div>
      <CruiseLineOnly>
        <Row>
          <Col md={3}>
            <MultiSelect name="operators" />
          </Col>
          <Col md={3}>
            <MultiSelect name="regions" />
          </Col>
          <Col md={3}>
            <MultiSelect name="countries" />
          </Col>
          <Col md={3}>
            <MultiSelect name="ports" />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <MultiSelect name="statuses" />
          </Col>
        </Row>
      </CruiseLineOnly>
    </Card.Header>
  )
}

export default Header
