export const getTours = ({ guestTours }) => guestTours

export const getToursPerPort = ({ guestTours }) => guestTours.toursPerPort

export const getPassengerID = ({ guestTours }) => guestTours.passenger

export const getVoyageName = ({ guestTours }) => guestTours.voyageName

export const getVoyageID = ({ guestTours }) => guestTours.voyageId

export const isInitialized = ({ guestTours }) => guestTours.initialized
