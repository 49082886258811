import React from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import MultiSelect from "@/components/input/MultiSelect"
import { filter, setTableLoading, updateFormValues } from "@/insurance/features/dashboard/Slice"
import { getForm } from "../selectors"

const toTooltip = ({ values, maxDisplay }) => {
  if (values.length <= maxDisplay) {
    return null
  }

  return _.join(
    _.map(_.map(values, "label"), (item) => `• ${item}`),
    "\n"
  )
}

const MultiSelector = ({ name }): JSX.Element => {
  const dispatch = useDispatch()

  const form = useSelector(getForm)

  const onChange = (e) => {
    dispatch(setTableLoading({ isTableLoading: true }))

    const updatedForm = JSON.parse(JSON.stringify(form))

    updatedForm[name].value = e.target.value

    dispatch(updateFormValues({ updatedForm }))
    dispatch(filter(updatedForm))
  }

  const tooltipValues = _.map(form[name].value, (v) => _.find(form[name].options, { value: v }))

  return (
    <MultiSelect
      name={name}
      value={form[name].value}
      onChange={onChange}
      placeholder={form[name].allLabel}
      options={form[name].options}
      maxSelectedLabels={1}
      label={form[name].label}
      tooltip={toTooltip({ values: tooltipValues, maxDisplay: 1 })}
    />
  )
}

export default MultiSelector
