import React from "react"
import { Button, Form } from "react-bootstrap"
import { Formik, FieldArray } from "formik"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { v4 as uuid } from "uuid"

import Api from "@/api"
import OnScreenNotifications, { newAxiosErrorHandler } from "@/osn"
import { useModal } from "@/helpers/useModal"
import { formatDateTime } from "@/components/DatePickers"
import WriteOnly from "@/document_editor/components/editor_mode_contexts/WriteOnly"
import NotReading from "@/document_editor/components/editor_mode_contexts/NotReading"
import { updateDocument } from "@/document_editor/Slice"
import { getSection } from "@/document_editor/selectors"
import { FieldTypes } from "@/document_editor/models/Field"

import FieldGroup from "./fields/FieldGroup"
import NewFieldModal from "./fields/NewFieldModal"
import RemoveSectionModal from "./RemoveSectionModal"

const newField = (name, type) => {
  let defaultValue

  switch (type) {
    case FieldTypes.TEXT_LIST:
      defaultValue = [""]
      break
    default:
      defaultValue = ""
  }

  return {
    id: uuid(),
    name,
    type,
    text: "",
    changed: false,
    initial: true,
    value: defaultValue,
    proposal: {
      id: null,
      proposedBy: "",
      value: null,
      canAccept: false,
      canReject: false,
      accepted: false,
      rejected: false,
    },
  }
}

const FieldComponent = ({ values, arrayHelpers, isSubmitting }) => {
  const addNewField = (name, type, afterClick) => () => {
    arrayHelpers.push(newField(name, type))
    afterClick()
  }

  const toggleNewFieldModal = useModal((props) => (
    <NewFieldModal {...props} onSubmit={addNewField} />
  ))

  const toggleRemoveSectionModal = useModal((props) => (
    <RemoveSectionModal {...props} section={values} />
  ))

  return (
    <div className="d-flex flex-column gap-3">
      {_.map(values.fields, (field, index) => (
        <FieldGroup key={field.id} index={index} field={field} />
      ))}
      <div className="d-flex gap-3">
        <NotReading>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </NotReading>
        <WriteOnly>
          <Button variant="secondary" disabled={isSubmitting} onClick={toggleNewFieldModal}>
            Add Field
          </Button>
          <Button variant="danger" disabled={isSubmitting} onClick={toggleRemoveSectionModal}>
            Remove Section
          </Button>
        </WriteOnly>
      </div>
      <div className="text-muted">
        <small>{`Last update at: ${formatDateTime(new Date(values.updatedAt))}`}</small>
      </div>
    </div>
  )
}

const Section = ({ initialSection }): JSX.Element => {
  const dispatch = useDispatch()
  const section = useSelector(getSection(initialSection.id))

  return (
    <Formik
      enableReinitialize
      initialValues={section}
      onSubmit={(values, { setSubmitting }) => {
        const payload = {
          ...values,
          fields: values.fields.map((field) => ({
            ...field,
            changed:
              !field.initial &&
              field.value !== _.find(section.fields, (f) => f.name === field.name).value,
          })),
        }

        Api.collaboration.submitSection(section.id, payload).then(({ data }) => {
          // TODO Move to thunk
          dispatch(updateDocument({ document: data.data.document }))
          dispatch(OnScreenNotifications.success(data.message))
        }, newAxiosErrorHandler(dispatch))

        setSubmitting(false)
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FieldArray
            name="fields"
            render={(arrayHelpers) => (
              <FieldComponent
                values={values}
                arrayHelpers={arrayHelpers}
                isSubmitting={isSubmitting}
              />
            )}
          />
        </Form>
      )}
    </Formik>
  )
}

export default Section
