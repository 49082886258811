import React from "react"

import ManualBookingForm from "@/manual_booking/features/booking_form/ManualBookingForm"
import Autorun, { registerAutorun } from "@/manual_booking/Autorun"
import { registerReservationSystem } from "@/manual_booking/reservationSystems"
import bookingFormAutorun from "@/manual_booking/features/booking_form/autorun"
import tourBookingAutorun from "@/manual_booking/features/tour_bookings/autorun"
import transportRentalBookingAutorun from "@/manual_booking/features/transport_rental_bookings/autorun"
import tourReservationSystem from "@/manual_booking/features/tour_bookings/reservationSystem"
import transportRentalReservationSystem from "@/manual_booking/features/transport_rental_bookings/reservationSystem"
import privateVillaReservationSystem from "@/manual_booking/features/private_villa_bookings/reservationSystem"
import { isFeatureOn } from "@/helpers/Flipflop"
import GuestOnly from "@/auth_descriptor/GuestOnly"
import Navbar from "@/guests_footer/Navbar"
import { withApp } from "@/components/App"
import ManualBookingSlice from "@/manual_booking/features/booking_form/Slice"
import TourBookingsSlice from "@/manual_booking/features/tour_bookings/Slice"
import TransportRentalBookingsSlice from "@/manual_booking/features/transport_rental_bookings/Slice"

const App = (props): JSX.Element => {
  /**
   * XXX These functions run on every state change, might be a source of performance issues
   *     if we aren't careful.
   */
  registerAutorun(bookingFormAutorun)
  registerAutorun(tourBookingAutorun)
  registerReservationSystem(tourReservationSystem)

  if (isFeatureOn("privateCars")) {
    registerAutorun(transportRentalBookingAutorun)
    registerReservationSystem(transportRentalReservationSystem)
  }

  if (isFeatureOn("privateVillas")) {
    registerReservationSystem(privateVillaReservationSystem)
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  return (
    <Autorun {...props}>
      <GuestOnly>
        <h4 className="m-3">Book an excursion</h4>
      </GuestOnly>
      <ManualBookingForm {...props} />
      <GuestOnly>
        <Navbar page="Booking" guestId={urlParams.get("id")} />
      </GuestOnly>
    </Autorun>
  )
}

export default withApp(App, {
  slices: [ManualBookingSlice, TourBookingsSlice, TransportRentalBookingsSlice],
})
