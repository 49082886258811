import React, { useState } from "react"
import { Col, ListGroup, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getDocument, getMode } from "@/document_editor/selectors"
import EditorModes from "@/document_editor/models/EditorModes"

import Section from "./Section"
import NewSectionForm from "./NewSectionForm"

const tabTitle = (section) => {
  if (section.pendingProposals > 0) {
    return (
      <>
        {`${section.name} `}
        <span className="badge bg-secondary text-dark">{section.pendingProposals}</span>
      </>
    )
  }

  return <>{section.name}</>
}

const NEW_SECTION_KEY = "new"

const SectionController = (): JSX.Element => {
  const document = useSelector(getDocument)
  const editorMode = useSelector(getMode)

  const sections = _.sortBy(document.sections, "order")
  const keys = _.map(sections, "id")

  if (editorMode === EditorModes.WRITE) {
    keys.push(NEW_SECTION_KEY)
  }

  const [activeKey, setActiveKey] = useState(_.head(keys))

  if (keys.length === 0) {
    return <p>There is currently no available content to edit.</p>
  }

  let content

  if (activeKey === NEW_SECTION_KEY) {
    content = <NewSectionForm documentId={document.id} />
  } else {
    content = <Section initialSection={_.find(sections, (section) => section.id === activeKey)} />
  }

  return (
    <Row>
      <Col md={3}>
        <ListGroup>
          {sections.map((section) => (
            <ListGroup.Item
              key={section.id}
              active={section.id === activeKey}
              action
              onClick={() => setActiveKey(section.id)}
            >
              {tabTitle(section)}
            </ListGroup.Item>
          ))}
          {editorMode === EditorModes.WRITE && (
            <ListGroup.Item
              key={NEW_SECTION_KEY}
              active={activeKey === NEW_SECTION_KEY}
              action
              onClick={() => setActiveKey(NEW_SECTION_KEY)}
            >
              New Section
            </ListGroup.Item>
          )}
        </ListGroup>
      </Col>
      <Col md={9}>{content}</Col>
    </Row>
  )
}

export default SectionController
