import { isLoading } from "@/helpers/AsyncField"

export const getDashboard = ({ operatorProfileDashboard }) => operatorProfileDashboard

export const isViewLoading = (state) => isLoading(getDashboard(state)._view)

export const getView = (state) => getDashboard(state)._view.value

export const getProfiles = (state) => getView(state).profiles

export const getForm = (state) => getView(state).form
