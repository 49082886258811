import { createSlice } from "@reduxjs/toolkit"

const Slice = createSlice({
  name: "guests",
  initialState: {
    initialized: false,
    guestId: "",
    name: "",
    paxType: "",
    voyage: {},
    ports: {},
    bookings: "",
    cruise: "",
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      ...payload,
      ports: payload.ports.filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id)
      ),
      // TODO(guest-app): Pass bookings in as an empty array from the backend. Also simplify reduce here into
      // a curly brace function rather.
      bookings:
        payload.bookings === ""
          ? ""
          : Object.values(
              payload.bookings.reduce(
                (acc, { port, tour }) => (
                  ((acc[port] = acc[port] || { port, tour: [] }).tour = tour.concat(
                    acc[port].tour
                  )),
                  acc
                ),
                {}
              )
            ),
      initialized: true,
    }),
  },
})

export const { initialize } = Slice.actions

export default Slice
