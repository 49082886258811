import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import _ from "lodash"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { onAddConstraint, updateAllotmentConstraints, updateConstraintsEditStates } from "../Slice"
import {
  canSubmit,
  getConstraintsEditStates,
  getNewCallDayConstraints,
  getView,
} from "../selectors"
import ConstraintsTableRow from "./ConstraintsTableRows"
import NewConstraintsTableRow from "./NewConstraintsTableRows"

const ConstraintsTable = () => {
  const view = useSelector(getView)

  const constraintsEditStates = useSelector(getConstraintsEditStates)
  const newConstraints = useSelector(getNewCallDayConstraints)

  const submit = useSelector(canSubmit)

  const dispatch = useDispatch()
  const [hasConstraints, setHasConstraints] = useState(
    newConstraints.length > 0 || !_.isEmpty(view.inventory.callDayConstraints)
  )

  useEffect(() => {
    setHasConstraints(newConstraints.length > 0 || !_.isEmpty(view.inventory.callDayConstraints))
  }, [newConstraints, view])

  const onNew = () => {
    dispatch(onAddConstraint())
  }

  const onSubmit = () => {
    const changedIds = Object.keys(constraintsEditStates).filter(
      (allotmentConstraintId) => constraintsEditStates[allotmentConstraintId]
    )
    dispatch(updateConstraintsEditStates({ editState: {} }))

    const updatedAllotmentConstraints = view.inventory.callDayConstraints.filter((constraint) =>
      changedIds.includes(constraint.allotmentConstraintId)
    )
    const newConstraintsNullIds = newConstraints.map((constraint) => ({
      ...constraint,
      allotmentConstraintId: null,
    }))

    const allNewConstraints = [...updatedAllotmentConstraints, ...newConstraintsNullIds]

    dispatch(updateAllotmentConstraints(allNewConstraints))
  }

  return !hasConstraints ? (
    <>
      <span>No capacity allotments have been submitted</span>
      <CruiseLineOnly>
        <br />
        <Button onClick={() => onNew()} className="btn-secondary mt-2">
          Add constraints
        </Button>
      </CruiseLineOnly>
    </>
  ) : (
    <>
      <Table>
        <thead className="thead-light">
          <tr>
            <th> Start Date of Constraint </th>
            <th> End Date of Constraint </th>
            <th> Minimum per Departure </th>
            <th> Maximum per day </th>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-start-time-delta">
                  Minimum time required after the ship's arrival before a tour can depart
                </Tooltip>
              }
            >
              <th className="cursor-pointer"> Start Time Margin (In Minutes)</th>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-end-time-delta">
                  Minimum time required to remain, after the tour's end time, until the ship's
                  departure time
                </Tooltip>
              }
            >
              <th className="cursor-pointer"> End Time Margin (In Minutes)</th>
            </OverlayTrigger>
            <CruiseLineOnly>
              <th />
            </CruiseLineOnly>
          </tr>
        </thead>
        <tbody>
          <ConstraintsTableRow />
          <NewConstraintsTableRow />
        </tbody>
      </Table>
      <CruiseLineOnly>
        <div className="mt-2 d-flex justify-content-end me-4">
          <Button onClick={() => onNew()} className="btn-secondary me-2">
            <span className="fas fa-plus me-2" />
            New
          </Button>
          {submit && (
            <Button onClick={() => onSubmit()} className="btn-secondary">
              Submit
            </Button>
          )}
        </div>
      </CruiseLineOnly>
    </>
  )
}

export default ConstraintsTable
