import React from "react"
import { Row, Col } from "react-bootstrap"

import SimpleSearchInput from "@/components/SimpleSearchInput"

const Coupon = ({ result }) => (
  <Row className="align-items-center">
    <Col className="ms--2">
      <h4 className="h6 mb-0">{result.coupon.name}</h4>
      <small>
        <i>{result.code}</i>
        {` • ${result.coupon.benefit} off`}
      </small>
    </Col>
  </Row>
)

const CouponSearch = ({ results, isLoading, onSearch, onSelect, ...props }) => (
  <SimpleSearchInput
    id="couponSearch"
    results={isLoading ? [] : results}
    isLoading={isLoading}
    onSearch={onSearch}
    onSelect={onSelect}
    placeholder="Search Coupons"
    labelKey="code"
    resultComponent={Coupon}
    {...props}
  />
)

export default CouponSearch
