import React from "react"

import _ from "lodash"

import routes from "@/routes"

import { Table } from "react-bootstrap"

import ButtonLink from "@/components/ButtonLink"

import CurrentApplication from "./CurrentApplication"

import PendingApplication from "./PendingApplication"

import Settings from "./SettingsApp"

const ArchivedApplications = (props) => (
  <Table>
    <thead>
      <tr>
        <th> Company Name </th>
        <th> Initiated At </th>
        <th> State </th>
        <th> Rejection Reason </th>
        <th> </th>
      </tr>
    </thead>
    <tbody>
      {props.view.archivedApplications.length == 0 ? (
        <tr>
          {" "}
          <td colSpan="5">
            {" "}
            <i> No archived applications </i>{" "}
          </td>{" "}
        </tr>
      ) : (
        _.map(props.view.archivedApplications, (a) => (
          <tr key={a.id}>
            <td> {a.invitation.operator.name} </td>
            <td> {a.initiatedAt} </td>
            <td className={a.statusClass}> {a.status} </td>
            <td> {a.rejectionReason} </td>
            <td align="right">
              <ButtonLink
                href={routes.onboarding_operator_application_path({ id: a.id, _options: true })}
                label="View"
                button={{ variant: "outline-primary" }}
                key="view"
              />
            </td>
          </tr>
        ))
      )}
    </tbody>
  </Table>
)

const OperatorProfileApp = (props) => {
  const { view } = props

  return (
    <div>
      <div className="mb-4">
        <Table>
          <tbody>
            <tr>
              <th className="fit">Current Application Status</th>
              {view.currentApplication ? (
                <td>
                  <strong>
                    <div className={view.currentApplication.statusClass}>
                      {view.currentApplication.status}
                    </div>
                  </strong>
                </td>
              ) : (
                <td>
                  <i>No current application</i>
                </td>
              )}
            </tr>
            {/* Render Pending Application Status */}
            <tr>
              <th className="fit">Pending Application Status</th>
              {view.application ? (
                <td>
                  <strong>
                    <div className={view.application.statusClass}>{view.application.status}</div>
                  </strong>
                </td>
              ) : (
                <td>
                  <i>No application in-progress</i>
                </td>
              )}
            </tr>
            {/* Render Rejection Reason */}
            <tr>
              <th className="fit">Rejection Reason</th>
              {view.application ? (
                <td>{view.application.rejectionReason}</td>
              ) : (
                <td>
                  <i>No application in-progress</i>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
      {/* Render Tabs */}
      <div className="nav nav-tabs mb-3" role="tablist">
        {view.currentApplication && (
          <div
            className="nav-link nav-tab ms-1 border border-primary active"
            data-bs-toggle="tab"
            data-bs-target="#tabsCurrentApplication"
            role="tab"
            aria-controls="tabsCurrentApplication"
            aria-selected="true"
          >
            Current Application
          </div>
        )}
        {view.application && (
          <div
            className={`nav-link nav-tab ms-1 border border-primary ${
              !view.currentApplication ? "active" : ""
            }`}
            data-bs-toggle="tab"
            data-bs-target="#tabsPendingApplication"
            role="tab"
            aria-controls="tabsPendingApplication"
            aria-selected="false"
          >
            Pending Application
          </div>
        )}
        {view.settings && (
          <div
            className="nav-link nav-tab ms-1 border border-primary"
            data-bs-toggle="tab"
            data-bs-target="#tabsSettings"
            role="tab"
            aria-controls="tabsSettings"
            aria-selected="false"
          >
            Settings
          </div>
        )}

        <div
          className="nav-link nav-tab ms-1 border border-primary"
          data-bs-toggle="tab"
          data-bs-target="#tabsArchivedApplications"
          role="tab"
          aria-controls="tabsArchivedApplications"
          aria-selected="false"
        >
          Archived Applications
        </div>
      </div>
      {/* Render Tab Content */}
      <div className="tab-content">
        {view.currentApplication && (
          <div className="tab-pane fade show active" id="tabsCurrentApplication">
            <CurrentApplication view={view} />
          </div>
        )}
        {view.application && (
          <div
            className={`tab-pane fade show ${!view.currentApplication ? "active" : ""}`}
            id="tabsPendingApplication"
          >
            <PendingApplication view={view} />
          </div>
        )}
        {view.settings && (
          <div className="tab-pane fade show" id="tabsSettings">
            <Settings view={view.settings} />
          </div>
        )}
        <div className="tab-pane fade show" id="tabsArchivedApplications">
          <ArchivedApplications view={view} />
        </div>
      </div>
    </div>
  )
}

export default OperatorProfileApp
