import { getDayOfYear } from "date-fns"

export const rowStyle = (date) => {
  const dayOfYear = getDayOfYear(new Date(date))

  if (dayOfYear % 2 === 0) {
    return { backgroundColor: "WhiteSmoke" }
  }

  return {}
}
