import { createSlice } from "@reduxjs/toolkit"
import * as AsyncField from "@/helpers/AsyncField"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"
import { LoadState } from "@/helpers/AsyncField"
import Api from "@/api"

export const filter = AsyncField.createLoadThunk(
  "insurances/filter",
  (_action) => "_view",
  async (form, thunkAPI) => {
    const response = await Api.insurances
      .filter(form)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

const Slice = createSlice({
  name: "insurance",
  initialState: {
    initialized: false,
    _view: {},
    form: {},
    isTableLoading: false,
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      initialized: true,
      _view: AsyncField.createField({
        value: payload.view,
        defaultValue: payload.view,
        state: LoadState.Ready,
      }),
      form: payload.view.form,
      insuranceOverview: payload.view.insuranceOverview,
    }),
    updateFormValues: (state, { payload }) => ({
      ...state,
      form: payload.updatedForm,
    }),
    setTableLoading: (state, { payload }) => ({
      ...state,
      isTableLoading: payload.isTableLoading,
    }),
  },
  extraReducers: {
    ...filter.reducers,
  },
})

export const { initialize, updateFormValues, setTableLoading } = Slice.actions

export default Slice
