const isPrivateVillaBooking = (booking) => booking.kind === "PrivateVillaBooking"

const isTourBooking = (booking) => booking.kind === "TourBooking"

const productTypeText = (booking, plural = false) => {
  if (isPrivateVillaBooking(booking)) {
    return `Private Villa${plural ? "s" : ""}`
  }

  if (isTourBooking(booking)) {
    return `Tour${plural ? "s" : ""}`
  }
}

export { isPrivateVillaBooking, isTourBooking, productTypeText }
