import React from "react"
import { useSelector } from "react-redux"

import { getSelectedProduct } from "@/onboarding/features/price_analysis/selectors"
import routes from "@/routes"

const ProductLinkButton = (): JSX.Element => {
  const product = useSelector(getSelectedProduct)

  return (
    <a
      href={routes.onboarding_product_path({ id: product.id })}
      className="btn btn-primary btn-sm"
      role="button"
    >
      <span className="fas fa-file-alt me-2" />
      View Product
    </a>
  )
}

export default ProductLinkButton
