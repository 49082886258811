import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { Formik } from "formik"

import Api from "@/api"
import AsyncSearch from "@/helpers/AsyncSearch"
import { getView } from "@/onboarding/features/product/components/product_components/views/EditWrapper"
import { appendFieldListItem } from "@/onboarding/features/product/Slice"
import { getComponentFieldName } from "@/onboarding/features/product/models/Component"
import LoadingSpinner from "@/components/LoadingSpinner"

const getComponent = ({ components }) => {
  if (_.isEmpty(components)) {
    return {}
  }

  return _.head(components).value.document
}

const componentSelected = (values) => !_.isEmpty(getComponent(values))

const ComponentPreview = ({ component }): JSX.Element => {
  const View = getView(component)

  return <View component={component} />
}

const ImportComponentModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="import-component-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="import-component-modal-title">Import Component</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <Formik
          initialValues={{ components: [] }}
          onSubmit={(values, { setSubmitting }) => {
            if (!componentSelected(values)) {
              return
            }

            setSubmitting(true)

            const component = getComponent(values)

            dispatch(
              appendFieldListItem({
                fieldName: getComponentFieldName(component.kind),
                value: component,
              })
            )

            setSubmitting(false)
            onHide()
          }}
        >
          {({ handleSubmit, setValues, values, isSubmitting }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Component</Form.Label>
                <AsyncSearch
                  selectedValues={values.components}
                  setSelectedValues={(components) => setValues({ components })}
                  getOptions={Api.onboardingProduct.searchComponents}
                  maxSelectable={1}
                />
              </Form.Group>
              {componentSelected(values) && <ComponentPreview component={getComponent(values)} />}
              {isSubmitting ? (
                <LoadingSpinner />
              ) : (
                <div className="d-flex gap-3">
                  <Button type="submit" disabled={!componentSelected(values)}>
                    Submit
                  </Button>
                  <Button variant="danger" onClick={onHide}>
                    Cancel
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ImportComponentModal
