import React from "react"
import { Form } from "react-bootstrap"
import { Dropdown as PrimeDropdown } from "primereact/dropdown"
import _ from "lodash"

import { isPresent } from "@/utils"

import FloatableGroup from "./FloatableGroup"

const EmptyMessage = ({ message }) => {
  if (_.isString(message)) {
    return <i>{message}</i>
  }

  return message
}

// @param options is a list of items shaped as [{label: 'Paris', value: 'PRS'}]
// See for help and more options https://www.primefaces.org/primereact/multiselect/
const SingleSelect = ({
  name,
  label,
  helpText = "",
  options = [],
  floating = false,
  emptyMessage = "",
  filter = true,
  onChangeFormatValue = undefined,
  showLockedIcon = false,
  panelStyle = {},
  showRequired = false,
  onChange,
  value,
  ...props
}) => {
  const renderEmpty = _.isEmpty(options) && isPresent(emptyMessage)

  if (renderEmpty) {
    return (
      <FloatableGroup label={label} floating={floating} showLockedIcon={showLockedIcon}>
        <div>
          <EmptyMessage message={emptyMessage} />
        </div>
      </FloatableGroup>
    )
  }

  const formattedOnChange = onChangeFormatValue
    ? (event) => {
        event.value = onChangeFormatValue(event.value)
        event.target.value = event.value

        return onChange(event)
      }
    : onChange

  return (
    <FloatableGroup
      label={label}
      floating={floating}
      showLockedIcon={showLockedIcon}
      showRequired={showRequired}
    >
      <div>
        <PrimeDropdown
          key="select"
          appendTo="self"
          name={name}
          options={options}
          style={{ width: "100%" }}
          filter={filter || undefined}
          onChange={formattedOnChange}
          value={_.has(value, "value") ? value.value : value}
          panelStyle={{
            maxHeight: "518px",
            backgroundColor: "white",
            overflowY: "auto",
            ...panelStyle,
          }}
          {...props}
        />
        <br />
        {isPresent(helpText) && (
          <Form.Text key="helpText" muted>
            {helpText}
          </Form.Text>
        )}
      </div>
    </FloatableGroup>
  )
}

export default SingleSelect
