import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isBlank } from "@/utils"
import _ from "lodash"
import { CaretDown, CaretUp, BlueLock, GreenCheckMark } from "@/components/Icons"
import { parseISO } from "date-fns"
import { formatDate } from "@/components/DatePickers"
import { Button } from "react-bootstrap"
import NumberInput from "@/components/input/Number"
import { ChatButton } from "@/components/chat"
import {
  getDayExpansionStates,
  getRowEditStates,
  getCruiseDeparturesEditStates,
  getDepartureRequestStates,
  getView,
  getIsMakingRequestStates,
  isDisplaySales,
} from "../selectors"
import CruiseLineDepartures from "./CruiseLineDepartures"
import {
  calculateTotalMaximumCapacity,
  RenderIssuesColumn,
  RenderDeparturesColumn,
  isRowExpandable,
  textColor,
  getSalesTextColor,
} from "./Helpers"
import AdditionalTours from "./AdditionalTours"
import AdditionalShips from "./AdditionalShips"
import {
  updateDayExpansionStates,
  updateRowEditStates,
  updateAllotmentConstraints,
  onConstraintRowInputChange,
  onCancelEdit,
  lockDays,
  unlockDays,
  addDraftConstraint,
  addDraftCruiseDeparture,
  updateCruiseDeparturesEditStates,
  updateDeparturesRequestStates,
  approveDepartures,
  updateIsMakingRequestStates,
} from "../Slice"
import SalesDepartures from "./SalesDepartures"

const CruiselineTableRows = ({ index }) => {
  const view = useSelector(getView)
  const call = view.inventory.allocationTableData.callDays[index]
  const dispatch = useDispatch()
  const tour = call.inventory.currentAllocatingTour
  const hasCruiselineDepartures = !isBlank(tour.constraints.departures)

  const dayExpansionStates = useSelector(getDayExpansionStates)
  const isMakingRequestStates = useSelector(getIsMakingRequestStates)
  const displaySales = useSelector(isDisplaySales)

  const rowEditStates = useSelector(getRowEditStates)
  const cruiseDepartureEditStates = useSelector(getCruiseDeparturesEditStates)
  const departureRequestStates = useSelector(getDepartureRequestStates)

  const [expand, setExpand] = useState(dayExpansionStates || {})
  const [edit, setEdit] = useState(rowEditStates || {})
  const [editCruiseDepartures, setEditCruiseDepartures] = useState(cruiseDepartureEditStates || {})
  const [departureRequests, setDepartureRequests] = useState(departureRequestStates || {})
  const [makingRequest, setMakingRequest] = useState(isMakingRequestStates || {})

  const ConstraintsEditColumns = (date, constraints, property) => (
    <NumberInput
      style={{ minWidth: "75px", maxWidth: "120px" }}
      className="mt-1"
      name="description"
      label=""
      placeholder={0}
      value={constraints[property]}
      onChange={(e) =>
        dispatch(
          onConstraintRowInputChange({
            date,
            property,
            input: e.target.value,
          })
        )
      }
    />
  )

  useEffect(() => {
    if (dayExpansionStates) {
      setExpand(dayExpansionStates)
    }
    if (rowEditStates) {
      setEdit(rowEditStates)
    }
    if (cruiseDepartureEditStates) {
      setEditCruiseDepartures(cruiseDepartureEditStates)
    }
    if (departureRequestStates) {
      setDepartureRequests(departureRequestStates)
    }
    if (isMakingRequestStates) {
      setMakingRequest(isMakingRequestStates)
    }
  }, [
    dayExpansionStates,
    rowEditStates,
    cruiseDepartureEditStates,
    departureRequestStates,
    isMakingRequestStates,
  ])

  const onApproveOperatorDepartures = async (date) => {
    await dispatch(approveDepartures([call.date]))
  }

  const onExpand = (date) => {
    const newState = { ...expand, [date]: !expand[date] }
    setExpand(newState)
    dispatch(updateDayExpansionStates({ expandState: newState }))
  }

  const onEdit = (date, cancel = false) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    dispatch(updateRowEditStates({ editState: newState }))
    if (!cancel && isBlank(tour.constraints.allotmentConstraint)) {
      dispatch(addDraftConstraint({ date }))
    }
    if (cancel) {
      dispatch(onCancelEdit({ date }))
    }
  }

  const onRequest = (date) => {
    const newEditState = { ...editCruiseDepartures, [date]: !editCruiseDepartures[date] }
    setEditCruiseDepartures(newEditState)
    dispatch(updateCruiseDeparturesEditStates({ editState: newEditState }))

    const newRequestState = { ...departureRequests, [date]: !departureRequests[date] }
    setDepartureRequests(newRequestState)
    dispatch(updateDeparturesRequestStates({ requestState: newRequestState }))
    if (!hasCruiselineDepartures) {
      dispatch(addDraftCruiseDeparture({ date }))
    }

    const newMakingRequestState = { ...makingRequest, [date]: true }
    setMakingRequest(newMakingRequestState)
    dispatch(updateIsMakingRequestStates({ isMakingRequestStates: newMakingRequestState }))
  }

  const onLockDay = (date) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    tour.lockedDay ? dispatch(unlockDays([date])) : dispatch(lockDays([date]))
    dispatch(updateRowEditStates({ editState: newState }))
  }

  const onSubmit = (date) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    const constraint = _.cloneDeep(tour.constraints.allotmentConstraint)

    if (!tour.constraints.isSingleDayConstraint) {
      constraint.allotmentConstraintId = null
      delete constraint.id
    } else {
      constraint.allotmentConstraintId = constraint.id
      delete constraint.id
    }

    constraint.dateSelectors = [
      {
        startDate: date,
        endDate: date,
        departureStartTimeDeltaMinutes: tour.constraints.startTimeDelta,
        departureEndTimeDeltaMinutes: tour.constraints.endTimeDelta,
        weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      },
    ]
    dispatch(updateAllotmentConstraints([constraint]))
    dispatch(updateRowEditStates({ editState: newState }))
    const newMakingRequestState = { ...makingRequest, [date]: false }
    setMakingRequest(newMakingRequestState)
    dispatch(updateIsMakingRequestStates({ isMakingRequestStates: newMakingRequestState }))
  }

  const salesTextColor = getSalesTextColor(
    tour.sales.salesNearOrAtSoldOut,
    tour.sales.salesExceedsCapacity
  )

  return (
    <>
      <tr key={call.date}>
        <td
          className={isRowExpandable(call, true) ? "cursor-pointer" : ""}
          onClick={isRowExpandable(call, true) ? () => onExpand(call.date) : undefined}
        >
          {isRowExpandable(call, true) && (
            <div className="d-inline me-2">{expand[call.date] ? <CaretUp /> : <CaretDown />}</div>
          )}
          {tour.lockedDay && (
            <BlueLock
              data-toggle="tooltip"
              data-placement="top"
              title="Bulk updates will not override this day's departures"
            />
          )}{" "}
          {formatDate(parseISO(call.date), "date.humaneShort")}
        </td>
        <td>
          <span
            className="cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="shipDetails"
          >
            <span
              title={`Launched: ${call.inventory.ship.launchDate}\nCapacity:   ${call.inventory.ship.capacity}\nSailings:     ${call.inventory.ship.sailings}`}
            >
              {`${call.inventory.ship.name}`}
            </span>
          </span>
          <br />
          {`day ${call.portDay.cruiseDay} of ${call.portDay.cruiseLength} `}
        </td>
        <td>
          {call.inventory.ship.arrivalTime && call.inventory.ship.departureTime
            ? `${call.inventory.ship.arrivalTime} - ${call.inventory.ship.departureTime}`
            : call.inventory.ship.arrivalTime
              ? `${call.inventory.ship.arrivalTime} - in port`
              : call.inventory.ship.departureTime
                ? ` in port - ${call.inventory.ship.departureTime}`
                : "in port"}
        </td>
        <td>
          <strong>{tour.code}</strong> <br />
          {isBlank(tour.constraints.allotmentConstraint) && (
            <div>
              {" "}
              No requirement
              <br /> loaded
              {displaySales && (
                <>
                  <br />
                  <br />
                  <strong>Total Sales</strong> <br />
                  <span className="cursor-pointer" data-toggle="tooltip" data-placement="top">
                    <span title={`${tour.sales.capacitySold} / ${tour.sales.capacityTotal} sold`}>
                      Percentage:{" "}
                      {tour.sales.percentageSold !== "N/A"
                        ? `${tour.sales.percentageSold}%`
                        : tour.sales.percentageSold}
                    </span>
                  </span>
                  <br />
                  <span style={{ marginRight: "13px" }}>Units Sold:</span>
                  <span>{tour.sales.capacitySold}</span>
                </>
              )}
            </div>
          )}
          {!isBlank(tour.constraints.allotmentConstraint) && (
            <>
              Min per departure:{" "}
              {edit[call.date]
                ? ConstraintsEditColumns(
                    call.date,
                    tour.constraints.allotmentConstraint,
                    "capacityPerDepartureMin"
                  )
                : ` ${tour.constraints.allotmentConstraint.capacityPerDepartureMin}`}
              <br />
              {edit[call.date] ? (
                <div className="mt-n3">
                  <span>Max per day:</span>
                  {ConstraintsEditColumns(
                    call.date,
                    tour.constraints.allotmentConstraint,
                    "capacityPerDayMax"
                  )}
                </div>
              ) : (
                <>
                  <span style={{ marginRight: "41px" }}>Max per day:</span>
                  <span>{tour.constraints.allotmentConstraint.capacityPerDayMax}</span>
                  {displaySales && (
                    <>
                      <br />
                      <br />
                      <strong>Total Sales</strong> <br />
                      <span
                        className="cursor-pointer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="totalSales"
                      >
                        <span
                          title={`${tour.sales.capacitySold} / ${tour.sales.capacityTotal} sold`}
                        >
                          Percentage:{" "}
                          <span style={{ color: salesTextColor }}>
                            {tour.sales.percentageSold !== "N/A"
                              ? `${tour.sales.percentageSold}%`
                              : tour.sales.percentageSold}
                          </span>
                        </span>
                      </span>
                      <br />
                      <span style={{ marginRight: "13px" }}>Units Sold:</span>
                      <span>{tour.sales.capacitySold}</span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </td>
        <td>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="departureTime"
              closed={tour.closedDay}
            />
          )}
        </td>
        <td>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="durationInMinutes"
              closed={tour.closedDay}
            />
          )}
        </td>
        <td>
          {!isBlank(tour.departures) && (
            <RenderDeparturesColumn
              departures={tour.departures}
              property="minimumCapacity"
              closed={tour.closedDay}
            />
          )}
        </td>
        <td>
          <>
            {!isBlank(tour.departures) && (
              <RenderDeparturesColumn
                departures={tour.departures}
                property="maximumCapacity"
                closed={tour.closedDay}
              />
            )}

            {!tour.closedDay && !isBlank(tour.departures) && (
              <strong style={{ color: textColor("totalMaximumDepartureCapacity", tour.issues) }}>
                {`${calculateTotalMaximumCapacity(tour.departures)} (Total)`}
              </strong>
            )}
          </>
        </td>
        <td>{!isBlank(tour.issues) && <RenderIssuesColumn issues={tour.issues} />}</td>
        <td>
          {tour.state == "Approved" && <GreenCheckMark />} {tour.state}
        </td>
        <td>
          {edit[call.date] ? (
            <>
              <Button className="primary mb-1" onClick={() => onLockDay(call.date)}>
                {tour.lockedDay ? "Unlock" : "Lock"}
              </Button>
              <br />
              <Button variant="secondary mb-1" onClick={() => onEdit(call.date, true)}>
                Cancel
              </Button>
              <br />
              <Button variant="secondary" onClick={() => onSubmit(call.date)}>
                Submit
              </Button>
            </>
          ) : (
            <>
              <Button className="primary mb-1" onClick={() => onEdit(call.date)}>
                Edit
              </Button>
              {tour.state == "Operator request" && (
                <>
                  <br />
                  <Button
                    className="primary mt-1"
                    onClick={() => onApproveOperatorDepartures(call.date)}
                  >
                    Accept
                  </Button>
                </>
              )}
              {!hasCruiselineDepartures && (
                <>
                  <br />
                  <Button className="primary mt-1" onClick={() => onRequest(call.date)}>
                    Request
                  </Button>
                </>
              )}
            </>
          )}
        </td>
        <td>
          <div key={call.chat.id}>
            <ChatButton chat={call.chat} key={call.chat.date} />
          </div>
        </td>
        <td />
      </tr>
      {(expand[call.date] || makingRequest[call.date]) && <CruiseLineDepartures index={index} />}
      {expand[call.date] && <SalesDepartures index={index} />}
      {expand[call.date] && !isBlank(call.additionalToursForViewing) && (
        <AdditionalTours inventory={call.additionalToursForViewing} />
      )}
      {expand[call.date] && !isBlank(call.additionalInventoryInPort) && (
        <AdditionalShips additionalInventoryInPort={call.additionalInventoryInPort} />
      )}
    </>
  )
}

export default CruiselineTableRows
