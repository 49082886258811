import _ from "lodash"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import routes from "@/routes"
import Api from "@/api"
import { newAxiosErrorHandler } from "@/osn"
import { getGuestIds } from "@/settlement/selectors"
import * as AsyncField from "@/helpers/AsyncField"

const pushConfirmations = createAsyncThunk(
  "settlement/pushConfirmations",
  async ({ confirmedIds }, thunkAPI) => {
    const { tourId } = thunkAPI.getState().settlement.tour
    const unconfirmedIds = _.difference(getGuestIds(thunkAPI.getState()), confirmedIds)

    const data = {
      settlement: { confirmedIds, unconfirmedIds },
    }

    const response = await Api.settlement
      .pushConfirmations(tourId, data)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return response.data.data.settlement
  }
)

const submitSheet =
  ({ signature, comment, override }) =>
  async (dispatch, getState) => {
    const { tourId } = getState().settlement.tour

    const data = {
      signature,
      comment,
      override,
    }

    return Api.settlement.submitSheet(tourId, { settlement: data }).then((_response) => {
      window.location = routes.dashboard_path()
    }, newAxiosErrorHandler(dispatch))
  }

const disputeSheet =
  ({ comment }) =>
  async (dispatch, getState) => {
    const { tourId } = getState().settlement.tour

    const data = {
      comment,
    }

    return Api.settlement.disputeSheet(tourId, { settlement: data }).then((_response) => {
      window.location = routes.dashboard_path()
    }, newAxiosErrorHandler(dispatch))
  }

const fetchPriceBreakdown = AsyncField.createLoadThunk(
  "settlement/fetchPriceBreakdown",
  () => "_priceBreakdown",
  async ({}, thunkAPI) => {
    const { tourId } = thunkAPI.getState().settlement.tour

    const response = await Api.settlement
      .fetchPriceBreakdown(tourId)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.settlement.priceBreakdown,
    }
  }
)

const Slice = createSlice({
  name: "settlement",
  initialState: {
    initialized: false,
    finalized: false,
    editing: false,
    submitting: false,

    settlementId: null,
    arrivedIds: [],
    confirmedIds: [],
    unconfirmedIds: [],
    pendingConfirmedIds: [],

    tour: {},
    cruiseLine: "",
    signedShxAgent: null,
    signedTourAgent: null,

    guests: [],
    adults: [],
    children: [],

    _priceBreakdown: AsyncField.createStaleField(),
  },
  reducers: {
    initializeSheet: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
      adults: _.filter(payload.guests, (guest) => guest.paxType === "adult"),
      children: _.filter(payload.guests, (guest) => guest.paxType === "child"),
    }),
    toggleEditing: (state, _action) => ({
      ...state,
      editing: !state.editing,
      pendingConfirmedIds: _.difference(getGuestIds({ settlement: state }), state.unconfirmedIds),
    }),
    guestConfirmed: (state, { payload }) => ({
      ...state,
      pendingConfirmedIds: _.union(state.pendingConfirmedIds, [payload.guestId]),
    }),
    guestUnconfirmed: (state, { payload }) => ({
      ...state,
      pendingConfirmedIds: _.difference(state.pendingConfirmedIds, [payload.guestId]),
    }),
  },
  extraReducers: {
    ...fetchPriceBreakdown.reducers,
    [pushConfirmations.pending]: (state, _action) => ({
      ...state,
      submitting: true,
    }),
    [pushConfirmations.rejected]: (state, _action) => ({
      ...state,
      editing: false,
      submitting: false,
    }),
    [pushConfirmations.fulfilled]: (state, { payload }) => ({
      ...state,
      editing: false,
      submitting: false,
      confirmedIds: payload.confirmedIds,
      unconfirmedIds: payload.unconfirmedIds,
      _priceBreakdown: AsyncField.fn.invalidatedField(state._priceBreakdown),
    }),
  },
})

export const { initializeSheet, toggleEditing, guestConfirmed, guestUnconfirmed } = Slice.actions

export { fetchPriceBreakdown, submitSheet, disputeSheet, pushConfirmations }

export default Slice
