import { createAsyncThunk } from "@reduxjs/toolkit"
import { v4 as uuid } from "uuid"
import _ from "lodash"

import { createBookingsSlice } from "@/manual_booking/createBookingsSlice"
import { getSubmitableGlobalData } from "@/manual_booking/features/booking_form/selectors"
import Api from "@/api"
import { requireKeys } from "@/utils"
import { LoadState } from "@/helpers/useDelayedState"
import * as AsyncField from "@/helpers/AsyncField"

import { getBookingFormById, getBookingFormByAction } from "./selectors"
import { getSubmitableBookingForm } from "./bookingSelectors"

const fetchTourProductOptions = AsyncField.createLoadThunk(
  "tourBookings/fetchTourProductOptions",
  (action) => `bookingForms.${action.payload.formId}._tourProductOptions`,
  async ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().tourBookings, formId)
    )

    const response = await Api.manualBooking.fetchTourProductOptions(bookingData)

    return { value: response.data.data.tourProducts }
  }
)

const fetchTourOptions = AsyncField.createLoadThunk(
  "tourBookings/fetchTourOptions",
  (action) => `bookingForms.${action.payload.formId}._tourOptions`,
  async ({ formId, tourProductId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().tourBookings, formId)
    )

    const response = await Api.manualBooking.fetchTourOptions(bookingData)
    return { value: response.data.data.tours }
  }
)

const fetchPort = AsyncField.createLoadThunk(
  "tourBookings/fetchPort",
  (action) => `bookingForms.${action.payload.formId}._port`,
  async ({ formId, tourProductId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().tourBookings, formId)
    )

    const response = await Api.manualBooking.fetchPort(bookingData.tourStartDate)
    return { value: response.data.data.port }
  }
)

const findRooms = AsyncField.createLoadThunk(
  "tourBookings/fetchRooms",
  (action) => `bookingForms.${action.payload.formId}._roomSearch`,
  async ({ q, formId }, thunkAPI) => {
    const { tourId } = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().tourBookings, formId)
    )

    const response = await Api.manualBooking.fetchRooms(q, { tourIds: [tourId] })

    return {
      value: response.data.data.rooms,
    }
  }
)

const fetchPassengers = createAsyncThunk(
  "tourBookings/fetchPassengers",
  async ({ passengerIds, formId }, thunkAPI) => {
    const response = await Api.manualBooking.fetchGuests(passengerIds)
    return { formId, passengers: response.data.data.guests }
  }
)

const fetchNextBookableDate = AsyncField.createLoadThunk(
  "tourBookings/fetchNextBookableDate",
  (action) => `bookingForms.${action.payload.formId}._nextTourStartDate`,
  async ({ formId, date }, thunkAPI) => {
    const response = await Api.manualBooking.fetchNextBookableDate(date)
    return { value: response.data.data.nextBookableDate }
  }
)

const selectRoomResult = createAsyncThunk(
  "tourBookings/selectRoomResult",
  async ({ room, formId }, thunkAPI) => {
    const { tourStartDate: date } = getSubmitableBookingForm(
      getBookingFormById(_.get(thunkAPI.getState(), "tourBookings"), formId)
    )

    const response = await Api.manualBooking.fetchRoomGuests(room.id, date)

    return {
      formId,
      passengers: response.data.data.guests,
      chargedRoomAccount: room.roomAccount,
    }
  }
)

// Finds the correct tourOption object for the given id
const currentTourOption = (formState) => {
  if (!formState.tourId) return null

  return _.find(formState._tourOptions.value, { id: formState.tourId })
}

const selectTourProductFromTour = (formState) => {
  // This is a lot like selectTourProduct, but
  // here we select the product based on the tour ID
  const selectedTourOption = currentTourOption(formState)

  if (selectedTourOption) {
    formState.tourProductId = selectedTourOption.tourProductId
  }
}

const calculateBookingPrice = AsyncField.createLoadThunk(
  "tourBookings/calculateBookingPrice",
  (action) => `bookingForms.${action.payload.formId}._price`,
  async ({ formId }, thunkAPI) => {
    const bookingData = _.merge(
      getSubmitableGlobalData(thunkAPI.getState()),
      getSubmitableBookingForm(getBookingFormById(thunkAPI.getState().tourBookings, formId))
    )

    const response = await Api.manualBooking.calculateBookingPrice(bookingData)
    const { price } = response.data.data

    if (price.errorMessage.length > 0) {
      return thunkAPI.rejectWithValue({ message: price.errorMessage })
    }

    return { value: price }
  }
)

const metaAction = (action) => ({ payload: action.meta.arg, type: action.type })

const createSetterReducer =
  (stateField, payloadField = stateField) =>
  (state, action) => {
    requireKeys(action.payload, payloadField)
    _.set(state, stateField, _.get(action.payload, payloadField))
    return state
  }

const createFormSetter = (formFinder, setter) => (state, action) => {
  const formState = formFinder(state, action)
  setter(formState, action)
  return state
}

const newPassengerOption = (passenger) => ({
  id: passenger.id.toString(),
  state: LoadState.Ready,
})

const newUnloadedPassengerOption = (passengerId) => ({
  id: passengerId.toString(),
  state: LoadState.Stale,
})

const newBookingForm = (overrides = {}) => {
  // Use the next date if available
  let prefilledTourStartDate = null

  if (
    _.has(overrides, "_nextTourStartDate") &&
    AsyncField.isReady(_.get(overrides, "_nextTourStartDate"))
  ) {
    prefilledTourStartDate = _.get(overrides, "_nextTourStartDate.value")
  }

  const base = {
    formId: undefined,
    tourId: undefined,
    tourProductId: undefined,
    specialRequirements: "",
    waitlist: false,
    tourStartDate: prefilledTourStartDate || new Date().toDateString(),

    passengerIds: [],
    couponCodes: [],

    _passengerOptions: [],

    _port: AsyncField.createField({ defaultValue: null }),
    _price: AsyncField.createField({ defaultValue: 0 }),
    _roomSearch: AsyncField.createField({ defaultValue: [], state: AsyncField.LoadState.Ready }),
    _tourProductOptions: AsyncField.createField({ defaultValue: [] }),
    _tourOptions: AsyncField.createField({ defaultValue: [] }),
    createdAtMillis: Date.now(),
    kind: "TourBooking",
  }

  const nonOverridable = {
    _nextTourStartDate: AsyncField.createField({ defaultValue: null }),
    _prefilledTourStartDate: prefilledTourStartDate,
  }

  const formData = _.merge(base, overrides, nonOverridable)

  // Check for missing passengerOptions
  if (!_.isEmpty(formData.passengerIds)) {
    const missingOptions = _.filter(
      formData.passengerIds,
      (id) => !_.find(formData._passengerOptions, (o) => o.id === id)
    )

    formData._passengerOptions = formData._passengerOptions.concat(
      _.map(missingOptions, newUnloadedPassengerOption)
    )
  }

  return formData
}

export const Slice = createBookingsSlice({
  name: "tourBookings",
  initialState: {
    passengers: {},
  },
  newBookingForm,
  initialize(state, action) {
    if (_.isEmpty(state.bookingForms)) {
      const formId = uuid()
      state.bookingForms[formId] = newBookingForm({ formId })
    }
    return state
  },
  reducers: {
    selectTourStartDate: (state, action) => {
      requireKeys(action.payload, "tourStartDate")

      const form = getBookingFormByAction(state, action)

      form.tourStartDate = action.payload.tourStartDate

      form.tourProductId = undefined
      form.tourId = undefined

      AsyncField.invalidateField(form._tourProductOptions)
      AsyncField.invalidateField(form._tourOptions)
      AsyncField.invalidateField(form._price)
      AsyncField.invalidateField(form._port)
      AsyncField.invalidateField(form._nextTourStartDate)

      // XXX Piggyback on the selectTourStartDate to handle prefilled start date
      if (action.payload.clearPrefilled) {
        form._prefilledTourStartDate = null
      }

      return state
    },
    selectTour: (state, action) => {
      requireKeys(action.payload, "tourId")

      const form = getBookingFormByAction(state, action)

      form.tourId = action.payload.tourId.toString()

      AsyncField.invalidateField(form._price)

      selectTourProductFromTour(form)

      return state
    },
    selectTourProduct: (state, action) => {
      requireKeys(action.payload, "tourProductId")

      const form = getBookingFormByAction(state, action)

      form.tourProductId = action.payload.tourProductId.toString()

      form.tourId = undefined

      AsyncField.invalidateField(form._tourOptions)
      AsyncField.invalidateField(form._price)

      return state
    },
    waitlistBooking: createFormSetter(getBookingFormByAction, createSetterReducer("waitlist")),
    setSpecialRequirements: createFormSetter(
      getBookingFormByAction,
      createSetterReducer("specialRequirements", "value")
    ),
    removePassengers: (state, action) => {
      const form = getBookingFormByAction(state, action)

      const removeIds = action.payload.passengerIds

      form.passengerIds = _.without(form.passengerIds, ...removeIds)

      form._passengerOptions = _.reject(form._passengerOptions, (o) => _.includes(removeIds, o.id))

      AsyncField.invalidateField(form._price)

      return state
    },
    addPassenger: (state, action) => {
      requireKeys(action.payload, "passengerId")

      const form = getBookingFormByAction(state, action)

      const { passengerId } = action.payload

      form.passengerIds = form.passengerIds.concat([passengerId])

      AsyncField.invalidateField(form._price)

      return state
    },
    removePassenger: (state, action) => {
      requireKeys(action.payload, "passengerId")

      const form = getBookingFormByAction(state, action)

      const { passengerId } = action.payload

      form.passengerIds = _.without(form.passengerIds, passengerId)

      AsyncField.invalidateField(form._price)

      return state
    },
    addCoupon: (state, action) => {
      requireKeys(action.payload, "code")

      const form = getBookingFormByAction(state, action)

      const { code } = action.payload

      form.couponCodes = _.union(form.couponCodes, [code])

      AsyncField.invalidateField(form._price)

      return state
    },
    removeCoupon: (state, action) => {
      requireKeys(action.payload, "code")

      const form = getBookingFormByAction(state, action)

      const { code } = action.payload

      form.couponCodes = _.without(form.couponCodes, code)

      AsyncField.invalidateField(form._price)

      return state
    },
  },
  // This is where you specify side-effecty reducers
  extraReducers: {
    ...fetchTourProductOptions.reducers,
    ...fetchTourOptions.reducers,
    ...findRooms.reducers,
    ...calculateBookingPrice.reducers,
    ...fetchPort.reducers,
    ...fetchNextBookableDate.reducers,
    [selectRoomResult.fulfilled]: (state, action) => {
      const form = getBookingFormByAction(state, action)

      _.each(action.payload.passengers, (g) => {
        if (_.has(state.passengers, g.id.toString())) return
        state.passengers[g.id.toString()] = g
      })

      const existingPassengerIds = new Set(_.map(form._passengerOptions, (g) => g.id.toString()))

      const freshPassengerOptions = _.chain(action.payload.passengers)
        .reject((g) => existingPassengerIds.has(g.id.toString()))
        .map(newPassengerOption)
        .value()

      form._passengerOptions = form._passengerOptions.concat(freshPassengerOptions)

      // set default room account to charge
      if (!state.chargedRoomAccount) {
        state.chargedRoomAccount = action.payload.chargedRoomAccount
      }

      // Select all passengers by default
      form.passengerIds = form.passengerIds.concat(
        _.map(freshPassengerOptions, (g) => g.id.toString())
      )
      AsyncField.invalidateField(form._price)

      return state
    },
    [fetchPassengers.pending]: (state, action) => {
      const mAction = metaAction(action)

      const form = getBookingFormByAction(state, mAction)

      _.each(form._passengerOptions, (g) => {
        if (!_.includes(mAction.payload.passengerIds, g.id)) {
          return
        }
        g.state = LoadState.Loading
      })

      return state
    },
    [fetchPassengers.rejected]: (state, action) => {
      const mAction = metaAction(action)

      const form = getBookingFormByAction(state, mAction)

      _.each(form._passengerOptions, (g) => {
        if (!_.includes(mAction.payload.passengerIds, g.id)) {
          return
        }
        g.state = LoadState.Failed
      })

      return state
    },
    [fetchPassengers.fulfilled]: (state, action) => {
      const form = getBookingFormByAction(state, action)

      _.each(action.payload.passengers, (g) => {
        if (_.has(state.passengers, g.id.toString())) return
        state.passengers[g.id.toString()] = g
      })

      // Mark passengerOptions as loaded
      _.each(form._passengerOptions, (g) => {
        if (!_.includes(action.payload.passengerIds, g.id)) return
        g.state = LoadState.Ready
      })

      return state
    },
  },
})

export const {
  initialize,
  selectTourStartDate,
  selectTourProduct,
  selectTour,
  addPassenger,
  removePassenger,
  removePassengers,
  setSpecialRequirements,
  waitlistBooking,
  copyBookingForm,
  removeBookingForm,
  addCoupon,
  removeCoupon,
} = Slice.actions

export {
  findRooms,
  selectRoomResult,
  fetchTourProductOptions,
  fetchTourOptions,
  fetchPassengers,
  fetchPort,
  calculateBookingPrice,
  fetchNextBookableDate,
}

export { LoadState }

export default Slice
