import React from "react"

import _ from "lodash"

import Field from "./Field"

import { getFields } from "../utils"

const Fields = ({ namePrefix = undefined, ...props }): JSX.Element => {
  const fieldName = (name: string) => {
    if (namePrefix) {
      return `${namePrefix}.${name}`
    }
    return name
  }

  return (
    <>
      {_.map(getFields(props), (field, name) => (
        <Field key={name} name={fieldName(name)} field={field} />
      ))}
    </>
  )
}

export default Fields
