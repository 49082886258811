import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import { newComponent } from "@/onboarding/features/product/models/Component"
import FormikBootstrap from "@/components/formik-bootstrap"
import Select from "@/components/input/Select"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"

import ComponentDisplay from "./ComponentDisplay"
import RateInput from "./RateInput"

const COMPONENT_TYPES = [
  "simple",
  "transport",
  "venue",
  "refreshment",
  "restaurant",
  "entertainment",
  "guide",
]

const Form = ({ formValues, api, ports, defaultRateCategories, defaultTiers }): JSX.Element => {
  const dispatch = useDispatch()

  const normalizedFormValues = _.mapValues(formValues, (v) => {
    if (v === "true") {
      return true
    }

    if (v === "false") {
      return false
    }

    return v
  })

  return (
    <Formik
      initialValues={normalizedFormValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        const document = _.cloneDeep(_.omit(values, ["enabled", "portId"]))

        const params = {
          name: document.name,
          ComponentType: document.kind,
          portId: values.portId,
          enabled: values.enabled,
          document,
        }

        api(params).then(newSuccessHandler(dispatch)).catch(newAxiosErrorHandler(dispatch))

        setSubmitting(false)
      }}
    >
      {({ values, setValues, handleSubmit, isSubmitting }) => (
        <div className="d-flex flex-column gap-2">
          <Row>
            <Col md={3}>
              <Select
                name="componentType"
                label="Type"
                value={values.kind}
                onChange={({ target }) => {
                  const newBase = newComponent({ kind: target.value, defaultRateCategories })
                  _.assign(newBase, { enabled: values.enabled, portId: values.portId })
                  setValues(newBase)
                }}
              >
                {COMPONENT_TYPES.map((kind) => (
                  <option key={kind} value={kind}>
                    {_.startCase(kind)}
                  </option>
                ))}
              </Select>
            </Col>
            <Col md={3}>
              <FormikBootstrap.Select name="portId" label="Port">
                {ports.map((port) => (
                  <option key={port.id} value={port.id}>
                    {port.name}
                  </option>
                ))}
              </FormikBootstrap.Select>
            </Col>
          </Row>
          <Row>
            <FormikBootstrap.Checkbox name="enabled" label="Enabled?" />
          </Row>
          <div>
            <h2 className="h5">Component Details</h2>
            <ComponentDisplay component={values} />
          </div>
          <div>
            <h2 className="h5">Rates</h2>
            <RateInput defaultRateCategories={defaultRateCategories} defaultTiers={defaultTiers} />
          </div>
          <div>
            <Button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default Form
