import React, { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { isBlank } from "@/utils"

const ReasonModal = ({
  show,
  onHide,
  reasonPrompt,
  header,
  buttonText,
  onSubmitCallback,
  issues = [],
}: {
  show: boolean
  onHide: () => void
  reasonPrompt: string
  header: string
  buttonText: string
  onSubmitCallback: (reason: string) => void
  issues?: { count: string; description: string }[]
}): JSX.Element => {
  const [reason, setReason] = useState("")

  const onSubmit = () => {
    onSubmitCallback(reason)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="reason-modal-title" centered>
      <Modal.Header>
        <Modal.Title id="reason-modal-title">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <>
              {!isBlank(issues) ? (
                <ul className="list-group list-group-numbered mb-3">
                  {issues.map((issue, index) => (
                    <li key={index} className="list-group-item">
                      {issue.description}
                      <span className="badge bg-primary rounded-pill ms-2">{issue.count}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
              <Form.Group>
                <Form.Label>{reasonPrompt}</Form.Label>
                <Form.Control
                  type="text"
                  name="reason"
                  placeholder={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                />
              </Form.Group>
            </>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReasonModal
