import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import { isBlank } from "@/utils"
import Routes from "@/routes"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column gap-2">
    <h5>Edit Details</h5>
    <Row>
      <Col md={3}>
        <FormikBootstrap.TextInput name="name" label="Name" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.NumberInput name="capacity" label="Capacity" />
      </Col>
      <Col md={6}>
        <FormikBootstrap.TextInput name="description" label="Menu Description" />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormikBootstrap.FileInput name="menuSrc" label="Sample Menu" />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="airConditioned" label="Air Conditioned?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="waiterService" label="Waiter Service?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="buffetService" label="Buffet Service?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox
          name="specialDietaryMeals"
          label="Special Dietary Meals Offered By Request?"
        />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="vegetarianMeals" label="Vegetarian Meals Offered?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="wheelchairAccessible" label="Wheelchair Accessible?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="rampsAvailable" label="Wheelchair Ramps Available?" />
      </Col>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="liftsAvailable" label="Wheelchair Lifts Available?" />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormikBootstrap.Checkbox name="restroomsAvailable" label="Restrooms Available?" />
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <h5 className="mt-4">Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <h5>Details</h5>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Capacity</th>
          <th>Sample Menu</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description">
            <TextDisplay value={component.description} />
          </td>
          <td aria-label="Capacity">
            <TextDisplay value={component.capacity} />
          </td>
          <td aria-label="Same Menu">
            {isBlank(component.menuSrc) ? (
              <>N/A</>
            ) : (
              <a
                href={Routes.file_holder_path({ id: component.menuSrc })}
                download
                className="text-underline"
              >
                Download
              </a>
            )}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className="text-wrap">
          <th>Air Conditioned?</th>
          <th>Waiter Service?</th>
          <th>Buffet Service?</th>
          <th>Special Dietary Meals Offered By Request?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Air Conditioned?">
            <TextDisplay value={component.airConditioned} />
          </td>
          <td aria-label="Waiter Service?">
            <TextDisplay value={component.waiterService} />
          </td>
          <td aria-label="Buffet Service?">
            <TextDisplay value={component.buffetService} />
          </td>
          <td aria-label="Special Dietary Meals Offered By Request?">
            <TextDisplay value={component.specialDietaryMeals} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className="text-wrap">
          <th>Vegetarian Meals Offered?</th>
          <th>Wheelchair Accessible?</th>
          <th>Wheelchair Ramps Available?</th>
          <th>Wheelchair Lifts Available?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Vegetarian Meals Offered?">
            <TextDisplay value={component.vegetarianMeals} />
          </td>
          <td aria-label="Wheelchair Accessible?">
            <TextDisplay value={component.wheelchairAccessible} />
          </td>
          <td aria-label="Wheelchair Ramps Available?">
            <TextDisplay value={component.rampsAvailable} />
          </td>
          <td aria-label="Wheelchair Lifts Available?">
            <TextDisplay value={component.liftsAvailable} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className="text-wrap">
          <th colSpan={4}>Restrooms Available?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={4} aria-label="Restrooms Available?">
            <TextDisplay value={component.restroomsAvailable} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <Col md={3}>
        <h5 className="mt-2">Rates</h5>
        <Pricing.View value={component.ratesheet} />
      </Col>
    )}
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
