import React from "react"
import { Form, Col, Button } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { getOperatorCheckIn } from "@/operator_check_in/selectors"
import { setTicketScan, submitTicketScan } from "@/operator_check_in/Slice"

const CheckinsScanner = (): JSX.Element => {
  const dispatch = useDispatch()
  const { ticketScan } = useSelector(getOperatorCheckIn)

  const submitScan = async (ticketScan) => {
    dispatch(submitTicketScan({ scan: ticketScan }))
  }

  return (
    <div>
      <Col md={3}>
        <Form.Group className="mb-3 mt-md ">
          <Form.Label>Ticket Reader</Form.Label>
          <Form.Control
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                submitScan(ticketScan)
              }
            }}
            autoFocus
            value={ticketScan}
            onChange={(e) => {
              dispatch(setTicketScan({ ticketScan: e.target.value }))
            }}
            placeholder="Ticket ID"
          />
        </Form.Group>
      </Col>
      <Button onClick={() => submitScan(ticketScan)}>submit scan</Button>
    </div>
  )
}

export default CheckinsScanner
