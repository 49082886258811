import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Table, Button, Form } from "react-bootstrap"
import TextInput from "@/components/input/Text"
import { useModal } from "@/helpers/useModal"
import { isBlank } from "@/utils"
import NumberInput from "@/components/input/Number"
import AuthDescriptorContext from "@/auth_descriptor/Context"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import DepartuePropertyCheckBox from "./DeparturePropertyCheckbox"
import ReasonModal from "./ReasonModal"
import {
  onBulkEditInputChange,
  onCancelBulkEdit,
  setShowBulkEditForm,
  updateDepartures,
} from "../Slice"
import {
  getView,
  getShowBulkEditForm,
  getbulkDepartureEdit,
  getSelectedProperties,
} from "../selectors"

const EditDepartures = (): JSX.Element => {
  const { isTourUi } = useContext(AuthDescriptorContext)

  const view = useSelector(getView)
  const showBulkEditForm = useSelector(getShowBulkEditForm)
  const bulkDepartureEdit = useSelector(getbulkDepartureEdit)
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [reasonPrompt, setreasonPrompt] = useState("")
  const [allowSubmit, setAllowSubmit] = useState(false)

  const selectedProperties = useSelector(getSelectedProperties)
  const existingDepartures = isTourUi
    ? view.inventory.allocationTableData.departures
    : view.inventory.allocationTableData.cruiseDepartures

  useEffect(() => {
    setAllowSubmit(Object.values(selectedProperties).includes(true))
  }, [selectedProperties])

  const filterDepartures = (departures) =>
    departures.map((departure) => {
      const filteredDeparture = { departureId: departure.id }
      for (const key in selectedProperties) {
        if (selectedProperties[key]) {
          filteredDeparture[key] = bulkDepartureEdit[key]
        }
      }
      return filteredDeparture
    })

  const onBulkEditSubmitCallback = async (reason) => {
    const departuresToUpdate = filterDepartures(existingDepartures)
    dispatch(setShowBulkEditForm({ showFormState: !showBulkEditForm }))
    dispatch(onCancelBulkEdit())
    await dispatch(updateDepartures({ departures: departuresToUpdate, reason }))
  }

  const toggleSubmitBulkDeparturesModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Update"
      onSubmitCallback={onBulkEditSubmitCallback}
      header={header}
      {...props}
    />
  ))

  const onSubmit = async () => {
    setHeader("You are updating departures in bulk")
    setreasonPrompt("Please provide a reason for this update")
    toggleSubmitBulkDeparturesModal()
  }

  const onCruiselineSubmit = async () => {
    setHeader("You are updating your departure requests in bulk")
    setreasonPrompt("Please provide a reason for this update")
    toggleSubmitBulkDeparturesModal()
  }

  return (
    !isBlank(existingDepartures) &&
    showBulkEditForm && (
      <>
        <div className="mt-3">
          <CruiseLineOnly>
            <h5 className="d-inline">Edit departure requests in bulk</h5>
            <i
              title="On submit these changes will be applied to all the exisitng departure requests included in the allocation table below"
              className="fas fa-info-circle d-inline ms-2 cursor-pointer"
            />
          </CruiseLineOnly>
          <OperatorOnly>
            <h5 className="d-inline">Edit departures in bulk</h5>
            <i
              title="On submit these changes will be applied to all the departures on the resulting days in the
              allocation table below"
              className="fas fa-info-circle d-inline ms-2 cursor-pointer"
            />
          </OperatorOnly>
        </div>
        <div style={{ width: "50%" }}>
          <Table className="mt-2">
            <thead className="thead-light">
              <tr>
                <th> Start Time Shift </th>
                <th> Departure </th>
                <th> Duration </th>
                <th> Min </th>
                <th> Max </th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <>
                    <div className="d-flex align-items-center">
                      <Form.Group className="ms-n3 me-2">
                        <DepartuePropertyCheckBox propertyName="startTimeShiftMinutes" />
                      </Form.Group>
                      <NumberInput
                        style={{ maxWidth: "120px" }}
                        className="mb-2 d-inline"
                        name="description"
                        label=""
                        placeholder={0}
                        value={bulkDepartureEdit.startTimeShiftMinutes}
                        onChange={(e) =>
                          dispatch(
                            onBulkEditInputChange({
                              property: "startTimeShiftMinutes",
                              input: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                    <sub className="ms-1">(Shift in minutes)</sub>
                  </>
                </td>
                <td>
                  <>
                    <div className="d-flex align-items-center">
                      <Form.Group className="ms-n3 me-2">
                        <DepartuePropertyCheckBox propertyName="departureTime" />
                      </Form.Group>
                      <TextInput
                        style={{ maxWidth: "120px" }}
                        className="mb-2"
                        name="description"
                        label=""
                        placeholder={
                          bulkDepartureEdit.departureTime === ""
                            ? "00:00"
                            : bulkDepartureEdit.departureTime
                        }
                        value={bulkDepartureEdit.departureTime}
                        onChange={(e) =>
                          dispatch(
                            onBulkEditInputChange({
                              property: "departureTime",
                              input: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                  </>
                </td>
                <td>
                  <>
                    <div className="d-flex align-items-center">
                      <Form.Group className="ms-n3 me-2">
                        <DepartuePropertyCheckBox propertyName="durationInMinutes" />
                      </Form.Group>
                      <NumberInput
                        style={{ maxWidth: "120px" }}
                        className="mb-2"
                        name="description"
                        label=""
                        suffix="min"
                        placeholder={0}
                        value={bulkDepartureEdit.durationInMinutes}
                        onChange={(e) =>
                          dispatch(
                            onBulkEditInputChange({
                              property: "durationInMinutes",
                              input: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                  </>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <Form.Group className="ms-n3 me-2">
                      <DepartuePropertyCheckBox propertyName="minimumCapacity" />
                    </Form.Group>
                    <NumberInput
                      style={{ maxWidth: "120px" }}
                      className="mb-2"
                      name="description"
                      label=""
                      placeholder={0}
                      value={bulkDepartureEdit.minimumCapacity}
                      onChange={(e) =>
                        dispatch(
                          onBulkEditInputChange({
                            property: "minimumCapacity",
                            input: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <Form.Group className="ms-n3 me-2">
                      <DepartuePropertyCheckBox propertyName="maximumCapacity" />
                    </Form.Group>
                    <NumberInput
                      style={{ maxWidth: "120px" }}
                      className="mb-2"
                      name="description"
                      label=""
                      placeholder={0}
                      value={bulkDepartureEdit.maximumCapacity}
                      onChange={(e) =>
                        dispatch(
                          onBulkEditInputChange({
                            property: "maximumCapacity",
                            input: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <CruiseLineOnly>
                    <Button
                      variant="primary"
                      disabled={!allowSubmit}
                      onClick={() => onCruiselineSubmit()}
                    >
                      Submit
                    </Button>
                  </CruiseLineOnly>
                  <OperatorOnly>
                    <Button variant="primary" disabled={!allowSubmit} onClick={() => onSubmit()}>
                      Submit
                    </Button>
                  </OperatorOnly>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    )
  )
}

export default EditDepartures
