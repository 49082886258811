import React, { useState } from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import _ from "lodash"

import { edit_departure_time_tour_bookings_path } from "@/routes"
import AuthenticityToken from "@/components/AuthenticityToken"
import Tour from "@/dashboard/models/Tour"
import TourAvailability from "@/components/TourAvailability"
import { useDelayedState } from "@/helpers/useDelayedState"
import { ButtonVisiblityToggle } from "@/components/VisibilityToggles"
import Api from "@/api"

const BookingTableRow = ({ booking, isChecked, onToggle }) => (
  <tr>
    <td className="p-1 align-middle">
      <input type="hidden" name="bookings[][booking_id]" value={booking.bookingId} />
      <Form.Group>
        <Form.Check
          type="checkbox"
          name="bookings[][change_tour]"
          value="1"
          label={booking.reference}
          defaultChecked={isChecked}
          onChange={onToggle}
        />
      </Form.Group>
    </td>
  </tr>
)

const BookingTable = ({ bookings }) => {
  const [bookingChecks, setBookingChecked] = useState(
    _.fromPairs(_.map(bookings, (b) => [b.bookingId.toString(), true]))
  )

  const bookingRows = _.map(bookings, (b) => {
    const isChecked = bookingChecks[b.bookingId]

    return (
      <BookingTableRow
        key={b.bookingId}
        isChecked={isChecked}
        onToggle={() => {
          const newToggled = {}
          newToggled[b.bookingId] = !isChecked
          setBookingChecked(_.assign({}, bookingChecks, newToggled))
        }}
        booking={b}
      />
    )
  })

  return (
    <ButtonVisiblityToggle variant="secondary" showLabel="Show Bookings" hideLabel="Hide Bookings">
      <Table striped>
        <thead>
          <tr>
            <th>Select which bookings to move</th>
          </tr>
        </thead>
        <tbody>{bookingRows}</tbody>
      </Table>
    </ButtonVisiblityToggle>
  )
}

const EditDepartureTimeModal = ({
  show,
  onHide,
  tour,
}: {
  show: boolean
  onHide: () => void
  tour: Tour
}): JSX.Element => {
  const [selectedTour, setSelectedTour] = useState(undefined)

  const tourOptionsControl = useDelayedState({
    initialValue: [],
    loadFn(control) {
      Api.dashboardView
        .alternativeTours({ tourId: tour.tourId })
        .then((response) => {
          control.setSuccess(
            _.map(response.data.data.tourOptions, (o) => ({
              id: o.tourId,
              label: o.timeslot,
              closedForSales: o.closedForSales,
            }))
          )
        })
        .catch((error) => {
          control.setFailed()
        })
    },
  })

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="duplicate-tour-modal-title"
      centered
    >
      <Form action={edit_departure_time_tour_bookings_path()} method="post">
        <AuthenticityToken />
        <Modal.Header>
          <Modal.Title id="duplicate-tour-modal-title">
            Move Bookings For {tour.name} @ {tour.timeslot}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>Alternative Departure Times</Form.Label>
            <TourAvailability
              tourOptions={tourOptionsControl.value}
              loadState={tourOptionsControl.state}
              inputName="tour_id"
              selected={selectedTour}
              onChange={(value) => {
                setSelectedTour(value)
              }}
              onReload={tourOptionsControl.reload}
              required
            />
          </Form.Group>
          <Form.Group>
            <BookingTable bookings={tour.confirmedBookings.concat(tour.waitlistedBookings)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type="submit" disabled={!selectedTour}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditDepartureTimeModal
