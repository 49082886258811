import React from "react"
import { ListGroup, Row, Col, Image } from "react-bootstrap"

const Guest = ({ guest, isCardHolder, includeRoom = false }) => (
  <Row className="align-items-center" key={guest.id}>
    <Col className="col-auto">
      <div className="avatar">
        <Image src={guest.profilePicturePath} roundedCircle />
      </div>
    </Col>
    <Col className="ms--2">
      <h4 className="h6 mb-0">
        {guest.name}
        {includeRoom ? ` (${guest.roomNumber})` : ""}
      </h4>
      {isCardHolder && (
        <small>
          <i>Card Holder</i>
        </small>
      )}
    </Col>
  </Row>
)

const RoomResultItem = ({ room, ...other }): JSX.Element => {
  const hasPaymentMethod = room.roomAccount && room.roomAccount.hasPaymentMethod

  const hasPayer = room.roomAccount && room.roomAccount.payer

  let paymentMethodStatus = null
  if (hasPaymentMethod) {
    paymentMethodStatus = (
      <span>
        <span className="fas fa-check-circle text-success ms-2" />
      </span>
    )
  } else {
    paymentMethodStatus = (
      <span>
        <span className="fas fa-times-circle text-danger ms-2" />{" "}
        <span> No Payment Method Loaded </span>
      </span>
    )
  }

  return (
    <div key={room.label}>
      <div>
        <span className="display-5">{room.label}</span>
        {paymentMethodStatus}
        {hasPayer && <Guest guest={room.roomAccount.payer} isCardHolder={hasPaymentMethod} />}
      </div>
    </div>
  )
}

const RoomResultListGroup = ({ rooms }): JSX.Element => (
  <ListGroup>
    {rooms.map((room) => (
      <RoomResultItem key={room.roomNumber} room={room} />
    ))}
  </ListGroup>
)

export default RoomResultListGroup
export { RoomResultItem, Guest }
