import React, { useState } from "react"

import Tour from "@/dashboard/models/Tour"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { tour_path } from "@/routes"
import Hidden from "@/components/Hidden"
import TourTools from "@/dashboard/components/tour_list_card/TourTools"
import TourCodeBadge from "@/dashboard/components/tour_list_card/TourCodeBadge"

import PrivateVillaBookingTable from "./PrivateVillaBookingTable"

const PrivateVillaRow = ({ tour }: { tour: Tour }): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  const toggleRow = () => setExpanded(!expanded)

  const guestCount =
    tour.waitlistedGuests > 0
      ? `${tour.confirmedGuests} (${tour.waitlistedGuests})`
      : tour.confirmedGuests

  const tourRow = (
    // XXX is there a cleaner way to handle the click event here?
    <tr>
      <td onClick={toggleRow} data-bs-toggle="tooltip" title={tour.components}>
        <span className="d-block">
          <a className="hover-underline" href={tour_path({ id: tour.tourId })}>
            {tour.name}
          </a>
        </span>
        <span className="d-inline-flex gap-1">
          <TourCodeBadge tour={tour} />
        </span>
        <Hidden if={tour.selfOperated}>
          <span className="d-inline-flex gap-1 ms-1">{tour.operators.join(", ")}</span>
        </Hidden>
      </td>
      <OperatorOnly>
        <td onClick={toggleRow}>
          {tour.manager} ({tour.shipName})
        </td>
      </OperatorOnly>
      <td onClick={toggleRow} className="text-right">{`${tour.maximumPax}`}</td>
      <td onClick={toggleRow} className="text-right">
        {guestCount}
      </td>
      <td onClick={toggleRow}>{tour.cancellationCutoffTime}</td>
      <td onClick={toggleRow} className="text-right">
        {tour.retailPrice}
      </td>
      <td className="pe-2">
        <TourTools tour={tour} />
      </td>
    </tr>
  )

  if (!expanded) {
    return tourRow
  }

  let bookingContent

  if (tour.bookings.length === 0) {
    bookingContent = (
      <div className="mx-4 my-2">
        <span>No bookings for this villa</span>
      </div>
    )
  } else {
    bookingContent = <PrivateVillaBookingTable bookings={tour.bookings} />
  }

  return (
    <>
      {tourRow}
      <tr>
        <td colSpan={8} className="p-0">
          {bookingContent}
        </td>
      </tr>
    </>
  )
}

export default PrivateVillaRow
