/* eslint-disable react/jsx-props-no-spreading */
import React from "react"

const HiddenInput = ({
  name,
  value,
  ...props
}: {
  name: string
  value: string | number | readonly string[]
}): JSX.Element => <input type="hidden" name={name} value={value} readOnly {...props} />

export default HiddenInput
