import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getFilteredCruiseBrandAwardSetTotal,
  getPriceAnalysis,
  getProductAwardSetTotal,
  cruiseBrandExpanded,
  getCruiseBrandProducts,
} from "@/onboarding/features/price_analysis/selectors"
import { formatMoney } from "@/helpers/money"
import {
  expandCruiseBrand,
  minimizeCruiseBrand,
  selectProduct,
} from "@/onboarding/features/price_analysis/Slice"

const CruiseBrandAwardSetTotal = ({ filteredBrandProducts, awardSetId }): JSX.Element => {
  const { amount, currency } = useSelector(
    getFilteredCruiseBrandAwardSetTotal(filteredBrandProducts)(awardSetId)
  )

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(amount, currency)}</>
}

const ProductAwardSetTotal = ({ productId, awardSetId }): JSX.Element => {
  const { amount, currency } = useSelector(getProductAwardSetTotal(productId)(awardSetId))

  // TODO Return green or red text based on minimum/maximum in the row
  return <>{formatMoney(amount, currency)}</>
}

const CruiseBrandRow = ({ cruiseBrand, awardSets, selectedAwardSetId }): JSX.Element => {
  const dispatch = useDispatch()
  const selected = useSelector(cruiseBrandExpanded(cruiseBrand.id))
  const { selectedOperatorId, selectedPortId } = useSelector(getPriceAnalysis)
  const cruiseBrandProducts = useSelector(getCruiseBrandProducts(cruiseBrand.id))

  const filteredBrandProducts = cruiseBrandProducts.filter((product) => {
    const matchesPort = !selectedPortId || product.port.id === selectedPortId

    const matchesOperator =
      !selectedOperatorId ||
      product.biddingOperators.some((operator) => operator.id === selectedOperatorId)

    return matchesPort && matchesOperator
  })

  const onClick = selected
    ? () => dispatch(minimizeCruiseBrand())
    : () => dispatch(expandCruiseBrand({ cruiseBrandId: cruiseBrand.id }))

  const cellClass = (awardSetId) =>
    awardSetId === selectedAwardSetId ? "text-right bg-secondary" : "text-right"

  let row

  if (cruiseBrandProducts.length > 0) {
    row = (
      <tr className="cursor-pointer" onClick={onClick}>
        <th scope="row">
          {`${cruiseBrand.name} `}
          <span className="badge bg-primary">{filteredBrandProducts.length}</span>
        </th>
        <td />
        {awardSets.map(({ id }) => (
          <td key={id} className={cellClass(id)}>
            <CruiseBrandAwardSetTotal
              filteredBrandProducts={filteredBrandProducts}
              awardSetId={id}
            />
          </td>
        ))}
      </tr>
    )
  } else {
    row = (
      <tr>
        <th scope="row">{cruiseBrand.name}</th>
        <td />
        {awardSets.map(({ id }) => (
          <td key={id} className={cellClass(id)}>
            <CruiseBrandAwardSetTotal
              filteredBrandProducts={filteredBrandProducts}
              awardSetId={id}
            />
          </td>
        ))}
      </tr>
    )
  }

  if (selected) {
    return (
      <>
        {row}
        {filteredBrandProducts.map((product) => (
          <tr key={product.id}>
            <th
              scope="row"
              className="text-underline cursor-pointer"
              onClick={() => dispatch(selectProduct({ productId: product.id }))}
            >
              <div className="ms-3">{product.code}</div>
            </th>
            <td>{product.name}</td>
            {awardSets.map(({ id }) => (
              <td key={id} className={cellClass(id)}>
                <ProductAwardSetTotal productId={product.id} awardSetId={id} />
              </td>
            ))}
          </tr>
        ))}
      </>
    )
  }

  return row
}

export default CruiseBrandRow
