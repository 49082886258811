import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import { ViewTypes } from "@/onboarding/features/price_analysis/Slice"
import { getPriceView, getSelectedProduct } from "@/onboarding/features/price_analysis/selectors"
import { formatMoney } from "@/helpers/money"

const PlainBreakdown = ({ priceBreakdown, currency }) => (
  <div className="d-flex flex-column">
    {_.map(priceBreakdown, (value, key) => (
      <div className="d-flex justify-content-between" key={key}>
        <span>
          <b>{_.startCase(key)}</b>
        </span>
        <span>{formatMoney(value, currency)}</span>
      </div>
    ))}
  </div>
)

const toVarianceSpan = (value, currency) => {
  if (value < 0) {
    return <span className="text-success">{formatMoney(value, currency)}</span>
  }

  if (value > 0) {
    return <span className="text-danger">{formatMoney(value, currency)}</span>
  }

  return <span>-</span>
}

const VarianceBreakdown = ({ priceBreakdown, currency }) => (
  <div className="d-flex flex-column">
    {_.map(priceBreakdown, (value, key) => (
      <div className="d-flex justify-content-end" key={key}>
        {toVarianceSpan(value, currency)}
      </div>
    ))}
  </div>
)

const PriceDisplay = ({
  expectedTotal = 0,
  expectedTotalVariance = 0,
  priceBreakdown = {},
  priceBreakdownVariance = {},
  originalValue = false,
}): JSX.Element => {
  const { currency } = useSelector(getSelectedProduct)
  const priceView = useSelector(getPriceView)

  switch (priceView) {
    case ViewTypes.AGGREGATE:
      return <div className="text-right">{formatMoney(expectedTotal, currency)}</div>
    case ViewTypes.AGGREGATE_VARIANCE:
      if (originalValue) {
        return <div className="text-right">{formatMoney(expectedTotal, currency)}</div>
      }

      if (expectedTotalVariance < 0) {
        return (
          <div className="text-right text-success">
            {formatMoney(expectedTotalVariance, currency)}
          </div>
        )
      }
      if (expectedTotalVariance > 0) {
        return (
          <div className="text-right text-danger">
            {formatMoney(expectedTotalVariance, currency)}
          </div>
        )
      }
      return <div className="text-right">{formatMoney(expectedTotalVariance, currency)}</div>

    case ViewTypes.BREAKDOWN:
      return <PlainBreakdown priceBreakdown={priceBreakdown} currency={currency} />
    case ViewTypes.BREAKDOWN_VARIANCE:
      if (originalValue) {
        return <PlainBreakdown priceBreakdown={priceBreakdown} currency={currency} />
      }

      return <VarianceBreakdown priceBreakdown={priceBreakdownVariance} currency={currency} />
    default:
      return null
  }
}

export default PriceDisplay
