import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductFilters, getForm } from "@/onboarding/features/product/selectors"
import SingleSelect from "@/components/input/SingleSelect"
import { fetchProduct } from "@/onboarding/features/product/Slice"

const Dropdown: FC = () => {
  const { seasonProductId } = useSelector(getForm)

  const productFilters = useSelector(getProductFilters)
  const dispatch = useDispatch()

  const onNewSeason = (newProductId) => {
    const newProductFilters = {
      ...productFilters,
      id: newProductId,
    }
    dispatch(fetchProduct(newProductFilters))
  }

  return (
    <SingleSelect
      name="seasonProductId"
      value={seasonProductId.value}
      onChange={(e) => onNewSeason(e.target.value)}
      options={seasonProductId.options}
      label={seasonProductId.label}
    />
  )
}

export default Dropdown
