import React, { FC, useContext } from "react"

import AuthDescriptorContext from "./Context"

const CruiseLineOnly: FC = ({ children }) => {
  const { isCruiseLineUi } = useContext(AuthDescriptorContext)

  if (!isCruiseLineUi) {
    return null
  }

  return <>{children}</>
}

export default CruiseLineOnly
