import React from "react"
import {
  OverlayTrigger,
  Popover,
  ListGroup,
  ListGroupItem,
  PopoverHeader,
  PopoverBody,
  Table,
} from "react-bootstrap"
import _ from "lodash"

import { tour_booking_path } from "@/routes"
import Booking, { ReservationStates } from "@/dashboard/models/Booking"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"

import PassengerInfo from "./PassengerInfo"
import BookingDocuments from "./BookingDocuments"
import ConfirmedBookingTools from "./ConfirmedBookingTools"
import BookingBadge from "./BookingBadge"
import WaitlistedBookingTools from "./WaitlistedBookingTools"
import CancelledBookingTools from "./CancelledBookingTools"

const ConflictingBookingsIcon = ({ booking }) => {
  const thStyle = {
    paddingTop: 4,
    paddingLeft: 0,
    paddingBottom: 4,
    paddingRight: 2,
  }

  if (booking.conflictingBookings.length > 0) {
    const conflictHover = (
      <Popover id={`${booking.reference}-conflicts-popover`}>
        <PopoverHeader className="text-white">Conflicting Bookings</PopoverHeader>
        <PopoverBody>
          <Table size="sm" className="table-pad-sm table-flush text-white">
            <tbody>
              {_.map(booking.conflictingBookings, (b) => (
                <React.Fragment key={b.reference}>
                  <tr key={`${b.reference}-ref`}>
                    <th style={thStyle}>Ref</th>
                    <td>{b.reference}</td>
                  </tr>
                  <tr key={`${b.reference}-tour`}>
                    <th style={thStyle}>Tour</th>
                    <td>{b.tour.name}</td>
                  </tr>
                  <tr key={`${b.reference}-retail`}>
                    <th style={thStyle}>Retail</th>
                    <td>{b.retailPrice}</td>
                  </tr>
                  <tr key={`${b.reference}-spacer`}>
                    <td colSpan={2}>
                      <br />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </PopoverBody>
      </Popover>
    )

    return (
      <OverlayTrigger
        key={`${booking.reference}-icon`}
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={conflictHover}
      >
        <i className="ms-1 bi bi-calendar2-x-fill" />
      </OverlayTrigger>
    )
  }

  return null
}

const BookingRow = ({ booking }: { booking: Booking }): JSX.Element => {
  let toolsAction
  let badge = null

  if (booking.reservationState === ReservationStates.CONFIRMED) {
    toolsAction = <ConfirmedBookingTools booking={booking} />
  } else if (booking.reservationState === ReservationStates.WAITLISTED) {
    toolsAction = <WaitlistedBookingTools booking={booking} />
    badge = <BookingBadge variant="warning" text="waitlist" />
  } else if (booking.reservationState === ReservationStates.CANCELLED) {
    toolsAction = <CancelledBookingTools booking={booking} />
    badge = <BookingBadge variant="danger" text="cancelled" />
  }

  const passengerHover = (
    <Popover id={`${booking.reference}-other-passenger-popover`}>
      <PopoverHeader className="text-white">Other Guests</PopoverHeader>
      <PopoverBody>
        <ListGroup>
          {booking.otherPassengers.map((passenger) => (
            <ListGroupItem key={passenger.email}>
              <PassengerInfo passenger={passenger} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </PopoverBody>
    </Popover>
  )

  return (
    <tr>
      <td>
        <div className="d-inline-flex flex-column">
          <div>
            <a className="hover-underline" href={tour_booking_path({ id: booking.bookingId })}>
              {booking.reference}
            </a>
            <ConflictingBookingsIcon booking={booking} />
          </div>
          {badge}
        </div>
      </td>
      <td>
        <OverlayTrigger trigger="click" placement="bottom" overlay={passengerHover}>
          <div>
            <span className="d-block">{booking.leadPassenger.name}</span>
            <span className="d-block">{booking.leadPassenger.roomNumber}</span>
          </div>
        </OverlayTrigger>
      </td>
      <td className="text-right">{booking.guests}</td>
      <td className="text-right">{booking.netPrice}</td>
      <td className="text-right">{booking.retailPrice}</td>
      <td>
        <div className="d-flex gap-2 justify-content-end">
          <BookingDocuments booking={booking} />
          <CruiseLineOnly>{toolsAction}</CruiseLineOnly>
        </div>
      </td>
    </tr>
  )
}

export default BookingRow
