import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectBiddingRegion } from "@/onboarding/features/price_analysis/Slice"
import { getBiddingRegions, getPriceAnalysis } from "@/onboarding/features/price_analysis/selectors"

const BiddingRegionDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedBiddingRegionId } = useSelector(getPriceAnalysis)
  const biddingRegions = useSelector(getBiddingRegions)

  return (
    <Form.Group>
      <Form.Label>Bidding Region</Form.Label>
      <Form.Control
        as="select"
        value={selectedBiddingRegionId}
        onChange={(e) => dispatch(selectBiddingRegion({ selectedBiddingRegionId: e.target.value }))}
      >
        {biddingRegions.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default BiddingRegionDropdown
