import _ from "lodash"

import { getBookings } from "@/manual_booking/reservationSystems"
import { fetchStaleTimelines } from "@/manual_booking/VoyageTimelineActions"

import { initializeForm, clearChargedRoomAccount } from "./Slice"

// XXX Dependent on knowning name of top-level slice
const autorun = (props, dispatch, getState) => {
  const { manualBooking } = getState()

  const clearRoomAccountOnNoBookings = () => {
    if (_.isEmpty(getBookings(getState())) && manualBooking.chargedRoomAccount) {
      dispatch(clearChargedRoomAccount())
    }
  }

  const clearRoomOnNoGuests = () => {
    if (_.isEmpty(_.filter(getBookings(getState()), (b) => b.hasGuests))) {
      dispatch(clearChargedRoomAccount())
    }
  }

  const initializeBookingForm = () => {
    if (!manualBooking.initialized) {
      dispatch(initializeForm(props.form))
    }
  }

  initializeBookingForm()
  clearRoomAccountOnNoBookings()
  fetchStaleTimelines(getState(), dispatch)
  clearRoomOnNoGuests()
}

export default autorun
