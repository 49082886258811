import React from "react"

const AttrIcon = ({ icon }) => <i className={`me-1 ${icon}`} />

const VehicleAttribute = ({ icon, text }) => (
  <span className="me-1">
    <AttrIcon icon={icon} />
    {text}
  </span>
)

export default VehicleAttribute
