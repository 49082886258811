import React, { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

import { withApp } from "@/components/App"
import Slice, {
  initialize,
  LAST_SELECTED_ITINERARY_ID_PATH,
  LAST_SELECTED_PORT_ID_PATH,
  LAST_SELECTED_SEASON_ID_PATH,
  LAST_SELECTED_SHIP_ID_PATH,
  LAST_SELECTED_TAB_PATH,
} from "@/itinerary_dashboard/Slice"
import { getItinerary } from "@/itinerary_dashboard/selectors"

import TabController from "./TabController"
import VisitCard from "./cards/VisitCard"
import ShipsCard from "./cards/ShipsCard"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getItinerary)

  useEffect(() => {
    const payload = _.merge({}, props, {
      tab: window.localStorage.getItem(LAST_SELECTED_TAB_PATH),
      seasonId: window.localStorage.getItem(LAST_SELECTED_SEASON_ID_PATH),
      itineraryId: window.localStorage.getItem(LAST_SELECTED_ITINERARY_ID_PATH),
      portId: window.localStorage.getItem(LAST_SELECTED_PORT_ID_PATH),
      shipId: window.localStorage.getItem(LAST_SELECTED_SHIP_ID_PATH),
    })

    if (!initialized) {
      dispatch(initialize(payload))
    }
  }, [initialized])

  if (!initialized) {
    return <Spinner animation="border" />
  }

  return (
    <div className="d-flex flex-column gap-3">
      <TabController />
      <VisitCard />
      <ShipsCard />
    </div>
  )
}

export default withApp(Initializer, { slices: [Slice] })
