import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getProductSummary } from "@/onboarding/features/price_analysis/selectors"
import { formatMoney } from "@/helpers/money"
import ErrorBoundary from "@/components/ErrorBoundary"
import { InfoCircle } from "@/components/Icons"

const SummaryDataRow = ({ label, summary }): JSX.Element => {
  const targetMet = summary.currentMargin >= summary.marginTarget

  return (
    <tr>
      <td>{label}</td>
      <td className="text-right">{summary.expectedAdults}</td>
      <td className="text-right">{summary.expectedChildren}</td>
      <td className="text-right">{summary.expectedGuests}</td>
      <td className="text-right">{formatMoney(summary.salesTarget, summary.currency)}</td>
      <td className="text-right">{formatMoney(summary.currentCosts, summary.currency)}</td>
      <td className="text-right">{formatMoney(summary.marginTarget, summary.currency)}</td>
      <td className={targetMet ? "text-success text-right" : "text-danger text-right"}>
        {formatMoney(summary.currentMargin, summary.currency)}
      </td>
    </tr>
  )
}

const SummaryTable = (): JSX.Element => {
  const summary = useSelector(getProductSummary)

  return (
    <div>
      <div className="d-flex align-items-center">
        <h2 className="h5">Target Performance Summary</h2>
        <InfoCircle
          className="ms-2 mt-n2 cursor-pointer"
          data-toggle="tooltip"
          data-placement="top"
          title="Calculates the expected revenue, costs, and margins using target pax, selected operator pricing, and anticipated retail rates submitted during the prebidding setup."
        />
      </div>
      <ErrorBoundary>
        <Table>
          <thead>
            <tr>
              <th scope="col" />
              <th scope="col" className="text-right">
                Adults
              </th>
              <th scope="col" className="text-right">
                Children
              </th>
              <th scope="col" className="text-right">
                Total Guests
              </th>
              <th scope="col" className="text-right">
                Sales Target
              </th>
              <th scope="col" className="text-right">
                Projected Costs
              </th>
              <th scope="col" className="text-right">
                Margin Target
              </th>
              <th scope="col" className="text-right">
                Projected Margin
              </th>
            </tr>
          </thead>
          <tbody>
            <SummaryDataRow label="Per Call" summary={summary.daily} />
            <SummaryDataRow label="Per Season" summary={summary.season} />
          </tbody>
        </Table>
      </ErrorBoundary>
    </div>
  )
}

export default SummaryTable
