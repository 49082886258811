import React from "react"

import { Button } from "react-bootstrap"

import { isPresent } from "@/utils"

const AddButton = ({ label = "", onClick, ...props }) => {
  const iconClassName = isPresent(label) ? "fas fa-plus me-2" : "fas fa-plus"

  return (
    <Button onClick={onClick} {...props}>
      <span className={iconClassName} />
      {label}
    </Button>
  )
}

export default AddButton
