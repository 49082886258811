import React, { useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, Form, Container, Spinner } from "react-bootstrap"
import _ from "lodash"

import FormIdContext from "@/manual_booking/contexts/FormIdContext"
import GuestColumns from "@/manual_booking/components/GuestColumns"

import { removePassenger, addPassenger, removePassengers } from "./Slice"
import { getPassengerList } from "./selectors"

const placeholderId = () => (-Math.random()).toString()

const CheckablePassengerItem = ({ passenger }) => {
  const dispatch = useDispatch()

  const formId = useContext(FormIdContext)

  const toggleBookingPassenger = (e) => {
    if (e.target.checked) {
      dispatch(addPassenger({ formId, passengerId: e.target.value }))
    } else {
      dispatch(removePassenger({ formId, passengerId: e.target.value }))
    }
  }

  const itemId = `${formId}/${passenger.id}`

  return (
    <Row className="align-items-center m-1">
      <Col className="col-auto">
        <Form.Check
          id={itemId}
          type="checkbox"
          value={passenger.id}
          defaultChecked={passenger.checked}
          onChange={toggleBookingPassenger}
        />
      </Col>
      <GuestColumns guest={passenger} />
    </Row>
  )
}

const RoomPassengerList = ({ label, passengers }) => {
  const formId = useContext(FormIdContext)
  const dispatch = useDispatch()

  const passengerItems = _.map(passengers, (g) => (
    <Col key={g.id || placeholderId()} className="col-auto">
      <CheckablePassengerItem key={g.id} passenger={g} />
    </Col>
  ))

  const removeRoomPassengers = () => {
    dispatch(removePassengers({ formId, passengerIds: _.map(passengers, (g) => g.id) }))
  }

  return (
    <Container fluid className="p-0 mb-1 mt-1">
      <b> {label} </b>
      <span
        className="bi bi-x-circle text-danger p-1"
        title="Remove All Room Guests"
        onClick={removeRoomPassengers}
      />
      <Row>{passengerItems}</Row>
    </Container>
  )
}

const PassengerList = (): JSX.Element => {
  const formId = useContext(FormIdContext)

  // TODO(mbf) Remove dependence knowing the slice which contains tourBookings
  const passengers = useSelector((state) => getPassengerList(state.tourBookings, formId))

  if (_.find(passengers, (g) => !g.hasProfile)) {
    return <Spinner animation="grow" className="m-2" />
  }

  return (
    <>
      {_(passengers)
        .groupBy("roomNumber")
        .map((passengers, roomName) => (
          <RoomPassengerList
            passengers={passengers}
            label={`Passengers in Room ${roomName}`}
            key={roomName}
          />
        ))
        .value()}
    </>
  )
}

export default PassengerList
