import React, { useContext } from "react"

import { v4 as uuid } from "uuid"

const NOOP = () => {}

const WizardContext = React.createContext({
  pages: [],
  activePage: null,
  setPage: NOOP,
  setPages: NOOP,
})

const BLANK_PAGE = {
  title: "NONE",
  href: "#",
  isValid: undefined,
  component: null,
}

const newPage = (page = {}) => _.merge({ uuid: uuid() }, BLANK_PAGE, page)

const initializePages = (pages) => _.map(pages, newPage)

const usePages = () => useContext(WizardContext).pages

const useActivePage = () => useContext(WizardContext).activePage

const useValidity = (page) => {
  const { setPageValidity } = useContext(WizardContext)

  const index = getPageIndex(page)

  return [page.isValid, (isValid) => setPageValidity(page, isValid)]
}

const updatePageValidity = (pages, page, isValid) => {
  const index = getPageIndex(page, pages)

  const newPages = _.merge([], pages, { [index]: _.merge({}, page, { isValid }) })

  return newPages
}

// Return all pages that have errors, but not those marked with (undefined)
const getInvalidPages = (options = {}) =>
  _.filter(
    options.pages || usePages(),
    (p) => getPageIndex(p, options.excludePages || []) < 0 && p.isValid === false
  )

const hasInvalidPages = (options = {}) => getInvalidPages(options).length > 0

const isPageEqual = (a, b) => a.uuid == b.uuid

const getPageIndex = (page, pages = usePages()) => _.findIndex(pages, { uuid: page.uuid })

const getNextPage = (page) => usePages()[getPageIndex(page) + 1]

const getPreviousPage = (page) => usePages()[getPageIndex(page) - 1]

const getPagesWithValidity = () =>
  _.filter(usePages(), (p) => p.isValid === true || p.isValid === false)

const previousPage = (page = useActivePage()) => getPreviousPage(page)

const nextPage = (page = useActivePage()) => getNextPage(page)

export default WizardContext

export {
  previousPage,
  nextPage,
  useValidity,
  newPage,
  initializePages,
  isPageEqual,
  updatePageValidity,
  getInvalidPages,
  hasInvalidPages,
  getPagesWithValidity,
}
