import _ from "lodash"

export const getTotalCost = (bookedPassengers) => {
  let total = 0.0
  const currency = bookedPassengers[0] ? bookedPassengers[0].price.currency : "USD"
  _.map(bookedPassengers, (value) => {
    total += parseFloat(value.price.amount)
  })
  return formatMoney(total, currency)
}

const FORMATTERS = {
  USD: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
}

// TODO(guest-app): Consolidate all money related functions in the upper helpers folder
export const formatMoney = (amount, currencyCode: string) => FORMATTERS[currencyCode].format(amount)
