import React, { useState, useEffect } from "react"
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap"
import { TypeaheadInputMulti, AsyncTypeahead, Token as RBTToken } from "react-bootstrap-typeahead"
import { defaultFilterBy } from "react-bootstrap-typeahead/lib/utils"
import _ from "lodash"

import { DateTimePicker } from "@/components/DatePickers"
import SimpleSearchInput from "@/components/SimpleSearchInput"
import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"
import AuthenticityToken from "@/components/AuthenticityToken"
import GuestSearchResult from "@/components/GuestSearchResult"
import HiddenInput from "@/components/HiddenInput"

const PercentageFormControl = ({ start, end, step = 1, ...extra }) => {
  if (start > end) {
    throw new Error("invalid, start > end!")
  }

  if (step === 0) {
    throw new Error("step must be non-zero")
  }

  const options = _.map(_.range(start, end + 1, step), (s) => (
    <option key={s.toString()} id={`${s}`} value={`${s}`}>
      {s}%
    </option>
  ))

  return <Form.Select {...extra}>{options}</Form.Select>
}

const ComplaintCategoriesSelect = ({ onSearch, options, isLoading, onChange, selected }) => (
  <AsyncTypeahead
    id="complaint-categories-select"
    multiple
    onChange={onChange}
    onSearch={onSearch}
    options={options}
    isLoading={isLoading}
    minLength={0}
    useCache
    placeholder="Choose a category"
    filterBy={(option, props) => {
      if (props.text.length === 0 && option.new) {
        return false
      }
      return defaultFilterBy(option, { ...props, filterBy: [] })
    }}
    renderInput={(inputProps, props) => (
      <TypeaheadInputMulti {...inputProps} selected={selected}>
        {selected.map((option, idx) => (
          <RBTToken index={idx} key={option.label} onRemove={props.onRemove} option={option}>
            {option.selectLabel || option.label}
          </RBTToken>
        ))}
      </TypeaheadInputMulti>
    )}
    selected={selected}
  />
)

const ComplaintCategoriesSearch = ({ selectedCategories, setSelectedCategories }) => {
  const [categoriesField, setCategoriesField] = useState(
    AsyncField.createField({ defaultValue: [] })
  )

  const [categoryOptions, setCategoryOptions] = useState([])
  const [isInitialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!isInitialized) {
      // preload options
      searchCategories("")
      setInitialized(true)
    }
  }, [isInitialized])

  const searchCategories = (query) => {
    const field = AsyncField.cloneField(categoriesField)
    setCategoriesField(AsyncField.setFieldLoading(field))

    Api.guestComplaints
      .fetchComplaintOptions(query)
      .then((response) => {
        setCategoriesField(
          AsyncField.setFieldReady(field, { value: response.data.data.complaintCategories })
        )
        if (_.find(field.value, { label: query })) {
          setCategoryOptions(field.value)
        } else {
          setCategoryOptions(
            _.concat(field.value, {
              new: true,
              label: `New Category: ${query}`,
              value: query,
              selectLabel: query,
            })
          )
        }
      })
      .catch((e) => {
        setCategoriesField(AsyncField.setFieldFailed(field))
      })
  }

  return (
    <ComplaintCategoriesSelect
      options={categoryOptions}
      selected={selectedCategories}
      onChange={setSelectedCategories}
      isLoading={AsyncField.isLoading(categoriesField)}
      onSearch={searchCategories}
    />
  )
}

const BookingResult = () => {}

const BookingSearch = ({ selected, onSelect, tourId }) => {
  const [matchedField, setMatches] = useState(AsyncField.createField({ defaultValue: [] }))

  const ResultItem = ({ result, ...props }) => (
    <Row className="align-items-center">
      <Col className="col-auto">
        <GuestSearchResult guest={result.chargedPassenger} includeRoom />
      </Col>
      <Col>{result.token}</Col>
    </Row>
  )

  if (selected) {
    return (
      <Row className="align-items-center">
        <Col className="col-auto">
          <HiddenInput name="guest_complaint[booking_type]" value={selected.bookingType} />
          <HiddenInput name="guest_complaint[booking_id]" value={selected.bookingId} />
          <ResultItem result={selected} />
        </Col>
        <Col>
          <Button variant="outline-secondary" size="sm" onClick={() => onSelect(null)}>
            Change
          </Button>
        </Col>
      </Row>
    )
  }

  const copiedMatchedField = () => AsyncField.cloneField(matchedField)

  const onSearch = (q) => {
    setMatches(AsyncField.setFieldLoading(copiedMatchedField()))

    Api.guestComplaints
      .fetchBookingsByRoom({ tourId, q })
      .then((response) => {
        setMatches(
          AsyncField.setFieldReady(copiedMatchedField(), { value: response.data.data.bookings })
        )
      })
      .catch((error) => {
        setMatches(AsyncField.setFieldFailed(copiedMatchedField(), {}))
      })
  }

  return (
    <SimpleSearchInput
      id="bookingSearch"
      results={matchedField.value}
      isLoading={AsyncField.isLoading(matchedField)}
      onSearch={onSearch}
      onSelect={(selections, typeAhead) => {
        onSelect(selections[0])
        typeAhead.clear()
      }}
      placeholder="Find booking by room number"
      labelKey="token"
      resultComponent={ResultItem}
    />
  )
}

const tomorrowDate = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return tomorrow
}

const AffectedGuestsSelect = ({ booking }) => {
  if (!booking) {
    return (
      <span className="d-block">
        <i>Select a booking to see affected guests</i>
      </span>
    )
  }

  const passengerCheckbox = (passenger, idx) => {
    const id = `affected-guest-${passenger.id}`

    return (
      <Col key={id} className="col-auto">
        <Row key={id} className="align-items-center m-1">
          <Col className="col-auto">
            <Form.Check
              id={id}
              name="guest_complaint[passengers_attributes][][id]"
              type="checkbox"
              value={passenger.id}
            />
          </Col>
          <Col className="col-auto">
            <Row>
              <Col className="col-auto">
                <div className="avatar">
                  <Image src={passenger.profilePicturePath} roundedCircle />
                </div>
              </Col>
              <Col className="ms-2 col-auto">
                <h4 className="h6 mb-0">{passenger.name}</h4>
                <small>
                  <b>Room: </b>
                  {passenger.roomNumber}
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  const passengerItems = _.map(booking.passengers, (p, idx) => passengerCheckbox(p, idx))

  return (
    <Container fluid className="p-0 mb-1 mt-1">
      <Row>{passengerItems}</Row>
    </Container>
  )
}

const GuestComplaintForm = (props) => {
  const [selectedCategories, setSelectedCategories] = useState([])

  const [bookingResults, setBookingResults] = useState(AsyncField.createField({ defaultValue: [] }))

  const [booking, setBooking] = useState(null)

  const [resolutionDate, setResolutionDate] = useState(tomorrowDate())

  return (
    <Row>
      <Col md="6">
        <Form method="post" action={props.action}>
          <AuthenticityToken />
          <Form.Group className="mb-2">
            <Form.Label>Booking</Form.Label>
            <BookingSearch tourId={props.tourId} onSelect={setBooking} selected={booking} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Affected Guests</Form.Label>
            <AffectedGuestsSelect booking={booking} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Complaint Categories</Form.Label>
            <ComplaintCategoriesSearch
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
            {_.map(selectedCategories, (c, idx) => {
              if (c.new) {
                return (
                  <div className="d-hidden" key={c.value}>
                    <HiddenInput
                      name="guest_complaint[guest_complaint_categories_attributes][][id]"
                      value={c.id}
                    />
                    <HiddenInput
                      name="guest_complaint[guest_complaint_categories_attributes][][name]"
                      value={c.value}
                    />
                  </div>
                )
              }
              return (
                <div className="d-hidden" key={c.value}>
                  <HiddenInput
                    name="guest_complaint[guest_complaint_categories_attributes][][id]"
                    value={c.value}
                  />
                </div>
              )
            })}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Complaint Details</Form.Label>
            <Form.Control as="textarea" rows={3} name="guest_complaint[description]" />
          </Form.Group>
          <HiddenInput
            name="guest_complaint[resolution_due_at]"
            value={resolutionDate.toISOString()}
          />
          <DateTimePicker
            wrapperProps={{ className: "mb-2" }}
            inputLabel="Resolution Due By"
            selected={resolutionDate}
            onChange={(datetime) => {
              setResolutionDate(datetime)
            }}
          />
          <Button variant="primary" type="submit" className="mt-2">
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  )
}

export default GuestComplaintForm
