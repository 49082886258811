import _ from "lodash"

import { getOrderedBookingForms as getOrderedTourBookingForms } from "@/manual_booking/features/tour_bookings/selectors"

import { isPrivateVillaBooking } from "@/manual_booking/productSelectors"

const filterPrivateVillaBookings = (bookings) => _.filter(bookings, isPrivateVillaBooking)

const getLastBooking = (state) =>
  _.last(filterPrivateVillaBookings(getOrderedTourBookingForms(state.tourBookings)))

export { getLastBooking }
