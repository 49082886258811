import React from "react"

import OperatorApplicationApp from "@/onboarding/features/operator_application/App"

import ApplicationReview from "./ApplicationReview"

const CurrentApplication = ({ view }) => {
  if (!view.currentApplication || !view.currentApplication.invitation) {
    return <p>Operator has not completed an application yet.</p>
  }
  return (
    <ApplicationReview application={view.currentApplication}>
      <OperatorApplicationApp
        form={view.currentApplication.invitation}
        sliceName="currentOperatorApplication"
      />
    </ApplicationReview>
  )
}

export default CurrentApplication
