import React from "react"

import { Button } from "react-bootstrap"

import CancelTourModal from "@/dashboard/components/tour_list_card/modals/CancelTourModal"

import { useModal, ModalHolder } from "@/helpers/useModal"

const ToggledCancelTourModal = ({ tour, toggleLabel }): JSX.Element => {
  const toggleModal = useModal((props) => <CancelTourModal tour={tour} {...props} />)

  return (
    <Button variant="danger" onClick={toggleModal}>
      {toggleLabel}
    </Button>
  )
}

const App = (props) => (
  <ModalHolder>
    <ToggledCancelTourModal {...props} />
  </ModalHolder>
)

export default App
