import React, { useRef } from "react"
import { Dropdown } from "react-bootstrap"

import objectToForm from "@/helpers/objectToForm"
import AuthenticityToken from "@/components/AuthenticityToken"

type PostDropdownItemProps = {
  url: string
  linkText: string
  params?: any
  disabled?: boolean
  hidden?: boolean
}

const PostDropdownItem: React.FC<PostDropdownItemProps> = ({
  url,
  linkText,
  params = undefined,
  disabled = false,
  hidden = false,
}) => {
  const formRef = useRef()

  const form = (
    <form ref={formRef} action={url} method="POST">
      <AuthenticityToken />
      {objectToForm(params)}
    </form>
  )

  if (hidden) {
    return null
  }

  return (
    <>
      {form}
      <Dropdown.Item
        disabled={disabled}
        onClick={() => formRef.current && formRef.current.submit()}
      >
        {linkText}
      </Dropdown.Item>
    </>
  )
}

export { PostDropdownItem }
