import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isBlank } from "@/utils"
import { CaretDown, CaretUp, BlueLock, GreenCheckMark } from "@/components/Icons"
import { parseISO } from "date-fns"
import { formatDate } from "@/components/DatePickers"
import { Button } from "react-bootstrap"
import { useModal } from "@/helpers/useModal"
import { ChatButton } from "@/components/chat"
import {
  getDayExpansionStates,
  getRowEditStates,
  getCallDaysPreEdit,
  getView,
  isDisplaySales,
} from "../selectors"
import CruiseLineDepartures from "./CruiseLineDepartures"
import DeparturesEditColumns from "./DeparturesEditColumns"
import {
  calculateTotalMaximumCapacity,
  RenderIssuesColumn,
  RenderDeparturesColumn,
  isRowExpandable,
  textColor,
  getSalesTextColor,
} from "./Helpers"
import AdditionalTours from "./AdditionalTours"
import ReasonModal from "./ReasonModal"
import AdditionalShips from "./AdditionalShips"
import { IssueCalculator } from "./IssueCalculator"
import {
  updateDayExpansionStates,
  updateRowEditStates,
  updateDepartures,
  onCancelEdit,
  lockDays,
  unlockDays,
  closeDays,
  openDays,
  addDraftDeparture,
  removeDepartures,
} from "../Slice"
import ConfirmationModal from "./ConfirmationModal"
import SalesDepartures from "./SalesDepartures"

const OperatorTableRow = ({ index }) => {
  const dispatch = useDispatch()
  const view = useSelector(getView)
  const call = view.inventory.allocationTableData.callDays[index]
  const tour = call.inventory.currentAllocatingTour
  const callDaysPreEdit = useSelector(getCallDaysPreEdit)
  const dayExpansionStates = useSelector(getDayExpansionStates)
  const rowEditStates = useSelector(getRowEditStates)
  const displaySales = useSelector(isDisplaySales)
  const [expand, setExpand] = useState(dayExpansionStates || {})
  const [edit, setEdit] = useState(rowEditStates || {})
  const [reasonPrompt, setreasonPrompt] = useState("")
  const [confirmationPrompt, setConfirmationPrompt] = useState("")
  const [confirmationHeader, setConfirmationHeader] = useState("")
  const [header, setHeader] = useState("")
  const [issues, setIssues] = useState([])

  const toggleReasonModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Submit"
      onSubmitCallback={onDaySubmitCallback}
      header={header}
      {...props}
    />
  ))

  const toggleSubmitDeparturesModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Submit"
      onSubmitCallback={onDepartureSubmitCallback}
      issues={issues}
      header={header}
      {...props}
    />
  ))

  const toggleConfirmationModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onRemoveCallback}
      buttonText="Delete"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  useEffect(() => {
    if (dayExpansionStates) {
      setExpand(dayExpansionStates)
    }
    if (rowEditStates) {
      setEdit(rowEditStates)
    }
  }, [dayExpansionStates, rowEditStates])

  const onDaySubmitCallback = async (reason) => {
    dispatch(closeDays({ dates: [call.date], reason }))
    const newState = { ...edit, [call.date]: !edit[call.date] }
    setEdit(newState)
    dispatch(updateRowEditStates({ editState: newState }))
  }

  const onDepartureSubmitCallback = async (reason = null) => {
    const newState = { ...edit, [call.date]: !edit[call.date] }
    setEdit(newState)

    const dateIndex = callDaysPreEdit.findIndex((callDay) => callDay.date === call.date)
    const previousDepartures =
      callDaysPreEdit[dateIndex]?.inventory?.currentAllocatingTour?.departures || []

    const commonDepartures = tour.departures.filter((departure) =>
      previousDepartures.some((prevDeparture) => prevDeparture.id === departure.id)
    )

    const departuresToAdd = tour.departures.filter(
      (departure) => !previousDepartures.some((prevDeparture) => prevDeparture.id === departure.id)
    )

    const departuresToRemove = previousDepartures.filter(
      (prevDeparture) => !tour.departures.some((departure) => departure.id === prevDeparture.id)
    )

    const departuresToUpdate = commonDepartures.filter((commonDeparture) => {
      const prevDeparture = previousDepartures.find((prev) => prev.id === commonDeparture.id)
      if (!prevDeparture) return false
      return Object.keys(commonDeparture).some((key) => {
        if (key !== "id" && key !== "issues") {
          return prevDeparture[key] !== commonDeparture[key]
        }
      })
    })

    if (!isBlank(departuresToAdd)) {
      await dispatch(updateDepartures({ departures: departuresToAdd, reason }))
    }

    if (!isBlank(departuresToUpdate)) {
      const departuresToUpdateFormatted = departuresToUpdate.map((departure) => {
        const { id, ...rest } = departure
        return { departureId: id, ...rest }
      })
      await dispatch(updateDepartures({ departures: departuresToUpdateFormatted, reason }))
    }

    if (!isBlank(departuresToRemove)) {
      const departuresToRemoveFormatted = departuresToRemove.map((departure) => ({
        departureId: departure.id,
        remove: true,
      }))
      await dispatch(updateDepartures({ departures: departuresToRemoveFormatted, reason }))
    }

    dispatch(updateRowEditStates({ editState: newState }))
  }

  const onRemoveCallback = () => {
    dispatch(removeDepartures(tour.departures))
  }

  const onExpand = (date) => {
    const newState = { ...expand, [date]: !expand[date] }
    setExpand(newState)
    dispatch(updateDayExpansionStates({ expandState: newState }))
  }

  const onEdit = (date, cancel = false) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    dispatch(updateRowEditStates({ editState: newState }))
    if (!cancel && isBlank(tour.departures)) {
      dispatch(addDraftDeparture({ date }))
    }
    if (cancel) {
      dispatch(onCancelEdit({ date }))
    }
  }

  const onLockDay = (date) => {
    const newState = { ...edit, [date]: !edit[date] }
    setEdit(newState)
    tour.lockedDay ? dispatch(unlockDays([date])) : dispatch(lockDays([date]))
    dispatch(updateRowEditStates({ editState: newState }))
  }

  const onCloseDays = async (date) => {
    if (tour.closedDay) {
      const newState = { ...edit, [date]: !edit[date] }
      setEdit(newState)
      dispatch(updateRowEditStates({ editState: newState }))
      await dispatch(openDays([date]))
    } else {
      setHeader("Closed day: No departures will be provided on this day")
      setreasonPrompt(
        `Reason required for closing ${tour.code} for ${call.inventory.ship.name} on this day`
      )
      toggleReasonModal()
    }
  }

  const onSubmit = async (call) => {
    const issuesArray = IssueCalculator(call)
    if (isBlank(issuesArray)) {
      onDepartureSubmitCallback()
    } else {
      setIssues(issuesArray)
      setHeader("Your submission has unmet constraints")
      setreasonPrompt(
        "Please provide a reason why the constraints cannnot be met, or cancel this submission"
      )
      toggleSubmitDeparturesModal()
    }
  }

  const onRemove = () => {
    setConfirmationPrompt(`Are you sure you want to delete all departures loaded for ${call.date}?`)
    setConfirmationHeader("Confirm deletion")
    toggleConfirmationModal()
  }
  const salesTextColor = getSalesTextColor(
    tour.sales.salesNearOrAtSoldOut,
    tour.sales.salesExceedsCapacity
  )

  return (
    <>
      <tr key={call.date}>
        <td
          className={isRowExpandable(call) ? "cursor-pointer" : ""}
          onClick={isRowExpandable(call) ? () => onExpand(call.date) : undefined}
        >
          {isRowExpandable(call) && (
            <div className="d-inline me-1">{expand[call.date] ? <CaretUp /> : <CaretDown />}</div>
          )}
          {tour.lockedDay && (
            <BlueLock
              data-toggle="tooltip"
              data-placement="top"
              title="Bulk updates will not override this day's departures"
            />
          )}{" "}
          {formatDate(parseISO(call.date), "date.humaneShort")}
        </td>
        <td>
          <span
            className="cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="shipDetails"
          >
            <span
              title={`Launched: ${call.inventory.ship.launchDate}\nCapacity:   ${call.inventory.ship.capacity}\nSailings:     ${call.inventory.ship.sailings}`}
            >
              {`${call.inventory.ship.name}`}
            </span>
          </span>
          <br />
          {`day ${call.portDay.cruiseDay} of ${call.portDay.cruiseLength} `}
        </td>
        <td>
          {call.inventory.ship.arrivalTime && call.inventory.ship.departureTime
            ? `${call.inventory.ship.arrivalTime} - ${call.inventory.ship.departureTime}`
            : call.inventory.ship.arrivalTime
              ? `${call.inventory.ship.arrivalTime} - in port`
              : call.inventory.ship.departureTime
                ? ` in port - ${call.inventory.ship.departureTime}`
                : "in port"}
        </td>
        <td>
          <strong>{tour.code}</strong> <br />
          {isBlank(tour.constraints.allotmentConstraint) && (
            <div>
              No requirement
              <br /> loaded
              {displaySales && (
                <>
                  <br />
                  <br />
                  <strong>Total Sales</strong> <br />
                  <span
                    className="cursor-pointer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="totalSales"
                  >
                    <span title={`${tour.sales.capacitySold} / ${tour.sales.capacityTotal} sold`}>
                      Percentage:{" "}
                      <span style={{ color: salesTextColor }}>
                        {tour.sales.percentageSold !== "N/A"
                          ? `${tour.sales.percentageSold}%`
                          : tour.sales.percentageSold}
                      </span>
                    </span>
                  </span>
                  <br />
                  <span style={{ marginRight: "13px" }}>Units Sold:</span>
                  <span>{tour.sales.capacitySold}</span>
                </>
              )}
            </div>
          )}
          {!isBlank(tour.constraints.allotmentConstraint) && (
            <>
              {`Min per departure: ${tour.constraints.allotmentConstraint.capacityPerDepartureMin}`}
              <br />
              {/* TODO(inventory,ui) Rather use the margin utilities like me-1 or me-2 */}
              <span style={{ marginRight: "41px" }}>Max per day:</span>
              <span>{tour.constraints.allotmentConstraint.capacityPerDayMax}</span>
              {displaySales && (
                <>
                  <br />
                  <br />
                  <strong>Total Sales</strong> <br />
                  <span className="cursor-pointer" data-toggle="tooltip" data-placement="top">
                    <span title={`${tour.sales.capacitySold} / ${tour.sales.capacityTotal} sold`}>
                      Percentage:{" "}
                      <span style={{ color: salesTextColor }}>
                        {tour.sales.percentageSold !== "N/A"
                          ? `${tour.sales.percentageSold}%`
                          : tour.sales.percentageSold}
                      </span>
                    </span>
                  </span>
                  <br />
                  <span style={{ marginRight: "13px" }}>Units Sold:</span>
                  <span>{tour.sales.capacitySold}</span>
                </>
              )}
            </>
          )}
        </td>
        <td>
          {edit[call.date]
            ? DeparturesEditColumns(call.date, tour.departures, "departureTime")
            : !isBlank(tour.departures) && (
                <RenderDeparturesColumn
                  departures={tour.departures}
                  property="departureTime"
                  closed={tour.closedDay}
                />
              )}
        </td>
        <td>
          {edit[call.date] ? (
            <>{DeparturesEditColumns(call.date, tour.departures, "durationInMinutes")}</>
          ) : (
            !isBlank(tour.departures) && (
              <RenderDeparturesColumn
                departures={tour.departures}
                property="durationInMinutes"
                closed={tour.closedDay}
              />
            )
          )}
        </td>
        <td>
          {edit[call.date]
            ? DeparturesEditColumns(call.date, tour.departures, "minimumCapacity")
            : !isBlank(tour.departures) && (
                <RenderDeparturesColumn
                  departures={tour.departures}
                  property="minimumCapacity"
                  closed={tour.closedDay}
                />
              )}
        </td>
        <td>
          {edit[call.date] ? (
            DeparturesEditColumns(call.date, tour.departures, "maximumCapacity")
          ) : (
            <>
              {!isBlank(tour.departures) && (
                <RenderDeparturesColumn
                  departures={tour.departures}
                  property="maximumCapacity"
                  closed={tour.closedDay}
                />
              )}
              {!tour.closedDay && !isBlank(tour.departures) && (
                <strong style={{ color: textColor("totalMaximumDepartureCapacity", tour.issues) }}>
                  {`${calculateTotalMaximumCapacity(tour.departures)} (Total)`}
                </strong>
              )}
            </>
          )}
        </td>

        <td>{!isBlank(tour.issues) && <RenderIssuesColumn issues={tour.issues} />}</td>
        <td>
          {tour.state == "Approved" && <GreenCheckMark />} {tour.state}
        </td>
        <td>
          {edit[call.date] ? (
            <>
              <Button className="primary mb-1" onClick={() => onCloseDays(call.date)}>
                {tour.closedDay ? "Open" : "Close"}
              </Button>
              <br />
              <Button className="primary mb-1" onClick={() => onLockDay(call.date)}>
                {tour.lockedDay ? "Unlock" : "Lock"}
              </Button>
              <br />
              <Button variant="secondary mb-1" onClick={() => onEdit(call.date, true)}>
                Cancel
              </Button>
              <br />
              <Button variant="secondary" onClick={() => onSubmit(call)}>
                Submit
              </Button>
            </>
          ) : (
            <>
              <Button className="primary mb-1" onClick={() => onEdit(call.date)}>
                Edit
              </Button>
              <br />
              {!isBlank(tour.departures) && (
                <Button className="primary mb-1" onClick={() => onRemove()}>
                  Remove
                </Button>
              )}
              {tour.state == "Reinstatement" && (
                <>
                  <br />
                  <Button className="primary">Accept</Button>
                </>
              )}
            </>
          )}
        </td>
        <td>
          <div key={call.chat.id}>
            <ChatButton chat={call.chat} key={call.chat.date} />
          </div>
        </td>
        <td />
      </tr>

      {isRowExpandable(call) && expand[call.date] && <CruiseLineDepartures index={index} />}
      {isRowExpandable(call) && expand[call.date] && <SalesDepartures index={index} />}

      {expand[call.date] && !isBlank(call.additionalToursForViewing) && (
        <AdditionalTours inventory={call.additionalToursForViewing} />
      )}
      {expand[call.date] && !isBlank(call.additionalInventoryInPort) && (
        <AdditionalShips additionalInventoryInPort={call.additionalInventoryInPort} />
      )}
    </>
  )
}

export default OperatorTableRow
