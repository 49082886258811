import React, { useRef } from "react"

import objectToForm from "@/helpers/objectToForm"

import _ from "lodash"

import Rails from "@rails/ujs"
import AuthenticityToken from "./AuthenticityToken"

function urlQueryParams(uri) {
  const queryString = uri.includes("?") ? uri.split("?")[1] : ""
  return Object.fromEntries(new URLSearchParams(queryString).entries())
}

/**
 * == ButtonLink Component Overview
 *
 * The `ButtonLink` component is designed to render a button that submits a form.
 * It no longer renders `<a>` tags; instead, it always renders a hidden form.
 *
 * == Remote Submissions
 *
 * The `remote` prop has been added to enable remote form submissions, similar to how Rails UJS operates.
 * When `remote` is set to `true`, the form will be submitted via JavaScript without a full page reload.
 *
 * == Styling Options
 *
 * For styling, you must specify either the `className` or `button` props. The `button` prop can be a boolean
 * or an object with `variant` and `size` keys. If both `className` and `button` are provided,
 * an error will be thrown, as these options are mutually exclusive.
 *
 * == Form Parameters
 *
 * The `params` prop allows you to include hidden fields in the form. These parameters
 * are merged with any extracted from the `href`.
 *
 * == Notes on Using `href` with Parameters
 *
 * By default, when you pass an `href` containing URL parameters to `ButtonLink`,
 * these parameters are discarded. To ensure they are included, we extract and merge
 * them into the form data.
 *
 * This approach works well for simple parameters. For more complex cases, it's recommended
 * to pass parameters directly through the `params` prop.
 *
 * (edited and refined using a LLM)
 */
const ButtonLink = ({
  label = "",
  method = "get",
  href = "",
  params = {},
  remote = false,
  // Can also be an object specifying variant & size
  button = true,
  icon = "",
  className = undefined,
  children = undefined,
}): JSX.Element => {
  const formRef = useRef()

  const formAction = href

  const formParams = _.merge({}, urlQueryParams(href), params || {})

  const formOptions = {}

  const buttonOptions = { className }

  if (button && className) {
    throw new Error(
      "Options `button` and `className` are mutually exclusive. If you're going to specify className, then you're on your own!"
    )
  }

  // Support both button and simple className configuration
  if (button) {
    buttonOptions.className = "btn"

    if (_.isPlainObject(button)) {
      buttonOptions.className += ` btn-${button.variant || "primary"}`

      if (button.size) {
        buttonOptions.className += ` btn-${button.size}`
      }
    } else {
      buttonOptions.className += " btn-primary"
    }
  }

  /* Remote submissions uses RailsUJS.
   *
   * When doing a GET request with RailsUJS it expects the server to return JS which it will execute on the frontend.
   */
  if (remote) {
    formOptions["data-remote"] = true

    buttonOptions.onClick = (event) => {
      if (formRef.current) {
        event.preventDefault()
        Rails.fire(formRef.current, "submit")
      }
    }
  }

  return (
    <form ref={formRef} {...formOptions} action={formAction} method={method}>
      <AuthenticityToken />
      {objectToForm(formParams)}
      <button type="submit" {...buttonOptions}>
        {icon && <span className={`${icon} me-2`} />}
        {label || children}
      </button>
    </form>
  )
}

export default ButtonLink
