import React from "react"
import { Button } from "react-bootstrap"
import { useSelector } from "react-redux"

import { useModal } from "@/helpers/useModal"
import AwardBidsModal from "@/onboarding/features/price_analysis/components/modals/AwardBidsModal"
import { getSelectedProspectiveAwardSet } from "@/onboarding/features/price_analysis/selectors"

const AwardBidsButton = (): JSX.Element => {
  const awardSet = useSelector(getSelectedProspectiveAwardSet)
  const toggleModal = useModal((props) => <AwardBidsModal {...props} />)

  if (!awardSet) {
    return null
  }

  if (awardSet.finalized) {
    return (
      <Button size="sm" disabled>
        <span className="fas fa-trophy me-2" />
        Award Bids
      </Button>
    )
  }

  return (
    <Button size="sm" onClick={toggleModal}>
      <span className="fas fa-trophy me-2" />
      Award Bids
    </Button>
  )
}

export default AwardBidsButton
