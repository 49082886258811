import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"

import { updateFields } from "./Slice"
import { isProductReady } from "./selectors"

export const withUpdateFields = (dispatch) => (InputComponent) =>
  FormikBootstrap.withForm((values) => dispatch(updateFields({ values })))(InputComponent)

export const withUpdateFieldArray = (dispatch) => (InputComponent) =>
  FormikBootstrap.withArrayForm((values) => dispatch(updateFields({ values })))(InputComponent)

const LinkIcon = ({ sourceFieldId }) => {
  if (sourceFieldId) {
    return <span title="This field is linked" className="fas fa-link me-2" />
  }

  return <span title="This field is unlinked" className="fas fa-unlink me-2" />
}

/**
 * Shows a link icon next to the field if it has a sourceFieldId.
 *
 * It is expected that props contains all the attributes of a Field
 */
export const withFieldLinking = (Component) => (props) => {
  let label

  if (!_.isUndefined(props.label)) {
    label = (
      <span>
        {props.label} <LinkIcon sourceFieldId={props.sourceFieldId} />
      </span>
    )
  }

  return (
    <div>
      <Component {...props} label={label} />
    </div>
  )
}

// TODO(currency) Yeaaaah this is basically a big hack to just avoid dealing with
// the whole variable precision element supported by ratesheets.
export const displayPrecision = (amount: number) => amount / 100

export const useProductSubmitting = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const productReady = useSelector(isProductReady)
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (isSubmitting && productReady) {
      setSubmitting(false)
    }
  }, [isSubmitting, productReady])

  return [isSubmitting, setSubmitting]
}
