import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateShowBaseline } from "@/onboarding/features/price_analysis/Slice"
import { isShowBaseline } from "@/onboarding/features/price_analysis/selectors"

const BaselineSelector = (): JSX.Element => {
  const dispatch = useDispatch()
  const showBaseline = useSelector(isShowBaseline)

  const onClick = () => dispatch(updateShowBaseline({ showBaseline: !showBaseline }))

  return (
    <Form.Check
      className="mt-1"
      type="checkbox"
      checked={showBaseline}
      label="Show Baseline"
      onChange={onClick}
    />
  )
}

export default BaselineSelector
