import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { getProductField, getFieldSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import RatesheetRow from "../costs/RatesheetRow"

const Content: FC = () => {
  const { value } = useSelector(getProductField(ReservedFields.HEADSET_COMPONENTS))
  const { headset_components } = useSelector(getFieldSettings(ReservedFields.HEADSET_COMPONENTS))

  const prompt = headset_components.requiredz
    ? "No components loaded. At least one is required."
    : "No Components loaded for this tour"

  return (
    <Row>
      <Col md={6}>
        <h5 className="mb-1">Details</h5>
        {_.isEmpty(value) && <div>{prompt}</div>}
        {_(value)
          .groupBy("category")
          .map((headsets, category) => (
            <Row key={category}>
              <h3 className="h6 mt-2">{_.startCase(category)}</h3>
              <Col className="px-4 gap-2">
                {headsets.map((headset, index) => (
                  <Row key={index}>
                    <Col md={3}>
                      <span className="fw-bold">Name:</span>
                      <br />
                      <TextDisplay value={headset.name} />
                    </Col>
                    <Col md={9}>
                      <span className="fw-bold">Headset provided:</span>
                      <br />
                      <TextDisplay value={headset.headsetProvided} />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          ))
          .value()}
      </Col>

      <Col md={4}>
        <h5 className="mt-0">Rates</h5>
        <RatesheetRow
          fieldName={ReservedFields.HEADSET_FEE_RATESHEET}
          rowLabel="Headset Fee"
          editable={false}
        />
      </Col>
    </Row>
  )
}

export default Content
