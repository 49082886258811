import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getProduct } from "@/onboarding/features/product/selectors"
import Select from "@/components/input/Select"

const Dropdown: FC = () => {
  const { port } = useSelector(getProduct)

  return (
    <Select label="Port">
      <option value={port.id}>{`${port.name} (${port.countryName})`}</option>
    </Select>
  )
}

export default Dropdown
