import React from "react"

// TODO(guest-app): Use the React Modal class or even some of our internal ones here
// See for example https://github.com/activitar/shoretime/blob/main/app/javascript/components/ToggledCancelTourModal.tsx
const BookingsModal = (props): JSX.Element => (
  <div
    className="modal fade"
    id={props.name}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Bookings at {props.name}
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        {props.tour ? (
          <div className="modal-body">
            {props.tour.map((tour) => (
              <a href={tour.bookingUrl} key={tour.bookingUrl}>
                {tour.name}
                <br />
              </a>
            ))}
          </div>
        ) : (
          <div className="modal-body">No tours booked at this port</div>
        )}
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default BookingsModal
