import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { initializeSheet } from "@/operator_check_in/Slice"
import LoadingSpinner from "@/components/LoadingSpinner"
import { isInitialized } from "@/operator_check_in/selectors"

import Header from "./card/Header"
import Body from "./card/Body"

const OperatorCheckInCard = ({ operatorCheckIn }): JSX.Element => {
  const dispatch = useDispatch()
  const initialized = useSelector(isInitialized)

  useEffect(() => {
    if (!initialized) {
      dispatch(initializeSheet(operatorCheckIn))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return (
    <Card border="light" className="shadow-sm">
      <Header />
      <Body />
    </Card>
  )
}

export default OperatorCheckInCard
