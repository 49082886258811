import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import { confirm_tour_booking_path, edit_tour_booking_path } from "@/routes"
import { PostDropdownItem } from "@/components/post_links"
import Booking from "@/dashboard/models/Booking"
import { useModal } from "@/helpers/useModal"

import CancelBookingModal from "./modals/CancelBookingModal"

const WaitlistedBookingTools = ({ booking }: { booking: Booking }): JSX.Element => {
  const toggleModal = useModal((props) => <CancelBookingModal tourBooking={booking} {...props} />)

  // TODO Why can't we use the format:"json" key below????
  return (
    <DropdownButton size="sm" title="Tools" boundary="viewport">
      <PostDropdownItem
        url={confirm_tour_booking_path({ id: booking.bookingId })}
        linkText="Confirm"
      />
      <PostDropdownItem
        url={confirm_tour_booking_path({ id: booking.bookingId })}
        params={{ print_tickets: true }}
        linkText="Confirm & Print Tickets"
      />
      <Dropdown.Item href={edit_tour_booking_path({ id: booking.bookingId })}> Edit </Dropdown.Item>
      <Dropdown.Item
        href={edit_tour_booking_path({ id: booking.bookingId, rebook: "true", _options: true })}
      >
        Rebook
      </Dropdown.Item>
      <Dropdown.Item onClick={toggleModal}>Cancel</Dropdown.Item>
    </DropdownButton>
  )
}

export default WaitlistedBookingTools
