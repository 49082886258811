import React from "react"
import { Button, Form, Modal } from "react-bootstrap"

import AuthenticityToken from "@/components/AuthenticityToken"
import { cancel_tour_path } from "@/routes"
import Tour from "@/dashboard/models/Tour"

const CancelTourModal = ({
  show,
  tour,
  onHide,
}: {
  show: boolean
  tour: Tour
  onHide: () => void
}): JSX.Element => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="cancel-tour-modal-title" centered>
    <Form action={cancel_tour_path({ id: tour.tourId })} method="post">
      <AuthenticityToken />

      <Modal.Header>
        <Modal.Title id="cancel-tour-modal-title">
          Cancel {tour.name} ({tour.code})
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Control
            as="textarea"
            placeholder="Why do you wish to cancel this tour?"
            rows={3}
            name="reason"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="credit_room_account"
            value="1"
            label="Credit room account?"
            defaultChecked
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="notify_guests"
            value="1"
            label="Send cancellation message to guests?"
            defaultChecked
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button type="submit">Submit</Button>
      </Modal.Footer>
    </Form>
  </Modal>
)

export default CancelTourModal
