export enum FieldTypes {
  TEXT_FIELD = "text_field",
  DATETIME = "datetime",
  NUMBER = "number",
  TEXT_AREA = "text_area",
  BOOLEAN = "boolean",
  TOUR_COMPONENT_LIST = "tour_component_list",
  TIME = "time",
  TEXT_LIST = "text_list",
  IMAGE = "image",
  RATESHEET_PICKER = "ratesheet_picker",
  RATESHEET_TIERED = "ratesheet/tiered_ratesheet",
  RATESHEET_FLAT = "ratesheet/flat_ratesheet",
}

export default interface Field {
  id: string
  name: string
  order: number
  type: FieldTypes
  text: string
  changed: boolean
  initial: boolean
  removed: boolean
  value: unknown
  valueOptions: Array<unknown>
}
