import React from "react"
import { useSelector } from "react-redux"

import { getComponentBidOffer } from "@/onboarding/features/price_analysis/selectors"

import PriceDisplay from "./PriceDisplay"

const ComponentBidValue = ({ componentId, operatorId }): JSX.Element => {
  const { expectedTotal, priceBreakdown, priceBreakdownVariance } = useSelector(
    getComponentBidOffer(componentId)(operatorId)
  )

  return (
    <PriceDisplay
      expectedTotal={expectedTotal}
      priceBreakdown={priceBreakdown}
      priceBreakdownVariance={priceBreakdownVariance}
    />
  )
}

export default ComponentBidValue
