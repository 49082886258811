import React, { useContext } from "react"

import ShoretimeStyles from "@/stylesheets/shoretime"

import ConversationBody from "./ConversationBody"

import ChatContext from "./ChatContext"

const canvasStyle = {
  height: 450,
  width: "22rem",
  top: "auto",
  bottom: 0,
  right: 0,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  tabindex: "-1",
}

const canvasHeaderStyle = {
  backgroundColor: ShoretimeStyles.secondaryColour,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
}

const ChatBox = ({ isVisible, onClose }): JSX.Element => {
  const { conversation } = useContext(ChatContext)

  return (
    <div
      className={`offcanvas offcanvas-end${isVisible ? " show" : ""}`}
      style={canvasStyle}
      data-bs-backdrop="false"
    >
      <div className="offcanvas-header" style={canvasHeaderStyle}>
        <div className="offcanvas-title">{conversation.subject}</div>
        <br />
        <button
          type="button"
          className="btn-close text-reset"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
      <div className="offcanvas-body">
        <ConversationBody />
      </div>
    </div>
  )
}

export default ChatBox
