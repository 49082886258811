import React from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"
import { getOperatorCheckIn } from "@/operator_check_in/selectors"
import ToggleGuest from "./body/ToggleGuest"

const Body = (): JSX.Element => {
  const { guests } = useSelector(getOperatorCheckIn)

  if (guests.length === 0) {
    return (
      <Card.Body>
        <p>No bookings for this tour</p>
      </Card.Body>
    )
  }

  return (
    <Table responsive size="sm" className="align-items-center table-flush">
      <thead className="thead-light">
        <tr>
          <th scope="col">Name</th>
          <th scope="col" className="text-center">
            Type
          </th>
          <th scope="col" className="text-center">
            Room
          </th>
          <th scope="col" className="text-center">
            Booking
          </th>
          <th scope="col" className="text-center">
            Ticket Accepted
          </th>
        </tr>
      </thead>
      <tbody>
        {guests.map((guest) => (
          <tr key={guest.guestId}>
            <td>{guest.name}</td>
            <td className="text-center">{_.startCase(guest.paxType)}</td>
            <td className="text-center">{guest.roomName}</td>
            <td className="text-center">{guest.bookingReference}</td>
            <td className="text-center">
              <ToggleGuest guestId={guest.guestId} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default Body
