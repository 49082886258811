import React, { FC, useContext } from "react"

import AuthDescriptorContext from "./Context"

const ProcurementOnly: FC = ({ children }) => {
  const { isProcurementUi } = useContext(AuthDescriptorContext)

  if (!isProcurementUi) {
    return null
  }

  return <>{children}</>
}

export default ProcurementOnly
