import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { selectProduct } from "@/onboarding/features/price_analysis/Slice"
import {
  getPriceAnalysis,
  getProduct,
  getPort,
  getAllBiddingRegionProducts,
} from "@/onboarding/features/price_analysis/selectors"

const ProductDropdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedProductId } = useSelector(getPriceAnalysis)
  const product = useSelector(getProduct(selectedProductId))
  const allBiddingRegionProducts = useSelector(getAllBiddingRegionProducts)
  const port = useSelector((state) => (product ? getPort(product.port.id)(state) : null))
  const productOptions = port ? port.products : allBiddingRegionProducts

  return (
    <Form.Group>
      <Form.Label>Product</Form.Label>
      <Form.Control
        as="select"
        value={selectedProductId || ""}
        onChange={(e) => dispatch(selectProduct({ productId: e.target.value }))}
      >
        <option value="">Select a product</option>
        {productOptions.map(({ id, name, code }) => (
          <option key={id} value={id}>
            {`${name} - ${code}`}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default ProductDropdown
