import React, { useState } from "react"
import { Button } from "react-bootstrap"

const ButtonVisiblityToggle = ({
  visible = false,
  showLabel,
  hideLabel,
  ...props
}): JSX.Element => {
  const [isVisible, setVisible] = useState(visible)

  if (isVisible) {
    return (
      <>
        <Button onClick={() => setVisible(false)} {...props}>
          {hideLabel}
        </Button>
        {props.children}
      </>
    )
  }

  return (
    <>
      <Button onClick={() => setVisible(true)} {...props}>
        {showLabel}
      </Button>
      <div className="d-none">{props.children}</div>
    </>
  )
}

export { ButtonVisiblityToggle }
