import React from "react"
import { useSelector } from "react-redux"
import { FieldArray } from "formik"

import { canEdit } from "@/onboarding/features/product/selectors"

import InputList from "./InputList"

const TextList = ({
  name,
  label,
  readOnly = false,
  showLockedIcon = false,
  showRequired = false,
}) => {
  const isEditable = useSelector(canEdit)

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <InputList
          name={name}
          label={label}
          push={arrayHelpers.push}
          remove={arrayHelpers.remove}
          readOnly={!isEditable && readOnly}
          showControls={isEditable}
          showLockedIcon={showLockedIcon}
          showRequired={showRequired}
        />
      )}
    />
  )
}

export default TextList
