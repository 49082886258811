import React from "react"
import { Button, Modal } from "react-bootstrap"

const ConfirmationModal = ({
  show,
  onHide,
  confirmationPrompt,
  header,
  buttonText,
  onConfirmationCallback,
}: {
  show: boolean
  onHide: () => void
  confirmationPrompt: string
  buttonText: string
  header: string
  onConfirmationCallback: () => void
}): JSX.Element => {
  const onConfirmation = () => {
    onConfirmationCallback()
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="reason-modal-title" centered>
      <Modal.Header>
        <Modal.Title id="reason-modal-title">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{confirmationPrompt}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirmation}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
