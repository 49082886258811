import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { useSelector } from "react-redux"

import { useModal } from "@/helpers/useModal"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import AuthDescriptorContext from "@/auth_descriptor/Context"

import {
  getSettlement,
  requiresShxSignature,
  requiresOperatorSignature,
} from "@/settlement/selectors"
import EditButton from "./ToggleEditButton"
import CommitButton from "./CommitButton"
import SignSettlementModal from "./SignSettlementModal"
import DisputeSettlementModal from "./DisputeSettlementModal"

const ButtonRow = (): JSX.Element => {
  const settlement = useSelector(getSettlement)

  if (settlement.finalized) {
    return <h2 className="h5 text-success text-end">All Changes Finalized</h2>
  }

  if (settlement.disputed) {
    return <h2 className="h5 text-danger text-end">Settlement in dispute</h2>
  }

  const requiresShx = useSelector(requiresShxSignature)
  const requiresOperator = useSelector(requiresOperatorSignature)
  const { isCruiseLineUi, isTourUi } = useContext(AuthDescriptorContext)

  if ((isCruiseLineUi && !requiresShx) || (isTourUi && !requiresOperator)) {
    return null
  }

  const toggleSignSettlementModal = useModal((props) => <SignSettlementModal {...props} />)
  const toggleDisputeSettlementModal = useModal((props) => <DisputeSettlementModal {...props} />)

  if (settlement.editing) {
    return (
      <div className="d-inline-flex justify-content-end gap-2">
        <div>
          <EditButton />
        </div>
        <div>
          <CommitButton />
        </div>
      </div>
    )
  }

  return (
    <div className="d-inline-flex justify-content-end gap-2">
      <CruiseLineOnly>
        <div>
          <EditButton />
        </div>
      </CruiseLineOnly>
      <div>
        <Button variant="success" onClick={toggleSignSettlementModal}>
          {isCruiseLineUi ? "Submit" : "Approve"}
        </Button>
      </div>
      <OperatorOnly>
        <Button variant="danger" onClick={toggleDisputeSettlementModal}>
          Dispute
        </Button>
      </OperatorOnly>
    </div>
  )
}

export default ButtonRow
