import React, { useState, useEffect } from "react"
import { Row, Col, Button } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import FormToolkit from "@/form_toolkit"
import LoadingSpinner from "@/components/LoadingSpinner"
import { isBlank } from "@/utils"
import { Checkbox } from "primereact/checkbox"
import {
  getView,
  getForm,
  isViewLoading,
  getShowBulkEditForm,
  isDisplaySales,
  getIssueCount,
} from "../selectors"
import { filter, setShowBulkEditForm, onCancelBulkEdit, setDisplaySales } from "../Slice"
import { SingleDateSelect, MultipleDateSelect } from "./Helpers"
import BulkEditForm from "./BulkEditForm"

const Select = FormToolkit.buildReduxSingleSelect(getForm, filter)
const MultiSelect = FormToolkit.buildReduxMultiSelect(getForm, filter)

const InventoryAllocationTableHeader = (): JSX.Element => {
  const view = useSelector(getView)
  const issueCount = useSelector(getIssueCount)
  const isLoading = useSelector(isViewLoading)
  const showBulkEditForm = useSelector(getShowBulkEditForm)
  const displaySales = useSelector(isDisplaySales)
  const [buttonText, setButtonText] = useState("Bulk Actions")
  const [display, setDisplay] = useState([displaySales ? "sales" : ""])

  const onSalesChange = (e) => {
    if (e.target.checked) {
      setDisplay([e.target.value])
      dispatch(setDisplaySales({ displaySales: true }))
    } else {
      setDisplay([])
      dispatch(setDisplaySales({ displaySales: false }))
    }
  }

  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(setShowBulkEditForm({ showFormState: !showBulkEditForm }))
    dispatch(onCancelBulkEdit())
  }

  useEffect(() => {
    const text = showBulkEditForm ? "Cancel" : "Bulk Actions"
    setButtonText(text)
  }, [showBulkEditForm])

  return (
    <>
      <h5 className="ms-4 mt-4">{`Inventory Allocation and Analysis for ${view.inventory.tour.code}: ${view.inventory.tour.name}`}</h5>
      <div className="ms-4 d-inline-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <h6>{`Filter ${view.inventory.ship.name}'s days in ${view.inventory.port.name}`}</h6>
          {isLoading && <LoadingSpinner className="align-self-center" />}
        </div>

        <Row className="mb-2">
          <Col md={2}>
            <MultiSelect name="weekDays" />
          </Col>
          {/* <Col md={2}>
            <MultiSelect name="shipPortTimes" />
          </Col> */}
          <Col md={2}>
            <MultiSelect name="issues" count={issueCount} />
          </Col>
          <Col md={2}>
            <MultiSelect name="dayStates" />
          </Col>
          <Col md={2}>
            <MultiSelect name="additionalShips" />
          </Col>
          <Col md={2}>
            <MultiSelect name="additionalTours" />
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Select name="season" />
          </Col>
          <Col md={2}>
            <SingleDateSelect name="startDate" />
          </Col>
          <Col md={2}>
            <SingleDateSelect name="endDate" />
          </Col>
          <Col md={2}>
            <MultipleDateSelect name="dates" />
          </Col>
        </Row>
      </div>
      <h5 className="ms-4 mt-4">{`${view.inventory.tour.code} Inventory Allotments`}</h5>
      <div>
        <h6 className="ms-4">{`${view.inventory.ship.name} in ${view.inventory.port.name}`}</h6>
      </div>
      {!isBlank(view.inventory.allocationTableData.callDays) && (
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            className="my-0 ms-4"
            inputId="sales1"
            name="sales"
            value="sales"
            onChange={onSalesChange}
            checked={display.includes("sales")}
          />
          <label className="ms-2 text-muted my-0">Show sales</label>
        </div>
      )}
      <BulkEditForm />
      {!isBlank(view.inventory.allocationTableData.callDays) && (
        <Col className="text-end mb-2 me-2">
          <Button variant="secondary" onClick={() => onClick()}>
            {buttonText}
          </Button>
        </Col>
      )}
    </>
  )
}

export default InventoryAllocationTableHeader
