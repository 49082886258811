import { connect } from "react-redux"
import _ from "lodash"

import OnScreenNotifications from "./OnScreenNotifications"
import { buildAxiosErrorHandler } from "./axios"
import Slice from "./Slice"

const buildMessageNotifier =
  (notifier, { ...options }) =>
  (dispatch, message = null) =>
  (result) => {
    // results are usually an Axios response object
    const resultMessage = _.get(result, "data.message") || _.get(result, "message")

    if (!message && _.isString(resultMessage)) {
      dispatch(notifier[options.type](resultMessage, options))
    } else {
      dispatch(notifier[options.type](message || "Successfully completed action", options))
    }

    return result
  }

const OSN = _.extend(
  OnScreenNotifications,
  /**
   * Given a dispatch function, returns a new error handler function that can be passed an axios error
   * response. The error handler will print out the error message on screen
   */
  { newAxiosErrorHandler: buildAxiosErrorHandler(OnScreenNotifications) },
  /**
   * Given a dispatch function and a message, returns a new promise callback that writes the
   * message to screen and return the original result.
   *
   * If no message is provided and the promised object has a `message` attribute, this is printed to
   * screen instead.
   */
  {
    newInfoHandler: buildMessageNotifier(OnScreenNotifications, {
      type: "info",
      duration: 2500,
    }),
    newSuccessHandler: buildMessageNotifier(OnScreenNotifications, {
      type: "success",
      duration: 2500,
    }),
  },
  { slice: Slice }
)

export const ConnectedOnScreenNotifications = connect(({ notifications }: any) => ({
  notifications,
}))(OSN)

export const { slice, newAxiosErrorHandler, newInfoHandler, newSuccessHandler } = OSN

export default OSN
