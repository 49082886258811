import React from "react"
import { Card } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getOrderedBookingForms } from "@/manual_booking/selectors"
import { renderBooking } from "@/manual_booking/reservationSystems"

const BookingFormCard = ({ booking }): JSX.Element => (
  <Card className="mb-4" key={booking.formId}>
    <Card.Body>{renderBooking(booking)}</Card.Body>
  </Card>
)

const BookingFormList = (): JSX.Element => {
  const bookings = useSelector((state) => getOrderedBookingForms(state))

  return (
    <>
      {bookings.map((booking) => (
        <BookingFormCard key={booking.formId} booking={booking} />
      ))}
    </>
  )
}

export default BookingFormList
