import { useEffect } from "react"
import _ from "lodash"

import Notifications from "@/notifications"

import { notifiedUser, notifyUser } from "./Slice"

// React component that uses the useEffect function to display notices on screen
const OnScreenNotifications = ({ notifications, dispatch, children }) => {
  useEffect(() => {
    _.each(notifications.pending, (n) => {
      Notifications[n.type](n.message, _.omit(n, ["type", "message"]))

      dispatch(notifiedUser({ id: n.id }))
    })
  }, [notifications])

  return children
}

// Define OnScreenNotifications.{info, warning, error, ...} notification functions
Notifications.Types.forEach((type) => {
  OnScreenNotifications[type] = (message, options = {}) =>
    notifyUser({
      message,
      type,
      ...options,
    })
})

export default OnScreenNotifications
