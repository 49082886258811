import React, { useRef, useEffect } from "react"

// Adding this element at the end of a list of items ensures that the view is scrolled to there.
// https://stackoverflow.com/a/61266099
const AlwaysScrollToBottom = () => {
  const elementRef = useRef()
  useEffect(() => elementRef.current && elementRef.current.scrollIntoView())
  return <div ref={elementRef} />
}

export default AlwaysScrollToBottom
