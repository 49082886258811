import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import VoyageItinerary from "./VoyageItinerary"

const GuestsCard = (): JSX.Element => (
  <div className="pb-6">
    <Card border="light" className="shadow-sm overflow-auto">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <VoyageItinerary />
          </Col>
        </Row>
      </Card.Header>
    </Card>
  </div>
)

export default GuestsCard
