import React from "react"
import _ from "lodash"

import { isTourBooking } from "@/manual_booking/productSelectors"

import { getSubmitableBookingForms } from "./selectors"
import { getBookingDescription } from "./bookingSelectors"
import TourBookingForm from "./TourBookingForm"
import ActionBarButtons from "./ActionBarButtons"
import { addCoupon } from "./Slice"

// TODO break coupling to the tourBookings slice name
export default {
  name: "tourBookings",
  getBookings: (state) =>
    _.chain(state.tourBookings.bookingForms)
      .filter(isTourBooking)
      .map((b) => ({
        formId: b.formId,
        description: getBookingDescription(b),
        price: b._price,
        createdAtMillis: b.createdAtMillis,
        kind: b.kind,
        hasGuests: b.passengerIds.length > 0,
        couponCodes: b.couponCodes,
      }))
      .value(),
  getBookingsForSubmission: (state) =>
    _.filter(getSubmitableBookingForms(state.tourBookings), isTourBooking),
  canRenderBooking: (booking) => booking && isTourBooking(booking),
  renderBooking: (booking) => <TourBookingForm formId={booking.formId} />,
  getActionBarButtons: () => <ActionBarButtons />,
  addCoupon,
}
