import React, { useState } from "react"

import _ from "lodash"
import { Form } from "react-bootstrap"
import useDelayedFocus from "@/helpers/useDelayedFocus"

import ContentVisibility from "@/components/ContentVisibility"

/**
 * Text Area which is opened by clicking a button. Focusses on the text area when toggled.
 */
const ToggledTextAreaFieldControl = ({
  value,
  onChange,
  hidden = false,
  label = "",
  toggleLabel = "",
  toggleClassName = "",
  helpText = undefined,
  hideBlankInput = true,
  children = null,
  rows = 3,
}) => {
  const [isInputOpen, openInput] = useState(false)
  const [ref, setMustFocus] = useDelayedFocus()

  const handleInputBlur = () => {
    if (_.isEmpty(value) && isInputOpen && hideBlankInput) openInput(false)
  }

  const renderFormControl = () => {
    if (_.isEmpty(value) && !isInputOpen) {
      return (
        <button
          className={`btn ${toggleClassName}`}
          onClick={(e) => {
            openInput(true)
            setMustFocus()
          }}
        >
          {toggleLabel}
        </button>
      )
    }
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          rows={rows}
          value={value}
          onBlur={handleInputBlur}
          onChange={onChange}
          ref={ref}
        />
        {children}
        {helpText && <div className="form-text">{helpText}</div>}
      </Form.Group>
    )
  }
  return <ContentVisibility hidden={hidden}>{renderFormControl()}</ContentVisibility>
}

export default ToggledTextAreaFieldControl
