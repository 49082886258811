import React from "react"
import { useSelector } from "react-redux"

import { getReceivedTicketDifference } from "@/daily_settlement/Slice"

const ReceivedTicketDifference = ({ settlementSheetId }) => {
  const difference = useSelector(getReceivedTicketDifference(settlementSheetId))

  if (difference === null) {
    return null
  }
  if (difference === 0) {
    return <div className="text-success">{difference}</div>
  }
  if (difference < 0) {
    return <div className="text-danger">{difference}</div>
  }
  return <div className="text-danger">{`+ ${difference}`}</div>
}

export default ReceivedTicketDifference
