import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { v4 as uuid } from "uuid"

import { requireKeys } from "@/utils"

const strictAddReducers = (reducers, newReducers) =>
  _.reduce(
    newReducers,
    (memo, reducer, name) => {
      if (_.has(memo, name)) {
        throw new Error(`${name} is already defined as a reducer.`)
      }

      return { ...memo, [name]: reducer }
    },
    reducers
  )

export const createBookingsSlice = ({
  name,
  initialState = {},
  initialize = (state, action) => state,
  reducers = {},
  newBookingForm,
  ...extras
}) => {
  const newReducers = strictAddReducers(reducers, {
    initialize: (state, action) => {
      const payload = (action.payload || {})[name] || {}

      state.fields = payload.fields || {}

      _.each(payload.bookings, (b) => {
        const formId = uuid()
        state.bookingForms[formId] = newBookingForm({ ...b, formId })
      })

      state.initialized = true

      return initialize(state, action)
    },
    removeBookingForm: (state, action) => {
      requireKeys(action.payload, "formId")

      const { formId } = action.payload

      state.bookingForms = _.omit(state.bookingForms, formId)

      return state
    },
    copyBookingForm: (state, action) => {
      requireKeys(action.payload, "sourceFormId")

      const { sourceFormId, ...overrides } = action.payload

      const sourceForm = state.bookingForms[sourceFormId] || {}

      const formId = action.payload.formId || uuid()

      state.bookingForms[formId] = newBookingForm({
        ...sourceForm,
        ...overrides,
        createdAtMillis: Date.now(),
        formId,
      })

      return state
    },
  })

  return createSlice({
    initialState: {
      initialized: false,
      fields: {},
      bookingForms: {},
      ...initialState,
    },
    name,
    reducers: newReducers,
    ...extras,
  })
}
