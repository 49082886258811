import _ from "lodash"

import EditorModes from "./models/EditorModes"

export const getState = ({ documentEditor }) => documentEditor

export const getDocument = ({ documentEditor }) => documentEditor.document

export const getMode = ({ documentEditor }): EditorModes => documentEditor.editorMode

export const getSection =
  (sectionId) =>
  ({ documentEditor }) =>
    _.find(documentEditor.document.sections, (section) => section.id === sectionId)
