import React from "react"
import { Button, Form, Modal, Row, Col, Table } from "react-bootstrap"
import { Formik, FieldArray } from "formik"

import AuthenticityToken from "@/components/AuthenticityToken"
import { update_stickers_tour_path } from "@/routes"
import Tour from "@/dashboard/models/Tour"
import Api from "@/api"
import useDelayedState from "@/helpers/useDelayedState"
import DelayedComponent from "@/components/DelayedComponent"

const SpecialRequirementsInfo = ({ tourId }) => {
  const bookingsControl = useDelayedState({
    initialState: [],
    loadFn: (control, context) => {
      Api.dashboardView
        .specialRequirementBookings({ tourId })
        .then((response) => {
          control.setSuccess(response.data.data.bookings)
        })
        .catch((error) => {
          control.setFailed()
        })
    },
  })

  const renderBookings = (bookings) => {
    if (!bookings || bookings.length === 0) {
      return <p className="mb-0">There are no bookings with special requirements for this tour.</p>
    }

    return (
      <>
        <h5>Special Requirement Bookings</h5>
        <Table size="sm" className="align-items-center table-flush mb-0">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="px-1">
                Reference
              </th>
              <th scope="col" className="px-1">
                Charged Guest
              </th>
              <th scope="col" className="px-1">
                Room
              </th>
              <th scope="col" className="px-1">
                Requirement
              </th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.bookingId}>
                <td>{booking.reference}</td>
                <td>{booking.leadPassenger.name}</td>
                <td>{booking.leadPassenger.roomNumber}</td>
                <td>{booking.specialRequirements}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )
  }

  return (
    <DelayedComponent onReload={bookingsControl.reload} state={bookingsControl.state}>
      {renderBookings(bookingsControl.value)}
    </DelayedComponent>
  )
}

const StickerModal = ({
  show,
  tour,
  onHide,
}: {
  show: boolean
  tour: Tour
  onHide: () => void
}): JSX.Element => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="update-stickers-tour-modal-title"
    centered
  >
    <Formik initialValues={{ data: tour.stickers }} onSubmit={(values) => console.log(values)}>
      {({ values, handleChange, setFieldValue }) => (
        <Form action={update_stickers_tour_path({ id: tour.tourId })} method="post">
          <AuthenticityToken />
          <Modal.Header>
            <Modal.Title id="update-stickers-tour-modal-title">
              {`${tour.name} (${tour.code}) Stickers - ${tour.confirmedGuests} Guests`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Color</Form.Label>
              <Form.Control
                as="select"
                name="data[color]"
                value={values.data.color}
                onChange={handleChange}
              >
                <option>Red</option>
                <option>Green</option>
                <option>Blue</option>
                <option>Yellow</option>
                <option>Green</option>
                <option>Violet</option>
                <option>White</option>
                <option>Black</option>
              </Form.Control>
            </Form.Group>
            <FieldArray
              name="data[stickers]"
              render={(arrayHelpers) => (
                <>
                  {values.data.stickers &&
                    values.data.stickers.length > 0 &&
                    values.data.stickers.map((sticker, index) => (
                      <Row key={index} className="mb-3 align-items-end">
                        <Col xs={2}>
                          <Form.Group>
                            <Form.Label>Count</Form.Label>
                            <Form.Control
                              name="data[stickers][][pax]"
                              value={sticker.pax}
                              onChange={(e) =>
                                setFieldValue(`data[stickers][${index}][pax]`, e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              name="data[stickers][][notes]"
                              value={sticker.notes}
                              onChange={(e) =>
                                setFieldValue(`data[stickers][${index}][notes]`, e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Button onClick={() => arrayHelpers.remove(index)} variant="danger">
                            <i className="fas fa-trash me-2" />
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  <Button onClick={() => arrayHelpers.push({ pax: 0, notes: "" })} className="mt-1">
                    <i className="fas fa-plus me-2" />
                    Add Sticker
                  </Button>
                </>
              )}
            />
            <Row className="mt-3">
              <Col>
                <SpecialRequirementsInfo tourId={tour.tourId} />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>Close</Button>
            <Button type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)

export default StickerModal
