import React, { FC, useState } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import {
  getProduct,
  getStopsWithComponents,
  getFieldSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { useModal } from "@/helpers/useModal"
import TourStopsEdit from "@/onboarding/features/product/components/tour_stops/Edit"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

import * as RateTable from "./RateTable"

const StopSection = ({ stop }): JSX.Element => (
  <>
    <tr>
      <td colSpan={3}>
        <span className="ps-2">{stop.name}</span>
      </td>
    </tr>
    {stop.components.map((component, index) => (
      <RateTable.Row key={`${component.name}-${index}`} ratesheet={component.ratesheet}>
        <td>
          <span className="ps-4">
            <TextDisplay value={component.name} />
          </span>
        </td>
      </RateTable.Row>
    ))}
  </>
)

const StopComponentTable: FC = () => {
  const stops = useSelector(getStopsWithComponents)
  const { tour_stops } = useSelector(getFieldSettings(ReservedFields.TOUR_STOPS))
  const label = tour_stops.required ? "Tour Segments *" : "Tour Segments"

  const { componentTotals } = useSelector(getProduct)
  const [expanded, setExpanded] = useState(true)

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Tour Segments" Body={TourStopsEdit} />
  ))

  if (_.isEmpty(stops)) {
    return null
  }

  const totalRatesheet = _.find(componentTotals, (t) => t.name === ReservedFields.TOUR_STOPS).total

  return (
    <Table>
      <thead>
        <RateTable.HeaderRow editIcon={<EditIcon onEdit={toggleModal} />}>
          <th className="d-flex gap-2 cursor-pointer" onClick={() => setExpanded(!expanded)}>
            <span>{label}</span>
            <span className={expanded ? "d-none" : ""}>
              <i key="down-icon" className="fas fa-chevron-down" />
            </span>
            <span className={expanded ? "" : "d-none"}>
              <i key="up-icon" className="fas fa-chevron-up" />
            </span>
          </th>
        </RateTable.HeaderRow>
      </thead>
      <tbody>
        {expanded &&
          stops.map((stop, index) => <StopSection key={`${stop.name}-${index}`} stop={stop} />)}
        <RateTable.Row ratesheet={totalRatesheet}>
          <th>
            <span className="ps-2">Total</span>
          </th>
        </RateTable.Row>
      </tbody>
    </Table>
  )
}

export default StopComponentTable
