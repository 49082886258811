import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"
import { getTourBooking, getLoggedInPassenger } from "@/guests_booked_tour_account/selectors"
import Slice, { initialize } from "@/guests_booked_tour_account/Slice"
import Navbar from "@/guests_footer/Navbar"
import { withApp } from "@/components/App"

import Header from "./Header"
import TourGuestsCard from "./TourGuestsCard"
import TourInfoCard from "./TourInfoCard"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getTourBooking)
  const guestId = useSelector(getLoggedInPassenger)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <div className="pb-6">
      <Header />
      <TourGuestsCard />
      <TourInfoCard />
      <Navbar page="Account" guestId={guestId} />
    </div>
  )
}

export default withApp(Initializer, { slices: [Slice] })
