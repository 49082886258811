import React from "react"

const TicketPreview = ({ url }: { url: string }): JSX.Element => (
  <div className="embed-responsive">
    <iframe
      title="ticket-preview"
      className="embed-responsive-item w-100"
      style={{ height: 500 }}
      src={url}
    />
  </div>
)

export default TicketPreview
