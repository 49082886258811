import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"
import { getState } from "@/document_editor/selectors"
import { initialize } from "@/document_editor/Slice"

import SectionController from "./sections/SectionController"

const Editor = ({ document, editorMode, availableOperators = [] }): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getState)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ document, editorMode, availableOperators }))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return <SectionController />
}

export default Editor
