import React from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { Form, InputGroup } from "react-bootstrap"

const BootstrapSearchInput = (props) => {
  const { isLoading, onTypeAheadRef, ...remainderProps } = props

  return (
    <AsyncTypeahead
      {...remainderProps}
      isLoading={false}
      emptyLabel={isLoading ? "Loading..." : "No results"}
      ref={(ref) => {
        onTypeAheadRef && onTypeAheadRef(ref)
      }}
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <div className="d-flex">
          <InputGroup className="flex-grow-1">
            <InputGroup.Text>
              <span className="fa fa-search" />
            </InputGroup.Text>
            <Form.Control
              {...inputProps}
              ref={(input) => {
                // Be sure to correctly handle these refs. In many cases, both can simply receive
                // the underlying input node, but `referenceElementRef can receive a wrapper node if
                // your custom input is more complex (See TypeaheadInputMulti for an example).
                inputRef(input)
                referenceElementRef(input)
              }}
            />
          </InputGroup>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading ? (
              <div className="rbt-loader spinner-border spinner-border-sm" role="status">
                <span className="sr-only" />
              </div>
            ) : null}
          </div>
        </div>
      )}
    />
  )
}

export default BootstrapSearchInput
