import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getField, getProduct } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { getTitle } from "@/onboarding/features/product/components/product_components/Section"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"

import * as RateTable from "./RateTable"
import ComponentRateEdit from "./ComponentRateEdit"

const GeneralComponentTable = ({ fieldName }): JSX.Element => {
  const field = useSelector(getField(fieldName))
  const { componentTotals } = useSelector(getProduct)
  const [expanded, setExpanded] = useState(true)

  const toggleModal = useModal((props) => (
    <EditModal
      {...props}
      title={getTitle(fieldName)}
      Body={ComponentRateEdit}
      bodyProps={{ fieldName }}
    />
  ))

  const totalRatesheet = _.find(componentTotals, (t) => t.name === fieldName).total
  const title = field.required ? `${getTitle(fieldName)} *` : getTitle(fieldName)

  return (
    <Table>
      <thead>
        <RateTable.HeaderRow editIcon={field.writeable && <EditIcon onEdit={toggleModal} />}>
          <th className="d-flex gap-2 cursor-pointer" onClick={() => setExpanded(!expanded)}>
            <span>{title}</span>
            <span className={expanded ? "d-none" : ""}>
              <i key="down-icon" className="fas fa-chevron-down" />
            </span>
            <span className={expanded ? "" : "d-none"}>
              <i key="up-icon" className="fas fa-chevron-up" />
            </span>
          </th>
        </RateTable.HeaderRow>
      </thead>
      <tbody>
        {expanded &&
          field.value.map((component, index) => (
            <RateTable.Row key={`${component.name}-${index}`} ratesheet={component.ratesheet}>
              <td>
                <span className="ps-2">
                  <TextDisplay value={component.name} />
                </span>
              </td>
            </RateTable.Row>
          ))}
        <RateTable.Row ratesheet={totalRatesheet}>
          <th>
            <span className="ps-2">Total</span>
          </th>
        </RateTable.Row>
      </tbody>
    </Table>
  )
}

export default GeneralComponentTable
