import React from "react"
import { Table } from "react-bootstrap"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import Tour from "@/dashboard/models/Tour"

import TourRow from "./TourRow"
import TourFooter from "./TourFooter"

const TourTable = ({
  tours,
  showDate = false,
  showTotals = true,
}: {
  tours: Array<Tour>
}): JSX.Element => (
  <Table responsive size="sm" className="align-items-center table-flush">
    <thead className="thead-light">
      <tr>
        {showDate && (
          <th scope="col" className="text-left">
            Date
          </th>
        )}
        <th scope="col">Departure</th>
        <th scope="col">Tour</th>
        <OperatorOnly>
          <th scope="col">Cruise Line</th>
        </OperatorOnly>
        <th scope="col">Min / Max</th>
        <th scope="col" className="text-right">
          Guests
        </th>
        <th scope="col" className="text-right">
          A (W)
        </th>
        <CruiseLineOnly>
          <th scope="col">Cut-off</th>
        </CruiseLineOnly>
        <OperatorOnly>
          <th scope="col" className="text-right">
            Net
          </th>
        </OperatorOnly>
        <CruiseLineOnly>
          <th scope="col" className="text-right">
            Retail
          </th>
        </CruiseLineOnly>
        <CruiseLineOnly>
          <th scope="col" className="text-center px-0">
            Recon
          </th>
        </CruiseLineOnly>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      {tours.map((tour) => (
        <TourRow tour={tour} key={tour.tourId} showDate={showDate} />
      ))}
    </tbody>
    {showTotals && <TourFooter />}
  </Table>
)

export default TourTable
