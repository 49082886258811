import React from "react"
import { Card } from "react-bootstrap"
import Header from "./Header"
import CapacityAllotments from "./CapacityAllotments"
import InventoryAllocationTableHeader from "./InventoryAllocationTableHeader"
import InventoryAllocationTable from "./InventoryAllocationTable"

const Dashboard = (): JSX.Element => (
  <Card>
    <Header />
    <CapacityAllotments />
    <InventoryAllocationTableHeader />
    <InventoryAllocationTable />
  </Card>
)

export default Dashboard
