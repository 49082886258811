import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"
import { getInsurance, getStatusLabels } from "../selectors"
import StatusIcon from "../../StatusIcon"

const InsuranceMetrics = (): JSX.Element => {
  const { _view } = useSelector(getInsurance)
  const statusLabels = useSelector(getStatusLabels)

  return (
    <Table className="table align-items-center mb-3">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Insurance Status</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        {_.map(_view.value.insuranceOverview.statusMetrics, (count, insuranceStatus) => (
          <tr key={insuranceStatus}>
            <td>
              <StatusIcon
                status={insuranceStatus}
                label={_.startCase(statusLabels[insuranceStatus])}
              />
              {insuranceStatus == "pending"
                ? " Ready for review"
                : ` ${_.startCase(statusLabels[insuranceStatus])}`}
            </td>
            <td>{count}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default InsuranceMetrics
