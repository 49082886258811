import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"
import { LoadState } from "@/helpers/AsyncField"
import { isBlank } from "@/utils"

const findAppliedProductFilters = ({ productId, biddingRegions }) => {
  let biddingRegionId = null
  let portId = null

  _.each(biddingRegions, (biddingRegion) => {
    _.each(biddingRegion.ports, (port) => {
      if (_.includes(_.map(port.products, "id"), productId)) {
        biddingRegionId = biddingRegion.id
        portId = port.id

        return { biddingRegionId, portId }
      }
    })
  })

  return { biddingRegionId, portId }
}

export enum PageTypes {
  DASHBOARD = "dashboard",
  PRODUCT = "product",
  COMPARISON = "comparison",
}

export enum ViewTypes {
  BREAKDOWN_VARIANCE = "breakdown_variance",
  BREAKDOWN = "breakdown",
  AGGREGATE_VARIANCE = "aggregate_variance",
  AGGREGATE = "aggregate",
}

export const LAST_SELECTED_BIDDING_REGION_ID_PATH = "onboarding.priceAnalysis.lastBiddingRegionId"
export const LAST_SELECTED_AWARD_SET_ID_PATH = "onboarding.priceAnalysis.lastAwardSetId"

export const selectBiddingRegion = createAsyncThunk(
  "priceAnalysis/selectBiddingRegion",
  async ({ selectedBiddingRegionId }) => {
    window.localStorage.setItem(LAST_SELECTED_BIDDING_REGION_ID_PATH, selectedBiddingRegionId)

    return { selectedBiddingRegionId }
  }
)

export const selectAwardSet = createAsyncThunk(
  "priceAnalysis/selectAwardSet",
  async ({ selectedAwardSetId }) => {
    window.localStorage.setItem(LAST_SELECTED_AWARD_SET_ID_PATH, selectedAwardSetId)

    return { selectedAwardSetId }
  }
)

export const assignOperator = AsyncField.createLoadThunk(
  "priceAnalysis/assignOperator",
  (_action) => "_season",
  async ({ awardSetId, productId, operatorId }, thunkAPI) => {
    const response = await Api.priceAnalysis
      .assignOperator(awardSetId, productId, operatorId)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.season,
    }
  }
)

export const unassignOperator = AsyncField.createLoadThunk(
  "priceAnalysis/unassignOperator",
  (_action) => "_season",
  async ({ awardSetId, productId, operatorId }, thunkAPI) => {
    const response = await Api.priceAnalysis
      .unassignOperator(awardSetId, productId, operatorId)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.season,
    }
  }
)

export const confirmProspectiveAwardSet = AsyncField.createLoadThunk(
  "priceAnalysis/confirmProspectiveAwardSet",
  (_action) => "_season",
  async ({ awardSetId }, thunkAPI) => {
    const response = await Api.priceAnalysis
      .confirmProspectiveAwardSet(awardSetId)
      .then(newSuccessHandler(thunkAPI.dispatch, "Award set confirmed"))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.season,
    }
  }
)

const Slice = createSlice({
  name: "priceAnalysis",
  initialState: {
    initialized: false,
    showBaseline: false,
    groupByBrand: false,
    selectedBiddingRegionId: "",
    costAnalysisRegionId: "",
    selectedPortId: "",
    selectedAwardSetId: "",
    expandedPortId: "",
    expandedCruiseBrandId: "",
    selectedProductId: "",
    selectedCruiseBrandId: "",
    selectedOperatorId: "",
    selectedView: ViewTypes.BREAKDOWN,
    page: PageTypes.DASHBOARD,
    _season: {},
  },
  reducers: {
    initialize: (state, { payload }) => {
      const newState = {
        ...state,
        initialized: true,
        showBaseline: false,
        selectedBiddingRegionId:
          !isBlank(payload.selectedBiddingRegionId) &&
          payload.selectedBiddingRegionId !== "[object Object]"
            ? payload.selectedBiddingRegionId
            : _(payload.season.biddingRegions).map("id").head(),
        selectedAwardSetId:
          payload.selectedAwardSetId ||
          _(payload.season.biddingRegions).flatMap("prospectiveAwardSets").map("id").head(),
        _season: AsyncField.createField({
          value: payload.season,
          defaultValue: payload.season,
          state: LoadState.Ready,
        }),
      }

      if (!_.isNil(payload.selectedProductId) && payload.selectedProductId !== "") {
        const { biddingRegionId, portId } = findAppliedProductFilters({
          productId: payload.selectedProductId,
          biddingRegions: payload.season.biddingRegions,
        })

        if (!_.isNil(biddingRegionId)) {
          newState.selectedBiddingRegionId = biddingRegionId
          newState.expandedPortId = portId
          newState.selectedProductId = payload.selectedProductId
          newState.page = PageTypes.PRODUCT
        }
      }

      return newState
    },
    expandPort: (state, { payload }) => ({
      ...state,
      expandedPortId: payload.portId,
    }),
    minimizePort: (state) => ({
      ...state,
      expandedPortId: "",
    }),
    expandCruiseBrand: (state, { payload }) => ({
      ...state,
      expandedCruiseBrandId: payload.cruiseBrandId,
    }),
    minimizeCruiseBrand: (state) => ({
      ...state,
      expandedCruiseBrandId: "",
    }),
    selectProduct: (state, { payload }) => ({
      ...state,
      selectedProductId: payload.productId,
      page: PageTypes.PRODUCT,
    }),
    selectCruiseBrand: (state, { payload }) => ({
      ...state,
      selectedCruiseBrandId: payload.cruiseBrandId,
    }),
    selectOperator: (state, { payload }) => ({
      ...state,
      selectedOperatorId: payload.operatorId,
    }),
    selectPort: (state, { payload }) => ({
      ...state,
      selectedPortId: payload.portId,
    }),
    navigateToDashboard: (state, { payload }) => ({
      ...state,
      costAnalysisRegionId: payload.biddingRegionId,
      page: PageTypes.DASHBOARD,
    }),
    navigateToProduct: (state, { payload }) => ({
      ...state,
      selectedBiddingRegionId: payload.biddingRegionId,
      page: PageTypes.PRODUCT,
    }),
    navigateToComparisonPage: (state) => ({
      ...state,
      page: PageTypes.COMPARISON,
    }),
    selectView: (state, { payload }) => ({
      ...state,
      selectedView: payload.selectedView,
    }),
    updateShowBaseline: (state, { payload }) => ({
      ...state,
      showBaseline: payload.showBaseline,
    }),
    updateGroupByBrand: (state, { payload }) => ({
      ...state,
      groupByBrand: payload.groupByBrand,
    }),
  },
  extraReducers: {
    ...assignOperator.reducers,
    ...unassignOperator.reducers,
    ...confirmProspectiveAwardSet.reducers,
    [selectBiddingRegion.fulfilled]: (state, { payload }) => ({
      ...state,
      selectedBiddingRegionId: payload.selectedBiddingRegionId,
    }),
    [selectAwardSet.fulfilled]: (state, { payload }) => ({
      ...state,
      selectedAwardSetId: payload.selectedAwardSetId,
    }),
  },
})

export const {
  initialize,
  expandPort,
  expandCruiseBrand,
  minimizePort,
  minimizeCruiseBrand,
  selectProduct,
  selectCruiseBrand,
  selectPort,
  navigateToDashboard,
  navigateToProduct,
  navigateToComparisonPage,
  selectView,
  updateShowBaseline,
  updateGroupByBrand,
  selectOperator,
} = Slice.actions

export default Slice
