import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import {
  getFieldValues,
  getSectionFieldNames,
  getFieldSettings,
} from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ReferencedValueTypes, ReservedFields } from "@/onboarding/features/product/models/Product"
import { DAY_OF_WEEK_OPTIONS } from "@/onboarding/features/product/models/Field"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"

const Edit = ({ setEditing }): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.tourDetails)
  const initialValues = useSelector(getFieldValues(...fieldNames))
  const fieldsSettings = useSelector(getFieldSettings(...fieldNames))

  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(updateFields({ values }))
      }}
    >
      {({ handleSubmit }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormikBootstrap.NumberInput
                name={ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX}
                label="Minimum Per Departure"
                readOnly={!fieldsSettings[ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX].writeable}
                showLockedIcon={
                  !fieldsSettings[ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX].writeable
                }
                showRequired={fieldsSettings[ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.NumberInput
                name={ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX}
                label="Maximum Per Departure"
                readOnly={!fieldsSettings[ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX].writeable}
                showLockedIcon={
                  !fieldsSettings[ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX].writeable
                }
                showRequired={fieldsSettings[ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.NumberInput
                name={ReservedFields.TARGET_MAXIMUM_CALL_PAX}
                label="Maximum Per Call"
                readOnly={!fieldsSettings[ReservedFields.TARGET_MAXIMUM_CALL_PAX].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.TARGET_MAXIMUM_CALL_PAX].writeable}
                showRequired={fieldsSettings[ReservedFields.TARGET_MAXIMUM_CALL_PAX].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.NumberInput
                name={ReservedFields.TARGET_DEPARTURES}
                label="Departures"
                readOnly={!fieldsSettings[ReservedFields.TARGET_DEPARTURES].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.TARGET_DEPARTURES].writeable}
                showRequired={fieldsSettings[ReservedFields.TARGET_DEPARTURES].required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ReferencedValueSelect
                name={ReservedFields.TOUR_TYPE}
                type={ReferencedValueTypes.TOUR_TYPE}
                label="Tour Types"
                isDisabled={!fieldsSettings[ReservedFields.TOUR_TYPE].writeable}
                showRequired={fieldsSettings[ReservedFields.TOUR_TYPE].required}
              />
            </Col>
            <Col md={3}>
              <ReferencedValueSelect
                name={ReservedFields.ACTIVITY_TYPE}
                type={ReferencedValueTypes.ACTIVITY_TYPE}
                label="Activity Type"
                isDisabled={!fieldsSettings[ReservedFields.ACTIVITY_TYPE].writeable}
                showRequired={fieldsSettings[ReservedFields.ACTIVITY_TYPE].required}
              />
            </Col>
            <Col md={3}>
              <ReferencedValueSelect
                name={ReservedFields.ACTIVITY_LEVEL}
                type={ReferencedValueTypes.ACTIVITY_LEVEL}
                label="Activity Level"
                isDisabled={!fieldsSettings[ReservedFields.ACTIVITY_LEVEL].writeable}
                showRequired={fieldsSettings[ReservedFields.ACTIVITY_LEVEL].required}
              />
            </Col>
            <Col md={3} className="d-flex align-items-center">
              <FormikBootstrap.Switch
                name={ReservedFields.ADA_COMPLIANT}
                label="ADA Compliant"
                disabled={!fieldsSettings[ReservedFields.ADA_COMPLIANT].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.ADA_COMPLIANT].writeable}
                showRequired={fieldsSettings[ReservedFields.ADA_COMPLIANT].required}
              />
            </Col>
          </Row>
          <FormikBootstrap.TextAreaInput
            rows={5}
            name={ReservedFields.TOUR_DESCRIPTION}
            label="Tour Description"
            readOnly={!fieldsSettings[ReservedFields.TOUR_DESCRIPTION].writeable}
            showLockedIcon={!fieldsSettings[ReservedFields.TOUR_DESCRIPTION].writeable}
            showRequired={fieldsSettings[ReservedFields.TOUR_DESCRIPTION].required}
          />
          <FormikBootstrap.TextAreaInput
            name={ReservedFields.SHORT_TOUR_DESCRIPTION}
            label="Tour Description (Less Than 1000 Characters)"
            readOnly={!fieldsSettings[ReservedFields.SHORT_TOUR_DESCRIPTION].writeable}
            showLockedIcon={!fieldsSettings[ReservedFields.SHORT_TOUR_DESCRIPTION].writeable}
            showRequired={fieldsSettings[ReservedFields.SHORT_TOUR_DESCRIPTION].required}
          />
          <FormikBootstrap.TextAreaInput
            name={ReservedFields.TOUR_NOTES}
            label="Tour Notes"
            readOnly={!fieldsSettings[ReservedFields.TOUR_NOTES].writeable}
            showLockedIcon={!fieldsSettings[ReservedFields.TOUR_NOTES].writeable}
            showRequired={fieldsSettings[ReservedFields.TOUR_NOTES].required}
          />
          <FormikBootstrap.TextInput
            name={ReservedFields.TOP_THREE_REASONS}
            label="Top Three Reasons A Guest Should Buy This Tour"
            readOnly={!fieldsSettings[ReservedFields.TOP_THREE_REASONS].writeable}
            showLockedIcon={!fieldsSettings[ReservedFields.TOP_THREE_REASONS].writeable}
            showRequired={fieldsSettings[ReservedFields.TOP_THREE_REASONS].required}
          />
          <Row>
            <Col>
              <FormikBootstrap.MultiSelect
                name={ReservedFields.UNAVAILABLE_DAYS}
                label="Days On Which The Tour Will Be Unavailable"
                options={DAY_OF_WEEK_OPTIONS}
                showLockedIcon={!fieldsSettings[ReservedFields.UNAVAILABLE_DAYS].writeable}
                disabled={!fieldsSettings[ReservedFields.UNAVAILABLE_DAYS].writeable}
                showRequired={fieldsSettings[ReservedFields.UNAVAILABLE_DAYS].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.TextInput
                name={ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION}
                label="Other Relevant Information On Days And Times The Tour Is Not Available"
                readOnly={
                  !fieldsSettings[ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION].writeable
                }
                showLockedIcon={
                  !fieldsSettings[ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION].writeable
                }
                showRequired={
                  fieldsSettings[ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION].required
                }
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button size="sm" type="submit" disabled={isSubmitting}>
              <i className="fas fa-save me-2" />
              Save
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
