import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { initialize, PageTypes } from "@/onboarding/features/price_analysis/Slice"
import { getPriceAnalysis } from "@/onboarding/features/price_analysis/selectors"
import LoadingSpinner from "@/components/LoadingSpinner"

import Product from "./components/Product"
import Comparison from "./components/Comparison"
import Dashboard from "./components/Dashboard"

const PriceAnalysis = ({
  season,
  selectedBiddingRegionId,
  selectedAwardSetId,
  selectedProductId,
}): JSX.Element => {
  const dispatch = useDispatch()

  const { initialized, page } = useSelector(getPriceAnalysis)

  useEffect(() => {
    if (!initialized) {
      dispatch(
        initialize({
          season,
          selectedBiddingRegionId,
          selectedAwardSetId,
          selectedProductId,
        })
      )
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  if (page === PageTypes.PRODUCT) {
    return <Product />
  }

  if (page === PageTypes.COMPARISON) {
    return <Comparison />
  }

  return <Dashboard />
}

export default PriceAnalysis
