import React, { FC, useState } from "react"
import { Button, Form, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import {
  getOverheadCategories,
  getDefaultRateCategories,
} from "@/onboarding/features/product/selectors"
import {
  appendFieldListItem,
  removeFieldListItem,
  updateFieldListItem,
} from "@/onboarding/features/product/Slice"
import { newComponent, ComponentType } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import FormikBootstrap from "@/components/formik-bootstrap"
import RateInputs from "@/onboarding/features/product/components/shared/RateInputs"

const ComponentEditForm = ({ component, index, onCancel }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={component}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        dispatch(updateFieldListItem({ fieldName: component.fieldName, index, value: values }))
        setSubmitting(false)
        onCancel()
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <FormikBootstrap.TextInput name="name" label="Name" />
          <FormikBootstrap.TextInput name="description" label="Description" />
          <RateInputs name="ratesheet" />
          <div className="d-flex gap-3">
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <Button variant="danger" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

// TODO This and all the other Edit components are kind of merging into one potentially
// reusable component.
const Edit: FC = () => {
  const [editingComponent, setEditingComponent] = useState(null)
  const dispatch = useDispatch()
  const components = useSelector(getOverheadCategories)
  const defaultRateCategories = useSelector(getDefaultRateCategories)

  const addComponent = () => {
    dispatch(
      appendFieldListItem({
        fieldName: ReservedFields.OVERHEAD_CATEGORIES,
        value: newComponent({ defaultRateCategories, kind: ComponentType.OVERHEAD }),
      })
    )
  }

  const cancelEdit = () => setEditingComponent(null)

  if (editingComponent !== null) {
    return (
      <ComponentEditForm
        component={components[editingComponent]}
        index={editingComponent}
        onCancel={cancelEdit}
      />
    )
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {components.length === 0 && (
            <tr>
              <td colSpan={3}>No components loaded</td>
            </tr>
          )}
          {components.map((component, index) => (
            <tr key={`${component.name}-${index}`}>
              <td>
                <TextDisplay value={component.name} />
              </td>
              <td>
                <TextDisplay value={component.description} />
              </td>
              <td>
                <div className="d-flex justify-content-end gap-3">
                  <span className="cursor-pointer" onClick={() => setEditingComponent(index)}>
                    <i className="fas fa-edit" />
                  </span>
                  <span
                    className="cursor-pointer text-danger"
                    onClick={() =>
                      dispatch(
                        removeFieldListItem({
                          fieldName: ReservedFields.OVERHEAD_CATEGORIES,
                          index,
                        })
                      )
                    }
                  >
                    <i className="fas fa-trash" />
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button size="sm" variant="outline-primary" onClick={addComponent}>
        <i className="fas fa-plus me-2" />
        Add Component
      </Button>
    </>
  )
}

export default Edit
