import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import BiddingRegionDropdown from "./header/BiddingRegionDropdown"
import AwardBidsButton from "./header/AwardBidsButton"
import BackToDashboardButton from "./header/BackToDashboardButton"
import SummaryTable from "./dashboard/SummaryTable"
import CruiseBrandDropdown from "./header/CruiseBrandDropdown"
import OperatorDropdown from "./header/OperatorDropdown"
import PortDropdown from "./header/PortDropdown"
import { getSelectedBiddingRegion, isGroupByBrand } from "../selectors"
import CruiseBrandTable from "./comparison/CruiseBrandTable"
import PortTable from "./comparison/PortTable"

const Comparison = (): JSX.Element => {
  const biddingRegion = useSelector(getSelectedBiddingRegion)
  const groupByBrand = useSelector(isGroupByBrand)

  return (
    <Card>
      <Card.Header className="d-inline-flex flex-column gap-4">
        <Row>
          <Col md={6}>
            <h1 className="h4">Cost Comparison</h1>
            {biddingRegion && (
              <span className="text-muted">{`Bidding Region: ${biddingRegion.name}`}</span>
            )}
          </Col>
          <Col className="d-inline-flex justify-content-end gap-2">
            <div>
              <AwardBidsButton />
            </div>
            <div>
              <BackToDashboardButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <CruiseBrandDropdown />
          </Col>
          <Col md={2}>
            <BiddingRegionDropdown />
          </Col>
          <Col md={2}>
            <PortDropdown />
          </Col>
          <Col md={2}>
            <OperatorDropdown />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="d-inline-flex flex-column gap-4">
        <SummaryTable />
        {groupByBrand ? <CruiseBrandTable /> : <PortTable />}
      </Card.Body>
    </Card>
  )
}

export default Comparison
