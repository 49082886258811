import React from "react"
import { useSelector } from "react-redux"
import { Card } from "react-bootstrap"
import _ from "lodash"
import { formatDate } from "@/guests_booked_tour_account/helpers/Dates"
import { getTourDetails } from "../selectors"

const TourInfoCard = (): JSX.Element => {
  const tourDetails = useSelector(getTourDetails)
  const meetingDate = formatDate(tourDetails.meetingTime).date
  const meetingTime = formatDate(tourDetails.meetingTime).time

  return (
    <Card className="mt-4">
      <h6 className="mb-3 mt-3 ms-3 pe-3 lh-base">{tourDetails.reviewDescription}</h6>
      <div className="mb-3 ms-3 pe-3">
        <p className="fw-bold d-inline">Departure Point: </p>
        All guests to meet at the {tourDetails.meetingLocation} on {meetingDate} at {meetingTime}.
      </div>
      <div className="mb-2 ms-3 pe-3">
        <p className="fw-bold d-inline">Inclusions:</p>
        <ul>
          {_.map(tourDetails.inclusions, (value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      </div>
      <div className="mb-3 ms-3 pe-3">
        <p className="fw-bold d-inline">Requirements: </p>
        <ul>
          {_.map(tourDetails.requirements, (value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      </div>
    </Card>
  )
}

export default TourInfoCard
