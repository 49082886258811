import React from "react"

import { OrangeExclamation, GreenCheckMark } from "@/components/Icons"

const StatusIcon = ({ isValid, className = "" }) => {
  if (isValid) {
    return (
      <div className={`d-inline ${className}`}>
        <GreenCheckMark />
      </div>
    )
  }
  if (isValid === false) {
    return (
      <div className={`d-inline ${className}`}>
        <OrangeExclamation title="Form has errors" />
      </div>
    )
  }
  return null
}

export default StatusIcon
