import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"

import { duplicate_tour_path } from "routes"
import { DateTimePicker } from "@/components/DatePickers"
import AuthenticityToken from "@/components/AuthenticityToken"
import Tour from "@/dashboard/models/Tour"

const DuplicateTourModal = ({
  show,
  tour,
  onHide,
}: {
  show: boolean
  tour: Tour
  onHide: () => void
}): JSX.Element => {
  const [startTime, setStartTime] = useState(new Date(tour.startTime))
  const [endTime, setEndTime] = useState(new Date(tour.endTime))

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="duplicate-tour-modal-title"
      centered
    >
      <Form action={duplicate_tour_path({ id: tour.tourId })} method="post">
        <AuthenticityToken />
        <Modal.Header>
          <Modal.Title id="duplicate-tour-modal-title">{`Duplicate ${tour.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-3">
          <Form.Group>
            <Form.Label>Tour Name</Form.Label>
            <Form.Control defaultValue={tour.name} name="name" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Tour Code</Form.Label>
            <Form.Control defaultValue={tour.code} name="code" />
          </Form.Group>
          <DateTimePicker
            selected={startTime}
            onChange={setStartTime}
            inputLabel="Start time"
            inputName="start_time"
          />
          <DateTimePicker
            selected={endTime}
            onChange={setEndTime}
            inputLabel="End time"
            inputName="end_time"
          />
          <Form.Group>
            <Form.Label>Minimum Pax</Form.Label>
            <Form.Control defaultValue={tour.minimumPax} name="minimum_pax" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Maximum Pax</Form.Label>
            <Form.Control defaultValue={tour.maximumPax} name="maximum_pax" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type="submit">Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default DuplicateTourModal
