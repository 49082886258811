import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { initialize } from "@/onboarding/features/inventory_allocation/Slice"
import { getInventoryAllocation } from "@/onboarding/features/inventory_allocation/selectors"
import LoadingSpinner from "@/components/LoadingSpinner"

import Dashboard from "./components/Dashboard"
import NoAwardedtoursSection from "./components/NoAwardedToursSection"

const InventoryAllocation = ({ view }): JSX.Element => {
  const dispatch = useDispatch()

  const { initialized, hasNoAwardedTours } = useSelector(getInventoryAllocation)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ view }))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return hasNoAwardedTours ? <NoAwardedtoursSection /> : <Dashboard />
}

export default InventoryAllocation
