import _ from "lodash"

import { deepCamelizeKeys, omitAt } from "@/utils"

export const KIND = "Pricing::BulkRatesheet"

export const newRatesheet = (existingRatesheet = undefined) => {
  if (existingRatesheet) {
    return _.cloneDeep(deepCamelizeKeys(existingRatesheet))
  }

  return {
    kind: KIND,
    bulkRate: 0,
    tiers: [],
    categories: [],
  }
}

const updateRatesheet = (ratesheet, ...updates) => _.assign(newRatesheet(ratesheet), ...updates)

export const newTier = (tier = {}) => _.merge({ tierFrom: 1 }, _.omit(tier, "rateKeys"))

export const addTier = (ratesheet, tier) =>
  updateRatesheet(ratesheet, {
    tiers: _.concat(ratesheet.tiers, tier),
  })

export const maxTierFrom = (ratesheet) => _.max(_.map(ratesheet.tiers, "tierFrom"))

export const nextTierFrom = (ratesheet) => maxTierFrom(ratesheet) + 1

export const removeTierAt = (ratesheet, tierIndex) =>
  updateRatesheet(ratesheet, {
    tiers: omitAt(ratesheet.tiers, tierIndex),
  })

export const addCategory = (ratesheet, category) =>
  updateRatesheet(ratesheet, {
    categories: _.concat(ratesheet.categories, category),
  })

export const removeCategory = (ratesheet, category) =>
  updateRatesheet(ratesheet, {
    categories: _.filter(ratesheet.categories, (ctg) => ctg !== category),
  })

export const rateCategories = ({ categories }) => _(categories).uniq().sort().value()

export const tierAsRow = (ratesheet, tier, tierIndex) => ({
  tierFromColumn: { value: tier.tierFrom },
  rateColumns: _.map(rateCategories(ratesheet), (category) => ({
    value: Math.round(
      parseFloat(ratesheet.bulkRate) /
        (parseInt(tier.tierFrom, 10) === 0 ? 1 : parseInt(tier.tierFrom, 10))
    ),
    category,
  })),
  tierIndex,
})

export const asTable = (ratesheet) => {
  const headings = _.concat(
    [{ label: "Tier start", value: 0 }],
    _.map(rateCategories(ratesheet), (category) => ({
      label: `${_.capitalize(category)}`,
      value: category,
    }))
  )

  const body = _.map(ratesheet.tiers, (tier, index) => tierAsRow(ratesheet, tier, index))

  return {
    headings,
    body,
    orderedRateCategories: rateCategories(ratesheet),
    tierFroms: _.sortBy(_.map(ratesheet.tiers, "tierFrom")),
  }
}
