import React from "react"
import { ListGroup } from "react-bootstrap"

import Passenger from "@/search_results/models/Passenger"

const PassengerItem = ({ passenger }: { passenger: Passenger }): JSX.Element => (
  <ListGroup.Item>
    <div className="d-flex justify-content-between">
      <h2 className="h5">{passenger.name}</h2>
      <small>Room: #{passenger.roomNumber}</small>
    </div>
    <ListGroup variant="flush">
      <ListGroup.Item>
        Phone number:
        {passenger.phoneNumber}
      </ListGroup.Item>
      <ListGroup.Item>
        Email:
        {passenger.email}
      </ListGroup.Item>
    </ListGroup>
  </ListGroup.Item>
)

const PassengerListGroup = ({ passengers }: { passengers: Array<Passenger> }): JSX.Element => (
  <ListGroup>
    {passengers.map((passenger) => (
      <PassengerItem key={passenger.email} passenger={passenger} />
    ))}
  </ListGroup>
)

export default PassengerListGroup
