import React from "react"
import { Button, Card, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import FormikBootstrap from "@/components/formik-bootstrap"
import { submitComment } from "@/onboarding/features/product/Slice"
import { getComments, getAuthDescriptor } from "@/onboarding/features/product/selectors"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import LoadingSpinner from "@/components/LoadingSpinner"

const Comment = ({ comment, currentUser }) => {
  const ownComment = currentUser.userId === comment.sender.id

  const senderName = ownComment ? "You" : comment.sender.name
  const cardClass = ownComment ? "bg-secondary p-2 w-75" : "p-2 w-75"
  const cardJustification = ownComment ? "justify-content-end" : "justify-content-start"

  return (
    <div className={`d-flex ${cardJustification}`}>
      <Card className={cardClass}>
        <div className="d-flex flex-column gap-2">
          <div>{comment.body}</div>
          <div className="d-flex justify-content-between align-items-bottom font-small gap-2 text-muted text-small">
            <span className="fw-bold">{senderName}</span>
            <span>{comment.sentAt}</span>
          </div>
        </div>
      </Card>
    </div>
  )
}

const CommentModal = ({
  show,
  onHide,
  title,
}: {
  show: boolean
  onHide: () => void
  title: string
}): JSX.Element => {
  const sectionName = _.snakeCase(title)

  const dispatch = useDispatch()
  const comments = useSelector(getComments(sectionName))
  const currentUser = useSelector(getAuthDescriptor)
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="comment-modal-title" centered>
      <Modal.Header>
        <Modal.Title className="w-100" id="comment-modal-title">
          <div className="d-flex justify-content-between">
            <span>{`Comment on ${title}`}</span>
            <span className="cursor-pointer" onClick={onHide}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-3">
          {_.isEmpty(comments) && <p className="mb-0">No comments for this section.</p>}
          {_.map(comments, (comment) => (
            <Comment key={comment.id} comment={comment} currentUser={currentUser} />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Formik
          initialValues={{ message: "" }}
          onSubmit={(values, { resetForm }) => {
            setSubmitting(true)
            dispatch(submitComment({ message: values.message, sectionName }))
            resetForm()
          }}
        >
          {({ handleSubmit }) => (
            <div className="d-flex justify-content-between align-items-bottom gap-2 w-100">
              <div className="w-50">
                <FormikBootstrap.TextInput name="message" placeholder="Add a comment..." />
              </div>
              <div className="d-flex gap-2">
                {isSubmitting && <LoadingSpinner />}
                <div>
                  <Button size="sm" onClick={() => handleSubmit()} disabled={isSubmitting}>
                    <i className="fas fa-paper-plane me-2" />
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => onHide()}
                    disabled={isSubmitting}
                  >
                    <i className="fas fa-times me-2" />
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Modal.Footer>
    </Modal>
  )
}

export default CommentModal
