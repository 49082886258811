import React from "react"

// TODO(guest-app): Switch to existing date helper classes under components/DatePickers
export const formatDate = (dateTime) => {
  const dateTimeString = new Date(dateTime).toString()
  const dateTimeArray = dateTimeString.split(" ")
  const date = dateTimeArray.slice(0, 4).join(" ")
  const time = dateTimeArray[4].substring(0, dateTimeArray.length - 5)
  return { date, time }
}

export const displayDate = (dateTimeStart, dateTimeEnd) => {
  const start = formatDate(dateTimeStart)
  const end = formatDate(dateTimeEnd)

  if (start.date === end.date) {
    return <p>{`${start.date}, ${start.time} - ${end.time}`}</p>
  }

  return (
    <p>
      Starting on {start.date} at {start.time}
      <br />
      Ending on {end.date} at {end.time}
    </p>
  )
}
