import React from "react"
import { useSelector } from "react-redux"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { isBlank } from "@/utils"
import { getView } from "../selectors"
import OperatorTableRows from "./OperatorTableRows"
import CruiselineTableRows from "./CruiselineTableRows"

const InventoryAllocationTableRows = () => {
  const view = useSelector(getView)
  if (isBlank(view.inventory.allocationTableData.callDays)) {
    return (
      <tr>
        <td>
          <h6 className="my-2">
            {`${view.inventory.ship.name} has no corresponding call days in ${view.inventory.port.name}`}
          </h6>
        </td>
      </tr>
    )
  }

  return view.inventory.allocationTableData.callDays.map((call, index) => (
    <React.Fragment key={call.date}>
      <OperatorOnly>
        <OperatorTableRows index={index} />
      </OperatorOnly>
      <CruiseLineOnly>
        <CruiselineTableRows index={index} />
      </CruiseLineOnly>
    </React.Fragment>
  ))
}

export default InventoryAllocationTableRows
