import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getSettlement } from "@/settlement/selectors"

import SettlementTotalSummary from "./SettlementTotalSummary"

const SettlementInfo = (): JSX.Element => {
  const { tour } = useSelector(getSettlement)

  return (
    <div>
      <h1 className="h4">{`Settlement for ${tour.name} (${_.join(tour.operatorNames, ", ")})`}</h1>
      <h2 className="h5">{`${tour.port}. ${tour.date} ${tour.timeslot}`}</h2>
      <SettlementTotalSummary />
    </div>
  )
}

export default SettlementInfo
