import React from "react"
import { Form, InputGroup } from "react-bootstrap"
import { BlueLock } from "@/components/Icons"

// TODO remove this displayScale hack!
const NumberInput = ({
  name,
  displayScale = 1,
  label = "",
  value = null,
  minimum = null,
  suffix = "",
  showLockedIcon = false,
  showRequired = false,
  ...props
}) => {
  const placeholderStyle = `
  .placeholder-muted::placeholder {
    color: #BEBEBE;
  }
`
  const numericValue = isNaN(Number(value)) ? null : Number(value)

  return (
    <Form.Group className="w-100">
      <div className="d-flex align-items-center">
        {showLockedIcon && (
          <BlueLock
            className="me-2 mt-n2 cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="This value is fixed and cannot be edited"
          />
        )}
        {label !== "" && (
          <Form.Label>
            {label}
            {showRequired && <span className="ms-1">*</span>}
          </Form.Label>
        )}
      </div>
      <style>{placeholderStyle}</style>
      <InputGroup>
        <Form.Control
          type="number"
          {...props}
          value={value === "" ? value : Number(value) / displayScale}
          onChange={(e) => {
            const newValue = e.target.value === "" ? "" : Number(e.target.value) * displayScale
            props.onChange && props.onChange({ target: { value: newValue, name } })
          }}
          name={name}
          placeholder="0"
          className="placeholder-muted"
        />
        {suffix !== "" && <InputGroup.Text>{suffix}</InputGroup.Text>}
      </InputGroup>
      {minimum != null && numericValue < parseInt(minimum) && (
        <small className="text-danger mt-1">{`${label} must be at least ${minimum}`}</small>
      )}
    </Form.Group>
  )
}

export default NumberInput
