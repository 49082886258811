import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { format as formatDate } from "date-fns"
import _ from "lodash"

import Voyage from "@/dashboard/models/Voyage"
import { DailySale } from "@/dashboard/models/DailySale"
import Tour from "@/dashboard/models/Tour"
import { DateBadge, FORMATS as DATE_FORMATS } from "@/components/DatePickers"
import { dashboard_path } from "@/routes"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import ErrorBoundary from "@/components/ErrorBoundary"

import TourTable from "./tour_list_card/TourTable"
import ExcelExportLink from "./tour_list_card/actions/ExcelExportLink"
import FilterOperator from "./tour_list_card/actions/FilterOperator"
import VoyageInfo from "./tour_list_card/header/VoyageInfo"
import FirstMeetingInfo from "./tour_list_card/header/FirstMeetingInfo"
import DashboardTools from "./tour_list_card/DashboardTools"

const TourListCard = ({
  date,
  firstMeeting,
  voyage,
  dailySale,
  tours,
}: {
  date: string
  firstMeeting: string
  voyage: Voyage
  dailySale: DailySale
  tours: Array<Tour>
}): JSX.Element => {
  const toursHeading = _([
    "Tours",
    voyage.portName.length === 0 ? "" : `in ${voyage.portName}`,
    "on",
  ])
    .reject((v) => v.length === 0)
    .join(" ")

  const onDateSelected = (date) => {
    window.location = dashboard_path({
      date: formatDate(date, DATE_FORMATS.date.default),
    })
  }

  const header = (
    <Card.Header>
      <CruiseLineOnly>
        <Row className="align-items-center">
          <Col>
            <VoyageInfo voyage={voyage} />
          </Col>
        </Row>
      </CruiseLineOnly>
      <Row className="align-items-center">
        <Col>
          <h2 className="h5">
            {toursHeading}
            <DateBadge
              selected={date}
              className="ms-1 bg-secondary text-black cursor-pointer"
              dateFormat={DATE_FORMATS.date.humaneLong}
              onChange={onDateSelected}
            />
          </h2>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div>
              <FirstMeetingInfo firstMeeting={firstMeeting} />
            </div>
          </div>
          <CruiseLineOnly>
            <FilterOperator />
          </CruiseLineOnly>
        </Col>
        <Col className="col-auto">
          <DashboardTools date={date} dailySale={dailySale} />
          <OperatorOnly>
            <Row className="justify-content-end mt-2">
              <Col>
                <ExcelExportLink date={date} />
              </Col>
            </Row>
          </OperatorOnly>
        </Col>
      </Row>
    </Card.Header>
  )

  const renderTours = () => {
    if (tours.length === 0) {
      return (
        <Card.Body>
          <p className="mb-0">No tours for the specified date</p>
        </Card.Body>
      )
    }

    return <TourTable tours={tours} />
  }

  return (
    <ErrorBoundary>
      {header}
      {renderTours()}
    </ErrorBoundary>
  )
}

export default TourListCard
