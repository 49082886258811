import React from "react"
import { Button } from "react-bootstrap"

import { useModal } from "@/helpers/useModal"

import ShowSignatureModal from "./ShowSignatureModal"

const SignedByLabel = ({ agent }): JSX.Element => {
  if (agent === null) {
    return null
  }

  const toggleSignatureModal = useModal((props) => <ShowSignatureModal {...props} agent={agent} />)

  return (
    <div className="d-flex justify-content-end gap-2 text-small">
      <div className="d-flex flex-column gap-1">
        <span>{`Signed by ${agent.name}`}</span>
        <span className="text-right">
          <b>{agent.company}</b>
          {` • ${agent.signedAt}`}
        </span>
      </div>
      <Button variant="sm" onClick={toggleSignatureModal}>
        <i className="fas fa-search" />
      </Button>
    </div>
  )
}

export default SignedByLabel
