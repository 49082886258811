import React from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { navigateToDashboard } from "@/onboarding/features/price_analysis/Slice"
import { isBlank } from "@/utils"
import { getPriceAnalysis } from "../../selectors"

const BackToDashboardButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const { costAnalysisRegionId, selectedBiddingRegionId } = useSelector(getPriceAnalysis)

  return (
    <Button
      size="sm"
      variant="secondary"
      onClick={() =>
        dispatch(
          navigateToDashboard({
            biddingRegionId: isBlank(costAnalysisRegionId)
              ? selectedBiddingRegionId
              : costAnalysisRegionId,
          })
        )
      }
    >
      <span className="fas fa-arrow-left me-2" />
      Region Cost Analysis
    </Button>
  )
}

export default BackToDashboardButton
