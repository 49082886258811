import React from "react"

import { Card, Row, Col, Container, Form, Button } from "react-bootstrap"

import routes from "@/routes"

import FormToolkit from "@/form_toolkit"

import { Formik } from "formik"

import * as yup from "yup"

import { useDispatch } from "react-redux"

import Api from "@/api"

import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"

import _ from "lodash"

const Page = ({ form }): JSX.Element => {
  const dispatch = useDispatch()

  const formSchema = yup.object({
    userForm: FormToolkit.yupSchema({ form: form.userForm }),
    companyForm: FormToolkit.yupSchema({ form: form.companyForm }),
  })

  const initialValues = _.merge(
    {},
    FormToolkit.fieldValues({ form: form.userForm, namePrefix: "userForm" }),
    FormToolkit.fieldValues({ form: form.companyForm, namePrefix: "companyForm" })
  )

  return (
    <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
      <Container>
        <Row className="justify-content-center">
          <Col className="d-flex align-items-center justify-content-center" sm={12} lg={8}>
            <Card className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
              <Card.Body>
                <div className="text-center mb-4 mt-0">
                  <h1 className="h3">Operator Registration</h1>
                  <i>
                    Already have an account?{" "}
                    <a className="text-underline" href={routes.new_user_session_path()}>
                      Sign In
                    </a>
                  </i>
                </div>
                <div className="text-center">
                  <p> Complete the form below to register as an operator on Shoretime</p>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    Api.operatorRegistration
                      .create(values)
                      .then(newSuccessHandler(dispatch))
                      .catch(newAxiosErrorHandler(dispatch))
                      .finally(() => {
                        setSubmitting(false)
                      })
                  }}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <h2 className="h4 text-center">User Account</h2>
                      <FormToolkit.Fields namePrefix="userForm" form={form.userForm} />
                      <h2 className="h4 text-center">Company Registration Information</h2>
                      <FormToolkit.Fields namePrefix="companyForm" form={form.companyForm} />
                      <Button type="submit" disabled={isSubmitting}>
                        Sign Up
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Page
