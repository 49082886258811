import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const LAST_SELECTED_TAB_PATH = "itinerary_dashboard.lastTab"
export const LAST_SELECTED_SEASON_ID_PATH = "itinerary_dashboard.lastSeasonId"
export const LAST_SELECTED_ITINERARY_ID_PATH = "itinerary_dashboard.lastItineraryId"
export const LAST_SELECTED_PORT_ID_PATH = "itinerary_dashboard.lastPortId"
export const LAST_SELECTED_SHIP_ID_PATH = "itinerary_dashboard.lastShipId"

export enum TabTypes {
  ITINERARY = "itinerary",
  PORT = "port",
  SHIP = "ship",
}

export const setTab = createAsyncThunk("itinerary/setTab", async (tab: string) => {
  window.localStorage.setItem(LAST_SELECTED_TAB_PATH, tab)

  return { tab: tab as TabTypes }
})

export const setSeason = createAsyncThunk("itinerary/setSeason", async (seasonId: string) => {
  window.localStorage.setItem(LAST_SELECTED_SEASON_ID_PATH, seasonId)

  return { seasonId }
})

export const setItinerary = createAsyncThunk(
  "itinerary/setItinerary",
  async (itineraryId: string) => {
    window.localStorage.setItem(LAST_SELECTED_ITINERARY_ID_PATH, itineraryId)

    return { itineraryId }
  }
)

export const setPort = createAsyncThunk("itinerary/setPort", async (portId: string) => {
  window.localStorage.setItem(LAST_SELECTED_PORT_ID_PATH, portId)

  return { portId }
})

export const setShip = createAsyncThunk("itinerary/setShip", async (shipId: string) => {
  window.localStorage.setItem(LAST_SELECTED_SHIP_ID_PATH, shipId)

  return { shipId }
})

const Slice = createSlice({
  name: "itinerary",
  initialState: {
    initialized: false,
    selectedTab: TabTypes.PORT,
    selectedSeasonId: "",
    selectedItineraryId: "",
    selectedPortId: "",
    selectedShipId: "",
    seasons: [],
    itineraries: [],
    ports: [],
    ships: [],
    visits: [],
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      initialized: true,
      selectedTab: payload.tab || TabTypes.PORT,
      selectedSeasonId: payload.seasonId || "",
      selectedItineraryId: payload.itineraryId || "",
      selectedPortId: payload.portId || "",
      selectedShipId: payload.shipId || "",
      seasons: payload.seasons,
      itineraries: payload.itineraries,
      ports: payload.ports,
      ships: payload.ships,
      visits: payload.visits,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setTab.fulfilled, (state, { payload }) => ({
      ...state,
      selectedTab: payload.tab,
    }))

    builder.addCase(setSeason.fulfilled, (state, { payload }) => ({
      ...state,
      selectedSeasonId: payload.seasonId,
    }))

    builder.addCase(setItinerary.fulfilled, (state, { payload }) => ({
      ...state,
      selectedItineraryId: payload.itineraryId,
    }))

    builder.addCase(setPort.fulfilled, (state, { payload }) => ({
      ...state,
      selectedPortId: payload.portId,
    }))

    builder.addCase(setShip.fulfilled, (state, { payload }) => ({
      ...state,
      selectedShipId: payload.shipId,
    }))
  },
})

export const { initialize } = Slice.actions

export default Slice
