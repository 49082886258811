import { array, object, string } from "yup"

const internationalPhoneNumber = () =>
  string()
    .trim()
    .transform((v) => v.replace(/[^+0-9]_/g, ""))
    .matches(/\+[0-9]{1,3}[0-9]{4,14}/g)

export const CONTACT_TYPE_VALUES = ["business", "reservation", "administrative", "financial"]

export const ContactSchema = object({
  firstName: string().trim().required(),
  lastName: string().trim().required(),
  jobTitle: string().trim().required(),
  emailAddress: string().trim().email().required(),
  phoneNumber: internationalPhoneNumber().required(),
  mobileNumber: internationalPhoneNumber().required(),
  contactType: string().required().oneOf(CONTACT_TYPE_VALUES),
})

export const OperatorFormSchema = object({
  name: string().required(),
  registrationNumber: string(),
  vatNumber: string(),
  address: string().required(),
  contacts: array().of(ContactSchema),
})
