import React, { useState } from "react"

import { v4 as uuid } from "uuid"

import routes from "@/routes"

import AuthenticityToken from "@/components/AuthenticityToken"
import ButtonLink from "@/components/ButtonLink"

const ApplicationReview = ({ application, children }) => {
  const [formId, _] = useState(uuid())

  const rejectUrl = routes.onboarding_operator_profile_reject_application_path({
    operator_profile_id: application.operatorProfileId,
    id: application.id,
    _options: true,
  })

  const approveUrl = routes.onboarding_operator_profile_approve_application_path({
    operator_profile_id: application.operatorProfileId,
    id: application.id,
    _options: true,
  })

  return (
    <div>
      {children}
      <div className="row sticky-top">
        <div className="col-md-3" />
        <div className="col-md-9">
          {application.reviewable && (
            <div>
              <h2 className="h5">Review Application</h2>
              <p>
                After reviewing the application, use the Reject or Approve controls below to
                finalize the application.
              </p>
              <form action={rejectUrl} method="post" className="form" id={formId}>
                <AuthenticityToken />
                <div className="row">
                  <div className="col-lg-8">
                    {application.rejectable && (
                      <div className="form-group">
                        <label htmlFor="rejection_reason">Rejection Reason</label>
                        <textarea name="rejection_reason" className="form-control" />
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <div className="mt-2 d-flex gap-2">
                {application.approvable && (
                  <ButtonLink
                    button={{ variant: "success", size: "sm" }}
                    href={approveUrl}
                    method="post"
                  >
                    Approve
                  </ButtonLink>
                )}
                {application.rejectable && (
                  <button form={formId} type="submit" className="btn btn-danger btn-sm">
                    Reject
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ApplicationReview
