import React from "react"
import { useSelector } from "react-redux"
import { Card, Table } from "react-bootstrap"
import _ from "lodash"
import { getRoom, getPassengers, getPayer } from "../selectors"

const RoomGuestsCard = (): JSX.Element => {
  const room = useSelector(getRoom)
  const passengers = useSelector(getPassengers)
  const payer = useSelector(getPayer)

  return (
    <div className="pb-6">
      <Card className="mt-4">
        <h4 className="mb-1 mt-3 ms-2">{`Room ${room.name} Guests`}</h4>
        <Table size="sm" className="align-items-center table-flush table-pad-sm">
          <thead>
            <tr>
              <th scope="col" className="text-center" />
              <th scope="col" className="text-center">
                Name
              </th>
              <th scope="col" className="text-center" />
            </tr>
          </thead>

          <tbody>
            {_.map(passengers, (value) => (
              <tr key={value.details.name}>
                <td scope="col" className="text-center">
                  <img className="avatar rounded-circle" src={value.profilePicture} alt="" />
                </td>
                <td scope="col" className="text-center">
                  <button
                    className="btn btn-light"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    {value.details.name}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li className="dropdown-item">{_.startCase(value.details.pax_type)}</li>
                    {value.details.email ? (
                      <li className="dropdown-item">{value.details.email}</li>
                    ) : (
                      ""
                    )}
                    {value.details.phone_number ? (
                      <li className="dropdown-item">{value.details.phone_number}</li>
                    ) : (
                      ""
                    )}
                  </ul>
                </td>
                <td scope="col" className="text-center">
                  {value.details.name == payer.name ? <i className="fa fa-credit-card" /> : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default RoomGuestsCard
