import React, { useRef, useState } from "react"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import SignaturePad from "react-signature-pad-wrapper"

import { getSettlement, submitReceivedTickets } from "@/daily_settlement/Slice"

const SubmitReceivedTicketsModal = ({
  show,
  onHide,
  settlementSheetId,
}: {
  show: boolean
  onHide: () => void
  settlementSheetId: string
}): JSX.Element => {
  const dispatch = useDispatch()
  const settlement = useSelector(getSettlement(settlementSheetId))

  const signaturePad: any = useRef({})
  const [adults, setAdults] = useState(settlement.adults)
  const [children, setChildren] = useState(settlement.children)
  const [comment, setComment] = useState("")

  const clearCanvas = () => signaturePad.current.clear()
  const onSubmit = () => {
    dispatch(
      submitReceivedTickets({
        settlementSheetId,
        adults,
        children,
        comment,
        signature: signaturePad.current.toDataURL(),
      })
    )

    onHide()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="submit-received-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="submit-received-modal-title">
          Submit Tickets Received
          <p className="mb-0">{`${settlement.tourCode} - ${settlement.tourName} departing at ${settlement.departure}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Adult Tickets</Form.Label>
              <Form.Control
                type="number"
                name="adults"
                value={adults}
                onChange={(e) => setAdults(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Child Tickets</Form.Label>
              <Form.Control
                type="number"
                name="children"
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            type="text"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Signature</Form.Label>
          <Card>
            <SignaturePad ref={signaturePad} height={250} />
          </Card>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="warning" onClick={clearCanvas}>
          Clear Signature
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SubmitReceivedTicketsModal
