import React from "react"
import { MutedcheckMark } from "@/components/Icons"
import { isBlank } from "@/utils"
import {
  calculateTotalMaximumCapacity,
  RenderDeparturesColumn,
  RenderIssuesColumn,
} from "./Helpers"

const TableRow = ({ tour }) => (
  <tr>
    <td />
    <td />
    <td />
    <td style={{ color: "#888888" }}>
      <strong>{tour.code}</strong> <br />
      {isBlank(tour.constraints.allotmentConstraint) && (
        <div>
          No requirement
          <br /> loaded
        </div>
      )}
      {!isBlank(tour.constraints.allotmentConstraint) && (
        <>
          Min per departure: {`${tour.constraints.allotmentConstraint.capacityPerDepartureMin}`}
          <br />
          <>
            <span style={{ marginRight: "40px" }}>Max per day:</span>
            <span>{tour.constraints.allotmentConstraint.capacityPerDayMax}</span>
          </>
        </>
      )}
    </td>
    <td style={{ color: "#888888" }}>
      {!isBlank(tour.departures) && (
        <RenderDeparturesColumn
          departures={tour.departures}
          property="departureTime"
          closed={tour.closedDay}
          additionalData
        />
      )}
    </td>
    <td style={{ color: "#888888" }}>
      {!isBlank(tour.departures) && (
        <RenderDeparturesColumn
          departures={tour.departures}
          property="durationInMinutes"
          closed={tour.closedDay}
          additionalData
        />
      )}
    </td>
    <td style={{ color: "#888888" }}>
      {!isBlank(tour.departures) && (
        <RenderDeparturesColumn
          departures={tour.departures}
          property="minimumCapacity"
          closed={tour.closedDay}
          additionalData
        />
      )}
    </td>
    <td style={{ color: "#888888" }}>
      {!isBlank(tour.departures) && (
        <>
          <RenderDeparturesColumn
            departures={tour.departures}
            property="maximumCapacity"
            closed={tour.closedDay}
            additionalData
          />
          <strong> {`${calculateTotalMaximumCapacity(tour.departures)} (Total)`}</strong>
        </>
      )}
    </td>
    <td style={{ color: "#888888" }}>
      {!isBlank(tour.issues) && <RenderIssuesColumn issues={tour.issues} />}
    </td>
    <td style={{ color: "#888888" }}>
      {tour.state == "Approved" && <MutedcheckMark />} {tour.state}
    </td>
    <td />
    <td />
    <td />
  </tr>
)

const AdditionalTours = ({ inventory }) =>
  inventory.map((tour) => (
    <React.Fragment key={tour.code}>
      <TableRow tour={tour} />
    </React.Fragment>
  ))

export default AdditionalTours
