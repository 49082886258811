import React from "react"

import Tour, { ArrangementsStates, OperationalStates } from "@/dashboard/models/Tour"

const TourCodeBadge = ({ tour }: { tour: Tour }) => {
  let badgeVariant

  if (tour.operationalState === OperationalStates.CANCELLED) {
    badgeVariant = "bg-danger"
  } else if (tour.arrangementsState === ArrangementsStates.PENDING) {
    badgeVariant = "bg-warning text-dark"
  } else {
    badgeVariant = "bg-success"
  }

  return <span className={`badge ${badgeVariant}`}>{tour.code}</span>
}

export default TourCodeBadge
