import React, { useState } from "react"
import { Card, Row, Col, Button, Table, Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

import RoomSearch from "@/manual_booking/components/RoomSearch"
import ApplicableCouponSearch from "@/manual_booking/components/CouponSearch"
import { formatMoney } from "@/helpers/money"
import GuestColumns from "@/manual_booking/components/GuestColumns"
import ActionBar from "@/manual_booking/components/ActionBar"
import { isReady, isLoading } from "@/helpers/AsyncField"
import { isEditable, isVisible, labelFor, actionExtras } from "@/helpers/formSpec"
import {
  getOrderedBookingForms,
  getChargedRoomAccountSearch,
  getApplicableCouponSearch,
} from "@/manual_booking/selectors"
import ScreenSize from "@/helpers/ScreenSize"
import { useAuthDescriptor } from "@/auth_descriptor/hooks"

import {
  submitForm,
  selectChargedRoomAccount,
  fetchChargedRoomAccountRooms,
  fetchApplicableCoupons,
  selectApplicableCoupon,
} from "./Slice"
import { getChargedRoomAccount, getFields, getSelectedCoupon } from "./selectors"

const NOT_APPLICABLE = "N/A"
const SUMMARY_TABLE_COLUMNS = 2

const MissingPriceSpinner = () => <Spinner animation="grow" variant="warning" size="sm" />

const BookingPrice = ({ price, isMobile }) => {
  if (isReady(price)) {
    const { amount, currency } = price.value
    return <span>{formatMoney(amount, currency)}</span>
  }

  if (!_.isEmpty(price.message) && !isLoading(price) && !isMobile) {
    return <b className="text-warning">{price.message}</b>
  }

  return <MissingPriceSpinner />
}
const BookingRow = ({ booking }) => {
  const isMobile = ScreenSize()
  const placement = isMobile ? "text-start ps-0" : "text-end"
  let tourName = booking.description

  if (!_.isNil(booking.description) && isMobile) {
    tourName = booking.description.split("(")[0]
  }

  return (
    <tr key={booking.formId}>
      <td className="text-start ps-0">{tourName}</td>
      <td className={placement}>
        <BookingPrice price={booking.price} isMobile={isMobile} />
      </td>
    </tr>
  )
}

const calculateTotalPrice = (bookings) => {
  if (!_.every(bookings, (b) => isReady(b.price)) || _.isEmpty(bookings)) {
    return NOT_APPLICABLE
  }

  // TODO Source currency from server instead of first booking
  const amounts = _.map(bookings, (b) => parseFloat(b.price.value.amount))
  const totalPrice = _.sum(amounts)
  const { currency } = _.get(_.first(bookings), "price.value")

  return formatMoney(totalPrice, currency)
}

const BookingTableSummaryRows = ({ bookings }) => {
  const isMobile = ScreenSize()

  const placement = isMobile ? "text-start ps-0" : "text-end"
  if (_.isEmpty(bookings)) {
    return (
      <tr style={{ borderTop: "1px solid #000" }}>
        <td colSpan={SUMMARY_TABLE_COLUMNS}>
          <i>No bookings have been added</i>
        </td>
      </tr>
    )
  }

  const totalPrice = calculateTotalPrice(bookings)

  const bookingRows = _.map(bookings, (b) => <BookingRow key={b.formId} booking={b} />)

  return (
    <>
      {bookingRows}
      <tr key="summary" style={{ borderTop: "1px solid #000" }}>
        <td className="ps-0" colSpan={SUMMARY_TABLE_COLUMNS - 1}>
          <b>Total Price</b>
        </td>
        <td className={placement}>
          {totalPrice === NOT_APPLICABLE ? <MissingPriceSpinner /> : totalPrice}
        </td>
      </tr>
    </>
  )
}

const ChargedRoomAccountSearch = () => {
  const { isGuestUi } = useAuthDescriptor()

  const dispatch = useDispatch()

  const fields = useSelector(getFields)
  const chargedRoomAccountSearch = useSelector(getChargedRoomAccountSearch)
  const [isActive, setActive] = useState(false)

  const toggleActive = () => {
    setActive(!isActive)
  }

  if (!isEditable(fields, "chargedRoomAccount")) {
    return null
  }

  if (isActive) {
    return (
      <>
        <Col sm={5}>
          <RoomSearch
            results={chargedRoomAccountSearch.results}
            isLoading={chargedRoomAccountSearch.isLoading}
            onSearch={(q) => dispatch(fetchChargedRoomAccountRooms({ q }))}
            onSelect={(selections, typeAhead) => {
              dispatch(selectChargedRoomAccount({ chargedRoomAccount: selections[0].roomAccount }))
              typeAhead.clear()
              toggleActive()
            }}
          />
        </Col>
        <Col sm={1}>
          <Button variant="secondary" onClick={toggleActive}>
            Cancel
          </Button>
        </Col>
      </>
    )
  }

  if (isGuestUi) {
    return <div />
  }

  return (
    <Col className="col-auto">
      <Button size="sm" variant="secondary" onClick={toggleActive}>
        Assign another Room Account
      </Button>
    </Col>
  )
}

const CouponSearch = () => {
  const dispatch = useDispatch()

  const fields = useSelector(getFields)
  const couponSearch = useSelector(getApplicableCouponSearch)
  const [isActive, setActive] = useState(false)

  const toggleActive = () => {
    setActive(!isActive)
  }

  if (!isEditable(fields, "coupon")) {
    return null
  }

  if (isActive) {
    return (
      <div className="ms-n4 ps-0">
        <br />
        <Col sm={5} className="mt-1">
          <ApplicableCouponSearch
            results={couponSearch.results}
            isLoading={couponSearch.isLoading}
            onSearch={(q) => {
              dispatch(fetchApplicableCoupons({ q }))
            }}
            onSelect={(selections, typeAhead) => {
              dispatch(selectApplicableCoupon({ selectedCoupon: selections[0] }))
              typeAhead.clear()
              toggleActive()
            }}
          />
        </Col>
        <Col sm={1} className="mt-2">
          <Button variant="secondary" onClick={toggleActive}>
            Cancel
          </Button>
        </Col>
      </div>
    )
  }
  return (
    <div className="ms-n4 ps-0">
      <br />
      <Col className="col-auto mt-1">
        <Button size="sm" variant="secondary" onClick={toggleActive}>
          Apply Coupon
        </Button>
      </Col>
    </div>
  )
}
const OrderRoomAccount = () => {
  const chargedRoomAccount = useSelector(getChargedRoomAccount)

  const chargedGuest = chargedRoomAccount && chargedRoomAccount.payer
  const hasPaymentMethod = chargedRoomAccount ? chargedRoomAccount.hasPaymentMethod : undefined

  return (
    <Row className="m-4">
      <Col sm={3} className="ms-n4 ps-0 mb-1">
        <b>Charged Room Account</b>
      </Col>
      <Col sm={3} className="ms-n4 ps-0">
        <Row>
          <GuestColumns
            guest={chargedGuest}
            // guestId={(chargedGuest && chargedGuest.id) || "no-guest"}
            noGuestMessage="No room account selected"
            hasPaymentMethod={hasPaymentMethod}
            // auto
          />
        </Row>
      </Col>
      <ChargedRoomAccountSearch />
    </Row>
  )
}

const CouponSelection = () => {
  const selectedCoupon = useSelector(getSelectedCoupon)

  return (
    <Row className="m-4">
      <Col sm={3} className="ms-n4 ps-0">
        <b>Coupon</b>
      </Col>
      <Col sm={3} className="ms-n4 ps-0">
        {selectedCoupon ? (
          <div className="d-flex flex-column gap-1">
            <span>
              <b>{selectedCoupon.coupon.name}</b>
            </span>
            <span>
              <i>{selectedCoupon.code}</i>
              {` • ${selectedCoupon.coupon.benefit} off`}
            </span>
          </div>
        ) : (
          <i>No coupon selected</i>
        )}
      </Col>
      <CouponSearch />
    </Row>
  )
}

const BookButton = () => {
  const { isGuestUi } = useAuthDescriptor()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const dispatch = useDispatch()

  const fields = useSelector(getFields)

  return (
    <Button
      className="px-4 me-n4"
      variant="primary"
      onClick={() =>
        dispatch(submitForm(actionExtras(fields, "book"), isGuestUi, urlParams.get("id")))
      }
    >
      {labelFor(fields, "book")}
    </Button>
  )
}

const BookAndPrintButton = () => {
  const dispatch = useDispatch()

  const fields = useSelector(getFields)

  if (!isVisible(fields, "bookAndPrint")) return null

  return (
    <>
      <span className="p-1 ms-4" />
      <Button
        variant="primary"
        onClick={() => dispatch(submitForm(actionExtras(fields, "bookAndPrint")))}
      >
        {labelFor(fields, "bookAndPrint")}
      </Button>
    </>
  )
}

const OrderSummary = () => {
  const { isGuestUi } = useAuthDescriptor()
  const isMobile = ScreenSize()

  const placement = isMobile ? "text-start ps-1" : "text-end"
  const bookings = useSelector(getOrderedBookingForms)

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>Summary</Card.Title>
        <Table responsive>
          <thead>
            <tr>
              <th className="ps-0">Product</th>
              <th className={placement}>Price</th>
            </tr>
          </thead>
          <tbody>
            <BookingTableSummaryRows bookings={bookings} />
          </tbody>
        </Table>

        <OrderRoomAccount />
        <CouponSelection />

        {!isGuestUi ? (
          <ActionBar>
            <div className="float-end">
              <BookButton />
              <BookAndPrintButton />
            </div>
          </ActionBar>
        ) : (
          <ActionBar>
            <div className="float-end">
              <BookButton />
            </div>
          </ActionBar>
        )}
      </Card.Body>
    </Card>
  )
}

export default OrderSummary
