export const getRoomAccount = ({ guestRoomAccount }) => guestRoomAccount

export const getRoom = ({ guestRoomAccount }) => guestRoomAccount.room

export const getID = ({ guestRoomAccount }) => guestRoomAccount.guestId

export const getPassengers = ({ guestRoomAccount }) => guestRoomAccount.passengers

export const getPayer = ({ guestRoomAccount }) => guestRoomAccount.payer

export const getCharges = ({ guestRoomAccount }) => guestRoomAccount.charges

export const isInitialized = ({ guestRoomAccount }) => guestRoomAccount.initialized
