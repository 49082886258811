import React from "react"
import _ from "lodash"

import { newComponent } from "@/onboarding/features/product/models/Component"
import Api from "@/api"

import Form from "./Form"

const Edit = ({
  component,
  ports,
  defaultRateCategories,
  defaultTiers,
  referenceValueOptions,
}): JSX.Element => {
  const formValues = _.cloneDeep(component.document)

  if (!_.has(formValues, "kind")) {
    _.assign(formValues, newComponent({ kind: component.componentType, defaultRateCategories }))
  }

  // referenceValueOptions probably does not belong here, but I need it further down the stack
  _.assign(formValues, {
    enabled: component.enabled,
    portId: component.portId,
    referenceValueOptions,
  })
  const api = (params) => Api.componentLibrary.update(component.id, params)

  return (
    <Form
      formValues={formValues}
      api={api}
      ports={ports}
      defaultRateCategories={defaultRateCategories}
      defaultTiers={defaultTiers}
    />
  )
}

export default Edit
