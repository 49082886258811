export enum ContentSetTypes {
  PRIMARY = "PrimaryContentSet",
  BROCHURE = "BrochureContentSet",
  TICKET = "TicketContentSet",
  KIOSK = "KioskContentSet",
}

export default interface ContentSet {
  id: string
  name: string
  type: ContentSetTypes
}
