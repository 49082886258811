import React, { useState, useEffect, useRef } from "react"
import SingleSelect from "@/components/input/SingleSelect"
import MultiSelect from "@/components/input/MultiSelect"

const SelectComponent = ({
  name,
  label,
  helpText = "",
  options = [],
  floating = false,
  placeholder = "",
  value = [],
  onChange,
  multiple = false,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value)

  // Create refs for the MultiSelect and SingleSelect components
  const selectRef = useRef(null)
  useEffect(() => {
    // Use refs to target the correct input element instead of querySelector
    if (selectRef.current) {
      const hiddenElement = selectRef.current.querySelector(".p-hidden-accessible input")
      if (hiddenElement) {
        hiddenElement.remove()
      }
    }
  }, [])

  const handleChange = (change) => {
    setSelectedOptions(change.value)
    if (onChange) {
      onChange(change)
    }
  }

  return (
    <>
      {multiple ? (
        <>
          <div ref={selectRef}>
            <MultiSelect
              name={name}
              label={label}
              onChange={handleChange}
              placeholder={placeholder}
              value={selectedOptions}
              options={options}
              {...props}
            />
          </div>
          {/* Hidden select element for multi-select */}
          <select name={`${name}[]`} value={selectedOptions} multiple hidden readOnly>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>
          <div ref={selectRef}>
            <SingleSelect
              name={name}
              label={label}
              onChange={handleChange}
              placeholder={placeholder}
              value={Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions}
              options={options}
              {...props}
            />
          </div>
          {/* Hidden input for single select */}
          <input hidden name={name} value={selectedOptions} readOnly />
        </>
      )}
    </>
  )
}

export default SelectComponent
