import React, { FC } from "react"
import { useSelector } from "react-redux"
import { getFieldSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import SectionHolder from "./shared/SectionHolder"
import Content from "./headsets/Content"
import Edit from "./headsets/Edit"

const Headsets: FC = () => {
  const { headset_components } = useSelector(getFieldSettings(ReservedFields.HEADSET_COMPONENTS))

  return (
    <SectionHolder
      title="Headsets"
      editComponent={headset_components.writeable ? Edit : null}
      editMethod="inline"
      showLockedIcon={!headset_components.writeable}
    >
      <Content />
    </SectionHolder>
  )
}

export default Headsets
