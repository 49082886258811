import React from "react"
import { useSelector } from "react-redux"
import { Table } from "react-bootstrap"

import {
  getSelectedBiddingRegion,
  getProspectiveAwardSets,
  getPriceAnalysis,
} from "@/onboarding/features/price_analysis/selectors"

import PortRow from "./port_table/PortRow"
import AwardSetRadioButton from "./port_table/AwardSetRadioButton"
import GroupingSelector from "../header/GroupingSelector"

const PortTable = (): JSX.Element => {
  const { selectedAwardSetId, selectedPortId } = useSelector(getPriceAnalysis)
  const { ports } = useSelector(getSelectedBiddingRegion)
  const selectedPort = useSelector(getSelectedBiddingRegion).ports.find(
    (port) => port.id === selectedPortId
  )
  const awardSets = useSelector(getProspectiveAwardSets)

  return (
    <div>
      <h2 className="h5">Total Cost Per Port</h2>
      <GroupingSelector />
      <Table responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "20%" }}>
              Port
            </th>
            <th />
            {awardSets.map(({ id }) => (
              <th key={id} scope="col">
                <div className="d-flex justify-content-end">
                  <AwardSetRadioButton awardSetId={id} />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedPort ? (
            <PortRow
              key={selectedPort.id}
              port={selectedPort}
              awardSets={awardSets}
              selectedAwardSetId={selectedAwardSetId}
            />
          ) : (
            ports.map((port) => (
              <PortRow
                key={port.id}
                port={port}
                awardSets={awardSets}
                selectedAwardSetId={selectedAwardSetId}
              />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default PortTable
