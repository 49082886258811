import React from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import _ from "lodash"
import { useFormikContext } from "formik"

import FormikBootstrap from "@/components/formik-bootstrap"
import * as FlatRatesheet from "@/pricing/models/FlatRatesheet"

import AddRateCategory from "./AddRateCategory"

const RatesheetRow = ({ label, readOnly, onRemoveRate, ...props }): JSX.Element => (
  // TODO get rid of displayScale! (makes cents into currency)
  <Form.Group as="tr" key={props.name}>
    <td className="align-middle">{label}</td>
    <td>
      <div className="d-flex justify-content-end gap-2">
        <FormikBootstrap.NumberInput name={props.name} readOnly={readOnly} displayScale={100.0} />
        {!readOnly && onRemoveRate && (
          <Button key="remove" variant="danger" size="sm" onClick={onRemoveRate}>
            <i className="fas fa-trash" />
          </Button>
        )}
      </div>
    </td>
  </Form.Group>
)

const RatesheetTable = ({ name, value, readOnly, onRemoveRate }): JSX.Element => {
  const rateInputs = _.map(value.rates, (rate, rateCtg) => {
    const rateName = `${name}.rates.${rateCtg}`

    return (
      <RatesheetRow
        name={rateName}
        key={rateName}
        label={_.capitalize(rateCtg)}
        value={rate}
        readOnly={readOnly}
        onRemoveRate={onRemoveRate && (() => onRemoveRate(rateCtg))}
      />
    )
  })

  if (_.isEmpty(rateInputs)) return null

  return (
    <Table className="mb-2">
      <thead>
        <tr>
          <th>Category</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>{rateInputs}</tbody>
    </Table>
  )
}

const FlatRatesheetEditor = ({ name, value, readOnly, canEditPricingStructure }): JSX.Element => {
  const formCtx = useFormikContext()

  const addRateCategory = (category) => {
    formCtx.setFieldValue(name, FlatRatesheet.addCategory(value, category))
  }

  const removeRateCategory = (category) => {
    formCtx.setFieldValue(name, FlatRatesheet.removeCategory(value, category))
  }

  return (
    <Row>
      <Col md={12}>
        <RatesheetTable
          name={name}
          value={value}
          readOnly={readOnly}
          onRemoveRate={!readOnly && canEditPricingStructure && removeRateCategory}
        />
        {!readOnly && canEditPricingStructure && (
          <AddRateCategory rateCategories={_.keys(value.rates)} onClick={addRateCategory} />
        )}
      </Col>
    </Row>
  )
}

export default FlatRatesheetEditor
