import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import { waitlist_tour_booking_path, edit_tour_booking_path } from "@/routes"

import { PostDropdownItem } from "@/components/post_links"
import Booking from "@/dashboard/models/Booking"
import { useModal } from "@/helpers/useModal"
import Hidden from "@/components/Hidden"
import { isPrivateVillaBooking } from "@/product_api"

import CancelBookingModal from "./modals/CancelBookingModal"

const ConfirmedBookingTools = ({ booking }: { booking: Booking }): JSX.Element => {
  const toggleModal = useModal((props) => <CancelBookingModal tourBooking={booking} {...props} />)

  return (
    <DropdownButton size="sm" title="Tools" boundary="viewport">
      <Dropdown.Item href={edit_tour_booking_path({ id: booking.bookingId })}> Edit </Dropdown.Item>
      <Dropdown.Item
        href={edit_tour_booking_path({ id: booking.bookingId, rebook: "true", _options: true })}
      >
        {" "}
        Rebook{" "}
      </Dropdown.Item>
      <Dropdown.Item onClick={toggleModal}>Cancel</Dropdown.Item>
      <PostDropdownItem
        url={waitlist_tour_booking_path({ id: booking.bookingId })}
        linkText="Waitlist"
      />
      <Dropdown.Item>No Show</Dropdown.Item>
      <Dropdown.Item>Insurance Letter</Dropdown.Item>
      <Hidden unless={isPrivateVillaBooking(booking)}>
        <Dropdown.Item>Message Villa Arrangements</Dropdown.Item>
      </Hidden>
    </DropdownButton>
  )
}

export default ConfirmedBookingTools
