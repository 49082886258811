import React from "react"
import { useDispatch } from "react-redux"
import TextInput from "@/components/input/Text"
import NumberInput from "@/components/input/Number"
import { Button } from "react-bootstrap"
import {
  onDepartureInputChange,
  addDraftDeparture,
  removeDraftDeparture,
  removeCruiseDraftDeparture,
  addDraftCruiseDeparture,
  onCruiseDepartureInputChange,
} from "../Slice"

const DeparturesEditColumns = (date, departures, property, cruiseDepartures = false) => {
  const dispatch = useDispatch()

  return (
    <>
      {departures.map((departure, index) => (
        <React.Fragment key={index}>
          {property === "departureTime" && (
            <div className="d-flex align-items-center">
              {departures.length !== 1 && (
                <div
                  className="cursor-pointer mt-n1"
                  onClick={() =>
                    cruiseDepartures
                      ? dispatch(removeCruiseDraftDeparture({ date, index }))
                      : dispatch(removeDraftDeparture({ date, index }))
                  }
                >
                  <i className="fas fa-window-close fa-lg me-3 ms-n4" />
                </div>
              )}
              <TextInput
                style={{ minWidth: "75px", maxWidth: "90px" }}
                className="mb-2"
                name="description"
                label=""
                placeholder={departure[property] === "" ? "00:00" : null}
                value={departure[property]}
                onChange={(e) =>
                  cruiseDepartures
                    ? dispatch(
                        onCruiseDepartureInputChange({
                          date,
                          index,
                          property,
                          input: e.target.value,
                        })
                      )
                    : dispatch(
                        onDepartureInputChange({
                          date,
                          index,
                          property,
                          input: e.target.value,
                        })
                      )
                }
              />
            </div>
          )}
          {property !== "departureTime" && (
            <NumberInput
              style={{
                maxWidth: property === "durationInMinutes" ? "100px" : "90px",
              }}
              className="mb-2"
              name="description"
              label=""
              value={departure[property]}
              placeholder={0}
              suffix={property === "durationInMinutes" ? "min" : ""}
              onChange={(e) => {
                cruiseDepartures
                  ? dispatch(
                      onCruiseDepartureInputChange({
                        date,
                        index,
                        property,
                        input: e.target.value,
                      })
                    )
                  : dispatch(
                      onDepartureInputChange({
                        date,
                        index,
                        property,
                        input: e.target.value,
                      })
                    )
              }}
            />
          )}
        </React.Fragment>
      ))}
      {property === "maximumCapacity" && (
        <Button
          className="btn-sm btn-outline-light ms-5 mt-1"
          onClick={() =>
            cruiseDepartures
              ? dispatch(addDraftCruiseDeparture({ date }))
              : dispatch(addDraftDeparture({ date }))
          }
        >
          <i className="fas fa-plus cursor-pointer" />
        </Button>
      )}
    </>
  )
}

export default DeparturesEditColumns
