import React, { FC } from "react"
import { Container } from "react-bootstrap"

const ActionBar: FC = ({ children }) => (
  <Container fluid>
    <div className="mt-4 mb-3">{children}</div>
  </Container>
)

export default ActionBar
