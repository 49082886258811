import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import FormikBootstrap from "@/components/formik-bootstrap"
import { submitMainDetails } from "@/onboarding/features/product/Slice"
import { getReferenceOptions, getProductMainDetails } from "@/onboarding/features/product/selectors"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"

const EditMainDetailsModal = ({
  show,
  onHide,
}: {
  show: boolean
  onHide: () => void
}): JSX.Element => {
  const dispatch = useDispatch()
  const options = useSelector(getReferenceOptions)
  const initialValues = useSelector(getProductMainDetails)
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="edit-main-details-modal-title"
      centered
    >
      <Modal.Header>
        <Modal.Title id="edit-main-details-modal-title">Edit Tour Main Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-4">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setSubmitting(true)
            dispatch(submitMainDetails(values))
          }}
        >
          {({ handleSubmit }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <FormikBootstrap.TextInput name="name" label="Name" />
              <FormikBootstrap.TextInput name="code" label="Code" />
              <FormikBootstrap.SingleSelect name="port" label="Port" options={options.port} />
              <FormikBootstrap.MultiSelect
                name="cruiseBrands"
                label="Cruise Brands"
                options={options.cruiseBrand}
              />
              <FormikBootstrap.SingleSelect
                name="incumbentOperator"
                label="Incumbent Operator"
                options={options.operator}
              />
              <div className="d-flex gap-3">
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button variant="danger" onClick={onHide} disabled={isSubmitting}>
                  Cancel
                </Button>
                {isSubmitting && <LoadingSpinner />}
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditMainDetailsModal
