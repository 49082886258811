import React from "react"
import { useSelector, useDispatch } from "react-redux"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { Button } from "react-bootstrap"
import { isBlank } from "@/utils"
import { getView } from "../selectors"
import { updateDepartures } from "../Slice"
import { RenderCruiseDeparturesColumn, hasSalesMismatch } from "./Helpers"

const SalesDepartures = ({ index }) => {
  const view = useSelector(getView)
  const call = view.inventory.allocationTableData.callDays[index]
  const { unmatchedSalesDepartures } = call.inventory.currentAllocatingTour

  const dispatch = useDispatch()

  const onApproveSalesDepartures = async () => {
    await dispatch(updateDepartures({ departures: unmatchedSalesDepartures }))
  }

  return (
    <>
      <OperatorOnly>
        {!isBlank(unmatchedSalesDepartures) && hasSalesMismatch(call) && (
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="departureTime"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="durationInMinutes"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="minimumCapacity"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="maximumCapacity"
                />
              )}
            </td>
            <td />
            <td>Unmatched sales import</td>
            <td>
              <Button className="primary" onClick={() => onApproveSalesDepartures()}>
                Accept
              </Button>
            </td>
            <td />
            <td />
          </tr>
        )}
      </OperatorOnly>
      <CruiseLineOnly>
        {!isBlank(unmatchedSalesDepartures) && (
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="departureTime"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="durationInMinutes"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="minimumCapacity"
                />
              )}
            </td>
            <td>
              {!isBlank(unmatchedSalesDepartures) && (
                <RenderCruiseDeparturesColumn
                  departures={unmatchedSalesDepartures}
                  property="maximumCapacity"
                />
              )}
            </td>
            <td />
            <td>Pending sales import</td>
            <td />
            <td />
            <td />
          </tr>
        )}
      </CruiseLineOnly>
    </>
  )
}

export default SalesDepartures
