import React from "react"
import { Button, Dropdown } from "react-bootstrap"
import { useSelector } from "react-redux"

import { tickets_path } from "@/routes"
import { getTicketTotals } from "@/dashboard/selectors"

const BulkTicketLink = ({
  date,
  variant = "button",
}: {
  date: string
  variant?: string
}): JSX.Element => {
  const { printedTicketCountTotal, ticketCountTotal } = useSelector(getTicketTotals)

  if (variant === "button") {
    return (
      <Button className="float-end" href={tickets_path({ date })}>
        {"Ticket Prints "}
        {ticketCountTotal > 0 && (
          <span className="badge bg-secondary text-dark">{`${printedTicketCountTotal} / ${ticketCountTotal}`}</span>
        )}
      </Button>
    )
  }

  if (variant === "dropdown") {
    return (
      <Dropdown.Item href={tickets_path({ date })}>
        {"Ticket Prints "}
        {ticketCountTotal > 0 && (
          <span className="badge bg-secondary text-dark">{`${printedTicketCountTotal} / ${ticketCountTotal}`}</span>
        )}
      </Dropdown.Item>
    )
  }

  throw new Error(`Variant ${variant} not supported`)
}

export default BulkTicketLink
