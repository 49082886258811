import { createSlice } from "@reduxjs/toolkit"

const Slice = createSlice({
  name: "guestTours",
  initialState: {
    initialized: false,
    toursPerPort: {},
    passenger: "",
    voyageName: "",
    voyageId: "",
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
  },
})

export const { initialize } = Slice.actions

export default Slice
