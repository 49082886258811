import _ from "lodash"

import { getSubmitableGlobalData } from "@/manual_booking/features/booking_form/selectors"
import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"

import { getSubmitableBookingForm, getBookingFormById } from "./selectors"

const createAsyncFieldThunkConnectionErrorHandler = (thunkAPI) => (error) => {
  const errorMessage = _.get(error, "response.data.message")
  const responseStatus = _.get(error, "response.status")
  const failedConnecting = _.get(error, "request") && !_.get(error, "response")

  if (errorMessage) {
    throw thunkAPI.rejectWithValue({ message: errorMessage })
  } else if (responseStatus && responseStatus >= 500 && responseStatus < 600) {
    throw thunkAPI.rejectWithValue({
      message: "Your request could not be processed. Please try again later or contact support",
    })
  } else if (failedConnecting) {
    throw thunkAPI.rejectWithValue({
      message: "Server could not be reached, please check your internet connection",
    })
  } else {
    throw error
  }
}

const fetchVehicleClassOptions = AsyncField.createLoadThunk(
  "transportRentalBookings/fetchVehicleClassOptions",
  (action) => `bookingForms.${action.payload.formId}._vehicleClassOptions`,
  ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    return Api.manualBooking.transportRental
      .fetchVehicleClassOptions(bookingData)
      .then((response) => ({ value: response.data.data.vehicleClassOptions }))
      .catch(createAsyncFieldThunkConnectionErrorHandler(thunkAPI))
  }
)

const fetchTransmissionOptions = AsyncField.createLoadThunk(
  "transportRentalBookings/fetchTransmissionOptions",
  (action) => `bookingForms.${action.payload.formId}._transmissionOptions`,
  async ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    const response = await Api.manualBooking.transportRental.fetchTransmissionOptions(bookingData)

    return {
      value: response.data.data.transmissionOptions,
    }
  }
)

const fetchAirconOptions = AsyncField.createLoadThunk(
  "transportRentalBookings/fetchAirconOptions",
  (action) => `bookingForms.${action.payload.formId}._airconOptions`,
  ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    return Api.manualBooking.transportRental
      .fetchAirconOptions(bookingData)
      .then((response) => ({ value: response.data.data.airconOptions }))
      .catch(createAsyncFieldThunkConnectionErrorHandler(thunkAPI))
  }
)

const fetchUnlimitedMileageOptions = AsyncField.createLoadThunk(
  "transportRentalBookings/fetchUnlimitedMileage",
  (action) => `bookingForms.${action.payload.formId}._unlimitedMileageOptions`,
  ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    return Api.manualBooking.transportRental
      .fetchUnlimitedMileageOptions(bookingData)
      .then((response) => ({ value: response.data.data.unlimitedMileageOptions }))
      .catch(createAsyncFieldThunkConnectionErrorHandler(thunkAPI))
  }
)

const findMatchingVehicles = AsyncField.createLoadThunk(
  "transportRentalBookings/findMatchingVehicles",
  (action) => `bookingForms.${action.payload.formId}._matchingVehicles`,
  async ({ formId }, thunkAPI) => {
    const bookingData = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    try {
      const response = await Api.manualBooking.transportRental.matchingVehicles(bookingData)
      return { value: response.data.data.matchingVehicles }
    } catch (e) {
      createAsyncFieldThunkConnectionErrorHandler(thunkAPI)(e)
    }
  }
)

const calculateBookingPrice = AsyncField.createLoadThunk(
  "transportRentalBookings/calculateBookingPrice",
  (action) => `bookingForms.${action.payload.formId}._price`,
  async ({ formId }, thunkAPI) => {
    const bookingData = _.merge(
      getSubmitableGlobalData(thunkAPI.getState()),
      getSubmitableBookingForm(
        getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
      )
    )

    const response = await Api.manualBooking.transportRental.price(bookingData)
    const { price } = response.data.data

    if (price.errorMessage.length > 0) {
      return thunkAPI.rejectWithValue({ message: price.errorMessage })
    }

    return { value: price }
  }
)

const fetchDrivers = AsyncField.createLoadThunk(
  "transportRentalBookings/fetchDrivers",
  (action) => `bookingForms.${action.payload.formId}._driverSearch`,
  async ({ q, formId }, thunkAPI) => {
    const { pickupDateTime, dropoffDateTime } = getSubmitableBookingForm(
      getBookingFormById(thunkAPI.getState().transportRentalBookings, formId)
    )

    const response = await Api.manualBooking.transportRental.fetchDrivers(
      q,
      pickupDateTime,
      dropoffDateTime
    )

    return {
      value: response.data.data.drivers,
    }
  }
)

export {
  calculateBookingPrice,
  fetchTransmissionOptions,
  fetchVehicleClassOptions,
  findMatchingVehicles,
  fetchAirconOptions,
  fetchUnlimitedMileageOptions,
  fetchDrivers,
}
