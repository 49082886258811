import React from "react"

import { Table, Button, Modal } from "react-bootstrap"

import { useModal } from "@/helpers/useModal"

const AuditDetailsModal = ({ show, onHide, audit }) => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby={`${audit.eventId}-title`} centered>
    <Modal.Header>
      <Modal.Title id={`${audit.eventId}-title`}>Audit Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div dangerouslySetInnerHTML={{ __html: audit.detailHtml }} />
    </Modal.Body>
  </Modal>
)

const AuditRow = ({ audit }) => {
  const toggleModal = useModal((props) => <AuditDetailsModal audit={audit} {...props} />)

  return (
    <tr>
      <td> {audit.actor} </td>
      <td> {audit.company} </td>
      <td className="text-right"> {audit.occurredAt} </td>
      <td> {audit.description} </td>
      <td>
        <Button variant="primary" onClick={toggleModal}>
          Details
        </Button>
      </td>
    </tr>
  )
}

const AuditTrail = ({ audits }) => {
  let tableBody = null

  if (audits.length === 0) {
    tableBody = (
      <tr>
        <td colSpan="3">
          <i> No audits present </i>
        </td>
      </tr>
    )
  } else {
    tableBody = _.map(audits, (a, idx) => <AuditRow audit={a} key={idx} />)
  }

  return (
    <div className="mb-2">
      <Table>
        <thead>
          <tr>
            <th className="fit"> Actor </th>
            <th className="fit"> Company </th>
            <th className="fit"> Time </th>
            <th> Description </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    </div>
  )
}

export default AuditTrail
