import React from "react"
import { Form } from "react-bootstrap"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { typeContracted, typeUncontracted } from "../Slice"

const ContractedCheckBox = ({ vehicleType }): JSX.Element => {
  const dispatch = useDispatch()

  const { editingCategories, contractedIds, pendingContractedIds } = useSelector(({ profile }) => ({
    editingCategories: profile.editingCategories,
    contractedIds: profile.contractedIds,
    pendingContractedIds: profile.pendingContractedIds,
  }))

  let checked
  let onChange

  if (editingCategories) {
    checked = _.includes(pendingContractedIds, vehicleType.id)
  } else {
    checked = _.includes(contractedIds, vehicleType.id)
  }

  if (editingCategories) {
    if (checked) {
      onChange = () => dispatch(typeUncontracted({ vehicleTypeID: vehicleType.id }))
    } else {
      onChange = () => dispatch(typeContracted({ vehicleTypeID: vehicleType.id }))
    }
  }

  return (
    <>
      <CruiseLineOnly>
        <Form.Check
          type="checkbox"
          checked={checked}
          name="name"
          value={vehicleType.id}
          label={vehicleType.name}
          readOnly
        />
      </CruiseLineOnly>

      <OperatorOnly>
        <Form.Check
          type="checkbox"
          checked={checked}
          name="name"
          value={vehicleType.id}
          label={vehicleType.name}
          onChange={() => onChange()}
        />
      </OperatorOnly>
    </>
  )
}

export default ContractedCheckBox
