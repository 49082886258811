import React from "react"
import { Form } from "react-bootstrap"

import { isBlank } from "@/utils"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Placeholder = () => <div className="bootstrap-feedback-placeholder">&nbsp;</div>

const Invalid = ({ message = "" }) => {
  if (isBlank(message)) return null

  return <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
}

export default {
  Invalid,
}
