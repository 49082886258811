/* eslint-disable import/prefer-default-export */
import consumer from "./consumer"

export const subscribeToConversation = (id: string, onReceiveConversation, onResubscribe) =>
  // console.log(`[ConversationsChannel@${id}] Subscribe`)

  consumer.subscriptions.create(
    {
      channel: "ConversationsChannel",
      id,
    },
    {
      received(message) {
        // console.log(`[ConversationsChannel@${id}] Received`, message)

        switch (message.type) {
          case "conversation":
            onReceiveConversation(message.data)
            break
          case "resubscribe":
            onResubscribe && onResubscribe()
            break
          default:
            console.warn("Unknown message type:", message.type)
        }
      },
    }
  )
