import { DatePicker } from "@/components/DatePickers"
import { Calendar } from "primereact/calendar"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { isBlank } from "@/utils"
import { onDateRangeChange, filter } from "../Slice"
import { getForm, isDisplaySales } from "../selectors"

export const DateSelector = ({
  constraint,
  index,
  dateRange,
  dateType,
  isNewConstraint = false,
}) => {
  const dispatch = useDispatch()

  return (
    <React.Fragment key={index}>
      <DatePicker
        className="mb-2"
        selected={new Date(dateType === "start" ? dateRange.startDate : dateRange.endDate)}
        onChange={(date) =>
          dispatch(
            onDateRangeChange({
              id: constraint.allotmentConstraintId,
              key: dateType === "start" ? "startDate" : "endDate",
              date: formatDate(date),
              isNew: isNewConstraint,
              index,
            })
          )
        }
        inputLabel=""
        inputName={dateType === "start" ? "startDate" : "endDate"}
      />
      <br />
    </React.Fragment>
  )
}

export const formatDate = (calendarDate) => {
  const date = new Date(calendarDate.toString())
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const SingleDateSelect = ({ name }): JSX.Element => {
  const dispatch = useDispatch()

  const form = useSelector(getForm)
  const date = form[name].value

  return (
    <div>
      <label id="calendar-label" htmlFor="calendar-input">
        {form[name].label}
      </label>
      <br />
      <Calendar
        placeholder={date || "Select date"}
        ariaLabelledBy="calendar-label"
        showIcon
        showButtonBar
        dateFormat="yy-mm-dd"
        name={name}
        value={date}
        onChange={(e) => {
          const updatedForm = JSON.parse(JSON.stringify(form))
          updatedForm[name].value = e.target.value === null ? null : formatDate(e.target.value)
          updatedForm.changedFields = [name]
          dispatch(filter(updatedForm))
        }}
      />
    </div>
  )
}

export const MultipleDateSelect = ({ name }): JSX.Element => {
  const dispatch = useDispatch()
  const form = useSelector(getForm)
  const dates = form[name].value
  return (
    <div>
      <label id="calendar-label" htmlFor="calendar-input">
        {form[name].label}
      </label>
      <br />
      <Calendar
        placeholder={dates.length ? dates : "Select dates"}
        ariaLabelledBy="calendar-label"
        selectionMode="multiple"
        dateFormat="yy-mm-dd"
        name={name}
        value={dates.map((dateStr) => new Date(dateStr))}
        showIcon
        showButtonBar
        onChange={(e) => {
          const updatedForm = JSON.parse(JSON.stringify(form))
          const formatted_dates =
            e.target.value === null ? null : e.target.value.map((date) => formatDate(date))
          updatedForm[name].value = formatted_dates
          updatedForm.changedFields = [name]
          dispatch(filter(updatedForm))
        }}
      />
    </div>
  )
}

export const calculateTotalMaximumCapacity = (departures) => {
  if (!departures || departures.length === 0) {
    return 0
  }

  return departures.reduce((total, departure) => total + parseInt(departure.maximumCapacity, 10), 0)
}

export const getSalesTextColor = (nearOrAtSoldOut, exceedsCapacity) =>
  nearOrAtSoldOut ? "#FF5F15" : exceedsCapacity ? "#e11d48" : "#374151"

export const RenderDeparturesColumn = ({
  departures,
  property,
  closed,
  additionalData = false,
}) => {
  const displaySales = useSelector(isDisplaySales)
  if (closed) {
    return (
      <div className="d-flex align-items-center">
        {property === "departureTime" && !isBlank(closed.reason) && (
          <div className="cursor-pointer" key="closed_day">
            <span title={closed.reason} className="fas fa-info-circle d-inline ms-n2 me-2" />
          </div>
        )}
        <div>closed day</div>
      </div>
    )
  }

  if (isBlank(departures)) {
    return <div>none</div>
  }
  if (property === "maximumCapacity" && !additionalData) {
    return (
      <div>
        {departures.map((departure, index) => (
          <div key={index} className="d-flex align-items-center mb-1">
            <div
              className="d-inline me-1"
              style={{
                color: additionalData ? "#888888" : textColor(property, departure.issues),
                position: "relative",
                width: "36px",
              }}
            >
              {departure[property]}
              <span
                className="cursor-pointer"
                style={{
                  fontStyle: "italic",
                  opacity: "0.7",
                  color: "#1f2937",
                  position: "absolute",
                  left: "85%",
                  marginLeft: "10px",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                }}
                data-toggle="tooltip"
                data-placement="top"
              >
                <span title={`${departure.capacitySold} / ${departure[property]} sold`}>
                  {displaySales && (
                    <span
                      style={{
                        color: getSalesTextColor(
                          departure.salesNearOrAtSoldOut,
                          departure.salesExceedsCapacity
                        ),
                      }}
                    >
                      {departure.percentageSold !== "N/A" ? (
                        `(${departure.percentageSold}%)`
                      ) : (
                        <span>N/A</span>
                      )}
                    </span>
                  )}
                </span>
              </span>
            </div>
            {index !== departures.length - 1 && <br />}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      {departures.map((departure, index) => (
        <div className="d-flex align-items-center" key={index}>
          {property === "departureTime" && !isBlank(departure.reason) && (
            <div className="cursor-pointer" key={`${index}-${departure.reason}`}>
              <span title={departure.reason} className="fas fa-info-circle d-inline ms-n2 me-2" />
            </div>
          )}
          {property === "departureTime" && isBlank(departure.reason) && (
            <div className="cursor-pointer" key={index}>
              <span
                className="d-inline"
                style={{
                  marginRight: "14px",
                }}
              />
            </div>
          )}
          <div
            style={{
              color: additionalData ? "#888888" : textColor(property, departure.issues),
            }}
          >
            {departure[property]}
            {index !== departures.length - 1 && <br />}
          </div>
        </div>
      ))}
    </div>
  )
}

export const RenderCruiseDeparturesColumn = ({ departures, property }) => {
  if (isBlank(departures)) {
    return "none"
  }

  return (
    <div style={{ color: "#000000" }}>
      {departures.map((departure, index) => (
        <div className="d-flex align-items-center" key={index}>
          {property === "departureTime" && !isBlank(departure.reason) && (
            <div className="cursor-pointer" key={index}>
              <span title={departure.reason} className="fas fa-info-circle d-inline ms-n2 me-2" />
            </div>
          )}
          <div>
            {departure[property]}
            {index !== departures.length - 1 && <br />}
          </div>
        </div>
      ))}
    </div>
  )
}

export const RenderIssuesColumn = ({ issues }) =>
  !isBlank(issues) &&
  issues.map((issue) => (
    <div
      key={issue.id}
      data-toggle="tooltip"
      data-placement="top"
      title={issue.description}
      className={`cursor-pointer ${issue.state === "dismissed" && "muted"}`}
    >
      <span className={`${issue.iconIndicator}`} title={issue.description} />
    </div>
  ))

export const isRowExpandable = (call, cruiseLineUI = false) => {
  if (!cruiseLineUI) {
    return (
      (!isBlank(call.inventory.currentAllocatingTour.constraints.departures) &&
        call.inventory.currentAllocatingTour.issues.some(
          (issue) => issue.kind === "departures_mismatch"
        )) ||
      !isBlank(call.additionalToursForViewing) ||
      !isBlank(call.additionalInventoryInPort) ||
      !isBlank(call.inventory.currentAllocatingTour.unmatchedSalesDepartures)
    )
  }
  return (
    !isBlank(call.inventory.currentAllocatingTour.constraints.departures) ||
    !isBlank(call.inventory.currentAllocatingTour.unmatchedSalesDepartures) ||
    !isBlank(call.additionalToursForViewing) ||
    !isBlank(call.additionalInventoryInPort)
  )
}

export const snakeToCamel = (text) => {
  const words = text.split("_")
  const camelCase = words.map((word, index) =>
    index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
  )
  return camelCase.join("")
}

export const textColor = (affectedProperty, issues) => {
  if (isBlank(issues)) {
    return "#000000"
  }

  return issues.some((issue) => {
    const camelCaseProperty = snakeToCamel(issue.affectedProperty)
    const departureTimeIssue =
      (camelCaseProperty === "startTime" || camelCaseProperty === "endTime") &&
      affectedProperty === "departureTime"
    return (
      departureTimeIssue ||
      issues.some((issue) => snakeToCamel(issue.affectedProperty) === affectedProperty)
    )
  })
    ? "#e11d48"
    : "#000000"
}

export const hasDepartureMismatch = (call) =>
  call.inventory.currentAllocatingTour.issues.some((issue) => issue.kind === "departures_mismatch")

export const hasSalesMismatch = (call) =>
  call.inventory.currentAllocatingTour.issues.some(
    (issue) => issue.kind === "day_sales_on_missing_departures"
  )
