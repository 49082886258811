import React, { FC } from "react"

import OrderSummary from "./OrderSummary"
import BookingFormList from "./BookingFormList"
import BookingActionBar from "./BookingActionBar"

const ManualBookingForm: FC = () => (
  <>
    <BookingFormList />
    <BookingActionBar />
    <OrderSummary />
  </>
)

export default ManualBookingForm
