import React, { useState, useEffect } from "react"
import { Button, Table, Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import _ from "lodash"

import { useSliceSelector, useSliceActions } from "@/helpers/SliceProvider"

import { useModal } from "@/helpers/useModal"

import { hasInvalidPages, getInvalidPages } from "@/components/Wizard"
import { getAgreement } from "../selectors"

import SignAgreementModal from "./SignAgreementModal"
import PageStatusTable from "./PageStatusTable"

const WarningLine = ({ children }) => <i className="text-warning"> {children} </i>

const ApplicationProblems = ({ agreement }) => {
  const allAgreed = !_.some(agreement.documents, (d) => !d.agreed)

  const invalidPages = getInvalidPages()

  const anyPagesInvalid = hasInvalidPages()

  const messages = []

  if (!allAgreed) {
    messages.push(<span> Agree to all the above documents before continuing </span>)
  }

  if (anyPagesInvalid) {
    messages.push(
      <>
        <span> Address problems in previous steps </span>
        <PageStatusTable />
      </>
    )
  }

  if (messages.length == 0) return null

  return (
    <div>
      <h2 className="h5"> Application Problems </h2>
      <WarningLine>
        <p>
          You must address the following application problems before you can sign the application
        </p>
      </WarningLine>
      <ol>
        {_.map(messages, (msg, idx) => (
          <li key={idx}> {msg} </li>
        ))}
      </ol>
    </div>
  )
}

const DocumentAgreementTable = ({ agreements }): JSX.Element => {
  const dispatch = useDispatch()

  const { agreeToDocument } = useSliceActions()

  // TODO: Check if this is still hardcoded
  const onAccept = (documentId) => {
    dispatch(agreeToDocument({ documentId }))
  }

  return (
    <Table className="mb-2">
      <thead>
        <tr>
          <th>Agreement Document Link</th>
          <th>Acceptance</th>
          <th>Accepting User</th>
          <th>Acceptance Date</th>
        </tr>
      </thead>
      <tbody>
        {_.map(agreements, (a, index) => (
          <tr key={index}>
            <td>
              <a
                className="underline hover-underline cursor-pointer"
                href={a.url}
                target="_blank"
                rel="noreferrer"
              >
                {a.name}
              </a>
            </td>
            <td>
              <Form.Check checked={a.agreed} onChange={() => onAccept(a.id)} />
            </td>
            <td>{a.agreedByUser}</td>
            <td>{a.agreedOnDate}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const SignatureSection = ({ agreement, allAgreed, context }) => {
  let body = <> </>

  if (context.readOnly) {
    if (agreement.signedByUser) {
      body = (
        <div>
          <img src={agreement.signature} height={250} />
          <p>
            <b>Signed by: </b> {agreement.signedByUser} ({agreement.userCompany})
          </p>
        </div>
      )
    } else {
      body = (
        <div>
          <h3 className="h5"> Signature </h3>
          <i> Not signed yet </i>
        </div>
      )
    }
  } else if (!allAgreed) {
    body = null
  }

  return (
    <div>
      <div className="mb-2">{body}</div>
    </div>
  )
}

const AgreementForm = (props): JSX.Element => {
  const dispatch = useDispatch()

  const { submitAgreement } = useSliceActions()

  const { agreement, allAgreed } = useSliceSelector((state) => {
    const agreement = getAgreement(state)
    const allAgreed = !_.some(agreement.documents, (d) => !d.agreed)

    return { agreement, allAgreed }
  })

  const anyPagesInvalid = hasInvalidPages()

  const [canSignAgreement, setCanSignAgreement] = useState(true)

  const onSubmitSignature = (data) => {
    dispatch(submitAgreement(data))
  }

  useEffect(() => {
    setCanSignAgreement(allAgreed && !anyPagesInvalid)
  }, [anyPagesInvalid, allAgreed])

  const toggleAgreementModal = useModal((props) => (
    <SignAgreementModal {...props} onSubmit={onSubmitSignature} />
  ))

  return (
    <>
      <h2 className="h5">{props.title}</h2>

      <div dangerouslySetInnerHTML={{ __html: agreement.agreementText }} />

      <fieldset disabled={props.context.readOnly}>
        <DocumentAgreementTable agreements={agreement.documents} />
      </fieldset>

      <SignatureSection context={props.context} allAgreed={allAgreed} agreement={agreement} />

      <ApplicationProblems agreement={agreement} />

      <div className="d-flex gap-2">
        {props.formNavigation}
        {!props.context.readOnly && (
          <Button variant="success" onClick={toggleAgreementModal} disabled={!canSignAgreement}>
            Sign and Continue
          </Button>
        )}
      </div>
    </>
  )
}

export default AgreementForm
