import React from "react"
import { useSelector } from "react-redux"
import { useField, useFormikContext } from "formik"
import _ from "lodash"

import {
  getDefaultRateCategories,
  getDefaultTiers,
  getFeatures,
} from "@/onboarding/features/product/selectors"
import Pricing from "@/pricing"

const RateInputs = ({ name = "ratesheet", readOnly = false, ...props }) => {
  const formCtx = useFormikContext()
  const [field] = useField(name)

  const value = _.get(formCtx, `values.${name}`)

  const defaultRateCategories = useSelector(getDefaultRateCategories)
  const features = useSelector(getFeatures)
  const defaultTiers = useSelector(getDefaultTiers)

  return (
    <Pricing.Editor
      {...field}
      {...props}
      name={name}
      value={value}
      readOnly={readOnly}
      defaultRateCategories={defaultRateCategories}
      canEditPricingStructure={features.general.product.editPricingStructure}
      defaultTiers={defaultTiers}
    />
  )
}

export default RateInputs
