import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { getOverheadCategories, getField } from "@/onboarding/features/product/selectors"
import { formatPercentage } from "@/helpers/number"
import { useModal } from "@/helpers/useModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"

import OverheadEdit from "./OverheadEdit"
import * as RateTable from "./RateTable"

const OverheadTable: FC = () => {
  const categories = useSelector(getOverheadCategories)
  const field = useSelector(getField(ReservedFields.OVERHEAD_CATEGORIES))
  const title = field.required ? "Overhead Category *" : "Overhead Category"

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Overhead Categories" Body={OverheadEdit} />
  ))

  return (
    <Table>
      <thead>
        <RateTable.HeaderRow editIcon={field.writeable && <EditIcon onEdit={toggleModal} />}>
          <th>{title}</th>
          <th>Description</th>
        </RateTable.HeaderRow>
      </thead>
      <tbody>
        {categories.map((category, index) => (
          <RateTable.Row key={index} ratesheet={category.ratesheet}>
            <td>
              <div className="d-flex gap-2">
                <span className="ps-2">
                  <TextDisplay value={category.name} />
                </span>
                <span>{`(${formatPercentage(category.percentage)})`}</span>
              </div>
            </td>
            <td>{category.description}</td>
          </RateTable.Row>
        ))}
      </tbody>
    </Table>
  )
}

export default OverheadTable
