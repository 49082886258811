import React, { useContext } from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import AuthDescriptorContext from "@/auth_descriptor/Context"
import { acceptGuest, rejectGuest } from "@/operator_check_in/Slice"
import { isAccepted } from "@/operator_check_in/selectors"

const ToggleGuest = ({ guestId }): JSX.Element => {
  const dispatch = useDispatch()
  const { isTourUi } = useContext(AuthDescriptorContext)
  const checked = useSelector(isAccepted(guestId))

  let onChange

  if (!isTourUi) {
    onChange = null
  } else if (checked) {
    onChange = () => dispatch(rejectGuest({ guestId }))
  } else {
    onChange = () => dispatch(acceptGuest({ guestId }))
  }

  return <Form.Check type="checkbox" readOnly={!isTourUi} checked={checked} onChange={onChange} />
}

export default ToggleGuest
