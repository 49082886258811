export const getGuests = ({ guests }) => guests

export const getName = ({ guests }) => guests.name

export const getID = ({ guests }) => guests.guestId

export const getVoyage = ({ guests }) => guests.voyage

export const getPorts = ({ guests }) => guests.ports

export const getBookings = ({ guests }) => guests.bookings

export const getCruise = ({ guests }) => guests.cruise

export const isInitialized = ({ guests }) => guests.initialized
