import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "react-bootstrap"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { isBlank } from "@/utils"
import { useModal } from "@/helpers/useModal"
import {
  approveDepartures,
  closeDays,
  lockDays,
  onCancelBulkEdit,
  openDays,
  unlockDays,
  updateBulkExpansionStates,
  removeDepartures,
  setShowBulkEditForm,
} from "../Slice"
import {
  getBulkExpansionStates,
  getShowBulkEditForm,
  getView,
  getUnlockedDates,
  getDatesWithCruiseRequests,
  getDatesWithOperatorRequests,
  getShip,
  getTour,
  getAllDates,
} from "../selectors"
import EditDepartures from "./EditDepartures"
import ReplaceDepartures from "./ReplaceDepartures"
import ConfirmationModal from "./ConfirmationModal"
import ReasonModal from "./ReasonModal"
import AppendDepartures from "./AppendDepartures"

const BulkEditForm = (): JSX.Element => {
  const dispatch = useDispatch()

  const view = useSelector(getView)
  const ship = useSelector(getShip)
  const tour = useSelector(getTour)
  const showBulkEditForm = useSelector(getShowBulkEditForm)
  const bulkExpansionStates = useSelector(getBulkExpansionStates)
  const unlockedDates = useSelector(getUnlockedDates)
  const datesWithCruiseRequests = useSelector(getDatesWithCruiseRequests)
  const datesWithOperatorRequests = useSelector(getDatesWithOperatorRequests)
  const allDates = useSelector(getAllDates)

  const [expand, setExpand] = useState(bulkExpansionStates || {})
  const [header, setHeader] = useState("")
  const [reasonPrompt, setreasonPrompt] = useState("")
  const [confirmationPrompt, setConfirmationPrompt] = useState("")
  const [confirmationHeader, setConfirmationHeader] = useState("")

  const operatorDepartures = view.inventory.allocationTableData.departures
  const { cruiseDepartures } = view.inventory.allocationTableData

  const toggleConfirmationLockModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onLockDaysCallback}
      buttonText="Lock"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationUnlockModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onUnlockDaysCallback}
      buttonText="Unlock"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationOpenModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onOpenDaysCallback}
      buttonText="Open"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationAcceptOperatorRequestsModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onApproveOperatorRequestsCallback}
      buttonText="Accept"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationAcceptCruiseLineRequestsModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onApproveCruiseLineRequestsCallback}
      buttonText="Accept"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationRemoveCruiseLineRequestsModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onRemoveCruiseLineRequestsCallback}
      buttonText="Remove"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleConfirmationRemoveOperatorDeparturesModal = useModal((props) => (
    <ConfirmationModal
      onConfirmationCallback={onRemoveOperatorDeparturesCallback}
      buttonText="Remove"
      confirmationPrompt={confirmationPrompt}
      header={confirmationHeader}
      {...props}
    />
  ))

  const toggleCloseDaysReasonModal = useModal((props) => (
    <ReasonModal
      reasonPrompt={reasonPrompt}
      buttonText="Close"
      onSubmitCallback={onCloseDaysCallback}
      header={header}
      {...props}
    />
  ))

  useEffect(() => {
    setExpand(bulkExpansionStates)
  }, [bulkExpansionStates])

  const onLockDays = () => {
    setConfirmationPrompt("Are you sure you want to lock all the call days in the table below?")
    setConfirmationHeader("Confirm Locked Days")
    toggleConfirmationLockModal()
  }

  const onLockDaysCallback = async () => {
    dispatch(onCancelBulkEdit())
    await dispatch(lockDays(unlockedDates))
  }

  const onUnlockDays = () => {
    setConfirmationPrompt(
      "Are you sure you want to unlock all the locked call days in the table below?"
    )
    setConfirmationHeader("Confirm Unlocked Days")
    toggleConfirmationUnlockModal()
  }

  const onUnlockDaysCallback = async () => {
    dispatch(onCancelBulkEdit())
    await dispatch(unlockDays(allDates))
  }

  const onOpenDays = () => {
    setConfirmationPrompt(
      "Are you sure you want to open all the closed call days in the table below?"
    )
    setConfirmationHeader("Confirm Open Days")
    toggleConfirmationOpenModal()
  }

  const onOpenDaysCallback = async () => {
    dispatch(onCancelBulkEdit())
    await dispatch(openDays(unlockedDates))
  }

  const onApproveOperatorRequests = async () => {
    setConfirmationPrompt(
      "Are you sure you want to accept all the operator requests for the call days in the table below?"
    )
    setConfirmationHeader("Accept Operator Requests")
    toggleConfirmationAcceptOperatorRequestsModal()
  }

  const onApproveOperatorRequestsCallback = async () => {
    await dispatch(approveDepartures(datesWithOperatorRequests))
  }

  const onApproveCruiseLineRequests = async () => {
    setConfirmationPrompt(
      "Are you sure you want to accept all the departure requests for the call days in the table below?"
    )
    setConfirmationHeader("Accept Departures")
    toggleConfirmationAcceptCruiseLineRequestsModal()
  }

  const onRemoveCruiseLineRequests = async () => {
    setConfirmationPrompt(
      "Are you sure you want to remove all the departure requests for the call days in the table below?"
    )
    setConfirmationHeader("Remove Departure Requests")
    toggleConfirmationRemoveCruiseLineRequestsModal()
  }

  const onRemoveOperatorDepartures = async () => {
    setConfirmationPrompt(
      "Are you sure you want to remove all the departures for the call days in the table below?"
    )
    setConfirmationHeader("Remove Departures")
    toggleConfirmationRemoveOperatorDeparturesModal()
  }

  const onApproveCruiseLineRequestsCallback = async () => {
    dispatch(setShowBulkEditForm({ showFormState: !showBulkEditForm }))
    dispatch(onCancelBulkEdit())
    await dispatch(approveDepartures(datesWithCruiseRequests))
  }

  const onRemoveCruiseLineRequestsCallback = async () => {
    await dispatch(removeDepartures(cruiseDepartures))
  }

  const onRemoveOperatorDeparturesCallback = async () => {
    await dispatch(removeDepartures(operatorDepartures))
  }

  const onCloseDays = () => {
    dispatch(onCancelBulkEdit())
    setHeader("Closed days: No departures will be provided on these days")
    setreasonPrompt(`Reason required for closing ${tour.code} for ${ship.name} on these days`)
    toggleCloseDaysReasonModal()
  }

  const onCloseDaysCallback = async (reason) => {
    dispatch(onCancelBulkEdit())
    await dispatch(closeDays({ dates: unlockedDates, reason }))
  }

  const onExpand = (section) => {
    const newState = { ...expand, [section]: !expand[section] }
    setExpand(newState)
    dispatch(updateBulkExpansionStates({ expansionState: newState }))
  }

  return (
    showBulkEditForm && (
      <div className="ms-4 mt-2">
        <Button variant="primary" className="me-2" onClick={() => onLockDays()}>
          Lock days
        </Button>
        <Button variant="primary" className="me-2" onClick={() => onUnlockDays()}>
          Unlock days
        </Button>
        <CruiseLineOnly>
          <Button variant="primary" className="me-2" onClick={() => onApproveOperatorRequests()}>
            Accept requests
          </Button>
          <Button
            variant="primary"
            disabled={isBlank(cruiseDepartures)}
            className="me-2"
            onClick={() => onRemoveCruiseLineRequests()}
          >
            Remove departure requests
          </Button>
          <Button variant="secondary" className="me-2" onClick={() => onExpand("appendDepartures")}>
            Add departure requests
          </Button>
          <Button
            variant="secondary"
            disabled={isBlank(cruiseDepartures)}
            className="me-2"
            onClick={() => onExpand("editDepartures")}
          >
            Edit departure requests
          </Button>
          <Button
            variant="secondary"
            className="me-2"
            disabled={isBlank(cruiseDepartures)}
            onClick={() => onExpand("replaceDepartures")}
          >
            Replace departure requests
          </Button>
        </CruiseLineOnly>
        <OperatorOnly>
          <Button variant="primary" className="me-2" onClick={() => onCloseDays()}>
            Close days
          </Button>
          <Button variant="primary" className="me-2" onClick={() => onOpenDays()}>
            Open days
          </Button>
          <Button
            variant="primary"
            disabled={isBlank(operatorDepartures)}
            className="me-2"
            onClick={() => onRemoveOperatorDepartures()}
          >
            Remove departures
          </Button>
          <Button
            variant="primary"
            disabled={isBlank(cruiseDepartures)}
            className="me-2"
            onClick={() => onApproveCruiseLineRequests()}
          >
            Accept requests
          </Button>
          <Button variant="secondary" className="me-2" onClick={() => onExpand("appendDepartures")}>
            Add departures
          </Button>
          <Button
            variant="secondary"
            disabled={isBlank(operatorDepartures)}
            className="me-2"
            onClick={() => onExpand("editDepartures")}
          >
            Edit departures
          </Button>
          <Button
            variant="secondary"
            className="me-2"
            disabled={isBlank(operatorDepartures)}
            onClick={() => onExpand("replaceDepartures")}
          >
            Replace departures
          </Button>
        </OperatorOnly>
        {expand.replaceDepartures && <ReplaceDepartures />}
        {expand.editDepartures && <EditDepartures />}
        {expand.appendDepartures && <AppendDepartures />}
      </div>
    )
  )
}

export default BulkEditForm
