import React, { useState } from "react"
import {
  OverlayTrigger,
  Popover,
  PopoverBody,
  PopoverHeader,
  Table,
  Tooltip,
} from "react-bootstrap"

import Tour from "@/dashboard/models/Tour"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { tour_path } from "@/routes"

import BookingTable from "./BookingTable"
import TourTools from "./TourTools"
import TourCodeBadge from "./TourCodeBadge"
import TourPaxConstraints from "./TourPaxConstraints"
import ReconState from "./ReconState"

const UnresolvedComplaintsIcon = ({ tour }) => {
  if (tour.unresolvedComplaints > 0) {
    return <i className="bi bi-megaphone-fill ms-1 text-danger" />
  }

  return null
}

const TourRow = ({ tour, showDate = false }: { tour: Tour; showDate: boolean }): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  let toggleRow

  if (tour.bookings.length === 0) {
    toggleRow = null
  } else {
    toggleRow = () => setExpanded(!expanded)
  }

  const ratesHover = (
    <Popover id={`${tour.tourId}-rates-popover`}>
      <PopoverHeader className="text-center text-white">{`${tour.code} Rates`}</PopoverHeader>
      <PopoverBody>
        <Table>
          <thead>
            <tr>
              <th className="text-center text-white">Type</th>
              <th className="text-right text-white">Net</th>
              <th className="text-right text-white">Retail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="text-center text-white">Adult</th>
              <td className="text-right text-white">{tour.rates.net.adult}</td>
              <td className="text-right text-white">{tour.rates.rack.adult}</td>
            </tr>
            <tr>
              <th className="text-center text-white">Child</th>
              <td className="text-right text-white">{tour.rates.net.child}</td>
              <td className="text-right text-white">{tour.rates.rack.child}</td>
            </tr>
          </tbody>
        </Table>
      </PopoverBody>
    </Popover>
  )

  // The zero margin fixes an issue with Popper causing warnings in the browser
  const componentHover = (
    <Tooltip className="m-0" id={`tooltip-components-${tour.tourId}`}>
      {tour.components === "" ? "No components listed" : tour.components}
    </Tooltip>
  )

  const guestCount = `${tour.confirmedAdults} / ${tour.confirmedChildren} (${tour.confirmedGuests})`

  const availabilityCount =
    tour.availableCount > tour.waitlistedGuests && tour.waitlistedGuests > 0 ? (
      <span>
        {tour.availableCount} <b>({tour.waitlistedGuests})</b>
      </span>
    ) : (
      <span>
        {tour.availableCount} ({tour.waitlistedGuests})
      </span>
    )

  const tourRow = (
    <tr>
      {showDate && (
        <td onClick={toggleRow}>
          <span className="d-block">{tour.startTime.substring(0, 10)}</span>
          <span className="d-inline-flex" />
        </td>
      )}
      <td onClick={toggleRow} className="pe-2">
        <span className="d-block">{tour.timeslot}</span>
        <span className="d-inline-flex gap-1">
          {tour.departureStatus && <span className="badge bg-primary">{tour.departureStatus}</span>}
        </span>
      </td>
      <td onClick={toggleRow}>
        <OverlayTrigger placement="bottom" overlay={componentHover}>
          <span className="d-block">
            <a className="hover-underline" href={tour_path({ id: tour.tourId })}>
              {tour.name}
            </a>
            <UnresolvedComplaintsIcon tour={tour} />
          </span>
        </OverlayTrigger>
        <span className="d-inline-flex gap-1">
          <TourCodeBadge tour={tour} />
        </span>
        <CruiseLineOnly>
          <span className="d-inline-flex gap-1 ms-1">{tour.operators.join(", ")}</span>
        </CruiseLineOnly>
      </td>
      <OperatorOnly>
        <td onClick={toggleRow}>
          {tour.manager} ({tour.shipName})
        </td>
      </OperatorOnly>
      <td onClick={toggleRow}>
        <TourPaxConstraints tour={tour} />
      </td>
      <td onClick={toggleRow} className="text-right">
        {guestCount}
      </td>
      <td onClick={toggleRow} className="text-right">
        {availabilityCount}
      </td>
      <CruiseLineOnly>
        <td onClick={toggleRow}>{tour.cancellationCutoffTime}</td>
      </CruiseLineOnly>
      <OperatorOnly>
        <td onClick={toggleRow} className="text-right">
          {tour.netPrice}
        </td>
      </OperatorOnly>
      <CruiseLineOnly>
        <OverlayTrigger placement="bottom" overlay={ratesHover}>
          <td className="text-right">{tour.retailPrice}</td>
        </OverlayTrigger>
      </CruiseLineOnly>
      <CruiseLineOnly>
        <td onClick={toggleRow} className="text-center">
          <ReconState tour={tour} />
        </td>
      </CruiseLineOnly>
      <td className="pe-2">
        <TourTools tour={tour} />
      </td>
    </tr>
  )

  if (!expanded || tour.bookings.length === 0) {
    return tourRow
  }

  return (
    <>
      {tourRow}
      <tr>
        <td colSpan={showDate ? 10 : 9} className="p-0">
          <BookingTable bookings={tour.bookings} />
        </td>
      </tr>
    </>
  )
}

export default TourRow
