import React from "react"
import { Card, Col, Row } from "react-bootstrap"

import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import ErrorBoundary from "@/components/ErrorBoundary"

import PrivateCarBookingsTable from "./private_car_bookings_card/PrivateCarBookingsTable"
import FilterCarRentalOperator from "./private_car_bookings_card/FilterCarRentalOperator"

const PrivateCarBookingsCard = ({ bookings }) => {
  const renderPrivateCarBookingsTable = () => {
    if (bookings.length === 0) {
      return (
        <Card.Body>
          <p>No private car bookings for the specified date</p>
        </Card.Body>
      )
    }

    return <PrivateCarBookingsTable bookings={bookings} />
  }

  return (
    <ErrorBoundary>
      <Card.Header>
        <Row>
          <Col>
            <br />
            <h2 className="h5"> Private Car Bookings </h2>
            <CruiseLineOnly>
              <FilterCarRentalOperator />
            </CruiseLineOnly>
          </Col>
        </Row>
      </Card.Header>
      {renderPrivateCarBookingsTable()}
    </ErrorBoundary>
  )
}

export default PrivateCarBookingsCard
