import React from "react"
import { Table } from "react-bootstrap"

import Booking from "@/dashboard/models/Booking"

import PrivateVillaBookingRow from "./PrivateVillaBookingRow"

const PrivateVillaBookingTable = ({ bookings }: { bookings: Array<Booking> }): JSX.Element => {
  let tableClassName = "align-items-center table-flush"

  if (bookings.length > 0) {
    // bottom padding
    tableClassName += " mb-3"
  }

  return (
    <Table size="sm" className={tableClassName}>
      <thead className="thead-light">
        <tr>
          <th scope="col">Reference</th>
          <th scope="col">Charged Guest</th>
          <th scope="col" className="text-right">
            Guests
          </th>
          <th scope="col" className="text-right">
            Retail
          </th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {bookings.map((booking) => (
          <PrivateVillaBookingRow booking={booking} key={booking.reference} />
        ))}
      </tbody>
    </Table>
  )
}

export default PrivateVillaBookingTable
