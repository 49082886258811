import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import {
  getSelectedProspectiveAwardSet,
  getSelectedProduct,
  getSelectedBiddingRegion,
} from "@/onboarding/features/price_analysis/selectors"

import AwardSetDropdown from "./header/AwardSetDropdown"
import ProductDropdown from "./header/ProductDropdown"
import ProductLinkButton from "./header/ProductLinkButton"
import DashboardButton from "./header/DashboardButton"
import PriceViewDropdown from "./header/PriceViewDropdown"
import SummaryTable from "./product/SummaryTable"
import ComponentTable from "./product/ComponentTable"

const Product = (): JSX.Element => {
  const selectedAwardSet = useSelector(getSelectedProspectiveAwardSet)
  const product = useSelector(getSelectedProduct)
  const biddingRegion = useSelector(getSelectedBiddingRegion)
  const productName = product?.name || "Product"

  let body

  if (_.isNil(product)) {
    body = (
      <Card.Body>
        <p className="mb-0">Select a product to compare bids.</p>
      </Card.Body>
    )
  } else if (_.isNil(selectedAwardSet)) {
    body = (
      <Card.Body>
        <p className="mb-0">Select a bid scenario to compare bids.</p>
      </Card.Body>
    )
  } else {
    body = (
      <Card.Body className="d-inline-flex flex-column gap-4">
        <SummaryTable />
        <ComponentTable />
      </Card.Body>
    )
  }

  return (
    <Card>
      <Card.Header className="d-inline-flex flex-column gap-4">
        <Row>
          <Col md={5}>
            <h1 className="h4">Cost Analysis</h1>
            <h2 className="h5">{`${productName} Bid Comparison`}</h2>
            {product && (
              <>
                <span className="text-muted mb-0">
                  {`Cruise Brands: ${product.cruiseBrands.map((brand) => brand.name).join(", ")}`}
                </span>
                <br />
              </>
            )}
            {biddingRegion && (
              <>
                <span className="text-muted mb-0">{`Bidding Region: ${biddingRegion.name}`}</span>
                <br />
              </>
            )}
            {product && product.port && (
              <span className="text-muted mb-0">{`Port: ${product.port.name}`}</span>
            )}
          </Col>
          <Col className="d-inline-flex justify-content-end gap-2">
            {product && (
              <div>
                <ProductLinkButton />
              </div>
            )}
            <div>
              <DashboardButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ProductDropdown />
          </Col>
          <Col md={3}>
            <AwardSetDropdown />
          </Col>
          <Col md={3}>
            <PriceViewDropdown />
          </Col>
        </Row>
      </Card.Header>
      {body}
    </Card>
  )
}

export default Product
