import React from "react"
import { Button, Form, Modal } from "react-bootstrap"

import AuthenticityToken from "@/components/AuthenticityToken"
import { start_conversations_path } from "@/routes"
import Tour from "@/dashboard/models/Tour"
import Role from "@/roles"

enum RecipientKind {
  Role = "role",
  TourOperator = "tour_operator",
  TourManager = "tour_manager",
  OnboardingProductTourOperator = "onboarding_product_tour_operator",
  OnboardingProductManager = "onboarding_product_tour_manager",
}

interface Recipient {
  kind: RecipientKind
  id: string
}

class RoleRecipient implements Recipient {
  constructor(role: Role) {
    this.kind = RecipientKind.Role
    this.id = role
  }
}

class TourOperatorRecipient implements Recipient {
  constructor(tour: Tour) {
    this.kind = RecipientKind.TourOperator
    this.id = tour.tourId
  }
}

class TourManagerRecipient implements Recipient {
  constructor(tour: Tour) {
    this.kind = RecipientKind.TourManager
    this.id = tour.tourId
  }
}

interface OnboardingProductOperator {
  id: string
}

interface OnboardingProduct {
  id: string
  operator: OnboardingProductOperator
}

class OnboardingProductTourManagerRecipient implements Recipient {
  constructor(product: OnboardingProduct) {
    this.kind = RecipientKind.OnboardingProductManager
    this.id = product.id
  }
}

class OnboardingProductTourOperatorRecipient implements Recipient {
  constructor(product: OnboardingProduct) {
    this.kind = RecipientKind.OnboardingProductTourOperator
    this.id = product.id
  }
}

const SendMessageModal = ({
  show,
  onHide,
  title,
  companyId,
  messageSubject,
  messageBody,
  recipient,
}: {
  show: boolean
  onHide: () => void
  title: string
  companyId: string
  recipient: Recipient
  messageSubject: string
  messageBody: string
}): JSX.Element => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="send-message-modal-title" centered>
    <Form action={start_conversations_path()} method="post">
      <AuthenticityToken />

      <input type="hidden" name="recipient_type" value={recipient.kind} />
      <input type="hidden" name="recipient_id" value={recipient.id} />

      <Modal.Header>
        <Modal.Title id="send-message-modal-title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label>Subject</Form.Label>
          <Form.Control as="input" name="subject" defaultValue={messageSubject} required />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Your Message</Form.Label>
          <Form.Control as="textarea" name="body" defaultValue={messageBody} rows={3} required />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button type="submit">Send</Button>
      </Modal.Footer>
    </Form>
  </Modal>
)

export default SendMessageModal

export {
  TourOperatorRecipient,
  TourManagerRecipient,
  RoleRecipient,
  OnboardingProductTourManagerRecipient,
  OnboardingProductTourOperatorRecipient,
}
