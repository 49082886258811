import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getProductField, getFieldSettings } from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import RateDisplay from "@/onboarding/features/product/components/shared/RateDisplay"

const FeeDisplay = ({ fee }): JSX.Element => (
  <Col md={6} className="d-flex py-2">
    <div style={{ width: "100px" }}>
      <RateDisplay amount={fee.ratesheet.rates.adult} />
    </div>
    <div>
      {fee.payableByGuest ? (
        <span>
          {fee.name} <b>(guest)</b>
        </span>
      ) : (
        <span>{fee.name}</span>
      )}
    </div>
  </Col>
)

const Content = (): JSX.Element => {
  const { value } = useSelector(getProductField(ReservedFields.EXTRA_FEES))
  const { extra_fees } = useSelector(getFieldSettings(ReservedFields.EXTRA_FEES))
  const prompt = extra_fees.required
    ? "No Extra Fees loaded. This is a required component."
    : "No Extra Fees loaded for this tour"

  return (
    <div className="d-flex flex-column gap-4 px-4">
      {_.isEmpty(value) && <div className="ms-n4">{prompt}</div>}
      {_(value)
        .groupBy("category")
        .map((fees, category) => (
          <Row key={category}>
            <h3 className="h6">{category}</h3>
            <Col>
              <Row className="px-4">
                {fees.map((fee, index) => (
                  <FeeDisplay key={index} fee={fee} />
                ))}
              </Row>
            </Col>
          </Row>
        ))
        .value()}
    </div>
  )
}

export default Content
