import React from "react"

import getCSRFToken from "@/helpers/csrfToken"
import HiddenInput from "@/components/HiddenInput"

const AuthenticityToken = (): JSX.Element => (
  <HiddenInput name="authenticity_token" value={getCSRFToken()} />
)

export default AuthenticityToken
