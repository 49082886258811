import React from "react"

import PriceAnalysis from "@/onboarding/features/price_analysis/PriceAnalysis"
import Slice, {
  LAST_SELECTED_AWARD_SET_ID_PATH,
  LAST_SELECTED_BIDDING_REGION_ID_PATH,
} from "@/onboarding/features/price_analysis/Slice"
import { withApp } from "@/components/App"

const App = ({ season, productId }): JSX.Element => (
  <PriceAnalysis
    season={season}
    selectedBiddingRegionId={window.localStorage.getItem(LAST_SELECTED_BIDDING_REGION_ID_PATH)}
    selectedAwardSetId={window.localStorage.getItem(LAST_SELECTED_AWARD_SET_ID_PATH)}
    selectedProductId={productId}
  />
)

export default withApp(App, { slices: [Slice] })
