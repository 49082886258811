import React from "react"
import { Table, DropdownButton, Dropdown, Spinner } from "react-bootstrap"
import _ from "lodash"

import { useModal } from "@/helpers/useModal"
import PrivateCarBooking, { ReservationStates } from "@/dashboard/models/PrivateCarBooking"
import SendMessageModal, {
  TourOperatorRecipient,
} from "@/dashboard/components/tour_list_card/modals/SendMessageModal"
import Routes from "@/routes"

const PendingSpinner = () => (
  <Spinner animation="border" variant="light" size="sm" className="badge-spinner-sm ms-1" />
)

const PrivateBookingStatusBadge = ({ booking }) => {
  let badgeVariant

  if (
    booking.reservationState === ReservationStates.CONFIRMED ||
    booking.reservationState === ReservationStates.PENDING_CONFIRMATION
  ) {
    badgeVariant = "bg-success"
  } else if (
    booking.reservationState === ReservationStates.CANCELLED ||
    booking.reservationState === ReservationStates.PENDING_CANCELLATION ||
    booking.reservationState === ReservationStates.REJECTED
  ) {
    badgeVariant = "bg-danger"
  } else {
    badgeVariant = "bg-primary"
  }

  if (booking.reservationPending) {
    return (
      <span className="d-block">
        <span className={`badge ${badgeVariant}`}>
          {booking.reservationStateDescription}
          <PendingSpinner />
        </span>
      </span>
    )
  }

  return (
    <span className={`d-block badge ${badgeVariant}`}>{booking.reservationStateDescription}</span>
  )
}

const PrivateCarBookingRow = ({ b }) => {
  const onMessageOperator = useModal((props) => (
    <SendMessageModal
      title={`Message ${b.rentalCompanyName}`}
      companyId={authDescriptor.companyId}
      recipient={new TourOperatorRecipient(tour)}
      messageSubject="Private Car Rental"
      messageBody=""
      {...props}
    />
  ))

  return (
    <tr>
      <td>
        <a
          className="hover-underline"
          href={Routes.private_car_booking_path({ id: b.bookingId, _options: true })}
        >
          {b.token}
        </a>
        <PrivateBookingStatusBadge booking={b} />
      </td>
      <td>{b.rentalCompanyName}</td>
      <td>{b.vehicleDescription}</td>
      <td>
        {b.chargedPassenger.name} ({b.chargedPassenger.roomNumber}
        )
        <br />
        {b.driver.name} ({b.driver.roomNumber})
      </td>
      <td>
        {b.pickupTime}
        <br />
        {b.dropoffTime}
      </td>
      <td colSpan={2}>
        <div className="d-flex gap-2 justify-content-end">
          <DropdownButton size="sm" title="Documents">
            <Dropdown.Item>Ticket</Dropdown.Item>
          </DropdownButton>
          <DropdownButton size="sm" title="Tools">
            <Dropdown.Item>Message Driver</Dropdown.Item>
            <Dropdown.Item onClick={onMessageOperator}>Message Operator</Dropdown.Item>
            <Dropdown.Item>Cancel</Dropdown.Item>
            <Dropdown.Item>Edit</Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
    </tr>
  )
}

const PrivateCarBookingsTable = ({
  bookings,
}: {
  bookings: Array<PrivateCarBooking>
}): JSX.Element => (
  <Table responsive size="sm" className="align-items-center table-flush">
    <thead className="thead-light">
      <tr>
        <th scope="col">Reference</th>
        <th scope="col">Rental Operator</th>
        <th scope="col">Vehicle</th>
        <th scope="col">Charged / Driver</th>
        <th scope="col">Pick-up / Drop-off</th>
        <th colSpan={2}> </th>
      </tr>
    </thead>
    <tbody>
      {_.map(bookings, (b) => (
        <PrivateCarBookingRow b={b} key={b.bookingId} />
      ))}
    </tbody>
  </Table>
)

export default PrivateCarBookingsTable
