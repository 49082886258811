import _ from "lodash"

import { SettlementStates } from "@/dashboard/models/Tour"

const SELECT_ALL_OPERATORS = "All Tour Operators"
const SELECT_ALL_CAR_RENTAL_OPERATORS = "All Rental Operators"

export const getDashboard = ({ dashboard }) => dashboard

export const getFilteredTours = ({ dashboard }) => {
  if (dashboard.selectedOperator === SELECT_ALL_OPERATORS) {
    return dashboard.tours
  }

  return _.filter(dashboard.tours, (tour) => _.includes(tour.operators, dashboard.selectedOperator))
}

export const getFinalizedSettlementTours = (state) =>
  _.filter(getFilteredTours(state), (tour) => tour.settlementState !== SettlementStates.PENDING)

export const getTicketTotals = (state) => {
  const tours = getFilteredTours(state)

  return {
    printedTicketCountTotal: _.sum(_.map(tours, "printedTicketCount")),
    ticketCountTotal: _.sum(_.map(tours, "ticketCount")),
  }
}

export const getTourTotals = (state) => {
  const tours = getFilteredTours(state)

  return _.reduce(
    tours,
    (sum, tour) => ({
      minimumPax: sum.minimumPax + tour.minimumPax,
      maximumPax: sum.maximumPax + tour.maximumPax,
      confirmedAdults: sum.confirmedAdults + tour.confirmedAdults,
      confirmedChildren: sum.confirmedChildren + tour.confirmedChildren,
      confirmedGuests: sum.confirmedGuests + tour.confirmedGuests,
      availableCount: sum.availableCount + tour.availableCount,
      waitlistedGuests: sum.waitlistedGuests + tour.waitlistedGuests,
    }),
    {
      minimumPax: 0,
      maximumPax: 0,
      confirmedAdults: 0,
      confirmedChildren: 0,
      confirmedGuests: 0,
      availableCount: 0,
      waitlistedGuests: 0,
    }
  )
}

export const getFilteredPrivateCarBookings = ({ dashboard }) => {
  if (dashboard.selectedCarRentalOperator === SELECT_ALL_CAR_RENTAL_OPERATORS) {
    return getPrivateCarBookings({ dashboard })
  }

  return _.filter(getPrivateCarBookings({ dashboard }), (booking) =>
    _.includes([booking.rentalCompanyName], dashboard.selectedCarRentalOperator)
  )
}

export const getPrivateCarBookings = ({ dashboard }) => dashboard.privateCarBookings

export const getPrivateVillas = ({ dashboard }) => dashboard.privateVillas

export const getTours = ({ dashboard }) => dashboard.tours

export const getDate = ({ dashboard }) => dashboard.date

export const getAvailableCarRentalOperators = ({ dashboard }) =>
  dashboard.availableCarRentalOperators

export const getSelectedCarRentalOperators = ({ dashboard }) => dashboard.selectedCarRentalOperator
