import React from "react"
import { Dropdown } from "react-bootstrap"
import { useSelector } from "react-redux"

import { useModal } from "@/helpers/useModal"
import CancelPortModal from "@/dashboard/components/tour_list_card/modals/CancelPortModal"
import { getTours, getDate } from "@/dashboard/selectors"

const CancelPortLink = ({ variant = "button" }: { variant: string }): JSX.Element => {
  const tours = useSelector(getTours)
  const date = useSelector(getDate)

  if (!tours || tours.length === 0) {
    return <></>
  }

  const toggleModal = useModal((props) => (
    <CancelPortModal
      portId={tours[0].portId}
      portName={tours[0].portName}
      voyageId={tours[0].voyageId}
      date={date}
      {...props}
    />
  ))

  if (variant === "dropdown") {
    return <Dropdown.Item onClick={toggleModal}>Cancel Port</Dropdown.Item>
  }

  throw new Error(`Variant ${variant} not supported`)
}

export default CancelPortLink
