import React from "react"

import OperatorApplicationApp from "@/onboarding/features/operator_application/App"

import ApplicationReview from "./ApplicationReview"

const PendingApplication = ({ view }) => {
  if (!view.application || !view.application.invitation) {
    return <p>No application is currently in progress</p>
  }

  return (
    <ApplicationReview application={view.application}>
      <OperatorApplicationApp
        form={view.application.invitation}
        sliceName="pendingOperatorApplication"
      />
    </ApplicationReview>
  )
}

export default PendingApplication
