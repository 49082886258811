import React from "react"

const FirstMeetingInfo = ({ firstMeeting }: { firstMeeting?: string }): JSX.Element => {
  if (firstMeeting) {
    return <span>{`First meeting: ${firstMeeting}`}</span>
  }

  return null
}

export default FirstMeetingInfo
