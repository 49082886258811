import _ from "lodash"

import { deepCamelizeKeys, omitAt } from "@/utils"

export const KIND = "Pricing::TieredRatesheet"

export const newRatesheet = (existingRatesheet = undefined) => {
  const blankRatesheet = {
    kind: KIND,
    categoryOrder: [],
    perTier: false,
    independentCategories: false,
    tiers: [],
  }

  if (existingRatesheet) {
    return _.merge(blankRatesheet, _.cloneDeep(deepCamelizeKeys(existingRatesheet)))
  }

  return blankRatesheet
}

const updateRatesheet = (ratesheet, ...updates) => _.assign(newRatesheet(ratesheet), ...updates)

const rateKeysToRateHash = (rateKeys) =>
  _.reduce(
    rateKeys,
    (memo, rateCtg) => {
      memo[rateCtg] = 0.0
      return memo
    },
    {}
  )

export const newTier = (tier = { rateKeys: [] }) => {
  const rateKeys = tier.rateKeys || []

  return _.merge({ tierFrom: 1, rates: rateKeysToRateHash(rateKeys) }, _.omit(tier, "rateKeys"))
}

export const addTier = (ratesheet, tier) =>
  updateRatesheet(ratesheet, {
    tiers: _.concat(ratesheet.tiers, tier),
  })

export const removeTierAt = (ratesheet, tierIdx) =>
  updateRatesheet(ratesheet, {
    tiers: omitAt(ratesheet.tiers, tierIdx),
  })

// TODO I think we need to consider normalize the category name here. At least lowercase internally.
export const addCategory = (ratesheet, category) =>
  updateRatesheet({
    categoryOrder: _.concat(ratesheet.categoryOrder, category),
    tiers: _.map(ratesheet.tiers, (t) => _.merge({ rates: { [category]: 0.0 } }, t)),
  })

export const removeCategory = (ratesheet, category) => {
  const result = updateRatesheet({
    categoryOrder: _.filter(ratesheet.categoryOrder, (ctg) => ctg !== category),
    tiers: _.map(ratesheet.tiers, (t) => _.omit(t, `rates.${category}`)),
  })

  return result
}

const swop = (arr, aIdx, bIdx) => {
  const resultArr = _.clone(arr)

  const a = arr[aIdx]
  const b = arr[bIdx]

  resultArr[aIdx] = b
  resultArr[bIdx] = a

  return resultArr
}

const categoryIndex = (ratesheet, category) => _.indexOf(ratesheet.categoryOrder, category)

export const moveCategoryLeft = (ratesheet, ctg) => {
  const fromIdx = categoryIndex(ratesheet, ctg)

  if (fromIdx === 0) return ratesheet
  return updateRatesheet(ratesheet, {
    categoryOrder: swop(ratesheet.categoryOrder, fromIdx, fromIdx - 1),
  })
}

export const moveCategoryRight = (ratesheet, ctg) => {
  const fromIdx = categoryIndex(ratesheet, ctg)

  if (fromIdx === ratesheet.categoryOrder.length - 1) return ratesheet
  return updateRatesheet(ratesheet, {
    categoryOrder: swop(ratesheet.categoryOrder, fromIdx, fromIdx + 1),
  })
}

// --- Selectors
// returns the rate categories in the correct display order
export const rateCategories = (ratesheet) =>
  // return _.uniq(_.flatMap(ratesheet.tiers, (t) => _.keys(t.rates)))
  ratesheet.categoryOrder

export const maxTierFrom = (ratesheet) => _.max(_.map(ratesheet.tiers, "tierFrom"))

export const nextTierFrom = (ratesheet) => maxTierFrom(ratesheet) + 1

export const tierAsRow = (ratesheet, tier, tierIndex) => ({
  tierFromColumn: { value: tier.tierFrom },
  rateColumns: _.map(rateCategories(ratesheet), (ctg) => ({
    value: tier.rates[_.lowerCase(ctg)] || 0.0,
    category: ctg,
  })),
  tierIndex,
})

export const asTable = (ratesheet) => {
  const headings = _.concat(
    [{ label: "Tier start", value: 0 }],
    _.map(rateCategories(ratesheet), (ctg) => ({ label: `${_.capitalize(ctg)}`, value: ctg }))
  )

  const body = _.map(ratesheet.tiers, (t, idx) => tierAsRow(ratesheet, t, idx))

  return {
    headings,
    body,
    orderedRateCategories: rateCategories(ratesheet),
    tierFroms: _.sortBy(_.map(ratesheet.tiers, "tierFrom")),
  }
}
