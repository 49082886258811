import React from "react"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import {
  checkAllSettlements,
  getDailySettlement,
  getPayableSettlementSheetIds,
  uncheckAllSettlements,
} from "@/daily_settlement/Slice"

const ToggleAllSettlements = (): JSX.Element => {
  const dispatch = useDispatch()
  const payableIds = useSelector(getPayableSettlementSheetIds)
  const { checkedIds } = useSelector(getDailySettlement)

  const checked = _.isEqual(payableIds, checkedIds) && payableIds.length !== 0

  let onChange

  if (checked) {
    onChange = () => dispatch(uncheckAllSettlements({}))
  } else {
    onChange = () => dispatch(checkAllSettlements({}))
  }

  return <Form.Check type="checkbox" checked={checked} onChange={onChange} />
}

export default ToggleAllSettlements
