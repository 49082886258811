import React from "react"

import { useSelector, useDispatch } from "react-redux"

import { Button } from "react-bootstrap"
import ContentVisibility from "@/components/ContentVisibility"

import { isHidden } from "@/helpers/formSpec"

import { getLastBooking } from "@/manual_booking/features/tour_bookings/selectors"

import { copyBookingForm } from "@/manual_booking/features/tour_bookings/Slice"

const ActionBarButtons = (props) => {
  const dispatch = useDispatch()

  const lastFormId = useSelector((state) => (getLastBooking(state.tourBookings) || {}).formId)

  const fields = useSelector((state) => state.tourBookings.fields)

  return (
    <ContentVisibility hidden={isHidden(fields, "addBooking")} key="tourBookings.addBooking">
      <Button
        variant="secondary"
        onClick={() => dispatch(copyBookingForm({ sourceFormId: lastFormId }))}
      >
        <i className="bi bi-plus-lg"> </i> Tour
      </Button>
    </ContentVisibility>
  )
}

export default ActionBarButtons
