import React from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"

import Checkbox from "@/components/Checkbox"
import * as TieredRatesheet from "../../models/TieredRatesheet"

const TieredRatesheetView = ({ value }) => {
  const table = TieredRatesheet.asTable(value)

  return (
    <div>
      <Checkbox disabled value={value.perTier} label="Price per tier?" />
      <Checkbox disabled value={value.independentCategories} label="Independent Rate Categories?" />
      <Table className="mb-2">
        <thead>
          <tr>
            {_.map(table.headings, (heading, index) => (
              <th key={index}>{heading.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.map(table.body, (tier, idx) => (
            <tr key={idx}>
              <td key="tierFrom">{tier.tierFromColumn.value}</td>
              {_.map(tier.rateColumns, (r) => (
                <td key={r.category}>{r.value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default TieredRatesheetView
