import React, { FC, useContext } from "react"

import AuthDescriptorContext from "./Context"

const OperatorOnly: FC = ({ children }) => {
  const { isTourUi } = useContext(AuthDescriptorContext)

  if (!isTourUi) {
    return null
  }

  return <>{children}</>
}

export default OperatorOnly
