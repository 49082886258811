import React from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"
import routes from "@/routes"
import { format, parseISO } from "date-fns"
import StatusIcon from "../../StatusIcon"

const CertificateTable = ({ insuranceUploads }) => (
  <Table size="sm" className="align-items-center table-flush mb-2 table-borderless">
    <thead className="thead-light">
      <tr>
        <th scope="col">
          <div className="ms-2">Effective Date</div>
        </th>
        <th scope="col">Expiration Date</th>
        <th scope="col">Certificate Status</th>
        <th scope="col">Certificate Link</th>
        <th scope="col">Certificate Categories</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {_.isEmpty(insuranceUploads) && (
        <tr>
          <td colSpan={8}>
            <div className="ms-2 mt-2">No certificates available</div>
          </td>
        </tr>
      )}
      {insuranceUploads.map((insuranceUpload) => (
        <tr key={insuranceUpload.certificateId}>
          <td>
            <div className="ms-2">
              {format(parseISO(insuranceUpload.effectiveDate), "dd MMMM yyyy")}
            </div>
          </td>
          <td>{format(parseISO(insuranceUpload.expirationDate), "dd MMMM yyyy")}</td>
          <td>
            <StatusIcon
              status={insuranceUpload.status}
              label={_.startCase(insuranceUpload.statusLabel)}
            />
            <span> {_.startCase(insuranceUpload.statusLabel)} </span>
          </td>
          <td>
            <a
              className="hover-underline"
              href={routes.file_holder_path({ id: insuranceUpload.fileSrc || "dummy" })}
              download
            >
              <span className="fa fa-file" />
              <span> Download insurance certificate</span>
            </a>
          </td>
          <td>
            {insuranceUpload.categories.map((category) => (
              <div key={category}>
                {category}
                <br />
              </div>
            ))}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export default CertificateTable
