import React from "react"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

import formatISO from "date-fns/formatISO"
import parseISO from "date-fns/parseISO"
import { AsyncDatePicker } from "@/components/DatePickers"

import {
  hasReadyOverlappingTimeline,
  hasUnloadedTimelineFields,
  getDaysBetween,
} from "@/manual_booking/VoyageTimelineSelectors"

// TODO Cross feature imports are very smelly
import { loadVoyageDate } from "@/manual_booking/features/booking_form/Slice"

const dateString = (date) => formatISO(date, { representation: "date" })

const VoyageAwareDatePicker = ({ showOnlyCurrentVoyage = false, ...props }) => {
  const state = useSelector((state) => state)

  const dispatch = useDispatch()

  return (
    <AsyncDatePicker
      isLoading={useSelector((state) => hasUnloadedTimelineFields(state))}
      loadDates={({ dates, visibleStartDate, visibleEndDate }) => {
        // XXX Implicitly assuming all dates are getting loaded
        dispatch(loadVoyageDate({ date: dateString(dates[0]) }))
      }}
      isDateLoaded={(date) => hasReadyOverlappingTimeline(state, date)}
      getHighlightedDates={({ visibleStartDate, visibleEndDate }) => {
        const inPortDates = _.chain(getDaysBetween(state, visibleStartDate, visibleEndDate))
          .filter((day) => !showOnlyCurrentVoyage || day.isCurrentVoyage)
          .map((day) => parseISO(day.date))
          .value()

        return [
          {
            "react-datepicker__day--highlight-in-port": inPortDates,
          },
        ]
      }}
      {...props}
    />
  )
}

export default VoyageAwareDatePicker
