import React, { useState } from "react"
import { Button, Form, Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import FormikBootstrap from "@/components/formik-bootstrap"
import _ from "lodash"
import MultiSelect from "@/components/input/MultiSelect"
import { getMinimumCoverageRequirement } from "@/insurance/features/helpers"
import { getProfile } from "../selectors"
import { uploadInsuranceCertificate, setIsNewUpload } from "../Slice"

const NewUpload = ({ upload, onCancel }): JSX.Element => {
  const dispatch = useDispatch()
  const { _view } = useSelector(getProfile)
  const form = upload.data
  const certificateId = upload.id

  const { categoriesForm } = form

  const [selectedCategories, setSelectedCategories] = useState(_.cloneDeep(categoriesForm.value))

  const [minimumCoverageRequirement, setMinimumCoverageRequirement] = useState(
    getMinimumCoverageRequirement(categoriesForm.value, _view.value.profile.insuranceCategories)
  )

  const onChange = (e) => {
    setSelectedCategories(e.target.value)
    setMinimumCoverageRequirement(
      getMinimumCoverageRequirement(e.target.value, _view.value.profile.insuranceCategories)
    )
  }

  return (
    <div key={`newCertificate-${certificateId}`} className="col-sm-8">
      <Card className="pb-4 mb-3">
        <h5 className="mt-2 ms-3">Certificate Upload</h5>
        <div className="col-lg-3 ms-3 mb-4">
          <MultiSelect
            name="categories"
            value={selectedCategories}
            label="Select certificate categories"
            onChange={onChange}
            placeholder="General Liability"
            options={categoriesForm.options}
            maxSelectedLabels={1}
          />
        </div>
        <Formik
          enableReinitialize
          initialValues={form}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(setIsNewUpload({ isNewUpload: false }))
            const categories =
              selectedCategories.length > 0 ? selectedCategories : ["General Liability"]
            const status = "pending"
            const valuesToSend = {
              ...values,
              categories,
              status,
            }

            dispatch(uploadInsuranceCertificate({ values: valuesToSend }))
            onCancel()
            setSubmitting(false)
          }}
        >
          {({ handleSubmit, isSubmitting, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <FormikBootstrap.TextInput name="insurer" label="Insurer" required />
                    <div className="mt-2" />
                    <FormikBootstrap.TextInput name="brokerName" label="Broker Name" required />
                    <div className="mt-2" />
                    <FormikBootstrap.TextInput name="policyNumber" label="Policy Number" required />
                    <div className="mt-2" />
                    <FormikBootstrap.NumberInput
                      name="minimumCoverage"
                      label={`Minimum Coverage (${_view.value.profile.currency})`}
                      minimum={minimumCoverageRequirement}
                      // minimum={500000}
                      required
                    />
                    <div className="mt-2" />
                    <FormikBootstrap.NumberInput
                      name="deductible"
                      label={`Deductible (${_view.value.profile.currency})`}
                      required
                    />
                    <div className="mt-2" />
                    <FormikBootstrap.TextInput
                      name="subcontractor"
                      label="Subcontractor (Optional)"
                    />
                  </div>
                  <div className="col">
                    <FormikBootstrap.Select name="currencyCode" label="Currency Code" required>
                      <option value="">Select Currency</option>
                      {form.currencyForm.map((currency) => (
                        <option key={currency.value} value={currency.value}>
                          {currency.label}
                        </option>
                      ))}
                    </FormikBootstrap.Select>
                    <div className="mt-2" />
                    <FormikBootstrap.DatePicker name="effectiveDate" label="Effective Date" />
                    <div className="mt-2" />
                    <FormikBootstrap.DatePicker name="expirationDate" label="Expiration Date" />
                    <div className="mt-2" />
                    <FormikBootstrap.TextInput name="jurisdiction" label="Jurisdiction" required />
                    <div className="mt-2" />
                    <FormikBootstrap.FileInput name="fileSrc" label="Upload certificate" required />
                    <small className="text-muted">
                      *Your name must match the name on the certificate
                    </small>
                    <div className="mt-2" />

                    <small className="d-inline">
                      <strong>
                        By submitting this form, you confirm that the information provided is true,
                        accurate and that you have the authority to share it.
                      </strong>
                    </small>
                    <div key={`check-${certificateId}`} className="d-inline-block ms-1">
                      <span className="fa fa-check-circle text-success" />
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                      <Button className="btn-secondary" onClick={onCancel}>
                        <i className="fas fa-times me-2" />
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn-primary ms-2"
                        disabled={!dirty || isSubmitting}
                      >
                        <i className="fas fa-save me-2" />
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default NewUpload
