import { createSlice } from "@reduxjs/toolkit"

const Slice = createSlice({
  name: "guestTourDescription",
  initialState: {
    initialized: false,
    passenger: "",
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
  },
})

export const { initialize } = Slice.actions

export default Slice
