import _ from "lodash"
import { isSameDay, parseISO } from "date-fns"

import * as AsyncField from "@/helpers/AsyncField"
import { dateLteq, dateGteq } from "@/utils"

/**
 * There are about three main concepts used here
 * - AsyncField
 * - A timeline
 * - A day
 */
const toDate = (date) => (_.isDate(date) ? date : parseISO(date))

const getTimelineFieldsHash = (state) => state.manualBooking.voyageTimeline.fields

const getStaleTimelineFieldsHash = (state) =>
  _.pickBy(getTimelineFieldsHash(state), (field, key) => AsyncField.isStale(field))

const getTimeLineFields = (state) => _.values(getTimelineFieldsHash(state))

const getReadyTimelineFields = (state) => _.filter(getTimeLineFields(state), AsyncField.isReady)

const getReadyTimelines = (state) => _.map(getReadyTimelineFields(state), AsyncField.getValue)

const hasUnloadedTimelineFields = (state) =>
  _.some(getTimeLineFields(state), (f) => !AsyncField.isReady(f))

const dayOverlaps = (point, date) => isSameDay(toDate(point.date), date)

const timelineOverlaps = (timeline, startDate, endDate = startDate) =>
  dateLteq(startDate, toDate(timeline.endDate)) && dateGteq(endDate, toDate(timeline.startDate))

const hasReadyOverlappingTimeline = (state, date) =>
  _.some(getReadyTimelines(state), (t) => timelineOverlaps(t, date))

const getDaysBetween = (state, startDate, endDate) =>
  _.chain(getReadyTimelines(state))
    .filter((timeline) => timelineOverlaps(timeline, startDate, endDate))
    .flatMap((timeline) => timeline.daysInPort)
    .value()

const getPortDatesBetween = (state, startDate, endDate) =>
  _.chain(getDaysBetween(state, startDate, endDate))
    .map((portDay) => portDay.date)
    .uniq()
    .value()

export {
  getStaleTimelineFieldsHash,
  getTimelineFieldsHash,
  hasReadyOverlappingTimeline,
  hasUnloadedTimelineFields,
  getPortDatesBetween,
  getDaysBetween,
}
