import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getProduct } from "@/onboarding/features/product/selectors"
import MultiSelect from "@/components/input/MultiSelect"

const Dropdown: FC = () => {
  const { cruiseBrands } = useSelector(getProduct)

  const value = cruiseBrands.map((brand) => ({ id: brand.id, label: brand.initials }))
  const options = cruiseBrands.map((brand) => ({ id: brand.id, label: brand.initials }))

  return <MultiSelect name="cruiseBrands" label="Brands" options={options} value={value} />
}

export default Dropdown
