import Api from "@/api"

const sendMessage = (options = {}) => Api.chat.send(options)

const markAsRead = ({ conversationId }) => Api.chat.markAsRead(conversationId)

export default {
  sendMessage,
  markAsRead,
}
