import React, { useEffect, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import FloatableGroup from "./FloatableGroup"
import Feedback from "./Feedback"
import { warnInputPropsForwarding, omitDeprecatedInputProps } from "./deprecations"

const Number = ({
  label = undefined,
  floating = false,
  debounce = false,
  formGroupOptions = {},
  validationMessage = "",
  type = undefined,
  className = undefined,
  suffix = "",
  ...props
}) => {
  warnInputPropsForwarding(props)
  props = omitDeprecatedInputProps(props)

  const [initialized, setInitialized] = useState(false)
  const [delayedValue, setDelayedValue] = useState(props.value || "")

  useEffect(() => {
    // Avoid firing the onChange when this component first loads
    if (!initialized) {
      setInitialized(true)
      return
    }

    const timeOutId = setTimeout(() => {
      props.onChange({ target: { value: delayedValue } })
    }, 500)

    return () => clearTimeout(timeOutId)
  }, [delayedValue])

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    if (/^-?\d*$/.test(inputValue)) {
      setDelayedValue(inputValue)
    }
  }

  const placeholderStyle = `
    .placeholder-muted::placeholder {
      color: #BEBEBE;
    }
  `

  return (
    <FloatableGroup label={label} floating={floating} {...formGroupOptions} className={className}>
      <style>{placeholderStyle}</style>
      <InputGroup>
        <Form.Control
          {...props}
          value={delayedValue}
          onChange={handleInputChange}
          className="placeholder-muted"
        />
        {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
      </InputGroup>
      <Feedback.Invalid message={validationMessage} />
    </FloatableGroup>
  )
}

export default Number
