import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import { getField, getProductField } from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import RateInputs from "../shared/RateInputs"

const Edit = ({ setEditing }): JSX.Element => {
  const { value } = useSelector(getProductField(ReservedFields.HEADSET_COMPONENTS))
  const dispatch = useDispatch()
  const field = useSelector(getField(ReservedFields.HEADSET_FEE_RATESHEET))

  return (
    <Formik
      initialValues={{
        headsetComponents: value,
        [ReservedFields.HEADSET_FEE_RATESHEET]: field.value,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        dispatch(
          updateFields({
            values: {
              [ReservedFields.HEADSET_COMPONENTS]: values.headsetComponents,
              [ReservedFields.HEADSET_FEE_RATESHEET]: values[ReservedFields.HEADSET_FEE_RATESHEET],
            },
          })
        )
        setSubmitting(false)
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          {_.isEmpty(values.headsetComponents) ? (
            <p className="text-muted">
              Once headset components have been loaded, you can edit them here.
            </p>
          ) : (
            <Row>
              <Col md={6}>
                <h5>Edit Details</h5>
                {_(values.headsetComponents)
                  .map((headset, index) => _.merge({ index }, headset))
                  .groupBy("category")
                  .map((headsets, category) => (
                    <Row key={category}>
                      <h3 className="h6">{_.startCase(category)}</h3>
                      <Col className="px-4 gap-2">
                        {headsets.map((headset, index) => (
                          <Row key={index}>
                            <Col md={3}>
                              <TextDisplay value={headset.name} />
                            </Col>
                            <Col md={9}>
                              <FormikBootstrap.Switch
                                name={`headsetComponents[${headset.index}].headsetProvided`}
                                label="Headset provided?"
                              />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  ))
                  .value()}
              </Col>

              <Col md={4}>
                <h5>Edit Rates</h5>
                <RateInputs name={ReservedFields.HEADSET_FEE_RATESHEET} />
              </Col>
            </Row>
          )}
          <div className="d-flex gap-2">
            <Button size="sm" type="submit" disabled={!dirty || isSubmitting}>
              <i className="fas fa-save me-2" />
              Save
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
