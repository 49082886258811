import React from "react"
import _ from "lodash"

import { getSubmitableBookingForms } from "@/manual_booking/features/tour_bookings/selectors"
import { getBookingDescription } from "@/manual_booking/features/tour_bookings/bookingSelectors"
import TourBookingForm from "@/manual_booking/features/tour_bookings/TourBookingForm"
import ActionBarButtons from "@/manual_booking/features/private_villa_bookings/ActionBarButtons"
import { isPrivateVillaBooking } from "@/manual_booking/productSelectors"
import { addCoupon } from "@/manual_booking/features/tour_bookings/Slice"

// TODO break coupling to the touurBookings slice name
export default {
  name: "privateVillaBookings",
  getBookings: (state) =>
    _.chain(state.tourBookings.bookingForms)
      .filter(isPrivateVillaBooking)
      .map((b) => ({
        formId: b.formId,
        description: getBookingDescription(b),
        price: b._price,
        createdAtMillis: b.createdAtMillis,
        kind: b.kind,
        hasGuests: b.passengerIds.length > 0,
        couponCodes: b.couponCodes,
      }))
      .value(),
  getBookingsForSubmission: (state) =>
    _.filter(getSubmitableBookingForms(state.tourBookings), isPrivateVillaBooking),
  canRenderBooking: (booking) => booking && isPrivateVillaBooking(booking),
  renderBooking: (booking) => <TourBookingForm formId={booking.formId} />,
  getActionBarButtons: () => <ActionBarButtons />,
  addCoupon,
}
