import React, { ReactNode } from "react"
import _ from "lodash"

import { AsyncField } from "@/helpers/AsyncField"

const RESERVATION_SYSTEMS = {}

interface Price {
  amount: number
  currency: string
}

interface Booking {
  kind: string
  formId: string
  description?: string
  createdAtMillis: number
  price: AsyncField<Price>
  hasGuests: boolean
  couponCodes: Array<string>
}

interface ReservationSystem {
  name: string
  getBookings: (state: any) => Array<Booking>
  getBookingsForSubmission: (state: any) => Array<Object>
  canRenderBooking: (booking: Booking) => boolean
  renderBooking: (booking: Booking) => ReactNode
  getActionBarButtons: () => ReactNode
  addCoupon: any
}

const registerReservationSystem = (sys: ReservationSystem) => {
  if (RESERVATION_SYSTEMS[sys.name]) {
    throw new Error(`Reservation system ${sys.name} has already been defined.`)
  }

  RESERVATION_SYSTEMS[sys.name] = sys
}

const getReservationSystems = (): Array<ReservationSystem> => _.values(RESERVATION_SYSTEMS)

const renderBooking = (booking) => {
  for (const sysName in RESERVATION_SYSTEMS) {
    const sys = RESERVATION_SYSTEMS[sysName]

    if (sys.canRenderBooking(booking)) {
      return sys.renderBooking(booking)
    }
  }
  throw new Error(`No reservation system supports rendering booking: ${JSON.stringify(booking)}`)
}

const getBookings = (state) => _.flatMap(getReservationSystems(), (sys) => sys.getBookings(state))

const getBookingsForSubmission = (state) =>
  _.flatMap(getReservationSystems(), (sys) => sys.getBookingsForSubmission(state))

const renderActionBarButtons = () =>
  _.map(getReservationSystems(), (sys, idx) => <span key={idx}>{sys.getActionBarButtons()}</span>)

const addCouponToBookings = (dispatch, state, code) => {
  const bookings = getBookings(state)
  const reservationSystems = getReservationSystems()

  bookings.forEach((booking) =>
    reservationSystems
      .filter((system) => system.canRenderBooking(booking))
      .forEach((system) => dispatch(system.addCoupon({ code, formId: booking.formId })))
  )
}

export {
  registerReservationSystem,
  getReservationSystems,
  renderBooking,
  getBookings,
  getBookingsForSubmission,
  renderActionBarButtons,
  addCouponToBookings,
  ReservationSystem,
  Booking,
  Price,
}
