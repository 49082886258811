import _ from "lodash"

import { deepCamelizeKeys } from "@/utils"

export const KIND = "Pricing::FlatRatesheet"

export const newRatesheet = (existingRatesheet = undefined) => {
  if (existingRatesheet) {
    return _.cloneDeep(deepCamelizeKeys(existingRatesheet))
  }

  return { kind: KIND, rates: {} }
}

const updateRatesheet = (ratesheet, ...updates) => _.assign(newRatesheet(ratesheet), ...updates)

export const rateCategories = ({ rates }) => _(rates).keys().uniq().sort().value()

export const addCategory = (ratesheet, category) =>
  updateRatesheet(ratesheet, {
    rates: _.assign(ratesheet.rates, { [category]: 0.0 }),
  })

export const removeCategory = (ratesheet, category) =>
  updateRatesheet(ratesheet, {
    rates: _.omit(ratesheet.rates, category),
  })

export const newTier = (tier = {}) => tier

export const addTier = (ratesheet, _tier) => ratesheet
