import React from "react"
import { Badge } from "react-bootstrap"

import ItemStates from "./ItemStates"

const ItemStatusBadge = ({ state }: { state: ItemStates }): JSX.Element => {
  switch (state) {
    case ItemStates.PENDING:
      return <Badge bg="warning">Pending</Badge>
    case ItemStates.STARTED:
      return <Badge bg="primary">Started</Badge>
    case ItemStates.FAILED:
      return <Badge bg="danger">Failed</Badge>
    case ItemStates.SUCCESSFUL:
      return <Badge bg="success">Successful</Badge>
    default:
      throw new Error(`State ${state} is not supported`)
  }
}

export default ItemStatusBadge
