import React from "react"
import { Card, Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"

import { getImport } from "./selectors"
import ItemStatusBadge from "./ItemStatusBadge"

const ImportTable = (): JSX.Element => {
  const { seasonName, finished, items } = useSelector(getImport)

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <div className="d-flex justify-content-between flex-wrap">
          <h1 className="h4 mb-0">{`${seasonName} Import Status`}</h1>
          {!finished && <LoadingSpinner />}
        </div>
      </Card.Header>
      <Table>
        <thead className="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Code</th>
            <th scope="col">Port</th>
            <th scope="col" className="text-center">
              State
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td>{item.port}</td>
              <td className="text-center">
                <ItemStatusBadge state={item.state} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

export default ImportTable
