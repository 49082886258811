import React, { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"
import { useAuthDescriptor } from "@/auth_descriptor/hooks"

import { getProductFilters, getState, isProductStale } from "./selectors"
import { fetchProduct, initialize } from "./Slice"
import TourForm from "./components/TourForm"

const FormWrapper: FC = () => {
  const dispatch = useDispatch()

  const productStale = useSelector(isProductStale)
  const productFilters = useSelector(getProductFilters)

  useEffect(() => {
    if (productStale) {
      dispatch(fetchProduct(productFilters))
    }
  }, [productStale, productFilters])

  if (productStale) {
    return <LoadingSpinner />
  }

  return <TourForm />
}

const Product = (props): JSX.Element => {
  const dispatch = useDispatch()

  const authDescriptor = useAuthDescriptor()
  const { initialized } = useSelector(getState)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ ...props, authDescriptor }))
    }
  }, [initialized, authDescriptor])

  if (!initialized) {
    return <LoadingSpinner />
  }

  return <FormWrapper />
}

export default Product
