import _ from "lodash"

export const getItinerary = ({ itinerary }) => itinerary

export const getVisitsByItinerary = (state) => {
  const visits = getVisits(state)
  const selected = getSelectedItinerary(state)

  if (selected) {
    return _.filter(visits, (v) => v.itinerary.id === selected.id)
  }

  return visits
}

export const getVisitsByPort = (state) => {
  const visits = getVisits(state)
  const selected = getSelectedPort(state)

  if (selected) {
    return _.filter(visits, (v) => v.port.id === selected.id)
  }

  return visits
}

export const getVisitsByShip = (state) => {
  const visits = getVisits(state)
  const selected = getSelectedShip(state)

  if (selected) {
    return _.filter(visits, (v) => v.ship.id === selected.id)
  }

  return visits
}

export const getSeasons = (state) => getItinerary(state).seasons

export const getItineraries = (state) => getItinerary(state).itineraries

export const getPorts = (state) => getItinerary(state).ports

export const getShips = (state) => getItinerary(state).ships

export const getVisits = (state) => getItinerary(state).visits

export const getSelectedSeason = (state) => {
  const { selectedSeasonId } = getItinerary(state)

  return _.find(getSeasons(state), (season) => season.id === selectedSeasonId)
}

export const getSelectedItinerary = (state) => {
  const { selectedItineraryId } = getItinerary(state)

  return _.find(getItineraries(state), (itinerary) => itinerary.id === selectedItineraryId)
}

export const getSelectedPort = (state) => {
  const { selectedPortId } = getItinerary(state)

  return _.find(getPorts(state), (port) => port.id === selectedPortId)
}

export const getSelectedShip = (state) => {
  const { selectedShipId } = getItinerary(state)

  return _.find(getShips(state), (ship) => ship.id === selectedShipId)
}
