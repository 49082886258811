import { useSelector } from "react-redux"

import { hasField } from "@/onboarding/features/product/selectors"

const FieldVisible = ({ fieldName, children }): JSX.Element => {
  const isVisible = useSelector(hasField(fieldName))

  if (!isVisible) {
    return null
  }

  return children
}

export default FieldVisible
