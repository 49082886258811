import React from "react"
import { Button } from "react-bootstrap"
import { useField } from "formik"

const DeleteFieldButton = ({ fieldName }) => {
  const [field, meta, helpers] = useField(fieldName)
  const { removed } = field.value

  let variant

  if (removed) {
    variant = "danger"
  } else {
    variant = "outline-danger"
  }

  const onClick = () => {
    helpers.setValue({
      ...field.value,
      removed: !removed,
    })
  }

  return (
    <Button key="remove" variant={variant} size="sm" onClick={onClick}>
      <i className="fas fa-trash me-2" />
      Delete
    </Button>
  )
}

export default DeleteFieldButton
