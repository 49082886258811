import React, { FC } from "react"
import { Form, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Field as FormikField } from "formik"

import { getSettlementMethod, getField } from "@/onboarding/features/product/selectors"
import { ReservedFields, SettlementMethods } from "@/onboarding/features/product/models/Product"
import { useModal } from "@/helpers/useModal"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"
import FormikFieldSubmitter from "@/onboarding/features/product/components/shared/FormikFieldSubmitter"
import FormikBootstrap from "@/components/formik-bootstrap"

const displayName = (settlementMethod: SettlementMethods) => {
  switch (settlementMethod) {
    case SettlementMethods.PER_GUEST:
      return "Fixed price per guest"
    case SettlementMethods.PER_VEHICLE:
      return "Tiered per-vehicle occupancy"
    case SettlementMethods.PER_VEHICLE_AVERAGE:
      return "Tiered based on average vehicle occupancy"
    default:
      return "Unknown type"
  }
}

const description = (settlementMethod: SettlementMethods) => {
  switch (settlementMethod) {
    case SettlementMethods.PER_GUEST:
      return "Settle using the agreed upon net rate per guest ticket collected. Tiered rates are calculated according to total tickets."
    case SettlementMethods.PER_VEHICLE:
      return "Settle using the agreed upon net rate per vehicle guest count. Vehicles are filled up one by one up to capacity. Tiered rates are calculated according to guests per vehicle."
    case SettlementMethods.PER_VEHICLE_AVERAGE:
      return "Settle using the agreed upon net rate per vehicle guest count. Guest counts are averaged across all vehicles. Tiered rates are calculated according to guests per vehicle."
    default:
      return "Unknown type"
  }
}

const Edit: FC = () => {
  const settlementMethod = useSelector(getSettlementMethod)
  const availableMethods = [
    SettlementMethods.PER_GUEST,
    SettlementMethods.PER_VEHICLE,
    SettlementMethods.PER_VEHICLE_AVERAGE,
  ]

  return (
    <FormikFieldSubmitter initialValues={{ [ReservedFields.SETTLEMENT_METHOD]: settlementMethod }}>
      <FormikBootstrap.Select name={ReservedFields.SETTLEMENT_METHOD}>
        {availableMethods.map((method) => (
          <option key={method} value={method}>
            {displayName(method)}
          </option>
        ))}
      </FormikBootstrap.Select>
      <FormikField name={ReservedFields.SETTLEMENT_METHOD}>
        {({ field: { value } }) => <Form.Text muted>{description(value)}</Form.Text>}
      </FormikField>
    </FormikFieldSubmitter>
  )
}

const SettlementMethod: FC = () => {
  const settlementMethod = useSelector(getSettlementMethod)
  const field = useSelector(getField(ReservedFields.SETTLEMENT_METHOD))
  const title = field.required ? "Settlement Method *" : "Settlement Method"

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Settlement Method" Body={Edit} />
  ))

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <div className="d-flex justify-content-between">
              <span>{title}</span>
              {field.writeable && <EditIcon onEdit={toggleModal} />}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{displayName(settlementMethod)}</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default SettlementMethod
