import React from "react"
import { Table } from "react-bootstrap"

import Tour from "@/dashboard/models/Tour"

import PrivateVillaRow from "./PrivateVillaRow"

const PrivateVillasTable = ({ tours }: { tours: Array<Tour> }): JSX.Element => (
  <Table responsive size="sm" className="align-items-center table-flush">
    <thead className="thead-light">
      <tr>
        <th scope="col">Villa</th>
        <th scope="col" className="text-right">
          Capactiy
        </th>
        <th scope="col" className="text-right">
          Guests{" "}
        </th>
        <th scope="col">Cut-off</th>
        <th scope="col" className="text-right">
          Retail{" "}
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      {tours.map((tour) => (
        <PrivateVillaRow tour={tour} key={tour.tourId} />
      ))}
    </tbody>
  </Table>
)

export default PrivateVillasTable
