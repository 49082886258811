import React from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"

import {
  dispatch_path,
  reverse_dispatch_path,
  private_villas_dispatch_path,
  operators_dispatch_path,
} from "@/routes"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { IfFeatureOn } from "@/helpers/Flipflop"

const DispatchLink = ({
  date,
  variant = "button",
}: {
  date: string
  variant: string
}): JSX.Element => {
  const renderItems = () => (
    <>
      <Dropdown.Item
        key="dispatch-pdf"
        href={dispatch_path({ date, format: "pdf", _options: true })}
      >
        Tours
      </Dropdown.Item>
      <IfFeatureOn key="dispatch-private-villas" feature="privateVillas">
        <Dropdown.Item href={private_villas_dispatch_path({ date, format: "pdf", _options: true })}>
          Private Villas
        </Dropdown.Item>
      </IfFeatureOn>
    </>
  )

  if (variant === "button") {
    return (
      <CruiseLineOnly>
        <DropdownButton id="dispatch-dropdown" title="Dispatch" className="float-end">
          {renderItems()}
        </DropdownButton>
      </CruiseLineOnly>
    )
  }

  if (variant === "dropdown") {
    return (
      <CruiseLineOnly>
        <Dropdown.Item
          key="dispatch-pdf"
          href={dispatch_path({ date, format: "pdf", _options: true })}
        >
          Tour Dispatch (Departures)
        </Dropdown.Item>
        <Dropdown.Item
          key="reverse-dispatch-pdf"
          href={reverse_dispatch_path({ date, format: "pdf", _options: true })}
        >
          Tour Dispatch (Arrivals)
        </Dropdown.Item>
        <Dropdown.Item
          key="operators-dispatch-pdf"
          href={operators_dispatch_path({ date, format: "pdf", _options: true })}
        >
          Tour Dispatch (Per Operator)
        </Dropdown.Item>
        <IfFeatureOn key="dispatch-private-villas" feature="privateVillas">
          <Dropdown.Item
            href={private_villas_dispatch_path({ date, format: "pdf", _options: true })}
          >
            Private Villa Dispatch
          </Dropdown.Item>
        </IfFeatureOn>
      </CruiseLineOnly>
    )
  }
  throw new Error(`Variant ${variant} not supported`)
}

export default DispatchLink
