import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import LoadingSpinner from "@/components/LoadingSpinner"
import Slice, { initialize } from "@/guests_room_accounts/Slice"
import { withApp } from "@/components/App"
import Navbar from "@/guests_footer/Navbar"
import { getRoomAccount, getID } from "@/guests_room_accounts/selectors"

import Header from "./Header"
import RoomChargesCard from "./RoomChargesCard"
import RoomGuestsCard from "./RoomGuestsCard"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getRoomAccount)
  const guestId = useSelector(getID)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <>
      <Header />
      <Navbar page="Account" guestId={guestId} />
      <RoomChargesCard />
      <RoomGuestsCard />
    </>
  )
}

export default withApp(Initializer, { slices: [Slice] })
