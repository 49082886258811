import React, { FC } from "react"
import { Row, Col } from "react-bootstrap"
import { useSelector } from "react-redux"

import ActionBar from "@/manual_booking/components/ActionBar"
import ContentVisibility from "@/components/ContentVisibility"
import { renderActionBarButtons } from "@/manual_booking/reservationSystems"
import { isHidden } from "@/helpers/formSpec"

import { getFields } from "./selectors"

const BookingActionBar: FC = () => {
  const fields = useSelector(getFields)

  return (
    <ActionBar>
      <ContentVisibility hidden={isHidden(fields, "actionBar")}>
        <Row>
          <Col className="d-flex gap-2">{renderActionBarButtons()}</Col>
        </Row>
      </ContentVisibility>
    </ActionBar>
  )
}

export default BookingActionBar
