import _ from "lodash"
import * as AsyncField from "@/helpers/AsyncField"
import {
  fetchVehicleClassOptions,
  fetchTransmissionOptions,
  findMatchingVehicles,
  fetchAirconOptions,
  fetchUnlimitedMileageOptions,
} from "./thunks"

import { initialize } from "./Slice"

const canLoadVehicleClassOptions = (form) =>
  form.pickupDateTime && AsyncField.isStale(form._vehicleClassOptions)

const canLoadTransmissionOptions = (form) =>
  form.pickupDateTime && AsyncField.isStale(form._transmissionOptions)

const canLoadMatchingVehicles = (form) =>
  AsyncField.isStale(form._matchingVehicles) && !form._selectedVehicle

const canLoadAirconOptions = (form) => AsyncField.isStale(form._matchingVehicles)

const canLoadUnlimitedMileageOptions = (form) => AsyncField.isStale(form._unlimitedMileageOptions)

const autorunBooking = (dispatch, form) => {
  // XXX Can be optimized further, currently queries API per field
  if (canLoadVehicleClassOptions(form)) {
    dispatch(fetchVehicleClassOptions({ formId: form.formId }))
  }

  if (canLoadTransmissionOptions(form)) {
    dispatch(fetchTransmissionOptions({ formId: form.formId }))
  }

  if (canLoadMatchingVehicles(form)) {
    dispatch(findMatchingVehicles({ formId: form.formId }))
  }

  if (canLoadAirconOptions(form)) {
    dispatch(fetchAirconOptions({ formId: form.formId }))
  }

  if (canLoadUnlimitedMileageOptions(form)) {
    dispatch(fetchUnlimitedMileageOptions({ formId: form.formId }))
  }
}

const autorun = async (props, dispatch, getState) => {
  const { transportRentalBookings } = getState()

  if (!transportRentalBookings.initialized) {
    dispatch(initialize(props))
  }

  _.each(transportRentalBookings.bookingForms, _.curry(autorunBooking)(dispatch))
}

export default autorun
