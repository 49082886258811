import React from "react"
import { Form } from "react-bootstrap"

import _ from "lodash"
import FloatableGroup from "./input/FloatableGroup"

import Feedback from "./input/Feedback"

import { warnInputPropsForwarding, omitDeprecatedInputProps } from "./input/deprecations"

const TextAreaInput = ({
  name,
  label,
  floating = false,
  formGroupOptions = {},
  validationMessage = "",
  validationStyling = null,
  type = undefined,
  className = undefined,
  showLockedIcon = false,
  showRequired = false,
  ...props
}): JSX.Element => {
  warnInputPropsForwarding(props)

  props = omitDeprecatedInputProps(props)

  if (validationStyling === "hidden") {
    props = _.omit(props, "isValid", "isInvalid")
  }

  return (
    <FloatableGroup
      label={label}
      floating={floating}
      {...formGroupOptions}
      className={className}
      showLockedIcon={showLockedIcon}
      showRequired={showRequired}
    >
      <Form.Control as="textarea" name={name} {...props} type={type} />
      <Feedback.Invalid message={validationMessage} />
    </FloatableGroup>
  )
}

export default TextAreaInput
