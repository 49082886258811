import React, { useState } from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import _ from "lodash"

import AuthenticityToken from "@/components/AuthenticityToken"
import { bulk_cancel_tour_bookings_path } from "@/routes"
import Tour from "@/dashboard/models/Tour"
import { ButtonVisiblityToggle } from "@/components/VisibilityToggles"

const BookingTableRow = ({ booking, isChecked, onToggle }) => (
  <tr>
    <td className="p-1 align-middle">
      <input type="hidden" name="bookings[][booking_id]" value={booking.bookingId} />
      <Form.Group>
        <Form.Check
          type="checkbox"
          name="bookings[][credit_room_account]"
          value="1"
          label={booking.reference}
          defaultChecked={isChecked}
          onChange={onToggle}
        />
      </Form.Group>
    </td>
  </tr>
)

const BookingTable = ({ bookings }) => {
  const [bookingChecks, setBookingChecked] = useState(
    _.fromPairs(_.map(bookings, (b) => [b.bookingId.toString(), true]))
  )

  const bookingRows = _.map(bookings, (b) => {
    const isChecked = bookingChecks[b.bookingId]

    return (
      <BookingTableRow
        key={b.bookingId}
        isChecked={isChecked}
        onToggle={() => {
          const newToggled = {}
          newToggled[b.bookingId] = !isChecked
          setBookingChecked(_.assign({}, bookingChecks, newToggled))
        }}
        booking={b}
      />
    )
  })

  return (
    <ButtonVisiblityToggle variant="secondary" showLabel="Show Bookings" hideLabel="Hide Bookings">
      <Table striped>
        <thead>
          <tr>
            <th> Credit Room Account? </th>
          </tr>
        </thead>
        <tbody>{bookingRows}</tbody>
      </Table>
    </ButtonVisiblityToggle>
  )
}

const BulkCancelBookingsModal = ({
  show,
  tour,
  onHide,
}: {
  show: boolean
  onHide: () => void
  tour: Tour
}): JSX.Element => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="cancel-booking-modal-title"
    centered
  >
    <Form action={bulk_cancel_tour_bookings_path()} method="post">
      <AuthenticityToken />
      <Modal.Header>
        <Modal.Title id="cancel-booking-modal-title">
          Bulk Cancel Bookings for {tour.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Control
            as="textarea"
            placeholder="Why do you wish to cancel these bookings?"
            rows={3}
            name="cancellation_reason"
            required
          />
        </Form.Group>
        <BookingTable bookings={tour.waitlistedBookings.concat(tour.confirmedBookings)} />
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="notify_guests"
            value="1"
            label="Send cancellation letter to guests?"
            defaultChecked
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button type="submit">Submit</Button>
      </Modal.Footer>
    </Form>
  </Modal>
)

export default BulkCancelBookingsModal
