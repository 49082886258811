import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"

import { FieldTypes } from "@/document_editor/models/Field"

const NewFieldModal = ({
  show,
  onHide,
  onSubmit,
}: {
  show: boolean
  onHide: () => void
  onSubmit: any
}): JSX.Element => {
  const [name, setName] = useState("")
  const [type, setType] = useState(FieldTypes.TEXT_FIELD)

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="new-field-modal-title" centered>
      <Modal.Header>
        <Modal.Title id="new-field-modal-title">Add Field</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Field Type</Form.Label>
          <Form.Select name="type" value={type} onChange={(e) => setType(e.target.value)} required>
            <option value={FieldTypes.TEXT_FIELD}>Short text field</option>
            <option value={FieldTypes.TEXT_AREA}>Long text area</option>
            <option value={FieldTypes.TEXT_LIST}>List of short text values</option>
            <option value={FieldTypes.NUMBER}>Numeric value</option>
            <option value={FieldTypes.TIME}>Time value</option>
            <option value={FieldTypes.DATETIME}>Date and time value</option>
            <option value={FieldTypes.IMAGE}>Image</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit(name, type, onHide)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewFieldModal
