import React from "react"
import { Form } from "react-bootstrap"

import FloatableGroup from "./FloatableGroup"
import Feedback from "./Feedback"
import { warnInputPropsForwarding, omitDeprecatedInputProps } from "./deprecations"

const Select = ({
  label = undefined,
  floating = false,
  formGroupOptions = {},
  validationMessage = "",
  children,
  ...props
}): JSX.Element => {
  warnInputPropsForwarding(props)
  const inputProps = omitDeprecatedInputProps(props)

  return (
    <FloatableGroup label={label} floating={floating} {...formGroupOptions}>
      <Form.Control as="select" {...inputProps}>
        {children}
      </Form.Control>
      <Feedback.Invalid message={validationMessage} />
    </FloatableGroup>
  )
}

export default Select
