import React from "react"
import { useSelector } from "react-redux"

import OperatorOnly from "@/auth_descriptor/OperatorOnly"

import { getTourTotals } from "@/dashboard/selectors"

const TourFooter = (): JSX.Element => {
  const totals = useSelector(getTourTotals)

  return (
    <thead>
      <tr>
        <th scope="row" colSpan={2}>
          Totals
        </th>

        <OperatorOnly>
          <th className="text-right" />
        </OperatorOnly>

        <th>{`${totals.minimumPax} / ${totals.maximumPax}`}</th>
        <th className="text-right">{`${totals.confirmedAdults} / ${totals.confirmedChildren} (${totals.confirmedGuests})`}</th>
        <th className="text-right">{`${totals.availableCount} (${totals.waitlistedGuests})`}</th>

        <th colSpan={4} />
      </tr>
    </thead>
  )
}

export default TourFooter
