import React, { FC } from "react"

import SectionHolder from "./shared/SectionHolder"
import Content from "./additional_details/Content"
import Edit from "./additional_details/Edit"

const AdditionalDetails: FC = () => (
  <SectionHolder
    title="Additional Details and Restrictions"
    editComponent={Edit}
    editMethod="inline"
  >
    <Content />
  </SectionHolder>
)

export default AdditionalDetails
