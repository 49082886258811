import Passenger from "./Passenger"
import Tour from "./Tour"

export enum ReservationStates {
  CONFIRMED = "confirmed",
  WAITLISTED = "waitlisted",
  CANCELLED = "cancelled",
}

export default interface Booking {
  bookingId: string
  reservationState: ReservationStates
  reference: string
  guests: number
  leadPassenger: Passenger
  otherPassengers: Array<Passenger>
  netPrice: string
  retailPrice: string
  // retail price if the booking was confirmed
  confirmedRetailPrice: string
  currency: string
  tour?: Tour
  conflictingBookings: Array<Booking>
  specialRequirements: string
  specialRequirementsFormatted: string
}
