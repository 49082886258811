import { createSlice } from "@reduxjs/toolkit"
import * as AsyncField from "@/helpers/AsyncField"
import { LoadState } from "@/helpers/AsyncField"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"
import Api from "@/api"
import _ from "lodash"
import { v4 as uuid } from "uuid"
import { isBlank } from "@/utils"
import { getInventory, getForm } from "./selectors"

export const filter = AsyncField.createLoadThunk(
  "inventoryAllocation/filter",
  (_action) => "_view",
  async (form, thunkAPI) => {
    const response = await Api.inventoryAllocation
      .filter(form)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const updateDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/updateDepartures",
  (_action) => "_view",
  async (payload, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const params = { departures: payload.departures, inventoryId, form }
    if (!isBlank(payload.reason)) {
      params.reason = payload.reason
    }
    const response = await Api.inventoryAllocation
      .updateDepartures(params)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const addDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/addDepartures",
  (_action) => "_view",
  async (departures, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .addDepartures({ departures, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const approveDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/approveDepartures",
  (_action) => "_view",
  async (dates, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .approveDepartures({ dates, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const addBulkDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/replaceDepartures",
  (_action) => "_view",
  async (bulkPayload, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .replaceDepartures({
        departures: bulkPayload.departures,
        dates: bulkPayload.dates,
        reason: bulkPayload.reason,
        inventoryId,
        form,
      })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const appendBulkDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/appendDepartures",
  (_action) => "_view",
  async (bulkPayload, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .appendDepartures({
        departures: bulkPayload.departures,
        dates: bulkPayload.dates,
        reason: bulkPayload.reason,
        inventoryId,
        form,
      })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const removeDepartures = AsyncField.createLoadThunk(
  "inventoryAllocation/removeDepartures",
  (_action) => "_view",
  async (departures, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .removeDepartures({ departures, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const updateAllotmentConstraints = AsyncField.createLoadThunk(
  "inventoryAllocation/updateAllotmentConstraints",
  (_action) => "_view",
  async (allotmentConstraints, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .updateAllotmentConstraints({ allotmentConstraints, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const lockDays = AsyncField.createLoadThunk(
  "inventoryAllocation/lockDays",
  (_action) => "_view",
  async (dates, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .lockDays({ dates, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const unlockDays = AsyncField.createLoadThunk(
  "inventoryAllocation/unlockDays",
  (_action) => "_view",
  async (dates, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .unlockDays({ dates, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const closeDays = AsyncField.createLoadThunk(
  "inventoryAllocation/closeDays",
  (_action) => "_view",
  async ({ dates, reason }, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .closeDays({
        dates,
        reason,
        inventoryId,
        form,
      })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

export const openDays = AsyncField.createLoadThunk(
  "inventoryAllocation/openDays",
  (_action) => "_view",
  async (dates, thunkAPI) => {
    const inventoryId = getInventory(thunkAPI.getState()).id
    const form = getForm(thunkAPI.getState())
    const response = await Api.inventoryAllocation
      .openDays({ dates, inventoryId, form })
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return {
      value: response.data.data.view,
    }
  }
)

const Slice = createSlice({
  name: "inventoryAllocation",
  initialState: {
    initialized: false,
    _view: {},
    isTableLoading: false,
    constraintsEditStates: {},
    rowEditStates: {},
    cruiseDeparturesEditState: {},
    dayExpansionStates: {},
    isMakingRequestStates: {},
    bulkExpansionStates: {},
    hasNoAwardedTours: true,
    departuresRequestStates: {},
    showBulkEditForm: false,
    bulkDepartures: [],
    bulkDepartureEdit: {},
    selectedProperties: {},
    displaySales: false,
  },
  reducers: {
    initialize: (state, { payload }) =>
      // console.log("inventory_allocation/Slice/initialize", payload),
      ({
        ...state,
        initialized: true,
        _view: AsyncField.createField({
          value: payload.view,
          defaultValue: payload.view,
          state: LoadState.Ready,
        }),
        constraintsEditStates: {},
        rowEditStates: {},
        cruiseDeparturesEditState: {},
        dayExpansionStates: {},
        getIsMakingRequestStates: {},
        bulkExpansionStates: {},
        hasNoAwardedTours: _.isEmpty(payload.view.form.tour.options),
        departuresRequestStates: {},
        showBulkEditForm: false,
        displaySales: false,
        bulkDepartures: [
          {
            departureTime: "",
            durationInMinutes: 0,
            maximumCapacity: 0,
            minimumCapacity: 0,
          },
        ],
        bulkDepartureEdit: {
          startTimeShiftMinutes: 0,
          departureTime: "",
          durationInMinutes: 0,
          maximumCapacity: 0,
          minimumCapacity: 0,
        },
        selectedProperties: {
          startTimeShiftMinutes: false,
          departureTime: false,
          durationInMinutes: false,
          maximumCapacity: false,
          minimumCapacity: false,
        },
      }),
    setTableLoading: (state, { payload }) => ({
      ...state,
      isTableLoading: payload.isTableLoading,
    }),
    setDisplaySales: (state, { payload }) => ({
      ...state,
      displaySales: payload.displaySales,
    }),
    setShowBulkEditForm: (state, { payload }) => ({
      ...state,
      showBulkEditForm: payload.showFormState,
    }),
    updateConstraintsEditStates: (state, { payload }) => ({
      ...state,
      constraintsEditStates: payload.editState,
    }),
    updateRowEditStates: (state, { payload }) => ({
      ...state,
      rowEditStates: payload.editState,
    }),
    updateDeparturesRequestStates: (state, { payload }) => ({
      ...state,
      departuresRequestStates: payload.requestState,
    }),
    updateCruiseDeparturesEditStates: (state, { payload }) => ({
      ...state,
      cruiseDeparturesEditState: payload.editState,
    }),
    updateDayExpansionStates: (state, { payload }) => ({
      ...state,
      dayExpansionStates: payload.expansionState,
    }),
    updateIsMakingRequestStates: (state, { payload }) => ({
      ...state,
      isMakingRequestStates: payload.isMakingRequestStates,
    }),
    updateBulkExpansionStates: (state, { payload }) => ({
      ...state,
      bulkExpansionStates: payload.expansionState,
    }),
    updateSelectedProperties: (state, { payload }) => ({
      ...state,
      selectedProperties: payload.selectedProperties,
    }),
    updateView: (state, { payload }) => ({
      ...state,
      _view: payload.view,
    }),
    onCancelConstraint: (state) => {
      const newState = _.cloneDeep(state)
      newState._view.value.inventory.callDayConstraints =
        newState._view.value.inventory.constraintsPreEdit
      return newState
    },
    onCancelEdit: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )
      newState._view.value.inventory.allocationTableData.callDays[dateIndex] =
        newState._view.value.inventory.callDaysPreEdit.callDays[dateIndex]
      return newState
    },
    onCancelBulkEdit: (state) => {
      const newState = _.cloneDeep(state)

      newState.bulkDepartures = [
        {
          departureTime: "",
          durationInMinutes: 0,
          maximumCapacity: 0,
          minimumCapacity: 0,
        },
      ]

      newState.bulkDepartureEdit = {
        startTimeShiftMinutes: 0,
        departureTime: "",
        durationInMinutes: 0,
        maximumCapacity: 0,
        minimumCapacity: 0,
      }

      newState.selectedProperties = {
        startTimeShiftMinutes: false,
        departureTime: false,
        durationInMinutes: false,
        maximumCapacity: false,
        minimumCapacity: false,
      }

      newState.bulkExpansionStates = {}

      return newState
    },
    onDateRangeChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const targetConstraints = payload.isNew
        ? newState._view.value.inventory.newCallDayConstraints
        : newState._view.value.inventory.callDayConstraints

      targetConstraints.forEach((constraint) => {
        if (constraint.allotmentConstraintId === payload.id) {
          constraint.dateSelectors[payload.index][payload.key] = payload.date
        }
      })

      return newState
    },
    onConstraintInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const targetConstraints = payload.isNew
        ? newState._view.value.inventory.newCallDayConstraints
        : newState._view.value.inventory.callDayConstraints

      targetConstraints.forEach((constraint) => {
        if (constraint.allotmentConstraintId === payload.id) {
          constraint[payload.key] = payload.input
        }
      })
      return newState
    },
    onBulkDepartureInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const targetDeparture = newState.bulkDepartures[payload.index]

      targetDeparture[payload.property] = payload.input

      return newState
    },
    onBulkEditInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const targetDeparture = newState.bulkDepartureEdit

      let inputValue

      if (payload.property === "departureTime") {
        inputValue = payload.input
      } else {
        const parsedInput = parseInt(payload.input)
        inputValue = isNaN(parsedInput) ? payload.input : parsedInput
      }

      targetDeparture[payload.property] = inputValue

      return newState
    },
    onDepartureInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      const targetDeparture =
        newState._view.value.inventory.allocationTableData.callDays[dateIndex].inventory
          .currentAllocatingTour.departures[payload.index]

      targetDeparture[payload.property] = payload.input

      return newState
    },
    onCruiseDepartureInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      const targetDeparture =
        newState._view.value.inventory.allocationTableData.callDays[dateIndex].inventory
          .currentAllocatingTour.constraints.departures[payload.index]

      targetDeparture[payload.property] = payload.input

      return newState
    },
    onConstraintRowInputChange: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      const targetConstraint =
        newState._view.value.inventory.allocationTableData.callDays[dateIndex].inventory
          .currentAllocatingTour.constraints.allotmentConstraint

      targetConstraint[payload.property] = payload.input

      return newState
    },
    addDraftBulkDeparture: (state) => {
      const newState = _.cloneDeep(state)

      const targetDepartures = newState.bulkDepartures

      let draftDeparture = {
        departureTime: "",
        durationInMinutes: 0,
        maximumCapacity: 0,
        minimumCapacity: 0,
        issues: [],
      }

      if (targetDepartures.length > 0) {
        const lastDeparture = _.cloneDeep(targetDepartures[targetDepartures.length - 1])
        delete lastDeparture.id
        draftDeparture = lastDeparture
      }

      targetDepartures.push(draftDeparture)

      return newState
    },
    addDraftDeparture: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      const targetDepartures =
        newState._view.value.inventory.allocationTableData.callDays[dateIndex].inventory
          .currentAllocatingTour.departures

      let draftDeparture = {
        departureDate: payload.date,
        departureTime: "",
        durationInMinutes: 0,
        maximumCapacity: 0,
        minimumCapacity: 0,
        issues: [],
      }

      if (targetDepartures.length > 0) {
        const lastDeparture = _.cloneDeep(targetDepartures[targetDepartures.length - 1])
        delete lastDeparture.id
        draftDeparture = { ...lastDeparture, departureDate: payload.date }
      }

      targetDepartures.push(draftDeparture)

      return newState
    },
    addDraftConstraint: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )
      const newConstraint = _.cloneDeep(newState._view.value.inventory.newCallDayConstraint)
      newConstraint.allotmentConstraintId = null

      newState._view.value.inventory.allocationTableData.callDays[
        dateIndex
      ].inventory.currentAllocatingTour.constraints.allotmentConstraint = newConstraint

      return newState
    },
    addDraftCruiseDeparture: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      const targetDepartures =
        newState._view.value.inventory.allocationTableData.callDays[dateIndex].inventory
          .currentAllocatingTour.constraints.departures
      let draftDeparture = {
        departureDate: payload.date,
        departureTime: "",
        durationInMinutes: 0,
        departureMinutes: 0,
        maximumCapacity: 0,
        minimumCapacity: 0,
        issues: [],
      }

      if (targetDepartures.length > 0) {
        const lastDeparture = _.cloneDeep(targetDepartures[targetDepartures.length - 1])
        delete lastDeparture.id
        draftDeparture = { ...lastDeparture, departureDate: payload.date }
      }

      targetDepartures.push(draftDeparture)
      return newState
    },
    removeDraftBulkDeparture: (state, { payload }) => {
      const newState = _.cloneDeep(state)

      newState.bulkDepartures = newState.bulkDepartures.filter((_, i) => i !== payload.index)

      return newState
    },
    removeDraftDeparture: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      newState._view.value.inventory.allocationTableData.callDays[
        dateIndex
      ].inventory.currentAllocatingTour.departures =
        newState._view.value.inventory.allocationTableData.callDays[
          dateIndex
        ].inventory.currentAllocatingTour.departures.filter((_, i) => i !== payload.index)

      return newState
    },
    removeCruiseDraftDeparture: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      const dateIndex = newState._view.value.inventory.allocationTableData.callDays.findIndex(
        (callDay) => callDay.date === payload.date
      )

      newState._view.value.inventory.allocationTableData.callDays[
        dateIndex
      ].inventory.currentAllocatingTour.constraints.departures =
        newState._view.value.inventory.allocationTableData.callDays[
          dateIndex
        ].inventory.currentAllocatingTour.constraints.departures.filter(
          (_, i) => i !== payload.index
        )

      return newState
    },
    onAddConstraint: (state) => {
      const newState = _.cloneDeep(state)
      const newConstraint = _.cloneDeep(newState._view.value.inventory.newCallDayConstraint)
      newConstraint.allotmentConstraintId = uuid()

      const currentConstraints = newState._view.value.inventory.callDayConstraints
      const newConstraints = newState._view.value.inventory.newCallDayConstraints
      const previousConstraints = newConstraints.length ? newConstraints : currentConstraints

      let newStartDate
      let newEndDate

      if (previousConstraints.length) {
        const previousConstraint = previousConstraints[previousConstraints.length - 1]
        const previousEndDate = previousConstraint.dateSelectors[0].endDate
          ? new Date(previousConstraint.dateSelectors[0].endDate)
          : new Date()

        newStartDate = new Date(previousEndDate)
        newStartDate.setDate(newStartDate.getDate() + 1)
      } else {
        newStartDate = new Date()
      }

      newEndDate = new Date(newStartDate)
      newEndDate.setFullYear(newStartDate.getFullYear() + 1)

      newConstraint.dateSelectors[0].startDate = newStartDate.toISOString().split("T")[0]
      newConstraint.dateSelectors[0].endDate = newEndDate.toISOString().split("T")[0]

      newState._view.value.inventory.newCallDayConstraints.push(newConstraint)
      return newState
    },
    onRemoveNewConstraint: (state, { payload }) => {
      const newState = _.cloneDeep(state)
      newState._view.value.inventory.newCallDayConstraints =
        newState._view.value.inventory.newCallDayConstraints.filter(
          (constraint) => constraint.allotmentConstraintId !== payload.id
        )
      return newState
    },
    onRemoveDateRange: (state, { payload }) => {
      const newState = _.cloneDeep(state)

      const targetConstraints = payload.isNew
        ? newState._view.value.inventory.newCallDayConstraints
        : newState._view.value.inventory.callDayConstraints

      targetConstraints.forEach((constraint) => {
        if (constraint.allotmentConstraintId === payload.id) {
          constraint.dateSelectors = constraint.dateSelectors.filter(
            (_, index) => index !== payload.index
          )
        }
      })

      return newState
    },
    onAddDateRange: (state, { payload }) => {
      const newState = _.cloneDeep(state)

      const targetConstraints = payload.isNew
        ? newState._view.value.inventory.newCallDayConstraints
        : newState._view.value.inventory.callDayConstraints

      targetConstraints.forEach((constraint) => {
        if (constraint.allotmentConstraintId === payload.id) {
          constraint.dateSelectors = [
            ...constraint.dateSelectors,
            _.cloneDeep(newState._view.value.inventory.newCallDayConstraint.dateSelectors[0]),
          ]
        }
      })

      return newState
    },
  },
  extraReducers: {
    ...filter.reducers,
    ...updateAllotmentConstraints.reducers,
    ...updateDepartures.reducers,
    ...addDepartures.reducers,
    ...approveDepartures.reducers,
    ...addBulkDepartures.reducers,
    ...removeDepartures.reducers,
    ...lockDays.reducers,
    ...unlockDays.reducers,
    ...closeDays.reducers,
    ...openDays.reducers,
    ...appendBulkDepartures.reducers,
  },
})

export const {
  initialize,
  setTableLoading,
  updateConstraintsEditStates,
  updateDayExpansionStates,
  updateIsMakingRequestStates,
  updateBulkExpansionStates,
  updateView,
  onConstraintInputChange,
  onDateRangeChange,
  onCancelConstraint,
  onAddConstraint,
  onRemoveNewConstraint,
  onRemoveDateRange,
  onAddDateRange,
  updateRowEditStates,
  onDepartureInputChange,
  onCancelEdit,
  addDraftDeparture,
  addDraftConstraint,
  removeDraftDeparture,
  onConstraintRowInputChange,
  updateCruiseDeparturesEditStates,
  addDraftCruiseDeparture,
  onCruiseDepartureInputChange,
  removeCruiseDraftDeparture,
  updateDeparturesRequestStates,
  setShowBulkEditForm,
  addDraftBulkDeparture,
  removeDraftBulkDeparture,
  onBulkDepartureInputChange,
  onBulkEditInputChange,
  onCancelBulkEdit,
  updateSelectedProperties,
  setDisplaySales,
} = Slice.actions

export default Slice
