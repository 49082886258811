import React from "react"
import { Form } from "react-bootstrap"
import { Formik } from "formik"
import FormikBootstrap from "@/components/formik-bootstrap"
import { useSelector } from "react-redux"
import routes from "@/routes"
import _ from "lodash"
import { getMinimumCoverageRequirement } from "@/insurance/features/helpers"
import { getProfile } from "../selectors"

const ViewUpload = ({ form, fileSrc }): JSX.Element => {
  const { _view } = useSelector(getProfile)
  const checkMinimum = !(form.status == "approved" || form.status == "expired")
  const minimumCoverageRequirement = getMinimumCoverageRequirement(
    form.categoriesForm.value,
    _view.value.profile.insuranceCategories
  )

  return (
    <Formik enableReinitialize initialValues={form}>
      {() => (
        <Form>
          <div className="container">
            <div className="row">
              <div className="col">
                <FormikBootstrap.TextInputViewOnly name="insurer" label="Insurer" readOnly />
                <div className="mt-2" />
                <FormikBootstrap.TextInputViewOnly name="brokerName" label="Broker Name" readOnly />
                <div className="mt-2" />
                <FormikBootstrap.TextInputViewOnly
                  name="policyNumber"
                  label="Policy Number"
                  readOnly
                />
                <div className="mt-2" />
                <FormikBootstrap.NumberInput
                  name="minimumCoverage"
                  label={`Minimum Coverage (${_view.value.profile.currency})`}
                  minimum={checkMinimum ? minimumCoverageRequirement : null}
                  readOnly
                />
                <div className="mt-2" />
                <FormikBootstrap.NumberInput
                  name="deductible"
                  label={`Deductible (${_view.value.profile.currency})`}
                  readOnly
                />
              </div>
              <div className="col">
                <FormikBootstrap.TextInputViewOnly
                  name="currencyCode"
                  label="Currency Code"
                  readOnly
                />
                <div className="mt-2" />
                <FormikBootstrap.DatePicker name="effectiveDate" label="Effective Date" readOnly />
                <div className="mt-2" />
                <FormikBootstrap.DatePicker
                  name="expirationDate"
                  label="Expiration Date"
                  readOnly
                />
                <div className="mt-2" />
                {_.isEmpty(_.trim(form.subcontractor)) ? (
                  <div />
                ) : (
                  <>
                    <FormikBootstrap.TextInput name="subcontractor" label="Subcontractor" />
                    <div className="mt-2" />
                  </>
                )}
                <FormikBootstrap.TextInputViewOnly
                  name="jurisdiction"
                  label="Jurisdiction"
                  readOnly
                />
                {form.minimumCoverage < minimumCoverageRequirement && checkMinimum ? (
                  <div className="mt-6" />
                ) : (
                  <div className="mt-5" />
                )}
                <a
                  className="hover-underline"
                  href={routes.file_holder_path({ id: fileSrc || "dummy" })}
                  download
                >
                  <span className="fa fa-file" />
                  <span> Download insurance certificate</span>
                  <p>
                    <b>Submitted by: </b> {form.user.firstName} {form.user.lastName}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ViewUpload
