import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuid } from "uuid"
import _ from "lodash"

export const Slice = createSlice({
  name: "notifications",
  initialState: {
    pending: [],
  },
  reducers: {
    notifyUser: (state, { payload }) => {
      const notification = {
        message: payload.message,
        type: payload.type,
        id: uuid(),
        ..._.pick(payload, ["duration"]),
      }

      state.pending = state.pending.concat(notification)

      return state
    },
    notifiedUser: (state, { payload }) => {
      state.pending = _.filter(state.pending, ({ id }) => id !== payload.id)

      return state
    },
  },
})

export const { notifyUser, notifiedUser } = Slice.actions

export default Slice
