import React, { FC } from "react"
import { useSelector } from "react-redux"

import { ReservedFields } from "@/onboarding/features/product/models/Product"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import { getFieldSettings } from "@/onboarding/features/product/selectors"

import SectionHolder from "./shared/SectionHolder"
import SettlementMethod from "./settlement/SettlementMethod"
import RatesDisplay from "./settlement/RatesDisplay"
import VehicleDisplay from "./settlement/VehicleDisplay"

// TODO(auth): We need to prevent this data from ever reaching the frontend instead of just
//  hiding it.
const Settlement: FC = () => {
  const { net_ratesheet } = useSelector(getFieldSettings(ReservedFields.NET_RATESHEET))
  const title = net_ratesheet.required ? "Buy Rate *" : "Buy Rate"

  return (
    <SectionHolder title="Settlement" showLockedIcon={!net_ratesheet.writeable}>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex justify-content-between gap-4">
          <SettlementMethod />
          <CruiseLineOnly>
            <RatesDisplay
              fieldName={ReservedFields.RACK_RATESHEET}
              rateLabel="Sell Rate"
              editable
            />
          </CruiseLineOnly>
          <RatesDisplay
            fieldName={ReservedFields.NET_RATESHEET}
            rateLabel={title}
            editable={net_ratesheet.writeable}
          />
        </div>
        <VehicleDisplay />
      </div>
    </SectionHolder>
  )
}
export default Settlement
