export const getStatus = (insuranceStatus) =>
  Object.keys(insuranceStatus).find((key) => insuranceStatus[key] === true)

export const decapitalizeFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1)

export const insertAnd = (string) => {
  const parts = string.split(", ")
  if (parts.length > 1) {
    const last = parts.pop()
    return `${parts.join(", ")} and ${last}`
  }
  return string
}

export const getMinimumCoverageRequirement = (selectedCategories, categories) => {
  let maxMinimumCoverage = 0
  categories.forEach((category) => {
    if (
      selectedCategories.includes(category.categoryName) &&
      category.minimumCoverage > maxMinimumCoverage
    ) {
      maxMinimumCoverage = category.minimumCoverage
    }
  })

  return maxMinimumCoverage
}
