import _ from "lodash"

import { EventTypes } from "./models/Event"

export const getOperatorCheckIn = ({ operatorCheckIn }) => operatorCheckIn

export const getGuestIds = ({ operatorCheckIn }) => _.map(operatorCheckIn.guests, "guestId")

export const getAcceptedIds = ({ operatorCheckIn }) => operatorCheckIn.acceptedIds

export const getLastEvent =
  (guestId) =>
  ({ operatorCheckIn }) =>
    _.findLast(operatorCheckIn.pendingEvents, (e) => e.guestId === guestId)

export const isInitialized = ({ operatorCheckIn }) => operatorCheckIn.initialized

export const isAccepted = (guestId) => (state) => {
  const lastEvent = getLastEvent(guestId)(state)

  if (lastEvent) {
    return lastEvent.type === EventTypes.ACCEPT
  }

  return _.includes(getAcceptedIds(state), guestId)
}
