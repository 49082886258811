import _ from "lodash"

// Notifies either from the error's response or
const notify = (
  dispatch,
  notifier,
  notificationType,
  error,
  defaultMessage = "Something went wrong"
) => {
  const message = _.get(error, "response.data.message", defaultMessage)

  dispatch(notifier[notificationType](message))
}

export const buildAxiosErrorHandler = (notifier) => (dispatch) => (error) => {
  if (error.response) {
    if (process.env.NODE_ENV == "development") {
      console.warn(error.response)
    }

    if (error.response.status == 400) {
      return notify(dispatch, notifier, "warning", error)
    }

    if (error.response.status == 403) {
      return notify(dispatch, notifier, "error", "You are not authorized to perform that action")
    }

    notify(dispatch, notifier, "error", error)
  } else {
    console.error(error)
  }
}
