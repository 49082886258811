import React from "react"
import { ListGroup } from "react-bootstrap"

import TourBooking from "@/search_results/models/TourBooking"
import { tour_booking_path } from "@/routes"

const TourBookingItem = ({ tourBooking }: { tourBooking: TourBooking }): JSX.Element => (
  <ListGroup.Item>
    <div className="d-flex justify-content-between">
      <h2 className="h4">
        <a className="hover-underline" href={tour_booking_path({ id: tourBooking.bookingId })}>
          {tourBooking.reference}
        </a>
      </h2>
    </div>
    <div className="d-flex justify-content-between">
      <h3 className="h5">{tourBooking.tourName}</h3>
      <small>{tourBooking.tourCode}</small>
    </div>
    <ListGroup variant="flush">
      <ListGroup.Item>
        <b>Lead name: </b>
        {tourBooking.chargedPassenger.name}
      </ListGroup.Item>
      <ListGroup.Item>
        <b>Lead phone number: </b>
        {tourBooking.chargedPassenger.phoneNumber}
      </ListGroup.Item>
      <ListGroup.Item>
        <b>Lead email: </b>
        {tourBooking.chargedPassenger.email}
      </ListGroup.Item>
      <ListGroup.Item>
        <b>Lead room number: </b>
        {tourBooking.chargedPassenger.roomNumber}
      </ListGroup.Item>
    </ListGroup>
  </ListGroup.Item>
)

const TourBookingListGroup = ({
  tourBookings,
}: {
  tourBookings: Array<TourBooking>
}): JSX.Element => (
  <ListGroup>
    {tourBookings.map((tourBooking) => (
      <TourBookingItem key={tourBooking.reference} tourBooking={tourBooking} />
    ))}
  </ListGroup>
)

export default TourBookingListGroup
