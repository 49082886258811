import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getProductField } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({ name, label }): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>{`${label}:`}</b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const HorizontalDisplayValue = ({ name, label }): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex gap-1">
      <span className="w-50">
        <b>{`${label}:`}</b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content: FC = () => (
  <div className="d-flex flex-column gap-4 px-4">
    <Row>
      <Col>
        <h3 className="h6">Tour Restrictions:</h3>
        <Row className="f-flex gap-4 px-4">
          <Col>
            <HorizontalDisplayValue name={ReservedFields.AGE_RESTRICTIONS} label="Age" />
          </Col>
          <Col>
            <HorizontalDisplayValue name={ReservedFields.HEIGHT_RESTRICTIONS} label="Height" />
          </Col>
          <Col>
            <HorizontalDisplayValue name={ReservedFields.WEIGHT_RESTRICTIONS} label="Weight" />
          </Col>
          <Col>
            <HorizontalDisplayValue
              name={ReservedFields.MEDICAL_CONDITION_RESTRICTIONS}
              label="Medical Conditions"
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="d-flex gap-4">
      <Col>
        <h3 className="h6">This tour provides:</h3>
        <Row className="ps-4">
          <HorizontalDisplayValue name={ReservedFields.INSTRUCTORS_PROVIDED} label="Instructors" />
          <HorizontalDisplayValue
            name={ReservedFields.CHANGING_FACILITIES_PROVIDED}
            label="Changing Facilities"
          />
          <HorizontalDisplayValue
            name={ReservedFields.STORAGE_LOCKERS_PROVIDED}
            label="Storage Lockers"
          />
          <HorizontalDisplayValue
            name={ReservedFields.TOWELS_PROVIDED}
            label="Towels For Water Activities"
          />
          <HorizontalDisplayValue
            name={ReservedFields.WAIVER_FORMS_REQUIRED}
            label="Waiver forms to be signed"
          />
        </Row>
      </Col>
      <Col>
        <h3 className="h6">This tour is suitable for people with:</h3>
        <Row className="ps-4">
          <HorizontalDisplayValue name={ReservedFields.WHEELCHAIR_SUITABLE} label="Wheelchairs" />
          <HorizontalDisplayValue name={ReservedFields.BLIND_SUITABLE} label="Blindness" />
          <HorizontalDisplayValue name={ReservedFields.DEAF_SUITABLE} label="Deafness" />
          <HorizontalDisplayValue
            name={ReservedFields.WALKING_DIFFICULTY_SUITABLE}
            label="Walking Difficulties"
          />
        </Row>
      </Col>
    </Row>
    <Row>
      <Col>
        <DisplayValue
          name={ReservedFields.INCLUDED_EQUIPMENT}
          label="What equipment is included on tour?"
        />
      </Col>
      <Col>
        <DisplayValue
          name={ReservedFields.GUEST_GUIDANCE}
          label="What should guests be advised to bring on tour?"
        />
      </Col>
    </Row>
  </div>
)

export default Content
