import React from "react"
import { Formik } from "formik"

import Pricing from "@/pricing"

const RatesheetEditorComponent = ({ name, value, defaultRateCategories, defaultTiers }) => {
  const formikName = "ratesheet_editor"

  return (
    <Formik initialValues={{ [formikName]: value }} onSubmit={() => {}}>
      {({ values }) => (
        <>
          <Pricing.Editor
            name={formikName}
            value={values[formikName]}
            readOnly={false}
            canEditPricingStructure
            defaultRateCategories={defaultRateCategories}
            defaultTiers={defaultTiers}
          />
          <input hidden name={name} value={JSON.stringify(values[formikName])} readOnly />
        </>
      )}
    </Formik>
  )
}

export default RatesheetEditorComponent
