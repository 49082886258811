import React from "react"
import { Card } from "react-bootstrap"

import OperatorOnly from "@/auth_descriptor/OperatorOnly"

import OperatorCheckInInfo from "./header/OperatorCheckInInfo"
import SubmitButton from "./header/SubmitButton"

const Header = (): JSX.Element => (
  <Card.Header>
    <div className="d-flex justify-content-between flex-wrap">
      <OperatorCheckInInfo />
      <OperatorOnly>
        <SubmitButton />
      </OperatorOnly>
    </div>
  </Card.Header>
)

export default Header
