import React from "react"
import { Button, Form, Modal } from "react-bootstrap"

import routes from "@/routes"
import getCSRFToken from "@/helpers/csrfToken"

const RemoveSectionModal = ({
  show,
  onHide,
  section,
}: {
  show: boolean
  onHide: () => void
  section: any
}): JSX.Element => (
  <Modal show={show} onHide={onHide} aria-labelledby="remove-field-modal-title" centered>
    <Form action={routes.section_path({ id: section.id })} method="post">
      <input name="_method" type="hidden" value="delete" />
      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />

      <Modal.Header>
        <Modal.Title id="remove-field-modal-title">Remove Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{`Are you sure you want to remove this ${section.name} section?`}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          Confirm
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>
)

export default RemoveSectionModal
