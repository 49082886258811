import React, { useState } from "react"

// TODO(guest-app): Check if the React Bootstrap Button classes can be used here
const StarRating = () => {
  const [rating, setRating] = useState(0)

  const starEmpty = (rate) => (
    <button className="btn btn-light shadow-none me-n3" onClick={() => setRating(rate)}>
      <h2 className="bi bi-star" />
    </button>
  )

  const starFilled = (rate) => (
    <button className="btn btn-light shadow-none me-n3" onClick={() => setRating(rate)}>
      <h2 className="bi bi-star-fill text-warning" />
    </button>
  )

  return (
    <div className="text-center ms-n3">
      {rating > 0 ? starFilled(1) : starEmpty(1)}

      {rating > 1 ? starFilled(2) : starEmpty(2)}

      {rating > 2 ? starFilled(3) : starEmpty(3)}

      {rating > 3 ? starFilled(4) : starEmpty(4)}

      {rating > 4 ? starFilled(5) : starEmpty(5)}
    </div>
  )
}

export default StarRating
