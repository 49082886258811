import React from "react"
import { Dropdown } from "react-bootstrap"
import _ from "lodash"

const NestedDropdown = ({ title, children }) => (
  <Dropdown drop="left" as={Dropdown.ItemText} className="py-2 w-100">
    <Dropdown.Toggle
      as={Dropdown.ItemText}
      role="button"
      className="p-0 w-100"
      id={`dropdown-${_.snakeCase(title)}`}
    >
      {title}
      <span className="d-inline float-end fas fa-chevron-right" />
    </Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
)

export default NestedDropdown
