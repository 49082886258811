import _ from "lodash"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import Api from "@/api"
import { newAxiosErrorHandler } from "@/osn"
import { formatMoney } from "@/helpers/money"

const getDailySettlement = ({ dailySettlement }) => dailySettlement

const getFilteredSettlementSheets = (state) => {
  const { settlements, currentOperator } = state.dailySettlement

  if (currentOperator === "All Operators") {
    return settlements
  }

  return _.filter(settlements, (s) => _.includes(s.operatorNames, currentOperator))
}

const getFilteredSettlementSheetIds = (state) => {
  const currentSettlements = getFilteredSettlementSheets(state)

  return _.map(currentSettlements, "settlementSheetId")
}

const getSettlement =
  (settlementSheetId) =>
  ({ dailySettlement }) =>
    dailySettlement.settlements.find((s) => s.settlementSheetId === settlementSheetId)

const getReceivedTicketDifference = (settlementSheetId) => (state) => {
  const settlement = getSettlement(settlementSheetId)(state)

  if (settlement.physicalTickets === null) {
    return null
  }

  return settlement.physicalTickets - settlement.guests
}

const getFilteredTotals = (state) => {
  const currentSettlements = getFilteredSettlementSheets(state)

  const amountTotal = _.sum(_.map(currentSettlements, (s) => parseFloat(s.totalAmount)))

  const physicalSettlements = _.filter(currentSettlements, (s) => s.physicalTickets !== null)
  const physicalTicketTotal = _.sum(_.map(physicalSettlements, "physicalTickets"))
  const ReceivedTicketDifferenceTotal = _.sum(
    _.map(physicalSettlements, (s) => s.physicalTickets - s.guests)
  )

  return {
    adultTotal: _.sum(_.map(currentSettlements, "adults")),
    childTotal: _.sum(_.map(currentSettlements, "children")),
    underTotal: _.sum(_.map(currentSettlements, "minimumUnder")),
    physicalTicketTotal,
    ReceivedTicketDifferenceTotal,
    priceTotal: formatMoney(amountTotal, state.dailySettlement.currency),
    finalizedTotal: _.filter(currentSettlements, "finalized").length,
    paidTotal: _.filter(currentSettlements, "paid").length,
  }
}

const getUnpaidSettlementSheets = (state) => {
  const currentSettlements = getFilteredSettlementSheets(state)

  return _.filter(currentSettlements, (s) => !s.paid)
}

const getPayableSettlementSheets = (state) => {
  const currentSettlements = getFilteredSettlementSheets(state)

  return _.filter(currentSettlements, (s) => (s.finalized || s.disputed) && !s.paid)
}

const getPayableSettlementSheetIds = (state) => {
  const unpaidSettlements = getPayableSettlementSheets(state)

  return _.map(unpaidSettlements, "settlementSheetId")
}

const markAsInvoiced = createAsyncThunk(
  "dailySettlement/markAsInvoiced",
  async ({ settlementSheetIds }, thunkAPI) => {
    const data = {
      dailySettlement: { settlementSheetIds },
    }

    // TODO This error handling is probably wrong here
    const response = await Api.dailySettlement
      .markAsInvoiced(data)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return { paidSettlementSheetIds: response.data.data.paidSettlementSheetIds }
  }
)

const submitReceivedTickets = createAsyncThunk(
  "dailySettlement/submitReceivedTickets",
  async ({ settlementSheetId, adults, children, comment, signature }, thunkAPI) => {
    const data = {
      dailySettlement: {
        settlementSheetId,
        adults,
        children,
        comment,
        signature,
      },
    }

    const response = await Api.dailySettlement
      .submitReceivedTickets(data)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    if (response === undefined) {
      return { settlementSheetId }
    }

    return response.data.data
  }
)

const mapAndFindSettlements = (state, settlementSheetId, fn) =>
  _.map(state.settlements, (settlement) => {
    if (settlement.settlementSheetId === settlementSheetId) {
      return fn(settlement)
    }
    return settlement
  })

const Slice = createSlice({
  name: "dailySettlement",
  initialState: {
    initialized: false,
    submitting: false,

    currency: "USD",
    totalAmount: 0.0,
    date: null,
    itinerary: null,
    portName: null,
    settlements: [],
    checkedIds: [],

    availableOperators: [],
    currentOperator: null,
  },
  reducers: {
    initialize: (state, { payload }) => {
      const availableOperators = _.concat(
        ["All Operators"],
        _.uniq(_.flatMap(payload.settlements, "operatorNames"))
      )

      const totalAmount = _.sum(_.map(payload.settlements, (s) => parseFloat(s.totalAmount)))

      return {
        ...state,
        ...payload,
        initialized: true,
        totalAmount,
        availableOperators,
        currentOperator: availableOperators[0],
      }
    },
    checkSettlement: (state, { payload }) => ({
      ...state,
      checkedIds: _.union(state.checkedIds, [payload.settlementId]),
    }),
    uncheckSettlement: (state, { payload }) => ({
      ...state,
      checkedIds: _.difference(state.checkedIds, [payload.settlementId]),
    }),
    checkAllSettlements: (state, _action) => ({
      ...state,
      checkedIds: getPayableSettlementSheetIds({ dailySettlement: state }),
    }),
    uncheckAllSettlements: (state, _action) => ({
      ...state,
      checkedIds: [],
    }),
    selectOperator: (state, { payload }) => ({
      ...state,
      currentOperator: payload.operator,
      checkedIds: [],
    }),
  },
  extraReducers: {
    [markAsInvoiced.pending]: (state, _action) => ({
      ...state,
      submitting: true,
    }),
    [markAsInvoiced.rejected]: (state, _action) => ({
      ...state,
      submitting: false,
    }),
    [markAsInvoiced.fulfilled]: (state, { payload }) => ({
      ...state,
      submitting: false,
      checkedIds: [],
      settlements: _.map(state.settlements, (settlement) => ({
        ...settlement,
        paid:
          settlement.paid ||
          _.includes(payload.paidSettlementSheetIds, settlement.settlementSheetId),
      })),
    }),
    [submitReceivedTickets.pending]: (state, action) => ({
      ...state,
      settlements: mapAndFindSettlements(
        state,
        action.meta.arg.settlementSheetId,
        (settlement) => ({
          ...settlement,
          submittingTicketCounts: true,
        })
      ),
    }),
    [submitReceivedTickets.rejected]: (state, action) => ({
      ...state,
      settlements: mapAndFindSettlements(
        state,
        action.meta.arg.settlementSheetId,
        (settlement) => ({
          ...settlement,
          submittingTicketCounts: false,
        })
      ),
    }),
    [submitReceivedTickets.fulfilled]: (state, { payload }) => ({
      ...state,
      settlements: mapAndFindSettlements(
        state,
        payload.settlement.settlementSheetId,
        (_settlement) => ({
          ...payload.settlement,
          submittingTicketCounts: false,
        })
      ),
    }),
  },
})

export const {
  initialize,
  checkSettlement,
  uncheckSettlement,
  checkAllSettlements,
  uncheckAllSettlements,
  selectOperator,
} = Slice.actions

export {
  getDailySettlement,
  getFilteredSettlementSheets,
  getFilteredSettlementSheetIds,
  getFilteredTotals,
  getUnpaidSettlementSheets,
  getPayableSettlementSheets,
  getPayableSettlementSheetIds,
  getSettlement,
  getReceivedTicketDifference,
}

export { markAsInvoiced, submitReceivedTickets }

export default Slice
