import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getTimeline } from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { isBlank } from "@/utils"

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  if (hours === 0) {
    return `${minutes}m`
  }

  if (minutes === 0) {
    return `${hours}h`
  }

  return `${hours}h${minutes}m`
}

const formatTimes = ({ startTime, endTime }): string => {
  if (isBlank(startTime) || isBlank(endTime)) {
    return ""
  }

  return `${startTime} - ${endTime}`
}

const StopRow = ({ stop }): JSX.Element => (
  <tr>
    <td>{formatTimes(stop)}</td>
    <td>{formatDuration(stop.duration)}</td>
    <td>
      <TextDisplay value={stop.name} />
    </td>
    <td>
      <TextDisplay value={stop.stopType} />
    </td>
    <td>
      <TextDisplay value={stop.transferType} />
    </td>
    <td>
      <TextDisplay value={stop.steps} />
    </td>
    <td>
      <TextDisplay value={stop.terrain} />
    </td>
    <td>
      <TextDisplay value={stop.bathroomsAvailable} />
    </td>
    <td>
      <div className="d-flex flex-column gap-1">
        {stop.components.map(({ name }, index) => (
          <span key={`${name}-${index}`}>
            <TextDisplay value={name} />
          </span>
        ))}
      </div>
    </td>
    <td>
      <TextDisplay value={stop.description} />
    </td>
  </tr>
)

const Timeline: FC = () => {
  const stops = useSelector(getTimeline)

  if (_.isEmpty(stops)) {
    return <p className="mb-0">No stops loaded</p>
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Time</th>
          <th>Duration</th>
          <th>Name</th>
          <th>Type</th>
          <th>Transfer Type</th>
          <th>Steps</th>
          <th>Terrain</th>
          <th>Bathrooms</th>
          <th>Components</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {stops.map((stop, index) => (
          <StopRow key={`${stop.name}-${index}`} stop={stop} />
        ))}
      </tbody>
    </Table>
  )
}

export default Timeline
