import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"

const AUTORUNS = []

const executeAutoruns = (props) => async (dispatch, getState) => {
  _.each(AUTORUNS, (f) => f(props, dispatch, getState))
}

const registerAutorun = (fn) => AUTORUNS.push(fn)

const Autorun = ({ children, ...props }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)

  // autoruns run on every state change!
  useEffect(() => {
    dispatch(executeAutoruns(props))
  }, [state])

  return children
}

export { registerAutorun }

export default Autorun
