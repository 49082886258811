import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import pluralize from "pluralize"

import { getPriceBreakdown, isEditing, isPriceReady } from "@/settlement/selectors"
import LoadingSpinner from "@/components/LoadingSpinner"

import PriceBreakdownLink from "./PriceBreakdownLink"

const Summary = (): JSX.Element => {
  const { settledPax, minimumUnder, minimumPax, price } = useSelector(getPriceBreakdown)

  const settledPaxDescriptions = _.transform(
    settledPax,
    (result, value, key) => result.push(pluralize(key, value, true)),
    []
  )

  const paxDescription = _.isEmpty(settledPaxDescriptions)
    ? "0 Confirmed Guests"
    : _.join(settledPaxDescriptions, ", ")

  return (
    <div className="d-flex gap-1">
      <span>{`${paxDescription}, ${minimumUnder} under (min ${minimumPax}) - ${price.formatted} total`}</span>
      <PriceBreakdownLink />
    </div>
  )
}

const SettlementTotalSummary = (): JSX.Element => {
  const editing = useSelector(isEditing)
  const ready = useSelector(isPriceReady)

  if (editing) {
    return <p className="mb-0 text-muted">Editing in progress</p>
  }

  if (!ready) {
    return <LoadingSpinner />
  }

  return <Summary />
}

export default SettlementTotalSummary
