import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { withApp } from "@/components/App"
import Slice, { initialize } from "@/guests/Slice"
import { getGuests, getID } from "@/guests/selectors"
import LoadingSpinner from "@/components/LoadingSpinner"
import Navbar from "@/guests_footer/Navbar"

import Header from "./Header"
import GuestsCard from "./GuestsCard"

// TODO: allow guest to switch between multiple voyages: if they are booked for more than one.
// TODO: add next assembly place/time (as notification)
// TODO: add other notifications via messenger
// TODO: add login - so that guest id is not just added manually into url
// TODO: do not display other side bar if a guest is logged in (only guest nav-bar)
// TODO: add guest logout option
const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()
  const { initialized } = useSelector(getGuests)
  const guestId = useSelector(getID)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <div>
      <Header />
      <GuestsCard />
      <Navbar page="Home" guestId={guestId} />
    </div>
  )
}

export default withApp(Initializer, { slices: [Slice] })
