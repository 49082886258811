import React from "react"

import { useDispatch } from "react-redux"

import { withApp } from "@/components/App"

import { Button, Container } from "react-bootstrap"

import Questionnaire from "@/form_toolkit/components/Questionnaire"

import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"

import Api from "@/api"

import { useFormikContext } from "formik"

const SubmitButton = () => {
  const { isSubmitting } = useFormikContext()

  return (
    <div className="d-flex gap-2">
      <Button type="submit" variant="success" disabled={isSubmitting}>
        Save
      </Button>
    </div>
  )
}

const App = ({ view }) => {
  const dispatch = useDispatch()

  return (
    <Container>
      <div className="align-self-center">
        <Questionnaire
          numbering={false}
          questionnaire={view.settings}
          onSubmit={async (values, { isSubmitting }) =>
            Api.operatorProfile
              .updateSettings(view.operatorProfileId, { settings: values })
              .then(newSuccessHandler(dispatch))
              .catch(newAxiosErrorHandler(dispatch))
          }
        >
          <SubmitButton />
        </Questionnaire>
      </div>
    </Container>
  )
}

export default withApp(App)
