import React from "react"
import FormikBootstrap from "@/components/formik-bootstrap"

import _ from "lodash"

const Field = (props): JSX.Element => {
  const { field, name } = props
  switch (field.kind) {
    case "text_area":
      return <FormikBootstrap.TextAreaInput className="mb-3" {...field} rows={6} name={name} />
    case "text":
      return <FormikBootstrap.TextInput className="mb-3" {...field} name={name} />
    case "select":
      return (
        <FormikBootstrap.Select className="mb-3" {...field} name={name}>
          {_.map(field.options, (o, idx) => (
            <option key={`${idx}-${o.value}`} value={o.value}>
              {o.label}
            </option>
          ))}
        </FormikBootstrap.Select>
      )
    case "password":
      return <FormikBootstrap.TextInput className="mb-3" {...field} name={name} type="password" />
    default:
      throw `Unsupported field type: ${field.kind}: ${JSON.stringify(props)}`
  }
}

export default Field
