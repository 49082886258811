import React from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { daily_settlement_path } from "@/routes"
import { formatMoney } from "@/helpers/money"
import { getDailySettlement, selectOperator } from "@/daily_settlement/Slice"

import MarkAsInvoicedButton from "./header/MarkAsInvoicedButton"

const Header = (): JSX.Element => {
  const dispatch = useDispatch()

  const { date, itinerary, portName, availableOperators, currentOperator, totalAmount, currency } =
    useSelector(getDailySettlement)

  return (
    <Card.Header>
      <div className="d-flex justify-content-between flex-wrap">
        <div>
          <h1 className="h4">
            {`Daily Settlement for ${date} - ${formatMoney(totalAmount, currency)} total`}
          </h1>
          {portName !== null && <h2 className="h5">{`${itinerary}, ${portName}`}</h2>}
          <Form.Group>
            <Form.Control
              as="select"
              value={currentOperator}
              onChange={(e) => dispatch(selectOperator({ operator: e.target.value }))}
            >
              {availableOperators.map((operator) => (
                <option key={operator}>{operator}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <div className="d-flex gap-1 flex-wrap">
            <MarkAsInvoicedButton />
            <Button href={daily_settlement_path({ date, format: "pdf", _options: true })}>
              Print
            </Button>
          </div>
        </div>
      </div>
    </Card.Header>
  )
}

export default Header
