import React from "react"

import { useDispatch } from "react-redux"
import VehicleSearchResults from "./VehicleSearchResults"

import VehicleResult from "./VehicleResult"

import { selectVehicle, clearSelectedVehicle } from "../Slice"

const VehicleSelection = ({ formData }) => {
  const dispatch = useDispatch()

  const renderSearchResults = () => (
    <div>
      <b> Search Results </b>
      <VehicleSearchResults
        field={formData._matchingVehicles}
        onReserve={(result) =>
          dispatch(selectVehicle({ vehicle: result, formId: formData.formId }))
        }
      />
    </div>
  )

  const renderSelectedVehicle = (vehicle) => {
    const dispatch = useDispatch()

    const changeButton = (
      <button
        className="btn btn-outline-secondary btn-sm"
        onClick={(e) => dispatch(clearSelectedVehicle({ formId: formData.formId }))}
      >
        Change
      </button>
    )

    return (
      <div>
        <b> Selected Vehicle </b>
        <VehicleResult result={vehicle} actionButton={changeButton} />
      </div>
    )
  }

  if (formData._selectedVehicle) {
    return renderSelectedVehicle(formData._selectedVehicle)
  }
  return renderSearchResults()
}

export default VehicleSelection
