import React from "react"
import { Button, Dropdown } from "react-bootstrap"

import { daily_manifest_path } from "@/routes"

const ManifestLink = ({
  date,
  variant = "button",
}: {
  date: string
  variant: string
}): JSX.Element => {
  if (variant === "button") {
    return (
      <Button className="float-end" href={daily_manifest_path({ date })}>
        Manifest
      </Button>
    )
  }

  if (variant === "dropdown") {
    return <Dropdown.Item href={daily_manifest_path({ date })}>Manifest</Dropdown.Item>
  }

  throw new Error(`Variant ${variant} not supported`)
}

export default ManifestLink
