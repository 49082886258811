import React from "react"
import { Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import { getSettlement } from "@/settlement/selectors"

const TicketAcceptedCheckBox = ({ guestId }): JSX.Element => {
  const { arrivedIds } = useSelector(getSettlement)

  const checked = _.includes(arrivedIds, guestId)

  return <Form.Check type="checkbox" readOnly checked={checked} />
}

export default TicketAcceptedCheckBox
