import React from "react"
import _ from "lodash"

const CompactPropTable = ({ rows }) => (
  <table>
    {_.map(rows, (row) => (
      <tr>
        {_.map(row, (c, key) => (
          <td key={key} style={{ paddingLeft: 0, paddingRight: 5 }}>
            {c}
          </td>
        ))}
      </tr>
    ))}
  </table>
)

export default CompactPropTable
