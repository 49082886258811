import React from "react"
import { Card } from "react-bootstrap"
import { useSelector } from "react-redux"
import LoadingSpinner from "@/components/LoadingSpinner"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { isViewLoading, getInventory } from "../selectors"

const HeaderTitle = () => {
  const inventory = useSelector(getInventory)

  return (
    <h1 className="h4 mb-0">
      Inventory Allocation
      {inventory && inventory.company && inventory.company.name && ` for ${inventory.company.name}`}
    </h1>
  )
}

const Header = (): JSX.Element => {
  const isLoading = useSelector(isViewLoading)
  return (
    <Card>
      <Card.Header>
        <div>
          <HeaderTitle />
          {isLoading && <LoadingSpinner className="align-self-center" />}
        </div>
        <Card.Body>
          <OperatorOnly>
            <span>
              Once a bid has been awarded, you can access this page to view the cruise line's
              capacity requirements and to allocate departures for the awarded tours.
            </span>
          </OperatorOnly>
          <CruiseLineOnly>
            <span>
              Once a bid has been awarded, you can access this page to allocate capacity
              requirements for the awarded tours and to view the tour operator's inventory
              allocation.
            </span>
          </CruiseLineOnly>
        </Card.Body>
      </Card.Header>
    </Card>
  )
}

export default Header
