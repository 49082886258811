import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getRegionSummary,
  getSelectedBiddingRegion,
} from "@/onboarding/features/price_analysis/selectors"
import { formatMoney } from "@/helpers/money"

const SummaryTable = (): JSX.Element => {
  const summary = useSelector(getRegionSummary)
  const biddingRegion = useSelector(getSelectedBiddingRegion)
  const regionName = biddingRegion?.name || ""

  const targetMet = summary.currentMargin >= summary.marginTarget

  return (
    <div>
      <h2 className="h5">{`Region Performance Summary: ${regionName}`}</h2>
      <Table className="text-right">
        <thead>
          <tr>
            <th scope="col" className="text-right">
              Expected Adults
            </th>
            <th scope="col" className="text-right">
              Expected Children
            </th>
            <th scope="col" className="text-right">
              Expected Total Guests
            </th>
            <th scope="col" className="text-right">
              Sales Target
            </th>
            <th scope="col" className="text-right">
              Projected Cost
            </th>
            <th scope="col" className="text-right">
              Margin Target
            </th>
            <th scope="col" className="text-right">
              Projected Margin
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{summary.expectedAdults}</td>
            <td>{summary.expectedChildren}</td>
            <td>{summary.expectedGuests}</td>
            <td>{formatMoney(summary.salesTarget, summary.currency)}</td>
            <td>{formatMoney(summary.currentCosts, summary.currency)}</td>
            <td>{formatMoney(summary.marginTarget, summary.currency)}</td>
            <td className={targetMet ? "text-success" : "text-danger"}>
              {formatMoney(summary.currentMargin, summary.currency)}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default SummaryTable
