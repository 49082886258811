import _ from "lodash"

export const nullField = (name: string) => ({
  type: "NullField",
  name,
})

export const DAY_OF_WEEK_OPTION_VALUES = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
]

export const DAY_OF_WEEK_OPTIONS = DAY_OF_WEEK_OPTION_VALUES.map((value) => ({
  value,
  label: _.startCase(value),
}))
