import React, { useState } from "react"
import { Card, CloseButton } from "react-bootstrap"

import ErrorBoundary from "@/components/ErrorBoundary"

import TourTable from "./tour_list_card/TourTable"

const LatestToursCard = ({ tours }) => {
  const [visibility, setVisibility] = useState(tours.length !== 0)

  if (!visibility) {
    return null
  }

  return (
    <ErrorBoundary>
      <Card border="light" className="shadow-sm mb-3">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="h4 mb-0">Latest Bookings</h1>
            <CloseButton onClick={() => setVisibility(false)} />
          </div>
        </Card.Header>
        <TourTable tours={tours} showDate showTotals={false} />
      </Card>
    </ErrorBoundary>
  )
}

export default LatestToursCard
