import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"
import {
  getProductField,
  getVehicles,
  isVehicleBasedSettlement,
} from "@/onboarding/features/product/selectors"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { useModal } from "@/helpers/useModal"
import EditModal from "@/onboarding/features/product/components/shared/EditModal"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"

import VehicleEditModal from "./VehicleEditModal"

const VehicleTable: FC = () => {
  const vehicles = useSelector(getVehicles)
  const { value } = useSelector(getProductField(ReservedFields.VEHICLES))

  const toggleModal = useModal((props) => (
    <EditModal {...props} title="Vehicles" Body={VehicleEditModal} />
  ))

  return (
    <Table>
      <thead>
        <tr>
          <th scope="col">Vehicle Name</th>
          <th scope="col" className="text-right">
            Quantity
          </th>
          <th scope="col" className="text-right">
            Max Capacity
          </th>
          <th scope="col">
            <div className="d-flex justify-content-end gap-2">
              <span>Allowed Capacity</span>
              {value.writeable && <EditIcon onEdit={toggleModal} />}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {_.isEmpty(vehicles) && (
          <tr>
            <td colSpan={4}>No vehicles loaded</td>
          </tr>
        )}

        {vehicles.map((vehicle, index) => (
          <tr key={`${vehicle.name}.${index}`}>
            <td>{vehicle.name}</td>
            <td className="text-right">{vehicle.quantity}</td>
            <td className="text-right">{vehicle.maxCapacity}</td>
            <td className="text-right">{vehicle.allowedCapacity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const VehicleDisplay: FC = () => {
  const usesVehicles = useSelector(isVehicleBasedSettlement)

  if (!usesVehicles) {
    return null
  }

  return <VehicleTable />
}

export default VehicleDisplay
