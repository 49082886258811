import React from "react"
import { useSelector } from "react-redux"
import { useField } from "formik"

import FormikBootstrap from "@/components/formik-bootstrap"
import { getOptionsForReferenceType } from "@/onboarding/features/product/selectors"

const Input = ({ name, type, label, isDisabled = false, showRequired = false }) => {
  const [field] = useField(name)

  // This is supposed to work both for the
  // - product page, and for the
  // - edit components page
  // quickest fix I could come up with
  const [_formikOptions, formikOptionsMeta] = useField("referenceValueOptions")

  const options = useSelector((state) => {
    if (state.product) {
      return getOptionsForReferenceType(type)(state)
    }
    if (formikOptionsMeta.initialValue) {
      return formikOptionsMeta.initialValue[type]
    }
    throw new Error(
      "This component requires either redux or formik to retrieve valid option values"
    )
  })

  const initialValue = field.value
  const onChangeFormatValue = (value) => ({
    ...initialValue,
    value,
  })

  if (initialValue.multiple) {
    return (
      <FormikBootstrap.MultiSelect
        name={name}
        label={label}
        options={options || []}
        onChangeFormatValue={onChangeFormatValue}
        showLockedIcon={isDisabled}
        showRequired={showRequired}
        disabled={isDisabled}
      />
    )
  }

  return (
    <FormikBootstrap.SingleSelect
      name={name}
      label={label}
      options={options}
      onChangeFormatValue={onChangeFormatValue}
      showLockedIcon={isDisabled}
      showRequired={showRequired}
      disabled={isDisabled}
    />
  )
}

export default Input
