import React from "react"
import { Form } from "react-bootstrap"

import { isPresent } from "@/utils"

const Checkbox = ({ name, label, helpText = "", checked, ...props }): JSX.Element => {
  const overriddenChecked = isPresent(checked) ? checked : props.value

  return (
    <Form.Group>
      <Form.Check
        type="checkbox"
        name={name}
        label={label}
        checked={overriddenChecked}
        {...props}
      />
      {isPresent(helpText) && <Form.Text muted>{helpText}</Form.Text>}
    </Form.Group>
  )
}

export default Checkbox
