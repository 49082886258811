import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const SELECT_ALL_OPERATORS = "All Tour Operators"
const SELECT_ALL_CAR_RENTAL_OPERATORS = "All Rental Operators"

const Slice = createSlice({
  name: "dashboard",
  initialState: {
    initialized: false,

    date: "",
    firstMeeting: "",
    voyage: {},
    tours: [],
    privateVillas: [],
    privateCarBookings: [],
    dailySale: {},

    availableOperators: [SELECT_ALL_OPERATORS],
    selectedOperator: SELECT_ALL_OPERATORS,

    availableCarRentalOperators: [SELECT_ALL_CAR_RENTAL_OPERATORS],
    selectedCarRentalOperator: SELECT_ALL_CAR_RENTAL_OPERATORS,
  },
  reducers: {
    initialize: (state, { payload }) => {
      const availableOperators = _.sortBy(_.uniq(_.flatten(_.map(payload.tours, "operators"))))
      const availableCarRentalOperators = _.sortBy(
        _.uniq(_.flatten(_.map(payload.privateCarBookings, "rentalCompanyName")))
      )

      return {
        ...state,
        initialized: true,
        ...payload,
        availableOperators: _.concat(state.availableOperators, availableOperators),
        availableCarRentalOperators: _.concat(
          state.availableCarRentalOperators,
          availableCarRentalOperators
        ),
      }
    },
    selectOperator: (state, { payload }) => ({
      ...state,
      selectedOperator: payload.operator,
    }),
    selectCarRentalOperator: (state, { payload }) => ({
      ...state,
      selectedCarRentalOperator: payload.operator,
    }),
  },
})

export const { initialize, selectOperator, selectCarRentalOperator } = Slice.actions

export default Slice
