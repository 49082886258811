import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { withApp } from "@/components/App"
import LoadingSpinner from "@/components/LoadingSpinner"
import { getTours, getPassengerID } from "@/guests_tours/selectors"
import Slice, { initialize } from "@/guests_tours/Slice"
import Navbar from "@/guests_footer/Navbar"

import ToursCard from "./ToursCard"

const Initializer = (props): JSX.Element => {
  const dispatch = useDispatch()

  const { initialized } = useSelector(getTours)
  const passengerID = useSelector(getPassengerID)

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize(props))
    }
  }, [initialized])

  if (!initialized) {
    return <LoadingSpinner animation="border" />
  }

  return (
    <>
      <h2 className="mb-n1">Booked Excursions</h2>
      <Navbar page="Excursions" guestId={passengerID} />
      <ToursCard />
    </>
  )
}

export default withApp(Initializer, { slices: [Slice] })
