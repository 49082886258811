import _ from "lodash"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import Api from "@/api"
import OnScreenNotifications, { newAxiosErrorHandler } from "@/osn"

import { getOperatorCheckIn } from "@/operator_check_in/selectors"
import { EventTypes } from "./models/Event"

const submitEvents = createAsyncThunk(
  "operatorCheckIn/submitEvents",
  async ({ events }, thunkAPI) => {
    const { tourId } = getOperatorCheckIn(thunkAPI.getState()).tour

    const response = await Api.operatorCheckIn
      .submitEvents(tourId, { events })
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return { events: response.data.data.events }
  }
)

const submitTicketScan = createAsyncThunk(
  "operatorCheckIn/submitTicketScan",
  async ({ scan }, thunkAPI) => {
    const { tourId } = getOperatorCheckIn(thunkAPI.getState()).tour
    const response = await Api.operatorCheckIn
      .submitTicketScan(tourId, { scan })
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    const { passenger } = response.data.data
    if (getOperatorCheckIn(thunkAPI.getState()).acceptedIds.includes(passenger)) {
      thunkAPI.dispatch(OnScreenNotifications.warning("Ticket already scanned"))
    }
    return { value: passenger }
  }
)

const Slice = createSlice({
  name: "operatorCheckIn",
  initialState: {
    initialized: false,
    submitting: false,
    tour: {},
    guests: [],
    acceptedIds: [],
    pendingEvents: [],
    ticketScan: "",
  },
  reducers: {
    initializeSheet: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
    acceptGuest: (state, { payload }) => ({
      ...state,
      pendingEvents: _.concat(state.pendingEvents, { type: "accept", guestId: payload.guestId }),
    }),
    rejectGuest: (state, { payload }) => ({
      ...state,
      pendingEvents: _.concat(state.pendingEvents, { type: "reject", guestId: payload.guestId }),
    }),
    setTicketScan: (state, { payload }) => ({
      ...state,
      ticketScan: payload.ticketScan,
    }),
  },
  extraReducers: {
    [submitEvents.pending]: (state, _action) => ({
      ...state,
      submitting: true,
    }),
    [submitEvents.rejected]: (state, _action) => ({
      ...state,
      submitting: false,
    }),
    [submitEvents.fulfilled]: (state, { payload }) => {
      let { acceptedIds } = state
      let { pendingEvents } = state

      _.each(payload.events, (event: Event) => {
        if (event.type === EventTypes.ACCEPT) {
          acceptedIds = _.union(acceptedIds, [event.guestId])
        } else if (event.type === EventTypes.REJECT) {
          acceptedIds = _.difference(acceptedIds, [event.guestId])
        }

        pendingEvents = _.difference(pendingEvents, [event])
      })

      return {
        ...state,
        submitting: false,
        acceptedIds,
        pendingEvents,
      }
    },
    [submitTicketScan.pending]: (state, _action) => ({
      ...state,
      submitting: true,
    }),
    [submitTicketScan.rejected]: (state, _action) => ({
      ...state,
      submitting: false,
    }),
    [submitTicketScan.fulfilled]: (state, { payload }) => {
      const acceptedIds = _.union(state.acceptedIds, [payload.value])
      return {
        ...state,
        submitting: false,
        acceptedIds,
        ticketScan: "",
      }
    },
  },
})

export const { initializeSheet, acceptGuest, rejectGuest, setTicketScan } = Slice.actions

export { submitEvents, submitTicketScan }

export default Slice
