import _ from "lodash"

export const getPriceAnalysis = ({ priceAnalysis }) => priceAnalysis

export const getPriceView = (state) => getPriceAnalysis(state).selectedView

export const isShowBaseline = (state) => getPriceAnalysis(state).showBaseline

export const isGroupByBrand = (state) => getPriceAnalysis(state).groupByBrand

export const cruiseBrandExpanded = (cruiseBrandId) => (state) =>
  getPriceAnalysis(state).expandedCruiseBrandId === cruiseBrandId

export const portExpanded = (portId) => (state) => getPriceAnalysis(state).expandedPortId === portId

export const getSeason = (state) => getPriceAnalysis(state)._season.value

export const getBiddingRegions = (state) => getSeason(state).biddingRegions

export const getCruiseBrands = (state) => getSeason(state).cruiseBrands

export const getBiddingRegion = (id) => (state) =>
  _.find(getBiddingRegions(state), (region) => region.id === id)

export const getSelectedBiddingRegion = (state) =>
  getBiddingRegion(getPriceAnalysis(state).selectedBiddingRegionId)(state)

export const getProspectiveAwardSets = (state) => {
  const biddingRegion = getSelectedBiddingRegion(state)

  if (!biddingRegion) {
    return []
  }

  return biddingRegion.prospectiveAwardSets
}

export const getCruiseBrand = (id) => (state) =>
  _.find(getCruiseBrands(state), (region) => region.id === id)

export const getSelectedCruiseBrand = (state) =>
  getCruiseBrand(getPriceAnalysis(state).selectedCruiseBrandId)(state)

export const getProspectiveAwardSet = (id) => (state) =>
  _.find(getProspectiveAwardSets(state), (set) => set.id === id)

export const getSelectedProspectiveAwardSet = (state) =>
  getProspectiveAwardSet(getPriceAnalysis(state).selectedAwardSetId)(state)

export const getRegionSummary = (state) => {
  const { ports, summary } = getSelectedBiddingRegion(state)
  const awardSet = getSelectedProspectiveAwardSet(state)

  const totalCost = awardSet
    ? _.reduce(
        ports,
        (sum, port) => sum + getPortAwardSetTotal(port.id)(awardSet.id)(state).amount,
        0
      )
    : 0

  return {
    ...summary,
    currentCosts: totalCost,
    currentMargin: summary.salesTarget - totalCost,
  }
}

export const getProductSummary = (state) => {
  const awardSet = getSelectedProspectiveAwardSet(state)

  if (_.isNil(awardSet)) {
    return null
  }

  const { id, dailySummary, seasonSummary } = getSelectedProduct(state)
  const totalCost = getProductAwardSetTotal(id)(awardSet.id)(state).amount

  // TODO Calculate the season product cost in the backend.
  // For now this quick hack just assumes that the season total scales linearly.
  const seasonScale =
    dailySummary.salesTarget === 0 ? 0 : seasonSummary.salesTarget / dailySummary.salesTarget

  return {
    daily: {
      ...dailySummary,
      currentCosts: totalCost,
      currentMargin: dailySummary.salesTarget - totalCost,
    },
    season: {
      ...seasonSummary,
      currentCosts: totalCost * seasonScale,
      currentMargin: seasonSummary.salesTarget - totalCost * seasonScale,
    },
  }
}

export const getSelectedBiddingRegionOperators = (state) => {
  const biddingRegion = getSelectedBiddingRegion(state)

  return _(biddingRegion.ports)
    .flatMap("products")
    .flatMap("bids")
    .map("operator")
    .compact()
    .uniqBy("id")
    .sortBy("name")
    .value()
}

export const getPort = (portId) => (state) => {
  const biddingRegion = getSelectedBiddingRegion(state)

  return _.find(biddingRegion.ports, (port) => port.id === portId)
}

export const getPortProducts = (portId) => (state) => {
  const port = getPort(portId)(state)

  if (!port) {
    return []
  }

  return port.products
}

export const getCruiseBrandProducts = (cruiseBrandId) => (state) => {
  const productsInBiddingRegion = getAllBiddingRegionProducts(state)

  if (!productsInBiddingRegion) {
    return []
  }

  return productsInBiddingRegion.filter((product) =>
    product.cruiseBrands.some((brand) => brand.id === cruiseBrandId)
  )
}

export const getAllBiddingRegionProducts = (state) => {
  const biddingRegion = getSelectedBiddingRegion(state)

  if (!biddingRegion || !Array.isArray(biddingRegion.ports)) {
    return []
  }

  return _.flatMap(biddingRegion.ports, "products") || []
}

export const getProduct = (productId) => (state) =>
  _.find(getAllBiddingRegionProducts(state), (product) => product.id === productId)

export const getProductRequestedTotal = (productId) => (state) => {
  const { buyTotal, priceComponents, currency } = getProduct(productId)(state)

  const base = {
    expectedTotal: _.toNumber(buyTotal),
    priceBreakdown: { adults: 0, children: 0 },
    currency,
  }

  return _.reduce(
    priceComponents,
    (memo, component) => ({
      ...memo,
      // expectedTotal: memo.expectedTotal + _.toNumber(component.indicativeExpectedTotal),
      priceBreakdown: {
        adults: memo.priceBreakdown.adults + _.toNumber(component.indicativeBreakdown.adults),
        children: memo.priceBreakdown.children + _.toNumber(component.indicativeBreakdown.children),
      },
    }),
    base
  )
}

export const getExpandedPort = (state) => getPort(getPriceAnalysis(state).expandedPortId)(state)

export const getSelectedProduct = (state) =>
  getProduct(getPriceAnalysis(state).selectedProductId)(state)

export const getProductOperatorTotal = (productId) => (operatorId) => (state) => {
  const { bids, currency } = getProduct(productId)(state)
  const bid = _.find(bids, ({ operator }) => operator.id === operatorId)

  const base = {
    expectedTotal: 0,
    expectedTotalVariance: 0,
    priceBreakdown: { adults: 0, children: 0 },
    priceBreakdownVariance: { adults: 0, children: 0 },
    currency,
  }

  if (!bid) {
    return base
  }

  // TODO Check whether or not we still want this total here
  base.expectedTotal = _.toNumber(bid.buyTotal)
  base.expectedTotalVariance = _.toNumber(bid.buyTotalVariance)

  return _.reduce(
    bid.priceComponentOffers,
    (memo, component) => ({
      ...memo,
      // expectedTotal: memo.expectedTotal + _.toNumber(component.expectedTotal),
      priceBreakdown: {
        adults: memo.priceBreakdown.adults + _.toNumber(component.priceBreakdown.adults),
        children: memo.priceBreakdown.children + _.toNumber(component.priceBreakdown.children),
      },
      priceBreakdownVariance: {
        adults:
          memo.priceBreakdownVariance.adults + _.toNumber(component.priceBreakdownVariance.adults),
        children:
          memo.priceBreakdownVariance.children +
          _.toNumber(component.priceBreakdownVariance.children),
      },
    }),
    base
  )
}

export const getPortOperatorTotal = (portId) => (operatorId) => (state) => {
  const port = getPort(portId)(state)

  const amount = _(port.products)
    .map(
      (product) => getProductOperatorTotal(product.id)(operatorId)(state).expectedTotal

      // TODO Check whether or not to use old code summing price components
      // I could use the selector from above here I suppose, but that would be more inefficient
      // const bid = _.find(product.bids, (bid) => bid.operator.id === operatorId)

      // if (!bid) {
      //   return 0
      // }

      // return _(bid.priceComponentOffers)
      //   .map((offer) => _.toNumber(offer.expectedTotal))
      //   .sum()
    )
    .sum()

  return { amount, currency: _(port.products).map("currency").head() || "USD" }
}

export const getFilteredPortOperatorTotal = (filteredPortProducts) => (operatorId) => (state) => {
  const amount = _(filteredPortProducts)
    .map((product) => getProductOperatorTotal(product.id)(operatorId)(state).expectedTotal)
    .sum()

  return { amount, currency: _(filteredPortProducts).map("currency").head() || "USD" }
}

export const getOperatorTotalForProducts = (products) => (operatorId) => (state) => {
  const amount = _(products)
    .map((product) => getProductOperatorTotal(product.id)(operatorId)(state).expectedTotal)
    .sum()

  return { amount, currency: _(products).map("currency").head() || "USD" }
}

export const getPortAwardSetTotal = (portId) => (awardSetId) => (state) => {
  const { products } = getPort(portId)(state)

  return _.reduce(
    products,
    (memo, product) => {
      const awardSetTotal = getProductAwardSetTotal(product.id)(awardSetId)(state)

      return {
        amount: memo.amount + awardSetTotal.amount,
        currency: awardSetTotal.currency,
      }
    },
    { amount: 0, currency: "USD" }
  )
}

export const getFilteredPortAwardSetTotal = (filteredPortProducts) => (awardSetId) => (state) =>
  _.reduce(
    filteredPortProducts,
    (memo, product) => {
      const awardSetTotal = getProductAwardSetTotal(product.id)(awardSetId)(state)

      return {
        amount: memo.amount + awardSetTotal.amount,
        currency: awardSetTotal.currency,
      }
    },
    { amount: 0, currency: "USD" }
  )

export const getFilteredCruiseBrandAwardSetTotal =
  (filteredBrandProducts) => (awardSetId) => (state) =>
    _.reduce(
      filteredBrandProducts,
      (memo, product) => {
        const awardSetTotal = getProductAwardSetTotal(product.id)(awardSetId)(state)

        return {
          amount: memo.amount + awardSetTotal.amount,
          currency: awardSetTotal.currency,
        }
      },
      { amount: 0, currency: "USD" }
    )

export const getProductAwardSetTotal = (productId) => (awardSetId) => (state) => {
  const { productOperatorAwards } = getProspectiveAwardSet(awardSetId)(state)
  const { expectedTotal, currency } = getProductOperatorTotal(productId)(
    productOperatorAwards[productId]
  )(state)

  return { amount: expectedTotal, currency }
}

export const getComponentBidOffer = (componentId) => (operatorId) => (state) => {
  const { bids, currency } = getSelectedProduct(state)
  const bid = _.find(bids, ({ operator }) => operator.id === operatorId)

  const componentOffer = _.find(
    bid.priceComponentOffers,
    ({ priceComponentId }) => priceComponentId === componentId
  )

  if (!componentOffer) {
    return { expectedTotal: 0, priceBreakdown: { adults: 0, children: 0 }, currency }
  }

  return _.merge({ currency }, componentOffer)
}

export const getPortExpandedProductAwards = (portId) => (state) => {
  const productIds = _.map(getPortProducts(portId)(state), "id")
  const { productOperatorAwards } = getSelectedProspectiveAwardSet(state)

  return _.pickBy(productOperatorAwards, (_operatorId, productId) =>
    _.includes(productIds, productId)
  )
}

export const getCruiseBrandExpandedProductAwards = (cruiseBrandId) => (state) => {
  const productIds = _.map(getCruiseBrandProducts(cruiseBrandId)(state), "id")
  const { productOperatorAwards } = getSelectedProspectiveAwardSet(state)

  return _.pickBy(productOperatorAwards, (_operatorId, productId) =>
    _.includes(productIds, productId)
  )
}

export const getProductAwardedOperator = (productId) => (state) => {
  const { productOperatorAwards } = getSelectedProspectiveAwardSet(state)

  return productOperatorAwards[productId]
}

export const getSelectedAwardOperatorSummary = (state) => {
  const { productOperatorAwards } = getSelectedProspectiveAwardSet(state)

  const products = getAllBiddingRegionProducts(state)
  const operators = _.reduce(
    getSelectedBiddingRegionOperators(state),
    (memo, operator) => _.merge(memo, { [operator.id]: operator }),
    {}
  )

  const operatorProducts = _.reduce(
    products,
    (memo, product) => {
      if (!_(productOperatorAwards).keys().includes(product.id)) {
        return memo
      }

      const operatorId = productOperatorAwards[product.id]

      if (memo[operatorId]) {
        memo[operatorId] = _.concat(memo[operatorId], product.name)
      } else {
        memo[operatorId] = [product.name]
      }

      return memo
    },
    {}
  )

  return _.reduce(
    operatorProducts,
    (memo, products, operatorId) => _.concat(memo, { name: operators[operatorId].name, products }),
    []
  )
}

export const getOperatorBidIdMap = (productId) => (state) => {
  const { bids } = getProduct(productId)(state)

  return _(bids)
    .groupBy("operator.id")
    .mapValues((bids) => _.head(_.map(bids, "id")))
    .value()
}
