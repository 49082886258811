import React from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import _ from "lodash"

import {
  getOperatorBidIdMap,
  getProductAwardedOperator,
  getSelectedProduct,
  isShowBaseline,
} from "@/onboarding/features/price_analysis/selectors"
import ErrorBoundary from "@/components/ErrorBoundary"

import ComponentRequestedValue from "./component_table/ComponentRequestedValue"
import ComponentBidValue from "./component_table/ComponentBidValue"
import BidTotalValue from "./component_table/BidTotalValue"
import OperatorSelector from "./component_table/OperatorSelector"
import BaselineSelector from "./component_table/BaselineSelector"
import RequestedTotalValue from "./component_table/RequestedTotalValue"

const ComponentTable = (): JSX.Element => {
  const product = useSelector(getSelectedProduct)
  const awardedOperatorId = useSelector(getProductAwardedOperator(product.id))
  const operatorBidIds = useSelector(getOperatorBidIdMap(product.id))

  const operators = _(product.bids).map("operator").sortBy("name").value()

  const operatorSelected = (operatorId) => awardedOperatorId === operatorId
  const cellClass = (operatorId, options = []) =>
    _.join(
      _.concat(
        operatorSelected(operatorId) ? ["text-right", "bg-secondary"] : ["text-right"],
        options
      ),
      " "
    )
  const cellStyle = (index) => ({ backgroundColor: index % 2 === 0 ? "Gainsboro" : "" })

  const groupedComponents = _.groupBy(product.priceComponents, "category")
  const showBaseline = useSelector(isShowBaseline)

  const calculateColSpan = () => (showBaseline ? operators.length + 2 : operators.length + 1)

  return (
    <div>
      <h2 className="h5 mb-0">Bid Cost Comparison</h2>
      {product.previousSeason && <BaselineSelector />}
      <ErrorBoundary>
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">Stops and Components Per Call</th>
              {showBaseline && (
                <th
                  scope="col"
                  className="text-left underline"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`The baseline shows the values from the previous season: \n ${product.previousSeason.name}`}
                >
                  Baseline
                </th>
              )}
              {operators.map((operator) => (
                <th key={operator.id} scope="col">
                  <div className="d-flex justify-content-end">
                    <OperatorSelector product={product} operator={operator} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(product.comparisonFields, ({ indicativeField, bidFields }) => (
              <React.Fragment key={indicativeField.fieldName}>
                <tr>
                  <th scope="row">{_.startCase(indicativeField.fieldName)}</th>
                  {showBaseline && <td className="text-wrap">{indicativeField.value}</td>}
                  {operators.map(({ id }, index) => (
                    <td key={id} className={cellClass(id, ["text-wrap"])} style={cellStyle(index)}>
                      {_.get(
                        _.find(bidFields, (bidField) => bidField.bidId === operatorBidIds[id]),
                        "field.value",
                        ""
                      )}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
            {_.isEmpty(groupedComponents) && (
              <tr>
                <td colSpan={calculateColSpan()}>
                  No components have been loaded yet for this tour.
                </td>
              </tr>
            )}
            {_.map(groupedComponents, (components, category) => (
              <React.Fragment key={category}>
                <tr>
                  <th scope="row">{category}</th>
                  {showBaseline && <td />}
                  {operators.map(({ id }, index) => (
                    <td key={id} className={cellClass(id)} style={cellStyle(index)} />
                  ))}
                </tr>
                {_.map(components, (priceComponent) => (
                  <tr key={priceComponent.id}>
                    <td>
                      <div className="ps-3 text-wrap">{priceComponent.name || "N/A"}</div>
                    </td>
                    {showBaseline && (
                      <td>
                        <ComponentRequestedValue component={priceComponent} />
                      </td>
                    )}
                    {operators.map(({ id }, index) => (
                      <td key={id} className={cellClass(id)} style={cellStyle(index)}>
                        <ComponentBidValue componentId={priceComponent.id} operatorId={id} />
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td scope="row">
                <b>Total</b>
              </td>
              {showBaseline && (
                <td>
                  <RequestedTotalValue productId={product.id} />
                </td>
              )}
              {operators.map(({ id }, index) => (
                <td key={id} className={cellClass(id)} style={cellStyle(index)}>
                  <BidTotalValue productId={product.id} operatorId={id} />
                </td>
              ))}
            </tr>
          </tfoot>
        </Table>
      </ErrorBoundary>
    </div>
  )
}

export default ComponentTable
